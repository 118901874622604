import React from 'react';
import {Row, Time, Date, Amount, Price, Icon} from '../styled'


export default function MarketTradesRow({time, date, price, amount, collapse, even, isBuy}) {
    return(<Row collapse={collapse} even={even}>
        <Time>{time}</Time>
        <Date>{date}</Date>
        <Price isBuy={isBuy}><Icon className={isBuy ? 'icon-single-up-pointing-angle' : 'icon-single-down-pointing-angle'} />{price}</Price>
        <Amount>{amount}</Amount>
    </Row>)
}
