import React, {Fragment} from 'react';
import Modal from 'react-modal';
import Exchange from '../../../OrderForm/component/Exchange';

export default ({showOpenOrderModal, labelButtonOpenOrder, closeModal}) => {
    return (
        <Modal
            isOpen={showOpenOrderModal}
            className="modal order"
            style={{
                overlay: {
                    backgroundColor: 'rgba(20, 22, 27, 0.65)',
                    zIndex: 2,
                },
            }}
        >
            <div className="modal-header">
                <span>{labelButtonOpenOrder}</span>
                <button onClick={closeModal}>
                    <i className="icon-close" />
                </button>
            </div>

            <div className="column">
                <Fragment>
                    <div className="order-exchange-component">
                        <Exchange />
                    </div>
                </Fragment>
            </div>
        </Modal>
    );
};
