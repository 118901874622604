import styled, {css} from 'styled-components';

const buttonBg = 'transparent';
const buttonBgActive = '#fff';
const buttonColor = '#999999';
const buttonColorHover = '#2B9241';
const buttonColorActive = '#2B9241';
const buttonShadow = 'inset 0 0 0 1px #999999';
const buttonShadowHover = 'inset 0 0 0 1px #2B9241';

export const Button = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;    
    padding: 0 1rem;
    text-align: center;
    color: ${buttonColor};
    height: 4.5rem;
    background: ${buttonBg};    
    font-size: 2.3333rem;
    text-transform: uppercase;
    box-sizing: border-box;        
    transition: .3s;
    box-shadow: ${buttonShadow};
    white-space: nowrap;
    ${({active}) => !active && css`
        &:hover {
        color: ${buttonColorHover};
        box-shadow: ${buttonShadowHover};
      }
    `}    
    ${({active}) => active && css`
        background: ${buttonBgActive};
        color: ${buttonColorActive};
        cursor: default;   
        box-shadow: ${buttonShadowHover};     
    `}
`;
