export const ActionTypes = {
    setActiveView: 'dashboard-scene.set-active-view',
    setActiveDashboardIndex: 'dashboard-scene.set-active-dashboard-index',
    enterDashboard: 'dashboard-scene.enter',
    leaveDashboard: 'dashboard-scene.leave',
    startRSSFeedReader: 'dashboard-scene.rss-feed.start',
    stopRSSFeedReader: 'dashboard-scene.rss-feed.stop',
    openDepositModal: 'dashboard-scene.deposit-modal.open',
    closeDepositModal: 'dashboard-scene.deposit-modal.close',
    setOrderForm: 'dashboard-scene.order-form.set',
    errorOrder: 'dashboard-scene.error.set',
    openSessionModal: 'dashboard-scene.Session-modal.open',
    closeSessionModal: 'dashboard-scene.Session-modal.close',
    positionTooltip: 'position-tooltip.dashboard-scene.error.set',
    setActiveTabSymbols: 'SET_ACTIVE_TAB_SYMBOLS',
    setActiveTabMyTrading: 'SET_ACTIVE_TAB_MY_TRADING',
    openLimitOrderModal: 'OPEN-LIMIT-ORDER-MODAL',
    closeLimitOrderModal: 'CLOSE-LIMIT-ORDER-MODAL',
};

export function openLimitOrderModal() {
    return {
        type: ActionTypes.openLimitOrderModal,
    }
}

export function closeLimitOrderModal() {
    return {
        type: ActionTypes.closeLimitOrderModal,
    }
}

export function setActiveView(view) {
    return {
        type: ActionTypes.setActiveView,
        view
    };
}

export function setActiveDashboardIndex(activeDashboardIndex) {
    return {
        type: ActionTypes.setActiveDashboardIndex,
        activeDashboardIndex
    };
}

export function enterDashboard() {
    return {
        type: ActionTypes.enterDashboard
    };
}

export function leaveDashboard() {
    return {
        type: ActionTypes.leaveDashboard
    };
}

export function startRSSFeedReader() {
    return {
        type: ActionTypes.startRSSFeedReader
    };
}

export function stopRSSFeedReader() {
    return {
        type: ActionTypes.stopRSSFeedReader
    };
}

export function openDepositModal() {
    return {
        type: ActionTypes.openDepositModal
    };
}

export function errorOrder(error) {
    return {
        type: ActionTypes.errorOrder,
        error
    }
}

export function closeDepositModal() {
    return {
        type: ActionTypes.closeDepositModal
    };
}

export function setOrderForm(payload) {
    return {
        type: ActionTypes.setOrderForm,
        payload
    };
}

export function setOpenSessionModal() {
    return {
        type: ActionTypes.openSessionModal
    };
}

export function setCloseSessionModal() {
    return {
        type: ActionTypes.closeSessionModal
    }
}

export function positionTooltip() {
    return {
        type: ActionTypes.positionTooltip,
    };
}

export function setActiveTabSymbols(payload) {
    return {
        type: ActionTypes.setActiveTabSymbols,
        payload,
    };
}


export function setActiveTabMyTrading(payload) {
    return {
        type: ActionTypes.setActiveTabMyTrading,
        payload,
    };
}
