// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import Form from 'muicss/lib/react/form';

import config from '../../../../localConfig';

import {setLocal} from '../../services/action/translation';

import FormField from '../Registration/component/FormField';
import {
    recoveryReducer,
    getRecoveryPasswordForm,
    submitRecoveryPasswordForm,
} from './service/actions';
import {updateForm} from '../../services/action/forms';

import parseTranslation from '../../../adapter/helper/parseTranslation';

import logo from '../Dashboard/components/News/logo.svg';

import Error from '../../component/Error';
import Language from 'infrastructure/view/component/Language';

function mapStateToProps(state) {
    const form = state.forms['ResetPasswordForm'] || {};
    const formError = state.forms.error;
    const {language, errorLanguage} = state.translation;
    const {locale} = state.settings;

    return {
        form,
        formError,
        locale,
        language,
        errorLanguage,
        langName: parseTranslation('lang-name'),
        titleRecovery: parseTranslation('recovery-title'),
        recoveryPasswordInfo: parseTranslation('recovery-password-info'),
        recoverCancel: parseTranslation('recover-cancel'),
        recoverContinue: parseTranslation('recover-continue'),
    };
}

const mapDispatchToProps = {
    updateForm,
    getRecoveryPasswordForm,
    submitRecoveryPasswordForm,
    recoveryReducer,
    setLocal,
};

type PropTypes = {
    token: string,
    form: {
        scheme: [],
        errors: {} | [],
        name: string,
    },
    getRecoveryPasswordForm: () => mixed,
    submitRecoveryPasswordForm: () => mixed,
};

class RecoveryPasswordForm extends PureComponent<PropTypes> {
    captchaRef = null;

    state = {
        successSubmitForm: false,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.captchaRefCallback = this.captchaRefCallback.bind(this);
    }

    componentDidMount() {
        this.props.getRecoveryPasswordForm();
    }

    componentDidUpdate(prevProps): void {
        const {locale, getRecoveryPasswordForm} = this.props;

        if (prevProps.local !== locale) {
            getRecoveryPasswordForm();
        }
    }

    render() {
        const {
            language,
            form,
            local,
            locale,
            errorLanguage,
            formError,
        } = this.props;
        const {scheme, errors, name} = form;

        return (
            <div className="container">
                <div className="wrapper login">
                    <div className="content recovery login-img">
                        <Language className="lng-recovery" leftBottom={true} />

                        <div className="column-wrap">
                            <a
                                href={`${config.landingUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="logo-link"
                            >
                                <img src={logo} alt="logo" />
                            </a>
                            {!this.state.successSubmitForm ? (
                                <Form onSubmit={this.onSubmit}>
                                    <h3>{this.props.titleRecovery}</h3>
                                    <span className="text-indent-down">
                                        <i className="icon-info" />
                                        <div>{this.props.recoveryPasswordInfo}</div>
                                    </span>
                                    <input
                                        className="hidden-field"
                                        type="password"
                                    />
                                    <input
                                        className="hidden-field"
                                        type="password"
                                    />
                                    {formError && <Error />}
                                    {scheme && (
                                        <>
                                            {Object.values(scheme)
                                                .sort((a, b) => a.idx - b.idx)
                                                .map(field => (
                                                    <FormField
                                                        key={field.name}
                                                        name={field.name}
                                                        type={field.type}
                                                        label={field.label}
                                                        options={field.options}
                                                        placeholder={
                                                            field.placeholder
                                                        }
                                                        onChange={value =>
                                                            this.props.updateForm(
                                                                'ResetPasswordForm',
                                                                field.name,
                                                                value
                                                            )
                                                        }
                                                        captchaRefCallback={
                                                            this
                                                                .captchaRefCallback
                                                        }
                                                        error={
                                                            !Array.isArray(
                                                                errors
                                                            ) &&
                                                            errors[
                                                                field.name
                                                            ] &&
                                                            errors[
                                                                field.name
                                                            ][0]
                                                        }
                                                        formName={name}
                                                        animatePlaceholder={
                                                            true
                                                        }
                                                    />
                                                ))}
                                            <div className="btn-wrap">
                                                <Link
                                                    to="/login"
                                                    className="link-item"
                                                >
                                                    {this.props.recoverCancel}
                                                </Link>
                                                <button
                                                    type="submit"
                                                >
                                                    {this.props.recoverContinue}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </Form>
                            ) : (
                                <Redirect to="/login" />
                            )}
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps): void {
        const {locale} = this.props;

        if (this.props.form.result === true) {
            this.setState({successSubmitForm: true});
        }

    }

    changeLocal = e => {
        const local = e.target.dataset.code;
        this.props.setLocal(local);
        localStorage.setItem('local', local);
    };

    onSubmit(e) {
        e.preventDefault();
        this.props.submitRecoveryPasswordForm();

        if (this.captchaRef) {
            this.captchaRef.reset();
        }
    }

    captchaRefCallback(ref) {
        this.captchaRef = ref;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecoveryPasswordForm);
