import {ActionTypes} from '../action/fullscreen';

const initialState = {
    enable: false,
};

function fullscreen(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.setFullscreenValue:
            return {
                enable: action.isEnabled,
            };

        default:
            return state;
    }
}

export default fullscreen;
