/* eslint-disable */
String.prototype.supplant = function (o) {
    return this.replace(
        /{([^{}]*)}/g,
        function (a, b) {
            let r = o[b];
            return (typeof r === 'string' || typeof r === 'number')? r : a;
        }
    );
};
