import { TwoFAAdapterInterface } from "../../application/adapter/TwoFA";

import localConfig from 'localConfig';
import {getDomain} from 'utils';

import type { Axios } from "axios/index";
import type { AccessToken } from "../../domain/entitiy/AccessToken";

class TwoFAAdapter implements TwoFAAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getTwoFAStatus(accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: "POST",
            url: `${getDomain(localConfig.apiUrl)}/v1/user/info`,

            headers: {
                "content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${accessToken}`,
                "cache-control": "no-cache"
            }
        });
    }

    async getTwoFAService(accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: "GET",
            url: `${getDomain(localConfig.apiUrl)}/v1/security/service?code=google-authenticator`,

            headers: {
                "content-type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${accessToken}`,
                "cache-control": "no-cache"
            }
        });
    }

    async getForm(locale: string, accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: "PUT",
            url: `${getDomain(localConfig.apiUrl)}/v1/form/switch-google-authenticator`,
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`,
                Authorization: `Bearer ${accessToken}`
            }
        });
    }

    async sendForm(formData: {}, locale: string, accessToken: AccessToken): Promise<AccessToken> {
        return await this.apiClient({
            method: "POST",
            url: `${getDomain(localConfig.apiUrl)}/v1/form/switch-google-authenticator`,
            headers: {
                "content-type": "application/json",
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`,
                Authorization: `Bearer ${accessToken}`
            },
            data: { ...formData }
        });
    }
}

export default TwoFAAdapter;
