import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';

import {close} from './service/actions';
import cn from 'classnames';

import CollapsibleFaq from './CollapsibleFaq';
import Tuition from './Tuition';

import parseTranslation from '../../../../../../../adapter/helper/parseTranslation';

function mapStateToProps(state) {
    const {isOpen} = state.faqModal;
    const {assets: faq} = state;

    return {
        isOpen,
        faq,
        titleFaqModal: parseTranslation('tittle-faq-modal'),
        labelTuition: parseTranslation('label-faq-modal-tuition'),
        labelFaq: parseTranslation('label-faq-modal-faq'),
    };
}

const mapDispatchToProps = {
    close,
};

class FaqModal extends PureComponent {
    state = {
        activeTab: 0,
    };

    renderTabBtn = (activeTab, title) => (
        <button
            className={cn({
                active: this.state.activeTab === activeTab,
            })}
            onClick={() => this.setState({activeTab})}
        >
            {title}
        </button>
    );

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                contentLabel="Fqa Modal"
                className="modal faq"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(20, 22, 27, 0.65)',
                        display: 'flex',
                        zIndex: 100,
                    },
                }}
            >
                <div className="modal-header">
                    <span>{this.props.titleFaqModal}</span>
                    <button onClick={this.props.close}>
                        <i className="icon-close" />
                    </button>
                </div>

                <div className="tabs-faq">
                    <div>
                        {this.renderTabBtn(0, `${this.props.labelTuition}`)}
                        {this.renderTabBtn(1, `${this.props.labelFaq}`)}
                    </div>

                    <div className="search-currency">
                        {/* TODO: Check, if it is possible to use Find component here */}
                        <input type="text" />
                    </div>
                </div>

                {this.state.activeTab === 0 && <Tuition />}

                {this.state.activeTab === 1 && <CollapsibleFaq />}
            </Modal>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FaqModal);
