import styled, {css} from 'styled-components';

export const buttonBg = '#F5F5F5';
export const buttonColor = '#000000';
export const buttonBgHover = '#E5E5E5';
export const iconColor = '#999999';
export const iconColorHover = '#2B9241';

export const Wrapper = styled.div`
    font-size: 2.333rem;
    color: ${buttonColor};
    display: flex;    
    align-items: center;    
    justify-content: center;
    cursor: pointer;
    height: 4.5rem;
    background: ${buttonBg};
    border-radius: 0;
    padding: 0 2rem;   
    ${({isInChart}) => isInChart && css`
      height: 5.833rem;
    `}   
    &:hover {
      background: ${buttonBgHover};
      i {
        color: ${iconColorHover};
      }
    }
`;

export const Icon = styled.i`
  color: ${iconColor};
  margin: 0.25rem 1rem 0;  
`;

