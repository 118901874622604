// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import Modal from 'react-modal';

import FormField from '../../Registration/component/FormField';
import {getVerificationForm, submitVerificationForm} from '../service/actions';
import {updateForm} from '../../../services/action/forms';

import parseTranslation from '../../../../adapter/helper/parseTranslation';
import ProcessedIcon from 'img/svg/icon-profile-processed.svg';
import VerifiedIcon from 'img/svg/icon-profile-verified.svg';
import Button from 'infrastructure/view/component/Button';

function mapStateToProps(state) {
    const verificationForm = state.forms['VerificationForm'] || {};
    const {isPendingVerificationForm} = state.tickets;
    const formError = state.forms.error;

    return {
        verificationForm,
        formError,
        titleComplete: parseTranslation('verification-form-complete'),
        titlePlease: parseTranslation('verification-form-please'),
        titleUpload: parseTranslation('verification-form-please-upload'),
        titleAdditional: parseTranslation(
            'verification-form-please-upload-additional'
        ),
        titleSubmit: parseTranslation(
            'verification-form-please-upload-additional-submit'
        ),

        titleVerification: parseTranslation('verification'),
        titleProof: parseTranslation('proof'),
        titleDocumentAdditional: parseTranslation('additional'),
        titlePassport: parseTranslation('passport'),
        titleIdCard: parseTranslation('id-card'),
        verificationAlreadyCreate: parseTranslation(
            'verification-already-create'
        ),
        verificationWait: parseTranslation('verification-wait'),
        verificationAlreadyVerified: parseTranslation(
            'verification-already-verified'
        ),
        buttonCancel: parseTranslation('create-ticket-button-cancel'),
        buttonCreate: parseTranslation('create-ticket-button-create'),
        ticketRequirements: parseTranslation(
            'create-ticket-verification-requirements'
        ),
        isPending: isPendingVerificationForm,
    };
}

const mapDispatchToProps = {
    getVerificationForm,
    updateForm,
    submitVerificationForm,
};

type PropTypes = {
    questionForm: {},
    getVerificationForm: () => mixed,
    submitVerificationForm: () => mixed,
    updateForm: () => mixed,
    verificationForm: {},
    list: [],
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

class VerificationForm extends PureComponent<PropTypes> {
    state = {
        modal: {
            title: '',
            isOpenModal: false,
            img: '',
        },
    };

    constructor(props) {
        super(props);
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.previewFile = this.previewFile.bind(this);
    }

    componentDidMount(): void {
        this.props.getVerificationForm();
    }

    componentDidUpdate(prevProps) {
        const {verificationForm} = this.props;

        if (
            verificationForm.result !== prevProps.verificationForm.result &&
            verificationForm.result === 'Success'
        ) {
            this.props.history.push('/tickets');
        }
    }

    handleTicketLimitCancel = () => {
        const {history} = this.props;

        history.push('/tickets');
    };

    previewFile(file): void {
        this.setState({
            modal: {
                title: file.name,
                img: file.preview.url,
                isOpen: true,
            },
        });
    }

    submitFormHandler(e) {
        e.preventDefault();
        this.props.submitVerificationForm();
    }

    onCloseModal(): void {
        this.setState({
            modal: {
                isOpen: false,
            },
        });
    }

    render() {
        const {isPending} = this.props;
        const {scheme, errors, name} = this.props.verificationForm;

        const isVerificationLimitExceeded = errors && errors.user_id && errors.user_id[0] === 'VERIFICATION_LIMIT_EXCEEDED';
        const isVerificationAlreadyVerified = errors && errors.user_id && errors.user_id[0] === 'VERIFICATION_ALREADY_VERIFIED';

        return (
            <Fragment>
                <div className="wrapper-form-create-ticket">
                    {isVerificationLimitExceeded && (
                        <div className="wrapper-form-create-ticket__verified">
                            <img
                                className="svg-icon"
                                src={ProcessedIcon}
                                alt="icon"
                            />
                            <div className="verified-text">
                                {this.props.verificationAlreadyCreate}
                                <br />
                                {this.props.verificationWait}
                            </div>

                            <Button
                                color="secondary"
                                className="ticket-limit__btn"
                                onClick={this.handleTicketLimitCancel}
                            >
                                {this.props.buttonCancel}
                            </Button>
                        </div>
                    )}
                    {isVerificationAlreadyVerified && (
                        <div className="wrapper-form-create-ticket__verified">
                            <img
                                className="svg-icon"
                                src={VerifiedIcon}
                                alt="icon"
                            />
                            <div className="verified-text">
                                {this.props.verificationAlreadyVerified}
                            </div>
                            <Button
                                color="secondary"
                                className="ticket-limit__btn"
                                onClick={this.handleTicketLimitCancel}
                            >
                                {this.props.buttonCancel}
                            </Button>
                        </div>
                    )}
                    {!isVerificationLimitExceeded && !isVerificationAlreadyVerified && (
                        <form
                            className="form-question form-billing"
                            onSubmit={this.submitFormHandler}
                        >
                            <p className={'wrapper-form-create-ticket__title'}>
                                {this.props.titleComplete}
                                {/*To complete your profile verification, we ask you to indicate and verify your*/}
                                {/*identity and residential address by uploading the respective documents in the*/}
                                {/*form below. File upload requirements: file size must be 2MB or less, supported*/}
                                {/*file formats are 'jpg', 'jpeg', 'png'. Maximum uploaded files number should*/}
                                {/*not exceed 10 files.*/}
                            </p>
                            <div className={'wrapper-form-create-ticket__item'}>
                                <p
                                    className={
                                        'wrapper-form-create-ticket__item-title'
                                    }
                                >
                                    {/*1. Identity verification  (Required)*/}
                                    {this.props.titleVerification}
                                </p>
                                <p
                                    className={
                                        'wrapper-form-create-ticket__item-text'
                                    }
                                >
                                    {this.props.titlePlease}
                                    {/*Please upload a clear, legible, valid and unaltered scanned colour copy or photo of one of the following documents to verify your identity:<br/>*/}
                                    <br />
                                    <span>
                                        •&nbsp;&nbsp;
                                        {this.props.titlePassport}
                                    </span>

                                    <span>
                                        •&nbsp;&nbsp;
                                        {this.props.titleIdCard}
                                    </span>
                                </p>
                            </div>
                            <div className={'wrapper-form-create-ticket__item'}>
                                <p
                                    className={
                                        'wrapper-form-create-ticket__item-title'
                                    }
                                >
                                    {/*2. Proof of residential address (Required)*/}
                                    {this.props.titleProof}
                                </p>
                                <p
                                    className={
                                        'wrapper-form-create-ticket__item-text'
                                    }
                                >
                                    {this.props.titleUpload}
                                    {/*Please upload a clear, legible, valid and unaltered scanned colour*/}
                                    {/*copy or photo of an address verification document. An address verification*/}
                                    {/*document can be a utility bill or other official document (bank statement,*/}
                                    {/*credit card statement etc) issued within the last 3 months and showing your*/}
                                    {/*registered name and residential address.*/}
                                </p>
                            </div>
                            <div className={'wrapper-form-create-ticket__item'}>
                                <p
                                    className={
                                        'wrapper-form-create-ticket__item-title'
                                    }
                                >
                                    {/*3. Additional documents (Optional)*/}
                                    {this.props.titleDocumentAdditional}
                                </p>
                                <p
                                    className={
                                        'wrapper-form-create-ticket__item-text'
                                    }
                                >
                                    {this.props.titleAdditional}
                                    {/*You can upload any additional applicable files to complete the verification procedure*/}
                                    {/*including but not limited to an image of you holding your ID next to your face, credit*/}
                                    {/*card confirmation forms, copies of your credit card front and back (showing the first*/}
                                    {/*6 and last 4 digits of your card number with CVV code covered on the back) and any*/}
                                    {/*other relevant documents.*/}
                                </p>
                            </div>
                            {scheme &&
                                Object.values(scheme).map(field => (
                                    <FormField
                                        key={field.name}
                                        name={field.name}
                                        type={field.type}
                                        label={field.label}
                                        options={field.options}
                                        onChange={value => {
                                            this.props.updateForm(
                                                'VerificationForm',
                                                field.name,
                                                value
                                            );
                                        }}
                                        error={
                                            !Array.isArray(errors) &&
                                            errors[field.name] &&
                                            errors[field.name][0]
                                        }
                                        allErrors={errors}
                                        typeFileParams={{
                                            className: 'files',
                                            previewFile: this.previewFile,
                                        }}
                                        formName={name}
                                    />
                                ))}
                            <p className={'wrapper-form-create-ticket__title'}>
                                {this.props.ticketRequirements}
                                <br />
                                <br />
                                {this.props.titleSubmit}

                                {/*After you submit this ticket, you can track its status and comments by*/}
                                {/*support agents in general list of tickets. You can also add your additional*/}
                                {/*comments and files to this ticket later on.*/}
                            </p>
                            <div className="wrapper-form-create-ticket__wrap-btn">
                                <Link to={'/tickets'}>
                                    {this.props.buttonCancel}
                                </Link>
                                <button type={'submit'} disabled={isPending}>
                                    {this.props.buttonCreate}
                                </button>
                            </div>
                        </form>
                    )}
                    <Modal
                        isOpen={this.state.modal.isOpen}
                        contentLabel="Ticket Modal"
                        className="modal faq preview"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(20, 22, 27, 0.65)',
                                display: 'flex',
                                zIndex: 100,
                            },
                        }}
                    >
                        <div className="modal-header">
                            <span>{this.state.modal.title}</span>
                            <button type={'button'} onClick={this.onCloseModal}>
                                <i className="icon-close" />
                            </button>
                        </div>
                        <div className="modal-wrap">
                            <img src={this.state.modal.img} alt="preview" />
                        </div>
                    </Modal>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VerificationForm)
);
