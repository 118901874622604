import React from 'react';
import {connect} from 'react-redux';

import {CreatedWrapper, Title, Description, Image} from './styled';
import listing from './listing.svg';
import parseTranslation from '../../../../adapter/helper/parseTranslation';

const mapStateToProps = () => ({
    title1: parseTranslation('title1-listing'),
    title2: parseTranslation('title2-listing'),
    description1: parseTranslation('description1-listing'),
    description2: parseTranslation('description2-listing'),
});

const Created = (props) => (
    <CreatedWrapper>
        <Image src={listing} alt="Listing" />
        <Title>{props.title1}</Title>
        <Title>{props.title2}</Title>
        <Description>
            {props.description1}
        </Description>
        <Description>
            {props.description2}
        </Description>
    </CreatedWrapper>
);

export default connect(mapStateToProps)(Created);
