import axios from '../../../adapter/helper/axios';

import {errorForm, setForm} from '../action/forms';

import {
    ActionsType,
    setTwoFAStatus,
    setTwoFAService,
    hideDisableModal,
    hideEnableModal,
    changeTwoFAStatus,
    nextEnableStep,
    checkboxChange,
} from '../action/twoFA';

import TwoFAAdapter from '../../../adapter/TwoFA';

import GetTwoFAStatus from '../../../../application/use-case/twoFA/GetTwoFAStatus';
import GetTwoFAService from '../../../../application/use-case/twoFA/GetTwoFAService';
import Get2FAForm from '../../../../application/use-case/twoFA/Get2FAForm';
import Submit2FAForm from '../../../../application/use-case/twoFA/Submit2FAForm';

const twoFAAdapter = new TwoFAAdapter(axios);

const getTwoFAStatus = new GetTwoFAStatus(twoFAAdapter);
const getTwoFAService = new GetTwoFAService(twoFAAdapter);
const get2FAForm = new Get2FAForm(twoFAAdapter);
const submit2FAForm = new Submit2FAForm(twoFAAdapter);

export default function(store) {
    return function(next) {
        return async function(action) {
            const {local} = store.getState().translation;

            if (action.type === ActionsType.getTwoFAStatus) {
                try {
                    const {accessToken} = store.getState().user;

                    const twoFAStatus = await getTwoFAStatus.invoke(
                        accessToken
                    );
                    if (!!twoFAStatus && twoFAStatus.length !== 0) {
                        store.dispatch(setTwoFAStatus(true, twoFAStatus));
                    } else {
                        store.dispatch(setTwoFAStatus(false, twoFAStatus));
                    }
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionsType.getTwoFAService) {
                try {
                    const {accessToken} = store.getState().user;

                    const service = await getTwoFAService.invoke(accessToken);
                    if (service) {
                        store.dispatch(
                            setTwoFAService(service.qrUrl, service.secret)
                        );
                    }
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionsType.getEnableForm) {
                try {
                    const {accessToken} = store.getState().user;

                    const formData = await get2FAForm.invoke(
                        local,
                        accessToken
                    );

                    store.dispatch(setForm(formData));

                } catch (e) {
                    store.dispatch(errorForm(e));
                }

            }

            if (action.type === ActionsType.submitEnableForm) {
                try {
                    const {accessToken} = store.getState().user;
                    const form = store.getState().forms[
                        'SwitchGoogleAuthenticatorForm'
                        ];
                    const formData = {
                        SwitchGoogleAuthenticatorForm: {
                            activate: 1,
                            code: form.scheme.code.value,
                            login: form.scheme.login.value,
                            password: form.scheme.password.value,
                        },
                    };

                    const formResult = await submit2FAForm.invoke(
                        formData,
                        local,
                        accessToken
                    );

                    if (formResult.result === 'Success') {
                        store.dispatch(nextEnableStep());
                        setTimeout(() => {
                            store.dispatch(hideEnableModal());
                            store.dispatch(changeTwoFAStatus(true));
                            store.dispatch(checkboxChange(true));
                        }, 1000);
                    }

                    store.dispatch(setForm(formResult));

                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionsType.getDisableForm) {
                try {
                    const {accessToken} = store.getState().user;
                    const formData = await get2FAForm.invoke(
                        local,
                        accessToken
                    );

                    store.dispatch(setForm(formData));

                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionsType.submitDisableForm) {
                try {
                    const {accessToken} = store.getState().user;
                    const form = store.getState().forms[
                        'SwitchGoogleAuthenticatorForm'
                        ];

                    const formData = {
                        SwitchGoogleAuthenticatorForm: {
                            activate: 0,
                            code: form.scheme.code.value,
                            login: form.scheme.login.value,
                            password: form.scheme.password.value,
                        },
                    };

                    const formResult = await submit2FAForm.invoke(
                        formData,
                        local,
                        accessToken
                    );

                    if (formResult.result === 'Success') {
                        store.dispatch(hideDisableModal());
                        store.dispatch(changeTwoFAStatus(false));
                        store.dispatch(checkboxChange(false));
                    }
                    store.dispatch(setForm(formResult));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            next(action);
        };
    };
}
