import React from 'react';

const VerificationImages = ({verificationImages, onOpenPreviewImage}) => {
    const onOpenPreviewButtonClick = (imagePreviewUrl) => () => {
        onOpenPreviewImage(imagePreviewUrl);
    };

    return (
        <div className="tickets-image-preview">
            <div className="tickets-image-preview__counter">
                <i className="icon icon-attach-file" />
                {`${verificationImages.length} files`}
            </div>
            <div className="tickets-image-preview__wrap-list">
                {verificationImages &&
                verificationImages.map(image => {
                    const imagePreviewUrl = window.URL.createObjectURL(
                        image
                    );

                    return (
                        <div className="tickets-image-preview__wrap">
                            <button
                                className={
                                    'tickets__control-btn tickets__control-btn_preview'
                                }
                                type={'button'}
                                onClick={onOpenPreviewButtonClick(
                                    imagePreviewUrl
                                )}
                            >
                                <i className="icon show-password icon-look" />
                            </button>
                            <img
                                className="tickets-image-preview__item"
                                src={imagePreviewUrl}
                                alt="img"
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default VerificationImages;
