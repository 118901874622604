import React, {Component, PureComponent} from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';
import ToolTip from 'react-portal-tooltip';
import {TableTooltipMyTrading} from '../../../../../component/TableTooltipMyTrading/index';
import {closeOrder, removeOrderFromList, clearCancelOrderError} from '../service/actions';
import Preloader from 'infrastructure/view/component/Preloader';
import {notificationReceived} from '../../../../../services/action/notification';
import parseTranslation from '../../../../../../adapter/helper/parseTranslation';
import ErrorContainer from "../../../../../component/Error/ErrorContainer";
import {uniqueId} from 'lodash';



function mapStateToProps(state) {
    return {
        titleTableOrder: parseTranslation('label-order'),
        titleTableSymbol: parseTranslation('label-symbol'),
        titleTableType: parseTranslation('label-type'),
        titleTableOpenTime: parseTranslation('label-open-time'),
        titleTableSetPrice: parseTranslation('label-set-price'),
        titleTableAmount: parseTranslation('label-amount'),
        titleTableClose: parseTranslation('label-close'),
        shouldLoadHistory: state.tradingHistory.shouldLoadHistory,
        errorWSCancelOrder: state.tradingHistory.errorWSCancelOrder,
        isSocketConnected: state.socket.isConnected,
        socketError: state.socket.error,
    };
}

const mapDispatchToProps = {
    closeOrder,
    removeOrderFromList,
    clearCancelOrderError,
    notificationReceived,
};

class Entry extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipActive: false,
            trigger: false,
        };

        this.timerId = null;
    }

    componentDidMount() {
        const {isMarket, id} = this.props.order;

        if (isMarket) {
            setTimeout(() => {
                this.props.removeOrderFromList(id);
            }, 3000);
        }
    }

    showTooltip() {
        this.setState(
            {isTooltipActive: false},

            () => {
                this.timerId = setTimeout(() => {
                    this.setState({isTooltipActive: true});
                }, 2000);
            }
        );
    }

    hideTooltip() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.setState({isTooltipActive: false});
    }

    close = () => {
        this.setState({trigger: true});
    };

    onCloseOrder = () => {
        const {closeOrder, clearCancelOrderError, order} = this.props;

        clearCancelOrderError(order.id);

        closeOrder(order);

        this.setState({forceHide: true});
    };

    render() {
        const {isTooltipActive, forceHide} = this.state;
        const {
            order: {id, symbol, type, openingTime, price, amount, isMarket},
            isTooltipLeaveTable,
            disableTooltip,
            errorWSCancelOrder,
        } = this.props;

        const isCancelError = errorWSCancelOrder.some(item => item === id);

        const trClassName = cn({'force-hide-tr': forceHide && !isCancelError});

        const [numerator, denominator] = symbol.split('/');

        const tooltipId = `order-${id}`.replace('/', '');


        let style = {
            style: {
                background: 'rgba(0,0,0,0)',
                boxShadow: '0 0 0 rgba(0,0,0,0)',
                left: '0',
                transition: 'none',
                visibility: 'none',
                pointerEvents: 'none',
            },
            arrowStyle: {
                display: 'none',
                borderColor: 'none',
            },
        };

        return (
            <>
                <ToolTip
                    active={
                        isTooltipActive &&
                        isTooltipLeaveTable &&
                        !disableTooltip
                    }
                    position="right"
                    style={style}
                    parent={'#' + tooltipId}
                    group="order"
                    tooltipTimeout={0}
                >
                    <TableTooltipMyTrading
                        symbol={
                            <span className="currency-pair-symbols">
                                <i
                                    className={` icon-currency icon-currency-left icon-${numerator.toLowerCase()}`}
                                />
                                <span
                                    className="currency-name"
                                    style={{
                                        fontSize: '2.3rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {symbol}
                                </span>
                                <i
                                    className={`icon-currency icon-currency-right icon-${denominator.toLowerCase()}`}
                                />
                            </span>
                        }
                        type={
                            <span className="order-type">
                                <span className={`${type.toLowerCase()}`}>
                                    <i className={`${type}`} />
                                    {type}
                                </span>
                            </span>
                        }
                        price={price}
                        amount={amount}
                    />
                </ToolTip>

                <tr
                    id={tooltipId}
                    className={trClassName}
                    onMouseEnter={this.showTooltip.bind(this)}
                    onMouseLeave={this.hideTooltip.bind(this)}
                    style={{
                        position: 'relative',
                    }}
                >
                    <td style={{width: '17%'}}>{id}</td>

                    <td>
                        <div className="currency-pair-wrapper">
                            <i
                                className={`icon-currency icon-${numerator.toLowerCase()}`}
                            />
                            <span className="currency-pair currency-pair-orders-expanded">
                                {symbol}
                            </span>
                            <i
                                className={`icon-currency icon-${denominator.toLowerCase()}`}
                            />
                        </div>
                    </td>

                    <td className="order-type cl-w">
                        <span className={`${type.toLowerCase()}`}>
                            <i className={`${type}`} />
                            {type}
                        </span>
                    </td>
                    <td>
                        <span style={{fontSize: '2.3rem', display: 'block'}}>
                            {openingTime.format('HH:mm:ss DD.MM.YYYY')}
                        </span>
                    </td>
                    <td>
                        {price}
                    </td>
                    <td>
                        {amount}
                    </td>
                    <td>
                        {!isMarket && (
                            <button onClick={this.onCloseOrder}>
                                <i className="icon-close" style={{fontSize: '2.2rem'}} />
                            </button>
                        )}
                    </td>
                </tr>
            </>
        );
    }
}

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipLeaveTable: false,
        };

        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleTooltip() {
        this.setState(state => ({
            isTooltipLeaveTable: !state.isTooltipLeaveTable,
        }));
    }

    render() {
        const {isTooltipLeaveTable} = this.state;
        const {
            orders,
            closeOrder,
            removeOrderFromList,
            disableTooltip,
            isSocketConnected,
            socketError,
            errorWSCancelOrder,
            clearCancelOrderError,
            notificationReceived,
        } = this.props;


        if (isSocketConnected && orders === null) {
            return <Preloader />;
        }

        if (socketError) {
            return <ErrorContainer />
        }

        let result = null;
        if (orders !== null) {
            result = orders.map(order => (
                    <Entry
                        disableTooltip={disableTooltip}
                        isTooltipLeaveTable={isTooltipLeaveTable}
                        key={order.id}
                        order={order}
                        closeOrder={closeOrder}
                        removeOrderFromList={removeOrderFromList}
                        errorWSCancelOrder={errorWSCancelOrder}
                        clearCancelOrderError={clearCancelOrderError}
                        notificationReceived={notificationReceived}
                    />
                ));
        }

        return (
            <div className="order-table-wrap">
                <table
                    className="general-body"
                    onMouseLeave={this.toggleTooltip}
                    onMouseEnter={this.toggleTooltip}
                >
                    <tbody>{result}</tbody>
                </table>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders);
