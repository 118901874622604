import React, {Component} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {NavLink, Link, withRouter, useLocation} from 'react-router-dom';
import Form from 'muicss/lib/react/form';

import parseTranslation from '../../../adapter/helper/parseTranslation';

import FormField from '../Registration/component/FormField';
import Language from 'infrastructure/view/component/Language';

import {getForm, submitForm} from './service/actions';
import {updateForm, removeGoogleAuthForm} from '../../services/action/forms';
import {setLocal} from '../../services/action/translation';
import {setLocale, sendSettingsData} from '../../services/action/settings';

import logo from '../Dashboard/components/News/logo.svg';
import bgLogo from 'img/svg/logo-left.svg';
import bgLogo1 from 'img/svg/logo-right.svg';

import config from '../../../../localConfig';
import Error from '../../component/Error';
import Preloader from '../../component/Preloader';
import {initAuth} from '../../services/action/user';
import {accessTokenKey} from '../../services/middleware/settings';
import {userRegionKey} from '../../../../settings';
import {setUserRegion, logout} from '../../services/action/user';

function mapStateToProps(state) {
    const formError = state.forms.error;
    const form = state.forms['GoogleAuthenticatorForm']
        ? state.forms['GoogleAuthenticatorForm']
        : state.forms['LoginForm'] || {};
    const {language, local, errorLanguage, isPendingTranslation} =
        state.translation;

    return {
        form,
        formError,
        locale: local,
        language,
        errorLanguage,
        langName: parseTranslation('lang-name'),
        titleLogin: parseTranslation('login-header'),
        buttonLogin: parseTranslation('login-btn'),
        hrefRecovery: parseTranslation('login-recover-account'),
        hrefRegister: parseTranslation('login-registration'),
        recoverCancel: parseTranslation('recover-cancel'),
        isPendingTranslation,
    };
}

const mapDispatchToProps = {
    initAuth,
    setUserRegion,
    logout,
    getForm,
    submitForm,
    updateForm,
    setLocal,
    sendSettingsData,
    setLocale,
    removeGoogleAuthForm,
};

class Login extends Component {
    captchaRef = null;

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.captchaRefCallback = this.captchaRefCallback.bind(this);
        this.state = {
            isLoading: false,
            is2FA: false,
        };
    }

    componentDidMount() {
        const {history, initAuth, setUserRegion, logout} = this.props;
        const data =
            this.props.history.location.search &&
            new URLSearchParams(this.props.history.location.search).get('data');
        const parsedData = data && JSON.parse(atob(data));
        if (parsedData) {
            localStorage.removeItem('userRegion');
            logout();
            const loginData = JSON.stringify({
                access_token: parsedData.token,
                userInfo: {
                    accountId: parsedData.accountId,
                    name: parsedData.first_name + ' ' + parsedData.last_name,
                    avatar: parsedData.avatar_url,
                },
                ibsn: 0,
                path:
                    parsedData.path &&
                    `/${
                        parsedData.path.split('/')[
                            parsedData.path.split('/').length - 1
                        ]
                    }`,
                region: 'Europe',
                alias: 'EU1',
                server: config.authUrl,
            });
            initAuth(parsedData.token);
            setUserRegion({region: 'Europe'});
            localStorage.setItem(userRegionKey, loginData);
            const path =
                parsedData.path &&
                `/${
                    parsedData.path.split('/')[
                        parsedData.path.split('/').length - 1
                    ]
                }`;

            history.push('/dashboard');
        } else {
            // history.push('/');
        }

        this.props.getForm({history});
        this.script = document.createElement('script');
        this.script.src = config.recaptchaUrl;
        this.script.async = true;
        this.script.defer = true;
        document.body.appendChild(this.script);
    }

    componentWillUnmount() {
        const scripts = document.getElementsByTagName('script');
        for (let script of scripts) {
            if (script.src.includes('recaptcha')) {
                script.remove();
            }
        }
    }

    componentDidUpdate(prevProps): void {
        const {
            locale,
            getForm,
            form: {errors},
        } = this.props;
        if (prevProps.form.errors !== errors && !Array.isArray(errors)) {
            this.setState({isLoading: false, is2fa: false});
        }
        if (prevProps.locale !== locale) {
            getForm();
        }
        if (
            prevProps.form !== this.props.form &&
            this.props.form.name === 'GoogleAuthenticatorForm'
        ) {
            if (!this.state.is2FA) {
                this.setState({isLoading: false});
                this.setState({is2FA: true});
            }
        }
    }

    cancelAuth(e) {
        e.preventDefault();
        this.setState({is2FA: false});
        this.props.removeGoogleAuthForm();
    }

    render() {
        const {formError, errorLanguage, isPendingTranslation} = this.props;
        const {scheme, errors, name} = this.props.form;

        // if (isPendingTranslation) return <Preloader />;

        return (
            <div className="container">
                <Language
                    className="lng-recovery"
                    leftBottom={true}
                    hide={this.props.form.name === 'GoogleAuthenticatorForm'}
                />
                <div className="wrapper login">
                    <div className="content login login-img">
                        <div
                            className={`column-wrap ${
                                this.props.form.name === 'LoginForm'
                                    ? 'login-form'
                                    : 'authenticator-form'
                            }`}
                        >
                            {!this.state.isLoading && (
                                <a
                                    href={`${config.landingUrl}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="logo-link"
                                >
                                    <img src={logo} alt="logo" />
                                </a>
                            )}

                            <div className="form-wrapper">
                                {this.state.isLoading ? (
                                    <Preloader />
                                ) : (
                                    <>
                                        <Form onSubmit={this.onSubmit}>
                                            <h3>{this.props.titleLogin}</h3>
                                            <input className="hidden-field" />
                                            <input
                                                className="hidden-field"
                                                type="password"
                                            />
                                            {formError && <Error />}
                                            {scheme && (
                                                <>
                                                    {Object.values(scheme)
                                                        .sort(
                                                            (a, b) =>
                                                                a.idx - b.idx
                                                        )
                                                        .map((field, index) => (
                                                            <FormField
                                                                key={index}
                                                                name={
                                                                    field.name
                                                                }
                                                                type={
                                                                    field.type
                                                                }
                                                                label={
                                                                    field.label
                                                                }
                                                                placeholder={
                                                                    field.placeholder
                                                                }
                                                                options={
                                                                    field.options
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) =>
                                                                    this.props.updateForm(
                                                                        this
                                                                            .props
                                                                            .form
                                                                            .name ===
                                                                            'GoogleAuthenticatorForm'
                                                                            ? 'GoogleAuthenticatorForm'
                                                                            : 'LoginForm',
                                                                        field.name,
                                                                        value
                                                                    )
                                                                }
                                                                captchaRefCallback={
                                                                    this
                                                                        .captchaRefCallback
                                                                }
                                                                error={
                                                                    !Array.isArray(
                                                                        errors
                                                                    ) &&
                                                                    errors[
                                                                        field
                                                                            .name
                                                                    ] &&
                                                                    errors[
                                                                        field
                                                                            .name
                                                                    ][0]
                                                                }
                                                                formName={name}
                                                                value={
                                                                    this.props
                                                                        .form
                                                                        .value
                                                                }
                                                                animatePlaceholder={
                                                                    true
                                                                }
                                                            />
                                                        ))}
                                                    <div className="button-wrapper">
                                                        {this.props.form
                                                            .name ===
                                                            'GoogleAuthenticatorForm' && (
                                                            <button
                                                                type="button"
                                                                className="secondary-button"
                                                                onClick={(e) =>
                                                                    this.cancelAuth(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    this.props
                                                                        .recoverCancel
                                                                }
                                                            </button>
                                                        )}
                                                        <button
                                                            className="main-button"
                                                            type="submit"
                                                        >
                                                            {
                                                                this.props
                                                                    .buttonLogin
                                                            }
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                        <Link
                                            to="/recovery"
                                            className="link-item"
                                        >
                                            {this.props.hrefRecovery}
                                        </Link>
                                        <NavLink
                                            to="/registration"
                                            className="link-item"
                                        >
                                            {this.props.hrefRegister}
                                        </NavLink>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    changeLocal = (e) => {
        const local = e.target.dataset.code;
        this.props.setLocal(local);
        localStorage.setItem('local', local);

        this.props.setLocale(local);
    };

    onSubmit(e) {
        e.preventDefault();
        const {updateForm, submitForm, history} = this.props;
        this.setState({isLoading: true});
        if (this.props.form.name === 'GoogleAuthenticatorForm') {
            updateForm(
                'GoogleAuthenticatorForm',
                'code',
                document.querySelector('input[name=code]').value
            );
        } else {
            updateForm(
                'LoginForm',
                'login',
                document.querySelector('input[name=login]').value
            );
            updateForm(
                'LoginForm',
                'password',
                document.querySelector('input[name=password]').value
            );
        }

        submitForm(history);

        if (this.captchaRef) {
            this.captchaRef.reset();
        }
    }

    captchaRefCallback(ref) {
        this.captchaRef = ref;
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(Login);
