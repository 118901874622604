import styled from 'styled-components';
import {firstLineColor} from '../components/LinesFill/styled';
import {panelBodyBg} from '../components/Panel/styled';

const emptyColor = '#999999';
const linkColor = '#2B9241';
const linkColorHover = '#0DC962';

export const Empty = styled.div`
    color: ${emptyColor};
    font-size: 2.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    a {
      color: ${linkColor}
      &:hover {
        color: ${linkColorHover}
      }
    }
`;

