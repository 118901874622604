export const ActionTypes = {
    symbolsSuccess: 'dashboard.symbols.success',
    updateSymbol: 'dashboard-scene.symbols-component.update-symbol',
    toggleSymbolFavorite:
        'dashboard-scene.symbols-component.toggle-symbol-favorite',
    setSymbolFilter: 'dashboard-scene.symbols-component.set-symbol-filter',
};

export function setSymbols(payload) {
    return {
        type: ActionTypes.symbolsSuccess,
        payload,
    };
}

export function updateSymbol(payload) {
    return {
        type: ActionTypes.updateSymbol,
        payload,
    };
}

export function toggleSymbolFavorite(id) {
    return {
        type: ActionTypes.toggleSymbolFavorite,
        id,
    };
}

export function setSymbolFilter(filter) {
    return {
        type: ActionTypes.setSymbolFilter,
        filter,
    };
}
