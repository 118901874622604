// @flow
import { xml2js } from 'xml-js';
import axios from 'axios';
import moment from 'moment';

class ReedRSSFeed {
    async invoke(): Promise<any> {
        try {
            const [news, forecasts] = await Promise.all([
                axios.get('https://www.dfid.org/news/rss.xml'),
                axios.get('https://www.dfid.org/forecasts/rss.xml'),
            ]);

            const data = [
                ...xml2js(news.data, { compact: true }).rss.channel.item,
                ...xml2js(forecasts.data, { compact: true }).rss.channel.item,
            ];

            const feeds = (
                data
                    .map(
                        feed => ({
                            title: feed.title._text,
                            link: feed.link._text,
                            contentSnippet: feed.description._text,
                            date: moment(feed.pubDate._text)
                        })
                    )
            );

            return feeds;
        } catch (e) {
            throw e;
        }
    }
}

export default ReedRSSFeed;
