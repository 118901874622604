import React from 'react';
import {
    HeaderWrapper,
    Logo,
    RightSide,
    HeaderRightRadius,
    HeaderLeftRadius,
} from './styled';
import logo from '../../../infrastructure/view/component/Header/logo.svg';
import config from '../../../localConfig';
export default function Header({children}) {
    return (
        <HeaderWrapper>
            <HeaderRightRadius />
            <HeaderLeftRadius />
            <a href={config.landingUrl} target="_blank">
                <Logo src={logo} />
            </a>
            <RightSide>{children}</RightSide>
        </HeaderWrapper>
    );
}
