import styled, {css} from 'styled-components';
import {TooltipWrapper} from '../Tooltip/styled';

const panelRadius = '0';
const headerBg = '#E5E5E5';
const headerColor = '#000000';
export const panelBodyBg = '#fff';
const iconColor = '#2B9241';
const expandColor = '#999999';
const expandColorHover = '#2B9241';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: ${panelRadius};
    overflow: hidden;
    width: 100%;
    ${({collapse}) => collapse && css`
      position:absolute;
      width: auto;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      top: 2rem;
      z-index: 11;
      .absolute-tooltip {
        display: none !important;
      }
      .view {
        justify-content: flex-start;
      }
    `}
`;
export const Icon = styled.i`
    margin: 0 2rem;
    font-size: 3.4rem;
    color: ${iconColor}
`;
export const Expand = styled.i`
    margin: 0 2rem;
    font-size: 2.6rem;
    color: ${expandColor};
    cursor: pointer;
    &:hover {
        color: ${expandColorHover};
    }
`;
export const Title = styled.div`
    display: flex;
    align-items: center;
`;
export const HeaderComponent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const Header = styled.div`
    display: flex;
    align-items: center;    
    background-color: ${headerBg};
    color: ${headerColor};
    justify-content: space-between;
    min-height: 6.6rem;
    font-size: 3rem;
    font-weight: 400;
    text-transform: uppercase;
`;

export const Body = styled.div`
    background-color: ${panelBodyBg};
    flex: 1;
    display: flex;
    flex-direction: column;
`;
