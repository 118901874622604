// @flow
import type Axios from 'axios';

import type {ListingFormAdapterInterface} from '../../application/adapter/ListingForm';

import {getDomain} from '../../utils';
import localConfig from '../../localConfig';

class ListingFormAdapter implements ListingFormAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getListingForm(locale: string, token: string): Promise<any> {
        return await this.apiClient({
            method: 'PUT',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/listing`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`,
            },
        });
    }

    async submitListingForm(
        locale: string,
        formData: {},
        token: string,
    ): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/listing`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`,
            },
            data: formData,
        });
    }
}

export default ListingFormAdapter;
