import {ActionTypes} from '../action/notification';

const initialState = {
    data: [],
    isSocketConnected: false,
    errorToken: null,
    errorSocket: null,
    errorHistory: null,
    errorClose: null,
    errorCloseAll: null,
};

function NotificationReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.NOTIFICATION_SOCKET_SUCCESS: {
            return {
                ...state,
                isSocketConnected: true,
            };
        }

        case ActionTypes.NOTIFICATION_SOCKET_CLOSE: {
            return {
                ...state,
                isSocketConnected: false,
            };
        }

        case ActionTypes.notificationsHistorySuccess: {
            return {
                ...state,
                data: action.history,
            };
        }

        case ActionTypes.notificationReceived: {
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        ...action.notification,
                        isVisible: action.isVisible
                    },
                ],
            };
        }


        case ActionTypes.closeNotification: {
            const updatedState = state.data.filter(
                notification => notification.id !== action.id,
            );

            return {
                ...state,
                data: updatedState,
            };
        }

        case ActionTypes.closeAllNotifications: {
            return {
                ...state,
                data: [],
            };
        }

        case ActionTypes.closePopupNotification: {
            const newState = state.data.map(notification => {
                if (notification.id === action.id) {
                    return {
                        ...notification,
                        isVisible: false,
                    };
                }
                return notification;
            });
            return {
                ...state,
                data: newState,
            };
        }

        case ActionTypes.notificationTokenError: {
            return {
                ...state,
                errorToken: action.payload,
            };
        }


        case ActionTypes.notificationSocketError: {
            return {
                ...state,
                isSocketConnected: false,
                errorSocket: action.payload,
            };
        }

        case ActionTypes.errorNotificationsHistory: {
            return {
                ...state,
                errorHistory: action.payload,
            };
        }

        case ActionTypes.notificationCloseError: {
            return {
                ...state,
                errorClose: action.payload,
            };
        }

        case ActionTypes.notificationCloseAllError: {
            return {
                ...state,
                errorCloseAll: action.payload,
            };
        }

        default:
            return state;
    }
}

export default NotificationReducer;
