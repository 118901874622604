// @flow
import type {
    RegistrationFormAdapterInterface as RegistrationFormAdapter
}  from '../../../application/adapter/RegistrationForm';

class ConfirmRegistration {
    adapter: RegistrationFormAdapter;

    constructor(adapter: RegistrationFormAdapter) {
        this.adapter = adapter;
    }

    async invoke(username: string, token: string): Promise<boolean> {
        try {
            await this.adapter.confirmRegistration(username, token);
            return true;
        } catch (e) {
            return false;
        }
    }
}

export default ConfirmRegistration;
