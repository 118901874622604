import React, {useRef, useState} from 'react';
import {Wrapper, TooltipWrapper} from './styled';
export default function TooltipAbsolute({
    children,
    content,
    leftCenter,
    rightCenter,
    topRight,
}) {
    const ref = useRef(null);
    const [coordinates, setCoordinates] = useState({});
    const handleShow = () => {
        const top = ref.current.getBoundingClientRect().top;
        const right =
            window.innerWidth -
            ref.current.getBoundingClientRect().right +
            ref.current.getBoundingClientRect().width;
        const left =
            ref.current.getBoundingClientRect().left +
            ref.current.getBoundingClientRect().width;
        const height = ref.current.getBoundingClientRect().height;
        let resultCoordinates;
        if (leftCenter)
            resultCoordinates = {
                top: top + height / 2,
                left: left,
                transform: 'translateY(-50%)',
                height: `${leftCenter}rem`,
            };
        if (rightCenter)
            resultCoordinates = {
                top: top + height / 2,
                left: 'auto',
                right: right,
                transform: 'translateY(-50%)',
                height: `${rightCenter}rem`,
            };
        if (topRight)
            resultCoordinates = {
                top: top,
                left: left,
                transform: 'translate(-100%, -100%)',
            };
        setCoordinates(resultCoordinates);
    };
    return (
        <Wrapper  onMouseOver={handleShow} ref={ref}>
            {children}
            <TooltipWrapper
                className="absolute-tooltip"
                leftCenter={leftCenter}
                rightCenter={rightCenter}
                topRight={topRight}
                style={{
                    transform: 'none',
                    bottom: 'auto',
                    position: 'fixed',
                    ...coordinates,
                }}
                leftCenter={leftCenter}
                rightCenter={rightCenter}
                topRight={topRight}
            >
                {content}
            </TooltipWrapper>
        </Wrapper>
    );
}
