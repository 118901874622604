import React, {Component, Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';

import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Panel from '../../../../component/Panel/Panel';
import CurrencyPair from 'infrastructure/view/component/SymbolButton';
import FormattedPrice from '../../../../component/FormattedPrice/FormattedPrice';
import {open as openSymbolsModal} from 'infrastructure/view/scene/Dashboard/components/Header/component/SymbolsModal/service/actions';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';

import {setActiveView as setActiveViewAction} from '../../service/actions';

function mapStateToProps(state) {
    const {sell, buy} = state.DOM;
    const {selectedSymbol} = state.settings;
    return {
        orders: {
            buy,
            sell,
        },
        selectedSymbol,
        title: parseTranslation('label-market-depth'),
        titleTableCount: parseTranslation('label-count'),
        titleTableAmount: parseTranslation('label-amount'),
        titleTableTotal: parseTranslation('label-total'),
        titleTablePrice: parseTranslation('label-price'),
    };
}

function mapDispatchToProps(dispatch) {
    function setActiveView() {
        dispatch(setActiveViewAction(null));
    }
    function openSymbolsModalAction() {
        dispatch(openSymbolsModal());
    }

    return {
        setActiveView,
        openSymbolsModalAction,
    };
}

class BuyGrad extends Component {
    render() {
        const {volumePercentage} = this.props;

        return (
            <tr>
                <td
                    style={{
                        backgroundImage: `linear-gradient(to left, rgba(14, 60, 54, 0.35) 0%, rgba(76, 229, 116, 0.35) ${volumePercentage}%, rgba(0, 0, 0, 0) 0%)`,
                    }}
                />
            </tr>
        );
    }
}

class Buy extends Component {
    render() {
        const {count, amount, total, price} = this.props;

        return (
            <tr>
                <td className="column-count">
                    <i className="icon-single-up-pointing-angle" />
                </td>
                <td>{count}</td>
                <td>
                    <FormattedPrice price={amount} />
                </td>
                <td>
                    <FormattedPrice price={total} />
                </td>
                <td>
                    {String(price).split('.').length > 1
                        ? <>{String(price).slice(0, -2)}<span className="buy-last-char">{String(price).slice(-2)}</span></>
                        : <>{String(price)}<span className="buy-last-char">.00</span></>
                    }
                </td>
            </tr>
        );
    }
}

class SellGrad extends Component {
    render() {
        const {volumePercentage} = this.props;

        return (
            <tr>
                <td
                    style={{
                        backgroundImage: `linear-gradient(90deg, rgba(95, 46, 62, 0.35) 0%, rgba(255, 67, 67, 0.35) ${volumePercentage}%, rgba(0, 0, 0, 0) 0%)`,
                    }}
                />
            </tr>
        );
    }
}

class Sell extends PureComponent {
    render() {
        const {count, amount, total, price} = this.props;

        return (
            <tr>
                <td>
                    {String(price).split('.').length > 1
                        ? <>{String(price).slice(0, -2)}<span className="sell-last-char">{String(price).slice(-2)}</span></>
                        : <>{String(price)}<span className="sell-last-char">.00</span></>
                    }
                </td>
                <td>
                    <FormattedPrice price={total} />
                </td>
                <td>
                    <FormattedPrice price={amount} />
                </td>
                <td>{count}</td>
                <td className="column-count">
                    <i className="icon-single-down-pointing-angle" />
                </td>
            </tr>
        );
    }
}

class DOM extends Component {
    render() {
    const {selectedSymbol, openSymbolsModalAction} = this.props;
        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-market-depth" />
            </button>
        );
        return (
            <Panel
                icon={icon}
                title={this.props.title}
                closable
                closableHandler={this.props.setActiveView}
            >
                <Fragment>
                    <div className="DOM-table-buy-expanded">
                        {this.renderTableBuy()}
                    </div>

                    <div className="DOM-table-sell-expanded">
                        {this.renderTableSell()}
                    </div>

                    <div
                        style={{position: 'absolute'}}
                        className="market-depth"
                    >
                        <div className="currency-pair-modal currency-pair-modal2">
                            <CurrencyPair
                                firstCurrency={selectedSymbol.numerator}
                                secondCurrency={selectedSymbol.denominator}
                                onClick={openSymbolsModalAction}
                            />
                        </div>
                    </div>
                </Fragment>
            </Panel>
        );
    }

    renderTableHeadSell() {
        return (
            <table>
                <thead>
                    <tr style={{backgroundColor: 'transparent'}}>
                        <th>{this.props.titleTablePrice}</th>
                        <th>{this.props.titleTableTotal}</th>
                        <th>{this.props.titleTableAmount}</th>
                        <th>{this.props.titleTableCount}</th>
                        <th />
                    </tr>
                </thead>
            </table>
        );
    }

    renderTableHead() {
        return (
            <table>
                <thead className="table-header expanded">
                    <tr style={{backgroundColor: 'transparent'}}>
                        <th />
                        <th>{this.props.titleTableCount}</th>
                        <th>{this.props.titleTableAmount}</th>
                        <th>{this.props.titleTableTotal}</th>
                        <th>{this.props.titleTablePrice}</th>
                    </tr>
                </thead>
            </table>
        );
    }

    renderTableBuy() {
        const {orders} = this.props;
        const {buy: rawBuyData} = orders;

        const buyTotal = rawBuyData.reduce(
            (result, order) => result + order.amount,
            0
        );
        const buy = rawBuyData
            .sort(this.sortDESC)
            .reduce((result, order, index) => {
                let volume = order.amount;
                if (index) {
                    volume += result[index - 1].volume;
                }

                const volumePercentage = (volume / buyTotal) * 100;

                result.push({
                    ...order,
                    volume,
                    volumePercentage,
                });

                return result;
            }, []);

        const entriesBuy = buy.map(buy => <Buy {...buy} />);

        const entriesBuyGrad = buy.map(buy => <BuyGrad {...buy} />);

        return (
            <div className="container-buy">
                <div>{this.renderTableHead()}</div>
                <div
                    style={{
                        position: 'absolute',
                        top: '6rem',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        display: 'flex',
                    }}
                >
                    <Scrollbars>
                        <div className="container-buy-expanded">
                            <table
                                className="container-table-expanded table-grad-zebra"
                                style={{
                                    position: 'relative',
                                    pointerEvents: 'none'
                                }}
                            >
                                <tbody>{entriesBuyGrad}</tbody>
                            </table>

                            <table className="container-table-expanded">
                                <tbody>{entriesBuy}</tbody>
                            </table>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }

    renderTableSell() {
        const {orders} = this.props;
        const {sell: rawSellData} = orders;

        const sellTotal = rawSellData.reduce(
            (result, order) => result + order.amount,
            0
        );
        const sell = rawSellData
            .sort(this.sortASC)
            .reduce((result, order, index) => {
                let volume = order.amount;
                if (index) {
                    volume += result[index - 1].volume;
                }

                const volumePercentage = (volume / sellTotal) * 100;

                result.push({
                    ...order,
                    volume,
                    volumePercentage,
                });

                return result;
            }, []);

        const entriesSell = sell.map(sell => <Sell {...sell} />);

        const entriesSellGrad = sell.map(sell => <SellGrad {...sell} />);

        return (
            <div className="container-sell">
                <div>{this.renderTableHeadSell()}</div>

                <div
                    style={{
                        position: 'absolute',
                        top: '6rem',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        display: 'flex',
                    }}
                >
                    <Scrollbars>
                        <div className="container-sell-expanded">
                            <table
                                className="container-table-expanded table-grad-zebra"
                                style={{
                                    position: 'relative',
                                    pointerEvents: 'none'
                                }}
                            >
                                <tbody>{entriesSellGrad}</tbody>
                            </table>

                            <table className="container-table-expanded">
                                <tbody>{entriesSell}</tbody>
                            </table>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }

    sortASC(a, b) {
        return a.price - b.price;
    }

    sortDESC(a, b) {
        return b.price - a.price;
    }
}

const ConnectedDOM = connect(
    mapStateToProps,
    mapDispatchToProps
)(DOM);
export default ConnectedDOM;
