export const ActionsType = {
    checkboxChange: "checkboxChange",
    showEnableModal: "showEnableModal",
    hideEnableModal: "hideEnableModal",
    nextEnableStep: "nextEnableStep",
    prevEnableStep: "prevEnableStep",
    restoreEnableStep: "restoreEnableStep",

    showDisableModal: "showDisableModal",
    hideDisableModal: "hideDisableModal",
    changeTwoFAStatus: "changeTwoFAStatus",

    getTwoFAStatus: "getTwoFAStatus",
    setTwoFAStatus: "setTwoFAStatus",

    getTwoFAService: "getTwoFAService",
    setTwoFAService: "setTwoFAService",

    submitEnableForm: "submitEnableForm",
    submitDisableForm: "submitDisableForm",
    getEnableForm: "getEnableForm",
    getDisableForm: "getDisableForm",

    changeInfoModalState: "changeInfoModalState"
};

export function checkboxChange(checked) {
    return {
        type: ActionsType.checkboxChange,
        checked
    };
}

export function showEnableModal() {
    return {
        type: ActionsType.showEnableModal
    };
}

export function hideEnableModal() {
    return {
        type: ActionsType.hideEnableModal
    };
}

export function nextEnableStep() {
    return {
        type: ActionsType.nextEnableStep
    };
}

export function prevEnableStep() {
    return {
        type: ActionsType.prevEnableStep
    };
}

export function restoreEnableStep() {
    return {
        type: ActionsType.restoreEnableStep
    };
}

export function showDisableModal() {
    return {
        type: ActionsType.showDisableModal
    };
}

export function hideDisableModal() {
    return {
        type: ActionsType.hideDisableModal
    };
}

export function changeTwoFAStatus(newStatus) {
    return {
        type: ActionsType.changeTwoFAStatus,
        newStatus
    };
}

export function getTwoFAStatus() {
    return {
        type: ActionsType.getTwoFAStatus
    };
}

export function setTwoFAStatus(isActive, twoFAType) {
    return {
        type: ActionsType.setTwoFAStatus,
        isActive,
        twoFAType
    };
}

export function getTwoFAService() {
    return {
        type: ActionsType.getTwoFAService
    };
}

export function setTwoFAService(qrCode, code) {
    return {
        type: ActionsType.setTwoFAService,
        qrCode,
        code
    };
}

export function getEnableForm() {
    return {
        type: ActionsType.getEnableForm
    };
}

export function getDisableForm() {
    return {
        type: ActionsType.getDisableForm
    };
}

export function submitEnableForm() {
    return {
        type: ActionsType.submitEnableForm
    };
}

export function submitDisableForm() {
    return {
        type: ActionsType.submitDisableForm
    };
}

export function changeInfoModalState() {
    return {
        type: ActionsType.changeInfoModalState
    };
}
