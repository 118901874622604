import styled, {css, keyframes} from 'styled-components';
import {firstLineColor} from '../components/LinesFill/styled';
import {panelBodyBg} from '../components/Panel/styled';

const titlesColor = '#000';
const rowColor = '#000000';
const rowHoverBg = '#E6EBFF';
const rowHoverColor = '#000000';
const iconColor = '#999999';
const iconColorActive = '#2B9241';
const positive = '#00B456';
const negative = '#FF4D4D';
const activeRow = '#000000';
const activeIconColor = '#fff';
const activeCurrencyColor = '#fff';

export const Tabs = styled.div`
    display: flex;
    padding: 1.5rem 0;
    & > div {
        width: 100%;
        margin: 0 2rem;
    }
`;
export const Titles = styled.div`
    display: flex;
    padding: 1.5rem 2rem;
    color: ${titlesColor};
`;
export const Symbol = styled.div`
    font-size: 2.333rem;
    width: 30%;
    color: ${({isActive}) => isActive && activeCurrencyColor};
    align-items: center;
    display: flex;
    i {
      ${({isModal}) => isModal && css`
        font-size: 3.5rem;
      `};
      color: ${iconColor}
    }
`;
export const Price = styled.div`
    font-size: 2.333rem;
    width: 21%;
    text-align: right;
`;
export const Volume = styled.div`
    font-size: 2.333rem;
    width: 25%;
    text-align: right;
`;
export const SymbolsWrapper = styled.div`
    flex: 1;
`;
export const Growth = styled.div`
    font-size: 2.333rem;
    width: 25%;
    text-align: right;
    ${({isPositive}) =>
        isPositive &&
        css`
            color: ${positive};
        `}
    ${({isNegative}) =>
        isNegative &&
        css`
            color: ${negative};
        `}
`;
export const Favorite = styled.div`
    font-size: 1.6rem;
    width: 9%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const symbolAnimationLow = keyframes`
  0% {
    background: transparent;
  }
  50% {
    background: linear-gradient(90deg, rgba(225, 77, 77, 0.15) 0%, rgba(225, 77, 77, 0.15) 100%, rgba(0, 0, 0, 0) 0%);
  }
  100% {
    background: transparent;
  }
`;
const symbolAnimationHigh = keyframes`
  0% {
    background: transparent;
  }
  50% {
    background: linear-gradient(to right, rgba(0, 227, 106, 0.15) 0%, rgba(0, 227, 106, 0.15) 100%, rgba(0, 0, 0, 0) 0%);
  }
  100% {
    background: transparent;
  }
`;
export const SymbolRowWrap = styled.div`
    display: flex;
    padding: 1.5rem 2rem;
    color: ${rowColor};
    height: 4.5rem;
    align-items: center;
    z-index: 1;
    position: relative;
    background: ${({isOdd}) => (isOdd ? firstLineColor : panelBodyBg)};
    cursor: pointer;
    &:hover {
        background: ${rowHoverBg};
        color: ${rowHoverColor};
        i {
          ${({isActive}) => !isActive && css`
            color: ${iconColorActive};
          `}          
        }
    }
    ${({isChanged, increase, isModal}) =>
        isChanged && !isModal &&
        css`
            animation: ${increase ? symbolAnimationHigh : symbolAnimationLow}
                0.7s 1;
        `}
    background: ${({isActive}) => isActive && activeRow};
    ${({isActive}) =>
        isActive &&
        css`
            color: ${activeIconColor};
            &:hover {
              background: ${activeRow};
              color: ${activeIconColor};
            }
            i {
                color: ${activeIconColor};
            }
        `}    
`;

export const SymbolTitle = styled.span`
    margin: 0 1rem;
    display: inline-block;
`;

export const ContentWrap = styled.div`
    font-size: 2.3rem;
    color: ${rowColor};
    display: flex;
    padding: 2.5rem 2rem;
    i {
      color: ${titlesColor}
    }
`;
export const ContentColumn = styled.div`
    display: table;    
`;
export const ContentRow = styled.div`
    display: table-row;    
`;
export const ContentLabel = styled.div`
    display: table-cell;
    padding-right: 2rem;
`;
export const ContentValue = styled.div`
    display: table-cell;
    ${({isPositive}) =>
        isPositive &&
        css`
            color: ${positive};
        `}
    ${({isNegative}) =>
        isNegative &&
        css`
            color: ${negative};
        `}
    i {
        font-size: 2.5rem;
    }
    .pair {
        display: inline-block;
        margin: 0 1rem;
    }
`;
