import React, {PureComponent, Fragment} from 'react';
import {Route, Switch, NavLink, withRouter, Redirect} from 'react-router-dom';

import parseTranslation from '../../../adapter/helper/parseTranslation';

import NavBar from '../../component/NavBar';
import Header from 'infrastructure/view/component/Header';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';

import ProfileSettingsLayout from './layout/ProfileSettingsLayout';
import PasswordSettingsLayout from './layout/PasswordSettingsLayout';

import Panel from '../../component/Panel/Panel';

import {connect} from 'react-redux';

function mapStateToProps(state) {
    const {isOpenMenu} = state.menu;
    return {
        titleProfileSettings: parseTranslation('profile-settings-user'),
        titlePasswordSettings: parseTranslation('password-settings-user'),
        isOpenMenu,
    };
}

class Settings extends PureComponent {
    render() {
        if (this.props.location.pathname === '/settings/') {
            return <Redirect to="/settings/profile" />;
        }

        return (
            <>
                <div
                    className={`container settings ${
                        this.props.location.pathname.split('/')[2]
                    }`}
                >
                    <NavBar isOpen={this.props.isOpenMenu} />

                    <Scrollbars>
                        <div className="wrapper">
                            <div className="content">
                                <div className="column profile-wrapper">
                                    <Panel
                                        isCenter={true}
                                        title={
                                            this.props.location.pathname ===
                                            '/settings/profile'
                                                ? this.props
                                                      .titleProfileSettings
                                                : this.props
                                                      .titlePasswordSettings
                                        }
                                    >
                                        <div className="tabs-component">
                                            <div className="tabs-component__tabs">
                                                <NavLink
                                                    className="tabs-component__tab"
                                                    to={'/settings/profile'}
                                                >
                                                    {
                                                        this.props
                                                            .titleProfileSettings
                                                    }
                                                </NavLink>

                                                <NavLink
                                                    className="tabs-component__tab"
                                                    to={'/settings/security'}
                                                >
                                                    {
                                                        this.props
                                                            .titlePasswordSettings
                                                    }
                                                </NavLink>
                                                <div className="tabs-component__slider" />
                                            </div>
                                        </div>
                                        <div
                                            className="active-content"
                                            style={{height: 'auto'}}
                                        >
                                            <Switch>
                                                <Route
                                                    path="/settings/profile"
                                                    component={
                                                        ProfileSettingsLayout
                                                    }
                                                />
                                                <Route
                                                    path="/settings/security"
                                                    component={
                                                        PasswordSettingsLayout
                                                    }
                                                />
                                            </Switch>
                                        </div>
                                    </Panel>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps)(Settings));
