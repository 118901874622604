import {GET_CHART_HISTORY, CHANGE_INTERVAL, SET_ERROR} from './actions';
import {successAction, failAction} from '../actions/actionTypes';
let questSettings = JSON.parse(localStorage.getItem('guestSettings'));
const initialState = {
    data: [],
    chartInterval: (questSettings && questSettings.chartInterval) || '1M',
};

export default function guestChart(state = initialState, action) {
    switch (action.type) {
        case successAction(GET_CHART_HISTORY): {
            const {data} = action.response;

            return {
                ...state,
                data: data.reverse(),
                error: false,
            };
        }
        case failAction(GET_CHART_HISTORY): {
            return {
                ...state,
                error: true,
            };
        }
        case SET_ERROR: {
            const settings = JSON.parse(localStorage.getItem('guestSettings'));
            delete settings.pair;
            localStorage.setItem('guestSettings', JSON.stringify(settings));
            return {
                ...state,
                error: action.value,
            };
        }
        case CHANGE_INTERVAL: {
            const {value} = action;
            questSettings = JSON.parse(localStorage.getItem('guestSettings'));
            localStorage.setItem(
                'guestSettings',
                questSettings
                    ? JSON.stringify({...questSettings, chartInterval: value})
                    : JSON.stringify({chartInterval: value})
            );
            return {
                ...state,
                chartInterval: value,
            };
        }
        default:
            return state;
    }
}
