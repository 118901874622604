import {SET_DEALING_WS, START_GUEST_DEALING_WS} from './actions';
import {
  dealingAssets,
  dealingBalances,
  dealingCancelOrder,
  dealingConfirm,
  dealingOpenOrder,
  dealingOrderBook,
  dealingUserOrder,
  SUCCESS_STATUS,
} from './constants';

const initialState = {
  [dealingAssets]: {},
  [dealingBalances]: {},
  [dealingConfirm]: [],
  [dealingOrderBook]: [],
  [dealingUserOrder]: [],
  loading: false,
};

export default function dealingWs(state = initialState, action) {
  switch (action.type) {
    case START_GUEST_DEALING_WS:
      return {
        ...state,
        loading: true,
      };

    case SET_DEALING_WS:
      const {value} = action;
      if (value.hasOwnProperty(dealingOpenOrder)) {
        return {
          ...state,
          [dealingUserOrder]: [
            ...state[dealingUserOrder],
            value[dealingOpenOrder],
          ],
          loading: false,
        };
      }
      if (
        value.hasOwnProperty(dealingCancelOrder) &&
        value[dealingCancelOrder].status === SUCCESS_STATUS
      ) {
        const {orderId} = value[dealingCancelOrder];
        return {
          ...state,
          [dealingUserOrder]: state[dealingUserOrder].filter(
            item => item.orderId !== orderId,
          ),
          loading: false,
        };
      }
      if (value.hasOwnProperty(dealingConfirm)) {
        const {price, amount, openTime, type} = value[dealingConfirm][0];

        return {
          ...state,
          [dealingConfirm]: [
            {price, amount, openTime, type},
          ],
        };
      }
      return {
        ...state,
        ...value,
        loading: false,
      };
    default:
      return state;
  }
}
