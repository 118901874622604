// @flow
import type Axios from 'axios';
import moment from 'moment';

import EventEmitter from 'events';
import type {DealingAdapterInterface} from '../../application/adapter/Dealing';
import type {DealingAccessToken} from '../../domain/entitiy/DealingAccessToken';
import type {OrderType} from '../../domain/entitiy/Order';
import type {AccessToken} from '../../domain/entitiy/AccessToken';
import {OHLC} from '../../domain/entitiy/OHLC';
import {Order} from '../../domain/entitiy/Order';
import {Asset} from '../../domain/entitiy/Asset';

import config from '../../localConfig';
import {object} from 'prop-types';

import {changePending} from '../view/scene/Dashboard/components/MarketTrades/service/actions';
import {webSocketConnected, webSocketDisconnected, webSocketError} from '../view/services/action/socket';
import {getDomain} from '../../utils';

class DealingAdapter implements DealingAdapterInterface {
    token: DealingAccessToken;
    eventEmitter: EventEmitter;
    handlers: {};
    socket: WebSocket;

    webSocketClient: Function;
    httpClient: Axios;

    constructor(httpClient: Axios, webSocketClient: WebSocket): void {
        this.httpClient = httpClient;
        this.webSocketClient = webSocketClient;

        // TODO: Pass EventEmmiter from outside
        this.eventEmitter = new EventEmitter();

        this.handlers = {
            'assets-data': this.handleAssetsData.bind(this),
        };
    }

    async connect(token: DealingAccessToken, store: {}): Promise<any> {
        return new Promise(resolve => {
            // TODO: Move all the static URLs to configuration
            this.socket = new this.webSocketClient(
                `${getDomain(config.wsUrl)}/dealing/${token}`
            );

            // this.socket.onopen = resolve;
            this.socket.onopen = () => {
                // setTimeout(() => this.simulateBot(), 500);
                // this.simulateBot();
                store.dispatch(webSocketConnected());
                resolve();
            };

            // TODO: Set socket instance to NULL on this event
            this.socket.onclose = (event) => {
                if (event.code === 4000) {
                    store.dispatch(webSocketDisconnected());
                    this.eventEmitter.emit('dealing-socket-onclose');
                }

            };

            // TODO: Add WS-event type
            this.socket.onmessage = event => {
                try {
                    const data = JSON.parse(event.data);

                    const [eventName, eventData] = data;

                    if (eventName in this.handlers) {
                        this.handlers[eventName](eventData);

                        return;
                    }

                    // TODO: Convert switch to adapter methods
                    switch (eventName) {
                        case 'confirm-deals': {
                            const orders = eventData.map(rawOrder => {
                                let orderType: OrderType = 'Buy';
                                if (rawOrder.type === 1) {
                                    orderType = 'Sell';
                                }

                                const openingTime = moment.unix(
                                    rawOrder.openTime
                                );

                                const order = new Order({
                                    id: rawOrder.orderId,
                                    symbol: rawOrder.asset,
                                    type: orderType,
                                    openingTime,
                                    price: +rawOrder.price,
                                    amount: +rawOrder.amount,
                                });
                                return order;
                            });
                            this.eventEmitter.emit('confirm-deals', orders);
                            break;
                        }

                        case 'user-orders': {
                            const orders = eventData.map(rawOrder => {
                                let orderType: OrderType = 'Buy';
                                if (rawOrder.type === 1) {
                                    orderType = 'Sell';
                                }

                                const openingTime = moment.unix(
                                    rawOrder.openTime
                                );

                                const order = new Order({
                                    id: rawOrder.orderId,
                                    symbol: rawOrder.asset,
                                    type: orderType,
                                    openingTime,
                                    price: +rawOrder.price,
                                    commission: +rawOrder.commissions,
                                    amount: +rawOrder.amount,
                                });

                                return order;
                            });

                            this.eventEmitter.emit('user-orders', orders);
                            break;
                        }

                        case 'open-order': {
                            if (eventData.status === 'SUCCESS') {
                                let orderType: OrderType = 'Buy';
                                if (eventData.type === 1) {
                                    orderType = 'Sell';
                                }
                                const isMarket = eventData.exType === 2;
                                const openingTime = moment.unix(
                                    eventData.openTime
                                );

                                const order = new Order({
                                    id: eventData.orderId,
                                    symbol: eventData.asset,
                                    type: orderType,
                                    isMarket,
                                    openingTime,
                                    price: +eventData.price,
                                    amount: +eventData.amount,
                                });
                                this.eventEmitter.emit('open-order', order);
                            } else {
                                this.eventEmitter.emit(
                                    'open-order-error',
                                    eventData
                                );
                            }
                            break;
                        }

                        case 'cancel-order': {
                            if (eventData.status === 'SUCCESS') {
                                this.eventEmitter.emit(
                                    'cancel-order',
                                    eventData.orderId
                                );
                            }

                            if (eventData.status === 'ERROR') {
                                this.eventEmitter.emit(
                                    'cancel-order-error',
                                    eventData
                                );
                            }
                            break;
                        }

                        case 'confirm-order': {
                            if (eventData.status === 'SUCCESS') {
                                let orderType: OrderType = 'Buy';
                                if (eventData.type === 1) {
                                    orderType = 'Sell';
                                }
                                const openingTime = moment.unix(
                                    eventData.openTime
                                );

                                const order = new Order({
                                    id: eventData.orderId,
                                    symbol: eventData.asset,
                                    type: orderType,
                                    openingTime,
                                    price: +eventData.price,
                                    amount: +eventData.amount,
                                });
                                this.eventEmitter.emit('confirm-order', order);
                            }
                            break;
                        }

                        case 'order-book': {
                            const sellOrders = (eventData['1'] || []).map(
                                rawOrder => {
                                    const orderType: OrderType = 'Sell';

                                    const order = new Order({
                                        type: orderType,
                                        count: rawOrder.count,
                                        price: +rawOrder.price,
                                        amount: +rawOrder.amount,
                                    });

                                    return order;
                                }
                            );

                            const buyOrders = (eventData['2'] || []).map(
                                rawOrder => {
                                    const orderType: OrderType = 'Buy';

                                    const order = new Order({
                                        type: orderType,
                                        count: rawOrder.count,
                                        price: +rawOrder.price,
                                        amount: +rawOrder.amount,
                                    });

                                    return order;
                                }
                            );

                            this.eventEmitter.emit('order-book', {
                                sell: sellOrders,
                                buy: buyOrders,
                                symbol: eventData.asset,
                            });
                            break;
                        }

                        case 'balances': {
                            this.eventEmitter.emit('balances', eventData);
                            break;
                        }

                        default: {
                            this.eventEmitter.emit(eventName, eventData);
                            break;
                        }
                    }
                } catch (error) {
                    store.dispatch(webSocketError(error));

                }
            };

            this.socket.onerror = error => {
                store.dispatch(webSocketError(error));
            };
        });
    }

    simulateBot() {
        setInterval(() => {
            const orderType = ['Buy', 'Sell'][Math.floor(Math.random() * 2)];
            const openingTime = moment();

            const order = new Order({
                id: '0',
                symbol: 'BTC/USD',
                type: orderType,
                openingTime,
                price: Math.random() * 20 + 60,
                amount: Math.random() * 0.999 + 0.1,
            });

            this.eventEmitter.emit('confirm-deals', [order]);
        }, 2000);
    }

    disconnect(): void {
        this.socket.close();
    }

    on(eventName: string, handler: Function) {
        this.eventEmitter.on(eventName, handler);
    }

    getOpenedOrders(): void {
        const message = ['user-orders', []];
        const messageSerialized = JSON.stringify(message);
        this.socket.send(messageSerialized);
    }

    async getClosedDealsHistory(
        asset: string,
        page: number,
        store: object
    ): Promise<any> {
        try {
            const {data} = await this.httpClient({
                method: 'POST',
                url: `${getDomain(config.apiUrl)}/v1/dealing/asset-closed-deals?asset=${asset}&page=${page}`,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'cache-control': 'no-cache',
                },
            });
            store.dispatch(changePending(false));
            const deals = data.map(rawDeal => {
                let orderType: OrderType = 'Buy';
                if (rawDeal.type === 1) {
                    orderType = 'Sell';
                }

                const openingTime = moment.unix(rawDeal.time);

                const deal = new Order({
                    symbol: asset,
                    type: orderType,
                    openingTime,
                    price: +rawDeal.price,
                    amount: +rawDeal.amount,
                });

                return deal;
            });
            // this.eventEmitter.emit('closed-deals', deals);
            return deals;
        } catch (error) {
            throw error;
        }
    }

    async getUserClosedDealsHistory(
        asset: string,
        page: number,
        token: AccessToken
    ): Promise<any> {
        try {
            const {data} = await this.httpClient({
                method: 'POST',
                url: `${getDomain(config.apiUrl)}/v1/dealing/user-closed-deals?page=${page}`,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    authorization: `Bearer ${token}`,
                    'cache-control': 'no-cache',
                },
            });

            const history = data.map(rawDeal => {
                let orderType: OrderType = 'Buy';
                if (rawDeal.type === '1') {
                    orderType = 'Sell';
                }

                const openingTime = moment.unix(rawDeal.openTime);
                const closTime = moment.unix(rawDeal.closeTime);

                const order = new Order({
                    amount: rawDeal.amount,
                    commission: rawDeal.commissions,
                    openingTime,
                    closTime,
                    id: rawDeal.orderId,
                    price: rawDeal.price,
                    type: orderType,
                    symbol: rawDeal.asset,
                    closePrice: rawDeal.closePrice,
                });
                delete order.total;

                return order;
            });

            // this.eventEmitter.emit('user-closed-deals', history);

            return history;
        } catch (error) {
            throw error;
        }
    }
    async getChartDataHistory(
        token: AccessToken,
        asset: string,
        interval: string,
        limit: number,
        offset: number | null
    ): Promise<any> {
        try {
            const offsetParameter = offset ? `&offset=${offset}` : '';
            const url = `${getDomain(config.apiUrl)}/v1/dealing/history?asset=${asset}&interval=${interval.toLowerCase()}&limit=${limit}${offsetParameter}`;

            const {data} = await this.httpClient({
                method: 'POST',
                url,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'cache-control': 'no-cache',
                    authorization: `Bearer ${token}`,
                },
            });

            return {
                data: data.reverse().map(ohlc => new OHLC(ohlc)),
                symbol: asset,
                interval,
            };
        } catch (error) {
            throw error;
        }
    }

    openOrder(
        asset: string,
        price: number,
        amount: number,
        exType: number,
        type: number
    ): void {
        const message = [
            'open-order',
            {
                asset,
                price,
                amount,
                exType,
                type,
            },
        ];
        const messageSerialized = JSON.stringify(message);
        this.socket.send(messageSerialized);
    }

    closeOrder(asset: string, type: number, orderId: string): void {
        const message = [
            'cancel-order',
            {
                asset,
                type,
                orderId,
            },
        ];
        const messageSerialized = JSON.stringify(message);
        this.socket.send(messageSerialized);
    }

    userDeals(asset: string): Promise<Order[]> {
        const message = [
            'user-deals',
            {
                asset,
            },
        ];

        const messageSerialized = JSON.stringify(message);

        // TODO: move `user-deals` server message handler to socket subscription
        const promise = new Promise(
            // TODO: Define where to throw `ERROR` from user-deals message
            resolve => {
                this.eventEmitter.once('user-deals', rawOrders => {
                    const orders = rawOrders.map(rawOrder => {
                        let orderType: OrderType = 'Buy';
                        if (rawOrder.type === 1) {
                            orderType = 'Sell';
                        }

                        const openingTime = moment.unix(rawOrder.openTime);

                        const order = new Order({
                            id: rawOrder.orderId,
                            symbol: rawOrder.asset,
                            type: orderType,
                            openingTime,
                            price: +rawOrder.price,
                            amount: +rawOrder.amount,
                        });

                        return order;
                    });

                    resolve(orders);
                });
            }
        );
        this.socket.send(messageSerialized);

        return promise;
    }

    // Subscribe to DOM by asset
    subscribeToAsset(asset: string): void {
        const message = [
            'subscribe',
            {
                asset,
            },
        ];

        const messageSerialized = JSON.stringify(message);

        this.socket.send(messageSerialized);
    }

    unsubscribeFromAsset(asset: string): void {
        const message = [
            'unsubscribe',
            {
                asset,
            },
        ];

        const messageSerialized = JSON.stringify(message);

        this.socket.send(messageSerialized);
    }

    handleAssetsData(data) {
        const assets = Object.keys(data).map(key => {
            return new Asset({
                id: key,
                ...data[key],
            });
        });

        this.eventEmitter.emit('assets-data', assets);
    }
}

export default DealingAdapter;
