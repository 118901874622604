// @flow
import React, {PureComponent, type ReactNode} from 'react';
import classnames from 'classnames';

import './dropdown.scss';

type PropTypes = {
    children: ReactNode,
    btn?: ReactNode,
    overlay?: ReactNode,
    className?: string,
    tooltip?: ReactNode,
};

type StateTypes = {
    isMenuVisible: boolean,
};

class Dropdown extends PureComponent<PropTypes, StateTypes> {
    menuRef = React.createRef();

    state = {
        isMenuVisible: false,
        visibleTooltip: true,
    };

    componentDidUpdate(prevProps) {
        const {close} = this.props;

        if (close !== prevProps.close && close === true) {
            this.setState({isMenuVisible: false});
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.handleClick, false);
    }

    toggleMenu = () => {
        this.setState(
            state => {
                return {
                    isMenuVisible: !state.isMenuVisible,
                    visibleTooltip: !state.isMenuVisible,
                };
            },
            () => {
                if (!this.state.isMenuVisible) {
                    setTimeout(
                        () => this.setState({visibleTooltip: true}),
                        1000
                    );
                }
                document.addEventListener('click', this.handleClick, false);
            }
        );
    };

    handleClick = e => {
        const {isInsideClick} = this.props;

        if (
            isInsideClick &&
            this.menuRef.current &&
            (this.menuRef.current.contains(e.target) ||
                (e.target.className &&
                    typeof e.target.className === 'string' &&
                    e.target.className.includes('select-input__option')))
        ) {
            // inside click if has prop isInsideClick
            this.setState(state => {
                return {isMenuVisible: true};
            });
            return;
        } else {
            // outside click
            this.setState(
                state => {
                    return {isMenuVisible: false};
                },
                () =>
                    document.removeEventListener(
                        'click',
                        this.handleClick,
                        false
                    )
            );
        }
    };

    render() {
        const {isMenuVisible, visibleTooltip} = this.state;
        const {children, btn, overlay, tooltip, className, isNavBar} = this.props;

        const classes = classnames('wrapper-dropdown', {
            [className]: className,
            active: isMenuVisible,
            'nav-bar-language': isNavBar,
    });

        const classesBtn = classnames({
            [btn.props.className]: btn.props.className,
            active: isMenuVisible,
        });

        return (
            <div className={classes}>
                {btn &&
                    React.cloneElement(btn, {
                        onClick: this.toggleMenu,
                        className: classesBtn,
                    })}
                {visibleTooltip &&
                    tooltip &&
                    React.cloneElement(tooltip, {
                        className: isMenuVisible ? 'hidden tooltip' : 'tooltip',
                    })}
                {isMenuVisible &&
                    React.cloneElement(children, {
                        overlay,
                        ref: this.menuRef,
                    })}
            </div>
        );
    }
}
export default Dropdown;
