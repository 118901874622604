export const ActionTypes = {
    getForm: 'registration-scene.form.get',
    submitForm: 'registration-scene.form.submit',
    confirmRegistration: 'registration-scene.confirmation.send',
    setConfirmationResult: 'registration-scene.confirmation.set'
};

export function getForm() {
    return {
        type: ActionTypes.getForm
    };
}

export function submitForm() {
    return {
        type: ActionTypes.submitForm
    };
}

export function confirmRegistration(username, token) {
    return {
        type: ActionTypes.confirmRegistration,
        username,
        token
    };
}

export function setConfirmationResult(isConfirmed) {
    return {
        type: ActionTypes.setConfirmationResult,
        isConfirmed
    };
}
