import { ActionTypes } from './actions';

const initialState= {
    isConfirmed: ''
};

function recoveryReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.setConfirmationResult:
            return {
                ...state,
                isConfirmed: action.isConfirmed
            };

        default:
            return state;
    }
}

export default recoveryReducer;
