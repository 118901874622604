import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import parseTranslation from '../../../adapter/helper/parseTranslation';

import {confirmRegistration} from './service/actions';
import logo from '../Dashboard/components/News/logo.svg';


import config from '../../../../localConfig';

function mapStateToProps(state) {
    const {isConfirmed} = state.registration;

    return {
        isConfirmed,
        titleConfirmation: parseTranslation('registration-confirmation'),
        titleFailure: parseTranslation('failure-title'),
        loginTitle: parseTranslation('login-btn'),
        text1Confirmation: parseTranslation('confirmation-text-1'),
        text2Confirmation: parseTranslation('confirmation-text-2'),
        textFailure: parseTranslation('failure-text'),
        linkFailure: parseTranslation('failure-link')
    };
}

const mapDispatchToProps = {
    confirmRegistration,
};

class Confirmation extends Component {
    componentDidMount() {
        const {isConfirmed, username, token} = this.props;

        if (typeof isConfirmed !== 'boolean') {
            this.props.confirmRegistration(username, token);
        }
    }

    render() {
        if (typeof this.props.isConfirmed === 'boolean') {
            return this.props.isConfirmed
                ? this.renderSuccess()
                : this.renderFailure();
        }

        return null;
    }

    renderSuccess() {
        return (
            <div className="container">
                <div className="wrapper login">
                    <div className="content registration login-img">
                        <div className="confirm-wrapper">
                            <div className="confirm-wrapper-holder">
                                <a
                                    href={`${config.landingUrl}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="logo-link"
                                >
                                    <img src={logo} alt="logo" />
                                </a>
                                <div className="confirmation-text">
                                    <h1>{this.props.titleConfirmation}</h1>
                                    <p>
                                        <span dangerouslySetInnerHTML={{__html: this.props.text1Confirmation + ' '}} />

                                        <Link to="/login">
                                            "{this.props.loginTitle}"
                                        </Link>
                                        {' ' + this.props.text2Confirmation}
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderFailure() {
        return (
            <div className="container">
                <div className="wrapper login">
                    <div className="content registration login-img">
                        <div className="confirm-wrapper confirmation-message">
                            <div className="confirm-wrapper-holder">
                                <a
                                    href={`${config.landingUrl}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="logo-link"
                                >
                                    <img src={logo} alt="logo" />
                                </a>
                                <div className="confirmation-text">
                                    <h1>{this.props.titleFailure}</h1>
                                    <p>
                                        <span dangerouslySetInnerHTML={{__html: this.props.textFailure + ' '}}/>
                                        <Link to="/registration">
                                            {this.props.linkFailure}
                                        </Link>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Confirmation);
