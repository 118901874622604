export const ActionTypes = {
    getForm: 'recovery-scene.form.get',
    submitForm: 'recovery-scene.form.submit',
    getRecoveryPasswordForm: "recoverypassword-scene.form.get",
    submitRecoveryPasswordForm: "recoverypassword-scene.form.submit",
    recoveryReducer: 'recovery-scene.form.recovery'
};

export function getForm() {
    return {
        type: ActionTypes.getForm
    };
}

export function submitForm() {
    return {
        type: ActionTypes.submitForm
    };
}

export function getRecoveryPasswordForm() {
    return {
        type: ActionTypes.getRecoveryPasswordForm
    };
}

export function submitRecoveryPasswordForm() {
    return {
        type: ActionTypes.submitRecoveryPasswordForm
    };
}

export function recoveryReducer() {
    return {
        type: ActionTypes.recoveryReducer
    };
}
