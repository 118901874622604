import {ActionTypes} from '../action/DXChart';

const initialState = {
    isPending: true,
    data: [],
    error: null,
    isNoDataErrorChart: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.chartHistorySuccess:
            return {
                ...state,
                isPending: false,
                data: action.payload,
                error: null,
            };

        case ActionTypes.chartHistoryIsPending:
            return {
                isPending: true,
            };

        case ActionTypes.chartHistoryFailure:
            return {
                ...state,
                isPending: false,
                data: [],
                error: action.payload,
            };
        case ActionTypes.setNoDataErrorChart:
            return {
                ...state,
                isPending: false,
                isNoDataErrorChart: action.payload,
            };
        default:
            return state;
    }
}
