// @flow
import type { AccessToken } from '../../../domain/entitiy/AccessToken';
import type { BillingAdapterInterface as BillingAdapter }  from '../../../application/adapter/Billing';

class SubmitDepositForm {
    billingAdapter: BillingAdapter;

    constructor(billingAdapter: BillingAdapter) {
        this.billingAdapter = billingAdapter;
    }

    async invoke(token: AccessToken, formData: {} = {}, locale: string) {
        try {
            const { data } = await this.billingAdapter.sendWithdrawForm(token, formData, locale);

            return data;
        } catch (error) {
            if(error.response) {
                const { status, data } = error.response;

                if (status === 422) {
                    // const fiats = [
                    //     'USD',
                    //     'EUR',
                    //     'RUB',
                    //     'JPY',
                    //     'CNY',
                    //     'CHN'
                    // ];
                    //
                    // const currency = (
                    //     data.scheme
                    //         .find(
                    //             ({ name }) => name === 'currency'
                    //         )
                    // );
                    //
                    // const method = (
                    //     data.scheme
                    //         .find(
                    //             ({ name }) => name === 'method'
                    //         )
                    // );
                    //
                    // if (fiats.includes(currency.value)) {
                    //     delete method.options.items.crypt;
                    // } else {
                    //     delete method.options.items.bank;
                    //     delete method.options.items.card;
                    // }

                    return data;
                }
            }

            throw error;
        }
    }
}

export default SubmitDepositForm;
