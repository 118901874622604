import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import NavBar from '../../component/NavBar';
import Chat from 'infrastructure/view/widget/Chat';


function mapStateToProps(state) {
    const {isOpenMenu} = state.menu;

    return {
        isOpenMenu,
    };
}

class ChatScene extends Component {
    constructor(props) {
        super(props);
        this.chatScrollbarContainer = React.createRef();
        this.scrollToBottom = this.scrollToBottom.bind(this);
    }

    render() {
        return (
            <Fragment>
                <div className="container chat">
                    <NavBar isOpen={this.props.isOpenMenu} />
                    <div className="wrapper">
                        <div className="content">
                            <Chat scrollToBottom={this.scrollToBottom} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    scrollToBottom(): void {
        this.chatScrollbarContainer.current &&
            this.chatScrollbarContainer.current.scrollToBottom();
    }
}

export default connect(mapStateToProps)(ChatScene);
