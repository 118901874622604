import React from 'react';
import {DropdownWrapper, DropdownPosition} from './styled';

export default ({children, align = 'center', offsetArrowRight = 0, offsetRight = 0}) => {
    return (
        <DropdownPosition align={align} offsetRight={offsetRight}>
            <DropdownWrapper align={align} offsetArrowRight={offsetArrowRight}>{children}</DropdownWrapper>
        </DropdownPosition>
    );
};
