import colors from 'infrastructure/view/styles/common-colors/common-colors';

export default function(openT, highT, closeT, lowT, volumeT) {
    return ({
    scale: {
        auto: true,
        history: [
            {
                min: NaN,
                max: NaN,
                firstId: 0,
                lastId: 0,
            },
        ],
    },
    components: {
        chart: {
            type: 'candle',
            showCandlesBorder: true,
            areaLineWidth: 1,
            lineWidth: 1,
        },
        yAxis: {
            visible: true,
            sideLabel: false,
            valueLines: 10,
            customScale: true,
        },
        xAxis: {
            visible: true,
            dateFormats: {
                minute: {
                    format: 'HH:mm',
                },
                day: {
                    format: 'dd.MM',
                },
                weekday: {
                    format: 'EEE',
                },
                month: {
                    format: 'MMM',
                },
                year: {
                    format: 'YYYY',
                },
            },
        },
        offsets: {
            visible: false,
            top: 0,
            right: 25,
            bottom: 0,
        },
        waterMark: {
            fontFamily: 'Open Sans, sans-serif',
            titleFontSize: 70,
            titleBottomPadding: 10,
            descriptionFontSize: 21,
            descriptionBottomPadding: 25,
            timeIntervalFontSize: 28,
        },
        highLow: {},
        instrumentInfo: {
            infoTemplate: '{description}',
        },
        crossTool: {
            visible: true,
            quote:
                `${openT}: {open}<br>${highT}:2 {high}<br>${lowT}: {low}<br>${closeT}:{close}<br>${volumeT}:{volume}<p> {time}</p>`,
            volume: 'Volume: {volume}',
            study: '{title}:{value}',
        },
        studies: {
            visible: true,
            labelTemplate: '{name} ({params}): {lines}',
            lineTemplate:
                "<span style='color: {color}' class='color' data-idx='{idx}'>{title}</span>",
        },
        grid: {
            visible: true,
            width: 1,
            dash: [1, 4],
        },
        volumes: {
            visible: true,
        },
        drawings: {
            visible: true,
            controlPanelEnabled: true,
            points: {
                defaultKeyPoint: {
                    shape: "circle",
                    radius: 4,
                    fillStyle: colors.color20,
                    lineWidth: 0,
                    lineColor: colors.color20,
                    opacity: 1
                },
                activeKeyPoint: {
                    shape: "circle",
                    radius: 4,
                    fillStyle: colors.color20,
                    lineWidth: 0,
                    lineColor: colors.color20,
                    opacity: 1
                },
                defaultHoverKeyPoint: {
                    shape: "circle",
                    radius: 4,
                    fillStyle: colors.color20,
                    lineWidth: 0,
                    lineColor: colors.color20,
                    opacity: 1
                },
                activeHoverKeyPoint: {
                    shape: "circle",
                    radius: 4,
                    fillStyle: colors.color20,
                    lineWidth: 0,
                    lineColor: colors.color20,
                    opacity: 1
                },
                addingKeyPoint: {
                    shape: "circle",
                    radius: 4,
                    fillStyle: colors.color20,
                    lineWidth: 0,
                    lineColor: colors.color20,
                    opacity: 1
                }
            }
        },
        navigationMap: {
            visible: true,
            allCandlesHistory: true,
            dateFormats: {
                dayMonth: {
                    format: 'd MMM',
                },
                month: {
                    format: 'MMM',
                },
                year: {
                    format: 'YYYY',
                },
            },
        },
    },
    colors: {
        candleTheme: {
            bullColor: colors.color8,
            bearColor: colors.color11,
            noneColor: colors.color8,
            bullWickColor: colors.color8,
            bearWickColor: colors.color11,
            noneWickColor: colors.color8,
            borderOpacity: 0.5, // border opacity (wick color as base color)
        },
        barTheme: {
            bullColor: colors.color8,
            bearColor: colors.color11,
            noneColor: colors.color8,
        },
        lineTheme: {
            bullColor: colors.color8,
            bearColor: colors.color11,
            noneColor: colors.color8,
        },
        areaTheme: {
            lineColor: colors.color1,
            fillColor: colors.color24,
            startColor: colors.color24,
            stopColor: colors.color25,
        },
        chartAreaTheme: {
            backgroundColor: colors.color27,
            axisColor: colors.color14,
            gridColor: colors.color17,
            volumeLineColor: colors.color65,
            volumeFillColor: '#99999926',
            mapFillColor: colors.color1,
            mapGradientTopColor: '#ABB1C2',
            mapColor: '#ABB1C2',
            mapGradientBottomColor: '#ABB1C2',
        },
        waterMarkTheme: {
            titleColor: 'rgba(0, 0, 0, .3)',
            descriptionColor: 'rgba(0, 0, 0, .3)',
            timeIntervalColor: 'rgba(0, 0, 0, .3)',
        },
    },
    snappingToPriceIncrement: false,
    animation: {
        moveDuration: 1000,
        candleDuration: 200,
    },
    })
} ;
