import {accessTokenKey, userRegionKey} from 'settings';

export function getUserRegion() {
    const jsonData = localStorage.getItem(userRegionKey);
    let result = false;

    if (jsonData) {
        result = JSON.parse(jsonData);
    }

    return result;
}

export function getAccessToken() {

    const userRegion = getUserRegion();

    if (userRegion) {
        return userRegion.access_token
    }

    return userRegion;
}
