import {TranslationAdapterInterface} from '../../application/adapter/Translation';
import localConfig from 'localConfig';

import type {Axios} from 'axios/index';
import type {AccessToken} from '../../domain/entitiy/AccessToken';

import config from '../../localConfig';

class TranslationAdapter implements TranslationAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getLanguage(accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: 'GET',
            url: `${localConfig.authUrl}/v1/language/list`,
            withCredentials: false,

            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'cache-control': 'no-cache',
            },
        });
    }
    async getTranslation(
        accessToken: AccessToken,
        local: string
    ): Promise<any> {
        return await fetch(`${config.staticUrl}/langs/${local}.json`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
        });
    }
}

export default TranslationAdapter;
