import axios from '../../../adapter/helper/axios';

import {
    ActionType,
    setLanguage,
    errorLanguage,
    setTranslation,
    errorTranslation,
    getTranslation as getTranslationAction,
} from '../action/translation';

import TranslationAdapter from '../../../adapter/Translation';

import GetLanguage from '../../../../application/use-case/translation/GetLanguage';
import GetTranslation from '../../../../application/use-case/translation/GetTranslation';

const translationAdapter = new TranslationAdapter(axios);

const getLanguage = new GetLanguage(translationAdapter);
const getTranslation = new GetTranslation(translationAdapter);

let isDispatchedTranslation = false;

export default function(store) {
    return function(next) {
        return async function(action) {
            next(action);
            const {local} = store.getState().translation;

            if (action.type === ActionType.getLanguage) {
                try {
                    const {accessToken} = store.getState().user;

                    const res = await getLanguage.invoke(accessToken);

                    store.dispatch(setLanguage(res.data));
                } catch (e) {
                    store.dispatch(errorLanguage(e.message));
                }
            }

            if (action.type === ActionType.getTranslation) {
                try {
                    const {payload} = action;
                    const {accessToken} = store.getState().user;

                    const response = await getTranslation.invoke(
                        accessToken,
                        payload || local
                    );


                    if (response.status && response.status === 404) {
                        if (!isDispatchedTranslation) {
                            isDispatchedTranslation = true;
                            store.dispatch(getTranslationAction('en-US'));
                        }
                    } else {
                        const translation = await response.json();

                        localStorage.setItem('translation', JSON.stringify(translation));
                        localStorage.setItem('locale', payload);

                        store.dispatch(setTranslation(translation));
                    }
                } catch (e) {
                    store.dispatch(errorTranslation(e.message));
                }
            }
        };
    };
}
