import React, {PureComponent} from 'react';
import {TimeWrapper} from './styled';

class TimeDisplay extends PureComponent {
    state = {
        time: '',
    };

    updateTime() {
        return new Date()
            .toString()
            .split(' ')
            .splice(4, 1)
            .join(' ');
    }

    componentDidMount(): void {
        setInterval(() => this.setState({time: this.updateTime()}), 1000);
        this.setState({time: this.updateTime()});
    }

    render() {
        return (
                <TimeWrapper>{this.state.time}</TimeWrapper>
        );
    }
}

export default TimeDisplay;
