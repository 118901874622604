import {ActionTypes} from '../action/user';

const initialState = {
    accessToken: '',
    balances: {},
    info: {
        confirmed_at: 0,
        created_at: 0,
        email: '',
        avatar: '',
        avatar_url: '',
        last_login_at: null,
        updated_at: 0,
        username: '',
        first_name: '',
        last_name: '',
        absoluteAuthTimeout: null,
        authTimeout: null,
        errorUserData: null,
    },
    userRegion: null,
    errorSubscribeToSymbol: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.INIT_AUTH:
            return {
                ...state,
                accessToken: action.accessToken,
            };
        case ActionTypes.SET_SITE_PAIR:
            return {
                ...state,
                sitePair: action.value,
            };

        case ActionTypes.SET_USER_REGION:
            return {
                ...state,
                userRegion: action.userRegion,
            };

        case ActionTypes.accessToken:
            return {
                ...state,
                accessToken: action.accessToken,
            };

        case ActionTypes.dealingToken:
            return {
                ...state,
                dealingToken: action.dealingToken,
            };

        case ActionTypes.setUserData:
            return {
                ...state,
                info: action.data.info,
            };

        case ActionTypes.setBalances:
            return {
                ...state,
                balances: action.balances,
            };

        case ActionTypes.errorUserData:
            return {
                ...state,
                errorUserData: action.payload,
            };

        case ActionTypes.errorSubscribeToSymbol:
            return {
                ...state,
                errorSubscribeToSymbol: action.payload,
            };

        case ActionTypes.LOGOUT:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}
