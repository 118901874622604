import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {Waypoint} from 'react-waypoint';

import {fetchMarketTradesHistory as fetchMarketTradesHistoryAction} from './service/actions';
import {setActiveView as setActiveViewAction} from '../../service/actions';
import {open as openSymbolModal} from 'infrastructure/view/scene/Dashboard/components/Header/component/SymbolsModal/service/actions';

import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Panel from '../../../../component/Panel/Panel';
import CurrencyPair from 'infrastructure/view/component/SymbolButton';
import FormattedPrice from '../../../../component/FormattedPrice/FormattedPrice';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';

function mapStateToProps(state) {
    const {marketTrades} = state;
    const {selectedSymbol} = state.settings;

    return {
        selectedSymbol,
        orders: marketTrades.deals,
        page: marketTrades.page,
        shouldLoadHistory: marketTrades.shouldLoadHistory,
        title: parseTranslation('label-market-trades'),
        titleTableOpenTime: parseTranslation('label-open-time'),
        titleTableOpenDate: parseTranslation('label-open-date'),
        titleTablePrice: parseTranslation('label-price'),
        titleTableAmount: parseTranslation('label-amount'),
        loading: parseTranslation('label-market-trades-loading'),
    };
}

function mapDispatchToProps(dispatch) {
    function setActiveView() {
        dispatch(setActiveViewAction(null));
    }
    function fetchMarketTradesHistory(page) {
        dispatch(fetchMarketTradesHistoryAction(page));
    }
    function openSymbolModalAction() {
        dispatch(openSymbolModal());
    }

    return {
        setActiveView,
        fetchMarketTradesHistory,
        openSymbolModalAction,
    };
}

class Entries extends Component {
    render() {
        const {openingTime, price, amount, type} = this.props;

        return (
            <tr className="table-row expand">
                <td>{openingTime.format('HH:mm:ss')}</td>
                <td>{openingTime.format('DD.MM.YYYY')}</td>
                <td className={`cell-price-${type.toLowerCase()}`}>
                    <i
                        className={classnames({
                            'icon-single-up-pointing-angle': type === 'Buy',
                            'icon-single-down-pointing-angle': type === 'Sell',
                        })}
                    />
                    <FormattedPrice price={price} />
                </td>
                <td>
                    <FormattedPrice price={amount} />
                </td>
            </tr>
        );
    }
}

class MarketTradesExpanded extends Component {
    title = 'MARKET TRADES';

    render() {
        const {
            orders,
            page,
            shouldLoadHistory,
            fetchMarketTradesHistory,
            selectedSymbol,
            openSymbolModalAction,
        } = this.props;
        const entries = orders.map(el => <Entries {...el} />);

        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-market-trades" />
            </button>
        );

        return (
            <Panel
                icon={icon}
                title={this.props.title}
                closable
                closableHandler={this.props.setActiveView}
            >
                <Fragment>
                    <div className="currency-pair-modal">
                        <CurrencyPair
                            firstCurrency={selectedSymbol.numerator}
                            secondCurrency={selectedSymbol.denominator}
                            size="medium"
                            onClick={openSymbolModalAction}
                        />
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            top: '5rem',
                            zIndex: '1',
                        }}
                    >
                        {this.renderTableHead()}
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            top: '12rem',
                            bottom: '1rem',
                            left: 0,
                            right: 0,
                            display: 'flex',
                        }}
                    >
                        <Scrollbars>
                            <table className="history-table market-table">
                                {entries}
                            </table>

                            {shouldLoadHistory && (
                                <Waypoint
                                    key={entries.length}
                                    onEnter={() =>
                                        fetchMarketTradesHistory(page)
                                    }
                                >
                                    <div
                                        style={{
                                            height: '5rem',
                                            textAlign: 'center',
                                            width: '100%',
                                            fontSize: '1.8rem',
                                            color: '#999999',
                                        }}
                                    >
                                        {this.props.loading}
                                    </div>
                                </Waypoint>
                            )}
                        </Scrollbars>
                    </div>
                </Fragment>
            </Panel>
        );
    }

    renderTableHead() {
        return (
            <table>
                <thead>
                    <tr style={{backgroundColor: 'transparent'}}>
                        <th className="sticky-header">
                            {this.props.titleTableOpenTime}
                        </th>
                        <th className="sticky-header">
                            {this.props.titleTableOpenDate}
                        </th>
                        <th className="sticky-header">
                            {this.props.titleTablePrice}
                        </th>
                        <th className="sticky-header">
                            {this.props.titleTableAmount}
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }
}

const ConnectedMarketTradesExpanded = connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketTradesExpanded);
export default ConnectedMarketTradesExpanded;
