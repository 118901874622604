export const ActionTypes = {
    setActiveCharts: 'dashboard-scene.chart-component.active-charts.set',
    addOrdersToChart: 'dashboard-scene.chart-component.add-order',
    fetchChartDataHistory: 'dashboard-scene.chart-component.history.fetch',
    addChartDataHistory: 'dashboard-scene.chart-component.history.add',
    clearCharts: 'dashboard-scene.chart-component.clear-charts',
    setSelectedSymbolForChart: 'dashboard-scene.chart-component.selected-symbol.set',
    setChartInterval: 'dashboard-scene.chart-component.chart-interval.set',
    setChartType: 'dashboard-scene.chart-component.chart-type.set',
    setChartTheme: 'dashboard-scene.chart-component.chart-theme.set',
    switchChartView: 'dashboard-scene.chart-component.switch.view',
    addNewTickChartTV: 'dashboard-scene.TV-component.add.new-tick.chartTV',
    cleanupNewTick: 'cleanup.new-tick',
    setErrorChart: "SET_ERROR_CHART",
};

export const chartView = {
    tradingView: 'tradingView',
    custom: 'custom',
};

export function setActiveCharts(chartIds) {
    return {
        type: ActionTypes.setActiveCharts,
        chartIds
    };
}

export function addOrdersToChart(orders, chartInterval) {
    return {
        type: ActionTypes.addOrdersToChart,
        orders,
        chartInterval,
    };
}

export function fetchChartDataHistory(id, limit) {
    return {
        type: ActionTypes.fetchChartDataHistory,
        id,
        limit
    };
}

export function addChartDataHistory(id, history) {
    return {
        type: ActionTypes.addChartDataHistory,
        id,
        history
    };
}

export function clearCharts(ids) {
    return {
        type: ActionTypes.clearCharts,
        ids
    };
}

export function setSelectedSymbolForChart(id, selectedSymbol) {
    return {
        type: ActionTypes.setSelectedSymbolForChart,
        id,
        selectedSymbol
    };
}

export function setChartInterval(id, interval) {
    return {
        type: ActionTypes.setChartInterval,
        id,
        interval
    };
}

export function setChartType(id, chartType) {
    return {
        type: ActionTypes.setChartType,
        id,
        chartType
    };
}

export function setChartTheme(theme) {
    return {
        type: ActionTypes.setChartTheme,
        theme
    };
}

export function switchChartView(payload) {
    return {
        type: ActionTypes.switchChartView,
        payload,
    };
}

export function addNewTickChartTV(payload) {
    return {
        type: ActionTypes.addNewTickChartTV,
        payload,
    };
}

export function cleanupNewTick() {
    return {
        type: ActionTypes.cleanupNewTick,
    };
}

export function setErrorChart(payload){
    return {
        type: ActionTypes.setErrorChart,
        payload,
    };
}
