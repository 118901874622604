import React, {Component} from 'react';
import {connect} from 'react-redux';

import type {Notification} from './NotificationItem';
import {closePopupNotification} from '../../../../../../services/action/notification';
import NotificationItem from './NotificationItem';

type PropsType = {
    notifications: Array<Notification>,
    closePopupNotification: () => mixed,
};

class NotificationsContainer extends Component<PropsType> {
    render() {
        const {data, closePopupNotification} = this.props;

        return (
            <div className="notifications-container">
                {!!data && !!data.length && data.map(
                    notification =>
                        notification.isVisible && (
                            <NotificationItem
                                key={notification.id}
                                notification={notification}
                                close={closePopupNotification}
                            />
                        )
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {data, error} = state.notifications;

    return {
        data,
        error,
    };
}

const mapDispatchToProps = {
    closePopupNotification,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationsContainer);
