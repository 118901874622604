import axios from '../../../adapter/helper/axios';

import {
    ActionTypes,
    setConfirmationResult,
} from '../../scene/Registration/service/actions';
import {errorForm, setForm, updateForm} from '../action/forms';

import RegistrationFormAdapter from '../../../adapter/RegistrationForm';
import GetRegistrationForm from '../../../../application/use-case/registration/GetRegistrationForm';
import SubmitRegistrationForm from '../../../../application/use-case/registration/SubmitRegistrationForm';
import ConfirmRegistration from '../../../../application/use-case/registration/ConfirmRegistration';

const registrationFormAdapter = new RegistrationFormAdapter(axios);
const getRegistrationForm = new GetRegistrationForm(registrationFormAdapter);
const submitRegistrationForm = new SubmitRegistrationForm(
    registrationFormAdapter
);
const confirmRegistration = new ConfirmRegistration(registrationFormAdapter);

export default function(store) {
    return function(next) {
        return async function(action) {
            const local = localStorage.getItem('locale') || store.getState().translation.local;

            if (action.type === ActionTypes.getForm) {
                try {
                    const { data } = await getRegistrationForm.invoke(local);

                    const reducedData = {
                        ...data,
                        scheme: (
                            data.scheme
                                .reduce(
                                    (result, entry) => {
                                        if (entry.name === 'country_id') {
                                            result.push({
                                                ...entry,
                                                value: 0
                                            });
                                            return result;
                                        }

                                        if (entry.name === 'gender') {
                                            result.push({
                                                ...entry,
                                                value: 1
                                            });
                                            return result;
                                        }

                                        if (entry.name === 'callBackUrl') {
                                            result.push({
                                                ...entry,
                                                value: `${window.location.origin}/register/confirmation/${entry.value}`
                                            });
                                            return result;
                                        }

                                        result.push(entry);
                                        return result;
                                    },
                                    []
                                )
                        )
                    };

                    store.dispatch(setForm(reducedData));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionTypes.submitForm) {
                const {scheme} = store.getState().forms['RegistrationForm'];

                const formData = Object.values(scheme).reduce(
                    (result, schemeData) => {
                        result[schemeData.name] = schemeData.value;
                        return result;
                    },
                    {}
                );


                try {
                    const data = await submitRegistrationForm.invoke(
                        formData,
                        local
                    );

                    store.dispatch(setForm(data));
                    store.dispatch(updateForm('RegistrationForm', 'captcha', null));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionTypes.confirmRegistration) {
                const {username, token} = action;
                const isConfirmed = await confirmRegistration.invoke(
                    username,
                    token
                );

                store.dispatch(setConfirmationResult(isConfirmed));
            }

            next(action);
        };
    };
}
