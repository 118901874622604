import {takeEvery, fork} from 'redux-saga/effects';
import {CALL_API} from '../actions/actionTypes';
import watchApi from './api';

import wsDealing from '../webSockets/wsDealing';
import dashboard from '../GuestDashboard/saga';
import currencyPair from '../CurrencyPairContainer/saga';

function* watchApiRoot(action) {
  yield takeEvery(
    action => action.type.endsWith(CALL_API) && action.apiCall,
    watchApi,
  );
}

export default function* root() {
  yield fork(watchApiRoot);
  yield fork(wsDealing);
  yield fork(dashboard);
  yield fork(currencyPair);
}
