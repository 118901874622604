import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';

import Panel from '../../../../component/Panel/Panel';
import DateDisplay from '../../../../../view/component/DateTime/Date';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';

import Preloader from 'infrastructure/view/component/Preloader';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';
import {setActiveView as setActiveViewAction} from '../../service/actions';
import Error from '../../../../component/Error';
import ErrorContainer from "../../../../component/Error/ErrorContainer";

function mapStateToProps(state) {
    const {isPending, data, error} = state.news;

    return {
        isPending,
        data,
        error,
        title: parseTranslation('label-news'),
    };
}

function mapDispatchToProps(dispatch) {
    function setActiveView() {
        dispatch(setActiveViewAction('news'));
    }

    return {
        setActiveView,
    };
}

class News extends Component {
    title = 'NEWS';

    render() {
        const {isPending, data, error} = this.props;

        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-news" />
            </button>
        );

        return (
            <Panel
                icon={icon}
                title={this.props.title}
                expandable
                expandHandler={this.props.setActiveView}
                className="panel_news"
            >
                <Styles>
                    {isPending ? (
                        <Preloader />
                    ) : error ? (
                        <ErrorContainer className="news-error" />
                    ) : (
                        <>
                            <div className="date-news-title">
                                <DateDisplay />
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '5rem',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    display: 'flex',
                                }}
                            >
                                <Scrollbars>
                                    {data.length ? (
                                        <div className="news">
                                            {data.map(
                                                (
                                                    {
                                                        link,
                                                        date,
                                                        title,
                                                        contentSnippet,
                                                    },
                                                    index
                                                ) => (
                                                    <div
                                                        className="news-item"
                                                        key={index}
                                                    >
                                                        <div>
                                                            <a
                                                                key={link}
                                                                href={link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <span className="time">
                                                                    {date.format(
                                                                        'HH:mm:ss'
                                                                    )}
                                                                </span>
                                                                &nbsp;
                                                                <span className="title">
                                                                    {title.slice(
                                                                        0,
                                                                        35
                                                                    ) + '...'}
                                                                </span>
                                                            </a>
                                                            <br />
                                                            <span className="description">
                                                                {/*{ contentSnippet.split('.')[0] + '...' }*/}
                                                                {contentSnippet.slice(
                                                                    0,
                                                                    130
                                                                ) + '...'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {/*<img src={logoBg} alt="LogoNews" />*/}
                                        </div>
                                    )}
                                </Scrollbars>
                            </div>
                        </>
                    )}
                </Styles>
            </Panel>
        );
    }
}

const Styles = styled.div`
    height: 100%;

    .news-error {
        position: absolute;
        top: 50%;
        transform:translate(0, -50%); 
    }
`;

const ConnectedNews = connect(
    mapStateToProps,
    mapDispatchToProps
)(News);
export default ConnectedNews;
