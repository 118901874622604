import {GET_CLOSED_DEALS} from './actions';
import {successAction} from '../actions/actionTypes';
import {instance} from '../saga/api';

const initialState = {
    history: [],
};

export default function marketTrades(state = initialState, action) {
    switch (action.type) {
        case successAction(GET_CLOSED_DEALS): {
            const {data} = action.response;

            return {
                ...state,
                history: data,
            };
        }
        default:
            return state;
    }
}
