import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';

export const HeaderWrapper = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.theme.color15};
    height: 14.666rem;
    z-index: 99;

    .app-header {
        &__symbols {
            margin-left: 30rem;
        }
    }

    .icons-menu {
        .new__symbol-button,
        .new--button-link {
            margin-right: 2rem;
        }
    }
`;

export const Logo = styled.img`
    height: 7.5rem;
    margin-left: 12.8333rem;
`;

export const IconButton = styled.i`
    padding: 2rem;
    margin: 2rem;
    color: ${props => props.theme.color65};
    font-size: 4rem;
    cursor: pointer;
    position: relative;
    display: inline-block;

    &.active {
        background: ${props => props.theme.color1};
        color: ${props => props.theme.color20};
    }

    &:hover {
        color: ${props => props.theme.color20};
    }

    ${({isNew}) =>
        isNew &&
        css`
            &::after {
                content: '';
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                background: ${props => props.theme.color67};
                position: absolute;
                top: 1rem;
                right: 1rem;
            }
        `}
`;

export const DropdownItem = styled.div`
    height: 8rem;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    z-index: 1001;
    color: ${props => props.theme.color14};
    padding: 0 2rem;
    &:hover {
        background: #E5E5E5;
        color: ${props => props.theme.color1};
        a {
            color: ${props => props.theme.color1};
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: ${props => props.theme.color14};
    }
`;

export const Avatar = styled.img`
    width: ${({size}) =>
        (size === 'small' && '8rem') ||
        (size === 'medium' && '11.666rem') ||
        (size === 'large' && '15rem') ||
        '8.4rem'};
    height: ${({size}) =>
        (size === 'small' && '8rem') ||
        (size === 'medium' && '11.666rem') ||
        (size === 'large' && '15rem') ||
        '8.4rem'};
`;
export const NoImage = styled.div`
    min-width: ${({size}) =>
        (size === 'small' && '8rem') ||
        (size === 'medium' && '11.666rem') ||
        (size === 'large' && '15rem') ||
        '8rem'};
    height: ${({size}) =>
        (size === 'small' && '8rem') ||
        (size === 'medium' && '11.666rem') ||
        (size === 'large' && '15rem') ||
        '8rem'};    
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.color65};
    font-size: 4rem;
    font-size: ${({headerAvatar}) => (headerAvatar ? '3rem' : '4rem')}
    flex-grow: 1;
    color: ${props => props.theme.color23};
`;

export const UserBlock = styled.div`
    margin-right: 2rem;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2rem 0;
`;

export const UserBlockArrow = styled.div`
    margin-left: 2rem;
    color: ${props => props.theme.color23};
    font-size: 2.666rem;
`;

export const UserBlockContent = styled.div`
    display: flex;
    align-items: stretch;
    font-size: 2rem;
    margin: 4rem;
    color: ${props => props.theme.color14};
`;
export const UserInfos = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 4rem;
    margin-left: 2rem;
`;

export const RegistrationInfo = styled.div`
    color: ${props => props.theme.color14};
    white-space: nowrap;
`;

export const UserControls = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: nowrap;
    color: ${props => props.theme.color14};
    a {
        color: ${props => props.theme.color14};
    }
`;

export const Link = styled(NavLink)`
    display: flex;
    align-items: center;
    &:hover {
        color: ${props => props.theme.color1};
    }
`;

export const LinkIcon = styled.i`
    font-size: 2.5rem;
    margin-right: 1rem;
    color: ${props => props.theme.color14};
`;

export const UserButton = styled.div`
    cursor: pointer;
    padding-bottom: 0;
    border-bottom: none;
    display: flex;
    align-items: center;
    &:hover {
        color: ${props => props.theme.color1};
    }
`;
