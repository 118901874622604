import React, {useState, memo} from 'react';

import Wrapper from './Wrapper';
import DateDisplay from '../../DateTime/Date';
import TimeDisplay from '../../DateTime/Time';
import {
    ToggleWrapper,
    ServerName,
    ServerTitle,
    ServerWrapper,
    SwitchButtonWrapper,
} from './styled';
import SwitchButton from './SwitchButton';
import Tooltip from '../Tooltip';

function ServerInfo(
    {region, serverLabel, serverTimeTooltip, serverRegionTooltip, isDashboard},
    ...rest
) {
    const [toggle, setToggle] = useState(false);
    const [hover, setHover] = useState(false);

    const serverName = `${region.region}`;
    const tooltipMessage = toggle ? serverTimeTooltip : serverRegionTooltip;

    function onToggle() {
        setToggle(toggle => !toggle);
    }

    function onMouseEnter() {
        setHover(hover => !hover);
    }

    function onMouseLeave() {
        setHover(hover => !hover);
    }

    return (
        <Wrapper className="server-info" {...rest}>
            <SwitchButtonWrapper isDashboard={isDashboard}>
                <Tooltip
                    className="server-info__tooltip"
                    label={tooltipMessage}
                    offset={3.1}
                >
                    <SwitchButton
                        onClick={onToggle}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                        toggle={toggle}
                        hover={hover}
                    />
                </Tooltip>
            </SwitchButtonWrapper>
            <ToggleWrapper>
                {!toggle ? (
                    <div>
                        <DateDisplay />
                        <TimeDisplay />
                    </div>
                ) : (
                    <ServerWrapper>
                        <ServerTitle>{serverLabel}:</ServerTitle>
                        <ServerName>{serverName}</ServerName>
                    </ServerWrapper>
                )}
            </ToggleWrapper>
        </Wrapper>
    );
}

export default memo(ServerInfo);
