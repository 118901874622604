// @flow
import type Axios from 'axios';
import config from 'localConfig';

import type { RegistrationFormAdapterInterface } from '../../application/adapter/RegistrationForm';

class RegistrationFormAdapter implements RegistrationFormAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getForm(locale: string): Promise<any> {
        return await this.apiClient({
            method: 'PUT',
            url: `${config.authUrl}/v1/form/registration`,
            withCredentials: false,
            headers: {
                'content-type': 'application/json',
                'Accept-Language': `${locale}; q=1`,
            },
        });
    }

    async sendForm(formData: {}, locale: string): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${config.authUrl}/v1/form/registration`,
            withCredentials: false,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                'Accept-Language': `${locale}; q=1`,
            },
            data: {
                RegistrationForm: formData
            }
        });
    }

    async confirmRegistration(username: string, token: string): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${config.authUrl}/v1/security/register-confirm/${username}/${token}`,
            withCredentials: false,
            headers: {
                'cache-control': 'no-cache'
            }
        });
    }
}

export default RegistrationFormAdapter;
