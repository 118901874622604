//@flow
import React, {PureComponent} from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';

import {getListingForm, submitListingForm, listingReducer} from './event';
import {updateForm, dropFormResult} from '../../services/action/forms';
import parseTranslation from '../../../adapter/helper/parseTranslation';

import NavBar from '../../component/NavBar';
import Header from 'infrastructure/view/component/Header';
import FormField from '../Registration/component/FormField';
import Error from '../../component/Error';
import Created from './created';

function mapStateToProps(state) {
    const formError = state.forms.error;
    const form = state.forms['ListingForm'] || {};
    const {isOpenMenu} = state.menu;
    const {locale} = state.settings;

    return {
        form,
        locale,
        formError,
        isOpenMenu,
        titleListing: parseTranslation('title-listing'),
        titleListingInfo: parseTranslation('title-listing-info'),
        requiredFieldsTitle: parseTranslation('required-fields-title'),
        contactDetailsTitle: parseTranslation('contact-details-title'),
        projectOverviewTitle: parseTranslation('project-overview-title'),
        tokenTitle: parseTranslation('token-title'),
        exchangeTitle: parseTranslation('exchange-title'),
        communityTitle: parseTranslation('community-title'),
        marketingTitle: parseTranslation('marketing-title'),
        marketingServices: parseTranslation('marketing-services'),
        icoCalendarChecked: parseTranslation('icoCalendarChecked'),
        emailAnnouncementChecked: parseTranslation('emailAnnouncementChecked'),
        featuredPositionChecked: parseTranslation('featuredPositionChecked'),
        onChecked: parseTranslation('onChecked'),
        proposalChecked: parseTranslation('proposalChecked'),
        submitFormListing: parseTranslation('submit-form-listing'),
    };
}

const mapDispatchToProps = {
    updateForm,
    getListingForm,
    submitListingForm,
    listingReducer,
    dropFormResult,
};

type PropTypes = {
    isOpenMenu: boolean,
    getListingForm: () => mixed,
    submitListingForm: () => mixed,
};

type StateTypes = {
    isToggleDetails: boolean,
    isToggleOverview: boolean,
    isToggleToken: boolean,
    isToggleExchanges: boolean,
    isToggleCommunity: boolean,
    isToggleMarketing: boolean,
};

class Listing extends PureComponent<PropTypes, StateTypes> {
    constructor(props) {
        super(props);
        this.state = {
            isToggleDetails: true,
            isToggleOverview: true,
            isToggleToken: true,
            isToggleExchanges: true,
            isToggleCommunity: true,
            isToggleMarketing: true,
        };

        this.handleClickDetails = this.handleClickDetails.bind(this);
        this.handleClickOverview = this.handleClickOverview.bind(this);
        this.handleClickToken = this.handleClickToken.bind(this);
        this.handleClickExchanges = this.handleClickExchanges.bind(this);
        this.handleClickCommunity = this.handleClickCommunity.bind(this);
        this.handleClickMarketing = this.handleClickMarketing.bind(this);
        this.handleCheckClick = this.handleCheckClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleCheckClick() {
        this.setState({
            isSubmitEnabled: !this.state.isSubmitEnabled,
        });
    }

    handleClickDetails() {
        this.setState(state => ({
            isToggleDetails: !state.isToggleDetails,
        }));
    }

    handleClickOverview() {
        this.setState(state => ({
            isToggleOverview: !state.isToggleOverview,
        }));
    }

    handleClickToken() {
        this.setState(state => ({
            isToggleToken: !state.isToggleToken,
        }));
    }

    handleClickExchanges() {
        this.setState(state => ({
            isToggleExchanges: !state.isToggleExchanges,
        }));
    }

    handleClickCommunity() {
        this.setState(state => ({
            isToggleCommunity: !state.isToggleCommunity,
        }));
    }

    handleClickMarketing() {
        this.setState(state => ({
            isToggleMarketing: !state.isToggleMarketing,
        }));
    }

    componentDidMount(): void {
        this.props.getListingForm();
    }

    scrollTo = (element, to, duration) => {
        const start = element.view.scrollTop;
        const change = to - start;
        let currentTime = 0;
        const increment = 20;

        const easeInOutQuad = function (t, b, c, d) {
            t /= d/2;
            if (t < 1) return c/2*t*t + b;
            t--;
            return -c/2 * (t*(t-2) - 1) + b;
        };

        const animateScroll = function(){
            currentTime += increment;
            let val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop(val);
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    componentDidUpdate(prevProps) {
        const {locale, form: { errors, scheme }} = this.props;
        const details = ['email', 'full_name', 'title'];
        const project = ['project_name', 'project_website', 'project_whitepaper_link', 'project_description'];
        const token = ['token_name', 'token_symbol', 'blockchain_type', 'smart_contract_address', 'blockchain_explorer', 'github_link', 'token_description'];
        const exchanges = ['currency_pairs', 'coinmarketcap_link', 'exchanges_link'];
        const community = ['social_media_links'];
        const marketing = ['marketing_services', 'proposal_text'];
        let blockName;
        if (details.includes(errors && Object.keys(errors)[0])) blockName = 'details';
        if (project.includes(errors && Object.keys(errors)[0])) blockName = 'overview';
        if (token.includes(errors && Object.keys(errors)[0])) blockName = 'token';
        if (exchanges.includes(errors && Object.keys(errors)[0])) blockName = 'exchanges';
        if (community.includes(errors && Object.keys(errors)[0])) blockName = 'community';
        if (marketing.includes(errors && Object.keys(errors)[0])) blockName = 'marketing';
        if (errors && Object.keys(errors )[0] && prevProps.form.scheme !== scheme) {

            this.setState(
                {...this.state, [`isToggle${blockName && this.capitalizeFirstLetter(blockName)}`]: false },
                this.scrollTo(this.refs.scrollbar, this.refs[blockName] && this.refs[blockName].offsetTop, 1000)
            );
        }
        if (prevProps.locale !== locale) {
            this.props.getListingForm();
        }
    }

    render() {
        const {
            submitFormListing,
            contactDetailsTitle,
            projectOverviewTitle,
            tokenTitle,
            exchangeTitle,
            communityTitle,
            marketingTitle,
            marketingServices,
            titleListingInfo,
            requiredFieldsTitle,
            formError,
        } = this.props;
        const {scheme, result, errors} = this.props.form;

        if (result === 'Success') {
            this.props.dropFormResult('ListingForm');

            return <Redirect to="/tickets" />;
        }
        return (
            <>
                <div className="container setting listing">
                    <NavBar isOpen={this.props.isOpenMenu} />
                    <div className="listing-container">
                        <Scrollbars ref='scrollbar'>
                            <div className="wrapper">
                                <div className="content listing">
                                    <div
                                        className={classnames('column', {
                                            remove: result === 'Success',
                                        })}
                                    >
                                        {errors && errors.user_id && errors.user_id[0] === 'LISTING_LIMIT_EXCEEDED' ? (
                                            <Created />
                                        ) : (
                                            <form
                                                className="listing-form"
                                                onSubmit={this.onSubmit}
                                            >
                                                {formError && <Error />}

                                                {scheme && (
                                                    <div className="listing-form__body">
                                                        <div className="tittle">
                                                            <p>
                                                                {
                                                                    titleListingInfo
                                                                }
                                                            </p>
                                                        </div>

                                                        <p className="title-details">
                                                            *
                                                            {
                                                                requiredFieldsTitle
                                                            }
                                                        </p>

                                                        <div ref="details"
                                                            className={classnames(
                                                                'listing-details',
                                                                {
                                                                    active: !this
                                                                        .state
                                                                        .isToggleDetails,
                                                                }
                                                            )}
                                                        >
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .handleClickDetails
                                                                }
                                                                className="title"
                                                            >
                                                                <span>
                                                                    {
                                                                        contactDetailsTitle
                                                                    }
                                                                </span>
                                                                <div className="icon-wrap">
                                                                    <i className="icon-single-down-pointing-angle" />
                                                                </div>
                                                            </div>

                                                            <div className="form-wrapper">
                                                                <div className="wrpField">
                                                                    {Object.values(
                                                                        scheme
                                                                    )

                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) =>
                                                                                a.idx -
                                                                                b.idx
                                                                        )
                                                                        .slice(
                                                                            0,
                                                                            3
                                                                        )
                                                                        .map(
                                                                            field => (
                                                                                <FormField
                                                                                    key={
                                                                                        field.name
                                                                                    }
                                                                                    name={
                                                                                        field.name
                                                                                    }
                                                                                    type={
                                                                                        field.type
                                                                                    }
                                                                                    label={
                                                                                        field.label
                                                                                    }
                                                                                    value={
                                                                                        field.value
                                                                                    }
                                                                                    options={
                                                                                        field.options
                                                                                    }
                                                                                    placeholder={
                                                                                        field.placeholder
                                                                                    }
                                                                                    onChange={value =>
                                                                                        this.props.updateForm(
                                                                                            'ListingForm',
                                                                                            field.name,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        !Array.isArray(
                                                                                            errors
                                                                                        ) &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ] &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ][0]
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div ref="overview"
                                                            className={classnames(
                                                                'listing-project-overview textarea-overview',
                                                                {
                                                                    active: !this
                                                                        .state
                                                                        .isToggleOverview,
                                                                }
                                                            )}
                                                        >
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .handleClickOverview
                                                                }
                                                                className="title"
                                                            >
                                                                <span>
                                                                    {
                                                                        projectOverviewTitle
                                                                    }
                                                                </span>
                                                                <div className="icon-wrap">
                                                                    <i className="icon-single-down-pointing-angle" />
                                                                </div>
                                                            </div>

                                                            <div className="form-wrapper">
                                                                <div className="wrpField">
                                                                    {Object.values(
                                                                        scheme
                                                                    )
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) =>
                                                                                a.idx -
                                                                                b.idx
                                                                        )
                                                                        .slice(
                                                                            3,
                                                                            7
                                                                        )
                                                                        .map(
                                                                            field => (
                                                                                <FormField
                                                                                    key={
                                                                                        field.name
                                                                                    }
                                                                                    name={
                                                                                        field.name
                                                                                    }
                                                                                    type={
                                                                                        field.type
                                                                                    }
                                                                                    label={
                                                                                        field.label
                                                                                    }
                                                                                    value={
                                                                                        field.value
                                                                                    }
                                                                                    options={
                                                                                        field.options
                                                                                    }
                                                                                    placeholder={
                                                                                        field.placeholder
                                                                                    }
                                                                                    onChange={value =>
                                                                                        this.props.updateForm(
                                                                                            'ListingForm',
                                                                                            field.name,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        !Array.isArray(
                                                                                            errors
                                                                                        ) &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ] &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ][0]
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div ref="token"
                                                            className={classnames(
                                                                'listing-token textarea-token',
                                                                {
                                                                    active: !this
                                                                        .state
                                                                        .isToggleToken,
                                                                }
                                                            )}
                                                        >
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .handleClickToken
                                                                }
                                                                className="title"
                                                            >
                                                                <span>
                                                                    {tokenTitle}
                                                                </span>
                                                                <div className="icon-wrap">
                                                                    <i className="icon-single-down-pointing-angle" />
                                                                </div>
                                                            </div>

                                                            <div className="form-wrapper">
                                                                <div className="wrpField">
                                                                    {Object.values(
                                                                        scheme
                                                                    )
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) =>
                                                                                a.idx -
                                                                                b.idx
                                                                        )
                                                                        .slice(
                                                                            7,
                                                                            14
                                                                        )
                                                                        .map(
                                                                            field => (
                                                                                <FormField
                                                                                    key={
                                                                                        field.name
                                                                                    }
                                                                                    name={
                                                                                        field.name
                                                                                    }
                                                                                    type={
                                                                                        field.type
                                                                                    }
                                                                                    label={
                                                                                        field.label
                                                                                    }
                                                                                    value={
                                                                                        field.value
                                                                                    }
                                                                                    options={
                                                                                        field.options
                                                                                    }
                                                                                    placeholder={
                                                                                        field.placeholder
                                                                                    }
                                                                                    onChange={value =>
                                                                                        this.props.updateForm(
                                                                                            'ListingForm',
                                                                                            field.name,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        !Array.isArray(
                                                                                            errors
                                                                                        ) &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ] &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ][0]
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div ref="exchanges"
                                                            className={classnames(
                                                                'listing-exchanges',
                                                                {
                                                                    active: !this
                                                                        .state
                                                                        .isToggleExchanges,
                                                                }
                                                            )}
                                                        >
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .handleClickExchanges
                                                                }
                                                                className="title"
                                                            >
                                                                <span>
                                                                    {
                                                                        exchangeTitle
                                                                    }
                                                                </span>
                                                                <div className="icon-wrap">
                                                                    <i className="icon-single-down-pointing-angle" />
                                                                </div>
                                                            </div>

                                                            <div className="form-wrapper">
                                                                <div className="wrpField">
                                                                    {Object.values(
                                                                        scheme
                                                                    )
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) =>
                                                                                a.idx -
                                                                                b.idx
                                                                        )
                                                                        .slice(
                                                                            14,
                                                                            17
                                                                        )
                                                                        .map(
                                                                            field => (
                                                                                <FormField
                                                                                    key={
                                                                                        field.name
                                                                                    }
                                                                                    name={
                                                                                        field.name
                                                                                    }
                                                                                    type={
                                                                                        field.type
                                                                                    }
                                                                                    label={
                                                                                        field.label
                                                                                    }
                                                                                    value={
                                                                                        field.value
                                                                                    }
                                                                                    closeMenuOnSelect={
                                                                                        false
                                                                                    }
                                                                                    hideSelectedOptions={
                                                                                        false
                                                                                    }
                                                                                    options={
                                                                                        field.options
                                                                                    }
                                                                                    placeholder={
                                                                                        field.placeholder
                                                                                    }
                                                                                    onChange={value =>
                                                                                        this.props.updateForm(
                                                                                            'ListingForm',
                                                                                            field.name,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        !Array.isArray(
                                                                                            errors
                                                                                        ) &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ] &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ][0]
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div ref="community"
                                                            className={classnames(
                                                                'listing-community',
                                                                {
                                                                    active: !this
                                                                        .state
                                                                        .isToggleCommunity,
                                                                }
                                                            )}
                                                        >
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .handleClickCommunity
                                                                }
                                                                className="title"
                                                            >
                                                                <span>
                                                                    {
                                                                        communityTitle
                                                                    }
                                                                </span>
                                                                <div className="icon-wrap">
                                                                    <i className="icon-single-down-pointing-angle" />
                                                                </div>
                                                            </div>

                                                            <div className="form-wrapper">
                                                                <div className="wrpField">
                                                                    {Object.values(
                                                                        scheme
                                                                    )
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b
                                                                            ) =>
                                                                                a.idx -
                                                                                b.idx
                                                                        )
                                                                        .slice(
                                                                            17,
                                                                            18
                                                                        )
                                                                        .map(
                                                                            field => (
                                                                                <FormField
                                                                                    key={
                                                                                        field.name
                                                                                    }
                                                                                    name={
                                                                                        field.name
                                                                                    }
                                                                                    type={
                                                                                        field.type
                                                                                    }
                                                                                    label={
                                                                                        field.label
                                                                                    }
                                                                                    value={
                                                                                        field.value
                                                                                    }
                                                                                    options={
                                                                                        field.options
                                                                                    }
                                                                                    placeholder={
                                                                                        field.placeholder
                                                                                    }
                                                                                    onChange={value =>
                                                                                        this.props.updateForm(
                                                                                            'ListingForm',
                                                                                            field.name,
                                                                                            value
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        !Array.isArray(
                                                                                            errors
                                                                                        ) &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ] &&
                                                                                        errors[
                                                                                            field
                                                                                                .name
                                                                                        ][0]
                                                                                    }
                                                                                />
                                                                            )
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div ref="marketing"
                                                            className={classnames(
                                                                'listing-marketing',
                                                                {
                                                                    active: !this
                                                                        .state
                                                                        .isToggleMarketing,
                                                                }
                                                            )}
                                                        >
                                                            <div
                                                                onClick={
                                                                    this
                                                                        .handleClickMarketing
                                                                }
                                                                className="title"
                                                            >
                                                                <span>
                                                                    {
                                                                        marketingTitle
                                                                    }
                                                                </span>
                                                                <div className="icon-wrap">
                                                                    <i className="icon-single-down-pointing-angle" />
                                                                </div>
                                                            </div>

                                                            <div className="form-wrapper">
                                                                <p className="title-form">
                                                                    {
                                                                        marketingServices
                                                                    }
                                                                    *
                                                                </p>
                                                                <div className="wrpField checked-menu">
                                                                    <ul>
                                                                        {Object.values(
                                                                            scheme
                                                                        )
                                                                            .sort(
                                                                                (
                                                                                    a,
                                                                                    b
                                                                                ) =>
                                                                                    a.idx -
                                                                                    b.idx
                                                                            )
                                                                            .slice(
                                                                                18,
                                                                                24
                                                                            )
                                                                            .map(
                                                                                field => (
                                                                                    <li
                                                                                        key={
                                                                                            field.idx
                                                                                        }
                                                                                    >
                                                                                        <FormField
                                                                                            key={
                                                                                                field.name
                                                                                            }
                                                                                            name={
                                                                                                field.name
                                                                                            }
                                                                                            type={
                                                                                                field.type
                                                                                            }
                                                                                            label={
                                                                                                field.label
                                                                                            }
                                                                                            value={
                                                                                                field.value
                                                                                            }
                                                                                            options={
                                                                                                field.options
                                                                                            }
                                                                                            placeholder={
                                                                                                field.placeholder
                                                                                            }
                                                                                            onChange={value =>
                                                                                                this.props.updateForm(
                                                                                                    'ListingForm',
                                                                                                    field.name,
                                                                                                    value
                                                                                                )
                                                                                            }
                                                                                            error={
                                                                                                !Array.isArray(
                                                                                                    errors
                                                                                                ) &&
                                                                                                errors[
                                                                                                    field
                                                                                                        .name
                                                                                                ] &&
                                                                                                errors[
                                                                                                    field
                                                                                                        .name
                                                                                                ][0]
                                                                                            }
                                                                                        />
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button
                                                            className="submit-listing-btn"
                                                            type="submit"
                                                        >
                                                            {submitFormListing}
                                                        </button>
                                                    </div>
                                                )}
                                            </form>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </>
        );
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.submitListingForm();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing);
