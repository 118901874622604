class GetUserData {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(token: string): Promise<any> {
        return await this.adapter.getUserData(token);
    }
}

export default GetUserData;
