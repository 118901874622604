import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FormattedPrice extends Component {
    static propTypes = {
        precision: PropTypes.number,
        price: PropTypes.number.isRequired,
    };

    static defaultProps = {
        precision: 7,
    };

    render() {
        const digits = this.props.precision > 10 ? this.props.precision : 10;
        const [integer, fraction] = this.props.price.toFixed(digits).split('.');

        const formattedPrice = this.formatPrice(integer, fraction);
        const colorClass = formattedPrice > 0 ? 'green' : 'red';
        return <span className={colorClass}>{formattedPrice}</span>;
    }

    formatPrice(integer, fraction) {
        if (+integer) {
            return this.integerGTZero(integer, fraction);
        } else {
            return this.integerEQZero(fraction);
        }
    }

    integerGTZero(integer, fraction) {
        const fractionPrecision = this.props.precision - integer.length;
        // !!fraction &&
        let fractionWithPrecisionApplied;
        if (fractionPrecision > 0) {
            fractionWithPrecisionApplied = fraction.substr(
                0,
                fractionPrecision,
            );
        } else {
            fractionWithPrecisionApplied = fraction[0];
        }

        return `${integer}.${fractionWithPrecisionApplied}`;
    }

    integerEQZero(fraction) {
        const fractionPrecision = this.props.precision - 1;
        let fractionWithPrecisionApplied = fraction.substr(
            0,
            fractionPrecision,
        );

        if (!+fractionWithPrecisionApplied) {
            let index = 1;
            fraction.split('').find((char, i) => {
                if (+char > 0) {
                    index = i + 1;
                }

                return +char > 0;
            });

            fractionWithPrecisionApplied = fraction.substr(0, index);
        }

        return `0.${fractionWithPrecisionApplied}`;
    }
}

export default FormattedPrice;
