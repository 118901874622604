import {ActionTypes, errorTicketsHistory} from './actions';

export const initialState = {
    list: [],
    selectedTicket: null,
    filterParams: {
        status: [],
        pattern: '',
        category: '',
        subcategory: '',
        range: [],
    },
    isPendingVerificationForm: false,
    isPendingQuestionForm: false,
    isPendingVerificationImage: true,
    verificationImages: [],
    verificationImagesError: null,
    error: null,
    errorTicket: null,
    errorFetchTicket: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.setTicketsHistory:
            return {
                ...state,
                list: action.tickets,
            };

        case ActionTypes.errorTicketsHistory:
            return {
                ...state,
                error: action.payload,
            };

        case ActionTypes.setSelectedTicket:
            return {
                ...state,
                list: state.list.reduce((result, ticket) => {
                    if (ticket.id === action.ticket.id) {
                        result.push({
                            ...ticket,
                            status: action.ticket.status,
                            updatedAt: action.ticket.updatedAt,
                            shouldShowNotification: false,
                        });
                    } else {
                        result.push(ticket);
                    }

                    return result;
                }, []),
                selectedTicket: action.ticket,
            };

        case ActionTypes.errorSelectedTicket:
            return {
                ...state,
                errorTicket: action.payload,
            };

        case ActionTypes.setFilterTicketType:
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    status: action.payload,
                },
            };

        case ActionTypes.setFilterTicketPattern:
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    pattern: action.payload,
                },
            };

        case ActionTypes.setFilterTicketCategory:
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    category: action.payload,
                },
            };

        case ActionTypes.setFilterTicketSubCategory:
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    subcategory: action.payload,
                },
            };

        case ActionTypes.setFilterRangeDates:
            return {
                ...state,
                filterParams: {
                    ...state.filterParams,
                    range: action.payload,
                },
            };

        case ActionTypes.submitVerificationForm:
            return {
                ...state,
                isPendingVerificationForm: true,
            };

        case ActionTypes.submitVerificationFormResponse:
            return {
                ...state,
                isPendingVerificationForm: false,
            };

        case ActionTypes.submitVerificationFormError:
            return {
                ...state,
                isPendingVerificationForm: false,
            };

        case ActionTypes.submitQuestionForm:
            return {
                ...state,
                isPendingQuestionForm: true,
            };

        case ActionTypes.submitQuestionFormResponse:
            return {
                ...state,
                isPendingQuestionForm: false,
            };

        case ActionTypes.fetchTicketError:
            return {
                ...state,
                errorFetchTicket: action.payload,
            };
        case ActionTypes.GET_TICKET_VERIFICATION_IMAGE_REQUEST:
            return {
                ...state,
                isPendingVerificationImage: true,
                verificationImagesError: null,
            };

        case ActionTypes.GET_TICKET_VERIFICATION_IMAGE_SUCCESS:
            return {
                ...state,
                isPendingVerificationImage: false,
                verificationImages: action.payload,
                verificationImagesError: null,
            };

        case ActionTypes.GET_TICKET_VERIFICATION_IMAGE_FAILURE:
            return {
                ...state,
                isPendingVerificationImage: false,
                verificationImagesError: action.payload,
            };

        case ActionTypes.CLEAR_ARRAY_TICKETS_VERIFICATION_IMAGE:
            return {
                ...state,
                verificationImages: [],
            };
        default:
            return state;
    }
}
