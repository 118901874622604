export const ActionTypes = {
    getForm: 'login-scene.form.get',
    submitForm: 'login-scene.form.submit',
    getMathCaptcha: 'GET_MATH_CAPTCHA',
};

export function getMathCaptcha(url) {
    return {
        type: ActionTypes.getMathCaptcha,
        url,
    };
}
export function getForm(payload) {
    return {
        type: ActionTypes.getForm,
    };
}

export function submitForm(history) {
    return {
        type: ActionTypes.submitForm,
        history,
    };
}
