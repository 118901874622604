import React, {PureComponent} from 'react';
import styled from 'styled-components';
import colors from 'infrastructure/view/styles/common-colors/common-colors';
import parseTranslation from '../../../adapter/helper/parseTranslation';

class TableTooltipTrades extends PureComponent {
    render() {
        const {openingTime, openingTimeDate, amount, price} = this.props;

        return (
            <ContentTooltipTrades>
                <div>
                    <span>{parseTranslation('label-time')}</span> <span>{openingTime}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-date')}</span> <span>{openingTimeDate}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-price')}</span>{' '}
                    <span className="table-row-tooltip">{price}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-amount')}</span> <span>{amount}</span>
                </div>
            </ContentTooltipTrades>
        );
    }
}

export default TableTooltipTrades;

const ContentTooltipTrades = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    position: absolute;
    right: -24rem;
    top: -12rem;

    background: ${colors.color20};    
    border: 1px solid ${colors.color1};
    box-shadow: 0 0 20px ${colors.color66};
    font-size: 2.3rem;
    color: ${colors.color14};

    padding: 10px 10px 10px 20px;

    div {
        display: flex;
    }

    span {
        width: 11rem;
    }

    span:nth-child(2) {
        display: flex;
        width: auto;
        min-width: 10rem;

        i {
            font-size: 1.3rem;
            font-weight: 800;
            position: absolute;
            left: 0;
        }

        .cell-price-buy {
            position: relative;
            color: #00b456;
            text-align: left;
            padding: 0 2.2rem 0;
        }

        .cell-price-sell {
            position: relative;
            color: #FF4D4D;
            text-align: left;
            padding: 0 2.2rem 0;
        }
    }

    &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        right: 8%;

        transform: translateY(51%) rotate(-135deg);
        display: block;
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid ${colors.color1};
        border-bottom: none;
        border-right: none;
        background-color: ${colors.color20};
    }
`;
