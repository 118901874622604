import React, {PureComponent} from 'react';
import styled from 'styled-components';
import colors from 'infrastructure/view/styles/common-colors/common-colors';
import parseTranslation from '../../../adapter/helper/parseTranslation';

class TableTooltipDepthBuy extends PureComponent {
    render() {
        const {count, amount, total, price} = this.props;

        return (
            <ContentTooltipDepthBuy>
                <div>
                    <span>{parseTranslation('label-count')}</span> <span>{count}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-amount')}</span> <span>{amount}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-total')}</span> <span>{total}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-price')}</span> <span>{price}</span>
                </div>
            </ContentTooltipDepthBuy>
        );
    }
}

export default TableTooltipDepthBuy;

const ContentTooltipDepthBuy = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    position: relative;
    left: 3.2rem;
    right: 0;
    bottom: 0;

    background-color: ${colors.color20};
    border-radius: 0;
    border: 1px solid ${colors.color1};
    box-shadow: 0 0 20px ${colors.color66};
    font-size: 2.3rem;
    color: ${colors.color14};

    padding: 10px 20px;

    div {
        display: flex;
    }

    span {
        width: 11rem;
    }

    span:nth-child(2) {
        display: flex;
        width: auto;
        min-width: 10rem;

        .order-type {
            .buy {
                color: ${colors.$color8};
                .Buy {
                    content: '\e92d';
                    font-family: 'juicy_orange';
                    font-size: 1.3rem;
                    font-weight: 800;
                    position: relative;
                    top: 1rem;
                    left: -0.5rem;
                    font-style: inherit;
                }
            }

            .sell {
                color: ${colors.$color11};
                .Sell {
                    content: '\e91a';
                    font-family: 'juicy_orange';
                    font-size: 1.3rem;
                    font-weight: 800;
                    position: relative;
                    top: 1rem;
                    left: -0.5rem;
                    font-style: inherit;
                }
            }
        }

        .currency-pair {
            left: 2.6rem;
        }
    }

    &::after {
        content: ' ';
        position: absolute;
        top: 45%;
        right: 0;
        transform: translateX(50%) rotate(135deg);
        display: block;
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid ${colors.color1};
        border-bottom: none;
        border-right: none;
        background-color: ${colors.color20};
    }
`;
