// @flow
import type { AccessToken } from '../../../domain/entitiy/AccessToken';
import type { BillingAdapterInterface as BillingAdapter }  from '../../../application/adapter/Billing';

class SubmitDepositForm {
    billingAdapter: BillingAdapter;

    constructor(billingAdapter: BillingAdapter) {
        this.billingAdapter = billingAdapter;
    }

    async invoke(token: AccessToken, formData: {} = {}, locale: string) {
        try {
            const { data } = await this.billingAdapter.sendDepositForm(token, formData, locale);

            return data;
        } catch (error) {
            if(error.response) {
                const {status, data} = error.response;

                if (status === 422) {
                    const senderService = data.scheme.find(
                        ({name}) => name === 'sender_service'
                    );

                    const itemsLength = (
                        Object
                            .keys(senderService.options.items)
                            .length
                    );

                    if (itemsLength < 2) {
                        senderService.type = 'hidden';
                    }

                    return data;
                }
            }

            throw error
        }
    }
}

export default SubmitDepositForm;
