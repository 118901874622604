// @flow
import type { AccessToken } from '../../../domain/entitiy/AccessToken';
import type { BillingAdapterInterface as BillingAdapter }  from '../../../application/adapter/Billing';

class GetWithdrawForm {
    billingAdapter: BillingAdapter;

    constructor(billingAdapter: BillingAdapter) {
        this.billingAdapter = billingAdapter;
    }

    async invoke(token: AccessToken, formData: {} = {}, locale: string) {
        try {
            const { data } = await this.billingAdapter.getWithdrawForm(token, formData, locale);

            // const fiats = [
            //     'USD',
            //     'EUR',
            //     'RUB',
            //     'JPY',
            //     'CNY',
            //     'CHN'
            // ];
            //
            // const currency = (
            //     data.scheme
            //         .find(
            //             ({ name }) => name === 'currency'
            //         )
            // );
            //
            // const method = (
            //     data.scheme
            //         .find(
            //             ({ name }) => name === 'method'
            //         )
            // );
            //
            // if (fiats.includes(currency.value)) {
            //     delete method.options.items.crypt;
            // } else {
            //     delete method.options.items.bank;
            //     delete method.options.items.card;
            // }

            return data;
        } catch (e) {
            throw e
        }
    }
}

export default GetWithdrawForm;
