import axios from 'infrastructure/adapter/helper/axios';

import {
    ActionTypes,
    setTicketsHistory,
    fetchTicket,
    setSelectedTicket,
    submitVerificationFormResponse,
    submitQuestionFormResponse,
    errorTicketsHistory,
    errorSelectedTicket,
    fetchTicketError,
    getTicketsHistory as getTicketsHistoryAction,
    submitVerificationFormError,
    getTicketVerificationImageSuccess,
    getTicketVerificationImageFailure,
} from '../../scene/Tickets/service/actions';
import {
    setForm,
    cleanQuestionForm,
} from 'infrastructure/view/services/action/forms';


import {errorForm} from '../action/forms';

import TicketsAdapter from 'infrastructure/adapter/Tickets';

import GetTicketsHistory from 'application/use-case/tickets/GetTicketsHistory';
import GetTicket from 'application/use-case/tickets/GetTicket';
import ReplyToTicket from 'application/use-case/tickets/ReplyToTicket';
import GetQuestionForm from 'application/use-case/tickets/GetQuestionForm';
import SubmitQuestionForm from 'application/use-case/tickets/SubmitQuestionForm';
import GetVerificationForm from 'application/use-case/tickets/GetVerificationForm';
import SubmitVerificationForm from 'application/use-case/tickets/SubmitVerificationForm';
import GetTicketImageVerification from 'application/use-case/tickets/GetTicketImageVerification';

const ticketsAdapter = new TicketsAdapter(axios);
const getTicketsHistory = new GetTicketsHistory(ticketsAdapter, localStorage);
const getTicket = new GetTicket(ticketsAdapter, localStorage);
const replyToTicket = new ReplyToTicket(ticketsAdapter);

const getQuestionForm = new GetQuestionForm(ticketsAdapter);
const submitQuestionForm = new SubmitQuestionForm(ticketsAdapter);
const getVerificationForm = new GetVerificationForm(ticketsAdapter);
const submitVerificationForm = new SubmitVerificationForm(ticketsAdapter);
const getTicketImageVerification = new GetTicketImageVerification(
    ticketsAdapter
);
const titleTextKeys = {
    question: 'question',
    verification: 'ticket-title-verification',
    listing: 'ticket-title-listing',
    withdraw: 'ticket-title-withdraw',
};

export default function(store) {
    return function(next) {
        return async function(action) {
            next(action);

            if (action.type === ActionTypes.getTicketsHistory) {
                const {accessToken} = store.getState().user;
                const {local} = store.getState().translation;

                try {
                    const history = await getTicketsHistory.invoke(
                        accessToken,
                        local
                    );

                    if (history && history.length) {
                        store.dispatch(
                            setTicketsHistory(
                                history.map(item =>
                                    item.type === titleTextKeys.question
                                        ? item
                                        : {
                                              ...item,
                                              additionalInfo: {
                                                  ...item.additionalInfo,
                                                  title:
                                                      titleTextKeys[item.type],
                                              },
                                          }
                                )
                            )
                        );

                        store.dispatch(fetchTicket(history[0].id));
                    }
                } catch (error) {
                    store.dispatch(errorTicketsHistory(error));
                }
            }

            if (action.type === ActionTypes.fetchTicket) {
                const {accessToken} = store.getState().user;
                const {local} = store.getState().translation;
                try {
                    const ticket = await getTicket.invoke(
                        accessToken,
                        action.id,
                        local
                    );

                    store.dispatch(setSelectedTicket(ticket));
                } catch (error) {
                    store.dispatch(errorSelectedTicket(error));
                }
            }

            if (action.type === ActionTypes.replyToTicket) {
                try {
                    const {accessToken} = store.getState().user;

                    await replyToTicket.invoke(
                        accessToken,
                        action.id,
                        action.message
                    );

                    store.dispatch(fetchTicket(action.id));
                } catch (error) {
                    store.dispatch(fetchTicketError(error));
                }
            }

            if (action.type === ActionTypes.getQuestionForm) {
                try {
                    const {accessToken} = store.getState().user;
                    const {local} = store.getState().translation;

                    const questionForm = await getQuestionForm.invoke(
                        accessToken,
                        local
                    );

                    store.dispatch(setForm(questionForm));
                } catch (e) {
                    store.dispatch(errorForm(e));
                    store.dispatch(cleanQuestionForm('QuestionForm'));
                }
            }

            if (action.type === ActionTypes.submitQuestionForm) {
                const {accessToken} = store.getState().user;
                const {local} = store.getState().translation;
                const {scheme} = store.getState().forms['QuestionForm'];
                let formData = new FormData();

                // Check if verification form have no attaching files
                if (!!scheme['attachments_files'].value) {
                    for (
                        let i = 0;
                        i < scheme.attachments_files.value.length;
                        i++
                    ) {
                        let nameField = `QuestionForm[attachments_files][${i}]`;
                        formData.append(
                            nameField,
                            scheme.attachments_files.value[i]
                        );
                    }
                }

                Object.keys(scheme).forEach(objectKey => {
                    if (objectKey === 'attachments_files') {
                        return;
                    }
                    const nameField = `QuestionForm[${objectKey}]`;

                    formData.append(
                        nameField,
                        scheme[objectKey].value === null
                            ? ''
                            : scheme[objectKey].value
                    );
                });

                try {
                    const form = await submitQuestionForm.invoke(
                        accessToken,
                        formData,
                        local
                    );

                    store.dispatch(submitQuestionFormResponse());
                    store.dispatch(setForm(form));
                    store.dispatch(getTicketsHistoryAction());
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionTypes.getVerificationForm) {
                try {
                    const {accessToken} = store.getState().user;
                    const {local} = store.getState().translation;

                    const verificationForm = await getVerificationForm.invoke(
                        accessToken,
                        local
                    );

                    store.dispatch(setForm(verificationForm));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (
                action.type ===
                ActionTypes.GET_TICKET_VERIFICATION_IMAGE_REQUEST
            ) {
                try {
                    const {payload} = action;
                    const {accessToken} = store.getState().user;

                    const attachmentLength = payload.length;
                    let images = [];
                    const files = payload.map(item => {
                        const fileArr = item.split('/');
                        return fileArr[fileArr.length - 1];
                    });
                    files.map((item, idx) => {

                        (async () => {
                            const {data} = await getTicketImageVerification.invoke(accessToken, item);

                            images.push(data);
                            images.length === attachmentLength && store.dispatch(getTicketVerificationImageSuccess(images));
                        })();

                    });
                } catch (e) {
                    store.dispatch(getTicketVerificationImageFailure(e));
                }
            }


            if (action.type === ActionTypes.submitVerificationForm) {
                const {accessToken} = store.getState().user;
                const {local} = store.getState().translation;
                const {scheme} = store.getState().forms['VerificationForm'];
                let formData = new FormData();

                //TODO: переписать нахер весь мапинг

                const data = Object.values(scheme).reduce(
                    (result, schemeData) => {
                        result[schemeData.name] = schemeData.value;
                        return result;
                    },
                    {}
                );

                // Check if verification form have no attaching files
                if (!!data['passport_files']) {
                    for (let i = 0; i < data.passport_files.length; i++) {
                        let nameField = `VerificationForm[passport_files][${i}]`;
                        formData.append(nameField, data.passport_files[i]);
                    }
                }

                try {
                    const form = await submitVerificationForm.invoke(
                        accessToken,
                        formData,
                        local
                    );

                    store.dispatch(setForm(form));
                    store.dispatch(submitVerificationFormResponse());
                    store.dispatch(getTicketsHistoryAction());
                } catch (e) {
                    store.dispatch(submitVerificationFormError());
                    store.dispatch(errorForm(e));
                }
            }
        };
    };
}
