import moment from 'moment';

export function isTickFromPast(candle, tick) {
    const isInPast = tick.openingTime.isBefore(moment(candle.date));

    return isInPast;
}

export function isSameCandle(interval, candle, tick) {
    if (!candle) {
        return false;
    }

    const startDate = moment(candle.date);
    const endDate = moment(startDate).add(interval);

    const isInCurrentCandleInterval = tick.openingTime.isBetween(
        startDate,
        endDate,
        null,
        '[)',
    );

    return isInCurrentCandleInterval;
}

export function createCandle(tick, interval) {
    const {price, amount} = tick;

    const diff = tick.openingTime.unix() % interval.as('second');
    const date = moment(tick.openingTime).subtract(diff, 'second');

    const candle = {
        date: date.toDate(),
        open: price,
        hi: price,
        lo: price,
        close: price,
        volume: amount,
    };

    return candle;
}

export function createDummyCandle(date, value, interval) {
    const candle = {
        date: moment(date)
            .add(interval)
            .toDate(),
        open: value,
        hi: value,
        lo: value,
        close: value,
        volume: 0,
    };

    return candle;
}

export function updateCandle(currentCandle, tick) {
    const {hi, lo, volume} = currentCandle;
    const {price, amount} = tick;

    const candle = {
        ...currentCandle,
        hi: Math.max(hi, price),
        lo: Math.min(lo, price),
        close: price,
        volume: volume + amount,
    };

    return candle;
}

export function mapInterval(interval) {
    switch (interval) {
        case '1M':
            return moment.duration(1, 'minute');

        case '5M':
            return moment.duration(5, 'minute');

        case '15M':
            return moment.duration(15, 'minute');

        case '30M':
            return moment.duration(30, 'minute');

        case '1H':
            return moment.duration(1, 'hour');

        case '4H':
            return moment.duration(4, 'hour');

        case '1D':
            return moment.duration(1, 'day');

        case '1W':
            return moment.duration(1, 'week');

        case '1Y':
            return moment.duration(1, 'year');

        default:
            return null;
    }
}

export function getTimeDiff(startTime, endTime, interval) {
    const duration = mapInterval(interval);

    switch (interval) {
        case '1M': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('minute');

            return Math.floor(diff / 1);
        }

        case '5M': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('minute');

            return Math.floor(diff / 5);
        }

        case '15M': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('minute');

            return Math.floor(diff / 15);
        }

        case '30M': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('minute');

            return Math.floor(diff / 30);
        }

        case '1H': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('hour');

            return Math.floor(diff / 1);
        }

        case '4H': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('hour');

            return Math.floor(diff / 4);
        }

        case '1D': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('day');

            return Math.floor(diff / 1);
        }

        case '1W': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('week');

            return Math.floor(diff / 1);
        }

        case '1Y': {
            const diff = moment
                .duration(endTime.diff(startTime, duration))
                .as('year');

            return Math.floor(diff / 1);
        }

        default:
            return null;
    }
}
