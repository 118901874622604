import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import Logo from '../../widget/Chat/logo.svg';

import Header from 'infrastructure/view/component/Header';
import NavBar from '../../component/NavBar';

import TicketsLayoutIndex from './layout/index';
import TicketsLayoutCreate from './layout/create';
import {getTicketsHistory, getQuestionForm, getVerificationForm} from './service/actions';
import styled from 'styled-components';

function mapStateToProps(state) {
    const {user} = state;
    const {isOpenMenu} = state.menu;
    const {locale} = state.settings;

    return {
        isLoggedIn: !!user.accessToken,
        isOpenMenu,
        locale,
    };
}

class Tickets extends PureComponent {
    componentDidMount() {
        const {getTicketsHistory} = this.props;

        getTicketsHistory();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locale !== this.props.locale) {
            this.props.getTicketsHistory();
            this.props.getQuestionForm();
            this.props.getVerificationForm();
        }
    }

    render() {
        return (
            <Fragment>
                <div className="container tickets">
                    <NavBar isOpen={this.props.isOpenMenu} />
                    <div className="wrapper">
                        <WrapperLogo>
                            <img src={Logo} alt="logo" />
                        </WrapperLogo>
                        <div className="content">
                            <Switch>
                                <Route
                                    exact
                                    path="/tickets"
                                    component={TicketsLayoutIndex}
                                />

                                <div className="content no-logo">
                                    <Route
                                        path="/tickets/create"
                                        component={TicketsLayoutCreate}
                                    />
                                </div>
                            </Switch>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    getTicketsHistory,
    getQuestionForm,
    getVerificationForm
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Tickets);

export const WrapperLogo = styled.div`
    position: absolute;
    top: 47%;
    bottom: 0;
    left: 65%;
    transform: translate(-60%);
    width: 45%;
    height: 42.5rem;
    max-width: inherit;
    padding: 1.5rem 10.533rem 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #9E9EAC;
    z-index: 0;

    img {
        width: 90%;
        display: block;
        margin: 0 auto;
    }
`;
