import styled, {css, keyframes} from 'styled-components';
import Tooltip from '../Tooltip';

export const buttonColor = '#999999';
export const buttonBg = '#F5F5F5';
export const buttonColorHover = '#2B9241';

const animation = keyframes`
  0% {
    opacity: 0;
  }  
  100% {
    opacity: 1;
  }
`;

export const Button = styled.div`
    height: 5.833rem;
    width: 5.833rem;
    font-size: 2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${buttonBg};
    transition: all 0.3s ease 0s;    
    color: ${buttonColor};
    cursor: pointer;
    &:hover {
      color: ${buttonColorHover};
      div {
        opacity: 0;
        display: flex;        
        animation: ${animation} 0s 2s forwards;
        z-index: 101;
      }      
      &:before {        
        border-top-color: ${buttonColorHover};
      }
    }
    ${({withArrow}) => withArrow && css`
      ::before {
        content: "";
        position: absolute;
        bottom: 0.7rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.5rem 0.5rem 0;
        border-color: ${buttonColor} transparent transparent;
        transition: border 0.3s ease 0s;
      }
    `}
`;
export const Title = styled.span`
  display: block;
`;


