// Default settings

export const locale = 'en-US';
export const dashboardLayout = 0;
export const availableDashboardLayout = ['0', '1', '2', '3', '4', '5'];

export const DXChartOffsets = {
    top: 5,
    right: 0,
    bottom: 5,
};

export const dxChart = {
    chartInterval: '1M',
    chartType: 'candle',
    offsets: DXChartOffsets,
};

export const selectedSymbol = {
    numerator: 'ETH',
    denominator: 'BTC',
    name: 'ETH/BTC',
};

export const dxChartTypes = ['candle', 'line', 'bar', 'area'];

export const dxChartIntervals = ['1M', '5M', '15M', '30M', '1H'];

export const resetActivityTimeEvents = ['scroll', 'click'];

// localStorage and sessionStorage keys
export const accessTokenKey = 'accessToken';
export const userRegionKey = 'userRegion';
export const customChartStudiesKey = 'customChartStudies';
export const customChartDrawingsKey = 'customChartData';

// Notifications
export const notificationMessageMap = {
    password_changed: 'label-deposit-password-changed',
    deal_confirm_success: 'label-deal-confirm-success',
    deal_confirm_error: 'label-deal-confirm-error',
    ticket_new_withdraw: 'label-ticket-new-withdraw',
    ticket_new_question: 'ticket-new-question',
    ticket_new_verification: 'ticket-new-verification',
    ticket_new_listing: 'ticket-new-listing',
    ticket_pending_withdraw: 'label-ticket-pending-withdraw',
    ticket_pending_question: 'ticket-pending-question',
    ticket_pending_verification: 'ticket-pending-verification',
    ticket_pending_listing: 'ticket-pending-listing',
    ticket_processed_withdraw: 'label-ticket-processed-withdraw',
    ticket_processed_question: 'ticket-processed-question',
    ticket_processed_verification: 'ticket-processed-verification',
    ticket_processed_listing: 'ticket-processed-listing',
    ticket_rejected_withdraw: 'label-ticket-rejected-withdraw',
    ticket_rejected_question: 'ticket-rejected-question',
    ticket_rejected_verification: 'ticket-rejected-verification',
    ticket_rejected_listing: 'ticket-rejected-listing',
    deposit_transaction_success: 'label-deposit-transaction-success',
    withdraw_transaction_success: 'label-withdraw-transaction-success',
    newChatMessage: 'label-new-chat-message',
    open_order_error: 'open_order_error',
    cancel_order_error: 'cancel_order_error',
    'auto-closed-order': 'auto-closed-order',
    cancel_order_incorrect: 'cancel_order_incorrect',
    not_enough_balance: 'not_enough_balance',
    fill_order_form:'fill_order_form',
    'order-limit-error': 'order-limit-error',
};

export const POPUP_NOTIFICATION_LIFETIME = '2000';

//Session config

export const activityTime = 10000 * 60;
export const confirmTime = 5000 * 60;
export const intervalUpdateSessionTime = 3000 * 60;
export const absoluteSessionLogoutTime = 5000 * 60;

