import styled from 'styled-components';
import {rgba} from 'polished';

import colors from '../../theme/colors';

export const Select = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    background-color: ${colors.color3};    
    padding: 0.6rem 2rem;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    z-index: 2;
    border: ${({isOpen}) =>
        isOpen ? `1px solid ${colors.color7}` : '1px solid transparent'};
`;

export const Value = styled.div`
    font-size: 2.3rem;
    color: ${colors.color5};
`;

export const Icon = styled.div`
    font-size: 2rem;
    color: ${colors.color5};
`;

export const Dropdown = styled.div`
    position: absolute;
    background-color: ${colors.color2};
    width: 100%;
    top: 50%;
    padding-top: 1.8rem;    
    border: 1px solid ${colors.color7};
    box-sizing: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: ${({optionLength}) => (optionLength < 4 ? 'hidden' : 'auto')};
    box-shadow: 0 0 20px ${rgba('#8a92b280', 0.5)};  
    overflow: hidden;  
    &::-webkit-scrollbar {
  display: none;
}
`;

export const DropdownItem = styled.div`
    height: 5.5rem;
    display: flex;
    align-items: center;
    font-size: 2.3rem;
    padding: 0 5rem;
    box-sizing: border-box;
    cursor: pointer;
    color: ${({active}) => (active ? colors.color8 : colors.color5)};    
    position:relative;
    &:before {
      content: "";
      position:absolute;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      background: ${rgba(colors.color5, 0.5)};
      height: 1px;
    }    
    &:hover {    
        background-color: #CED8FE;
        color: ${colors.color7};
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
`;

export const SelectWrapper = styled.div`
    position: relative;
    z-index: 1;
`;
