import React, {useState, useEffect} from 'react';
import {
    Select,
    Icon,
    Value,
    Dropdown,
    DropdownItem,
    SelectWrapper,
} from './styled';

export default ({options, onSelect}) => {
    const [selected, setSelected] = useState(options[0].label);
    const [isOpen, setIsOpen] = useState(false);

    const toggleSelect = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        // if ()
        setSelected(options[0].label);
    }, [options[0].label]);
    const selectHandler = e => () => {
        // We can return simple value or object {value, label}
        onSelect(e);
        setIsOpen(false);
        setSelected(e.label);
    };

    return (
        <SelectWrapper>
            <Select isOpen={isOpen} onClick={toggleSelect}>
                <Value>{selected}</Value>
                <Icon className="icon-single-down-pointing-angle" />
            </Select>
            {isOpen && (
                <Dropdown optionLength={options.length}>
                    {options.map(({value, label}) => (
                        <DropdownItem key={value} active={label === selected} onClick={selectHandler({value, label})}>{label}</DropdownItem>
                    ))}
                </Dropdown>
            )}
        </SelectWrapper>
    );
};
