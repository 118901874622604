// @flow
import moment from 'moment';

type rawOHLC = {
    open: string,
    hi: string,
    lo: string,
    close: string,
    volume: string,
    timestamp: string
}

export class OHLC {
    open: number;
    hi: number;
    lo: number;
    close: number;
    volume: number;
    offset: number;
    moment: moment;
    date: Date;

    constructor(ohlc: rawOHLC) {
        this.open = +ohlc.open;
        this.hi = +ohlc.hi;
        this.lo = +ohlc.lo;
        this.close = +ohlc.close;
        this.volume = +ohlc.volume;

        this.offset = +ohlc.timestamp;
        this.moment = moment.unix(+ohlc.timestamp);
        this.date = this.moment.toDate();
    }
}
