import React from 'react';
import {
    Count,
    DepthRowWrap,
    Price,
    Amount,
    Total,
    GreenLine,
    RedLine,
    PriceText,
    GreenArrow,
    RedArrow,
} from '../styled';
import FormattedPrice from '../../../infrastructure/view/component/FormattedPrice/FormattedPrice';

export default function DepthRow({
    count,
    amount,
    total,
    price,
    isOdd,
    isTop,
    width,
    collapsed
}) {
    const newPrice = price.toString().split('.')[1] && price.toString().split('.')[1].length >= 1 ? price : `${price}.00`;
    return (
        <DepthRowWrap isOdd={isOdd} collapsed={collapsed}>
            {isTop ? <GreenLine collapsed={collapsed} width={width} /> : <RedLine collapsed={collapsed} width={width} />}
            {collapsed && !isTop
                ? <>
                    <Price>
                        {newPrice.split('.')[0]}.
                        <PriceText isTop={isTop}>{newPrice.split('.')[1].length === 1 ? `${newPrice.split('.')[1]}0` : newPrice.split('.')[1]}</PriceText>
                    </Price>
                    <Total>{<FormattedPrice price={+total}/>}</Total>
                    <Amount>{<FormattedPrice price={+amount}/>}</Amount>
                    <Count collapse={collapsed}>
                    {isTop ? (
                        <GreenArrow className="icon-single-up-pointing-angle" />
                    ) : (
                        <RedArrow className="icon-single-down-pointing-angle" />
                    )}
                    <div>{count}</div>
                </Count>


                    </>
                : <>
                    <Count collapse={collapsed}>
                    {isTop ? (
                        <GreenArrow className="icon-single-up-pointing-angle" />
                    ) : (
                        <RedArrow className="icon-single-down-pointing-angle" />
                    )}
                    <div>{count}</div>
                </Count>
                    <Amount>{<FormattedPrice price={+amount}/>}</Amount>
                    <Total>{<FormattedPrice price={+total}/>}</Total>
                    <Price>
                        {newPrice.split('.')[0]}.
                        <PriceText isTop={isTop}>{newPrice.split('.')[1].length === 1 ? `${newPrice.split('.')[1]}0` : newPrice.split('.')[1]}</PriceText>
                    </Price></>
            }
        </DepthRowWrap>
    );
}
