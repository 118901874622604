import React, {Component} from 'react';
import {connect} from 'react-redux';

import {setActiveCharts} from '../../Chart/service/actions';

import Chart from '../../Chart/ChartWithDimentionsFit';
import withControls from '../../Chart/withControls';

const ChartWithControls = withControls(Chart);

const mapDispatchToProps = {
    setActiveCharts,
};

class Layout02 extends Component {
    componentDidMount() {
        this.props.setActiveCharts([0]);
    }

    render() {
        return (
            <div className="chart-container">
                <ChartWithControls id={0} />
            </div>
        );
    }
}

export default connect(
    null,
    mapDispatchToProps
)(Layout02);
