import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import colors  from '../../styles/common-colors/common-colors';

import Modal from 'react-modal';
import parseTranslation from '../../../adapter/helper/parseTranslation';

import {clearAbsoluteSessionTimer, setAbsoluteSessionTimer} from "../../services/action/session";
import {logout} from 'infrastructure/view/services/action/user';
import config from '../../../../localConfig';

class SessionAbsoluteTimerModal extends Component {
    onContinue = () => {
        const { clearAbsoluteSessionTimer, setAbsoluteSessionTimer } = this.props;
        clearAbsoluteSessionTimer();
        setAbsoluteSessionTimer(false);
    };

    onHandleLogout = () => {
        const { clearAbsoluteSessionTimer, setAbsoluteSessionTimer, logout } = this.props;
        clearAbsoluteSessionTimer();
        setAbsoluteSessionTimer(false);
        logout();
        window.location.href = `${config.workspaceUrl}/logout`;
    };

    render() {
        const { absoluteSessionTimer} = this.props;

        return (
            <Modal
                isOpen={absoluteSessionTimer}
                className="modal modal-deposit absolute-session-modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(20, 22, 27, 0.65)',
                    },
                }}
            >
                <ContentWrap>
                    <Header>
                        <span>
                            {this.props.titleSessionAbsModal}
                        </span>

                        <button onClick={this.onContinue}>
                            <i className="icon-close" />
                        </button>
                    </Header>
                    <Column>
                        <p>{this.props.contentSessionAbsModal}</p>
                    </Column>

                    <ButtonWrapper>
                        <ButtonLogOut
                            className="secondary-button"
                            onClick={this.onHandleLogout}
                        >
                            {this.props.logoutSessionAbsModal}
                        </ButtonLogOut>
                        <ButtonOk
                            className="main-button"
                            onClick={this.onContinue}
                        >
                            {this.props.okSessionAbsModal}
                        </ButtonOk>
                    </ButtonWrapper>
                </ContentWrap>
            </Modal>
        )

    }
}

function mapStateToProps(state) {
    return {
        absoluteSessionTimer: state.session.absoluteSessionTimer,
        titleSessionAbsModal: parseTranslation('title-session-abs-modal'),
        contentSessionAbsModal: parseTranslation('content-session-abs-modal'),
        logoutSessionAbsModal: parseTranslation('logout-session-abs-modal'),
        okSessionAbsModal: parseTranslation('ok-session-abs-modal'),
    }
}

 function mapDispatchToProps(dispatch) {
    return {
        clearAbsoluteSessionTimer: () => dispatch(clearAbsoluteSessionTimer()),
        setAbsoluteSessionTimer: (payload) => dispatch(setAbsoluteSessionTimer(payload)),
        logout: (payload) => dispatch(logout(payload)),
    }
 }

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    background-color: ${colors.color52};
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: space-between;
    top: 0;
    width: 100%;
    height: 6.65rem;
    background: ${colors.color50};
    color: ${colors.color51};
    
    span {
      margin-left: 2rem;
      font-size: 3rem;
    }
    
    button {
      margin-right: 3rem;
      font-size: 3rem;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    padding: 6rem 10rem 0 10rem;
    
    p {
       color: ${colors.color14};
        font-size: 3rem;
    }
`;

const ButtonLogOut = styled.button`    
    margin-right: 4rem;    
`;

const ButtonOk = styled.button`
    
`;

export default connect(mapStateToProps, mapDispatchToProps)(SessionAbsoluteTimerModal);
