import React, {useEffect, useRef} from 'react';
import chartConfig from '../../../infrastructure/view/scene/Dashboard/components/Chart/chartConfig';
import parseTranslation from '../../../infrastructure/adapter/helper/parseTranslation';
let chartInstance = null;
export default function GuestDxChart({data, newTick, chartType, visibility, translation, height}) {
    let htmlContainer = useRef(null);
    const chartInstrument = {
        type: 'FOREX',
        precision: 2,
        priceIncrements: [0.00000100],
        pricePrecisions: [8],
    };
    const setTick = () => {
        const ticker = new window.chart.Candle(
            newTick.hi,
            newTick.lo,
            newTick.open,
            newTick.close,
            newTick.timestamp,
            newTick.volume);
        console.log(ticker, newTick, '777777777');
        chartInstance.bus.fire('candle', [ticker]);
    };

    useEffect(() => {
        data.length > 0 && chartInstance && newTick && Object.keys(newTick).length && setTick();
    }, [newTick]);
    const setChart = () => {
        chartInstance = new window.chart.ChartBootstrap(
            htmlContainer.current,
            {
                ...chartConfig(),
                components: {
                    ...chartConfig(
                        parseTranslation('label-chart1-pointer-open'),
                        parseTranslation('label-chart1-pointer-high'),
                        parseTranslation('label-close'),
                        parseTranslation('label-chart1-pointer-low'),
                        parseTranslation('label-volume'),
                    ).components,
                    chart: {
                        ...chartConfig().components.chart,
                        type: chartType,
                    },
                },
            },
        );
        chartInstance.setOffsets({
            ...{top: 5, right: 0, bottom: 5},
        });
        chartInstance.bus.fire('instrumentSelected', chartInstrument);

    };
    const toCandles = chartData => {
        return chartData.map(function(candleData) {
            return new window.chart.Candle(
                candleData.hi,
                candleData.lo,
                candleData.open,
                candleData.close,
                candleData.timestamp,
                candleData.volume
            );
        });
    };
    const setCandles = () => {
        const candles = toCandles(
            data.map(item => {
                return {
                    ...item,
                    timestamp: item.timestamp,
                    studyValues: {
                        values: [],
                    },
                };
            })
        );
        const candlesLength = candles.length;
        chartInstance.setCandles(candles);
        chartInstance.setXScale(candlesLength - 100, candlesLength);
    };
    const initChart = () => {
        setChart();
        setCandles();
    };
    useEffect(() => {
        htmlContainer.current.innerHTML = '';
        chartInstance = null;
        initChart();
    }, [data, translation]);
    useEffect(() => {
        chartInstance && chartInstance.setChartType(chartType);
    }, [chartType]);
    return (
        <div
            style={{display: visibility ? 'flex' : 'none'}}
            id="custom-chart"
            className="chart chartArea--graph"
            data-chart-container="true"
            ref={htmlContainer}
        />
    );
}
