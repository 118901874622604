export const ActionTypes = {
    updateDOM: 'dashboard-scene.DOM-component.update',
    setDOMLoading: 'dashboard-scene.DOM-component.loading',
    clearDOM: 'dashboard-scene.DOM-component.clear',
    setIsBuyTablePinnedToBottom: 'dashboard-scene.DOM-component.set-is-buy-table-pinned-to-bottom',
    updateTablesVisibility: 'dashboard-scene.DOM-component.update-tables-visibility'
};

export function updateDOM(orders) {
    return {
        type: ActionTypes.updateDOM,
        orders
    };
}
export function setLoading(value) {
    return {
        type: ActionTypes.setDOMLoading,
        value
    };
}

export function clearDOM() {
    return {
        type: ActionTypes.clearDOM
    };
}

export function setIsBuyTablePinnedToBottom(isBuyTablePinnedToBottom) {
    return {
        type: ActionTypes.setIsBuyTablePinnedToBottom,
        isBuyTablePinnedToBottom
    };
}

export function updateTablesVisibility(isTableBuyVisible, isTableSellVisible) {
    return {
        type: ActionTypes.updateTablesVisibility,
        isTableBuyVisible,
        isTableSellVisible
    };
}
