export const ActionTypes = {
    webSocketConnected: 'web-socket.connected',
    webSocketDisconnected: 'web-socket.disconnected',
    webSocketError: 'web-socket.error',
    DEALING_SOCKET_DISCONNECT: 'DEALING_SOCKET_DISCONNECT',
};

export function webSocketConnected() {
    return {
        type: ActionTypes.webSocketConnected,
    };
}

export function webSocketDisconnected() {
    return {
        type: ActionTypes.webSocketDisconnected,
    };
}

export function webSocketError(payload) {
    return {
        type: ActionTypes.webSocketError,
        payload,
    };
}

export function dealingSocketDisconnect() {
    return {
        type: ActionTypes.DEALING_SOCKET_DISCONNECT,
    };
}
