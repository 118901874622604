// @flow
class GetMathCaptcha {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(url: string): Promise<any> {
        return await this.adapter.getMathCaptcha(url);
    }
}

export default GetMathCaptcha;
