import React from 'react';
import {notificationMessageMap} from 'settings';
import {
    NotificationWrapper,
    Close,
    NotificationHeader,
    NotificationText,
    NotificationTitle,
} from './styled';
import parseTranslation from '../../../../adapter/helper/parseTranslation';

export default ({data, closeNotification}) => {
    const translationTitle = parseTranslation(
        `${notificationMessageMap[data.code]}_title`
    );

    const translationBody = `${notificationMessageMap[data.code]}_body`;

    const messageBody = parseTranslation(
        JSON.stringify({[translationBody]: data})
    );

    return (
        <NotificationWrapper>
            <NotificationHeader>
                <NotificationTitle>{translationTitle}</NotificationTitle>
                <Close
                    className="icon-close"
                    onClick={() => {
                        closeNotification(data.id, {[data.isVisible]: true});
                    }}
                />
            </NotificationHeader>
            <NotificationText>{messageBody}</NotificationText>
        </NotificationWrapper>
    );
};
