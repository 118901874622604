import styled, {css} from 'styled-components';

export const DropdownPosition = styled.div`
    position: absolute;
    top: 13.4rem;
    right: ${({align, offsetRight}) =>
        (align === 'right' && css`calc(${offsetRight}rem)`) || (align === 'center' && '50%') || 'auto'};
    left: ${({align}) => (align === 'left' && '0') || 'auto'};
    transform: ${({align}) =>
        (align === 'center' && 'translateX(50%)') || 'translateX(0)'};
    z-index: 20;
    display: flex;
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${props => props.theme.color1};
    background: ${props => props.theme.color23};
    position: relative;
    box-shadow: 0 0 3rem ${props => props.theme.color66};
    font-size: 2.333rem;    

    &::after {
        content: '';
        display: block;
        position: absolute;

        width: 2.5rem;
        height: 2.5rem;

        background: ${props => props.theme.color23};
        border: 1px solid ${props => props.theme.color1};
        border-bottom-color: transparent;
        border-right-color: transparent;
        bottom: 100%;
        left: ${({align}) =>
            (align === 'left' && '3rem') ||
            (align === 'center' && '50%') ||
            'auto'};
        right: ${({align, offsetArrowRight}) => (align === 'right' && css`calc(3rem + ${offsetArrowRight}rem)`) || 'auto'};
        transform: translateX(-50%) rotate(45deg);

        top: -1.5rem;
    }
`;
