// @flow
import axios from '../../../adapter/helper/axios';

import openNotify from '../../../../infrastructure/view/component/audio/notifications.ogg';

import NotificationAdapter from '../../../adapter/Notification';
import CloseNotification from '../../../../application/use-case/notification/CloseNotification';
import GetNotificationsHistory from '../../../../application/use-case/notification/GetNotificationsHistory';

import {
    ActionTypes as NotificationActionTypes,
    errorNotificationsHistory,
    notificationCloseAllError,
    notificationCloseError,
    notificationReceived,
    notificationsHistorySuccess,
    notificationTokenError,
} from '../action/notification';
import {notificationMessageMap} from 'settings';

const notificationAdapter = new NotificationAdapter(axios, WebSocket);
const closeNotification = new CloseNotification(notificationAdapter);
const getNotificationsHistory = new GetNotificationsHistory(
    notificationAdapter
);

const notificationAudio = new Audio(openNotify);
let isSubscribed = null;
export default function(store) {
    return function(next) {
        return async function(action) {
            if (action.type === NotificationActionTypes.NOTIFICATION_SOCKET_REQUEST) {
                const {accessToken} = store.getState().user;

                !isSubscribed && subscribeToNotificationEvents(notificationAdapter)(store);
                isSubscribed = true;
                try {
                    const {
                        data,
                    } = await notificationAdapter.getNotificationToken(
                        accessToken
                    );

                    await notificationAdapter.connect(data);
                } catch (error) {
                    store.dispatch(notificationTokenError(error));
                }
            }

            if (action.type === NotificationActionTypes.NOTIFICATION_SOCKET_DISCONNECT) {
                notificationAdapter.disconnect();
            }

            if (action.type === NotificationActionTypes.notificationReceived) {
                const {isSoundOn} = store.getState().settings;

                if (isSoundOn) {
                    notificationAudio.play();
                }
            }

            if (action.type === NotificationActionTypes.closeNotification) {
                try {
                    const {accessToken} = store.getState().user;
                    await notificationAdapter.closeNotification(
                        accessToken,
                        action.id
                    );
                } catch (error) {
                    store.dispatch(notificationCloseError(error));
                }
            }

            if (action.type === NotificationActionTypes.closeAllNotifications) {
                try {
                    const {accessToken} = store.getState().user;
                    await notificationAdapter.closeAllNotifications(
                        accessToken
                    );
                } catch (error) {
                    store.dispatch(notificationCloseAllError(error));
                }
            }

            if (
                action.type === NotificationActionTypes.getNotificationsHistory
            ) {
                try {
                    const {accessToken} = store.getState().user;
                    const {data} = await getNotificationsHistory.invoke(
                        accessToken
                    );
                    let result = [];
                    if (data) {
                        result = data.map(item => ({
                            ...item.data,
                            code: item.code,
                            id: item.id,
                        }));
                    }

                    store.dispatch(notificationsHistorySuccess(result));
                } catch (error) {
                    store.dispatch(errorNotificationsHistory(error));
                }
            }

            next(action);
        };
    };
}

function subscribeToNotificationEvents(adapter: NotificationAdapter) {
    return function(store: {dispatch: Function, getState: Function}) {
        adapter.on('notify-socket-onclose', async () => {
            const {accessToken} = store.getState().user;

            try {
                const {
                    data,
                } = await notificationAdapter.getNotificationToken(
                    accessToken
                );

                await notificationAdapter.connect(data);
            } catch (error) {
                store.dispatch(notificationTokenError(error));
            }
        });

        adapter.on('notification', ({id, code, data}) => {
            if (Object.keys(notificationMessageMap).includes(code)) {
                store.dispatch(
                    notificationReceived({
                        ...data,
                        id,
                        code,
                        isVisible: true,
                    })
                );
            }
        });
    };
}
