// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';
import Modal from 'react-modal';
import AvatarEditor from 'react-avatar-editor';
import {Redirect} from 'react-router-dom';
import Range from '../component/input-range';


import {getProfileForm, submitProfileForm} from '../service/actions';
import {
    showModal,
    hideModal,
    showDeleteModal,
    hideDeleteModal,
} from '../../../services/action/avatar';
import {getUserData} from '../../../services/action/user';
import {updateForm} from '../../../services/action/forms';

import FormField from '../../Registration/component/FormField';

import parseTranslation from '../../../../adapter/helper/parseTranslation';
import Error from '../../../component/Error';

function mapStateToProps(state) {
    const {locale} = state.settings;
    const {modalState, deleteModalState} = state.avatarModal;
    const {info} = state.user;
    const profileForm = state.forms['ProfileForm'] || {};
    const formError = state.forms.error;
    const formErrorProfile = state.forms.errorProfileForm;

    return {
        nameUser: info,
        profileForm,
        locale,
        modalState,
        deleteModalState,
        registrationDate: parseTranslation('registrationDate'),
        modalTitle: parseTranslation('modal-tittle'),
        modalDeleteTitle: parseTranslation('modal-delete-title'),
        saveBtn: parseTranslation('save-button'),
        cancelBtn: parseTranslation('cancel-button'),
        confirmBtn: parseTranslation('confirm-button'),
        confirmText: parseTranslation('confirm-text'),
        labelFirstName: parseTranslation('form-first-name'),
        labelUsername: parseTranslation('form-username-name'),
        labelLastName: parseTranslation('form-last-name'),
        labelEmail: parseTranslation('form-email'),
        labelCountry: parseTranslation('form-country'),
        labelCity: parseTranslation('form-city'),
        labelNumber: parseTranslation('form-phone-number'),
        labelGender: parseTranslation('form-gender'),
        labelButtonSaveChanges: parseTranslation('label-save-change'),
        formError,
        formErrorProfile,
    };
}

const mapDispatchToProps = {
    getProfileForm,
    submitProfileForm,
    updateForm,
    showModal,
    hideModal,
    showDeleteModal,
    hideDeleteModal,
    getUserData,
};

type PropTypes = {
    profileForm: {},
    getProfileForm: () => mixed,
    submitProfileForm: () => mixed,
    updateForm: () => mixed,
};

class ProfileSettings extends PureComponent<PropTypes> {
    userData = {
        first_name: null,
        last_name: null,
        avatar_url: null,
        created_at: null,
    };

    constructor(props) {
        super(props);
        this.avatar = React.createRef();
        this.changeAvatar = this.changeAvatar.bind(this);
        this.renderAvatar = this.renderAvatar.bind(this);
        this.saveAvatar = this.saveAvatar.bind(this);
        this.closeAvatarModal = this.closeAvatarModal.bind(this);
        this.dataURItoBlob = this.dataURItoBlob.bind(this);
        this.removeAvatar = this.removeAvatar.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }

    componentDidMount(): void {
        this.props.getProfileForm();
        const userDetails = localStorage.getItem('test');

        if (userDetails) {
            this.userData = JSON.parse(userDetails);
        } else {
            return <Redirect to="/login" />;
        }
    }

    componentDidUpdate(prevProps) {
        const {locale} = this.props;

        if (prevProps.locale !== locale) {
            this.props.getProfileForm();
        }
    }

    state = {
        rangeValue: 1,
    };

    render() {
        const {scheme, errors} = this.props.profileForm;
        const {nameUser, formError} = this.props;

        const userData = {
            username: this.userData.last_name || nameUser.username,
            avatar: this.userData.avatar_url || nameUser.avatar,
            email: this.userData.email || nameUser.email,
            createdAt: this.userData.created_at || nameUser.created_at,
        };

        return (
            <Fragment>
                {formError ? (
                    <Error />
                ) : (
                    <>
                        <Modal
                            isOpen={this.props.deleteModalState}
                            className="modal crop-modal remove-modal"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(20, 22, 27, 0.65)',
                                    zIndex: 2,
                                },
                            }}
                        >
                            <div className="modal-header">
                                <span>{this.props.modalDeleteTitle}</span>
                                <button
                                    onClick={() => this.props.hideDeleteModal()}
                                >
                                    <i className="icon-close" />
                                </button>
                            </div>

                            <div className="crop-modal__body">
                                <div className="delete-modal__desc">
                                    {this.props.confirmText}
                                </div>
                                <div className="modal-buttons">
                                    <button
                                        type={'button'}
                                        className="button gray"
                                        onClick={() =>
                                            this.props.hideDeleteModal()
                                        }
                                    >
                                        {this.props.cancelBtn}
                                    </button>

                                    <button
                                        type={'button'}
                                        className="button"
                                        onClick={this.confirmDelete}
                                    >
                                        {this.props.confirmBtn}
                                    </button>
                                </div>
                            </div>
                        </Modal>
                        <Modal
                            isOpen={this.props.modalState}
                            className="modal crop-modal"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(20, 22, 27, 0.65)',
                                    zIndex: 2,
                                },
                            }}
                        >
                            <div className="modal-header">
                                <span>{this.props.modalTitle}</span>
                                <button onClick={this.closeAvatarModal}>
                                    <i className="icon-close" />
                                </button>
                            </div>

                            <div className="crop-modal__body">
                                <AvatarEditor
                                    image={
                                        this.avatar.current &&
                                        this.avatar.current.files[0]
                                    }
                                    ref={this.setEditorRef}
                                    width={500}
                                    height={500}
                                    border={0}
                                    borderRadius={250}
                                    color={[11, 20, 35, 0.5]}
                                    scale={parseFloat(this.state.rangeValue)}
                                    rotate={0}
                                />
                               <Range onChange={e =>
                                   this.setState({
                                       rangeValue: e.target.value,
                                   })}/>
                                <div className="modal-buttons">
                                    <button
                                        type={'button'}
                                        className="button gray"
                                        onClick={this.closeAvatarModal}
                                    >
                                        {this.props.cancelBtn}
                                    </button>
                                    <button
                                        type={'button'}
                                        className="button"
                                        onClick={this.saveAvatar}
                                    >
                                        {this.props.saveBtn}
                                    </button>
                                </div>
                            </div>
                        </Modal>
                        <div className="user">
                            <div className="user__avatar">
                                {/* <input
                                type="file"
                                id={'avatar'}
                                accept="image/png,image/jpeg,image/jpg"
                                ref={this.avatar}
                                onChange={this.changeAvatar}
                            /> */}
                                <div className="avatar">
                                    {this.renderAvatar()}
                                </div>
                                <div className="ava-buttons">
                                    <input
                                        type="file"
                                        id={'avatar'}
                                        accept="image/png,image/jpeg,image/jpg"
                                        ref={this.avatar}
                                        onChange={this.changeAvatar}
                                    />
                                    <label
                                        htmlFor="avatar"
                                        className="ava-button"
                                    >
                                        <i className="icon-download" />
                                    </label>
                                    {!!userData.avatar &&
                                        userData.avatar &&
                                        !!userData.avatar && (
                                            <div
                                                className="ava-button"
                                                onClick={this.removeAvatar}
                                            >
                                                <i className="icon-chart-delete" />
                                            </div>
                                        )}
                                </div>
                            </div>
                            <div className="user__info">
                                <span className="name">
                                    {userData.username}
                                </span>
                                <span>{this.props.registrationDate}</span>
                                <span className="date">
                                    {moment
                                        .unix(userData.createdAt)
                                        .format('DD.MM.YYYY')}
                                </span>
                            </div>
                        </div>

                        <form>

                            <div className="form-group form-group_row from-profile">
                                {scheme &&
                                    Object.values(scheme).map(field => {
                                        if (field.type === 'file') {
                                            return '';
                                        }
                                        return (
                                            <FormField
                                                key={field.name}
                                                name={userData.username}
                                                type={field.type}
                                                label={field.label}
                                                options={field.options}
                                                value={field.value}
                                                onChange={() => {}}
                                                error={
                                                    !Array.isArray(errors) &&
                                                    errors[field.name] &&
                                                    errors[field.name][0]
                                                }
                                                disabled={true}
                                                typeFileParams={{}}
                                            />
                                        );
                                    })}
                            </div>
                        </form>

                        {/*<div className="form-group">*/}
                        {/*<label>{ this.props.labelFirstName }</label>*/}
                        {/*<input type="text" />*/}
                        {/*<label>{ this.props.labelUsername }</label>*/}
                        {/*<input type="text" />*/}
                        {/*<label>{ this.props.labelLastName }</label>*/}
                        {/*<input type="text" />*/}
                        {/*<label>{ this.props.labelEmail }</label>*/}
                        {/*<input type="email" />*/}
                        {/*<label>{ this.props.labelCountry }</label>*/}
                        {/*<input type="text" />*/}
                        {/*<label>{ this.props.labelCity }</label>*/}
                        {/*<input type="text" />*/}
                        {/*<label>{ this.props.labelNumber }</label>*/}
                        {/*<input type="tel" />*/}
                        {/*<label>{ this.props.labelGender }</label>*/}
                        {/*<input type="text"/>*/}
                        {/*<button className="changes bubble">{ this.props.labelButtonSaveChanges }</button>*/}

                        {/*</div>*/}
                    </>
                )}
            </Fragment>
        );
    }

    async saveAvatar() {
        if (this.editor) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            const canvas = this.editor.getImageScaledToCanvas();

            const src = canvas.toDataURL();

            const blob = this.dataURItoBlob(src);

            const image = new File([blob], 'avatar.png');

            this.props.updateForm('ProfileForm', 'avatar', image);
            this.closeAvatarModal();
            await this.props.submitProfileForm();
        }
    }

    removeAvatar() {
        this.props.showDeleteModal();
    }

    async confirmDelete() {
        this.props.updateForm('ProfileForm', 'remove_avatar', true);
        this.props.hideDeleteModal();

        await this.props.submitProfileForm();
    }

    closeAvatarModal() {
        this.props.hideModal();
        this.avatar.current.value = '';
        this.setState({rangeValue: 1});
    }
    setEditorRef = editor => (this.editor = editor);

    renderAvatar() {
        const userData = {
            avatar: this.userData.avatar_url || this.props.nameUser.avatar,
            username: this.userData.last_name || this.props.nameUser.username,
        };

        if (userData.avatar) {
            return <img src={userData.avatar} alt={'avatar'} />;
        } else {
            return (
                <div className="avatar-text">
                    {userData.username.slice(0, 2).toUpperCase()}
                </div>
            );
        }
        // const { scheme } = this.props.profileForm;

        // if (!!scheme && scheme.avatar && !!scheme.avatar.value) {
        //     return <img src={scheme.avatar.value} alt={'avatar'} />;
        // } else {
        //     return (
        //         <div className="avatar-text">
        //             {this.props.nameUser.username.slice(0, 2).toUpperCase()}
        //         </div>
        //     );
        // }
    }

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI
            .split(',')[0]
            .split(':')[1]
            .split(';')[0];

        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        var ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        var blob = new Blob([ab], {type: mimeString});
        return blob;
    }

    changeAvatar() {
        // this.props.updateForm('ProfileForm', 'avatar', this.avatar.current.files[0]);
        this.props.hideModal();
        this.props.showModal();
        // this.props.submitProfileForm();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileSettings);
