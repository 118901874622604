export default [
    {
        "id": "ChandeMomentumOscillator",
        "title": "Chande Momentum Oscillator",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "CMO",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "UpperLevel",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "LowerLevel",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator",
        "uiid": 1
    },
    {
        "id": "AverageTrueRange",
        "title": "Average True Range",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {"min": 1, "max": null, "precision": 0}
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "ATR",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": ["#d90100"]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator",
        "uiid": 2
    },
    {
        "id": "ZigZag",
        "title": "ZigZag",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "HIGH",
                "validationTO": null
            },
            {
                "id": "deviation",
                "studyParamType": "INTEGER_RANGE",
                "value": 5,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "ZigZag",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend",
        "uiid": 3
    },
    {
        "id": "LinearRegressionChannel",
        "title": "Linear Regression Channel",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "width",
                "studyParamType": "DOUBLE_RANGE",
                "value": 100.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            }
        ],
        "lines": [
            {
                "title": "Lower",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "LinReg",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "Upper",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other",
        "uiid": 4
    },
    {
        "id": "WildersSmoothing",
        "title": "Wilders Smoothing",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "WS",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other",
        "uiid": 5
    },
    {
        "id": "AccumulationDistribution",
        "title": "Accumulation/Distribution",
        "parameters": [],
        "lines": [
            {
                "title": "AccDist",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volume",
        "uiid": 6
    },
    {
        "id": "WilliamsFractal",
        "title": "Williams Fractal",
        "parameters": [
            {
                "id": "offset",
                "studyParamType": "INTEGER_RANGE",
                "value": 2,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "UpFractal",
                "studyLineType": "ARROW_UP_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            },
            {
                "title": "DownFractal",
                "studyLineType": "ARROW_DOWN_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other",
        "uiid": 7
    },
    {
        "id": "WilliamsAD",
        "title": "Williams AD",
        "parameters": [],
        "lines": [
            {
                "title": "WAD",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other",
        "uiid": 8
    },
    {
        "id": "FullStochastic",
        "title": "Full Stochastic",
        "parameters": [
            {
                "id": "kPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "kSlowing",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "dPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "SIMPLE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "FullK",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            },
            {
                "title": "FullD",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator",
        "uiid": 9
    },
    {
        "id": "HLVolatility",
        "title": "H-L Volatility",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "H-LVlt",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volatility"
    },
    {
        "id": "Elder",
        "title": "Elder Ray",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 13,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "BullPower",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            },
            {
                "title": "BearPower",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator",
        "uiid": 10
    },
    {
        "id": "PriceVolumeTrend",
        "title": "Price and Volume Trend",
        "parameters": [],
        "lines": [
            {
                "title": "PVT",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Trend"
    },
    {
        "id": "ADX",
        "title": "ADX",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "ADX",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Trend",
        "uiid": 11
    },
    {
        "id": "Oscillator",
        "title": "Oscillator",
        "parameters": [
            {
                "id": "fastLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "slowLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 28,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "VOLUME",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "Oscillator",
                "studyLineType": "TREND_HISTOGRAM_TYPE",
                "thickness": 1,
                "colors": [
                    "green",
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator",
        "uiid": 12
    },
    {
        "id": "RelativeVigorIndexSMA",
        "title": "Relative Vigor Index SMA",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "RVI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator",
        "uiid": 13
    },
    {
        "id": "PriceChannel",
        "title": "Price Channel",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "shiftLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "PCU",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "PCL",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend",
        "uiid": 14
    },
    {
        "id": "RelativeVigorIndex",
        "title": "Relative Vigor Index",
        "parameters": [],
        "lines": [
            {
                "title": "RVI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator",
        "uiid": 15
    },
    {
        "id": "EMA",
        "title": "EMA",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "AvgExp",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend",
        "uiid": 16
    },
    {
        "id": "VWAP",
        "title": "Volume Weighted Average Price",
        "parameters": [],
        "lines": [
            {
                "title": "VWAP",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "MoneyFlowIndex",
        "title": "Money Flow Index",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "MFIdx",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volume",
        "uiid": 18
    },
    {
        "id": "MarketFacilitationIndex",
        "title": "Market Facilitation Index",
        "parameters": [],
        "lines": [
            {
                "title": "MFIdx",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Trend",
        "uiid": 19
    },
    {
        "id": "LinearRegression",
        "title": "Linear Regression Curve",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "LinearRegression",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other",
        "uiid": 20
    },
    {
        "id": "OnBalanceVolume",
        "title": "On Balance Volume",
        "parameters": [],
        "lines": [
            {
                "title": "OBV",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volume",
        "uiid": 21
    },
    {
        "id": "ParabolicSAR",
        "title": "Parabolic SAR",
        "parameters": [
            {
                "id": "high",
                "studyParamType": "PRICE_FIELD",
                "value": "HIGH",
                "validationTO": null
            },
            {
                "id": "low",
                "studyParamType": "PRICE_FIELD",
                "value": "LOW",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "SAR",
                "studyLineType": "POINTS_TYPE",
                "thickness": 3,
                "colors": [
                    "white"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other",
        "uiid": 22
    },
    {
        "id": "DynamicMomentumIndex",
        "title": "Dynamic Momentum Index",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "stDevLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 5,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "stDevAverageLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "dynamicLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "minDynamicLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "maxDynamicLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 30,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 70,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 30,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "DYMI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other",
        "uiid": 24
    },
    {
        "id": "CenterOfGravityOscillator",
        "title": "Center Of Gravity Oscillator",
        "parameters": [
            {
                "id": "period",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "COG",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "PercentChange",
        "title": "Percent Change",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "PercentChange",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "ForecastOscillator",
        "title": "Forecast Oscillator",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 5,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "smLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "FOsc",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Signal",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "WilliamsAlligator",
        "title": "Williams Alligator",
        "parameters": [
            {
                "id": "priceHigh",
                "studyParamType": "PRICE_FIELD",
                "value": "HIGH",
                "validationTO": null
            },
            {
                "id": "priceLow",
                "studyParamType": "PRICE_FIELD",
                "value": "LOW",
                "validationTO": null
            },
            {
                "id": "jawLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 13,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "jawDisplace",
                "studyParamType": "INTEGER_RANGE",
                "value": 8,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "teethLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 8,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "teethDisplace",
                "studyParamType": "INTEGER_RANGE",
                "value": 5,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "lipsLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 5,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "lipsDisplace",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "Jaw",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "Teeth",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Lips",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "UltimateOscillator",
        "title": "Ultimate Oscillator",
        "parameters": [
            {
                "id": "fastLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 7,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "mediumLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "slowLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 28,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "UO",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "SMA",
        "title": "SMA",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "SMA",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "ROC",
        "title": "ROC",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 12,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "ROC",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "WeightedClose",
        "title": "Weighted Close",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "WtdClose",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Avg",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "WMA",
        "title": "WMA",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "WMA",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "DeMarker",
        "title": "DeMarker",
        "parameters": [],
        "lines": [
            {
                "title": "DeMarker",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "DayOpenClose",
        "title": "Day Open-Close",
        "parameters": [
            {
                "id": "aggregation",
                "studyParamType": "AGGREGATION",
                "value": "DAY",
                "validationTO": null
            },
            {
                "id": "showOnlyLast",
                "studyParamType": "BOOLEAN",
                "value": false,
                "validationTO": null
            },
            {
                "id": "rightexpansion",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": 0,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "DailyOpen",
                "studyLineType": "PIECEWISE_NO_MARKER_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "DailyClose",
                "studyLineType": "PIECEWISE_NO_MARKER_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "COMPARATIVE",
        "title": "Comparative study",
        "parameters": [
            {
                "id": "symbol",
                "studyParamType": "STRING",
                "value": null,
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "Comparison",
                "studyLineType": "COMPARATIVE_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Comparative"
    },
    {
        "id": "StandardErrorBands",
        "title": "Standard Error Bands",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "linRegLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 21,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "smLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "numDevUp",
                "studyParamType": "DOUBLE_RANGE",
                "value": 2.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "numDevDown",
                "studyParamType": "DOUBLE_RANGE",
                "value": -2.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            }
        ],
        "lines": [
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "MidLine",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "TripleEMA",
        "title": "TRIX",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "TRIX",
                "studyLineType": "HISTOGRAM_TYPE",
                "thickness": 1,
                "colors": [
                    "green",
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "PercentagePriceOscillator",
        "title": "Percentage Price Oscillator",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "fastLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "slowLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 18,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "EXPONENTIAL",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "PercentagePriceOscillator",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "TypicalPrice",
        "title": "Typical Price",
        "parameters": [],
        "lines": [
            {
                "title": "TP",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "Inertia",
        "title": "Inertia",
        "parameters": [
            {
                "id": "rviLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "regressionLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "Inertia",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Middle",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "RelativeStrengthIndex",
        "title": "Relative Strength Index",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 70,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 30,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "RSI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "SchaffTrendCycle",
        "title": "Schaff Trend Cycle",
        "parameters": [
            {
                "id": "fastLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 23,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "slowLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 50,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "kPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "kSlowing",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "SchaffTC",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "StandardDeviationChannel",
        "title": "Standard Deviation Channel",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "deviations",
                "studyParamType": "DOUBLE_RANGE",
                "value": 3.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            }
        ],
        "lines": [
            {
                "title": "LinReg",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Resistance",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            },
            {
                "title": "Support",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Volatility"
    },
    {
        "id": "FastStochastic",
        "title": "Fast Stochastic",
        "parameters": [
            {
                "id": "kPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "dPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "SIMPLE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "FastK",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            },
            {
                "title": "FastD",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "EnvelopeWMA",
        "title": "WMA Envelope",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "percentAbove",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "percentBelow",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "priceAbove",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "priceBelow",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            },
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "NegativeVolumeIndex",
        "title": "Negative Volume Index",
        "parameters": [],
        "lines": [
            {
                "title": "NVI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volume"
    },
    {
        "id": "TimeSeriesForecast",
        "title": "Time Series Forecast",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "regressionLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 2,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "forecastLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 7,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "TSF",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Oscillator"
    },
    {
        "id": "KRI",
        "title": "Kairi Relative Index",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "KRI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "ADXR",
        "title": "ADXR",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "ADX",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "ADXR",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Trend"
    },
    {
        "id": "PercentOfResistance",
        "title": "Percent Of Resistance",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "POR",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "BollingerBands",
        "title": "Bollinger Bands",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "numDevDown",
                "studyParamType": "DOUBLE_RANGE",
                "value": -2.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "numDevUp",
                "studyParamType": "DOUBLE_RANGE",
                "value": 2.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "EXPONENTIAL",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "MidLine",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "SMMA",
        "title": "SMMA",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "SMMA",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "DEMA",
        "title": "DEMA",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "DEMA",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "TrueStrengthIndex",
        "title": "True Strength Index",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "longLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 25,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "shortLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 13,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "signalLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 8,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "EXPONENTIAL",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "TSI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Signal",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "SwingAccumulation",
        "title": "Accumulation Swing Index",
        "parameters": [
            {
                "id": "limit",
                "studyParamType": "DOUBLE_RANGE",
                "value": 3.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            }
        ],
        "lines": [
            {
                "title": "ASI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "PivotPoints",
        "title": "Pivot Points",
        "parameters": [
            {
                "id": "aggregation",
                "studyParamType": "AGGREGATION",
                "value": "DAY",
                "validationTO": null
            },
            {
                "id": "showOnlyLast",
                "studyParamType": "BOOLEAN",
                "value": false,
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "R3",
                "studyLineType": "PIECEWISE_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            },
            {
                "title": "R2",
                "studyLineType": "PIECEWISE_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            },
            {
                "title": "R1",
                "studyLineType": "PIECEWISE_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            },
            {
                "title": "PP",
                "studyLineType": "PIECEWISE_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "S1",
                "studyLineType": "PIECEWISE_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "S2",
                "studyLineType": "PIECEWISE_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "S3",
                "studyLineType": "PIECEWISE_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "KeltnerChannels",
        "title": "Keltner Channels",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "factor",
                "studyParamType": "DOUBLE_RANGE",
                "value": 2.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "SIMPLE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "MidLine",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "ChaikinOscillator",
        "title": "Chaikin Oscillator",
        "parameters": [
            {
                "id": "shortLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "longLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "ChaikinOsc",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "EnvelopeSMA",
        "title": "SMA Envelope",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "percentAbove",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "percentBelow",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "priceAbove",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "priceBelow",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            },
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "EnvelopeEMA",
        "title": "EMA Envelope",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "percentAbove",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "percentBelow",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "priceAbove",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "priceBelow",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            },
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "IntradayMomentumIndex",
        "title": "Intraday Momentum Index",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 70,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 30,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "IMI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "Aroon",
        "title": "Aroon Indicator",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 25,
                "validationTO": {
                    "min": 2,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 70,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 30,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "AroonUp",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "AroonDown",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "SROC",
        "title": "SmoothedRateOfChange",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 12,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "shiftLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 6,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "ROC",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "MACD",
        "title": "MACD",
        "parameters": [
            {
                "id": "fastLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 12,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "slowLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 26,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "macdLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "EXPONENTIAL",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "Value",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Avg",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "Diff",
                "studyLineType": "HISTOGRAM_TYPE",
                "thickness": 1,
                "colors": [
                    "green",
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "MedianPrice",
        "title": "Median Price",
        "parameters": [],
        "lines": [
            {
                "title": "MP",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "MassIndex",
        "title": "Mass Index",
        "parameters": [
            {
                "id": "setup",
                "studyParamType": "DOUBLE_RANGE",
                "value": 27.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "trigger",
                "studyParamType": "DOUBLE_RANGE",
                "value": 26.5,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "sumLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 25,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "MI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Trigger",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "Setup",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Trend"
    },
    {
        "id": "RankCorrelationIndex",
        "title": "Spearman",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 2,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "averageLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": -80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "Spearman",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "SpearmanAverage",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "StandardDeviation",
        "title": "Standard Deviation",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "StdDev",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volatility"
    },
    {
        "id": "AccelerationDeceleration",
        "title": "Acceleration/Deceleration",
        "parameters": [],
        "lines": [
            {
                "title": "A/D",
                "studyLineType": "TREND_HISTOGRAM_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia",
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "DMI",
        "title": "DMI",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "DI-",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "DI+",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "ADX",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Trend"
    },
    {
        "id": "TEMA",
        "title": "TEMA",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "TEMA",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "WilliamsPercentRange",
        "title": "Williams Percent Range",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": -20,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": -80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "WPR",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "VerticalHorizontalFilter",
        "title": "Vertical Horizontal Filter",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "VHF",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "AwesomeOscillator",
        "title": "Awesome Oscillator",
        "parameters": [],
        "lines": [
            {
                "title": "AwesomeOsc",
                "studyLineType": "TREND_HISTOGRAM_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia",
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "ForceIndex",
        "title": "Force Index",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 13,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "ForceIndex",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "CCI",
        "title": "CCI",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 100,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": -100,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "CCI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Trend"
    },
    {
        "id": "Momentum",
        "title": "Momentum",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 12,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "Momentum",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "TMA",
        "title": "TMA",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "TMA",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "CommoditySelection",
        "title": "CSI",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "bigPointValue",
                "studyParamType": "DOUBLE_RANGE",
                "value": 1.0,
                "validationTO": {
                    "min": 0.01,
                    "max": null,
                    "precision": 2
                }
            },
            {
                "id": "myMargin",
                "studyParamType": "DOUBLE_RANGE",
                "value": 1000.0,
                "validationTO": {
                    "min": 0.01,
                    "max": null,
                    "precision": 2
                }
            },
            {
                "id": "myCommission",
                "studyParamType": "DOUBLE_RANGE",
                "value": 25.0,
                "validationTO": {
                    "min": 0.0,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "CSI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "GatorOscillator",
        "title": "Gator Oscillator",
        "parameters": [
            {
                "id": "priceHigh",
                "studyParamType": "PRICE_FIELD",
                "value": "HIGH",
                "validationTO": null
            },
            {
                "id": "priceLow",
                "studyParamType": "PRICE_FIELD",
                "value": "LOW",
                "validationTO": null
            },
            {
                "id": "jawLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 13,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "jawDisplace",
                "studyParamType": "INTEGER_RANGE",
                "value": 8,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "teethLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 8,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "teethDisplace",
                "studyParamType": "INTEGER_RANGE",
                "value": 5,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "lipsLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 5,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "lipsDisplace",
                "studyParamType": "INTEGER_RANGE",
                "value": 3,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "WILDERS",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "Histogram1",
                "studyLineType": "HISTOGRAM_TYPE",
                "thickness": 1,
                "colors": [
                    "blue",
                    "red"
                ]
            },
            {
                "title": "Histogram2",
                "studyLineType": "HISTOGRAM_TYPE",
                "thickness": 1,
                "colors": [
                    "green",
                    "red"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "SlowStochastic",
        "title": "Slow Stochastic",
        "parameters": [
            {
                "id": "kPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "dPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 80,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 20,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "SIMPLE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "SlowK",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            },
            {
                "title": "SlowD",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "DetrendedPriceOsc",
        "title": "Detrended Price Osc",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "DPO",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "ZeroLine",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "Ichimoku",
        "title": "Ichimoku",
        "parameters": [
            {
                "id": "tenkanPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "kijunPeriod",
                "studyParamType": "INTEGER_RANGE",
                "value": 26,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "tenkanSen",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "kijunSen",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "chikouSpan",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "green"
                ]
            },
            {
                "title": "senkouSpanA",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "senkouSpanB",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend",
        "uuid": 27
    },
    {
        "id": "AroonOscillator",
        "title": "Aroon Oscillator",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 25,
                "validationTO": {
                    "min": 2,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 50,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": -50,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "AroonOscillator",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "MidLine",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "SwingIndex",
        "title": "Swing Index",
        "parameters": [
            {
                "id": "limit",
                "studyParamType": "DOUBLE_RANGE",
                "value": 3.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            }
        ],
        "lines": [
            {
                "title": "SI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "RelativeVolatilityIndex",
        "title": "Relative Volatility Index",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "overbought",
                "studyParamType": "INTEGER_RANGE",
                "value": 70,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "oversold",
                "studyParamType": "INTEGER_RANGE",
                "value": 30,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "RVI",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "OverBought",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "OverSold",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Oscillator"
    },
    {
        "id": "EnvelopeSMMA",
        "title": "SMMA Envelope",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "percentAbove",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "percentBelow",
                "studyParamType": "DOUBLE_RANGE",
                "value": 10.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            },
            {
                "id": "priceAbove",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "priceBelow",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            },
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "fuchsia"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Trend"
    },
    {
        "id": "PriceOscillator",
        "title": "Price Oscillator",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "fastLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 9,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "slowLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 18,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "average",
                "studyParamType": "AVERAGE",
                "value": "EXPONENTIAL",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "PriceOscillator",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "STARCBands",
        "title": "STARC Bands",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "atrLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 15,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "smaLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 6,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "displace",
                "studyParamType": "INTEGER_RANGE",
                "value": 0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "multiplierFactor",
                "studyParamType": "DOUBLE_RANGE",
                "value": 2.0,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 1
                }
            }
        ],
        "lines": [
            {
                "title": "LowerBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            },
            {
                "title": "MidLine",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "blue"
                ]
            },
            {
                "title": "UpperBand",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": true,
        "categories": "Other"
    },
    {
        "id": "LinearRegressionSlope",
        "title": "Linear Regression Slope",
        "parameters": [
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            },
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 2,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "LinearRegressionSlope",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "yellow"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Other"
    },
    {
        "id": "ChaikinVolatility",
        "title": "Chaikin Volatility",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "shiftLength",
                "studyParamType": "INTEGER_RANGE",
                "value": 10,
                "validationTO": {
                    "min": null,
                    "max": null,
                    "precision": 0
                }
            }
        ],
        "lines": [
            {
                "title": "ChaikinVlt",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            },
            {
                "title": "Zero",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "red"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volatility"
    },
    {
        "id": "StdDevVolatility",
        "title": "StdDev Volatility",
        "parameters": [
            {
                "id": "length",
                "studyParamType": "INTEGER_RANGE",
                "value": 14,
                "validationTO": {
                    "min": 1,
                    "max": null,
                    "precision": 0
                }
            },
            {
                "id": "price",
                "studyParamType": "PRICE_FIELD",
                "value": "CLOSE",
                "validationTO": null
            }
        ],
        "lines": [
            {
                "title": "StdDevVlt",
                "studyLineType": "LINEAR_TYPE",
                "thickness": 1,
                "colors": [
                    "aqua"
                ]
            }
        ],
        "overlaying": false,
        "categories": "Volatility"
    }
]
