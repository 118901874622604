import React from 'react';
import {Title, Button} from './styled';
import Tooltip from '../Tooltip';

export default function ChartButton({title, icon, withArrow, tooltip}) {
    return (<Button withArrow={withArrow}>
        {icon && <i className={icon}/>}
        {title && <Title>{title}</Title>}
        {tooltip && <Tooltip title={tooltip} topCenter />}
    </Button>)
}
