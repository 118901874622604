export const LayoutActionTypes = {
    open: 'dashboard-scene.header-component.layout-modal.open',
    close: 'dashboard-scene.header-component.layout-modal.close',

    setActiveDashboardIndex: 'dashboard-scene.header-component.layout-modal.set-active-dashboard-index'
};

export function open() {
    return {
        type: LayoutActionTypes.open
    };
}

export function close() {
    return {
        type: LayoutActionTypes.close
    };
}

export function setActiveDashboardIndex(activeDashboardIndex) {
    return {
        type: LayoutActionTypes.setActiveDashboardIndex,
        activeDashboardIndex
    };
}