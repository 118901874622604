// @flow
import moment, {type Moment} from 'moment';
import EventEmitter from 'events';
import {uniqueId} from 'lodash';

import store from 'infrastructure/view/services/store'
import Config from '../../../localConfig';
import toCamelCase from 'infrastructure/polyfill/toCamelCase';
import {type Room, type Message, type IncomingTypingStatus} from './reducer';
import {socketError} from './action';


export interface ChatServiceWS {
    constructor(webSocketClient: WebSocket): void;
    connect(token: string): Promise<any>;
    disconnect(): void;
    on(eventName: string, handler: Function): void;

    handleRoom(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }): void;
    handleNewMessage(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }): void;
    handleTypingShow(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }): void;
    handleTypingStop(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }): void;
    handleTypingHide(data: {}): void;
    handleJoinEditor(data: {}): void;
    handleJoinViewer(data: {}): void;
    handleLeaveEditor(data: {}): void;
    handleLeaveViewer(data: {}): void;
    handleCloseChat(data: {}): void;
    handleJoinCreator(data: {}): void;
    handleClientLeaveChatInfo(data: {}): void;
    handleEditorRemovedFromChat(data: {}): void;

    sendMessage(messageData: {|
        type: string,
        roomId: string,
        data: string,
    |}): void;

}

class ChatServiceIMPL implements ChatServiceWS {
    token: string;
    eventEmitter: EventEmitter;
    handlers: {
        [string]: (any) => mixed,
    };
    socket: WebSocket | null;
    webSocketClient: Function;

    constructor(webSocketClient: WebSocket): void {
        this.webSocketClient = webSocketClient;

        this.eventEmitter = new EventEmitter();

        // TODO: Do all events like this handler
        this.handlers = {
            room: this.handleRoom.bind(this),
            new_message: this.handleNewMessage.bind(this),
            typing_hide: this.handleTypingShow.bind(this),
            typing_stop: this.handleTypingStop.bind(this),
            // 'typing_hide': this.handleTypingHide.bind(this),
            // 'join_editor': this.handleJoinEditor.bind(this),
            // 'join_viewer': this.handleJoinViewer.bind(this),
            // 'leave_editor': this.handleLeaveEditor.bind(this),
            // 'leave_viewer': this.handleLeaveViewer.bind(this),
            // 'close_chat': this.handleCloseChat.bind(this),
            // 'join_creator': this.handleJoinCreator.bind(this),
            // 'client_leave_chat_info': this.handleClientLeaveChatInfo.bind(this),
            // 'editor_removed_from_chat': this.handleEditorRemovedFromChat.bind(this)

            //balances: this.handleBalances.bind(this)
        };
    }

    async connect(token: string): Promise<any> {
        return new Promise(resolve => {
            // TODO: Move all the static URLs to configuration
            this.socket = new this.webSocketClient(
                `${Config.wsUrl()}/chat/${token}`
            );

            this.socket.onopen = () => {
                resolve();
            };

            // TODO: Set socket instance to NULL on this event
            this.socket.onclose = (event) => {
                if (event.code === 4000) {
                    this.eventEmitter.emit('chat-socket-onclose');
                }
                this.eventEmitter.emit('socketClosed', false);
                this.socket = null;
            };

            // TODO: Add WS-event type
            this.socket.onmessage = event => {
                try {
                    const data = JSON.parse(event.data);

                    const {type}: {type: string} = data;

                    if (type in this.handlers) {
                        this.handlers[type](data);
                        return false;
                    }
                } catch (error) {
                    console.error('Message was failed', error, event.data);
                }
            };

            this.socket.onerror = error => {
                this.eventEmitter.emit('socketClosed', false);
                console.error('Error from socket ', error);

                store.dispatch(socketError(error));
            };
        });
    }

    disconnect(): void {
        this.eventEmitter.emit('socketClosed', false);
        if (this.socket) {
            this.socket.close();
        }
    }

    on(eventName: string, handler: Function) {
        this.eventEmitter.on(eventName, handler);
    }

    handleRoom(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }) {
        const eventData: Room = Object.keys(data).reduce((result, key) => {
            if (key === 'type') {
                return result;
            } else if (key === 'data') {
                result['roomId'] = data[key];
                return result;
            }
            result[toCamelCase(key)] = data[key];
            return result;
        }, {});
        const room = store.getState()['module.chat'].room.roomId;
        if (room) {
            eventData.roomId !== room && this.eventEmitter.emit('room-closed');
            return
        }

        this.eventEmitter.emit('room', eventData);

    }

    handleNewMessage(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }) {
        const eventData: Message = Object.keys(data).reduce((result, key) => {
            if (key === 'type') {
                return result;
            } else if (key === 'create_time') {
                const timeValue = data[key];
                result[toCamelCase(key)] = {
                    date: moment.unix(timeValue).format('DD/MM/YY'),
                    time: moment.unix(timeValue).format('HH:mm:ss'),
                };
                return result;
            }
            result['id'] = uniqueId('msg-');
            result[toCamelCase(key)] = data[key];
            return result;
        }, {});

        this.eventEmitter.emit('newMessage', eventData);
    }

    handleTypingShow(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }) {
        const eventData: IncomingTypingStatus = Object.keys(data).reduce(
            (result, key) => {
                if (key === 'username') {
                    result[key] = data[key];
                    result['status'] = true;
                    return result;
                }
                return result;
            },
            {}
        );
        this.eventEmitter.emit('incomingTyping', eventData);
    }

    handleTypingStop(data: {
        type: string,
        data: string,
        username: string,
        avatar: string,
        create_time: Moment,
    }) {
        const eventData: IncomingTypingStatus = Object.keys(data).reduce(
            (result, key) => {
                if (key === 'username') {
                    result[key] = data[key];
                    result['status'] = false;
                    return result;
                }
                return result;
            },
            {}
        );

        this.eventEmitter.emit('incomingTypingStop', eventData);
    }

    sendMessage(messageData: {|type: string, roomId: string, data: string|}) {
        const messageSerialized = JSON.stringify(messageData);
        this.socket && this.socket.send(messageSerialized);
    }

    // handleBalances(data: {
    //     [string]: {
    //         available: string,
    //         btc: string,
    //         name: string,
    //         order: string,
    //         total: string
    //     }
    // }) {
    //     const eventData: {[string]: BalancesDataRow} = Object.keys(data).reduce(
    //         (result, key) => {
    //             const rowObj = data[key];
    //             const mappedData = Object.keys(rowObj).reduce(
    //                 (resultRow, rowKey) => {
    //                     if (rowKey === 'name') {
    //                         resultRow[rowKey] = rowObj[rowKey];
    //                         return resultRow;
    //                     }
    //                     resultRow[rowKey] = Number(rowObj[rowKey]);
    //                     return resultRow;
    //                 },
    //                 {}
    //             );
    //             result[key] = mappedData;
    //             return result;
    //         },
    //         {}
    //     );
    //     this.eventEmitter.emit('balances', eventData);
    // }
}

export default ChatServiceIMPL;
