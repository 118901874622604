import React from 'react';
import {
    ModalBody,
    ModalHeader,
    ModalHolder,
    ModalWrapper,
    ModalClose,
} from './styled';
import {useDispatch} from 'react-redux';
import {setModalOpen} from './actions';

export default function Modal({title, children, isOpen, width}) {
    const dispatch = useDispatch();
    const handleClose = () => dispatch(setModalOpen(false));
    return (
        <>
            {isOpen && (
                <ModalWrapper>
                    <ModalHolder width={width}>
                        <ModalHeader>
                            {title}
                            <ModalClose className="icon-close" onClick={handleClose} />
                        </ModalHeader>
                        <ModalBody>{children}</ModalBody>
                    </ModalHolder>
                </ModalWrapper>
            )}
        </>
    );
}
