import React, {useState} from 'react';
import {Fill, Wrapper} from './styled';

export default ({onChange}) => {
    const [value, setValue] = useState(1);
    return (
        <Wrapper>
            <input
                type="range"
                className="modal__range slider"
                defaultValue={1}
                min={1}
                max={5}
                step={0.1}
                onInput={e => {
                    onChange(e);
                    setValue(((e.target.value - 1) / 4) * 100);
                }}
            />
            <Fill left={value} />
        </Wrapper>
    );
};
