import React, {useState} from 'react';
import t from '../../infrastructure/adapter/helper/parseTranslation';
import ChartButton from '../components/ChartButton';
import DropDown from '../components/DropDown';
import CurrencyPairContainer from '../CurrencyPairContainer';
import {
    Pair,
    Wrapper,
    ButtonWrap,
    Side,
    Volume,
    VolumeData,
    VolumeLabel,
} from './styled';
import {intervals, chartTypes} from './constants';
import {useDispatch, useSelector} from 'react-redux';
import {changeInterval, getChartHistory} from '../GuestChart/actions';
import {setChartType} from './actions';
import {dealingAssets} from '../webSockets/constants';

export default function ChartMenu() {
    const dispatch = useDispatch();
    const [intervalTitle, setIntervalTitle] = useState('1M');
    const pair = useSelector(state => state.CURRENCY_PAIR.asset);
    const symbols = useSelector(state => state.GUEST_DEALING_WS[dealingAssets]);
    const handleInterval = value => {
        dispatch(getChartHistory(pair, value));
        dispatch(changeInterval(value));
        setIntervalTitle(value);
    };

    const intervalsT = {
        '1M': t('label-chart1-interval-1min'),
        '5M': t('label-chart1-interval-5min'),
        '15M': t('label-chart1-interval-15min'),
        '30M': t('label-chart1-interval-30min'),
        '1H': t('label-chart1-interval-1hr'),
    };
    const chartTypesT = {
        line: t('label-chart1-type-line'),
        candle: t('label-chart1-type-candlestick'),
        bar: t('label-chart1-type-bar'),
        area: t('label-chart1-type-area'),
    };

    const handleTypeChart = value => dispatch(setChartType(value));
    const questSettings = JSON.parse(localStorage.getItem('guestSettings'));
    const selectedInterval =
        questSettings &&
        intervals(intervalsT).find(({value}) => value === questSettings.chartInterval);
    const selectedType =
        questSettings &&
        chartTypes(chartTypesT).find(({value}) => value === questSettings.chartType);

    return (
        <Wrapper>
            <Side>
                <Pair>
                    <CurrencyPairContainer isInChart />
                </Pair>
                <ButtonWrap>
                    <DropDown
                        onChange={handleInterval}
                        button={
                            <ChartButton
                                withArrow
                                title={
                                    (selectedInterval &&
                                        selectedInterval.value) ||
                                    intervalTitle
                                }
                                tooltip={t('tooltip-chart1-interval')}
                            />
                        }
                        list={intervals(intervalsT)}
                        selectedItem={selectedInterval || intervals(intervalsT)[0]}
                    />
                </ButtonWrap>
                <ButtonWrap>
                    <DropDown
                        onChange={handleTypeChart}
                        button={
                            <ChartButton
                                withArrow
                                icon="icon-type-chart"
                                tooltip={t('tooltip-chart1-type')}
                            />
                        }
                        list={chartTypes(chartTypesT)}
                        selectedItem={selectedType || chartTypes(chartTypesT)[0]}
                    />
                </ButtonWrap>
            </Side>
            {symbols[pair] && (
                <Side>
                    <Volume>
                        <VolumeData>
                            {symbols[pair] && symbols[pair].volume}
                        </VolumeData>
                        <VolumeLabel>{`${t('label-volume')} ${
                            pair.split('/')[0]
                        }`}</VolumeLabel>
                    </Volume>
                    <Volume>
                        <VolumeData>
                            {Number(
                                symbols[pair] && symbols[pair].change
                            ).toFixed(2)}
                        </VolumeData>
                        <VolumeLabel>{`${t('label-growth')} %`}</VolumeLabel>
                    </Volume>
                </Side>
            )}
        </Wrapper>
    );
}
