import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import classnames from 'classnames';

import {close} from './service/actions';
import {setActiveDashboardIndex} from './service/actions';

import parseTranslation from '../../../../../../../adapter/helper/parseTranslation';

import {
    setDashboardLayout,
    sendSettingsData,
} from '../../../../../../services/action/settings';

function mapStateToProps(state) {
    const {isOpen} = state.layoutModal;
    const {dashboardLayout} = state.settings;

    return {
        isOpen,
        dashboardLayout,
        titleLayoutModal: parseTranslation('tittle-layout-modal'),
        defaultLayoutOne: parseTranslation('default-layout-modal-one'),
        defaultLayoutTwo: parseTranslation('default-layout-modal-two'),
        defaultLayoutThree: parseTranslation('default-layout-modal-three'),
        defaultLayoutFour: parseTranslation('default-layout-modal-four'),
        defaultLayoutFive: parseTranslation('default-layout-modal-five'),
        titleButtonCancel: parseTranslation('title-button-cancel'),
        titleButtonApply: parseTranslation('title-button-apply'),
    };
}

const mapDispatchToProps = {
    close,
    setActiveDashboardIndex,
    setDashboardLayout,
    sendSettingsData,
};

class LayoutModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedLayout: props.dashboardLayout,
            hoverLayout: props.dashboardLayout
        };

        this.close = this.close.bind(this);
        this.choiceLayout = this.choiceLayout.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setLayout = this.setLayout.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { dashboardLayout } = this.props;

        if (dashboardLayout !== prevProps.dashboardLayout) {
            this.setState({
                selectedLayout: dashboardLayout,
                hoverLayout: dashboardLayout,
            })
        }
    }

    choiceLayout() {
        const {setDashboardLayout, sendSettingsData} = this.props;
        const {hoverLayout} = this.state;

        setDashboardLayout(hoverLayout);
        this.setState((state) => {
            return {
                selectedLayout: state.hoverLayout
            }
        });
        this.close();
        sendSettingsData();
    }

    closeModal() {
        this.setState((state) => {
            return {
                hoverLayout: state.selectedLayout
            }
        });

        this.close();
    }

    setLayout(layoutIndex) {
        this.setState({
            hoverLayout: layoutIndex
        });
    }


    render() {
        const { isOpen } = this.props;
        const {hoverLayout} = this.state;
        return (
            <Modal
                isOpen={isOpen}
                contentLabel="LAYOUT"
                className="modal layout"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(20, 22, 27, 0.65)',
                        display: 'flex',
                        zIndex: 100,
                    },
                }}
            >
                <div className="modal-header">
                    <span>{this.props.titleLayoutModal}</span>
                    <button onClick={this.closeModal}>
                        <i className="icon-close" />
                    </button>
                </div>

                <div className="layout-window">
                    <div className="col-layout">
                        <div
                            className={classnames('layout-default', {
                                active: hoverLayout === 0,
                            })}
                            onClick={()=> this.setLayout(0)}
                        >
                            <span className="tittle">
                                {this.props.defaultLayoutOne}
                            </span>
                            <img
                                src={require('./layout-default-1.svg')}
                                alt="Layout1"
                            />
                        </div>
                        <div
                            className={classnames('layout-default', {
                                active: hoverLayout === 3,
                            })}
                            onClick={()=> this.setLayout(3)}
                        >
                            <span className="tittle">
                                {this.props.defaultLayoutFour}
                            </span>
                            <img
                                src={require('./layout-default-4.svg')}
                                alt="Layout4"
                            />
                        </div>
                    </div>

                    <div className="col-layout">
                        <div
                            className={classnames('layout-default', {
                                active: hoverLayout === 1,
                            })}
                            onClick={()=> this.setLayout(1)}
                        >
                            <span className="tittle">
                                {this.props.defaultLayoutTwo}
                            </span>
                            <img
                                src={require('./layout-default-2.svg')}
                                alt="Layout2"
                            />
                        </div>
                        <div
                            className={classnames('layout-default', {
                                active: hoverLayout === 4,
                            })}
                            onClick={()=> this.setLayout(4)}
                        >
                            <span className="tittle">
                                {this.props.defaultLayoutFive}
                            </span>
                            <img
                                src={require('./layout-default-5.svg')}
                                alt="Layout5"
                            />
                        </div>
                    </div>

                    <div className="col-layout">
                        <div
                            className={classnames('layout-default', {
                                active: hoverLayout === 2,
                            })}
                            onClick={()=> this.setLayout(2)}
                        >
                            <span className="tittle">
                                {this.props.defaultLayoutThree}
                            </span>
                            <img
                                src={require('./layout-default-3.svg')}
                                alt="Layout3"
                            />
                        </div>
                    </div>

                    <div className="wrapper-btn">
                        <a className="secondary-button"
                            onClick={this.closeModal}
                        >
                            {this.props.titleButtonCancel}
                        </a>

                        <a className="main-button"
                            onClick={this.choiceLayout}
                        >
                           {this.props.titleButtonApply}
                        </a>
                    </div>
                </div>

            </Modal>
        );
    }

    close() {
        this.props.close();
    }
}

const ConnectedLayoutModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(LayoutModal);

export default ConnectedLayoutModal;
