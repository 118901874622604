import {ActionTypes} from '../action/DXChartHistoryProvider';

const initialState = {
    isPending: true,
    error: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.chartDXHistoryRequest:
            return {
                ...state,
                isPending: true,
                error: false,
            };

        case ActionTypes.chartDXHistorySuccess:
            return {
                ...state,
                isPending: false,
                error: false,
            };

        case ActionTypes.chartDXHistoryFailure:
            return {
                ...state,
                isPending: false,
                error: true,
            };
        default:
            return state;
    }
}
