// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {TicketsAdapterInterface as TicketsAdapter} from '../../../application/adapter/Tickets';

const formType = 'question';

class SubmitQuestionForm {
    adapter: TicketsAdapter;

    constructor(adapter: TicketsAdapter) {
        this.adapter = adapter;
    }

    async invoke(token: AccessToken, formData, locale: string) {
        try {
            const {data: form} = await this.adapter.sendForm(
                token,
                formType,
                formData,
                locale,
            );

            return form;
        } catch (error) {
            if(error.response) {
                const {status, data} = error.response;

                if (status === 422) {
                    return data;
                }
            }

            throw error;
        }
    }
}

export default SubmitQuestionForm;
