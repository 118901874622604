import React, {useState} from 'react';
import {FindWrapper, FindInput, FindIcon, ClearIcon} from './styled';
import t from '../../../infrastructure/adapter/helper/parseTranslation';

export default function ModalFind({onChange}) {
    const [filterValue, setFilterValue] = useState('');
    const handleChange = ({target: {value}}) => {
        setFilterValue(value);
        onChange(value);
    };
    const handleClear = () => {
        setFilterValue('');
        onChange('');
    };

    return(
        <FindWrapper>
            {filterValue ? <ClearIcon className='icon-close' onClick={handleClear} /> : <FindIcon className='icon-find' />}
            <FindInput type="text" value={filterValue} placeholder={t('label-find')} onChange={handleChange}/>
        </FindWrapper>
    )
}
