import styled from 'styled-components';

const volumeDataColor = '#2B9241';
const volumeLabelColor = '#000000';

export const Wrapper = styled.div`
    display: flex;
    padding: 2rem 2rem 0;
    justify-content: space-between;   
`;
export const Pair = styled.div`
    width: 23.3rem;
    margin-right: 1rem;
    height: 5.833rem;
`;
export const ButtonWrap = styled.div`    
    margin-right: 1rem;    
    height: 5.833rem;
`;
export const Side = styled.div`    
    display: flex;
`;
export const Volume = styled.div`    
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 4rem;
`;
export const VolumeData = styled.div`    
    font-size: 3rem;
    color: ${volumeDataColor};
    text-align: right;
    width: 100%;
`;
export const VolumeLabel = styled.div`    
    color: ${volumeLabelColor};
    font-size: 2rem;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: right;
    width: 100%;
`;


