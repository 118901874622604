export const ActionTypes = {
    chartDXHistoryRequest: 'chart.DX.history.request',
    chartDXHistorySuccess: 'chart.DX.history.success',
    chartDXHistoryFailure: 'chart.DX.history.failure',
};

export const chartDXHistoryRequest = () => ({
    type: ActionTypes.chartDXHistoryRequest,
});

export const chartDXHistorySuccess = () => ({
    type: ActionTypes.chartDXHistorySuccess,
});

export const chartDXHistoryFailure = () => ({
    type: ActionTypes.chartDXHistoryFailure,
});
