import React, {Component} from 'react';
import {connect} from 'react-redux';

import Panel from '../../../../component/Panel/Panel';
import DateDisplay from '../../../../../view/component/DateTime/Date';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Preloader from 'infrastructure/view/component/Preloader';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';
import {setActiveView as setActiveViewAction} from '../../service/actions';

function mapStateToProps(state) {
    const {data, isPending} = state.news;

    return {
        data,
        isPending,
        title: parseTranslation('label-news'),
    };
}

function mapDispatchToProps(dispatch) {
    function setActiveView() {
        dispatch(setActiveViewAction(null));
    }

    return {
        setActiveView,
    };
}

class NewsExpanded extends Component {
    title = 'NEWS';

    render() {
        const {data, isPending} = this.props;

        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-news" />
            </button>
        );

        return (
            <Panel
                icon={icon}
                title={this.props.title}
                closable
                closableHandler={this.props.setActiveView}
            >
                <div className="date-news-title">
                    <DateDisplay />
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '5rem',
                        bottom: '1rem',
                        left: 0,
                        right: 0,
                        display: 'flex',
                    }}
                >
                    <Scrollbars>
                        {isPending ? (
                            <Preloader />
                        ) : (
                            <div className="news">
                                {data.map(
                                    ({link, date, title, contentSnippet}) => (
                                        <div className="news-item">
                                            <div>
                                                <a
                                                    key={link}
                                                    href={link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span className="time">
                                                        {date.format(
                                                            'HH:mm:ss'
                                                        )}
                                                    </span>
                                                    &nbsp;
                                                    <span className="title">
                                                        {title}
                                                    </span>
                                                </a>
                                                <br />
                                                <span className="description">
                                                    {contentSnippet}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        )}
                    </Scrollbars>
                </div>
            </Panel>
        );
    }
}

const ConnectedNewsExpanded = connect(
    mapStateToProps,
    mapDispatchToProps
)(NewsExpanded);
export default ConnectedNewsExpanded;
