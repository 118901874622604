import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import {fetchMarketTradesHistory as fetchMarketTradesHistoryAction} from './service/actions';
import {setActiveView as setActiveViewAction} from '../../service/actions';

import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Panel from '../../../../component/Panel/Panel';
import newId from './newid';
import CurrencyPair from '../../../../component/SymbolButton';
import FormattedPrice from '../../../../component/FormattedPrice/FormattedPrice';
import Preloader from 'infrastructure/view/component/Preloader';
import {open as openSymbolModal} from 'infrastructure/view/scene/Dashboard/components/Header/component/SymbolsModal/service/actions';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';

import ToolTip from 'react-portal-tooltip';
import {TableTooltipTrades} from '../../../../component/TableTooltipTrades';
import ErrorContainer from '../../../../component/Error/ErrorContainer';
import {clearMarketTradesTable} from './service/actions';

function mapStateToProps(state) {
    const {
        deals,
        page,
        shouldLoadHistory,
        isPendingFirstData,
        error,
    } = state.marketTrades;

    const {selectedSymbol} = state.settings;

    return {
        selectedSymbol,
        orders: deals,
        error,
        page: page,
        shouldLoadHistory: shouldLoadHistory,
        isPendingFirstData,
        title: parseTranslation('label-market-trades'),
        titleTablePrice: parseTranslation('label-price'),
        titleTableAmount: parseTranslation('label-amount'),
    };
}

function mapDispatchToProps(dispatch) {
    function setActiveView() {
        dispatch(setActiveViewAction('market-trades-expanded'));
    }
    function fetchMarketTradesHistory(page) {
        dispatch(fetchMarketTradesHistoryAction(page));
    }
    function openSymbolModalAction() {
        dispatch(openSymbolModal());
    }
    function clearTable() {
        dispatch(clearMarketTradesTable());
    }

    return {
        setActiveView,
        fetchMarketTradesHistory,
        openSymbolModalAction,
        clearTable,
    };
}

class Entries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipActive: false,
        };

        this.timerId = null;
    }

    showTooltip() {
        this.setState(
            {isTooltipActive: false},

            () => {
                this.timerId = setTimeout(() => {
                    this.setState({isTooltipActive: true});
                }, 2000);
            }
        );
    }

    hideTooltip() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.setState({isTooltipActive: false});
    }

    componentWillMount() {
        this.id = newId();
    }

    render() {
        const {openingTime, price, amount, type} = this.props;

        const {isTooltipActive} = this.state;
        const {isTooltipLeaveTable} = this.props;

        const tooltipId = this.id;

        const isTooltipShow = isTooltipActive && isTooltipLeaveTable;

        let style = {
            style: {
                display: `${isTooltipShow ? 'block' : 'none'}`,
                background: 'rgba(0,0,0,0)',
                boxShadow: '0 0 0 rgba(0,0,0,0)',
                left: '0',
                transition: 'none',
                visibility: 'none',
            },
            arrowStyle: {
                display: 'none',
                borderColor: 'none',
            },
        };

        return (
            <>
                <ToolTip
                    active={isTooltipShow}
                    position="top"
                    style={style}
                    parent={'#' + tooltipId}
                    group="trades"
                    tooltipTimeout={0}
                >
                    <TableTooltipTrades
                        openingTime={openingTime.format('HH:mm:ss')}
                        openingTimeDate={openingTime.format('DD.MM.YYYY')}
                        price={
                            <span
                                className={`trades-cell-price-${type.toLowerCase()}`}
                            >
                                <i
                                    className={classnames({
                                        'icon-single-up-pointing-angle':
                                            type === 'Buy',
                                        'icon-single-down-pointing-angle':
                                            type === 'Sell',
                                    })}
                                />
                                <FormattedPrice price={price} />
                            </span>
                        }
                        amount={amount}
                    />
                </ToolTip>

                <tr
                    className="table-row"
                    id={tooltipId}
                    onMouseEnter={this.showTooltip.bind(this)}
                    onMouseLeave={this.hideTooltip.bind(this)}
                >
                    <td>{openingTime.format('HH:mm:ss')}</td>
                    <td>{openingTime.format('DD.MM.YYYY')}</td>
                    <td className={`cell-price-${type.toLowerCase()}`}>
                        <i
                            className={classnames({
                                'icon-single-up-pointing-angle': type === 'Buy',
                                'icon-single-down-pointing-angle': type === 'Sell',
                            })}
                        />
                        <FormattedPrice price={price} />
                    </td>
                    <td>
                        <FormattedPrice price={amount} />
                    </td>
                </tr>
            </>
        );
    }
}

class MarketTrades extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipLeaveTable: false,
        };

        this.scrollAreaRef = React.createRef();
        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleTooltip() {
        this.setState(state => ({
            isTooltipLeaveTable: !state.isTooltipLeaveTable,
        }));
    }
    componentDidMount(): void {
        this.props.selectedSymbol.name && this.props.fetchMarketTradesHistory(1);
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (prevProps.selectedSymbol.name !== this.props.selectedSymbol.name) {
            this.props.clearTable();
            this.props.fetchMarketTradesHistory(1);
        }
    }

    render() {
        const {
            orders,
            page,
            fetchMarketTradesHistory,
            isPendingFirstData,
            error,
            selectedSymbol,
            openSymbolModalAction,
        } = this.props;


        const {isTooltipLeaveTable} = this.state;

        const entries = orders.map((el, index) => (
            <Entries
                key={index}
                {...el}
                isTooltipLeaveTable={isTooltipLeaveTable}
            />
        ));

        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-market-trades" />
            </button>
        );

        return (
            <Panel
                icon={icon}
                title={this.props.title}
                expandable
                expandHandler={this.props.setActiveView}
                className="panel_market-trades"
            >
                <Fragment>
                    {isPendingFirstData ? (
                        <Preloader />
                    ) : error ? (
                        <ErrorContainer />
                    ) : (
                        <>
                            <div className="currency-pair-modal">
                                <CurrencyPair
                                    firstCurrency={selectedSymbol.numerator}
                                    secondCurrency={selectedSymbol.denominator}
                                    size="medium"
                                    onClick={openSymbolModalAction}
                                />
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '1rem',
                                    zIndex: '1',
                                }}
                            >
                                {this.renderTableHead()}
                            </div>

                            <div
                                style={{
                                    position: 'absolute',
                                    top: '8rem',
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    display: 'flex',
                                }}
                            >
                                <Scrollbars
                                    onScroll={e => {
                                        const {
                                            scrollHeight,
                                            scrollTop,
                                            clientHeight,
                                        } = e.target;
                                        const offset =
                                            scrollTop +
                                            clientHeight -
                                            scrollHeight;
                                        if (!offset) {
                                            fetchMarketTradesHistory(page);
                                        }
                                    }}
                                >
                                    <table
                                        className="history-table market-table"
                                        onMouseLeave={this.toggleTooltip}
                                        onMouseEnter={this.toggleTooltip}
                                    >
                                        <tbody>{entries}</tbody>
                                    </table>
                                </Scrollbars>
                            </div>
                        </>
                    )}
                </Fragment>
            </Panel>
        );
    }

    renderTableHead() {
        return (
            <table>
                <thead>
                    <tr style={{backgroundColor: 'transparent'}}>
                        <th className="sticky-header" />
                        <th className="sticky-header" />
                        <th className="sticky-header">
                            {this.props.titleTablePrice}
                        </th>
                        <th className="sticky-header">
                            {this.props.titleTableAmount}
                        </th>
                    </tr>
                </thead>
                <tbody />
            </table>
        );
    }
}

const ConnectedMarketTrades = connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketTrades);
export default ConnectedMarketTrades;
