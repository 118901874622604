import axios from 'axios';
import store from '../../view/services/store';
import {logout} from '../../view/services/action/user';
import config from '../../../config';

const instance = axios.create({
    withCredentials: true,
});
instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.toString().includes('401')) {
        store.dispatch(logout());
        localStorage.removeItem('userRegion');
        window.location.href = `${config.workspaceUrl}/login`
    }
    return Promise.reject(error);
});

export default instance;
