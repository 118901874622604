import {SET_CHART_TYPE} from './actions';

let questSettings = JSON.parse(localStorage.getItem('guestSettings'));
const initialState = {
    chartType: (questSettings && questSettings.chartType) || 'candle',
};

export default function chartMenu(state = initialState, action) {
    switch (action.type) {
        case SET_CHART_TYPE: {
            questSettings = JSON.parse(localStorage.getItem('guestSettings'));
            const {value} = action;
            localStorage.setItem(
                'guestSettings',
                questSettings
                    ? JSON.stringify({...questSettings, chartType: value})
                    : JSON.stringify({chartType: value})
            );
            return {
                ...state,
                chartType: value,
            };
        }
        default:
            return state;
    }
}
