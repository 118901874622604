// @flow
import {type Message, type Room, type IncomingTypingStatus} from './reducer';

export const ActionTypes = {
    setAccessChat: 'module.chat.action.setAccessChat',
    errorAccessChat: 'module.chat.action.errorAccessChat',
    setRoom: 'module.chat.action.setRoom',
    closeRoom: 'module.chat.action.closeRoom',
    setMessage: 'module.chat.action.setMessage',
    setMessageText: 'module.chat.action.setMessageText',
    setTypingStatus: 'module.chat.action.setTypingStatus',
    removeChat: 'module.chat.action.removeChat',
    setChatStatus: 'module.chat.action.setChatStatus',
    setConnection: 'module.chat.action.setConnection',
    socketError: 'module.chat.action.socketError',
};

export type SetConnection = {|
    type: 'module.chat.action.setConnection',
|};

export type SetAccessChat = {|
    type: 'module.chat.action.setAccessChat',
    accessChat: string,
|};

export type ErrorAccessChat = {|
    type: 'module.chat.action.errorAccessChat',
    payload: {},
|};

export type SetRoom = {|
    type: 'module.chat.action.setRoom',
    room: Room,
|};

export type SetMessage = {|
    type: 'module.chat.action.setMessage',
    message: Message,
|};

export type SetMessageText = {|
    type: 'module.chat.action.setMessageText',
    messageText: string,
|};

export type SetTypingStatus = {|
    type: 'module.chat.action.setTypingStatus',
    incomingTypingStatus: IncomingTypingStatus,
|};

export type RemoveChat = {|
    type: 'module.chat.action.removeChat',
|};

export type SetChatStatus = {|
    type: 'module.chat.action.setChatStatus',
    status: boolean,
|};

export type SocketError = {|
    type: 'module.chat.action.socketError',
    payload: {},
|};

export type Action =
    | SetAccessChat
    | ErrorAccessChat
    | SetRoom
    | SetMessage
    | SetMessageText
    | SetTypingStatus
    | RemoveChat
    | SetChatStatus
    | SetConnection
    | SocketError;

export function setConnection(): SetConnection {
    return {
        type: ActionTypes.setConnection,
    };
}

export function setAccessChat(accessChat: string): SetAccessChat {
    return {
        type: ActionTypes.setAccessChat,
        accessChat,
    };
}

export function errorAccessChat(payload: {}): ErrorAccessChat {
    return {
        type: ActionTypes.errorAccessChat,
        payload,
    };
}

export function setRoom(room: Room): SetRoom {
    return {
        type: ActionTypes.setRoom,
        room,
    };
}
export function closeRoom(room: Room): SetRoom {
    return {
        type: ActionTypes.closeRoom,
        room,
    };
}

export function setMessage(message: Message): SetMessage {
    return {
        type: ActionTypes.setMessage,
        message,
    };
}

export function setMessageText(messageText: string): SetMessageText {
    return {
        type: ActionTypes.setMessageText,
        messageText,
    };
}

export function setTypingStatus(
    incomingTypingStatus: IncomingTypingStatus
): SetTypingStatus {
    return {
        type: ActionTypes.setTypingStatus,
        incomingTypingStatus,
    };
}

export function removeChat(): RemoveChat {
    return {
        type: ActionTypes.removeChat,
    };
}

export function setChatStatus(status: boolean): SetChatStatus {
    return {
        type: ActionTypes.setChatStatus,
        status,
    };
}

export function socketError(payload: {}): SocketError {
    return {
        type: ActionTypes.socketError,
        payload,
    };
}
