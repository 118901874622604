import styled from 'styled-components';

export const ChartHolder = styled.div`  
  flex: 1;
  position: relative;
  #custom-chart {
    top: 0;
  }
`;
export const ChartTabsWrapper = styled.div`
  display: flex;
  div:first-child {
    margin-right: 2rem;
  }
`;
