import {SET_MODAL_VISIBILITY} from './actions';
const initialState = {
    open: false,
};

export default function Modal(state = initialState, action) {
    switch (action.type) {
        case SET_MODAL_VISIBILITY: {
            const {value} = action;
            return {
                ...state,
                open: value,
            };
        }
        default:
            return state;
    }
}
