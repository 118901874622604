import React, {Component} from 'react';
import {DateWrapper} from './styled';
import moment from 'moment';

import parseTranslation from 'infrastructure/adapter/helper/parseTranslation';

class DateDisplay extends Component {
    render() {
        let mounth = null;
        switch (
            moment()
                .format('MMMM')
                .toLowerCase()
        ) {
            case 'january':
                mounth = parseTranslation('label-january');
                break;
            case 'february':
                mounth = parseTranslation('label-february');
                break;
            case 'march':
                mounth = parseTranslation('label-march');
                break;
            case 'april':
                mounth = parseTranslation('label-april');
                break;
            case 'may':
                mounth = parseTranslation('label-may');
                break;
            case 'june':
                mounth = parseTranslation('label-june');
                break;
            case 'july':
                mounth = parseTranslation('label-july');
                break;
            case 'august':
                mounth = parseTranslation('label-august');
                break;
            case 'september':
                mounth = parseTranslation('label-september');
                break;
            case 'october':
                mounth = parseTranslation('label-october');
                break;
            case 'november':
                mounth = parseTranslation('label-november');
                break;
            case 'december':
                mounth = parseTranslation('label-december');
                break;
            default:
                break;
        }

        let date = `${moment().format('DD')} ${mounth} ${moment().format(
            'YYYY'
        )}`;

        return <DateWrapper>{date}</DateWrapper>;
    }
}

export default DateDisplay;
