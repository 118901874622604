import React, {PureComponent} from 'react';

import PasswordSettings from '../component/PasswordSettings';

export class PasswordSettingsLayout extends PureComponent {
    render() {
        return (
            <div className="active-content" style={{height: 'auto'}}>
                <PasswordSettings />
            </div>
        );
    }
}

export default PasswordSettingsLayout;
