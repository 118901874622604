import {getUserRegion} from "../../../../utils/auth";

export const ActionTypes = {
    INIT_AUTH: 'INIT_AUTH',
    SET_USER_REGION: 'SET_USER_REGION',
    setUserData: 'user.user-data.set',
    accessToken: 'SET_ACCESS_TOKEN',
    GET_DEALING_TOKEN: 'GET_DEALING_TOKEN',
    SET_SITE_PAIR: 'SET_SITE_PAIR',
    dealingToken: 'user.dealing-token.set',
    setBalances: 'user.balances.set',
    setSelectedSymbol: 'user.symbol.set',
    getUserData: 'GET_USER_DATA',
    errorUserData: 'error.user.user-data.get',
    errorSubscribeToSymbol: 'user.subscribe.symbol.error',
    LOGOUT: 'LOGOUT',
};


export function initAuth(accessToken, history) {
    return {
        type: ActionTypes.INIT_AUTH,
        accessToken,
        history
    };
}

export function setUserRegion(userRegion) {
    return {
        type: ActionTypes.SET_USER_REGION,
        userRegion
    };
}

export function getDealingToken(value) {
    return {
        type: ActionTypes.GET_DEALING_TOKEN,
        value,
    };
}
export function setSitePair(value) {
    return {
        type: ActionTypes.SET_SITE_PAIR,
        value,
    };
}



export function setUserData(data) {
    return {
        type: ActionTypes.setUserData,
        data,
    };
}

export function setAccessToken(accessToken) {
    return {
        type: ActionTypes.accessToken,
        accessToken,
    };
}


export function setDealingToken(dealingToken, sitePair) {
    return {
        type: ActionTypes.dealingToken,
        dealingToken,
        sitePair,
    };
}

export function setBalances(balances) {
    return {
        type: ActionTypes.setBalances,
        balances,
    };
}

export function setSelectedSymbol(selectedSymbol) {
    return {
        type: ActionTypes.setSelectedSymbol,
        selectedSymbol,
    };
}

export function getUserData() {
    return {
        type: ActionTypes.getUserData,
    };
}

export function errorUserData(payload) {
    return {
        type: ActionTypes.errorUserData,
        payload,
    }
}

export function errorSubscribeToSymbol(payload) {
    return {
        type: ActionTypes.errorSubscribeToSymbol,
        payload,
    };
}

export function logout(history) {
    return {
        type: ActionTypes.LOGOUT,
        history,
    };
}
