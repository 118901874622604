import styled, {css} from 'styled-components';
import {panelBodyBg} from '../Panel/styled';

export const firstLineColor = '#F8F8F8';


export const LinesFill = styled.div`
    background: repeating-linear-gradient(${({fromBottom}) => fromBottom ? 'to top' : 'to bottom'}, ${firstLineColor}, ${firstLineColor} 7.5rem, ${panelBodyBg} 0, ${panelBodyBg} 15rem);
    position:absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;    
`;
