import React from 'react';
import {
    PageContainer,
    FillImage,
    Content,
    ContentTop,
    Logo,
    Title,
    ErrorCode,
    Text,
} from './styled';
import logo from './logo.svg';
import birds from './birds.svg';
import {useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setError} from '../../GuestChart/actions';

export const NotFound = () => {
    const history = useHistory();
    const d = useDispatch();
    const linkHandler = () => {
        if (localStorage.getItem('accessToken')) {
            history.push('/dashboard');
            window.location.reload();
        } else {
            d(setError(false));
            history.push('/market');
            window.location.reload();
        }
    };
    return (
        <PageContainer>
            <FillImage src={birds} alt="Birds" />
            <Content>
                <ContentTop>
                    <Logo src={logo} alt="Pinigus" />
                    <Title>Oops looks like we lost one!</Title>
                    <ErrorCode>404</ErrorCode>
                    <Text>
                        Page not found <br />
                        The page you are looking for does not exist
                    </Text>
                </ContentTop>
                <div className="main-button" onClick={linkHandler}>
                    Go to Home page
                </div>
            </Content>
        </PageContainer>
    );
};
