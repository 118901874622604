// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {TicketsAdapterInterface as TicketsAdapter} from '../../../application/adapter/Tickets';

const formType: string = 'verification';

class GetVerificationForm {
    adapter: TicketsAdapter;

    constructor(adapter: TicketsAdapter) {
        this.adapter = adapter;
    }

    async invoke(token: AccessToken, locale: string) {
        try {
            return await this.adapter.getForm(token, formType, locale);
        } catch (error) {
            if(error.response) {
                const { status, data } = error.response;

                if (status === 422) {
                    return data;
                }
            }

            throw error;
        }
    }
}

export default GetVerificationForm;
