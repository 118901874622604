import {ActionTypes} from './actions';

const INITIAL_STATE = {
    deals: [],
    shouldLoadHistory: true,
    page: 1,
    isPending: false,
    isPendingFirstData: true,
    error: null,
};

function marketTradesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.changePending:
            return {
                ...state,
                isPending: action.pendingState,
            };

        case ActionTypes.addOrdersToMarketTradesTable: {
            if (!action.payload.length) {
                return {...state};
            }

            const deals = [...action.payload, ...state.deals];

            const sortedDeals = deals
                .sort((a, b) =>
                    a.openingTime.isBefore(b.openingTime) ? -1 : 1
                )
                .map((deal, idx, array) => {
                    let type = 'Buy';

                    if (array[idx - 1]) {
                        if (deal.price === array[idx - 1].price) {
                            type = array[idx - 1].type;
                        }

                        if (deal.price < array[idx - 1].price) {
                            type = 'Sell';
                        }
                    }

                    deal.type = type;
                    return deal;
                })
                .reverse();

            let result;

            if (sortedDeals.length > 20) {
                result = sortedDeals.slice(0, sortedDeals.length - 1);
            } else {
                result = sortedDeals;
            }

            return {
                ...state,
                deals: [...result],
                error: null,
            };
        }

        case ActionTypes.addHistoryToMarketTradesTable: {
            let deals;

            if (state.page === 2) {
                deals = [
                    ...state.deals,
                    ...action.deals,
                ];
            } else {
                if (!action.deals.length) {
                    return {
                        ...state,
                        shouldLoadHistory: false,
                        isPendingFirstData: false,
                    };
                }

                deals = [
                    ...state.deals,
                    ...action.deals,
                ];
            }

            const sortedDeals = deals
                .reverse()
                .map((deal, idx, array) => {
                    let type = 'Buy';

                    if (array[idx - 1]) {
                        if (deal.price === array[idx - 1].price) {
                            type = array[idx - 1].type;
                        }

                        if (deal.price < array[idx - 1].price) {
                            type = 'Sell';
                        }
                    }

                    deal.type = type;
                    return deal;
                })
                .reverse();

            return {
                ...state,
                page: state.page + 1,
                deals: sortedDeals,
                isPendingFirstData: false,
            };
        }

        case ActionTypes.addHistoryToMarketTradesTableError:
            return {
                deals: [],
                shouldLoadHistory: true,
                page: 1,
                isPending: false,
                isPendingFirstData: false,
                error: action.payload,
            };

        case ActionTypes.clearMarketTradesTable:
            return {
                ...INITIAL_STATE,
            };

        default:
            return state;
    }
}

export default marketTradesReducer;
