import styled, {css} from 'styled-components';
import {firstLineColor} from '../components/LinesFill/styled';

const rowColor = '#000000';
const hoverBg = '#E6EBFF';
const hoverColor = '#000000';
const titleColor = '#999999';
const buyColor= '#00B456';
const sellColor= '#FF4D4D';

export const TopBlock = styled.div`
  padding: 2rem 4rem;
  display: flex;
  ${({collapse}) => collapse && css`
    flex-direction: column;    
  `}  
`;
export const Pair =styled.div`
  width: 23.666rem;  
`;
export const Row =styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4rem;
  height: 7.5rem;
  align-items: center;
  font-size: 2.3rem;
  color: ${rowColor};
  cursor: default;
  background: ${({even}) => !even && firstLineColor};
  &:hover {
    background: ${hoverBg};
    color: ${hoverColor};
  }
  ${({collapse}) => collapse && css`
    div {
      flex: 1;
      text-align: center;
      justify-content: center;
    }
`}
`;
export const Icon =styled.i`
    font-size: 1.3rem;    
    margin-right: 1rem;
`;
export const Time =styled.div``;
export const Date =styled.div``;
export const Price =styled.div`
  width: 21%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${({isBuy}) => isBuy ? buyColor : sellColor};
`;
export const Amount =styled.div`
  width: 21%;
  text-align: right;
`;
export const Titles =styled.div`
  display: flex;
  font-size: 2.3rem;
  flex: 1;
  color: ${titleColor};
  align-items: flex-end;
  div {
    text-align: right;
    flex: 1;
  }
  ${({collapse}) => collapse && css`
    div {
      text-align: center;
    }
`}
`;


export const ContentWrap = styled.div`
    font-size: 2.3rem;
    color: ${rowColor};
    display: flex;
    padding: 2.5rem 2rem;
`;
export const ContentColumn = styled.div`
    display: table;    
`;
export const ContentRow = styled.div`
    display: table-row;    
`;
export const ContentLabel = styled.div`
    display: table-cell;
    padding-right: 2rem;
`;
export const ContentValue = styled.div`   
    display: table-cell;         
    span {
        display: inline-block;       
    }
`;
export const ContentPrice = styled(ContentValue)`
    color: ${({isBuy}) => isBuy ? buyColor : sellColor}
    ${Icon} {
      margin-right: 1rem;
    }    
`;



