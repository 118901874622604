class Get2FAForm {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(locale: string, token: string): Promise<any> {
        try {
            const { data } = await this.adapter.getForm(locale, token);
            return data;
        } catch (error) {
            if(error.response) {
                const {status, data} = error.response;
                if (status === 422) {
                    throw data;
                }
            }

            throw error;
        }
    }
}

export default Get2FAForm;
