// @flow
import type Axios from 'axios';
import localConfig from 'localConfig';
import {getDomain} from 'utils';

import type { ProfileFormAdapterInterface } from '../../application/adapter/ProfileForm';
import type { AccessToken } from '../../domain/entitiy/AccessToken';

class ProfileFormAdapter implements ProfileFormAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getForm(locale: string, token: string = ''): Promise<any> {
        return await this.apiClient({
            method: 'PUT',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/profile`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                'Accept-Language': `${locale}; q=1`,
                'authorization': `Bearer ${token}`
            }
        });
    }

    async sendForm(token: string = '', formData: {}, locale: string): Promise<AccessToken> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/profile`,
            headers: {
                'content-type': 'multipart/form-data',
                'cache-control': 'no-cache',
                'Accept-Language': `${locale}; q=1`,
                'authorization': `Bearer ${token}`
            },
            data: formData
        });
    }
}

export default ProfileFormAdapter;
