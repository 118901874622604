import axios from '../../../adapter/helper/axios';

import {ActionTypes} from '../../scene/Billing/service/actions';
import {setForm, errorForm} from '../action/forms';

import {setTransactionHistory, errorTransaction} from '../../scene/Billing/service/actions';

import BillingAdapter from '../../../adapter/Billing';

import GetDepositForm from '../../../../application/use-case/billing/GetDepositForm';
import SubmitDepositForm from '../../../../application/use-case/billing/SubmitDepositForm';
import GetWithdrawForm from '../../../../application/use-case/billing/GetWithdrawForm';
import SubmitWithdrawForm from '../../../../application/use-case/billing/SubmitWithdrawForm';

import GetTransactionHistory from '../../../../application/use-case/billing/GetTransactionHistory';

const billingAdapter = new BillingAdapter(axios);
const getDepositForm = new GetDepositForm(billingAdapter);
const getTransactionHistory = new GetTransactionHistory(billingAdapter);
const submitDepositForm = new SubmitDepositForm(billingAdapter);
const getWithdrawForm = new GetWithdrawForm(billingAdapter);
const submitWithdrawForm = new SubmitWithdrawForm(billingAdapter);

export default function(store) {
    return function(next) {
        return async function(action) {
            next(action);

            const {local} = store.getState().translation;

            if (action.type === ActionTypes.getTransactionHistory) {
                const {accessToken} = store.getState().user;

                try {
                    const history = await getTransactionHistory.invoke(accessToken);
                    store.dispatch(setTransactionHistory(history));
                }catch (e) {
                    store.dispatch(errorTransaction(e))
                }
            }

            if (action.type === ActionTypes.getDepositForm) {
                const {accessToken} = store.getState().user;

                try {
                    const form = await getDepositForm.invoke(
                        accessToken,
                        action.formData,
                        local
                    );

                    store.dispatch(setForm(form));
                }catch (e) {
                    store.dispatch(errorForm(e))
                }
            }

            if (action.type === ActionTypes.submitDepositForm) {
                const {accessToken} = store.getState().user;
                const {scheme} = store.getState().forms['DepositForm'];

                const formData = {
                    DepositForm: Object.values(scheme).reduce(
                        (result, schemeData) => {
                            result[schemeData.name] = schemeData.value;
                            return result;
                        },
                        {}
                    ),
                };

                try {
                    const form = await submitDepositForm.invoke(
                        accessToken,
                        formData,
                        local
                    );

                    store.dispatch(setForm(form));
                }catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ActionTypes.getWithdrawForm) {
                const {accessToken} = store.getState().user;

                try {
                    const form = await getWithdrawForm.invoke(
                        accessToken,
                        action.formData,
                        local
                    );

                    store.dispatch(setForm(form));
                }catch (e) {
                    store.dispatch(errorForm(e))
                }
            }

            if (action.type === ActionTypes.submitWithdrawForm) {
                const {accessToken} = store.getState().user;
                const {scheme} = store.getState().forms['WithdrawForm'];

                const formData = {
                    WithdrawForm: Object.values(scheme).reduce(
                        (result, schemeData) => {
                            result[schemeData.name] = schemeData.value;
                            return result;
                        },
                        {}
                    ),
                };

                try {
                    const form = await submitWithdrawForm.invoke(
                        accessToken,
                        formData,
                        local
                    );

                    store.dispatch(setForm(form));
                }catch (e) {
                    store.dispatch(errorForm(e));
                }
            }
        };
    };
}
