export const ActionType = {
    OPEN_PREVIEW_IMAGE_MODAL: 'OPEN_PREVIEW_IMAGE_MODAL',
    CLOSE_PREVIEW_IMAGE_MODAL: 'CLOSE_PREVIEW_IMAGE_MODAL',
};

export function openPreviewImageModal() {
    return {
        type: ActionType.OPEN_PREVIEW_IMAGE_MODAL,
    };
}
export function closePreviewImageModal() {
    return {
        type: ActionType.CLOSE_PREVIEW_IMAGE_MODAL,
    };
}
