import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import classnames from 'classnames';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';

import parseTranslation from '../../../../../../../adapter/helper/parseTranslation';

import {close, setCryptoCurrencyFilter, setFindFilter} from './service/actions';
import {setSelectedSymbolForChart} from '../../../Chart/service/actions';

import {
    setChartSelectedSymbol,
    sendSettingsData,
} from '../../../../../../services/action/settings';

function mapStateToProps(state) {
    const {
        isOpen,
        cryptoCurrencyFilter,
        findFilter,
        chartId,
    } = state.chartContainer.modal;

    if (!isOpen) {
        return {};
    }

    const selectedSymbol = state.settings.chart[chartId].selectedSymbol;

    const symbols = Object.values(state.symbols.symbols)
        .filter(symbol => {
            let filter = selectedSymbol.numerator;

            if (cryptoCurrencyFilter) {
                filter = cryptoCurrencyFilter;
            }

            return symbol.id.includes(filter);
        })
        // TODO: Merge this filter with previous one
        .filter(symbol => symbol.id.includes(findFilter.toUpperCase()));

    const currencies = [
        ...Object.keys(state.symbols.symbols).reduce((result, symbol) => {
            const [currNumerator, currDenominator] = symbol.split('/');

            result.add(currNumerator).add(currDenominator);

            return result;
        }, new Set()),
    ];

    const selectedCurrency = cryptoCurrencyFilter
        ? cryptoCurrencyFilter
        : selectedSymbol.numerator;

    return {
        isOpen,
        symbols,
        currencies,
        findFilter,
        selectedSymbol,
        selectedCurrency,
        chartId,
        titleSymbolModal: parseTranslation('title-symbol-modal'),
        titleCurrencyPair: parseTranslation('label-curr-pair'),
        titlePopularPrice: parseTranslation('label-price'),
        titlePopularVolume: parseTranslation('label-volume'),
        titlePopularGrowth: parseTranslation('label-growth'),
        titleInput: parseTranslation('label-find'),
    };
}

const mapDispatchToProps = {
    close,
    setCryptoCurrencyFilter,
    setFindFilter,
    setSelectedSymbolForChart,
    setChartSelectedSymbol,
    sendSettingsData,
};

class Entries extends Component {
    render() {
        const {
            id,
            price,
            volume,
            change,
            selectedSymbol,
            setSelectedSymbolForChart,
            chartId,
            sendSettingsData,
            setChartSelectedSymbol,
        } = this.props;
        const [numerator, denominator] = id.split('/');

        let COLOR_RED = '#FF4D4D';
        let COLOR_GREEN = '#00B456';

        return (
            <tr
                onClick={() => {
                    if (
                        selectedSymbol.numerator !== numerator ||
                        selectedSymbol.denominator !== denominator
                    ) {
                        setChartSelectedSymbol(chartId, {
                            numerator,
                            denominator,
                        });
                        sendSettingsData();
                        setSelectedSymbolForChart(chartId, {
                            numerator,
                            denominator,
                        });
                    }
                }}
                className={classnames(
                    id === selectedSymbol.name ? 'active' : ''
                )}
            >
                <td className="symbols-pair-right">
                    <span className="symbols-pair">
                        <i
                            className={`icon icon-${
                                selectedSymbol.numerator === numerator
                                    ? denominator.toLowerCase()
                                    : denominator.toLowerCase()
                            }`}
                        />
                        {id}
                    </span>
                </td>
                <td>{price}</td>
                <td>{volume}</td>
                <td
                    style={{
                        color: change >= 0 ? COLOR_GREEN : COLOR_RED,
                    }}
                >
                    {change && parseFloat(change.toFixed(2))}%
                </td>
            </tr>
        );
    }
}

class SymbolsModal extends Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.isOpen || this.props.isOpen;
    }

    render() {
        const {
            isOpen,
            symbols,
            selectedSymbol,
            setSelectedSymbolForChart,
            chartId,
            titleSymbolModal,
            close,
            sendSettingsData,
            setChartSelectedSymbol,
        } = this.props;

        if (!isOpen) {
            return null;
        }

        const entries = symbols.map((symbol, index) => (
            <Entries
                key={index}
                {...symbol}
                selectedSymbol={selectedSymbol}
                setSelectedSymbolForChart={setSelectedSymbolForChart}
                chartId={chartId}
                setChartSelectedSymbol={setChartSelectedSymbol}
                sendSettingsData={sendSettingsData}
            />
        ));

        return (
            <Modal
                isOpen={this.props.isOpen}
                contentLabel="Symbols Modal"
                className="modal symbols"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(20, 22, 27, 0.65)',
                        display: 'flex',
                        zIndex: 100,
                    },
                }}
            >
                <div className="modal-header">
                    <span>{titleSymbolModal}</span>
                    <button onClick={close}>
                        <i className="icon-close" />
                    </button>
                </div>

                <div className="symbols-modal-content">
                    <div className="currency-crypto">
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                            }}
                        >
                            <Scrollbars>
                                <div style={{position: 'relative'}}>
                                    <ul>
                                        {this.props.currencies &&
                                            this.props.currencies.map(
                                                (currency, index) => (
                                                    <li
                                                        key={index}
                                                        className={classnames({
                                                            selected:
                                                                currency ===
                                                                this.props
                                                                    .selectedCurrency,
                                                        })}
                                                    >
                                                        {/* TODO: Max - check button styles */}
                                                        <button
                                                            type={'button'}
                                                            onClick={() =>
                                                                this.props.setCryptoCurrencyFilter(
                                                                    currency
                                                                )
                                                            }
                                                        >
                                                            {/* TODO: Max - check icon styles */}
                                                            <i
                                                                className={`icon icon-${currency.toLowerCase()}`}
                                                            />
                                                            {currency}
                                                            <i className="icon-left2" />
                                                        </button>
                                                    </li>
                                                )
                                            )}
                                    </ul>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>

                    <div className="search-currency">
                        {/* TODO: Check, if it is possible to use Find component here */}
                        <div className="wrapper-filter">
                            <i className="icon-find1" />
                            <input
                                type="text"
                                className="filter"
                                placeholder={this.props.titleInput}
                                value={this.props.findFilter}
                                onChange={event =>
                                    this.props.setFindFilter(event.target.value)
                                }
                            />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '10rem',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                            }}
                        >
                            <Scrollbars>
                                <table>
                                    <thead style={{width: '100%'}}>
                                        <tr
                                            style={{
                                                backgroundColor: 'transparent',
                                                height: '4.17rem',
                                                fontSize: '1.8rem',
                                                width: '100%',
                                            }}
                                        >
                                            <th
                                                key={1}
                                                className="sticky-header"
                                            >
                                                {this.props.titleCurrencyPair}
                                            </th>
                                            <th
                                                key={2}
                                                className="sticky-header"
                                            >
                                                {this.props.titlePopularPrice}
                                            </th>
                                            <th
                                                key={3}
                                                className="sticky-header"
                                            >
                                                {this.props.titlePopularVolume}
                                            </th>
                                            <th
                                                key={4}
                                                className="sticky-header"
                                            >
                                                {this.props.titlePopularGrowth}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{entries}</tbody>
                                </table>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const ConnectedSymbolsModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(SymbolsModal);
export default ConnectedSymbolsModal;
