// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';

import {notificationReceived} from '../../../../../services/action/notification';
import {openLimitOrderModal} from '../../../../../scene/Dashboard/service/actions';

import CurrencyPair from 'infrastructure/view/component/SymbolButton';
import {openOrder} from '../service/actions';
import {setOrderForm} from 'infrastructure/view/scene/Dashboard/service/actions';
import {open as openSymbolsModal} from 'infrastructure/view/scene/Dashboard/components/Header/component/SymbolsModal/service/actions';
import ReactSelectWrapper from 'infrastructure/view/component/ReactSelectWrapper/ReactSelectWrapper';
import {amountIsLimit} from '../../../../../services/action/forms';

import parseTranslation from '../../../../../../adapter/helper/parseTranslation';
import {limitForSelectedSymbol} from '../selectors';

function mapStateToProps(state) {
    const {balances} = state.user;
    const {selectedSymbol} = state.settings;
    const {sell, buy} = state.DOM;
    const {orderFormValues} = state.dashboard;
    const {orderFormAmountIsLimit} = state.forms;

    return {
        openOrder: state.tradingHistory.openedOrders,
        orders: {
            buy,
            sell,
        },
        selectedSymbol,
        orderFormValues,
        limitForSelectedSymbol: limitForSelectedSymbol(state),
        balances,
        limitAmountError: orderFormAmountIsLimit,
        marketPrice:
            (state.marketTrades.deals[0] &&
                state.marketTrades.deals[0].price) ||
            0,
        titleSymbol: parseTranslation('label-symbol'),
        titleOrderType: parseTranslation('label-order-type'),
        titleExchange: parseTranslation('label-exchange'),
        titleAmount: parseTranslation('label-amount'),
        titlePrice: parseTranslation('label-price'),
        titleBuyLimit: parseTranslation('label-buy-limit'),
        titleSellLimit: parseTranslation('label-sell-limit'),
        titleBuyMarket: parseTranslation('label-buy-market'),
        titleSellMarket: parseTranslation('label-sell-market'),
        titleLimit: parseTranslation('label-limit'),
        titleMarket: parseTranslation('label-market'),

        labelBuyPrice: parseTranslation('label-buy-price'),
        labelSellPrice: parseTranslation('label-sell-price'),
        labelBuyVolume: parseTranslation('label-buy-volume'),
        labelSellVolume: parseTranslation('label-sell-volume'),
    };
}

function mapDispatchToProps(dispatch) {
    function limitAmount(isLimit) {
        dispatch(amountIsLimit(isLimit));
    }

    function openSellOrder(asset, price, amount, exType) {
        const type = 1;
        dispatch(openOrder(asset, price, amount, exType, type));
    }
    function openBuyOrder(asset, price, amount, exType) {
        const type = 2;
        dispatch(openOrder(asset, price, amount, exType, type));
    }

    function setOrderFormValues(payload) {
        dispatch(setOrderForm(payload));
    }
    function openSymbolsModalAction() {
        dispatch(openSymbolsModal());
    }

    function excessOrderLimit(id, code, isVisible) {
        dispatch(notificationReceived({
            id,
            code
        }, isVisible));

        dispatch(openLimitOrderModal(true));
    }

    return {
        openSellOrder,
        openBuyOrder,
        setOrderFormValues,
        openSymbolsModalAction,
        limitAmount,
        excessOrderLimit,
        openLimitOrderModal,
    };
}

type PropTypes = {
    titleSymbol: string,
    titleOrderType: string,
    titleLimit: string,
    titleBuyLimit: string,
    titleSellLimit: string,
    titleMarket: string,
    titlePrice: string,
    selectedSymbol: {
        denominator: string,
        name: string,
        numerator: string,
    },
    balances: {},
    marketPrice: number,
    titleAmount: string,
    orderFormValues: {
        price: string,
        amount: string,
    },
    orders: {
        buy: [],
        sell: [],
    },
    openSellOrder: () => mixed,
    setOrderFormValues: () => mixed,
};

class Exchange extends Component<PropTypes> {
    state = {
        exTypeValue: 1,
        exTypeText: '',
        priceInputValue: '',
        amountInputValue: '',
        limitAmountError: false,
        isVisible: false
    };

    componentDidUpdate(prevProps) {
        if (this.props.selectedSymbol.name !== prevProps.selectedSymbol.name) {
            this.props.setOrderFormValues({
                price: '',
                amount: '',
            });
            this.checkMinLimitForAmount('');
        }
    }

    componentDidMount() {
        this.checkMinLimitForAmount(
            this.props.orderFormValues ? this.props.orderFormValues.amount : 0
        );
    }

    constructor(props) {
        super(props);
        this.setPriceValue = this.setPriceValue.bind(this);
        this.setAmountValue = this.setAmountValue.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.handleChangeAmount = this.handleChangeAmount.bind(this);
        this.state = {
            ...this.state,
            limitAmountError: props.orderFormValues.amount && props.orderFormValues.amount < props.limitForSelectedSymbol,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isOpen !== this.props.isOpen && this.props.orderFormValues.amount <= this.props.limitForSelectedSymbol) {
            this.setState({limitAmountError: true});
        }
        if (prevProps.isOpen !== this.props.isOpen
            && this.props.orderFormValues.amount >= this.props.limitForSelectedSymbol) {
            this.setState({limitAmountError: false});
        }
        if (prevProps.selectedSymbol.name !== this.props.selectedSymbol.name) {
            this.props.setOrderFormValues({
                price: '',
                amount: '',
            });
            this.props.limitAmount(false);
        }
        if (prevProps.limitAmountError !== this.props.limitAmountError) {
            this.setState({limitAmountError: this.props.limitAmountError})
        }
    }

    checkMinLimitForAmount = (amount) => {
        const {limitForSelectedSymbol} = this.props;

        if (amount.length && Number(amount) < limitForSelectedSymbol) {
            this.props.limitAmount(true);
        } else {
            this.props.limitAmount(false);
        }

        return amount;
    };

    render() {
        const {limitAmountError} = this.state;

        const {
            titleSymbol,
            titleOrderType,
            titleLimit,
            titleMarket,
            titlePrice,
            selectedSymbol,
            marketPrice,
            orderFormValues,
            titleAmount,
            openSellOrder,
            openSymbolsModalAction,
            limitForSelectedSymbol,
            labelBuyPrice,
            labelSellPrice,
            labelBuyVolume,
            labelSellVolume,
        } = this.props;

        const limitAmountErrorClasses = classnames('tooltip-sell-limit-error', {
            'show-tooltip': limitAmountError,
        });

        const amountInputErrorClasses = classnames('sell', {
            error: limitAmountError,
        });

        return (
            <div className="order-center">
                <div className="order-raw">
                    <div className="select-wrapper wrapper-symbol">
                        <span className="span-styles">{titleSymbol}</span>
                        <CurrencyPair
                            firstCurrency={selectedSymbol.numerator}
                            secondCurrency={selectedSymbol.denominator}
                            onClick={openSymbolsModalAction}
                        />
                    </div>
                    <div className="select-wrapper">
                        <span className="span-styles">{titleOrderType}</span>
                        <ReactSelectWrapper
                            defaultValue={{value: 1, label: titleLimit}}
                            onChange={selected => {
                                this.setState({
                                    exTypeValue: selected.value,
                                    exTypeText: selected.label,
                                });
                            }}
                            options={[
                                {value: 1, label: titleLimit},
                                {value: 2, label: titleMarket},
                            ]}
                            value={this.state.exTypeText ? {label: this.state.exTypeText, value: this.state.exTypeValue} : {value: 1, label: titleLimit}}
                            menuListHeight={67}
                        />
                    </div>
                </div>

                <form action="" id={'order-form'}>
                    <div className="order-form">
                        <div className="order-raw">
                            <div className="wrapper-line">
                                <span>
                                    {titlePrice}({selectedSymbol.denominator})
                                </span>

                                <div
                                    className={classnames('order-form-wrap', {
                                        disable: this.state.exTypeValue === 2,
                                    })}
                                >
                                    <div
                                        onClick={this.setPriceValue}
                                        className="wrap-buy buy-one"
                                    >
                                        <i
                                            className="icon-min"
                                            data-price={'min'}
                                        />
                                        <div className="tooltip-buy-one">
                                            {labelBuyPrice}
                                        </div>
                                    </div>

                                    <div
                                        onClick={this.setPriceValue}
                                        className="wrap-sell sell-one"
                                    >
                                        <i
                                            className="icon-max"
                                            data-price={'max'}
                                        />

                                        <div className="tooltip-sell-one">
                                            {labelSellPrice}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input
                                type="text"
                                value={
                                    this.state.exTypeValue === 2
                                        ? `≈${marketPrice}`
                                        : orderFormValues.price
                                }
                                disabled={this.state.exTypeValue === 2}
                                onChange={this.handleChangePrice}
                            />
                        </div>

                        <div className="order-raw">
                            <div className="wrapper-line">
                                <span className="wrapper-line-title">
                                    {titleAmount}({selectedSymbol.numerator})
                                </span>

                                <div className="order-form-wrap">
                                    <div
                                        onClick={this.setAmountValue}
                                        className="wrap-buy buy-two"
                                    >
                                        <i
                                            className="icon-min"
                                            data-price={'min'}
                                        />
                                        <div className="tooltip-buy-two">
                                           {labelBuyVolume}
                                        </div>
                                    </div>

                                    <div
                                        onClick={this.setAmountValue}
                                        className="wrap-sell sell-two"
                                    >
                                        <i
                                            className="icon-max"
                                            data-price={'max'}
                                        />
                                        <div className="tooltip-sell-two">
                                            {labelSellVolume}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sell-wrapper">
                                <div className={limitAmountErrorClasses}>
                                    Min limit is {limitForSelectedSymbol}
                                </div>
                                <input
                                    type="text"
                                    className={amountInputErrorClasses}
                                    placeholder={limitForSelectedSymbol}
                                    value={orderFormValues.amount}
                                    onChange={this.handleChangeAmount}
                                />
                            </div>
                        </div>
                    </div>
                </form>

                <div className="order-button">
                    <div
                        className={`order-green green order-btn ${limitAmountError && 'disabled'}`}
                        onClick={e => { if(!limitAmountError) {
                            let price = orderFormValues.price;

                            if (this.state.exTypeValue === 2) {
                                price = marketPrice;
                            }

                            setTimeout(() => {
                                document
                                    .querySelector('.order-button .order-green')
                                    .blur();
                            }, 300);

                            this.props.openBuyOrder(
                                selectedSymbol.name,
                                price,
                                orderFormValues.amount,
                                this.state.exTypeValue
                            );
                        }
                        }}
                    >
                        <i className="icon-single-up-pointing-angle" />
                        {this.state.exTypeValue === 1
                            ? this.props.titleBuyLimit
                            : this.props.titleBuyMarket}
                    </div>

                    <div
                        className={`order-red red order-btn ${limitAmountError && 'disabled'}`}
                        onClick={() => {
                            if (!limitAmountError) {
                                let price = orderFormValues.price;

                                if (this.state.exTypeValue === 2) {
                                    price = marketPrice;
                                }

                                setTimeout(() => {
                                    document
                                        .querySelector('.order-button .order-red')
                                        .blur();
                                }, 300);

                                openSellOrder(
                                    selectedSymbol.name,
                                    price,
                                    orderFormValues.amount,
                                    this.state.exTypeValue
                                );
                            }
                        }}
                    >
                        <i className="icon-single-down-pointing-angle" />
                        {this.state.exTypeValue === 1
                            ? this.props.titleSellLimit
                            : this.props.titleSellMarket}
                    </div>
                </div>
            </div>
        );
    }

    handleChangePrice(e: Event): void {
        const {selectedSymbol} = this.props;
        const price = e.target.value.replace(',', '.');
        const selectValue = selectedSymbol.name;
        const currency = /\/.{0,3}/.exec(selectValue)[0].replace('/', '');

        if (currency === 'USD' || currency === 'EUR') {
            const regex = /^\d*\.?\d{0,2}$/g;
            let match = regex.exec(price);
            if (match && match.shift().length <= 15) {
                this.props.setOrderFormValues({
                    ...this.props.orderFormValues,
                    price,
                });
            }
        } else {
            const regex = /^\d*\.?\d{0,8}$/g;
            let match = regex.exec(price);

            if (match && match.shift().length <= 15) {
                this.props.setOrderFormValues({
                    ...this.props.orderFormValues,
                    price,
                });
            }
        }
    }

    handleChangeAmount(e: Event): void {
        const {selectedSymbol} = this.props;

        const amount = e.target.value.replace(',', '.');

        const selectValue = selectedSymbol.name;
        const currency = /.{0,3}\//.exec(selectValue)[0].replace('/', '');

        if (currency === 'USD' || currency === 'EUR') {
            const regex = /^\d*\.?\d{0,2}$/g;
            let match = regex.exec(amount);

            if (match && match.shift().length <= 15) {
                this.props.setOrderFormValues({
                    ...this.props.orderFormValues,
                    amount,
                });
            }
        } else {
            const regex = /^\d*\.?\d{0,8}$/g;
            let match = regex.exec(amount);

            if (match && match.shift().length <= 15) {
                this.props.setOrderFormValues({
                    ...this.props.orderFormValues,
                    amount: this.checkMinLimitForAmount(amount),
                });
            }
        }
    }

    setPriceValue(e: Event): void {
        const priceLabel = e.target.getAttribute('data-price');
        const {orders} = this.props;
        let inputValue = null;

        if (priceLabel === 'min') {
            inputValue = _.minBy(orders.sell, 'price');
        } else if (priceLabel === 'max') {
            inputValue = _.maxBy(orders.buy, 'price');
        }

        if (inputValue && inputValue.price) {
            this.props.setOrderFormValues({
                ...this.props.orderFormValues,
                price: inputValue.price,
            });
        }
    }

    setAmountValue(e: Event): void {
        const amountLabel = e.target.getAttribute('data-price');
        const {orders} = this.props;
        let inputValue = null;
        const {balances, selectedSymbol} = this.props;

        const numeratorBalances = balances[selectedSymbol.numerator] ? +balances[selectedSymbol.numerator].available : 0;

        if (amountLabel === 'min' && orders.sell && orders.sell.length) {
            const minSellObj = _.minBy(orders.sell, 'price');
            const denominatorBalances = balances[selectedSymbol.denominator]
                ? +balances[selectedSymbol.denominator].available
                : 0;

            inputValue = denominatorBalances / minSellObj.price;
            inputValue = inputValue.toFixed(8);
        } else if (amountLabel === 'max') {
            inputValue = balances[selectedSymbol.numerator] && +balances[selectedSymbol.numerator].available;
        }

        if (inputValue) {
            this.props.setOrderFormValues({
                ...this.props.orderFormValues,
                amount: inputValue,
            });
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Exchange);
