import React, {Component, PureComponent} from 'react';
import {connect} from 'react-redux';

import ToolTip from 'react-portal-tooltip';
import {TableTooltipHistory} from '../../../../../component/TableTooltipHistory';

import FormattedPrice from '../../../../../component/FormattedPrice/FormattedPrice';
import parseTranslation from '../../../../../../adapter/helper/parseTranslation';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Preloader from 'infrastructure/view/component/Preloader';
import {fetchClosedOrders, clearClosedOrders} from '../service/actions';
import ErrorContainer from "../../../../../component/Error/ErrorContainer";

function mapStateToProps(state) {
    const {
        page,
        shouldLoadHistory,
        closedOrders,
        isPendingClosed,
        errorClosedOrders,
    } = state.tradingHistory;
    const  {name} = state.settings.selectedSymbol;
    return {
        isPendingClosed,
        tradingHistory: closedOrders,
        errorClosedOrders,
        page,
        name,
        shouldLoadHistory,
        titleTypeBuy: parseTranslation('type-buy'),
        titleTypeSell: parseTranslation('type-sell'),
        titleStatusConfirmed: parseTranslation('status-confirmed'),
        titleStatusCanceled: parseTranslation('status-canceled'),
    };
}

const mapDispatchToProps = {
    fetchClosedOrders,
    clearClosedOrders,
};

class Entry extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipActive: false,
        };

        this.timerId = null;
    }

    showTooltip() {
        this.setState(
            {isTooltipActive: false},

            () => {
                this.timerId = setTimeout(() => {
                    this.setState({isTooltipActive: true});
                }, 2000);
            }
        );
    }

    hideTooltip() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.setState({isTooltipActive: false});
    }



    render() {
        const {
            id,
            symbol,
            type,
            openingTime,
            price,
            amount,
            commission,
            closePrice,
            closTime,
            titleTypeBuy,
            titleTypeSell,
            titleStatusConfirmed,
            titleStatusCanceled,
            disableTooltip,
        } = this.props;

        let translationType = '';

        if (type === 'Buy') {
            translationType = titleTypeBuy;
        } else if (type === 'Sell') {
            translationType = titleTypeSell;
        }

        const {isTooltipActive} = this.state;
        const {isTooltipLeaveTable} = this.props;

        const tooltipId = `order-${id}`.replace('/', '');

        let style = {
            style: {
                background: 'rgba(0,0,0,0)',
                boxShadow: '0 0 0 rgba(0,0,0,0)',
                left: '0',
                transition: 'none',
                visibility: 'none',
            },
            arrowStyle: {
                display: 'none',
                borderColor: 'none',
            },
        };

        const [numerator, denominator] = symbol.split('/');

        let COLOR_RED = '#FF4D4D';
        let COLOR_GREEN = '#00B456';

        return (
            <>
                <ToolTip
                    active={isTooltipActive && isTooltipLeaveTable && !disableTooltip}
                    position="right"
                    style={style}
                    parent={'#' + tooltipId}
                    group="order"
                    tooltipTimeout={0}
                >
                    <TableTooltipHistory
                        symbol={
                            <span className="currency-pair-symbols">
                                <i
                                    className={` icon-currency icon-currency-left icon-${numerator.toLowerCase()}`}
                                />
                                <span
                                    style={{
                                        fontSize: '2.3rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {symbol}
                                </span>
                                <i
                                    className={`icon-currency icon-currency-right icon-${denominator.toLowerCase()}`}
                                />
                            </span>
                        }
                        type={
                            <span className="order-type">
                                <span className={`${type.toLowerCase()}`}>
                                    <i className={`${type}`} />
                                    {translationType}
                                </span>
                            </span>
                        }
                        price={<FormattedPrice price={+price} />}
                        amount={amount}
                    />
                </ToolTip>
                <tr
                    id={tooltipId}
                    onMouseEnter={this.showTooltip.bind(this)}
                    onMouseLeave={this.hideTooltip.bind(this)}
                    style={{
                        position: 'relative',
                    }}
                >
                    <td style={{width: '17%'}}>{id}</td>

                    <td>
                        <div className="currency-pair-wrapper">
                            <i
                                className={`icon-currency icon-${numerator.toLowerCase()}`}
                            />
                            <span className="currency-pair currency-pair-orders-expanded">
                                {symbol}
                            </span>
                            <i
                                className={`icon-currency icon-${denominator.toLowerCase()}`}
                            />
                        </div>
                    </td>

                    <td className="order-type">
                        <span className={`${type.toLowerCase()}`}>
                            <i className={`${type}`} />
                            {translationType}
                        </span>
                    </td>
                    <td>
                        <div style={{fontSize: '2.3rem', display: 'block'}}>
                            <div>{openingTime.format('HH:mm:ss')}</div>
                            <div>{openingTime.format('DD.MM.YYYY')}</div>
                        </div>
                    </td>
                    <td>
                        {price}
                    </td>
                    <td>
                        {amount}
                    </td>
                    <td>{commission}</td>
                    <td>
                        {closePrice}
                    </td>
                    <td style={{color: +closePrice !== 0 ? COLOR_GREEN : COLOR_RED}}>
                        {+closePrice !== 0
                            ? titleStatusConfirmed
                            : titleStatusCanceled}
                    </td>
                    <td>
                        <div style={{fontSize: '2.3rem', display: 'block'}}>
                            <div>{closTime.format('HH:mm:ss')}</div>
                            <div>{closTime.format('DD.MM.YYYY')}</div>
                        </div>
                    </td>
                </tr>
            </>
        );
    }
}

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipLeaveTable: false,
        };

        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleTooltip() {
        this.setState(state => ({
            isTooltipLeaveTable: !state.isTooltipLeaveTable,
        }));
    }

    componentDidMount() {
        const {fetchClosedOrders, page} = this.props;
        this.props.clearClosedOrders();
        fetchClosedOrders(1);
    }

    render() {
        const {isTooltipLeaveTable} = this.state;

        const {
            fetchClosedOrders,
            page,
            shouldLoadHistory,
            tradingHistory,
            isPendingClosed,
            disableTooltip,
            errorClosedOrders,
        } = this.props;

        return (
            <Scrollbars
                onScroll={e => {
                    const {scrollHeight, scrollTop, clientHeight} = e.target;
                    const offset = scrollTop + clientHeight - scrollHeight;
                    if (!offset && shouldLoadHistory) {
                        fetchClosedOrders(page);
                    }
                }}
            >
                {isPendingClosed ? (
                    <Preloader />
                ) : errorClosedOrders ? (
                    <ErrorContainer />
                ) : (
                    <div className="order-table-wrap">
                        <table
                            className="general-body"
                            onMouseLeave={this.toggleTooltip}
                            onMouseEnter={this.toggleTooltip}
                        >
                            {!!tradingHistory &&
                            tradingHistory.map((history, idx) => (
                                <Entry
                                    disableTooltip={disableTooltip}
                                    key={`${history.id}-${idx}`}
                                    {...history}
                                    isTooltipLeaveTable={isTooltipLeaveTable}
                                    titleTypeBuy={this.props.titleTypeBuy}
                                    titleTypeSell={this.props.titleTypeSell}
                                    titleStatusConfirmed={
                                        this.props.titleStatusConfirmed
                                    }
                                    titleStatusCanceled={
                                        this.props.titleStatusCanceled
                                    }
                                />
                            ))}
                        </table>
                    </div>
                )}
            </Scrollbars>
        );
    }
}

const ConnectedHistory = connect(
    mapStateToProps,
    mapDispatchToProps
)(History);
export default ConnectedHistory;
