// @flow
import type Axios from 'axios';
import localConfig from 'localConfig';
import {getDomain} from 'utils';

export interface ChatServiceXHR {
    constructor(httpClient: Axios): void;

    getChatAccessToken(token: string): Promise<any>;
}

class ChatServiceIMPL implements ChatServiceXHR {
    httpClient: Axios;

    constructor(httpClient: Axios): void {
        this.httpClient = httpClient;
    }

    async getChatAccessToken(token: string): Promise<any> {
        try {
            const {data}: {data: string} = await this.httpClient({
                method: 'POST',
                url: `${getDomain(localConfig.apiUrl)}/v1/user/chat-auth`,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'cache-control': 'no-cache',
                    authorization: `Bearer ${token}`,
                },
            });

            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default ChatServiceIMPL;
