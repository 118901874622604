import {ActionTypes} from '../action/forms';

const initState = {
    isPending: true,
    error: null,
    errorProfileForm: null,
    orderFormAmountIsLimit: false,
};

export default function(state = initState, action) {
    switch (action.type) {
        case ActionTypes.REMOVE_G_A_F:
            return {
                ...state,
                'GoogleAuthenticatorForm': null,
            };
        case ActionTypes.ORDER_FORM_AMOUNT_IS_LIMIT:
            return {
                ...state,
                orderFormAmountIsLimit: action.payload,
            };
        case ActionTypes.setMathCaptcha:
            return {...state, mathCaptchaUrl: action.data}
        case ActionTypes.setForm:
            if (!action.form || !action.form.name) {
                return state;
            }
            const prevScheme = state[action.form.name] && state[action.form.name].scheme;

            return {
                ...state,
                error: null,
                [action.form.name]: {
                    ...action.form,
                    scheme: action.form.scheme.reduce((result, field, idx) => {
                        // start save prev data
                        if(prevScheme && prevScheme[field.name]
                            && prevScheme[field.name].value
                            && !state[action.form.name].scheme[field.name].value) {
                            result[field.name] = {
                                ...field,
                                value: prevScheme[field.name].value,
                                idx,
                            };

                            return result;
                        }
                        //end save prev data
                        result[field.name] = {
                            ...field,
                            idx,
                        };

                        return result;
                    }, {}),
                    errors: action.form.errors,
                },
            };

        case ActionTypes.updateAllForm:
            return {
                ...state,
                [action.name]: {
                    ...action.form,
                },
            };

        case ActionTypes.updateForm:
            let object = state[action.form] && state[action.form].scheme;
            for (let key in object) {
                if (key === action.field) object[key].value = action.value;
            }
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    scheme: object,
                },
            };

        case ActionTypes.dropFormResult:
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    result: [],
                },
            };

        case ActionTypes.dropFormErrors:
            let errors = state[action.form].errors;

            if (Array.isArray(action.keys) && action.keys.length) {
                errors = {...state[action.form].errors};
                action.keys.forEach((key) => delete errors[key])
            }

            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    errors: errors,
                },
            };

        case ActionTypes.errorForm:
            return {
                ...state,
                error: action.payload,
            };

        case ActionTypes.errorPasswordForm:
            return {
                ...state,
                errorProfileForm: action.payload,
            };

        case ActionTypes.cleanQuestionForm:
            return {
                ...state,
                [action.formName]: null,
            };

        default:
            return state;
    }
}
