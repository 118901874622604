import {ActionType} from '../action/modal';

const initialState = {
    isOpenImagePreview: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ActionType.OPEN_PREVIEW_IMAGE_MODAL:
            return {
                ...state,
                isOpenImagePreview: true,
            };

        case ActionType.CLOSE_PREVIEW_IMAGE_MODAL:
            return {...state, isOpenImagePreview: false};

        default:
            return {...state};
    }
}
