import styled, {css} from 'styled-components';
import {firstLineColor} from '../components/LinesFill/styled';
import {panelBodyBg} from '../components/Panel/styled';

const titlesColor = '#999999';
const rowColor = '#000000';
const rowHoverBg = '#E6EBFF';
const rowHoverColor = '#000000';
const positive = '#00B456';
const negative = '#FF4D4D';
const iconHoverColor = '#2B9241';

export const GreenArrow = styled.i`
    font-size: 2.3rem;
    color: ${positive};
    margin: 0 5rem 0 4rem;
`;
export const RedArrow = styled.i`
    font-size: 2.3rem;
    color: ${negative};
    margin: 0 5rem 0 4rem;
`;

export const Header = styled.div`
    display: flex;
    padding: 2rem 0 2rem 4rem;
    align-items: center;
    justify-content: space-between;
`;
export const SortTabs = styled.div`
    display: flex;
    padding: 0 4rem 0 2rem;
    align-items: center;
    justify-content: space-between;
`;
export const Sort = styled.div`
    cursor: pointer;
    font-size: 3rem;
    margin-left: 4rem;
    position: relative;
    &:hover {
        div {
            display: flex;
        }
        i {
            color: ${iconHoverColor};
        }
    }
    .icon-buy-sell-down {
        display: inline-block;
        margin-right: -3rem;
        color: ${negative};
        position: relative;
        top: -0.3rem;
    }
    .icon-buy-sell-up {
        position: relative;
        top: -0.3rem;
        color: ${positive};
    }
    .icon-buy-up {
        color: ${positive};
    }
    .icon-sell-down {
        color: ${negative};
    }
`;
export const Tabs = styled.div`
    display: flex;
    padding: 1.5rem 0;
    & > div {
        width: 100%;
        margin: 0 2rem;
    }
`;

export const Titles = styled.div`
    display: flex;
    padding: 1.5rem 2rem;
    color: ${titlesColor};
`;
export const Count = styled.div`
    font-size: 2.333rem;
    width: 25%;
    display: flex;
    justify-content: ${({collapse}) => (collapse ? 'center' : 'space-between')};
    align-items: center;
    i {
        ${({collapse}) =>
            collapse &&
            css`
                margin-left: 0;
            `}
    }
    div {
        width: 3rem;
        white-space: nowrap;
        ${({collapse}) =>
            !collapse &&
            css`
                flex: 1;
                width: auto;
            `}
    }
`;
export const Amount = styled.div`
    font-size: 2.333rem;
    width: 25%;
    text-align: center;
`;
export const Total = styled.div`
    font-size: 2.333rem;
    width: 25%;
    text-align: center;
`;
export const Price = styled.div`
    font-size: 2.333rem;
    width: 25%;
    text-align: center;
`;
export const Markets = styled.div`
    display: flex;
    flex-direction: ${({collapsed}) => (collapsed ? 'row' : 'column')};
    flex: 1;
`;

export const MarketTopBottom = styled.div`    
    flex: 1;
`;

export const MarketTop = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 0;
    ${({collapse}) =>    
        !collapse &&
        css`
        margin-top: -1px;
            .view {
                display: flex;
                flex-direction: column;   
                             
            }
        `}
`;
export const MarketBottom = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const GreenLine = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
        ${({collapsed}) => (collapsed ? 'to left' : 'to right')},
        rgba(14, 60, 54, 0.35) 0%,
        rgba(0, 227, 106, 0.15) ${({width}) => width},
        rgba(0, 0, 0, 0) 0%
    );
`;
export const RedLine = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
        to right,
        rgba(95, 46, 62, 0.35) 0%,
        rgba(255, 67, 67, 0.35) ${({width}) => width},
        rgba(0, 0, 0, 0) 0%
    );
`;
export const PriceText = styled.span`
    color: ${({isTop}) => (isTop ? positive : negative)};
`;

export const DepthRowWrap = styled.div`
    display: flex;
    padding: 1.5rem 2rem;
    color: ${rowColor};
    min-height: 4.5rem;
    align-items: center;
    z-index: 1;
    position: relative;
    background: ${({isOdd}) => (isOdd ? firstLineColor : panelBodyBg)};
    &:hover {
        background: ${rowHoverBg};
        color: ${rowHoverColor};
    }
`;
export const ContentWrap = styled.div`
    font-size: 2.3rem;
    color: ${rowColor};
    display: flex;
    padding: 2.5rem 2rem;
`;

export const ContentColumn = styled.div`
    display: table;    
`;
export const ContentRow = styled.div`
    display: table-row;    
`;
export const ContentLabel = styled.div`
    display: table-cell;
    padding-right: 2rem;
`;
export const ContentValue = styled.div`
    ${({isPositive}) =>
        isPositive &&
        css`
            color: ${positive};
        `}
    ${({isNegative}) =>
        isNegative &&
        css`
            color: ${negative};
        `}
    i {
        font-size: 2.5rem;
    }
    span {
        display: inline-block;
        margin: 0 1rem;
    }
`;
