import React, {forwardRef} from 'react';
import {StyledScrollbars} from './styled';

const ScrollBar = forwardRef(({children, onScroll}, ref) => {
    const handleScroll = el => onScroll && onScroll(el);
    return(
        <StyledScrollbars
            ref={ref}
            onScroll={handleScroll}
            autoHide
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}>
            {children}
        </StyledScrollbars>
    )
});


export default ScrollBar;
