import React, {useState} from 'react';
import t from '../../../infrastructure/adapter/helper/parseTranslation';
import TabButton from '../../components/TabButton';
import {ChartTabsWrapper} from '../styled';

export default function ChartTabs({onChange}) {
    const [isDxChart, setDxChart] = useState(true);
    const handleChange = value => () => {
        onChange(value);
        setDxChart(value);
    };
    return (
        <ChartTabsWrapper>
            <TabButton
                title={'Custom'}
                onClick={handleChange(true)}
                active={isDxChart}
            />

            <TabButton
                title={'Trading View'}
                onClick={handleChange(false)}
                active={!isDxChart}
            />
        </ChartTabsWrapper>
    );
}
