import React, {Component} from 'react';
import styled from 'styled-components';
import {customChartStudiesKey} from 'settings';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import {connect} from 'react-redux';

import ChartMenuBtn from './ChartMenuBtn';
import Dropdown from '../../../../component/Dropdown/Dropdown';

import studiesList from './studiesList';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';

class StudiesMenu extends Component {
    state = {
        selectedList: [],
        showSelectedList: false,
        disabledStudies: [
            'AccelerationDeceleration',
            'SwingAccumulation',
            'AccumulationDistribution',
            'ADX',
            'ADXR',
            'Aroon',
            'AroonOscillator',
            'AverageTrueRange',
            'AwesomeOscillator',
            'CCI',
            'CenterOfGravityOscillator',
            'ChaikinOscillator',
            'ChaikinVolatility',
            'ChandeMomentumOscillator',
            'CommoditySelection',
            'DayOpenClose',
            'DeMarker',
            'DetrendedPriceOsc',
            'DMI',
            'DynamicMomentumIndex',
            'Elder',
            'EnvelopeEMA',
            'FastStochastic',
            'ForceIndex',
            'ForecastOscillator',
            'FullStochastic',
            'GatorOscillator',
            'HLVolatility',
            'Inertia',
            'IntradayMomentumIndex',
            'KRI',
            'LinearRegressionSlope',
            'MACD',
            'MarketFacilitationIndex',
            'MassIndex',
            'Momentum',
            'MoneyFlowIndex',
            'OnBalanceVolume',
            'Oscillator',
            'NegativeVolumeIndex',
            'ParabolicSAR',
            'PercentagePriceOscillator',
            'PercentChange',
            'PercentOfResistance',
            'PivotPoints',
            'PriceVolumeTrend',
            'PriceOscillator',
            'RelativeStrengthIndex',
            'RelativeVigorIndex',
            'RelativeVigorIndexSMA',
            'RelativeVolatilityIndex',
            'ROC',
            'SchaffTrendCycle',
            'SlowStochastic',
            'EnvelopeSMA',
            'EnvelopeSMMA',
            'SROC',
            'RankCorrelationIndex',
            'StandardDeviation',
            'StdDevVolatility',
            'SwingIndex',
            'TrueStrengthIndex',
            'TripleEMA',
            'UltimateOscillator',
            'VerticalHorizontalFilter',
            'WilliamsAD',
            'ZigZag',
            'EnvelopeWMA',
            'WilliamsPercentRange',
            'WilliamsAlligator',
            'WilliamsFractal',
        ],
    };

    componentDidMount() {
        const customChartStudies = sessionStorage.getItem(customChartStudiesKey);

        if (customChartStudies) {
            const customChartStudiesData = JSON.parse(customChartStudies);

            this.setState({selectedList: customChartStudiesData}, this.setStudies);
        }
    }

    componentDidUpdate(prevProps) {
        const {deleteI} = this.props;

        if (deleteI !== prevProps.deleteI && deleteI === true) {
            this.setState({selectedList: []}, this.setStudies);
        }
    }

    setStudies() {
        const {selectedList} = this.state;
        const {getStudies} = this.props;

        const studiesJSON = JSON.stringify(selectedList);
        sessionStorage.setItem(customChartStudiesKey, studiesJSON);

        if (getStudies) {
            getStudies(selectedList);
        }
    }

    onSelected = item => () => {
        this.setState(
            state => ({
                selectedList: [...state.selectedList, item],
            }),
            this.setStudies
        );
    };

    onRemove = id => () => {
        this.setState(
            state => ({
                selectedList: state.selectedList.filter(item => item.id !== id),
            }),
            this.setStudies
        );
    };

    getStudiesList() {
        const {selectedList, disabledStudies} = this.state;

        return studiesList
            .filter(
                item =>
                    !selectedList.some(el => el.id === item.id) &&
                    !disabledStudies.some(id => id === item.id)
            )
            .sort(function(a, b) {
                const aTitle = a.title.toLowerCase();
                const bTitle = b.title.toLowerCase();

                if (aTitle < bTitle) {
                    return -1;
                }
                if (aTitle > bTitle) {
                    return 1;
                }
                return 0;
            });
    }

    onShowEnter = () => {
        this.setState({showSelectedList: true});
    };

    onHideEnter = () => {
        this.setState({showSelectedList: false});
    };

    render() {
        const {selectedList, showSelectedList} = this.state;

        return (
            <Styles
                selectedList={!!selectedList.length}
                className="studies-menu"
            >
                <Dropdown
                    btn={<ChartMenuBtn icon="icon-chart-indicators" />}
                    tooltip={<Tooltip>{this.props.tooltipIndicators}</Tooltip>}
                >
                    <div className="studies-menu__drop-down">
                        <div className="studies-menu__drop-down-wrap">
                            <div
                                className="studies-menu__added-container"
                                onMouseEnter={this.onShowEnter}
                                onMouseLeave={this.onHideEnter}
                            >
                                {!!selectedList.length && (
                                    <>
                                        <div className="studies-menu__added">
                                            {this.props.added}
                                            <i className="icon-single-right-pointing-angl" />
                                        </div>
                                        {showSelectedList && (
                                            <div className="studies-menu__added-list">
                                                {selectedList.map(item => (
                                                    <div
                                                        key={item.id}
                                                        className="studies-menu__item"
                                                    >
                                                        {item.title}
                                                        <button
                                                            className="studies-menu__item-remove"
                                                            onClick={this.onRemove(
                                                                item.id
                                                            )}
                                                        >
                                                            <i className="icon-close" />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <Scrollbars style={{height: '45rem'}}>
                                <div className="studies-menu__list-wrap">
                                    <div className="studies-menu__list">
                                        {this.getStudiesList().map(item => (
                                            <div
                                                className="studies-menu__item"
                                                key={item.id}
                                                onClick={this.onSelected(item)}
                                            >
                                                {item.title}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                </Dropdown>
            </Styles>
        );
    }
}

const Tooltip = styled.div`
    position: absolute;
    top: calc(100% + 2.666rem);
    left: 50%;
    transform: translate(-50%);
    max-width: 50rem;
    min-width: 20rem;
    border: 1px solid ${p => p.theme.color1};
    box-shadow: 0 0 3rem ${props => props.theme.color66};
    text-align: center;
    background-color: ${p => p.theme.color23};
    color: ${p => p.theme.color14};
    font-size: 2.333rem;
    padding: 1rem;
    box-sizing: border-box;
    word-wrap: break-word;
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0;
    pointer-events: none;    

    &:after {
        content: ' ';
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        display: block;
        width: 2rem;
        height: 2rem;
        border: 1px solid ${p => p.theme.color1};
        border-bottom: none;
        border-right: none;
        background-color: ${p => p.theme.color23};
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        z-index: 10;
    }
`;

const Styles = styled.div`
    position: relative;
    margin-right: 1rem;

    .wrapper-dropdown {
        height: 100%;
        margin-right: 0 !important;
    }

    .studies-menu {
        margin-right: 6rem;

        &__btn {
            width: 6.666rem;
            height: 100%;
            background-color: #f5f5f5;
            overflow: hidden;
            color: #999999;
            transition: color 0.3s;

            &::before {
                content: '';
                position: absolute;
                bottom: 0.7rem;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 3px 3px 0 3px;
                border-color: #999999 transparent transparent transparent;
                transition: border 0.3s;
            }

            i {
                font-size: 3rem;
            }

            &:hover {
                color: #0c3dfb;

                &::before {
                    border-color: #0c3dfb transparent transparent transparent;
                }
            }
        }
        
        &__drop-down-wrap {
            border-radius: 3rem;
            //overflow: hidden;
        }

        &__drop-down {
            position: absolute;
            top: 8rem;
            left: 50%;
            transform: translateX(-50%);
            width: 33.33rem;
            color: ${p => p.theme.color14};
            font-size: 2.3rem;
            background-color: ${p => p.theme.color23};
             border: 1px solid ${props => props.theme.color1};
            box-shadow: 0 0 3rem $color66;            
            z-index: 101;
            
            .scrollbars-container {                
                overflow: hidden;
                ${p =>
                    !p.selectedList &&
                    `
                    
                `}
            }

            &:before,
            &:after {
                content: '';
                position: absolute;                
                left: 50%;
                bottom: 100%;
                transform: translateX(-50%);                           
                border: 1.5rem solid transparent;
                border-bottom-color: ${p => p.theme.color1};                             
                z-index: 10;
            }
            &:after {
                margin-top: 1px;
                border-bottom-color: ${p => p.theme.color23};   
                margin-bottom: -1px;
            }
        }

        &__list {
            position: relative;
        }

        &__item {
            position: relative;
            cursor: pointer;
            padding: 2rem 6rem 2rem 2rem;

            &:hover {
                position: relative;
                color: ${p => p.theme.color15};  
                background: ${p => p.theme.color69};
                z-index: 1;
                
                &:after {
                    display: none;
                }
            }

            &:hover .icon-close {
                color: ${p => p.theme.color1};
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 4rem);
                height: 0.2rem;
                background-color: ${p => p.theme.color7};
            }

            &:first-child {
                // ${p =>
                    !p.selectedList &&
                    `
                //     border-top-left-radius: 3rem;
                //     border-top-right-radius: 3rem;
                // `}
            }
            
            &:last-child {
                //border-bottom-left-radius: 3rem;
                //border-bottom-right-radius: 3rem;
                &:after {
                    display: none;
                }
            }
        }

        &__added-container {
            position: relative;

            &:hover .studies-menu__added {
                color: ${p => p.theme.color1};
            }
        }

        &__added {
            position: relative;
            z-index: 5;
            cursor: pointer;
            padding: 2rem;
            color: ${p => p.theme.color1};
            transition: 0.3s;
            background-color: ${p => p.theme.color69};            

            i {
                position: absolute;
                top: 50%;
                right: 1.5rem;
                transform: translateY(-50%);
            }
        }

        &__added-list {
            position: absolute;
            top: 0;
            left: 100%;
            min-width: 33.33rem;
            border: 1px solid ${p => p.theme.color1};
            background-color: ${p => p.theme.color23};            
            overflow: hidden;
            box-shadow: 0 0 3rem ${p => p.theme.color66};
        }

        &__item-remove {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
            height: 5rem;
            width: 5rem;

            .icon-close {
                font-size: 2.6rem;
            }
        }
    }

    &:hover {
        ${Tooltip} {
            opacity: 1;
            transition-delay: 1s;
        }
    }
`;

const mapStateToProps = state => ({
    tooltipIndicators: parseTranslation('tooltip-chart1-indicators'),
    added: parseTranslation('label-chart1-added'),
});

export default connect(mapStateToProps, null)(StudiesMenu);
