export const ActionTypes = {
    getDepositForm: 'billing-scene.deposit-form.get',
    submitDepositForm: 'billing-scene.deposit-form.submit',
    getWithdrawForm: 'billing-scene.withdraw-form.get',
    submitWithdrawForm: 'billing-scene.withdraw-form.submit',
    getTransactionHistory: 'billing-scene.transaction-history.get',
    setTransactionHistory: 'billing-scene.transaction-history.set',
    transactionReceived: 'transaction.received',
    errorTransaction: 'transaction.error',
};

export function getDepositForm(formData) {
    return {
        type: ActionTypes.getDepositForm,
        formData
    };
}

export function submitDepositForm(formData) {
    return {
        type: ActionTypes.submitDepositForm,
        formData
    };
}

export function getWithdrawForm(formData) {
    return {
        type: ActionTypes.getWithdrawForm,
        formData
    };
}

export function submitWithdrawForm(formData) {
    return {
        type: ActionTypes.submitWithdrawForm,
        formData
    };
}

export function getTransactionHistory() {
    return {
        type: ActionTypes.getTransactionHistory
    }
}

export function setTransactionHistory(history) {
    return {
        type: ActionTypes.setTransactionHistory,
        history
    };
}

export function errorTransaction(payload) {
    return {
        type: ActionTypes.errorTransaction,
        payload,
    }
}
