// @flow
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Modal from 'react-modal';

import parseTranslation from '../../../../adapter/helper/parseTranslation';

import {getPasswordForm, submitPasswordForm} from '../service/actions';
import FormField from '../../Registration/component/FormField';
import {updateForm} from '../../../services/action/forms';
import {
    checkboxChange,
    showEnableModal,
    hideEnableModal,
    nextEnableStep,
    prevEnableStep,
    restoreEnableStep,
    changeTwoFAStatus,
    showDisableModal,
    hideDisableModal,
    getTwoFAStatus,
    getTwoFAService,
    getEnableForm,
    submitEnableForm,
    getDisableForm,
    submitDisableForm,
} from '../../../services/action/twoFA';
import Error from '../../../component/Error';

function mapStateToProps(state) {
    const {local} = state.translation;
    const passwordForm = state.forms['PasswordForm'] || {};
    const google2FAForm = state.forms['SwitchGoogleAuthenticatorForm'] || {};
    const formError = state.forms.error;
    const formErrorProfile = state.forms.errorProfileForm;

    return {
        passwordForm,
        google2FAForm,
        local,
        labelButtonSaveChanges: parseTranslation('label-save-change'),
        authentication: parseTranslation('authentication'),
        downloadLabel: parseTranslation('download'),
        scanLabel: parseTranslation('scan'),
        confirmLabel: parseTranslation('conform'),
        step1: parseTranslation('step1'),
        step2: parseTranslation('step2'),
        step3: parseTranslation('step3'),
        nextBtn: parseTranslation('nextStep'),
        prevBtn: parseTranslation('prevStep'),
        step1Desc: parseTranslation('step1Desc'),
        step1CH: parseTranslation('step1CH'),
        needConfirm: parseTranslation('needConfirm'),
        step2p1: parseTranslation('step2p1'),
        step2p2: parseTranslation('step2p2'),
        step2p3: parseTranslation('step2p3'),
        step2p4: parseTranslation('step2p4'),
        step2CH1: parseTranslation('step2CH1'),
        step2CH2: parseTranslation('step2CH2'),
        enableBtn: parseTranslation('enableBtn'),
        disableBtn: parseTranslation('disableBtn'),
        enableModalTitle: parseTranslation('enableModalTitle'),
        disableModalTitle: parseTranslation('disableModalTitle'),
        twoFA: state.twoFA,
        oN2FA: parseTranslation('oN2FA'),
        oFF2FA: parseTranslation('oFF2FA'),
        explanations2FA: parseTranslation('explanations2FA'),
        password2FA: parseTranslation('password2FA'),
        formError,
        formErrorProfile,
    };
}

const mapDispatchToProps = {
    getPasswordForm,
    submitPasswordForm,
    updateForm,
    checkboxChange,
    showEnableModal,
    hideEnableModal,
    nextEnableStep,
    prevEnableStep,
    restoreEnableStep,
    showDisableModal,
    hideDisableModal,
    changeTwoFAStatus,
    getTwoFAStatus,
    getTwoFAService,
    getEnableForm,
    submitEnableForm,
    getDisableForm,
    submitDisableForm,
};

type PropTypes = {
    labelCurrentPassword: string,
    labelNewPassword: string,
    labelConfirmPassword: string,
    labelButtonSaveChanges: string,
    passwordForm: {},
    getPasswordForm: () => mixed,
    submitPasswordForm: () => mixed,
    updateForm: () => mixed,
};

export class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.changeState = this.changeState.bind(this);
    }

    changeState() {
        this.props.onChange(!this.props.checked);
    }

    render() {
        const { oN2FA, oFF2FA } = this.props;

        return (
            <div onClick={this.changeState} className="checkbox-2FA">
                <input type="checkbox" checked={this.props.checked}  />
                <label
                    className="checkbox-2FA__label"
                >
                    <span className="checkbox-2FA__label-text" >{this.props.label}</span>


                    <div className="checkbox-custom">
                        <span className="checked-text">
                            {!this.props.checked ? oFF2FA : oN2FA}
                        </span>
                    </div>
                </label>
            </div>
        );
    }
}

const ProgressBarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 3.33rem;
    background-color: ${props => props.theme.color5};
    border-radius: 3.33rem;
    position: relative;
    overflow: hidden;
    height: 3.3rem;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: ${props => props.value};
        height: 3.33rem;
        top: 0;
        left: 0;
        background-color: ${props => props.theme.color1};
        border-radius: 3.33rem;
        z-index: 0;
        transition: width linear 0.8s;
    }
    div {
        color: #999999;
        font-size: 2.3rem;
        z-index: 1;

        &.white-color {
            color: #ffffff;
        }
    }
`;

export class ProgressBar extends Component {
    render() {
        return (
            <ProgressBarWrapper value={this.props.value}>
                <div className={this.props.step >= 1 ? 'white-color' : ''}>
                    1. {this.props.downloadLabel}
                </div>
                <div className={this.props.step >= 2 ? 'white-color' : ''}>
                    2. {this.props.scanLabel}
                </div>
                <div className={this.props.step >= 3 ? 'white-color' : ''}>
                    3. {this.props.confirmLabel}
                </div>
            </ProgressBarWrapper>
        );
    }
}

class PasswordSettings extends Component<PropTypes> {
    constructor(props) {
        super(props);
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.changeCheckbox = this.changeCheckbox.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.hideEnabledModal = this.hideEnabledModal.bind(this);
        this.hideDisabledModal = this.hideDisabledModal.bind(this);
        this.disableTwoFA = this.disableTwoFA.bind(this);
        this.handleEnableTwoFASubmit = this.handleEnableTwoFASubmit.bind(this);
        this.handleDisableTwoFASubmit = this.handleDisableTwoFASubmit.bind(
            this
        );
        this.changeValidate = this.changeValidate.bind(this);

        this.checkbox1 = React.createRef();
        this.checkbox2 = React.createRef();
        this.checkbox3 = React.createRef();
    }

    componentDidMount(): void {
        this.props.getPasswordForm();
        this.props.getTwoFAStatus();
    }

    componentWillUnmount(): void {
        Object.keys(this.props.passwordForm.scheme).map(field => {
            this.props.updateForm(
                'PasswordForm',
                field,
                ''
            )
        });

    }

    componentDidUpdate(prevProps) {
        const {local} = this.props;

        if (prevProps.local !== local) {
            this.props.getPasswordForm(local);
        }
    }

    render2FAModalScreen1() {
        return (
            <div className="modal-wrapper">
                <div className="modal-content modal-content_2fa">
                    <p className="modal-content__title">
                        {this.props.step1} <span>1</span>
                    </p>
                    <div>{this.props.step1Desc}</div>
                    <div className="app-links">
                        <a
                            href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img src="../img/app-store.svg" alt="app store" />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <img
                                src="../img/google-play.svg"
                                alt="google play"
                            />
                        </a>
                    </div>
                    <div className="modal-checkbox">
                        <input
                            ref={this.checkbox1}
                            onChange={this.changeValidate}
                            type="checkbox"
                            id="screen1ch"
                        />
                        <label htmlFor="screen1ch">
                            {this.props.step1CH}
                            <span className="error">
                                {this.props.needConfirm}
                            </span>
                        </label>
                    </div>
                </div>
                <div className="modal-button">
                    <button
                        type={'button'}
                        className="button"
                        onClick={this.nextStep}
                    >
                        {this.props.nextBtn}
                    </button>
                </div>
            </div>
        );
    }
    render2FAModalScreen2() {
        const {qrCode, code} = this.props.twoFA;
        return (
            <div className="modal-wrapper">
                <div className="modal-content">
                    <p>
                        {this.props.step2} <span>2</span>
                    </p>
                    <div className="line-content">
                        <div className="qr-code">
                            <img src={qrCode} alt="qr code" />
                        </div>
                        <div className="qr-code__step2">
                            <span>{this.props.step2p1}</span>
                            <span>{this.props.step2p2}</span>
                            <span className="code">{code}</span>
                            <span>{this.props.step2p3}</span>
                            <span>{this.props.step2p4}</span>
                            <div className="modal-checkbox modal-checkbox_2">
                                <input
                                    ref={this.checkbox2}
                                    onChange={this.changeValidate}
                                    type="checkbox"
                                    id="screen21ch"
                                />
                                <label htmlFor="screen21ch">
                                    {this.props.step2CH1}
                                </label>
                                <span className="error">
                                    {this.props.needConfirm}
                                </span>
                            </div>
                            <div />
                            <div className="modal-checkbox modal-checkbox_2">
                                <input
                                    ref={this.checkbox3}
                                    onChange={this.changeValidate}
                                    type="checkbox"
                                    id="screen22ch"
                                />
                                <label htmlFor="screen22ch">
                                    {this.props.step2CH2}
                                </label>
                                <span className="error">
                                    {this.props.needConfirm}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-button">
                    <button
                        type={'button'}
                        className="button button--prev"
                        onClick={this.prevStep}
                    >
                        {this.props.prevBtn}
                    </button>

                    <button
                        type={'button'}
                        className="button"
                        onClick={this.nextStep}
                    >
                        {this.props.nextBtn}
                    </button>
                </div>
            </div>
        );
    }
    render2FAModalScreen3() {
        const {scheme, name, errors} = this.props.google2FAForm;
        return (
            <div className="modal-wrapper">
                <div className="modal-content step3">
                    <p className="step3-title">
                        {this.props.step3} <span>3</span>
                    </p>
                    <form
                        className="screen3form"
                        onSubmit={this.handleEnableTwoFASubmit}
                    >
                        <div className="form-wrapper">
                            {scheme &&
                                Object.values(scheme).map(field => (
                                    <FormField
                                        key={field.name}
                                        name={field.name}
                                        type={field.type}
                                        label={field.label}
                                        options={field.options}
                                        onChange={value =>
                                            this.props.updateForm(
                                                'SwitchGoogleAuthenticatorForm',
                                                field.name,
                                                value
                                            )
                                        }
                                        error={
                                            !Array.isArray(errors) &&
                                            errors[field.name] &&
                                            errors[field.name][0]
                                        }
                                        formName={name}
                                    />
                                ))}
                        </div>
                        <div className="modal-button">
                            <button type="submit" className="button">
                                {this.props.enableBtn}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    render() {
        const {
            passwordForm,
            updateForm,
            google2FAForm,
            formError,
            formErrorProfile,
            oFF2FA,
            oN2FA,
        } = this.props;
        const {scheme, name, errors} = passwordForm;
        const {
            checkboxStatus,
            enableStep,
            enableModalState,
            disableModalState,
        } = this.props.twoFA;

        return (
            <Fragment>
                <Modal
                    onRequestClose={this.hideEnabledModal}
                    isOpen={enableModalState}
                    className="modal modal2FA"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(20, 22, 27, 0.65)',
                            zIndex: 2,
                        },
                    }}
                >
                    <div className="modal-header">
                        <span>{this.props.enableModalTitle}</span>
                        <button onClick={this.hideEnabledModal}>
                            <i className="icon-close" />
                        </button>
                    </div>

                    <div>
                        <ProgressBar
                            downloadLabel={this.props.downloadLabel}
                            scanLabel={this.props.scanLabel}
                            confirmLabel={this.props.confirmLabel}
                            step={enableStep}
                            value={
                                enableStep === 1
                                    ? '35%'
                                    : enableStep === 2
                                    ? '70%'
                                    : enableStep === 3
                                    ? '95%'
                                    : '100%'
                            }
                        />

                        {formError ? (
                            <Error />
                        ) : enableStep === 1 ? (
                            this.render2FAModalScreen1()
                        ) : enableStep === 2 ? (
                            this.render2FAModalScreen2()
                        ) : (
                            this.render2FAModalScreen3()
                        )}
                    </div>
                </Modal>

                <Fragment>
                    <Modal
                        onRequestClose={this.hideDisabledModal}
                        isOpen={disableModalState}
                        className="modal disable-2FA"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(20, 22, 27, 0.65)',
                                zIndex: 2,
                            },
                        }}
                    >
                        <div className="modal-header">
                            <span>{this.props.disableModalTitle}</span>
                            <button onClick={this.hideDisabledModal}>
                                <i className="icon-close" />
                            </button>
                        </div>

                        <div className="modal-wrapper">
                            <div className="modal-content">
                                <form
                                    className="screen3form screenDisableForm"
                                    onSubmit={this.handleDisableTwoFASubmit}
                                >
                                    <Fragment>
                                        {google2FAForm.scheme &&
                                            Object.values(
                                                google2FAForm.scheme
                                            ).map(field => (
                                                <FormField
                                                    key={field.name}
                                                    name={field.name}
                                                    type={field.type}
                                                    value={field.value}
                                                    label={field.label}
                                                    options={field.options}
                                                    onChange={value =>
                                                        updateForm(
                                                            'SwitchGoogleAuthenticatorForm',
                                                            field.name,
                                                            value
                                                        )
                                                    }
                                                    error={
                                                        !Array.isArray(
                                                            google2FAForm.errors
                                                        ) &&
                                                        google2FAForm.errors[
                                                            field.name
                                                        ] &&
                                                        google2FAForm.errors[
                                                            field.name
                                                        ][0]
                                                    }
                                                    formName={
                                                        google2FAForm.name
                                                    }
                                                />
                                            ))}

                                        <div className="modal-button">
                                            <button
                                                type="submit"
                                                className="button"
                                            >
                                                {this.props.disableBtn}
                                            </button>
                                        </div>
                                    </Fragment>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </Fragment>
                {/* SwitchGoogleAuthenticatorForm */}
                <div className="form-group security-settings">
                    <form onSubmit={this.submitFormHandler} autoComplete="off">
                        {!formErrorProfile && (
                            <div className="flex-wrapper">
                                <div className='wrapper-checkbox-2FA'>
                                    <Checkbox
                                        onChange={this.changeCheckbox}
                                        idName="google-2FA"
                                        label={this.props.authentication}
                                        checked={checkboxStatus}
                                        oN2FA={oN2FA}
                                        oFF2FA={oFF2FA}
                                    />
                                    <p className='details-text-2FA'>{this.props.explanations2FA}</p>
                                </div>

                                <div className='wrapper-form-profile-password'>
                                    <span className='title'>{this.props.password2FA}</span>
                                    {scheme &&
                                        Object.values(scheme).map(field => (
                                            <FormField
                                                key={field.name}
                                                name={field.name}
                                                type={field.type}
                                                label={field.label}
                                                options={field.options}
                                                value={field.value}
                                                onChange={value =>
                                                    updateForm(
                                                        'PasswordForm',
                                                        field.name,
                                                        value
                                                    )
                                                }
                                                error={
                                                    !Array.isArray(errors) &&
                                                    errors[field.name] &&
                                                    errors[field.name][0]
                                                }
                                                formName={name}
                                            />
                                        ))}
                                    <button
                                        type={'submit'}
                                        className="changes"
                                    >
                                        {this.props.labelButtonSaveChanges}
                                    </button>
                                </div>
                            </div>
                        )}
                        <input className="hidden-field" />
                        <input className="hidden-field" type="password" />
                        {formErrorProfile && <Error />}
                    </form>
                </div>
            </Fragment>
        );
    }

    submitFormHandler(e) {
        e.preventDefault();
        this.props.submitPasswordForm();
    }

    changeValidate(e) {
        if (!e.target.checked) {
            e.target.parentNode.classList.add('invalid');
        } else {
            e.target.parentNode.classList.remove('invalid');
        }
    }

    changeCheckbox(checked) {
        if (checked) {
            this.props.showEnableModal();
            this.props.restoreEnableStep();
            this.props.getEnableForm();
            this.props.getTwoFAService();
        } else {
            this.props.getDisableForm();
            this.props.showDisableModal();
        }
    }

    hideEnabledModal() {
        this.props.hideEnableModal();
        this.props.checkboxChange(false);
    }

    hideDisabledModal() {
        this.props.hideDisableModal();
        this.props.checkboxChange(true);
    }

    nextStep() {
        if (!this.checkbox2.current) {
            if (this.checkbox1.current.checked) {
                this.props.nextEnableStep();
            } else {
                this.checkbox1.current.parentNode.classList.add('invalid');
            }
        } else {
            if (
                this.checkbox2.current.checked &&
                this.checkbox3.current.checked
            ) {
                this.props.nextEnableStep();
            } else {
                if (!this.checkbox2.current.checked) {
                    this.checkbox2.current.parentNode.classList.add('invalid');
                }
                if (!this.checkbox3.current.checked) {
                    this.checkbox3.current.parentNode.classList.add('invalid');
                }
            }
        }
    }

    prevStep() {
        this.props.prevEnableStep();
    }

    disableTwoFA() {
        this.props.changeTwoFAStatus(false);
        this.props.hideDisableModal();
    }

    handleEnableTwoFASubmit(e) {
        e.preventDefault();
        this.props.submitEnableForm();
    }

    handleDisableTwoFASubmit(e) {
        e.preventDefault();
        this.props.submitDisableForm();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordSettings);
