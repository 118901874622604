import React, {useEffect, useState} from 'react';
import CurrencyPair from '../components/CurrencyPair';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../components/Modal';
import ModalTop from './components/ModalTop';
import ModalFind from './components/ModalFind';
import Currency from './components/Currency';
import {dealingAssets} from '../webSockets/constants';
import GuestSymbolsContainer from '../GuestSymbols';
import {ModalFooter, ModalSymbols} from './components/styled';
import {setModalOpen} from '../components/Modal/actions';
import {setCurrencyPair} from './actions';
import t from '../../infrastructure/adapter/helper/parseTranslation';

export default function CurrencyPairContainer({isInChart}) {
    const dispatch = useDispatch();
    const selectedPair = useSelector(state => state.CURRENCY_PAIR.asset);
    const symbols = useSelector(state => state.GUEST_DEALING_WS[dealingAssets]);
    const openModal = useSelector(state => state.MODAL.open);
    const [filteredSymbols, setFilteredSymbols] = useState(null);
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [filterStr, setFilterStr] = useState('');
    const [newSelectedPair, setSelectedPair] = useState('');
    const handleClick = () => dispatch(setModalOpen(true));
    const handleClose = () => dispatch(setModalOpen(false));
    const handleApply = () => {
        dispatch(setModalOpen(false));
        newSelectedPair && dispatch(setCurrencyPair(newSelectedPair));
    };
    const handleChangeCurrency = value => setSelectedSymbol(value);
    const handleSelectPair = value => setSelectedPair(value);
    useEffect(() => {
        setFilteredSymbols(symbols);
    }, [symbols]);
    useEffect(() => {
        setSelectedSymbol('');
        setSelectedPair('');
        setFilterStr('');
    }, [openModal]);

    const handleFilter = value => setFilterStr(value);

    return (
        <>
            <CurrencyPair
                isInChart={isInChart}
                pair={selectedPair}
                onSelect={handleClick}
            />
            <Modal
                isOpen={openModal}
                title={t('tooltip-all-symbols')}
                width={140}
            >
                <ModalTop
                    selectedPair={selectedPair}
                    newPair={newSelectedPair}
                />
                <ModalFind onChange={handleFilter} />
                <ModalSymbols>
                    <Currency
                        filter={filterStr}
                        symbols={symbols}
                        selectedSymbols={selectedSymbol || selectedPair}
                        onSelect={handleChangeCurrency}
                    />
                    <GuestSymbolsContainer
                        isModal
                        filter={filterStr || selectedSymbol || selectedPair.split('/')[0]}
                        selectedPair={newSelectedPair || selectedPair}
                        onSelect={handleSelectPair}
                    />
                </ModalSymbols>
                <ModalFooter>
                    <button onClick={handleClose} className="secondary-button">
                        {t('cancel-button')}
                    </button>
                    <button onClick={handleApply} className="main-button">
                        {t('apply-button')}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
}
