import {callApi, callApiActionFactory} from '../actions/actionTypes';

export const GET_GUEST_API_URL = callApi('GET_GUEST_API_URL');
export const SET_SITE_PAIR_ALIAS = 'SET_SITE_PAIR_ALIAS';

const API_URL = '/v1/guest/settings';

export const getGuestApiUrl = () =>
    callApiActionFactory(GET_GUEST_API_URL, API_URL, 'GET');
export const setSitePairAlias = value => ({type: SET_SITE_PAIR_ALIAS, value})
