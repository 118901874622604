import styled, {css} from 'styled-components';

const arrowColor = '#999';
const inputBg = '#F5F5F5';
const inputColor = '#000000';
const findIconColor = '#999999';
const currencyIconColor = '#999999';
const currencyLabelColor = '#000000';
const footerBorder = '#f5f5f5';
const activeRow = 'linear-gradient(90deg, #000 0%, rgba(51,51,51,0.8) 100%);';
const rowHoverBg = 'rgba(43, 146, 65, 0.2)';
const activeIconColor = '#fff';
const activeIconHoverColor = '#2B9241';
const clearIconColor = '#999999';
const clearIconColorHover = '#2B9241';

export const ModalTopWrapper = styled.div`
    display: flex;
    padding: 4rem;
    align-items: center;
`;

export const ModalSymbols = styled.div`
    display: flex;
    border-top: 1px solid ${footerBorder};
    & > div {
        flex: 1;
    }
`;

export const Arrow = styled.i`
    font-size: 8.8rem;
    margin: 0 2rem;
    color: ${arrowColor};
`;
export const FindIcon = styled.i`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(-50%, -50%);
    color: ${findIconColor};    
    font-size: 2.666rem;
    width: 2.666rem;
    height: 2.666rem;
`;
export const ClearIcon = styled.i`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(-50%, -50%);
    color: ${clearIconColor};    
    font-size: 2.666rem;
    width: 2.666rem;
    height: 2.666rem;
    cursor: pointer;
    &:hover {
        color: ${clearIconColorHover};
    }
`;
export const FindWrapper = styled.div`
    position: relative;
    margin: 0 4rem 4rem;
`;
export const FindInput = styled.input`
    padding: 0 5.5rem 0 3rem;
    width: 100%;
    height: 5.833rem;
    background-color: ${inputBg};    
    border: 0;
    box-sizing: border-box;
    font-size: 2.333rem;
    color: ${inputColor};
    display: block;
    outline: none;
    
    &:hover, &:focus {
    box-shadow: 0 0 0 1px ${activeIconColor};
    }
`;

export const CurrencyWrapper = styled.div`
    height: 75rem;
    border-right: 1px solid ${footerBorder};
`;
export const CurrencyLabel = styled.div`
    font-size: 2.3rem;
    padding: 1.5rem 4rem;
    display: flex;
    align-items: center;
    color: ${currencyLabelColor};
`;
export const CurrencyTitle = styled.div`
    display: flex;
    align-items: center;
`;
export const CurrencyRow = styled.div`
    display: flex;
    padding: 0 4rem;
    justify-content: space-between;
    font-size: 2.3rem;
    height: 7.5rem;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    position: relative;
    color: ${currencyLabelColor}
    background: ${({isActive}) => isActive && activeRow};
    &:hover {
      background: ${({isActive}) => !isActive && rowHoverBg};
      i {
        color: ${activeIconHoverColor};
      }
    }
    ${({isActive}) =>
        isActive &&
        css`
        color: ${activeIconColor};
            i {
                color: ${activeIconColor};
            }
            &:hover {
              color: ${activeIconColor};
                i {
                    color: ${activeIconColor};
                }
            }
        `}
`;
export const IconArrow = styled.i``;
export const ModalFooter = styled.div`
    border-top: 1px solid ${footerBorder};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16.3rem;
    position:relative;
    button {
        margin: 0 1rem;
    }
`;
export const Icon = styled.i`
    font-size: 3.5rem;
    color: ${currencyIconColor};
    margin-right: 5rem;
`;
