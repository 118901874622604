import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {DXChartOffsets} from 'settings';
import ChartMenuBtn from './ChartMenuBtn';
import CurrencyPair from '../ChartContainer/components/CurrencyPair/CurrencyPair';
import Dropdown from 'infrastructure/view/component/Dropdown/Dropdown';
import Preloader from 'infrastructure/view/component/Preloader';
import {setChartInterval, setChartType} from './service/actions';

import {
    setChartInterval as settingsInterval,
    setChartType as settingsType,
    sendSettingsData,
} from '../../../../services/action/settings';

import StudiesMenu from './StudiesMenu';
import OffsetMenu from './OffsetMenu';
import DrawingsMenu from './DrawingsMenu';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';
import colors from '../../../../../../guest/theme/colors';
const Icon = styled.i`
    font-size: 3rem;
`;

function mapStateToProps(state) {
    const isPendingSettings = state.settings.isPending;
    const isPendingChartHistory = state.DXChart.isPending;
    const {type} = state.settings.dxChart;
    const {selectedSymbol} = state.settings;
    const {volume, change: growth} =
        state.symbols.symbols[selectedSymbol.name] || {};
    const {chartInterval, chartType} = state.settings.dxChart;

    const isPending = isPendingChartHistory && isPendingSettings;

    return {
        settings: state.settings,
        type,
        volume,
        growth,
        chartType,
        chartInterval,
        isPending,
        volumeTranslate: parseTranslation('label-chart1-volume'),
        growthTranslate: parseTranslation('label-chart1-growth'),
        timeIntervalTranslate: parseTranslation('tooltip-chart1-interval'),
        timeInterval_1_min_Translate: parseTranslation('label-chart1-interval-1min'),
        timeInterval_5_min_Translate: parseTranslation('label-chart1-interval-5min'),
        timeInterval_15_min_Translate: parseTranslation('label-chart1-interval-15min'),
        timeInterval_30_min_Translate: parseTranslation('label-chart1-interval-30min'),
        timeInterval_1_hour_Translate: parseTranslation('label-chart1-interval-1hr'),
        tooltipPointer: parseTranslation('tooltip-chart1-pointer'),
        tooltipChartType: parseTranslation('tooltip-chart1-type'),
        lineChartType: parseTranslation('label-chart1-type-line'),
        candlestickChartType: parseTranslation('label-chart1-type-candlestick'),
        barChartType: parseTranslation('label-chart1-type-bar'),
        areaChartType: parseTranslation('label-chart1-type-area'),
    };
}

const mapDispatchToProps = {
    setChartInterval,
    setChartType,
    sendSettingsData,
    settingsInterval,
    settingsType,
};



function withControls(WrappedComponent) {
    class WithControlsHOC extends Component {
        state = {
            studies: [],
            offsets: {
                ...DXChartOffsets,
            },
            drawings: {
                type: null,
                lineColor: '#0c3dfb',
                lineWidth: 1,
                text: 'text',
                isVisible: true,
                fillBackground: true,
                remove: false,
                repeat: false,
                bulletColor: '#0c3dfb',
            },
            selectedDrawing: null,
            isActiveInterval: false,
            cancelDrawing: false,
            deleteDw: false,
            deleteI: false,
            deleteAll: false,
        };

        componentDidMount() {
            setTimeout(() => {
                this.props.setChartInterval(
                    this.props.id,
                    this.props.chartInterval
                );
                this.props.setChartType(this.props.id, this.props.chartType);
            }, 1000);
        }

        getStudies = studies => this.setState({studies, deleteI: false});

        setOffsets = offsets => this.setState({offsets});

        setDrawings = drawingsEl => {

            this.setState({
                drawings: {
                    ...this.state.drawings,
                    ...drawingsEl,
                },
                cancelDrawing: false,
                deleteDw: false,
                deleteAll: false,
            });
        };

        setSelectedDrawing = drawing => {
            this.setState({selectedDrawing: drawing});
        };

        deleteDI = DIEl => {
            if (DIEl === 'deleteAll') {
                this.setState({
                    [DIEl]: true,
                    deleteI: true,
                });
                return;
            }
            this.setState({
                [DIEl]: true,
            });
        };

        cancelCurrentDrawing = () => {
            this.setState({cancelDrawing: true});
        };

        render() {
            const {
                studies,
                offsets,
                drawings,
                cancelDrawing,
                deleteDw,
                deleteI,
                deleteAll,
                selectedDrawing,
            } = this.state;
            const {isPending, settings} = this.props;
            if (isPending) {
                return <Preloader />;
            }
            const intervals = {
                '1M': this.props.timeInterval_1_min_Translate,
                '5M': this.props.timeInterval_5_min_Translate,
                '15M': this.props.timeInterval_15_min_Translate,
                '30M': this.props.timeInterval_30_min_Translate,
                '1H': this.props.timeInterval_1_hour_Translate,
            };
            return (
                <>
                    <div className="chart-container__option">
                        <div className="chart-container__wrapper">
                            <div className="currency-pair-modal3">
                                <CurrencyPair chartId={this.props.id} />
                            </div>

                            <div className="interval-settings">
                                <Dropdown
                                    className="interval-wrapper"
                                    btn={
                                        <ChartMenuBtn>
                                            {this.props.chartInterval}
                                        </ChartMenuBtn>
                                    }
                                    tooltip={<div>{this.props.timeIntervalTranslate}</div>}
                                >
                                    <div className="drop-down">
                                        <div className="drop-down-list-wrap">
                                            {Object.keys(intervals).map(key => (
                                                <div
                                                    className={
                                                        key ===
                                                        this.props.chartInterval
                                                            ? 'active-interval'
                                                            : null
                                                    }
                                                    onClick={() => {
                                                        this.props.settingsInterval(
                                                            this.props.id,
                                                            key
                                                        );
                                                        this.props.sendSettingsData();
                                                        // TODO: refactor this reducer
                                                        setTimeout(() => {
                                                            this.props.setChartInterval(
                                                                this.props.id,
                                                                key
                                                            );
                                                        }, 0);
                                                    }}
                                                    key={key}
                                                >
                                                    {intervals[key]}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Dropdown>

                                <button
                                    type="button"
                                    className="cross-btn"
                                    onClick={this.cancelCurrentDrawing}
                                >
                                    <i className="icon-crosshair" />
                                    <div className="tooltip">{this.props.tooltipPointer}</div>
                                </button>

                                <Dropdown
                                    className="types-chart-wrapper"
                                    btn={
                                        <ChartMenuBtn icon="icon-type-chart" />
                                    }
                                    tooltip={<div>{this.props.tooltipChartType}</div>}
                                >
                                    <div className="drop-down">
                                        <div className="drop-down-list-wrap">
                                            <div
                                                className={
                                                    this.props.chartType ===
                                                    'line'
                                                        ? 'chart-type-active'
                                                        : null
                                                }
                                                onClick={() => {
                                                    this.props.setChartType(
                                                        this.props.id,
                                                        'line'
                                                    );
                                                    this.props.settingsType(
                                                        this.props.id,
                                                        'line'
                                                    );
                                                    this.props.sendSettingsData();
                                                }}
                                            >
                                                <Icon className="icon-line" />
                                                <span>{this.props.lineChartType}</span>
                                            </div>
                                            <div
                                                className={
                                                    this.props.chartType ===
                                                    'candle'
                                                        ? 'chart-type-active'
                                                        : null
                                                }
                                                onClick={() => {
                                                    this.props.setChartType(
                                                        this.props.id,
                                                        'candle'
                                                    );
                                                    this.props.settingsType(
                                                        this.props.id,
                                                        'candle'
                                                    );
                                                    this.props.sendSettingsData();
                                                }}
                                            >
                                                <Icon className="icon-candlestick" />
                                                <span>{this.props.candlestickChartType}</span>
                                            </div>
                                            <div
                                                className={
                                                    this.props.chartType ===
                                                    'bar'
                                                        ? 'chart-type-active'
                                                        : null
                                                }
                                                onClick={() => {
                                                    this.props.setChartType(
                                                        this.props.id,
                                                        'bar'
                                                    );
                                                    this.props.settingsType(
                                                        this.props.id,
                                                        'bar'
                                                    );
                                                    this.props.sendSettingsData();
                                                }}
                                            >
                                                <Icon className="icon-bars" />
                                                <span>{this.props.barChartType}</span>
                                            </div>
                                            <div
                                                className={
                                                    this.props.chartType ===
                                                    'area'
                                                        ? 'chart-type-active'
                                                        : null
                                                }
                                                onClick={() => {
                                                    this.props.setChartType(
                                                        this.props.id,
                                                        'area'
                                                    );
                                                    this.props.settingsType(
                                                        this.props.id,
                                                        'area'
                                                    );
                                                    this.props.sendSettingsData();
                                                }}
                                            >
                                                <Icon className="icon-area" />
                                                <span>{this.props.areaChartType}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Dropdown>
                                <StudiesMenu
                                    deleteI={deleteI}
                                    getStudies={this.getStudies}
                                />
                                <DrawingsMenu
                                    deleteDI={this.deleteDI}
                                    setDrawings={this.setDrawings}
                                    selectedDrawing={selectedDrawing}
                                />
                                <OffsetMenu setOffsets={this.setOffsets} />
                            </div>
                        </div>
                        <div className="volume-chart">
                            <span className="volume">
                                <div className="volume-chart-value">
                                    {this.props.volume}
                                </div>
                                <span>
                                    {`${this.props.volumeTranslate} ` +
                                        settings.selectedSymbol.numerator}
                                </span>
                            </span>
                            <span className="growth">
                                <div className="volume-chart-value">
                                    {this.props.growth
                                        ? parseFloat(this.props.growth).toFixed(
                                              2
                                          )
                                        : '0'}
                                </div>
                                <span>{this.props.growthTranslate} %</span>
                            </span>
                        </div>
                    </div>
                    <WrappedComponent
                        id={this.props.id}
                        volumesHeight={this.props.volumesHeight}
                        studies={studies}
                        offsets={offsets}
                        drawings={drawings}
                        cancelDrawing={cancelDrawing}
                        deleteDw={deleteDw}
                        deleteI={deleteI}
                        deleteAll={deleteAll}
                        setSelectedDrawing={this.setSelectedDrawing}
                    />
                </>
            );
        }
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(WithControlsHOC);
}

export default withControls;
