import React from 'react';
import {Label, InputWrapper, MaxButton, MinButton, InputLabel, SortButtons} from './styled';
import Tooltip from '../Tooltip';

export default ({
    label,
    isMinMax = false,
    onMinClick,
    onMaxClick,
    children,
    sort,
}) => {
    return (
        <InputWrapper>
            <InputLabel>
                <Label isBottomOffset={!isMinMax}>{label}</Label>
                {sort}
            </InputLabel>
            {children}
        </InputWrapper>
    );
};
