import { ActionTypes } from './actions';

const INITIAL_STATE = 'night';

function chartThemeReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.setChartTheme:
            return action.theme;

        default:
            return state;
    }
}

export default chartThemeReducer;
