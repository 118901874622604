import styled, {css, keyframes} from 'styled-components';

const tooltipBg = '#fff';
const tooltipColor = '#000000';
const tooltipBorder = '#2B9241';
const tooltipShadow = '0 0 3rem #2B92411a';

export const TooltipWrapper = styled.div`
    font-family: Lato,Roboto,Arial,sans-serif;
    display: none;
    align-items: center;
    justify-content: center;  
    transform: translateX(-50%);
    left: 50%;  
    bottom: 100%;  
    white-space: nowrap;
    padding: 0 3rem;
    border-radius: 0;
    min-height: 6rem;
    background: ${tooltipBg};
    color: ${tooltipColor};
    font-size: 2.3rem;
    position: absolute;
    z-index: 1;    
    border: 1px solid ${tooltipBorder};
    box-shadow: ${tooltipShadow};
    margin-bottom: 2rem;
    animation: cssAnimation 0s 5s forwards;    
    ${({bottomRight}) =>
        bottomRight &&
        css`
            transform: none;
            left: auto;
            right: -4rem;
        `}
    ${({topCenter}) =>
        topCenter &&
        css`
            top: 100%;
            bottom: auto;
            margin-bottom: 0;
            margin-top: 2rem;
        `}
    ${({leftCenter}) =>
        leftCenter &&
        css`
            margin-left: 2rem;
        `}
    ${({rightCenter}) =>
        rightCenter &&
        css`
            margin-right: 2rem;
        `}
    ${({topRight}) =>
        topRight &&
        css`
            margin-top: -2rem;
        `}
    &:before {
      content: "";
      position:absolute;
      width: 0;
      height: 0;
      z-index: 3;
      border: 2rem solid transparent;  
      top: 100%;
      left: 50%;
      transform: translate(-50%);
      margin-top: -1px;
      border-top-color: ${tooltipBg};
    } 
    &:after {
      content: "";
      position:absolute;
      width: 0;
      height: 0;
      z-index: 2;
      border: 2rem solid transparent;    
      top: 100%;      
      left: 50%;
      transform: translate(-50%);
      border-top-color: ${tooltipBorder};      
    } 
    ${({bottomRight}) =>
        bottomRight &&
        css`
            &:before {
                left: auto;
                right: 1.5rem;
            }
            &:after {
                left: auto;
                right: 1.5rem;
            }
        `}
    ${({leftCenter}) =>
        leftCenter &&
        css`
      &:before {
        border-top-color: transparent;
        border-right-color: ${tooltipBg};
        left: auto;
        right: 100%;
        margin-top: 0;
        margin-right: -1px;
        top: 50%;
        transform: translate(0, -50%);
      }
      &:after {
        border-top-color: transparent;
        border-right-color: ${tooltipBorder}
        left: auto;
        right: 100%;
        top: 50%;
        transform: translate(0, -50%);
      }
    `}
    ${({rightCenter}) =>
        rightCenter &&
        css`
      &:before {
        border-top-color: transparent;
        border-left-color: ${tooltipBg};
        left: 100%;        
        margin-top: 0;
        margin-left: -1px;
        top: 50%;
        transform: translate(0, -50%);
      }
      &:after {
        border-top-color: transparent;
        border-left-color: ${tooltipBorder}        
        left: 100%;
        top: 50%;
        transform: translate(0, -50%);
      }
    `}
    ${({topCenter}) =>
        topCenter &&
        css`
            &:before {
                top: auto;
                bottom: 100%;
                border-color: transparent;
                border-bottom-color: ${tooltipBg};
                margin-top: 0;
                margin-bottom: -1px;
            }
            &:after {
                top: auto;
                bottom: 100%;
                border-color: transparent;
                border-bottom-color: ${tooltipBorder};
            }
        `}
`;
const animation = keyframes`
  0% {
    opacity: 0;    
  }  
  100% {
    opacity: 1;    
  }
`;
export const Wrapper = styled.div`
    &:hover {
        ${TooltipWrapper} {
            display: flex;
            opacity: 0;
            animation: ${animation} 0s 2s forwards;
        }
    }
`;
