import React from 'react';

class Dropdown extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isTooltipShown: false,
        };

        this.node = null;
        this.onShowTooltip = this.onShowTooltip.bind(this);
        this.onHideTooltip = this.onHideTooltip.bind(this);
    }


    onShowTooltip() {
        this.setState({isTooltipShown: true});
    }

    onHideTooltip() {
        this.setState({isTooltipShown: false});
    }

    render() {
        const {
            text,
            children,
        } = this.props;

        const {
            isTooltipShown,
        } = this.state;

        return (
            <div
                className="tooltip__wrapper"
                onMouseEnter={this.onShowTooltip}
                onMouseLeave={this.onHideTooltip}
            >
                {
                    isTooltipShown &&
                    <div className="tooltip__body">
                        <span className="tooltip__text">
                            { text }
                        </span>
                    </div>
                }
                { children }
            </div>
        )
    }
}

export default Dropdown;
