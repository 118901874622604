export const ActionTypes = {
    chartHistoryRequest: 'chart.history.request',
    chartHistorySuccess: 'chart.history.success',
    chartHistoryFailure: 'chart.history.failure',
    chartHistoryIsPending: 'chart.history.isPending',
    setNoDataErrorChart: "SET_NO_DATA_ERROR_CHART",
};

export function chartHistoryRequest(payload) {
    return {
        type: ActionTypes.chartHistoryRequest,
        payload,
    };
}

export function chartHistorySuccess(payload) {
    return {
        type: ActionTypes.chartHistorySuccess,
        payload,
    };
}

export function chartHistoryFailure(payload) {
    return {
        type: ActionTypes.chartHistoryFailure,
        payload,
    };
}

export function chartHistoryIsPending() {
    return {
        type: ActionTypes.chartHistoryIsPending,
    };
}

export function setNoDataErrorChart(payload){
    return {
        type: ActionTypes.setNoDataErrorChart,
        payload,
    };
}
