// @flow
import type Axios from 'axios';

import type { AccessToken } from '../../domain/entitiy/AccessToken';
import type { BillingAdapterInterface } from '../../application/adapter/Billing';
import {getDomain} from '../../utils';
import localConfig from '../../localConfig';

class BillingAdapter implements BillingAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getDepositForm(token: AccessToken, formData: {}, locale: string): Promise<any> {
        return await this.apiClient({
            method: 'PUT',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/deposit`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                'authorization': `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`
            },
            data: formData
        });
    }

    async sendDepositForm(token: AccessToken, formData: {}, locale: string): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/deposit`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                'authorization': `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`
            },
            data: formData
        });
    }

    async getWithdrawForm(token: AccessToken, formData: {}, locale: string): Promise<any> {
        return await this.apiClient({
            method: 'PUT',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/withdraw`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                'authorization': `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`
            },
            data: formData
        });
    }

    async sendWithdrawForm(token: AccessToken, formData: {}, locale: string): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/withdraw`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                'authorization': `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`
            },
            data: formData
        });
    }

    async getTransactionHistory(token: AccessToken): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/billing/history`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                'authorization': `Bearer ${token}`
            }
        });
    }
}

export default BillingAdapter;
