import {ActionTypes} from '../action/socket';

const initialState = {
    isConnected: false,
    error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.webSocketConnected:
            return {
                isConnected: true,
                error: null,
            };
        case ActionTypes.webSocketDisconnected:
            return {
                ...state,
                isConnected: false,
            };
        case ActionTypes.webSocketError:
            return {
                isConnected: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
