// @flow
import moment from 'moment';

export type OrderType = 'Sell' | 'Buy' | '';

type RawOrder = {
    id?: string,
    symbol?: string,
    count?: number,
    type: OrderType,
    isMarket: boolean,
    openingTime?: moment,
    closTime?: moment,
    price: number,
    amount: number,
    commission: number | string,
    closePrice: number,
};

export class Order {
    id: string;
    symbol: string;
    count: number;
    type: OrderType;
    isMarket: boolean;
    openingTime: moment;
    closTime: moment;
    price: number;
    amount: number;
    total: number;
    commission: number | string;
    closePrice: number;

    constructor(
        rawOrder: RawOrder = {
            id: '',
            symbol: '',
            count: 0,
            type: '',
            isMarket: false,
            openingTime: moment(),
            closTime: moment(),
            price: 0,
            amount: 0,
            commission: 0,
            closePrice: 0,
        },
    ) {
        this.id = rawOrder.id;
        this.symbol = rawOrder.symbol;
        this.count = rawOrder.count;
        this.type = rawOrder.type;
        this.isMarket = rawOrder.isMarket;
        this.openingTime = rawOrder.openingTime;
        this.closTime = rawOrder.closTime;
        this.price = rawOrder.price;
        this.amount = rawOrder.amount;
        this.total = this.price * this.amount;
        this.commission = rawOrder.commission;
        this.closePrice = rawOrder.closePrice;
    }
}
