import store from '../../view/services/store';

export default function(translate) {
    if (translate === '') {
        return ' ';
    }

    const translation = Boolean(localStorage.getItem('translation'))
        ? JSON.parse(localStorage.getItem('translation'))
        : store.getState().translation.translation;
    try {
        const ob = JSON.parse(translate);
        return translation[Object.keys(ob)[0]].supplant(ob[Object.keys(ob)[0]]);
    } catch (e) {
        if (translation) {
            if (translation[translate] === undefined) {
                return translate;
            }
            return translation[translate];
        }
        return translate;
    }
}
