import {userRegionKey} from '../settings';

export function getDomain(domain) {
    if (typeof domain === 'function') {
        return domain();
    } else {
        return domain;
    }
}

export function getServerAPI(isWs = false) {
    return () => {
        const json = localStorage.getItem(userRegionKey);

        function getWs(url) {
            const protocol = url.split('/');

            const isHttps =  protocol[0].includes('s');
            const domain = protocol[2];

            let result;

            if (isHttps) {
                result = `wss://${domain}`;
            } else {
                result =  `ws://${domain}`;
            }

            return result;
        }

        function domainType(domains) {
            if (isWs) {
                return domains.ws;
            } else {
                return domains.api;
            }
        }

        const result = {
            api: '',
            ws: '',
        };

        if (json) {
            const data = JSON.parse(json);

            if (data.server) {
                result.api = data.server;
                result.ws = getWs(data.server);

                return domainType(result);
            }
            return domainType(result);
        }
        return domainType(result);
    };
}
