import _ from 'lodash';
import axios from '../../../adapter/helper/axios';

import SettingsAdapter from '../../../adapter/Settings';

import GetSettings from '../../../../application/use-case/settings/GetSettings';
import SendSettings from '../../../../application/use-case/settings/SendSettings';
import {chartHistoryIsPending, chartHistoryRequest} from '../action/DXChart';

import {
    ActionType,
    setSettingsData,
    loadingFinished,
    getSettingsDataFailure,
} from '../action/settings';
import {ActionTypes as symbolsActionType} from 'infrastructure/view/scene/Dashboard/components/Symbols/service/actions';
import {
    ActionType as translationActionType,
    setLocal,
} from 'infrastructure/view/services/action/translation';
import {setSelectedSymbol} from '../action/user';

import {
    dxChartTypes,
    dxChartIntervals,
    locale,
    availableDashboardLayout,
} from 'settings';
import {fetchMarketTradesHistory} from '../../scene/Dashboard/components/MarketTrades/service/actions';

const settingsAdapter = new SettingsAdapter(axios);

const getSettings = new GetSettings(settingsAdapter);
const sendSettings = new SendSettings(settingsAdapter);

let response = null;
let firstSymbolsResponse = false;

export default function(store) {
    return function(next) {
        return async function(action) {
            next(action);

            const {accessToken} = store.getState().user;
            if (action.type === 'LOGOUT') {
                firstSymbolsResponse = false;
                response = null;
            }
            if (
                action.type === ActionType.getSettingsData ||
                action.type === symbolsActionType.symbolsSuccess ||
                action.type === translationActionType.setLanguage
            ) {
                try {
                    if (action.type === ActionType.getSettingsData) {
                        response = await getSettings.invoke(accessToken);
                    }

                    if (
                        response &&
                        response.data
                    ) {
                        if (!firstSymbolsResponse) {
                            let settings = JSON.parse(response.data);
                            const {symbols} = store.getState().symbols;

                            const symbolsEmpty = _.isEmpty(symbols);
                            firstSymbolsResponse = !symbolsEmpty;
                            const symbolsList = Object.keys(symbols);

                            // if (
                            //     settings &&
                            //     settings.selectedSymbol &&
                            //     !symbolsEmpty
                            // ) {
                            //     const {selectedSymbol} = settings;
                            //
                            //     const isCorrectSymbol = symbolsList.some(
                            //         symbol =>
                            //             symbol === settings.selectedSymbol.name
                            //     );
                            //
                            //     if (!isCorrectSymbol) {
                            //         delete settings.selectedSymbol;
                            //     }
                            //
                            //     if (
                            //         !selectedSymbol.hasOwnProperty('numerator') ||
                            //         !selectedSymbol.hasOwnProperty('denominator')
                            //     ) {
                            //         delete settings.selectedSymbol;
                            //     }
                            //
                            //     if (settings.selectedSymbol) {
                            //         store.dispatch(
                            //             setSelectedSymbol(settings.selectedSymbol)
                            //         )
                            //     }
                            // } else {
                            //     delete settings.selectedSymbol;
                            // }

                            if (settings && settings.dxChart) {
                                const isCorrectType = dxChartTypes.some(
                                    type => type === settings.dxChart.chartType
                                );

                                const isCorrectInterval = dxChartIntervals.some(
                                    interval =>
                                        interval === settings.dxChart.chartInterval
                                );

                                if (
                                    settings.dxChart &&
                                    settings.dxChart.chartType &&
                                    !isCorrectType
                                ) {
                                    delete settings.dxChart;
                                }

                                if (
                                    settings.dxChart &&
                                    settings.dxChart.chartInterval &&
                                    !isCorrectInterval
                                ) {
                                    delete settings.dxChart;
                                }

                                if (settings.dxChart) {
                                }
                            } else {
                                delete settings.dxChart;
                            }

                            const {language} = store.getState().translation;

                            if (
                                action.type === translationActionType.setLanguage ||
                                language.length
                            ) {
                                const isCorrectLanguage = language.some(
                                    type => type.code === settings.locale
                                );

                                if (!isCorrectLanguage) {
                                    store.dispatch(setLocal(locale));
                                    delete settings.locale;
                                } else {
                                    store.dispatch(setLocal(settings.locale))
                                }
                            }

                            if (settings && settings.dashboardLayout) {
                                const isCorrectLayout = availableDashboardLayout.some(
                                    layout =>
                                        Number(layout) === settings.dashboardLayout
                                );

                                if (!isCorrectLayout) {
                                    delete settings.dashboardLayout;
                                }
                            }
                            const pair = store.getState().user.sitePair;
                            const siteSelectedPair = pair && {numerator: pair.split('-')[0], denominator: pair.split('-')[1], name: pair.replace('-', '/')};

                            await store.dispatch(setSettingsData(pair ? {...settings, selectedSymbol: siteSelectedPair} : settings));
                            await store.dispatch(loadingFinished());
                        }

                        if (action.type === ActionType.getSettingsData) {
                            setTimeout(() => {
                                const {
                                    selectedSymbol: {name},
                                    dxChart: {chartInterval},
                                } = store.getState().settings;

                                const route = window.location.href;

                                if (route && route.includes('dashboard')) {
                                    // store.dispatch(fetchMarketTradesHistory(1));
                                }
                            }, 1000);
                        }
                    } else {
                        throw Error('response.data is not a object');
                    }
                } catch (error) {
                    store.dispatch(getSettingsDataFailure(error));
                }
            }

            if (action.type === ActionType.setChartInterval) {
                store.dispatch(chartHistoryIsPending());

                const {
                    selectedSymbol: {name},
                } = store.getState().settings;

            }

            if (action.type === ActionType.sendSettingsData) {
                const settings = store.getState().settings;
                await sendSettings.invoke(accessToken, settings);
            }
        };
    };
}
