export const ActionTypes = {
    openExchange:
        'openExchange',
};

export function openExchange(value) {
    return {
        type: ActionTypes.openExchange,
        value,
    };
}
