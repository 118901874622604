import {EventType} from './event';

const initialState = {
    isConfirmed: '',
};

function listingReducer(state = initialState, action) {
    switch (action.type) {
        case EventType.setConfirmationResult:
            return {
                ...state,
                isConfirmed: action.isConfirmed,
            };

        default:
            return state;
    }
}

export default listingReducer;
