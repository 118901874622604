import React, { Component } from 'react';


class Tuition extends Component {

    render() {
        return (
            <div className="tuition">
                <div className="tuition-item">
                    Welcome tour
                </div>

                <div className="tuition-item">
                    FULL INTRODUCTION
                </div>

                <div className="tuition-item">
                    Welcome tour
                </div>

                <div className="tuition-item">
                    Welcome tour
                </div>
                <div className="tuition-item">
                    Welcome tour
                </div>

                <div className="tuition-item">
                    FULL INTRODUCTION
                </div>

                <div className="tuition-item">
                    Welcome tour
                </div>

                <div className="tuition-item">
                    Welcome tour
                </div>
            </div>
        );
    }
}

export default Tuition;
