import React from 'react';

const AvatarComponent = ({avatar, className}) => (
    <img
        src={avatar}
        alt={'avatarImage.js'}
        className={className}
    />
);

export default AvatarComponent;
