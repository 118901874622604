// @flow
export class Asset {
    id: string;
    change: number;
    price: number;
    volume: number;
    limit: number;

    constructor(rawAsset: {
        id: string,
        change: string,
        price: string,
        volume: string,
        limit: number,
    }) {
        this.id = rawAsset.id;
        this.change = +rawAsset.change;
        this.price = +rawAsset.price;
        this.volume = +rawAsset.volume;
        this.limit = +rawAsset.limit;
    }
}
