import styled from 'styled-components';
import colors from '../../theme/colors';

export const Input = styled.input`
    background-color: ${colors.color3};
    border: 1px solid transparent;
    box-sizing: border-box;
    width: 100%;
    height: 4.5rem;    
    padding: 0.6rem 1.5rem;
    font-size: 2.3rem;
    color: ${colors.color5};
    vertical-align: top;
    &:focus {
        outline: none;
        border-color: ${colors.color7};
    }

    &::-webkit-input-placeholder {
        color: ${colors.color65};
    }
    &::-moz-placeholder {
        color: ${colors.color65};
    }
    &:-ms-input-placeholder {
        color: ${colors.color65};
    }
    &:-moz-placeholder {
        color: ${colors.color65};
    }

    &[type='number'] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
    }
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }
`;
