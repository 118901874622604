import React, {useEffect, useState} from 'react';
import t from '../../infrastructure/adapter/helper/parseTranslation';
import Panel from '../components/Panel';
import CurrencyPairContainer from '../CurrencyPairContainer';
import {TopBlock, Pair, Titles} from './styled';
import {getClosedDeals} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import MarketTradesRow from './components/Row';
import moment from 'moment';
import ScrollBar from '../components/Scroll';
import {dealingConfirm} from '../webSockets/constants';
import TooltipAbsolute from '../components/Tooltip/TooltipAbsolute';
import TradesTooltip from './components/TooltipContent';
import FormattedPrice from '../../infrastructure/view/component/FormattedPrice/FormattedPrice';

export default function GuestMarketTradesContainer() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const pair = useSelector(state => state.CURRENCY_PAIR.asset);
    const data = useSelector(state => state.MARKET_TRADES.history);
    const confirmDeals = useSelector(
        state => state.GUEST_DEALING_WS[dealingConfirm]
    );
    const [storedData, setData] = useState([]);
    useEffect(() => {
        setData([]);
        dispatch(getClosedDeals(pair, page));
    }, [pair]);
    const loadHandler = () => {
        dispatch(getClosedDeals(pair, page + 1));
        setPage(page + 1);
    };
    useEffect(() => {
        const newData = [...storedData, ...data];
        let prevStatus;
        const reverseArr = newData.reverse();
        const dataWithTypes = reverseArr.map((item, idx) => {
            if (idx > 0) {
                if (+item.price === +reverseArr[idx - 1].price) {
                    return {...item, type: prevStatus};
                }
                prevStatus = +item.price > +reverseArr[idx - 1].price;
                return {
                    ...item,
                    type: +item.price > +reverseArr[idx - 1].price,
                };
            }
            return {...item, type: false};
        });
        setData(dataWithTypes.reverse());
    }, [data]);
    const [collapse, setCollapse] = useState(false);
    const handleCollapse = value => setCollapse(value);
    const handleScroll = ({target}) => {
        target.scrollTop + target.clientHeight + 100 > target.scrollHeight &&
            loadHandler();
    };
    useEffect(() => {
        if (confirmDeals[0]) {
            const deal = {
                ...confirmDeals[0],
                time: confirmDeals[0].openTime,
                type: confirmDeals[0].type === 1,
            };
            setData([deal, ...storedData]);
        }
    }, [confirmDeals]);

    return (
        <Panel
            title={t('label-market-trades')}
            icon="icon-market-trades"
            onCollapse={handleCollapse}
        >
            <TopBlock collapse={collapse}>
                <Pair>
                    <CurrencyPairContainer />
                </Pair>
                <Titles collapse={collapse}>
                    {collapse && (
                        <>
                            <div>{t('label-open-time')}</div>
                            <div>{t('label-open-date')}</div>
                        </>
                    )}
                    <div>{t('label-price')}</div>
                    <div>{t('label-amount')}</div>
                </Titles>
            </TopBlock>
            <ScrollBar onScroll={handleScroll}>
                {storedData.map(({price, time, amount, type}, idx) => {
                    return (
                        <TooltipAbsolute
                            key={`${time}-${idx}`}
                            topRight
                            content={
                                <TradesTooltip
                                    price={<FormattedPrice price={+price} />}
                                    time={moment(time * 1000).format('hh:mm:ss')}
                                    amount={<FormattedPrice price={+amount} />}
                                    date={moment(time * 1000).format('DD.MM.YYYY')}
                                    isBuy={type}
                                />
                            }
                        >
                            <MarketTradesRow
                                isBuy={type}
                                even={idx % 2}
                                collapse={collapse}
                                time={moment(time * 1000).format('HH:mm:ss')}
                                date={moment(time * 1000).format('DD.MM.YYYY')}
                                price={<FormattedPrice price={+price} />}
                                amount={<FormattedPrice price={+amount} />}
                            />
                        </TooltipAbsolute>
                    );
                })}
            </ScrollBar>
        </Panel>
    );
}
