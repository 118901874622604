import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import {Link} from 'react-router-dom';
import moment from 'moment';

import NavBar from '../../component/NavBar';
import {logout} from '../../services/action/user';

import SymbolsExpanded from './components/Symbols/SymbolsExpanded';
import ChartContainerExpanded from './components/ChartContainer/ChartContainerExpanded';
import MarketTradesExpanded from './components/MarketTrades/MarketTradesExpanded';
import TradingHistoryExpanded from './components/TradingHistory/TradingHistoryExpanded';
import DomExpanded from './components/DOM/DomExpanded';
import NewsExpanded from './components/News/NewsExpanded';

import Layout00 from './layouts/layout-00';
import Layout01 from './layouts/layout-01';
import Layout02 from './layouts/layout-02';
import Layout03 from './layouts/layout-03';
import Layout04 from './layouts/layout-04';

import {
    leaveDashboard,
    startRSSFeedReader,
    stopRSSFeedReader,
    closeDepositModal,
    openLimitOrderModal,
    closeLimitOrderModal,
} from './service/actions';
import {
    changeInfoModalState,
    getTwoFAStatus,
} from '../../services/action/twoFA';

import {
    sendSettingsData,
    getSettingsData,
} from '../../services/action/settings';
import {getDealingToken, setSelectedSymbol, setSitePair} from '../../services/action/user';

import parseTranslation from '../../../adapter/helper/parseTranslation';

import debounce from 'lodash.debounce';
import screenfull from 'screenfull';
import store from '../../services/store';
import config from '../../../../config';

function mapStateToProps(state) {
    const {
        activeView,
        activeDashboardIndex,
        showDepositModal,
        errorOrder,
        openSessionModal,
        closeSessionModal,
        openErrorLimitOrderModal,
    } = state.dashboard;
    const {isActive, infoModalState, isLoaded} = state.twoFA;
    const {dashboardLayout, isLoading, selectedSymbol} = state.settings;
    const {isOpenMenu} = state.menu;
    const userEmail = state.user.info.email;

    return {
        userEmail,
        attention: parseTranslation('attention'),
        attentionDescriptionStart: parseTranslation(
            'attentionDescriptionStart'
        ),
        attentionDescriptionLink: parseTranslation('attentionDescriptionLink'),
        errorIncorrect: parseTranslation('label-error-incorrect'),
        errorIncorrectText: parseTranslation('label-error-incorrect-text'),
        errorInsufficient: parseTranslation('label-error-insufficient'),
        errorInsufficientText: parseTranslation(
            'label-error-insufficient-text'
        ),
        errorOrders: parseTranslation('label-error-order'),
        errorOrderText: parseTranslation('label-error-order-text'),
        errorTimeout: parseTranslation('label-error-timeout'),
        errorTimeoutText: parseTranslation('label-error-timeout-text'),
        labelButtonDeposit: parseTranslation('label-deposit'),
        modalCommandLimitTitle: parseTranslation('modal_command_limit_title'),
        modalCommandLimitText: parseTranslation('modal_command_limit_text'),
        orderLimitErrorTitle: parseTranslation('order_limit_error_title'),
        orderLimitErrorText: parseTranslation('order_limit_error_text'),
        openOrderMaxLimitModalTitle: parseTranslation(' open_order_max_limit_modal_title'),
        openOrderMaxLimitModalText: parseTranslation('open_order_max_limit_modal_text'),
        titleErrorLimitOrderModal: parseTranslation('label-title-error-limit-order-modal'),
        bodyErrorLimitOrderModal: parseTranslation('label-body-error-limit-order-modal'),
        labelErrorForbidden: parseTranslation('label-error-forbidden'),
        labelErrorForbiddenText: parseTranslation('label-error-forbidden-text'),
        activeView,
        activeDashboardIndex,
        showDepositModal,
        isActive,
        isLoaded,
        infoModalState,
        errorOrder,
        dashboardLayout,
        isOpenMenu,
        isLoading,
        openSessionModal,
        closeSessionModal,
        openErrorLimitOrderModal,
    };
}

const mapDispatchToProps = {
    leaveDashboard,
    startRSSFeedReader,
    stopRSSFeedReader,
    setSitePair,
    closeDepositModal,
    logout,
    changeInfoModalState,
    getTwoFAStatus,
    getSettingsData,
    sendSettingsData,
    setSelectedSymbol,
    openLimitOrderModal,
    closeLimitOrderModal,
    getDealingToken,
};

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.closeInfoModal = this.closeInfoModal.bind(this);
        this.errorOrders = this.errorOrders.bind(this);
    }

    debounceFn = debounce(() => window.location.reload(), 1000);

    componentDidMount() {
        const {getDealingToken, startRSSFeedReader, getTwoFAStatus, sitePair, setSitePair} = this.props;
        const pair = sitePair && sitePair.replace('-', '/');
        setSitePair(sitePair);
        getDealingToken(pair);
        startRSSFeedReader();
        getTwoFAStatus();

        if (!this.props.isActive) {
            if (localStorage.getItem(this.props.userEmail) === null) {
                localStorage.setItem(
                    this.props.userEmail,
                    moment(new Date(), 'YYYY-MM-DD')
                        .subtract(1, 'minutes')
                        .unix()
                );
            }
        }

        // How i am understand code below need for landing page
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
            const hostName = window.location.hostname;
            document.cookie = `token=${
                accessToken
            };domain=${hostName.substring(
                hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
            )}`;
        }
    }

    componentWillUnmount() {
        const {stopRSSFeedReader, leaveDashboard} = this.props;

        stopRSSFeedReader();
        leaveDashboard();
        window.removeEventListener('resize', this.debounceFn, false);
    }
    errorOrders()
    {
        const {
            errorOrder,
            errorIncorrect,
            closeDepositModal,
            errorIncorrectText,
            errorTimeout,
            errorTimeoutText,
            errorInsufficient,
            errorInsufficientText,
            labelButtonDeposit,
            errorOrders,
            errorOrderText,
        } = this.props;

        const {error} = errorOrder;
        if (this.props.errorOrder.error === 'INCORRECT_COMMAND') {
            if ('Incorrect command') {
                return (
                    <>
                        <div className="modal-header">
                            <h2>{errorIncorrect}</h2>
                            <button onClick={closeDepositModal}>
                                <i className="icon-close"/>
                            </button>
                        </div>
                        <div className="text-modal-deposit">
                            <span>{errorIncorrectText}</span>
                        </div>
                    </>
                );
            }
        }
        else if (error === 'LIMIT_EXCEEDED') {
            return (
                <>
                    <div className="modal-header">
                        <h2>{this.props.titleErrorLimitOrderModal}</h2>
                        <button onClick={this.props.closeDepositModal}>
                            <i className="icon-close" />
                        </button>
                    </div>
                    <div className="text-modal-deposit">
                        <span>{this.props.bodyErrorLimitOrderModal}</span>
                    </div>
                </>
            );
        } else if (this.props.errorOrder.error === 'FORBIDDEN_COMMAND') {
            return (
                <>
                    <div className="modal-header">
                        <h2>{this.props.labelErrorForbidden}</h2>
                        <button onClick={this.props.closeDepositModal}>
                            <i className="icon-close"/>
                        </button>
                    </div>
                    <div className="text-modal-deposit">
                        <span>{this.props.labelErrorForbiddenText}</span>
                    </div>
                </>
            );
        } else if (this.props.errorOrder.error === 'COMMAND_LIMIT_TIMEOUT') {
            if ('Too many commands') {
                return (
                    <>
                        <div className="modal-header">
                            <h2>{errorTimeout}</h2>
                            <button onClick={closeDepositModal}>
                                <i className="icon-close"/>
                            </button>
                        </div>
                        <div className="text-modal-deposit">
                            <span>{errorTimeoutText}</span>
                        </div>
                    </>
                );
            }
        } else if (error === 'NOT_ENOUGH_BALANCE') {
            if ('Insufficient funds') {
                return (
                    <>
                        <div className="modal-header">
                            <h2>{errorInsufficient}</h2>
                            <button onClick={closeDepositModal}>
                                <i className="icon-close"/>
                            </button>
                        </div>
                        <div className="text-modal-deposit">
                            <span>{errorInsufficientText}</span>
                        </div>
                        <button className="main-button deposit_button">
                            <Link to="/billing" className="deposit" onClick={closeDepositModal}>
                                {labelButtonDeposit}
                            </Link>
                        </button>
                    </>
                );
            }
        } else if (error === 'ORDER_CANCEL_ERROR') {
            if ('Order cancellation error') {
                return (
                    <>
                        <div className="modal-header">
                            <h2>{errorOrders}</h2>
                            <button onClick={closeDepositModal}>
                                <i className="icon-close"/>
                            </button>
                        </div>
                        <div className="text-modal-deposit">
                            <span>{errorOrderText}</span>
                        </div>
                    </>
                );
            }
        }
    }

    onCloseDepositModal = () => {
        const {closeDepositModal} = this.props;

        closeDepositModal();
    };



    render() {
        const {
            openErrorLimitOrderModal,
            activeView,
            dashboardLayout,
            infoModalState,
            isActive,
            attention,
            attentionDescriptionStart,
            attentionDescriptionLink,
            closeLimitOrderModal,
            showDepositModal,
            closeSessionModal,
            titleErrorLimitOrderModal,
            bodyErrorLimitOrderModal
        } = this.props;

        return (
            <Fragment>
                <div className="container dashboard">
                    <NavBar />

                    <div className="wrapper">
                        <div
                            className={`content ${
                                activeView ? 'active-view' : ''
                            }`}
                            id="change-theme-dark-content"
                        >
                            {activeView
                                ? this.renderActiveView(activeView)
                                : this.renderDashboard(
                                      dashboardLayout
                                  )}
                        </div>
                    </div>

                    {/*<Modal*/}
                    {/*    onRequestClose={this.closeInfoModal}*/}
                    {/*    isOpen={*/}
                    {/*        this.props.isLoaded && !isActive && infoModalState &&*/}
                    {/*        moment(new Date(), 'YYYY-MM-DD').isAfter(*/}
                    {/*            moment.unix(*/}
                    {/*                localStorage.getItem(*/}
                    {/*                    this.props.userEmail*/}
                    {/*                ),*/}
                    {/*                'MM/DD/YY'*/}
                    {/*            )*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*    className="modal modal-2FA"*/}
                    {/*    style={{*/}
                    {/*        overlay: {*/}
                    {/*            backgroundColor: 'rgba(20, 22, 27, 0.65)',*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <div className="header-2FA">*/}
                    {/*        <span className="title">*/}
                    {/*            {attention}*/}
                    {/*        </span>*/}
                    {/*        <button onClick={this.closeInfoModal}>*/}
                    {/*            <i className="icon-close" />*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*    <div className="content-2FA">*/}
                    {/*        <p className="description">*/}
                    {/*            {attentionDescriptionStart}*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*    <button className="main-button content-2FA_button" onClick={this.closeInfoModal}>*/}
                    {/*        <Link to="/settings/security">*/}
                    {/*            {attentionDescriptionLink}*/}
                    {/*        </Link>*/}
                    {/*    </button>*/}
                    {/*</Modal>*/}

                    <Modal
                        isOpen={openErrorLimitOrderModal}
                        className="modal-deposit modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(20, 22, 27, 0.65)',
                            },
                        }}
                    >
                        {this.errorOrders()}
                    </Modal>

                    <Modal
                        isOpen={showDepositModal}
                        className="modal-deposit modal"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(20, 22, 27, 0.65)',
                            },
                        }}
                    >
                        {this.errorOrders()}
                    </Modal>
                </div>
            </Fragment>
        );
    }

    renderActiveView = activeView => {
        switch (activeView) {
            case 'symbols':
                return <SymbolsExpanded />;
            case 'chart-container-expanded':
                return <ChartContainerExpanded />;
            case 'trading-history-expanded':
                return <TradingHistoryExpanded />;
            case 'dom-expanded':
                return <DomExpanded />;
            case 'market-trades-expanded':
                return <MarketTradesExpanded />;
            case 'news':
                return <NewsExpanded />;
            default:
                return null;
        }
    };

    renderDashboard = activeDashboardIndex => {
        switch (activeDashboardIndex) {
            case 0:
                return <Layout00 />;
            case 1:
                return <Layout01 />;
            case 2:
                return <Layout02 />;
            case 3:
                return <Layout03 positionTooltip="left" />;
            case 4:
                return <Layout04 />;
            default:
                return null;
        }
    };

    closeInfoModal() {
        const { changeInfoModalState } = this.props;
        changeInfoModalState(false);
        const minutes = 1440;
        localStorage.setItem(
            this.props.userEmail,
            moment(new Date(), 'YYYY-MM-DD')
                .add(minutes, 'minutes')
                .unix()
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
