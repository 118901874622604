import React from 'react';
import {Wrapper, Icon} from './styled';

export default function CurrencyPair({pair, onSelect, isInChart}) {
    const firstCurrency = pair.split('/')[0] && pair.split('/')[0].toLowerCase();
    const secondCurrency = pair.split('/')[1] && pair.split('/')[1].toLowerCase();
    const handleSelect = () => onSelect();
    return (
        <Wrapper onClick={handleSelect} isInChart={isInChart}>
            <Icon className={`icon-${firstCurrency}`} />
            {pair}
            <Icon className={`icon-${secondCurrency}`} />
        </Wrapper>
    );
}
