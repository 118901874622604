import styled from 'styled-components';
import {wrapperBg} from '../../GuestDashboard/styled';

export const PageContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 777;
    background: ${wrapperBg}    
`;

export const FillImage = styled.img`
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    pointer-events: none;

    @media (max-height: 1000px) {
        bottom: -16.66rem;
    }    
    @media (max-height: 875px) {
        display: none;
    } 
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 16.66rem 0;
    box-sizing: border-box;
    position: relative;
`;

export const ContentTop = styled(Content)`
    height: auto;
    padding: 0;
`;

export const Logo = styled.img`
    width: 62.5rem;
    display: block;
    margin-bottom: 3.5rem;
`;

export const Title = styled.h1`
    font-size: 8.333rem;
    line-height: 8.333rem;
    font-weight: 900;
    color: #2E1A66;
    margin-bottom: 4rem;
    text-align: center;
`;

export const ErrorCode = styled.div`
    font-size: 20rem;
    line-height: 20rem;
    font-weight: 900;
    color: #8175A3;
`;

export const Text = styled.p`
    text-align: center;
    font-size: 4rem;
    line-height: 4rem;
    color: #020202;
`;
