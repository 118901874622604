import React from 'react';
import styled from 'styled-components';

import {TVChartContainer} from '../../TVChartContainer';

function TVChartLayout({symbol, locale, isPending, chartInterval, chartDXHistoryRequest,tradingViewData, onSave, ...otherProps}) {
    return (
        <Layout className="TV-chart-layout">
            <TVChartContainer
                nextSymbol={symbol}
                locale={locale}
                chartInterval={chartInterval}
                onSave={onSave}
                tradingViewData={tradingViewData}
                isPending={isPending}
                chartDXHistoryRequest={chartDXHistoryRequest}
                {...otherProps}
            />
        </Layout>
    );
}

const Layout = styled.div`
    &.TV-chart-layout {
        height: 100%;
    }
`;

export default TVChartLayout;
