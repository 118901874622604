import { ActionTypes } from './actions';

const INITIAL_STATE = {
    isConfirmed: null
};

function registrationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.setConfirmationResult:
            return {
                ...state,
                isConfirmed: action.isConfirmed
            };

        default:
            return state;
    }
}

export default registrationReducer;
