import React from 'react';
import {SymbolRowWrap, Symbol, Price, Volume, Growth, SymbolTitle} from '../styled';
import FormattedPrice from '../../../infrastructure/view/component/FormattedPrice/FormattedPrice';

export default function SymbolsRow({title, price, volume, growth, isOdd, isChanged, increase, isActive, onClick, isModal}) {
    const firstIcon = title.split('/')[0].toLowerCase();
    const secondIcon = title.split('/')[1].toLowerCase();
    const handleClick = () => onClick();
    return(
        <SymbolRowWrap isModal={isModal} isOdd={isOdd} isChanged={isChanged} increase={increase} isActive={isActive} onClick={handleClick}>
            <Symbol isModal={isModal} isActive={isActive}>
                <i className={`icon-${firstIcon}`} />
                <SymbolTitle>{title}</SymbolTitle>
                <i className={`icon-${secondIcon}`} />
            </Symbol>
            <Price><FormattedPrice price={+price}/></Price>
            <Volume><FormattedPrice price={+volume} /></Volume>
            <Growth notTitle isPositive={growth > 0} isNegative={growth < 0}>{growth}%</Growth>
        </SymbolRowWrap>)
}
