export const ActionTypes = {
    open: 'dashboard-scene.dom-component.notification-modal.open',
    close: 'dashboard-scene.dom-component.notification-modal.close'
};

export function open() {
    return {
        type: ActionTypes.open
    };
}

export function close() {
    return {
        type: ActionTypes.close
    };
}