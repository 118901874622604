export const ActionType = {
    toggleMenu: 'menu.toggle',
    closeMenu: 'menu.close',
};

export function toggleMenu() {
    return {
        type: ActionType.toggleMenu,
    };
}
export function closeMenu() {
    return {
        type: ActionType.closeMenu,
    };
}
