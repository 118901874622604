import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import logo from '../Dashboard/components/News/logo.svg';
import FormField from './component/FormField';
import {getForm, submitForm} from './service/actions';
import {setLocal} from '../../services/action/translation';
import {updateForm} from '../../services/action/forms';
import config from '../../../../localConfig';
import parseTranslation from '../../../adapter/helper/parseTranslation';
import Error from '../../component/Error';
import Language from "../../component/Language";
import Preloader from '../../component/Preloader';

function mapStateToProps(state) {
    const form = state.forms['RegistrationForm'] || {};
    const formError = state.forms.error;
    const {local, language, errorLanguage, isPendingTranslation} = state.translation;

    return {
        form,
        formError,
        locale: local,
        language,
        errorLanguage,
        buttonLogin: parseTranslation('login-btn'),
        registrationConfirm: parseTranslation('registration-confirm'),
        langName: parseTranslation('lang-name'),
        titleRegistration: parseTranslation('registration-create-account'),
        registrationInfo: parseTranslation('registration-info'),
        registrationPolicy: parseTranslation('registration-policy'),
        termsConditionsLink: parseTranslation('registration-terms'),
        registrationAnd: parseTranslation('registration-and'),
        registrationPolicyLink: parseTranslation('registration-policy-link'),
        regCreateAccount: parseTranslation('registration-create-account'),
        regAlreadyHave: parseTranslation('registration-info'),
        regLogin: parseTranslation('login-btn'),
        footerInfo: parseTranslation('footer-company-info'),
        registrationCongratulations: parseTranslation(
            'registration-congratulations'
        ),
        registrationSuccess: parseTranslation('registration-success'),
        registrationEmail: parseTranslation('registration-email'),
        isPendingTranslation,
    };
}

const mapDispatchToProps = {
    getForm,
    updateForm,
    submitForm,
    setLocal,
};

class Registration extends Component {
    captchaRef = null;

    constructor(props) {
        super(props);
        this.script = null;
        this.onSubmit = this.onSubmit.bind(this);
        this.captchaRefCallback = this.captchaRefCallback.bind(this);
    }

    componentDidMount() {
        this.props.getForm();
        this.script = document.createElement("script");
        this.script.src = config.recaptchaUrl;
        this.script.async = true;
        this.script.defer = true;
        document.body.appendChild(this.script);
    }

    componentWillUnmount() {
        const scripts = document.getElementsByTagName('script');
        for (let script of scripts) {
            if (script.src.includes('recaptcha')) {
                script.remove();
            }
        }
    }


    componentDidUpdate(prevProps): void {
        const {locale} = this.props;

        if (prevProps.locale !== locale) {
            this.props.getForm();

            if (locale === 'en-US') {
                this.setState({langName: 'English'});
            }

            if (locale === 'zh-CN') {
                this.setState({langName: 'Chinese'});
            }

            if (locale === 'ru-RU') {
                this.setState({langName: 'Russian'});
            }
        }
    }


    render() {
        const {result} = this.props.form;
        const {isPendingTranslation} = this.props;

        // if (isPendingTranslation) return (<Preloader />);

        return (
            <div className="container registration-container">
                <Scrollbars className="registration__scrollbars registration">
                    <div className="scrollarea area login-img">
                        <div className="content confirm-message" />
                        {!Array.isArray(result) && result
                            ? this.renderSuccessText()
                            : this.renderForm()}
                        <p className="technologies">
                            {/*ToDo: block for incorporated text*/}
                        </p>
                    </div>
                </Scrollbars>
            </div>
        );
    }

    renderSuccessText() {
        return (
            <div className="confirm-wrapper">
                <div className="confirm-wrapper-holder">
                    <a
                        href={`${config.landingUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="logo-link"
                        style={{width: 'fit-content'}}
                    >
                        <img src={logo} alt="logo" />
                    </a>
                    <div className="confirmation-text">
                        <h1>
                            {this.props.registrationCongratulations}
                            <br />
                            {this.props.registrationSuccess}
                        </h1>
                        <p>{this.props.registrationEmail}</p>
                    </div>
                </div>
            </div>
        );
    }

    renderForm() {
        const {scheme, errors, name} = this.props.form;
        const {
            regCreateAccount,
            regAlreadyHave,
            regLogin,
            formError,
            errorLanguage,
        } = this.props;


        return (
            <Fragment>
                <div className="wrap">
                    <a
                        href={`${config.landingUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="logo-link"
                        style={{width: 'fit-content'}}
                    >
                        <img src={logo} alt="logo" />
                    </a>
                    <Language className="lng-recovery" leftBottom={true} />
                    <h3 className="reg-title">{regCreateAccount}</h3>
                    <span className="text-indent-down">
                        <i className="icon-info" />
                        {regAlreadyHave} &nbsp;
                        <NavLink to="/login" className="link-item">
                            {regLogin}
                        </NavLink>
                    </span>
                </div>
                <form className="top-form" onSubmit={this.onSubmit}>
                    <input className="hidden-field" />
                    <input
                        className="hidden-field"
                        type="password"
                    />
                    <div className="floating">
                        {formError && <Error />}
                        {scheme && (
                            <>
                                {Object.values(scheme)
                                    .sort((a, b) => a.idx - b.idx)
                                    .map(field => {
                                        return (
                                            <FormField
                                                isEmpty
                                                key={field.name}
                                                name={field.name}
                                                type={field.type}
                                                label={field.label}
                                                options={field.options}

                                                value={field.value ? field.value : null}
                                                onChange={value =>
                                                    this.props.updateForm(
                                                        'RegistrationForm',
                                                        field.name,
                                                        value
                                                    )
                                                }
                                                captchaRefCallback={
                                                    this.captchaRefCallback
                                                }
                                                error={
                                                    !Array.isArray(errors) &&
                                                    errors[field.name] &&
                                                    errors[field.name][0]
                                                }
                                                formName={name}
                                                selectTransparent
                                                selectHeight={160}
                                                animatePlaceholder={true}
                                                isSort={true}
                                            />
                                        );
                                    })}

                                <span>
                                    {this.props.registrationPolicy}&nbsp;
                                    <a
                                        href={`${
                                            config.landingUrl
                                            }`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{cursor: 'pointer'}}
                                        className="privacy-link"
                                    >
                                        {this.props.termsConditionsLink}
                                    </a>
                                    &nbsp;
                                    {this.props.registrationAnd}
                                    <br />
                                    <a
                                        href={`${
                                            config.landingUrl
                                            }`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{cursor: 'pointer'}}
                                        className="privacy-link"
                                    >
                                        {this.props.registrationPolicyLink}
                                    </a>
                                </span>

                                <button type="submit">
                                    {this.props.registrationConfirm}
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </Fragment>
        );
    }

    changeLocal = e => {
        const local = e.target.dataset.code;
        this.props.setLocal(local);
        localStorage.setItem('local', local);
    };

    onSubmit(e) {
        e.preventDefault();
        this.props.updateForm(
            'RegistrationForm',
            'username',
            document.querySelector('input[name=username]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'email',
            document.querySelector('input[name=email]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'email_conf',
            document.querySelector('input[name=email_conf]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'password',
            document.querySelector('input[name=password]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'password_conf',
            document.querySelector('input[name=password_conf]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'first_name',
            document.querySelector('input[name=first_name]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'last_name',
            document.querySelector('input[name=last_name]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'date_of_birth',
            document
                .querySelector('.date-picker--value')
                .getAttribute('data-time')
        );
        // this.props.updateForm(
        //     'RegistrationForm',
        //     'country_id',
        //     document.querySelector('select[name=country_id]').value,
        // );
        this.props.updateForm(
            'RegistrationForm',
            'city',
            document.querySelector('input[name=city]').value
        );
        this.props.updateForm(
            'RegistrationForm',
            'phone',
            document.querySelector('input[name=phone]').value
        );
        // this.props.updateForm(
        //     'RegistrationForm',
        //     'gender',
        //     document.querySelector('select[name=gender]').value,
        // );

        this.props.submitForm();
        this.captchaRef  && this.captchaRef.reset();
    }

    captchaRefCallback(ref) {
        this.captchaRef = ref;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Registration);
