import React from 'react';
import DatePicker from 'antd/lib/date-picker/index';
import classnames from 'classnames';

class RangePickerWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCalendarOpen: false,
        };

        this.onOpenChange = this.onOpenChange.bind(this);
        this.closeCalendar = this.closeCalendar.bind(this);
    }

    onOpenChange(isCalendarOpen) {
        this.setState({isCalendarOpen});
    };

    closeCalendar(e) {
        const {
            isCalendarOpen
        } = this.state;

        const { target } = e;

        if (isCalendarOpen && target.className === 'ant-calendar ant-calendar-range' )
         this.setState({isCalendarOpen: false});
    }

    render() {
        const RangePicker = DatePicker.RangePicker;
        const {
            onChange,
            placeholder,
            separator,
            suffixIcon,
            disabledDate,
            isRangeEmpty,
        } = this.props;

        const {
            isCalendarOpen
        } = this.state;

        const wrapperClass = classnames("range-picker-wrapper", {
            'calendar-opened': isCalendarOpen,
            'calendar-closed': !isCalendarOpen,
            'calendar-empty': isRangeEmpty,
        });

        return (
            <div className={wrapperClass} onClick={this.closeCalendar}>
                <RangePicker
                    suffixIcon={suffixIcon}
                    separator={separator}
                    onChange={onChange}
                    placeholder={placeholder}
                    format={'DD/MM/YYYY'}
                    onOpenChange={this.onOpenChange}
                    disabledDate={disabledDate}
                    open={isCalendarOpen}
                    ref={this.ref}
                />
            </div>
        )

    }
}

export default RangePickerWrapper;
