import React, {Component} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Dropdown from '../../../../component/Dropdown/Dropdown';
import ChartMenuBtn from './ChartMenuBtn';
import Width from './Width';
import colors from 'infrastructure/view/styles/common-colors/common-colors';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';
import {connect} from 'react-redux';

const drawingsList = [
    {value: 'line', title: 'Line Segment'},
    {value: 'vertical_line', title: 'Vertical Line'},
    {value: 'horizontal_line', title: 'Horizontal Line'},
    {value: 'ellipse', title: 'Ellipse'},
    {value: 'rectangle', title: 'Rectangle'},
    // {value: 'extended', title: 'Extended'},
    // {value: 'ray', title: 'Ray'},
    {value: 'pitchfork', title: 'Pitchfork'}, //1
    {value: 'fibonacci_ark', title: 'Fibonacci Arcs'},
    {value: 'fibonacci_rays', title: 'Fibonacci Rays'},
    {value: 'fibonacci_retracements', title: 'Fibonacci Retracements'},
    {value: 'trend_channel', title: 'Trend Channel'},
    {value: 'multichannel', title: 'Multichannel'},
    {value: 'text', title: 'Text'}, //1
    {value: 'base_isolation_tool', title: 'Base Isolation Tool'},
    {value: 'magnifying_tool_rectangle', title: 'Magnifying Tool Rectangle'}, //1
    {value: 'magnifying_tool_time_range', title: 'Magnifying Tool Time Range'}, //1
    {value: 'vertical_arrow_up', title: 'Vertical Arrow Up'}, //1
    {value: 'vertical_arrow_down', title: 'Vertical Arrow Down'}, //1
];

class DrawingsMenu extends Component {
    state = {
        list: drawingsList,
        selectedItem: '',
        showSelectedList: false,
        isVisibility: true,
        fillBackground: true,
        hideDrawings: false,
        width: 1,
        lineColor: colors.color1,
    };

    mapDrawingsIcon = iconName => {
        switch (iconName) {
            case 'line':
                return 'icon-line-segment';
            case 'vertical_line':
                return 'icon-vertical-line';
            case 'horizontal_line':
                return 'icon-horizontal-line';
            case 'ellipse':
                return 'icon-ellipse';
            case 'rectangle':
                return 'icon-rectangle';
            case 'pitchfork':
                return 'icon-pitchfork';
            case 'fibonacci_ark':
                return 'icon-fibonacci-arcs';
            case 'fibonacci_rays':
                return ' icon-fibonacci-rays';
            case 'fibonacci_retracements':
                return 'icon-fibonacci-retracement';
            case 'trend_channel':
                return 'icon-trend-channel';
            case 'multichannel':
                return 'icon-multichannel';
            case 'text':
                return 'icon-text';
            case 'base_isolation_tool':
                return ' icon-base-isolation-tool';
            case 'magnifying_tool_rectangle':
                return 'icon-magnifying-tool-rectangle';
            case 'magnifying_tool_time_range':
                return 'icon-magnifying-tool-time-range';
            case 'vertical_arrow_up':
                return 'icon-vertical-arrow-up';
            case 'vertical_arrow_down':
                return 'icon-vertical-arrow-down';
            default:
                return null;
        }
    };

    onSelectType = item => () => {
        if (this.state.selectedItem.value === item.value) {
            this.props.setDrawings({type: ''});
            this.timer = setTimeout(
                () => this.props.setDrawings({type: item.value}),
                10
            );
            return;
        }

        this.setState({selectedItem: item});
        this.props.setDrawings({type: item.value});
    };

    onChangeColor = e => {
        this.setState({lineColor: e.target.value});
        this.props.setDrawings({lineColor: e.target.value});
    };

    onChangeWidth = value => {
        if (value < 1 || value > 16) {
            return;
        }
        this.setState({width: value}, () =>
            this.props.setDrawings({lineWidth: this.state.width})
        );
    };

    onChangeText = e => this.props.setDrawings({text: e.target.value});

    onChangeVisibility = () => {
        this.setState(
            prevState => ({
                isVisibility: !prevState.isVisibility,
                hideDrawings: !prevState.hideDrawings,
            }),
            () => {
                this.props.setDrawings({isVisible: this.state.isVisibility});
            }
        );
    };

    removeDrawings = () => this.props.setDrawings({remove: true});

    onChangeFillBackground = () => {
        this.setState(
            prevState => ({
                fillBackground: !prevState.fillBackground,
            }),
            () => {
                this.props.setDrawings({
                    fillBackground: this.state.fillBackground,
                });
            }
        );
    };

    delete = item => () => {
        this.props.deleteDI(item);
    };

    onRemoveSelectedDrawing = () => {
        const {selectedDrawing} = this.props;

        if (selectedDrawing && selectedDrawing.selectedDrawing && selectedDrawing.chartInstance) {
            selectedDrawing.chartInstance.removeDrawing(selectedDrawing.selectedDrawing);
            selectedDrawing.selectedDrawing = null;
        }
    };

    componentWillUnmount(): void {
        clearTimeout(this.timer);
    }

    render() {
        const {selectedItem} = this.state;

        const iconBtn = classnames({
            'icon-drawings': !selectedItem.title,
            [this.mapDrawingsIcon(
                this.state.selectedItem.value
            )]: selectedItem.title,
        });

        return (
            <Styles className="drawing-menu">
                <Dropdown
                    className="drawing-type"
                    btn={<ChartMenuBtn icon={iconBtn} />}
                    tooltip={<Tooltip>{this.props.tooltipDrawings}</Tooltip>}
                >
                    <div className="drawing-menu__drop-down">
                        <Scrollbars style={{height: '45rem'}}>
                            <div className="drawing-menu__list">
                                {this.state.selectedItem && (
                                    <div
                                        className="drawing-menu__item drawing-menu__item_repeat"
                                        key={'rep-dr'}
                                        onClick={this.onSelectType(this.state.selectedItem)}
                                    >
                                        <i className="icon-repeat-drawing" />
                                        Repeat drawing
                                    </div>
                                )}
                                {this.state.list.map(item => (
                                    <div
                                        className={`drawing-menu__item ${
                                            item.value ===
                                            this.state.selectedItem.value
                                                ? 'active'
                                                : ''
                                        }`}
                                        key={item.title}
                                        onClick={this.onSelectType(item)}
                                    >
                                        <i
                                            className={this.mapDrawingsIcon(
                                                item.value
                                            )}
                                        />
                                        {item.title}
                                    </div>
                                ))}
                            </div>
                        </Scrollbars>
                    </div>
                </Dropdown>
                <Tooltip>{this.props.tooltipColor}</Tooltip>
                <ColorWrap>
                    <Colors
                        type="color"
                        value={this.state.lineColor}
                        onChange={this.onChangeColor}
                    />
                    <Tooltip>{this.props.tooltipColor}</Tooltip>
                </ColorWrap>
                <FillBg>
                    <input
                        id="fill-bg"
                        type="checkbox"
                        onChange={this.onChangeFillBackground}
                        defaultChecked={this.state.fillBackground}
                    />
                    <label htmlFor="fill-bg">
                        <i className="icon-fill-background" />
                    </label>
                    <Tooltip>{this.props.tooltipBackground}</Tooltip>
                </FillBg>
                <ShowDrawings>
                    <input
                        id="show-drawings"
                        type="checkbox"
                        onChange={this.onChangeVisibility}
                        value={this.state.isVisibility}
                    />
                    <label htmlFor="show-drawings">
                        {this.state.hideDrawings ? (
                            <i className="icon-hide" />
                        ) : (
                            <i className="icon-look" />
                        )}
                    </label>
                    <Tooltip>{this.props.tooltipHide}</Tooltip>
                </ShowDrawings>
                <Dropdown
                    className="delete"
                    btn={<ChartMenuBtn icon="icon-chart-delete" />}
                    tooltip={<Tooltip>{this.props.tooltipDelete}</Tooltip>}
                >
                    <div className="delete__drop-down">
                        <div className="delete__list">
                            <div
                                className="delete__item"
                                onClick={this.onRemoveSelectedDrawing}
                            >
                                {this.props.labelSelectedDrawer}
                            </div>
                            <div
                                className="delete__item"
                                key="d1"
                                onClick={this.delete('deleteDw')}
                            >
                                {this.props.labelDeleteDrawings}
                            </div>
                            <div
                                className="delete__item"
                                key="d2"
                                onClick={this.delete('deleteI')}
                            >
                                {this.props.labelRemoveIndicators}
                            </div>
                            <div
                                className="delete__item"
                                key="d3"
                                onClick={this.delete('deleteAll')}
                            >
                                {this.props.labelDeleteDrawingsIndicators}
                            </div>
                        </div>
                    </div>
                </Dropdown>
                <Width onChange={this.onChangeWidth} />
                <Text
                    type="text"
                    placeholder={this.props.labelPlaceholder}
                    onChange={this.onChangeText}
                />
            </Styles>
        );
    }
}

const RemoveBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.666rem;
    height: 100%;
    background-color: #f5f5f5;
    transition: color 0.3s;

    i {
        color: #999999;
        font-size: 3rem;
    }

    &:hover {
        i {
            color: #0c3dfb;
        }
        cursor: pointer;
    }
`;

const Styles = styled.div`
    display: flex;
    align-items: start;
    position: relative;
    width: auto;

    .drawing-menu {
        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 6.666rem;
            background-color: #f5f5f5;
            overflow: hidden;
            color: #999999;
            transition: color 0.3s;

            &::before {
                content: '';
                position: absolute;
                bottom: 0.7rem;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 3px 3px 0 3px;
                border-color: #999999 transparent transparent transparent;
                transition: border 0.3s;
            }

            i {
                font-size: 3rem;
            }

            &:hover {
                color: #0c3dfb;

                &::before {
                    border-color: #0c3dfb transparent transparent transparent;
                }
            }
        }

        &__drop-down {
            position: absolute;
            top: 8rem;
            left: 50%;
            transform: translateX(-50%);
            width: 33.33rem;
            color: ${p => p.theme.color14};
            font-size: 2.3rem;
            background-color: ${p => p.theme.color23};
            border: 1px solid ${props => props.theme.color1};
            box-shadow: 0 0 3rem $color66;
            z-index: 10;            

            .scrollbars-container {                
                overflow: hidden;                
            }
            &:before,
            &:after {
                content: '';
                position: absolute;                
                left: 50%;
                bottom: 100%;
                transform: translateX(-50%);                           
                border: 1.5rem solid transparent;
                border-bottom-color: ${p => p.theme.color1};                             
                z-index: 10;
            }
            &:after {
                margin-top: 1px;
                border-bottom-color: ${p => p.theme.color23};   
                margin-bottom: -1px;
            }
        }

        &__list {
            position: relative;
        }

        &__item {
            position: relative;
            cursor: pointer;
            padding: 2rem 6rem 2rem 8.333rem;

            &:hover {
                position: relative;
                color: ${p => p.theme.color15};
                background: ${p => p.theme.color69};
                z-index: 1;

                &:after {
                    display: none;
                }
            }

            &:hover .icon-close {
                color: ${p => p.theme.color20};
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 4rem);
                height: 0.2rem;
                background-color: ${p => p.theme.color7};
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            i {
                position: absolute;
                top: 50%;
                left: 2.167rem;
                transform: translateY(-50%);
                font-size: 2.5rem;
            }

            &.active {
                color: ${p => p.theme.color1};
            }

            &_repeat {
                border-bottom: 1px solid ${p => p.theme.color1};
            }
        }

        &__added-container {
            position: relative;

            &:hover .studies-menu__added {
                color: #fff;
                background: linear-gradient(to right, #00124a, #00124acc);
            }
        }

        &__added {
            cursor: pointer;
            padding: 2rem;
            color: #00124a;
            border-bottom: 0.1rem solid #0c3dfb;
            transition: 0.3s;
        }

        &__added-list {
            position: absolute;
            top: 0;
            left: 100%;
            min-width: 33.33rem;
            border: 1px solid #0c3dfb;
            background-color: #fff;
        }

        &__item-remove {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
            height: 5rem;
            width: 5rem;

            .icon-close {
                font-size: 2.6rem;
            }
        }
    }

    .drawing-type {
        height: 100%;
        margin-right: 1rem !important;
        width: auto;

        &:hover {
            .tooltip {
                opacity: 1;
                transition-delay: 1s;
            }
        }
    }

    .delete {
        height: 100%;

        &:hover {
            .tooltip {
                opacity: 1;
                transition-delay: 1s;
                visibility: visible;
            }
        }

        &__drop-down {
            position: absolute;
            top: 8rem;
            left: 50%;
            transform: translateX(-50%);
            width: 33.33rem;
            color: ${p => p.theme.color14};
            font-size: 2.3rem;
            background-color: ${p => p.theme.color23};
             border: 1px solid ${props => props.theme.color1};
            box-shadow: 0 0 3rem ${props => props.theme.color66};
            z-index: 10;            

            .scrollbars-container {
                margin-top: 0.1rem;
                margin-bottom: 0.1rem;                
                overflow: hidden;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;                
                left: 50%;
                bottom: 100%;
                transform: translateX(-50%);                           
                border: 1.5rem solid transparent;
                border-bottom-color: ${p => p.theme.color1};                             
                z-index: 10;
            }
            &:after {
                margin-top: 1px;
                border-bottom-color: ${p => p.theme.color23};   
                margin-bottom: -1px;
            }
        }

        &__list {
            position: relative;
            overflow: hidden;            
        }

        &__item {
            position: relative;
            cursor: pointer;
            padding: 2rem;

            &:hover {
                position: relative;
                color: ${p => p.theme.color15};
                background: ${p => p.theme.color69};
                z-index: 1;

                &:after {
                    display: none;
                }
            }

            &:hover .icon-close {
                color: ${p => p.theme.color1};
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: calc(100% - 4rem);
                height: 0.2rem;
                background-color: ${p => p.theme.color7};
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            i {
                position: absolute;
                top: 50%;
                left: 2.167rem;
                transform: translateY(-50%);
                font-size: 2.5rem;
            }

            &.active {
                color: ${p => p.theme.color1};
            }

            &_repeat {
                border: 1px solid #0c3dfb;
                background-color: #f5f5f5;
            }
        }
    }
`;

const Tooltip = styled.div`
    position: absolute;
    top: calc(100% + 2.666rem);
    left: 50%;
    transform: translate(-50%);
    max-width: 50rem;
    min-width: 20rem;
    border: 1px solid ${p => p.theme.color1};
    box-shadow: 0 0 3rem ${props => props.theme.color66};
    text-align: center;
    background-color: ${p => p.theme.color23};
    color: ${p => p.theme.color14};
    font-size: 2.333rem;
    padding: 1rem;
    box-sizing: border-box;
    word-wrap: break-word;
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0;
    pointer-events: none;    

    &:after {
        content: ' ';
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        display: block;
        width: 2rem;
        height: 2rem;
        border: 1px solid ${p => p.theme.color1};
        border-bottom: none;
        border-right: none;
        background-color: ${p => p.theme.color23};
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        z-index: 10;
    }
`;

const Colors = styled.input`
    display: block;
    height: 5.833rem;
    width: 5.833rem;
    border: 1px solid ${p => p.theme.color7};
    padding: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;    
    background: ${p => p.theme.color7};

    &::-webkit-color-swatch {
        border: none;        
        padding: 0;
    }
    &::-moz-color-swatch {
        border: none;        
        padding: 0;
    }
    &::-webkit-color-swatch-wrapper {
        border: none;        
        padding: 0;
    }

    &:focus {
        border: 1px solid ${p => p.theme.color1};
        outline: none;
    }
    &:hover {
        & + ${Tooltip} {
            opacity: 1;
            transition-delay: 1s;
        }
    }
`;

const ColorWrap = styled.div`
    position: relative;
    height: 100%;
    margin-right: 1rem;

    &:hover {
        ${Tooltip} {
            opacity: 1;
            transition-delay: 1s;
            z-index: 1;
        }
    }
`;

const Text = styled.input`
    display: block;
    width: 18rem;
    height: 5.833rem;
    border: 1px solid ${p => p.theme.color7};
    box-sizing: border-box;
    padding: 0 2rem;
    margin-right: 1rem;
    font-size: 2.3rem;
    color: ${p => p.theme.color14};    
    background: ${p => p.theme.color7};

    &:focus {
        border: 1px solid ${p => p.theme.color1};
        outline: none;
    }

    &::placeholder {
        color: ${p => p.theme.color65};
    }

    //::-webkit-input-placeholder {
    //    color: #8e9ebe;
    //}
    //::-moz-placeholder {
    //    color: #8e9ebe;
    //}
    //
    //&:focus {
    //    border: 1px solid #0c3dfb;
    //    outline: none;
    //}
`;

const ShowDrawings = styled.div`
    position: relative;
    height: 100%;
    margin-right: 1rem;

    input {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        clip: rect(0, 0, 0, 0);

        &:checked + label {
            background: ${p => p.theme.color1};

            i {
                color: ${p => p.theme.color23};
            }
        }
    }

    label {
        position: relative;
        display: block;
        height: 5.833rem;
        width: 5.833rem;
        background: ${p => p.theme.color7};
        cursor: pointer;        
        transition: background 0.3s;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: ${p => p.theme.color65};
            font-size: 3.9rem;
            transition: color 0.3s;
        }

        &:hover {
            i {
                color: ${p => p.theme.color1};
            }
        }
    }

    &:hover {
        ${Tooltip} {
            opacity: 1;
            transition-delay: 1s;
            visibility: visible;
            z-index: 1;
        }
    }
`;

const FillBg = styled.div`
    position: relative;
    height: 100%;
    margin-right: 1rem;

    input {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        clip: rect(0, 0, 0, 0);

        &:checked + label {
            background: ${p => p.theme.color1};

            i {
                color: ${p => p.theme.color20};
            }
        }
    }

    label {
        position: relative;
        display: block;
        height: 5.833rem;
        width: 5.833rem;
        background: ${p => p.theme.color33};
        cursor: pointer;        
        transition: background 0.3s;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: ${p => p.theme.color14};
            font-size: 3.9rem;
            transition: color 0.3s;
        }

        &:hover {
            i {
                color: ${p => p.theme.color20};
            }
        }
    }

    &:hover {
        ${Tooltip} {
            opacity: 1;
            transition-delay: 1s;
            z-index: 1;
        }
    }
`;
const mapStateToProps = state => ({
    tooltipDrawings: parseTranslation('tooltip-chart1-drawings'),
    tooltipBackground: parseTranslation('tooltip-chart1-background'),
    tooltipHide: parseTranslation('tooltip-chart1-hide'),
    tooltipDelete: parseTranslation('tooltip-chart1-delete'),
    tooltipColor: parseTranslation('tooltip-chart1-color'),
    labelSelectedDrawer: parseTranslation('label-chart1-delete-selected-drawing'),
    labelDeleteDrawings: parseTranslation('label-chart1-delete-drawings'),
    labelRemoveIndicators: parseTranslation('label-chart1-delete-indicators'),
    labelDeleteDrawingsIndicators: parseTranslation('label-chart1-delete-drawings-indicators'),
    labelPlaceholder: parseTranslation('placeholder-chart1-text'),
});
export default connect(mapStateToProps)(DrawingsMenu);
