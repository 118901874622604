// @flow
import type { RegistrationFormAdapterInterface as RegistrationFormAdapter }  from '../../../application/adapter/RegistrationForm';

class GetRegistrationForm {
    adapter: RegistrationFormAdapter;

    constructor(adapter: RegistrationFormAdapter) {
        this.adapter = adapter;
    }

    async invoke(locale: string) {
        return await this.adapter.getForm(locale);
    }
}

export default GetRegistrationForm;
