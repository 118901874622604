import React, {Fragment} from 'react';

import Symbols from '../components/Symbols/Symbols';
import News from '../components/News/News';
import ChartContainer from '../components/ChartContainer/ChartContainer';
import TradingHistory from '../components/TradingHistory/TradingHistory';
import DOM from '../components/DOM/DOM';
import OrderForm from '../components/OrderForm/OrderForm';
import MarketTrades from '../components/MarketTrades/MarketTrades';

export default function({positionTooltip}) {
    return (
        <Fragment>
            <div className="col wide">
                <div className="col-item">
                    <ChartContainer />
                </div>
                <div className="col-item">
                    <TradingHistory />
                </div>
            </div>
            <div className="col market">
                <div className="col-item">
                    <div className="col-item dom-item">
                        <DOM />
                    </div>
                    <div className="col-item col-order-height">
                        <OrderForm />
                    </div>
                </div>
                <div className="col-item">
                    <MarketTrades />
                </div>
            </div>
            <div className="col">
                <div className="col-item">
                    <Symbols
                        positionTooltip={positionTooltip}
                    />
                </div>
                <div className="col-item">
                    <News />
                </div>
            </div>
        </Fragment>
    );
}
