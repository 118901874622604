import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
`;

export const Fill = styled.div`
    position: absolute;
    left: 0.1rem;
    bottom: 0.5rem;
    height: 0.8rem;
    pointer-events: none;
    width: ${({left}) => `${left}%`};
    border-radius: 1rem;
    background: ${({theme}) => `${theme.color1}80`};
    z-index: 1;
`;
