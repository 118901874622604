class GetTwoFAStatus {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(token: string): Promise<any> {
        try {
            const { data } = await this.adapter.getTwoFAStatus(token);
            return data["2fa"];
        } catch (e) {
            throw e
        }
    }
}

export default GetTwoFAStatus;
