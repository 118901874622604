import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import Button from '../Header/Button';
import NotificationItem from './NotificationItem';

import {
    Title,
    NotificationWrapper,
    ScrollWrapper,
    NotNotificationText,
} from './styled';

export default ({
    notifications,
    closeNotification,
    closeAllNotifications,
    translation: {closeAll, titleNotification, noNotifLabel},
}) => {
    const {data} = notifications;
    return (
        <NotificationWrapper>
            <Title>{titleNotification}</Title>
            <ScrollWrapper quantity={data.length}>
                <Scrollbars
                    autoHide
                    renderTrackHorizontal={()=><div style={{display: 'none'}}/>}
                    renderThumbHorizontal={()=><div style={{display: 'none'}} />}
                >
                    {!!data.length ? (
                        data.map((notification, i) => (
                            <NotificationItem
                                key={i}
                                data={notification}
                                closeNotification={closeNotification}
                            />
                        ))
                    ) : (
                        <NotNotificationText empty={!!data.length}>
                            {noNotifLabel}
                        </NotNotificationText>
                    )}
                </Scrollbars>
            </ScrollWrapper>
            {data.length > 1 && (
                <button
                    className="main-button-small"
                    onClick={closeAllNotifications}
                >
                    {closeAll}
                </button>
            )}
        </NotificationWrapper>
    );
};
