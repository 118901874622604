import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import ReactSelectWrapper from 'infrastructure/view/component/ReactSelectWrapper/ReactSelectWrapper';
import Button from 'infrastructure/view/component/Button';
import ChartMenuBtn from './ChartMenuBtn';
import Dropdown from '../../../../component/Dropdown/Dropdown';
import {
    sendSettingsData,
    setChartOffset,
} from '../../../../services/action/settings';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';

const offsetOptions = [
    {value: 0, label: '0'},
    {value: 5, label: '5%'},
    {value: 10, label: '10%'},
    {value: 15, label: '15%'},
    {value: 20, label: '20%'},
];

class OffsetMenu extends Component {
    offsetOptions = [
        {value: 0, label: '0'},
        {value: 5, label: '5%'},
        {value: 10, label: '10%'},
        {value: 15, label: '15%'},
        {value: 20, label: '20%'},
    ];

    state = {
        offsets: {
            top: this.offsetOptions[1],
            right: this.offsetOptions[0],
            bottom: this.offsetOptions[1],
        },
        offsetsSettings: {
            top: this.offsetOptions[1],
            right: this.offsetOptions[0],
            bottom: this.offsetOptions[1],
        },
        isApply: false,
    };

    static getDerivedStateFromProps(props, state) {
        if (!_.isEqual(props.offsetsSettings, state.offsetsSettings)) {
            return {
                offsetsSettings: props.offsetsSettings,
                offsets: {
                    top: offsetOptions.filter(el => el.value === props.offsetsSettings.top)[0],
                    right: offsetOptions.filter(el => el.value === props.offsetsSettings.right)[0],
                    bottom: offsetOptions.filter(el => el.value === props.offsetsSettings.bottom)[0],
                },
            };
        }

        // Return null to indicate no change to state.
        return null;
    }

    passOffsetsToParent() {
        const {offsets} = this.state;
        const {setOffsets, setChartOffset, sendSettingsData} = this.props;

        const newOffsets = {
            top: offsets.top.value,
            right: offsets.right.value,
            bottom: offsets.bottom.value,
        };

        if (setOffsets) {
            setOffsets(newOffsets);
        }

        setChartOffset(newOffsets);
        sendSettingsData();
    }

    onApply = () => {
        this.passOffsetsToParent();
        this.setState({isApply: true}, () => this.setState({isApply: false}));
    };

    onDefault = () => {
        this.setState(
            {
                offsets: {
                    top: this.offsetOptions[1],
                    right: this.offsetOptions[0],
                    bottom: this.offsetOptions[1],
                },
            },
            this.passOffsetsToParent
        );
    };

    render() {
        const {offsets, isApply} = this.state;

        return (
            <Styles className="offset-menu">
                <Dropdown
                    close={isApply}
                    btn={<ChartMenuBtn icon="icon-indent" />}
                    isInsideClick
                >
                    <div className="offset-menu__drop-down">
                        <div className="offset-menu__select-wrap">
                            <div className="offset-menu__select-lable">
                                {this.props.labelOffsetTop}
                            </div>
                            <div className="offset-menu__select">
                                <ReactSelectWrapper
                                    defaultValue={offsets.top}
                                    value={offsets.top}
                                    onChange={selected => {
                                        this.setState(state => ({
                                            offsets: {
                                                ...state.offsets,
                                                top: selected,
                                            },
                                        }));
                                    }}
                                    options={this.offsetOptions}
                                />
                            </div>
                        </div>
                        {/*<div className="offset-menu__select-wrap">*/}
                        {/*    <div className="offset-menu__select-lable">*/}
                        {/*        Offset right*/}
                        {/*    </div>*/}
                        {/*    <div className="offset-menu__select">*/}
                        {/*        <ReactSelectWrapper*/}
                        {/*            defaultValue={offsets.right}*/}
                        {/*            value={offsets.right}*/}
                        {/*            onChange={selected => {*/}
                        {/*                this.setState(state => ({*/}
                        {/*                    offsets: {*/}
                        {/*                        ...state.offsets,*/}
                        {/*                        right: selected,*/}
                        {/*                    },*/}
                        {/*                }));*/}
                        {/*            }}*/}
                        {/*            options={this.offsetOptions}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="offset-menu__select-wrap">
                            <div className="offset-menu__select-lable">
                                {this.props.labelOffsetBottom}
                            </div>
                            <div className="offset-menu__select">
                                <ReactSelectWrapper
                                    defaultValue={offsets.bottom}
                                    value={offsets.bottom}
                                    onChange={selected => {
                                        this.setState(state => ({
                                            offsets: {
                                                ...state.offsets,
                                                bottom: selected,
                                            },
                                        }));
                                    }}
                                    options={this.offsetOptions}
                                />
                            </div>
                        </div>
                        <div className="offset-menu__controls">
                            <button className="secondary-button-small" color="secondary" size='medium' onClick={this.onDefault}>{this.props.buttonDefault}</button>
                            <button className="main-button-small"color="primary" size='medium' onClick={this.onApply}>
                                {this.props.buttonApply}
                            </button>
                        </div>
                    </div>
                </Dropdown>
                <Tooltip>{this.props.tooltipMargin}</Tooltip>
            </Styles>
        );
    }
}

const Tooltip = styled.div`
        position: absolute;
    top: calc(100% + 2.666rem);
    left: 50%;
    transform: translate(-50%);
    max-width: 50rem;
    min-width: 20rem;
    border: 1px solid ${p => p.theme.color1};
    text-align: center;
    background-color: ${p => p.theme.color23};
    box-shadow: 0 0 3rem ${props => props.theme.color66};
    color: ${p => p.theme.color14};
    font-size: 2.333rem;
    padding: 1rem;
    box-sizing: border-box;
    word-wrap: break-word;
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0;
    pointer-events: none;    
    z-index: 1;

    &:after {
        content: ' ';
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        display: block;
        width: 2rem;
        height: 2rem;
        border: 1px solid ${p => p.theme.color1};
        border-bottom: none;
        border-right: none;
        background-color: ${p => p.theme.color23};
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        z-index: 10;
    }
`;

const Styles = styled.div`
    position: relative;

    .offset-menu {
        margin-right: 6rem;

        &__drop-down {
            position: absolute;
            top: 8rem;
            left: 50%;
            transform: translateX(-50%);
            padding: 2rem;
            color: ${p => p.theme.color14};
            font-size: 2.3rem;
            background-color: ${p => p.theme.color23};
             border: 1px solid ${props => props.theme.color1};
            box-shadow: 0 0 3rem ${props => props.theme.color66};
            z-index: 10;            

            &:before,
            &:after {
                content: '';
                position: absolute;                
                left: 50%;
                bottom: 100%;
                transform: translateX(-50%);                           
                border: 1.5rem solid transparent;
                border-bottom-color: ${p => p.theme.color1};                             
                z-index: 10;
            }
            &:after {
                margin-top: 1px;
                border-bottom-color: ${p => p.theme.color23};   
                margin-bottom: -1px;
            }
        }

        &__select-wrap {
            display: flex;
            align-items: center;
            min-width: 33rem;
            margin-bottom: 2rem;

            .select-wrapper {
                margin: 0;
            }
        }

        &__select-lable {
            width: 50%;
            padding-right: 3rem;
        }

        &__select {
            width: 50%;
        }

        &__controls {
            display: flex;
            justify-content: space-between;

            button {
                width: calc(50% - 1rem);
            }
        }
    }

    &:hover {
        ${Tooltip} {
            opacity: 1;
            transition-delay: 1s;
        }
    }
`;

const mapStateToProps = (state) => ({
    offsetsSettings: state.settings.dxChart.offsets,
    tooltipMargin: parseTranslation('tooltip-chart1-margin'),
    labelOffsetTop: parseTranslation('label-chart1-margin-offset-top'),
    labelOffsetBottom: parseTranslation('label-chart1-margin-offset-bottom'),
    buttonDefault: parseTranslation('button-chart1-margin-default'),
    buttonApply: parseTranslation('button-chart1-margin-apply'),
});

const mapDispatchToProps = {
    setChartOffset,
    sendSettingsData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OffsetMenu);
