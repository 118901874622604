// @flow
class GetTranslation {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(accessToken: string, local: string): Promise<any> {
        return await this.adapter.getTranslation(accessToken, local);
    }
}

export default GetTranslation;
