import { ActionTypes } from './actions';

const INITIAL_STATE = {
    error: null,
    data: []
};


function historyTransactionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.setTransactionHistory: {
            return {
                data: action.history
            };
        }

        case ActionTypes.errorTransaction:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}

export default historyTransactionReducer;
