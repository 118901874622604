//@flow
import React, {PureComponent} from 'react';
import FormattedPrice from '../../FormattedPrice/FormattedPrice';

class MessageContent extends PureComponent {
    render() {
        const {firstTitle, firstInfo, secondTitle, secondInfo, isDocument} = this.props;
        const number = +firstInfo.split(' ')[0];
        const currency = firstInfo.split(' ')[1];
        return (
            <div className="ticket-message-content">
                <span className="ticket-message-content__content">
                    <span className="ticket-message-content__content--title">
                        {firstTitle}
                    </span>
                    {!isDocument &&
                        <span className="ticket-message-content__info">
                            {isNaN(number) ? <>{firstInfo}</> : <><FormattedPrice price={number}/> {currency}</>}
                        </span>
                    }
                </span>
                <span className="ticket-message-content__content">
                    <span className="ticket-message-content__content--title">
                        {secondTitle}
                    </span>
                    <span className="ticket-message-content__info">
                        {secondInfo}
                    </span>
                </span>
            </div>
        );
    }
}

export default MessageContent;
