// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {TicketsAdapterInterface as TicketsAdapter} from '../../../application/adapter/Tickets';

class ReplyToTicket {
    adapter: TicketsAdapter;

    constructor(adapter: TicketsAdapter) {
        this.adapter = adapter;
    }

    async invoke(token: AccessToken, id: number, message: string) {
        try {
            await this.adapter.replyToTicket(token, id, message);
        } catch (error) {
            throw error;
        }
    }
}

export default ReplyToTicket;
