import React, {useEffect, useRef, useState} from 'react';
import Panel from '../components/Panel';
import t from '../../infrastructure/adapter/helper/parseTranslation';
import {useDispatch, useSelector} from 'react-redux';
import {ChartHolder} from './styled';
import {clearOldPrevTick, formCandle} from './helpers';
import {getChartHistory} from './actions';
import ChartMenu from '../ChartMenu';
import ChartTabs from './component/ChartTabs';
import GuestDxChart from '../components/GuestDxChart';
import TVChartLayout from '../../infrastructure/view/scene/Dashboard/components/ChartContainer/components/TVChartLayout';
import {chartDXHistoryRequest} from '../../infrastructure/view/services/action/DXChartHistoryProvider';
import Preloader from '../../infrastructure/view/component/Preloader';
import {addNewTickChartTV} from '../../infrastructure/view/scene/Dashboard/components/Chart/service/actions';

export default function GuestChartContainer() {
    const dispatch = useDispatch();
    const chartHolder = useRef(null);
    const data = useSelector(state => state.CHART.data);
    const currencyPair = useSelector(state => state.CURRENCY_PAIR.asset);
    const chartInterval = useSelector(state => state.CHART.chartInterval);
    const chartType = useSelector(state => state.CHART_MENU.chartType);
    const {translation} = useSelector(state => state.translation);
    const [height, setHeight] = useState(0);
    const [newTick, setNewTick] = useState(null);
    const confirmDeal = useSelector(
        state => state.GUEST_DEALING_WS['confirm-deals'][0]
    );
    let momentInterval;
    const MINUTE_MS = 60000;
    switch (chartInterval) {
        case '1M': {
            momentInterval = 1 * MINUTE_MS;
            break;
        }
        case '5M': {
            momentInterval = 5 * MINUTE_MS;
            break;
        }
        case '15M': {
            momentInterval = 15 * MINUTE_MS;
            break;
        }
        case '30M': {
            momentInterval = 30 * MINUTE_MS;
            break;
        }
        case '1H': {
            momentInterval = 60 * MINUTE_MS;
            break;
        }
        default: {
            momentInterval = MINUTE_MS;
            break;
        }
    }

    useEffect(() => {
        setHeight(chartHolder.current.getBoundingClientRect().height);
    }, []);
    useEffect(() => {
        if (data.length > 0) {
            const last = {...data[data.length - 1], timestamp: data[data.length - 1].timestamp};
            if (data.length && confirmDeal) {
                setNewTick(
                    formCandle(confirmDeal, momentInterval, {
                        ...last,
                        timestamp: last.timestamp,
                    })
                );

                const {price, amount, openTime} = confirmDeal;
                const tvDeal = {
                    price: +price,
                    amount: +amount,
                    openingTime: openTime,
                };
                dispatch(addNewTickChartTV(tvDeal));
            }
        }

    }, [confirmDeal]);

    useEffect(() => {
        clearOldPrevTick();
        currencyPair && dispatch(getChartHistory(currencyPair, chartInterval));
    }, [currencyPair]);
    const [isDxChart, setDxChart] = useState(true);
    const handleChangeChart = value => setDxChart(value);

    return (
        <Panel
            title={t('label-chart')}
            icon="icon-chart"
            headerComponent={<ChartTabs onChange={handleChangeChart} />}
        >
            {isDxChart && <ChartMenu />}
            <ChartHolder ref={chartHolder}>
                {data.length ? (
                    <GuestDxChart
                        height={height}
                        translation={translation}
                        visibility={isDxChart}
                        data={data}
                        newTick={newTick}
                        currencyPair={currencyPair}
                        chartType={chartType}
                    />
                ) : (
                    <Preloader />
                )}

                {!isDxChart && (
                    <>
                        {data.length ? (
                            <TVChartLayout
                                symbol={currencyPair}
                                locale={'en-US'}
                                chartDXHistoryRequest={() =>
                                    dispatch(chartDXHistoryRequest())
                                }
                            />
                        ) : (
                            <Preloader />
                        )}
                    </>
                )}
            </ChartHolder>
        </Panel>
    );
}
