import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import Panel from '../components/Panel';
import OrderFormGuestButton from '../components/OrderFormGuestButton';
import OrderInput from '../components/OrderInput';
import CurrencyPairContainer from '../CurrencyPairContainer';
import Select from '../components/Select';
import Input from '../components/Input';
import {Wrapper} from './styles';
import {
    MaxButton,
    MinButton,
    SortButtons,
} from '../components/OrderInput/styled';
import Tooltip from '../components/Tooltip';
import t from '../../infrastructure/adapter/helper/parseTranslation';

export default () => {
    const selectedSymbol = useSelector(state => state.CURRENCY_PAIR.asset);

    const opt = [
        {label: t('label-limit'), value: '1'},
        {label: t('label-market'), value: '2'},
    ];

    const [type, setType] = useState(t('label-limit'));
    return (
        <Panel
            title={t('label-order-form')}
            icon="icon-open-order"
            hideExpander
        >
            <Wrapper>
                <OrderInput label={t('label-symbol')}>
                    <CurrencyPairContainer />
                </OrderInput>
                <OrderInput label={t('label-order-type')}>
                    <Select
                        value={type}
                        options={opt}
                        onSelect={({label}) => setType(label)}
                    />
                </OrderInput>
                <OrderInput
                    label={`${t('label-price')} (${selectedSymbol.split('/')[0]})`}
                    sort={
                        <SortButtons>
                            <MaxButton className="icon-max">
                                <Tooltip title={t('label-buy-price')} />
                            </MaxButton>
                            <MinButton className="icon-min">
                                <Tooltip title={t('label-sell-price')} />
                            </MinButton>
                        </SortButtons>
                    }
                >
                    <Input type="number" placeholder={t('label-price')} />
                </OrderInput>
                <OrderInput
                    label={`${t('label-amount')} (${
                        selectedSymbol.split('/')[1]
                    })`}
                    sort={
                        <SortButtons>
                            <MaxButton className="icon-max">
                                <Tooltip
                                    title={t('label-buy-volume')}
                                    bottomRight
                                />
                            </MaxButton>
                            <MinButton className="icon-min">
                                <Tooltip
                                    title={t('label-sell-volume')}
                                    bottomRight
                                />
                            </MinButton>
                        </SortButtons>
                    }
                >
                    <Input type="number" placeholder={t('label-amount')} />
                </OrderInput>
                <OrderFormGuestButton />
            </Wrapper>
        </Panel>
    );
};
