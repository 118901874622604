// @flow
class GetDealingToken {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(accessToken: string): Promise<any> {
        try {
            const {data: dealingToken} = await this.adapter.getDealingToken(
                accessToken,
            );

            return dealingToken;
        } catch (error) {
            // window.localStorage.removeItem('accessToken');
            // window.location.reload();
        }
    }
}

export default GetDealingToken;
