// @flow
import React, {Component, Fragment} from 'react';
import {debounce} from 'lodash'
import Recaptcha from 'react-recaptcha';
import moment from 'moment';
import {connect} from 'react-redux';
import classnames from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import {Scrollbars} from 'react-custom-scrollbars';
import DatePicker from '../../../../../vendor/react-dropdowns-datepicker';
import InputFile from '../../Tickets/component/InputFile';
import ReactSelectWrapper from 'infrastructure/view/component/ReactSelectWrapper/ReactSelectWrapper';
import parseTranslation from '../../../../adapter/helper/parseTranslation';
import {dropFormErrors} from '../../../services/action/forms';
import {getMathCaptcha} from '../../Login/service/actions';

const mapStateToProps = state => {
    const {translation} = state.translation;
    const form = state.forms['ListingForm'] || {};
    const {mathCaptchaUrl} = state.forms;
    return {
        translation,
        form,
        mathCaptchaUrl,
        createTicketQuestionRequirements: parseTranslation(
            'create-ticket-question-requirements'
        ),
        verificationFormPlease: parseTranslation('verification-form-please'),
        questionFormFileSizeError: parseTranslation('question-form-fileSizeError'),
        questionFormFileLimitError: parseTranslation('question-form-fileLimitError'),
    };
};

const customStyles = {
    valueContainer: provided => ({
        ...provided,
        textOverflow: 'ellipsis',
        maxWidth: '90%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'initial',
    }),
};

class FormInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            className: '',
            valueDynamicInput: 1,
            checkboxList: [],
            value: props.value,
        };

        this.handleInput = this.handleInput.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.addInput = this.addInput.bind(this);
        this.onChangeDynamicInput = this.onChangeDynamicInput.bind(this);
        this.handleChangeChecked = this.handleChangeChecked.bind(this);
        this.onChangeTextarea = this.onChangeTextarea.bind(this);
    }

    componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
        if (prevProps.value !== this.props.value) {
            this.setState({value: this.props.value || ''});
        }
        if (prevState.value !== this.state.value) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => this.props.onChange(this.state.value), 300);
        }
    }
    componentDidMount(): * {
        if (this.props.type === 'mathcaptcha') {
            const {captchaUrl} = this.props.options;
            this.props.getMathCaptcha(captchaUrl);
        }
    }

    handleChangeChecked(e, index, elValue) {
        const value = Boolean(+e.target.value);
        let arr = [].concat(this.props.value);

        if (!value) {
            if (elValue === 'no') {
                arr = [elValue];
            } else {
                arr.push(elValue);
                if (arr.some(el => el === 'no')) {
                    arr = arr.filter(el => el !== 'no');
                }
            }
        } else {
            arr = arr.filter(el => el !== elValue);
        }

        this.props.onChange(arr);
    }

    onChangeDynamicInput(e, i) {
        const value = e.target.value;
        const valueArr = [].concat(this.props.value);
        valueArr[i] = value;
        this.setState({value: valueArr});
    }

    handleInput(e) {
        if (!this.props.animatePlaceholder || e.target.value !== '') {
            return;
        }
        this.setState({className: 'on'});
    }

    handleBlur(e) {
        if (!this.props.animatePlaceholder || e.target.value !== '') {
            return;
        }

        this.setState({className: ''});
    }

    addInput() {
        this.setState({valueDynamicInput: this.state.valueDynamicInput + 1});
    }

    handleChange(e, idx) {
        const {limit} = this.state;

        this.setState({
            items: this.state.items,
            limit: {
                ...limit,
                [idx]: e.target.value,
            },
        });
    }

    onChangeField = e => {
        this.setState({passValue: e.target.value});
        this.setState({value: e.target.value});
        // this.props.onChange(e.target.value);
    };

    onChangeNumericField = ({target: {value}}) => {
        const price = value.replace(',', '.');
        const regex = /^\d*\.?\d{0,8}$/g;
        let match = regex.exec(price);
        if (match && match.shift().length <= 18) {
            this.setState({value: price});
            this.props.onChange(price);
        }
        // this.setState({value: value.replace(/,/g, '.')});

    };

    render() {
        return this.renderByType();
    }

    renderByType() {
        const {scheme} = this.props.form;
        const {name, type} = this.props;

        if (name === 'date_of_birth') {
            return this.renderTypeDatepicker();
        }

        switch (type) {
            case 'input': {
                const {name} = this.props;
                return this.renderTypeInput(name);
            }
            case 'mathcaptcha': {
                const {name} = this.props;
                return this.renderTypeMathCaptcha(name);
            }

            case 'dynamic_input': {
                const {name, options} = this.props;
                return this.renderTypeDynamicInput(
                    name,
                    options.limit,
                    scheme[name].value
                );
            }

            case 'checkbox_list': {
                const {name, options, value} = this.props;
                return this.renderTypeCheckbox(name, options.items, value);
            }

            case 'textarea': {
                const {name, options, error} = this.props;
                if (
                    scheme &&
                    !scheme.marketing_services.value.includes('proposal')
                ) {
                    if (name === 'proposal_text') {
                        return null;
                    }
                }

                return this.renderTypeTextarea(name, error, options);
            }

            case 'password': {
                const {name} = this.props;
                return this.renderTypeInput(name, 'password');
            }

            case 'hidden': {
                return null;
            }

            case 'select': {
                const {name, options, value} = this.props;
                return this.renderTypeSelect(name, options.items, value);
            }

            case 'multi_select': {
                const {name, options, value, error} = this.props;
                return this.renderTypeMultiSelect(name, options, value, error);
            }

            case 'captcha': {
                const {name, options} = this.props;
                return this.renderTypeCaptcha(name, options);
            }

            case 'file': {
                const {name, options, typeFileParams, error, allErrors, dropFormErrors} = this.props;

                return this.renderTypeFile(
                    name,
                    options,
                    typeFileParams,
                    error,
                    allErrors,
                    dropFormErrors,
                );
            }

            default:
                break;
        }

        return null;
    }

    renderTypeInput(name, type = 'text') {
        const {value, options, animatePlaceholder, placeholder} = this.props;
        const requiredSymbol = options.required ? '*' : '';
        if (name === 'recipient_amount' || name === 'amount') {
            return (
                <div className="floatingLabelInput">
                    {localStorage.getItem('userRegion') &&
                    <label
                        className={classnames(this.state.className, {
                            top: options.description,
                        })}
                        htmlFor={name}
                    >
                        {this.props.label + requiredSymbol}

                        {options.description && (
                            <p
                                className={classnames('description', {
                                    none: !options.description === true,
                                })}
                            >
                                {options.description}
                            </p>
                        )}
                    </label>
                    }
                    <div className="input-wrapper">
                        <input
                            className={`${this.state.value && 'filled'} form-input number`}
                            id={name}
                            name={name}
                            type="text"
                            value={this.state.value}
                            onChange={this.onChangeNumericField}
                            disabled={this.props.disabled}
                            placeholder={
                                animatePlaceholder ? '' : parseTranslation(placeholder)
                            }
                            onFocus={this.handleInput}
                            onBlur={this.handleBlur}
                        />
                    </div>
                    {this.props.children}
                </div>
            )
        }
        return (
            <div className="floatingLabelInput">
                {localStorage.getItem('userRegion') &&
                <label
                    className={classnames(this.state.className, {
                        top: options.description,
                    })}
                    htmlFor={name}
                >
                    {this.props.label + requiredSymbol}

                    {options.description && (
                        <p
                            className={classnames('description', {
                                none: !options.description === true,
                            })}
                        >
                            {options.description}
                        </p>
                    )}
                </label>
                }
                <div className="input-wrapper">
                    {type === 'password' && this.state.passValue ? (
                        <i
                            className="icon icon-hide show-password"
                            onClick={e => {
                                const self = e.target;
                                const input = e.target.nextSibling;
                                if (input.getAttribute('type') === 'password') {
                                    self.classList.remove('icon-hide');
                                    self.classList.add('icon-look');
                                    input.setAttribute('type', 'text');
                                } else {
                                    self.classList.add('icon-hide');
                                    self.classList.remove('icon-look');
                                    input.setAttribute('type', 'password');
                                }
                            }}
                        />
                    ) : (
                        ''
                    )}

                    <input
                        // onChangeCapture={this.handleInput}
                        className={`${this.state.value && 'filled'} form-input`}
                        id={name}
                        name={name}
                        type={type}
                        value={this.state.value}
                        onChange={this.onChangeField}
                        disabled={this.props.disabled}
                        placeholder={
                            animatePlaceholder ? '' : parseTranslation(placeholder)
                        }
                        onFocus={this.handleInput}
                        onBlur={this.handleBlur}
                    />
                </div>
                {!localStorage.getItem('userRegion') &&
                    <label
                        className={classnames(this.state.className, {
                            top: options.description,
                        })}
                        htmlFor={name}
                    >
                        {this.props.label + requiredSymbol}

                        {options.description && (
                            <p
                                className={classnames('description', {
                                    none: !options.description === true,
                                })}
                            >
                                {options.description}
                            </p>
                        )}
                    </label>
                }

                {this.props.children}
            </div>
        );
    }

    renderTypeMathCaptcha(name, type = 'number') {
        const {value, options, animatePlaceholder, placeholder} = this.props;
        const requiredSymbol = options.required ? '*' : '';

        const arrayBufferToBase64 = (buffer) => {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        };
        // console.log(arrayBufferToBase64(this.props.mathCaptchaUrl));
        return (
            <div className="floatingLabelInput">
                {localStorage.getItem('userRegion') &&
                <label
                    className={classnames(this.state.className, {
                        top: options.description,
                    })}
                    htmlFor={name}
                >
                    {this.props.label + requiredSymbol}

                    {options.description && (
                        <p
                            className={classnames('description', {
                                none: !options.description === true,
                            })}
                        >
                            {options.description}
                        </p>
                    )}
                </label>
                }
                {type === 'password' && this.state.passValue ? (
                    <i
                        className="icon icon-hide show-password"
                        onClick={e => {
                            const self = e.target;
                            const input = e.target.nextSibling;
                            if (input.getAttribute('type') === 'password') {
                                self.classList.remove('icon-hide');
                                self.classList.add('icon-look');
                                input.setAttribute('type', 'text');
                            } else {
                                self.classList.add('icon-hide');
                                self.classList.remove('icon-look');
                                input.setAttribute('type', 'password');
                            }
                        }}
                    />
                ) : (
                    ''
                )}
                <div className="input-wrapper">
                <input
                    // onChangeCapture={this.handleInput}
                    className={`${this.state.value && 'filled'} form-input captcha-input`}
                    id={name}
                    name={name}
                    type={type}
                    value={value === null ? '' : +this.state.value}
                    onChange={this.onChangeField}
                    disabled={this.props.disabled}
                    placeholder={
                        animatePlaceholder ? '' : parseTranslation(placeholder)
                    }
                    onFocus={this.handleInput}
                    onBlur={this.handleBlur}
                />
                </div>
                {!localStorage.getItem('userRegion') &&
                <label
                    className={classnames(this.state.className, {
                        top: options.description,
                    })}
                    htmlFor={name}
                >
                    {this.props.label + requiredSymbol}

                    {options.description && (
                        <p
                            className={classnames('description', {
                                none: !options.description === true,
                            })}
                        >
                            {options.description}
                        </p>
                    )}
                </label>
                }
                {this.props.mathCaptchaUrl && <img className="captcha-img" src={`data:image/png;base64,${this.props.mathCaptchaUrl}`} alt=""/>}
                {this.props.children}
            </div>
        );
    }

    renderTypeDynamicInput(name, type = 'text', valueField) {
        const {value, options} = this.props;
        const requiredSymbol = options.required ? '*' : '';
        const inputArray = [];

        for (let i = 0; i < options.limit; i++) {
            if (i >= this.state.valueDynamicInput) {
                break;
            }

            inputArray.push(
                <div key={i}>
                    <label className={this.state.className}>
                        {this.props.label + requiredSymbol}
                    </label>
                    <input
                        name={name + i}
                        type={type}
                        value={this.state.value && this.state.value[i] ? this.state.value[i] : ''}
                        disabled={this.props.disabled}
                        placeholder={this.props.placeholder}
                        onChange={e => this.onChangeDynamicInput(e, i)}
                    />
                </div>
            );
        }

        return (
            <div>
                <div className={classnames('floatingLabelInput dynamic-input')}>
                    {inputArray}
                    {this.props.children}
                </div>
                {this.state.valueDynamicInput < options.limit && (
                    <div
                        className={classnames('add-input-btn', {
                            'btn-disable':
                                valueField.length === 0 ||
                                valueField.length ===
                                    this.state.valueDynamicInput - 1 ||
                                valueField.includes(''),
                        })}
                        onClick={this.addInput}
                    >
                        {/*TODO: implement translation*/}
                        {/*Add link*/}
                        <i className="icon-plus"></i>
                    </div>
                )}
            </div>
        );
    }

    renderTypeCheckbox() {
        const {value, options} = this.props;
        const checkboxList = options.items;

        return (
            <div className="floatingLabelInput checkbox">
                <ul>
                    {checkboxList.map((el, index) => (
                        <li key={index}>
                            <div className="check-listing">
                                <input
                                    className="icon-check-mark"
                                    key={el.value}
                                    type="checkbox"
                                    name={el.value}
                                    checked={
                                        value.includes('no') &&
                                        el.value !== 'no'
                                            ? false
                                            : value.includes(el.value)
                                    }
                                    value={value.includes(el.value) ? 1 : 0}
                                    onChange={e =>
                                        this.handleChangeChecked(
                                            e,
                                            index,
                                            el.value
                                        )
                                    }
                                />
                            </div>
                            <label>{el.label}</label>
                        </li>
                    ))}
                </ul>
                {this.props.children}
            </div>
        );
    }

    renderTypeTextarea(name) {
        const {options} = this.props;
        const requiredSymbol = options.required ? '*' : '';
        let maxLength = null;

        // switch (name) {
        //     case 'proposal_text':
        //         maxLength = 500;
        //     default:
        //         maxLength = 2000;
        // }

        if (name === 'proposal_text') {
            maxLength = 500;
        } else {
            maxLength = 2000;
        }

        return (
            <div className="floatingLabelInput">
                {name !== 'proposal_text' && (
                    <label className={this.state.className + 'hidden-label'}>
                        {parseTranslation(this.props.label + requiredSymbol)}
                    </label>
                )}
                <Scrollbars
                    className="scroll textarea-scroll"
                    autoHeight
                    autoHeightMax="16rem"
                    renderTrackVertical={p => (
                        <div {...p} className="srollbar-container" />
                    )}
                    renderThumbVertical={p => (
                        <div {...p} className="srollbar-thumb" />
                    )}
                >
                    <TextareaAutosize
                        className="listing-textarea"
                        onChangeCapture={this.handleInput}
                        name={name}
                        onChange={this.onChangeTextarea}
                        placeholder={this.props.placeholder}
                        maxLength={maxLength}
                        value={this.state.value}
                    />
                </Scrollbars>
                {this.props.children}
            </div>
        );
    }

    onChangeTextarea(e) {
        const {value} = e.target;
        // this.props.onChange(value);
        this.setState({value: value});
    }

    renderTypeFile(name, options, typeFileParams, error, allErrors, dropFormErrors) {
        const {questionFormFileSizeError, questionFormFileLimitError, formName} = this.props;

        return (
            <div
                style={{
                    fontSize: '2.3rem',
                    fontWeight: '400',
                    color: '#999999',
                }}
            >
                {!!this.props.name &&
                    this.props.name === 'VerificationForm' && (
                        <p>{this.props.verificationFormPlease}</p>
                    )}

                {!!this.props.name && this.props.name === 'QuestionForm' && (
                    <p>{this.props.createTicketQuestionRequirements}</p>
                )}

                <InputFile
                    name={name}
                    className={typeFileParams.className}
                    previewFile={typeFileParams.previewFile}
                    handleChange={this.props.onChange}
                    error={error}
                    allErrors={allErrors}
                    limitErrorMessage={questionFormFileLimitError}
                    sizeErrorMessage={questionFormFileSizeError}
                    dropFormErrors={dropFormErrors}
                    formName={formName}
                />
                {this.props.children}
            </div>
        );
    }

    selectDefaultValue(options, value) {
        try {
            if (typeof value === 'number') {
                return options.filter(el => +el.value === +value)[0];
            }
            else {
                return options.filter(el => el.value === value)[0];
            }
        } catch (e) {
            return null;
        }
    }
    handleChangeSelect = value => this.props.onChange(value.value);
    renderTypeSelect(name, options) {
        const {selectTransparent, selectHeight, value, disabled, isSort} = this.props;
        const requiredSymbol = options.required ? '*' : '';

        let optionsElements = null;

        if (options) {
            optionsElements = Object.keys(options).reduce((result, key) => {
                const row = {label: options[key], value: key};
                result.push(row);
                return result;
            }, []);
            if(isSort) {
                optionsElements = [...optionsElements.sort((a, b) => a.label > b.label ? 1 : -1)];
            }
        }

        return (
            <div className="select">
                <label className={this.state.className}>
                    {parseTranslation(this.props.label) + requiredSymbol}
                </label>

                <ReactSelectWrapper
                    name={name}
                    value={this.selectDefaultValue(
                        optionsElements,
                        value
                    ) || {label: '', value: ''}}
                    selectTransparent={selectTransparent}
                    closeMenuOnSelect
                    options={optionsElements}
                    disabled={disabled}
                    handleChange={this.handleChangeSelect}
                    menuListHeight={selectHeight}
                />
                {name === 'currency' && disabled && options && options.length < 1 &&
                    <div className="no-money">{parseTranslation('no-funds-to-withdraw')}</div>
                }
                {this.props.children}
            </div>
        );
    }

    renderTypeMultiSelect(name, options, value, error) {
        const requiredSymbol = options.required ? '*' : '';

        const optionsElements = Object.keys(options.items).reduce(
            (result, key) => {
                const row = {label: options.items[key], value: key};
                result.push(row);
                return result;
            },
            []
        );

        return (
            <div className="floatingLabelInput">
                <label className={this.state.className}>
                    {this.props.label + requiredSymbol}
                </label>
                <ReactSelectWrapper
                    name={name}
                    closeMenuOnSelect={false}
                    menuListHeight={150}
                    isMulti
                    sizeBig
                    styles={customStyles}
                    options={optionsElements}
                    hideSelectedOptions={false}
                    backspaceRemovesValue={false}
                    error={error}
                    placeholder="-"
                    onChange={arr =>
                        this.props.onChange(
                            arr.reduce((result, entry, index) => {
                                result[index] = entry.value;
                                return result;
                            }, {})
                        )
                    }
                />
                {this.props.children}
            </div>
        );
    }

    renderTypeCaptcha(name, options) {
        return (
            <Recaptcha
                ref={this.props.captchaRefCallback}
                theme="dark"
                sitekey={options.reCaptchaKey}
                render="explicit"
                verifyCallback={this.props.onChange}
                // hl="en" // TODO: check if we need this
            />
        );
    }

    renderTypeDatepicker() {
        return (
            <Fragment>
                <label className={this.state.className}>
                    {parseTranslation(this.props.label)}
                </label>

                <DatePicker
                    className="date-user"
                    dateChange={date => {
                        const m = moment(date);
                        const offset = m.utcOffset();
                        const timestamp = m.add(offset, 'minute').unix();
                        document.querySelector(
                            '.date-picker--value'
                        ).dataset.time = timestamp;
                        this.props.onChange(timestamp);
                    }}
                    disabled={this.props.disabled}
                />
                {this.props.children}
            </Fragment>
        );
    }
}

const mapDispatchToProps = {
    getMathCaptcha,
    dropFormErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(FormInput);
