import React, {PureComponent} from 'react';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import connect from 'react-redux/es/connect/connect';
import Logo from './logo.svg';

import parseTranslation from '../../../../adapter/helper/parseTranslation';
import Error from "../../../component/Error";
import ErrorContainer from "../../../component/Error/ErrorContainer";

function mapStateToProps(state) {
    return {
        socketError: state.socket.error,
        labelTittleBankWire: parseTranslation('message-bank-wire-tittle'),
        labelMessageBankWire: parseTranslation('message-bank-wire'),
    };
}

class BankWireItem extends PureComponent {
    render() {
        const {coin, name, total, available, order, btc} = this.props;

        return (
            <tr className="ban-wire__item">
                <td style={{position: 'relative'}}>
                    <i className={`icon-currency icon-${coin.toLowerCase()}`} />
                    {coin}
                </td>
                <td className="ban-wire__name">{name}</td>
                <td>{total}</td>
                <td>{available}</td>
                <td>{order}</td>
                <td>{btc}</td>
            </tr>
        );
    }
}

class BankWire extends PureComponent {
    render() {
        const {balances, filter, socketError} = this.props;

        const balancesKeys = Object.keys(balances);

        if (!balancesKeys.length && !socketError) {
            return (
                <div className="not-purse">
                    <h2>
                        {this.props.labelTittleBankWire}
                        <p>{this.props.labelMessageBankWire}</p>
                    </h2>
                    <div className="wrap-logo">
                        <img src={Logo} alt="logo" />
                    </div>
                </div>
            );
        }

        return (
            <div
                style={{
                    position: 'absolute',
                    top: '13rem',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                }}
            >
                <Scrollbars>
                    {socketError && <ErrorContainer />}
                    <table>
                        <tbody style={{padding: '2px 0'}}>
                            {balancesKeys.reduce(
                                (result, coin, index) => {
                                    if (
                                        coin.toLowerCase().includes(filter) ||
                                        balances[coin].name
                                            .toLowerCase()
                                            .includes(filter)
                                    ) {
                                        const element = (
                                            <BankWireItem
                                                key={index}
                                                coin={coin}
                                                name={balances[coin].name}
                                                total={balances[coin].total}
                                                available={
                                                    balances[coin].available
                                                }
                                                order={balances[coin].order}
                                                btc={balances[coin].btc}
                                            />
                                        );

                                        result.push(element);
                                    }

                                    return result;
                                },
                                []
                            )}
                        </tbody>
                    </table>
                </Scrollbars>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    null
)(BankWire);
