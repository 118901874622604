//  @flow
import React, {PureComponent} from 'react';
import Files from 'react-files';

type PropTypes = {
    className?: string,
    name: string,
    previewFile: () => mixed,
    error?: boolean,
};

class InputFile extends PureComponent<PropTypes> {
    state = {
        files: [],
        isTooLargeFile: false,
        maxFiles: false,
        errorsCount: 0,
    };

    filenameExtension: Array<string> = ['image/jpg', 'image/jpeg', 'image/png'];

    constructor(props) {
        super(props);
        this.onFilesChange = this.onFilesChange.bind(this);
        this.filesRef = React.createRef();
    }

    componentDidUpdate(prevProps: Props, prevState) {
        const {files} = this.state;
        const {allErrors, dropFormErrors, formName} = this.props;

        if (prevState.files.length !== files.length) {
            dropFormErrors(formName, ['passport_files']);
        }

        if (
            allErrors &&
            Object.keys(allErrors).length !==
                Object.keys(prevProps.allErrors).length &&
            Object.keys(allErrors).length !== 0
        ) {
            this.filesRef.current.removeFiles();
        }
    }

    onFilesChange = files => {
        this.props.handleChange(files);
        this.setState({
            files,
            isTooLargeFile: false,
            maxFiles: false,
        });
    };

    onFilesError = error => {
        if (error.code === 2) {
            setTimeout(() => this.setState({isTooLargeFile: true}), 0);
        } else if (error.code === 4) {
            setTimeout(() => this.setState({maxFiles: true}), 0);
        }
    };

    onPreviewFile(file, e: Event): void {
        e.stopPropagation();
        this.props.previewFile(file);
    }

    onRemoveFile(file, e: Event): void {
        e.stopPropagation();
        this.filesRef.current.removeFile(file);
    }

    render() {
        const {isTooLargeFile, maxFiles} = this.state;
        const {
            className,
            name,
            limitErrorMessage,
            sizeErrorMessage,
        } = this.props;
        const maxSize = 2097152;

        return (
            <div className={className}>
                <Files
                    ref={this.filesRef}
                    className={'files__dropzone'}
                    onChange={this.onFilesChange}
                    onError={this.onFilesError}
                    accepts={this.filenameExtension}
                    multiple
                    maxFiles={10}
                    maxFileSize={maxSize}
                    clickable
                    name={name}
                >
                    <div
                        className={`files__plus files__item ${
                            maxFiles || isTooLargeFile ? 'error' : ''
                        }`}
                    />
                    {this.state.files.map((file, index) => (
                        <div className="files__preview files__item" key={index}>
                            <button
                                className={
                                    'files__control-btn files__control-btn_preview'
                                }
                                type={'button'}
                                onClick={this.onPreviewFile.bind(this, file)}
                            >
                                <i className="icon show-password icon-look" />
                            </button>
                            <button
                                className={
                                    'files__control-btn files__control-btn_remove'
                                }
                                type={'button'}
                                onClick={this.onRemoveFile.bind(this, file)}
                            >
                                <i className="icon-chart-delete" />
                            </button>
                            <img src={file.preview.url} alt={'preview'} />
                        </div>
                    ))}
                </Files>
                {isTooLargeFile && (
                    <span className="files__dropzone__limit">
                        {sizeErrorMessage}
                    </span>
                )}
                <br />
                {maxFiles && (
                    <span className="files__dropzone__limit">
                        {limitErrorMessage}
                    </span>
                )}
            </div>
        );
    }
}

export default InputFile;
