import {ActionTypes} from './actions';
import {LayoutActionTypes} from '../components/Header/component/LayoutModal/service/actions';

import {setActiveDashboardIndex} from './actions';
import {setNews, errorNews} from '../components/News/service/actions';
import ReadRSSFeed from '../../../../../application/use-case/ReadRSSFeed';

const readRSSFeed = new ReadRSSFeed();
let rssReaderInterval;

export default function(store) {
    return function(next) {
        return async function(action) {
            next(action);

            if (action.type === LayoutActionTypes.setActiveDashboardIndex) {
                const {activeDashboardIndex} = action;

                store.dispatch(setActiveDashboardIndex(activeDashboardIndex));
            }

            if (action.type === ActionTypes.startRSSFeedReader) {
                startRSSFeedReader(store);
            }

            if (action.type === ActionTypes.stopRSSFeedReader) {
                stopRSSFeedReader();
            }
        };
    };
}

function startRSSFeedReader(store) {
    readRssFeed(store);

    rssReaderInterval = setInterval(() => readRssFeed(store), 60000);
}

async function stopRSSFeedReader() {
    clearInterval(rssReaderInterval);
}

async function readRssFeed(store) {
    try {
        const news = await readRSSFeed.invoke();

        news.sort((a, b) => (a.date.isBefore(b.date) ? 1 : -1));

        store.dispatch(setNews(news));
    } catch (e) {
        store.dispatch(errorNews(e));
    }
}
