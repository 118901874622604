import {successAction} from '../actions/actionTypes';
import {SET_CURRENCY_PAIR, SET_PREV_CURRENCY_PAIR, SET_CURRENCY_PAIR_ALIAS} from './actions';
import {GET_GUEST_API_URL} from '../GuestDashboard/actions';
const initialState = {
    asset: '',
    prevAsset: '',
    alias: false,
};

export default function currencyPair(state = initialState, action) {
    switch (action.type) {
        case successAction(GET_GUEST_API_URL): {
            const {data} = action.response;
            const questSettings = JSON.parse(localStorage.getItem('guestSettings'));
            return {
                ...state,
                asset: state.alias || (questSettings && questSettings.pair) || data.asset,
                prevAsset: state.alias || (questSettings && questSettings.pair) || data.asset,
            };
        }
        case SET_CURRENCY_PAIR: {
            const {value} = action;
            return {
                ...state,
                asset: value,
            };
        }
        case SET_CURRENCY_PAIR_ALIAS: {
            const {value} = action;
            return {
                ...state,
                alias: value,
            };
        }
        case SET_PREV_CURRENCY_PAIR: {
            const {value} = action;
            return {
                ...state,
                prevAsset: value,
            };
        }
        default:
            return state;
    }
}
