export const ActionType = {
    getSettingsData: 'settings.data.get',
    getSettingsDataFailure: 'settings.data.get.failure',
    sendSettingsData: 'settings.data.send',
    setSettingsData: 'settings.data.set',
    setLocale: 'settings.locale.set',
    setDashboardLayout: 'settings.dashboard-layout.set',
    setChartLayout: 'settings.chart-layout.set',
    setChartInterval: 'settings.chart-interval.set',
    setChartType: 'settings.chart-type.set',
    setSelectedSymbol: 'settings.selected-symbol.set',
    setChartOffset: 'settings.chart.offset',
    setChartSelectedSymbol: 'settings.chart-selected-symbol.set',
    setFavoriteCurrency: 'settings.favorite-currency.set',
    loadingFinished: 'settings.loading.finished',
    ON_SOUND: 'set.sound.on',
    ON_SAVE_TRADIN_VIEW: 'ON_SAVE_TRADIN_VIEW'
};

export function loadingFinished() {
    return {
        type: ActionType.loadingFinished,
    };
}
export function getSettingsData(sitePair) {
    return {
        type: ActionType.getSettingsData,
        sitePair,
    };
}

export function sendSettingsData() {
    return {
        type: ActionType.sendSettingsData,
    };
}

export function saveTradingView(saveData) {
    return {
        type: ActionType.ON_SAVE_TRADIN_VIEW,
        payload: saveData,
    };
}

export function setSettingsData(data) {
    return {
        type: ActionType.setSettingsData,
        data,
    };
}

export function setLocale(locale) {
    return {
        type: ActionType.setLocale,
        locale,
    };
}

export function setDashboardLayout(layout) {
    return {
        type: ActionType.setDashboardLayout,
        layout,
    };
}

export function setChartOffset(payload) {
    return {
        type: ActionType.setChartOffset,
        payload,
    };
}

export function setChartLayout(layout) {
    return {
        type: ActionType.setChartLayout,
        layout,
    };
}

export function setChartInterval(id, interval) {
    return {
        type: ActionType.setChartInterval,
        id,
        interval,
    };
}

export function setChartType(id, chartType) {
    return {
        type: ActionType.setChartType,
        id,
        chartType,
    };
}

export function setSelectedSymbol(selectedSymbol) {
    return {
        type: ActionType.setSelectedSymbol,
        selectedSymbol,
    };
}

export function setChartSelectedSymbol(id, selectedSymbol) {
    return {
        type: ActionType.setChartSelectedSymbol,
        id,
        selectedSymbol,
    };
}

export function setFavoriteCurrency(favoriteCurrency) {
    return {
        type: ActionType.setFavoriteCurrency,
        favoriteCurrency,
    };
}

export function getSettingsDataFailure(payload) {
    return {
        type: ActionType.getSettingsDataFailure,
        payload,
    };
}

export function onSound() {
    return{
        type: ActionType.ON_SOUND,
    }
}
