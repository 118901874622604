import {createSelector} from 'reselect';

const symbolsSelector = state => state.symbols.symbols;
const selectedSymbolNameSelector = state => state.settings.selectedSymbol.name;

export const limitForSelectedSymbol  = createSelector(
    symbolsSelector,
    selectedSymbolNameSelector,
    (symbols, selectedSymbol) => {
        if (symbols.hasOwnProperty(selectedSymbol)) {
            return symbols[selectedSymbol].limit;
        }

        return 0;
    }
);
