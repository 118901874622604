// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {SettingsInterface as SettingsAdapter} from '../../../application/adapter/Settings';

class GetSettings {
    adapter: SettingsAdapter;

    constructor(adapter: SettingsAdapter) {
        this.adapter = adapter;
    }

    async invoke(token: AccessToken) {
        try {
            return await this.adapter.getSettings(token);
        } catch (e) {
            return e;
        }
    }
}

export default GetSettings;
