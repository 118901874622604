import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {open as openSymbolsModal} from '../../scene/Dashboard/components/Header/component/SymbolsModal/service/actions';

import {setSelectedSymbol} from '../../services/action/user';

function mapStateToProps(state) {
    const {selectedSymbol} = state.settings;

    return {
        ...selectedSymbol,
    };
}

const mapDispatchToProps = {
    openSymbolsModal,
    setSelectedSymbol,
};
// TODO: Accept callback which will be invoked on Asset selection
class CurrencyPair extends Component {
    constructor() {
        super();

        this.openSymbolsModal = this.openSymbolsModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.name !== prevProps.name) {
            const [numerator, denominator] = this.props.name.split('/');
            this.props.setSelectedSymbol({numerator, denominator});
        }
        // const numerator = this.props.numerator;
        // const denominator = this.props.denominator;
    }

    render() {
        return (
            <Fragment>
                <button
                    className="currency-pair--tools currency-pair--tools-light-grey"
                    onClick={this.openSymbolsModal}
                >
                    <i
                        className={`icon-currency icon-${this.props.numerator.toLowerCase()}`}
                    />
                    <span className="name-currency">{this.props.name}</span>
                    <i
                        className={`icon-currency icon-${this.props.denominator.toLowerCase()}`}
                    />
                </button>
            </Fragment>
        );
    }

    openSymbolsModal() {
        this.props.openSymbolsModal();
    }
}

const ConnectedCurrencyPair = connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrencyPair);
export default ConnectedCurrencyPair;
