export const ActionTypes = {
    setActiveLayoutIndex: 'dashboard-scene.chart-container-component.layout.set-index'
};

export function setActiveLayoutIndex(activeLayoutIndex) {
    return {
        type: ActionTypes.setActiveLayoutIndex,
        activeLayoutIndex
    };
}
