import type Axios from 'axios';

import localConfig from 'localConfig';
import {getDomain} from 'utils';

import type {SessionInterface} from '../../application/adapter/Session';

class SessionSettings implements SessionInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getSession(token: string = ''): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/user/refresh`,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
            },
        });
    }
}

export default SessionSettings;
