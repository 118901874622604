import React, {Component, PureComponent} from 'react';
import {connect} from 'react-redux';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import TextareaAutosize from 'react-textarea-autosize';

import {
    replyToTicket,
    getTicketsHistory,
    fetchTicket,
    getTicketVerificationImageRequest,
    clearArrayTicketsVerificationImage,
} from '../../scene/Tickets/service/actions';
import {
    openPreviewImageModal,
    closePreviewImageModal,
} from 'infrastructure/view/services/action/modal';
import ChatListItem from './components/ChatListItem';
import PreviewImageModal from './components/PreviewImageModal';
import VerificationImages from './components/VerificationImages';
import QuestionImages from './components/QuestionImages';


import parseTranslation from '../../../adapter/helper/parseTranslation';

function mapStateToProps(state) {
    const {
        selectedTicket: {replies, additionalInfo, type},
        verificationImages,
    } = state.tickets;
    const {info} = state.user;
    const {isOpenImagePreview} = state.modal;

    const {username, avatar} = state.user.info;

    return {
        additionalInfo,
        replies,
        username,
        avatar,
        info,
        type,
        verificationImages,
        isOpenImagePreview,
        labelSend: parseTranslation('label-send'),
        labelAddComment: parseTranslation('label-add-comment'),
        buttonLabel: parseTranslation('ticket-chat-label'),
        supportManager: parseTranslation('support-manager'),
        supportManagerRes: parseTranslation('support-manager-response'),
        ticketChatPlaceholder: parseTranslation('ticket-chat-placeholder'),
    };
}

const mapDispatchToProps = {
    replyToTicket,
    getTicketsHistory,
    fetchTicket,
    getTicketVerificationImageRequest,
    openPreviewImageModal,
    closePreviewImageModal,
    clearArrayTicketsVerificationImage,
};

export class Modal extends Component {
    render() {
        const {title, image, isOpen, closeHandler} = this.props;
        return (
            <div
                className={`ticket-image-modal ticket-image-modal-show-${isOpen}`}
            >
                <div className="ticket-image-modal__header">
                    <div className="ticket-image-modal__title">{title}</div>
                    <button onClick={() => closeHandler()} type={'button'}>
                        <i className="icon-close" />
                    </button>
                </div>
                <div className="ticket-image-modal__wrap">
                    <img
                        src={image}
                        className="ticket-image-modal__image"
                        alt={'preview'}
                    />
                </div>
            </div>
        );
    }
}

// TODO: rename directory to TicketsChat
class Chat extends Component {
    state = {
        message: '',
        modal: {
            isOpen: false,
            title: '',
            image: '',
        },
        initialHeight: 120,
        currentHeight: 110,
        selectedImageUrl: null,
    };

    constructor(props) {
        super(props);
        this.replyByEnter = this.replyByEnter.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.scrollBarRef = React.createRef();
    }

    componentDidMount(): void {
        const {
            additionalInfo: {attachments},
            getTicketVerificationImageRequest,
            clearArrayTicketsVerificationImage,
            type,
        } = this.props;

        if (
            attachments &&
            attachments.length
        ) {
            clearArrayTicketsVerificationImage();

            attachments.forEach(item => {
                if (type === 'verification') {
                    getTicketVerificationImageRequest(this.getImageCode(item));
                }
            });
        }
        this.scrollBarRef.current.scrollToBottom();
    }

    componentDidUpdate(prevProps) {
        const {
            additionalInfo: {attachments},
            getTicketVerificationImageRequest,
            ticketId,
            clearArrayTicketsVerificationImage,
            type,
            replies,
        } = this.props;
        if (prevProps.replies !== replies) {
            this.scrollBarRef.current.scrollToBottom();
        }
        if (
            ticketId !== prevProps.ticketId &&
            attachments &&
            attachments.length
        ) {
            clearArrayTicketsVerificationImage();
            type === 'verification' && getTicketVerificationImageRequest(attachments);
        }
    }

    componentWillUnmount() {
        const {clearArrayTicketsVerificationImage} = this.props;

        clearArrayTicketsVerificationImage();
    }

    getImageCode(string) {
        const splitArr = string.split('/');
        return splitArr[splitArr.length - 1];
    }

    showModal(title, image) {
        this.setState({modal: {isOpen: true, title, image}});
    }

    closeModal() {
        this.setState({modal: {isOpen: false}});
    }

    replyByEnter(e) {
        if (e.which === 13 && e.ctrlKey) {
            this.reply();
        }
    }

    reply() {
        this.props.replyToTicket(this.props.ticketId, this.state.message);
        setTimeout(() => {
            this.props.fetchTicket(this.props.ticketId);
        }, 500);
        this.setState({message: ''});
    }
    onOpenPreviewImage = imagePreviewUrl => {
        const {openPreviewImageModal} = this.props;
        openPreviewImageModal();

        this.setState({selectedImageUrl: imagePreviewUrl});
    };

    render() {
        const {selectedImageUrl} = this.state;
        const {
            title,
            status,
            type,
            replies,
            updatedAt,
            username: currentUser,
            avatar,
            additionalInfo,
            ticketChatPlaceholder,
            verificationImages,
            closePreviewImageModal,
            isOpenImagePreview,
        } = this.props;

        return (
            <div className="wide">
                <h4>
                    {title}
                    {status}
                </h4>

                <div className="message-chat">
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                        }}
                    >
                        <Scrollbars refScroll={this.scrollBarRef}>
                            <ul>
                                <ChatListItem
                                    isFirstMessage
                                    type={type}
                                    reply={{}}
                                    currentUser={currentUser}
                                    avatar={avatar}
                                    additionalInfo={additionalInfo}
                                    updatedAt={updatedAt}
                                />
                                {type === 'question' &&
                                additionalInfo.attachments && (
                                    <QuestionImages
                                        questionImages={
                                            additionalInfo.attachments
                                        }
                                        onOpenPreviewImage={
                                            this.onOpenPreviewImage
                                        }
                                    />
                                )}
                                {type === 'verification' &&
                                !!verificationImages.length && (
                                    <VerificationImages
                                        verificationImages={
                                            verificationImages
                                        }
                                        onOpenPreviewImage={
                                            this.onOpenPreviewImage
                                        }
                                    />
                                )}
                                {replies.map((reply, i) => (
                                    <ChatListItem
                                        key={i}
                                        type={type}
                                        reply={reply}
                                        currentUser={currentUser}
                                        avatar={avatar}
                                        additionalInfo={additionalInfo}
                                        updatedAt={updatedAt}
                                    />
                                ))}
                            </ul>
                        </Scrollbars>
                    </div>
                </div>

                <div className="messages">
                    <div className="form-group-chat">
                        <div>
                            <span className="add-comment">
                                {this.props.labelAddComment}
                            </span>
                            {/*<i className="icon-smile" />*/}
                            {/*<i className="icon-attach-file" />*/}
                        </div>

                        <form
                            className="inline-form"
                            onSubmit={e => {
                                e.preventDefault();
                                this.reply();
                            }}
                        >
                            {/*<textarea*/}
                            {/*onKeyPress={this.replyByEnter}*/}
                            {/*style={{height: this.state.currentHeight}}*/}
                            {/*value={this.state.message}*/}
                            {/*onChange={e => {*/}
                            {/*this.setState({*/}
                            {/*message: e.target.value,*/}
                            {/*});*/}
                            {/*}}*/}
                            {/*/>*/}

                            <Scrollbars
                                autoHide
                                className="area"
                                style={{
                                    height: this.state.initialHeight,
                                }}
                            >
                                <TextareaAutosize
                                    onKeyPress={this.replyByEnter}
                                    style={{height: this.state.currentHeight}}
                                    value={this.state.message}
                                    onChange={e => {
                                        this.setState({
                                            message: e.target.value,
                                        });
                                    }}
                                    placeholder={ticketChatPlaceholder}
                                    minRows={6}
                                />
                            </Scrollbars>

                            <div className="wrap-button">
                                <span>
                                    {this.props.buttonLabel} Ctrl + Enter
                                </span>

                                <button onClick={this.addTask}>
                                    {this.props.labelSend}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

                <Modal
                    isOpen={this.state.modal.isOpen}
                    title={this.state.modal.title}
                    image={this.state.modal.image}
                    closeHandler={this.closeModal}
                />
                {selectedImageUrl && (
                    <PreviewImageModal
                        isOpenImagePreview={isOpenImagePreview}
                        title={'Image Preview'}
                        onCloseModal={closePreviewImageModal}
                        imgUrl={selectedImageUrl}
                    />
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Chat);
