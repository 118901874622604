import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import Panel from '../../../../component/Panel/Panel';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Orders from './component/Orders';
import History from './component/History';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';

import {setActiveView as setActiveViewAction, setActiveTabMyTrading} from '../../service/actions';
import {
    closeOrder as closeOrderAction,
    dropOrderHighlight as dropOrderHighlightAction,
} from './service/actions';
import Preloader from '../../../../component/Preloader';

function sortByDate(a, b) {
    if (a.openingTime.isSameOrAfter(b.openingTime)) {
        return -1;
    }
    if (a.openingTime.isSameOrBefore(b.openingTime)) {
        return 1;
    }
    return 0;
}

function mapStateToProps(state) {
    let tradingHistory = state.tradingHistory.openedOrders;
    if (state.tradingHistory.openedOrders !== null) {
        tradingHistory = Object.values(state.tradingHistory.openedOrders).sort(
            sortByDate
        );
    }

    const {isPendingOpened} = state.tradingHistory;

    return {
        isPendingOpened,
        tradingHistory,
        title: parseTranslation('label-my-trading'),
        labelTabsOrders: parseTranslation('label-orders'),
        labelTabsHistory: parseTranslation('label-history'),
        titleTableOrder: parseTranslation('label-order'),
        titleTableSymbol: parseTranslation('label-symbol'),
        titleTableType: parseTranslation('label-type'),
        titleTableOpenTime: parseTranslation('label-open-time'),
        titleTableSetPrice: parseTranslation('label-set-price'),
        titleTableAmount: parseTranslation('label-amount'),
        titleTableClose: parseTranslation('label-close'),

        titleTableCommission: parseTranslation('label-commission'),
        titleTableOrderPrice: parseTranslation('label-order-price'),
        titleTableCloseTime: parseTranslation('label-close-time'),
        titleTableStatus: parseTranslation('label-table-status'),

        myTradingActiveTab: state.dashboard.myTradingActiveTab
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setActiveView: () => dispatch(setActiveViewAction('trading-history-expanded')),
        closeOrder: (id) => dispatch(closeOrderAction(id)),
        dropOrderHighlight: (id) => dispatch(dropOrderHighlightAction(id)),
        setActiveTabMyTrading: (payload) => dispatch(setActiveTabMyTrading(payload))
    };
}

const styles = {
    header: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '2.4rem',
    },
    cell: {
        textAlign: 'center',
        flex: 1,
        fontSize: '2.3rem',
        color: '#999999',
    }
};

class TradingHistory extends Component {
    title = 'MY TRADING';

    constructor() {
        super();

        this.renderActiveTab = this.renderActiveTab.bind(this);
        this.renderTabBtn = this.renderTabBtn.bind(this);
    }

    rendererHeaderOrder() {
        const {
            titleTableOrder,
            titleTableSymbol,
            titleTableType,
            titleTableOpenTime,
            titleTableSetPrice,
            titleTableAmount,
            titleTableClose
        } = this.props;

        return (
            <div style={styles.header}>
                <div style={{...styles.cell, flexBasis: '5.27%'}}>{titleTableOrder}</div>
                <div style={{...styles.cell, flexBasis: '20px'}}>{titleTableSymbol}</div>
                <div style={{...styles.cell, flexBasis: '20px'}}>{titleTableType}</div>
                <div style={{...styles.cell, flexBasis: '20px'}}>{titleTableOpenTime}</div>
                <div style={{...styles.cell, flexBasis: '20px'}}>{titleTableSetPrice}</div>
                <div style={{...styles.cell, flexBasis: '20px'}}>{titleTableAmount}</div>
                <div style={{...styles.cell, flexBasis: '20px'}}>{titleTableClose}</div>
            </div>
        );
    }

    rendererHeaderHistory() {
        const {
            titleTableOrder,
            titleTableSymbol,
            titleTableType,
            titleTableOpenTime,
            titleTableSetPrice,
            titleTableAmount,
            titleTableCommission,
            titleTableOrderPrice,
            titleTableStatus,
            titleTableCloseTime
        } = this.props;

        return (
            <table
                style={{
                    position: 'relative',
                    top: '2rem',
                }}
            >
                <thead>
                    <tr style={{backgroundColor: 'transparent'}}>
                        <th className="sticky-header" style={{width: '17%'}}>
                            {titleTableOrder}
                        </th>
                        <th className="sticky-header">
                            {titleTableSymbol}
                        </th>
                        <th className="sticky-header order-type w50">
                            {titleTableType}
                        </th>
                        <th className="sticky-header">
                            {titleTableOpenTime}
                        </th>
                        <th className="sticky-header">
                            {titleTableSetPrice}
                        </th>
                        <th className="sticky-header">
                            {titleTableAmount}
                        </th>
                        <th className="sticky-header">
                            {titleTableCommission}
                        </th>
                        <th className="sticky-header">
                            {titleTableOrderPrice}
                        </th>
                        <th className="sticky-header">
                            {titleTableStatus}
                        </th>
                        <th className="sticky-header">
                            {titleTableCloseTime}
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }

    render() {
        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-my-trading" />
            </button>
        );
        const {
            title,
            setActiveView,
            myTradingActiveTab,
            labelTabsOrders,
            labelTabsHistory
        } = this.props;

        return (
            <Panel
                icon={icon}
                title={title}
                expandable
                expandHandler={setActiveView}
                className={
                    myTradingActiveTab !== 2
                        ? 'panel_orders'
                        : 'panel_history'
                }
            >
                <Fragment>
                    <div className="tabs-component without-width">
                        <div className="tabs-component__tabs">
                            {this.renderTabBtn(0, labelTabsOrders)}
                            {this.renderTabBtn(1, labelTabsHistory)}
                        </div>
                    </div>
                    {this.renderActiveTab()}
                </Fragment>
            </Panel>
        );
    }

    renderTabBtn(activeTab, title) {
        const {myTradingActiveTab, setActiveTabMyTrading} = this.props;
        return (
            <button
                className={classnames('tabs-component__tab', {
                    active: myTradingActiveTab === activeTab,
                })}
                onClick={() => setActiveTabMyTrading(activeTab)}
            >
                {title}
            </button>
        );
    }

    renderActiveTab() {
        const {
            myTradingActiveTab,
            tradingHistory,
            closeOrder,
            dropOrderHighlight
        } = this.props;

        switch (myTradingActiveTab) {
            case 0:
                return (
                    <div>
                        {this.rendererHeaderOrder()}
                        <div
                            style={{
                                position: 'absolute',
                                top: '13rem',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                            }}
                        >
                            <Scrollbars>
                                <Orders
                                    orders={tradingHistory}
                                    closeOrder={closeOrder}
                                    dropOrderHighlight={dropOrderHighlight}
                                />
                            </Scrollbars>
                        </div>
                    </div>
                );

            case 1:
                // TODO: Orders passed from props just for demonstration
                return (
                    <div>
                        {this.rendererHeaderHistory()}
                        <div
                            style={{
                                position: 'absolute',
                                top: '13rem',
                                bottom: '1rem',
                                left: 0,
                                right: 0,
                                display: 'flex',
                            }}
                        >
                            <History orders={tradingHistory} />
                        </div>
                    </div>
                );

            default:
                return null;
        }
    }
}

const ConnectedTradingHistory = connect(
    mapStateToProps,
    mapDispatchToProps
)(TradingHistory);
export default ConnectedTradingHistory;
