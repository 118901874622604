import {GET_GUEST_API_URL} from './actions';
import {successAction} from '../actions/actionTypes';
import {instance} from '../saga/api';

const initialState = {
    api: '',
    wsApi: '',
    asset: '',
    prevAsset: '',
    alias: false,
};

export default function guestDashboard(state = initialState, action) {
    switch (action.type) {
        case successAction(GET_GUEST_API_URL): {
            const {data, config} = action.response;
            const wsHost = data.host.replace(/(^\w+:|^)\/\//, '');
            const questSettings = JSON.parse(localStorage.getItem('guestSettings'));
            !questSettings && localStorage.setItem('guestSettings', questSettings ? JSON.stringify({...questSettings, pair: data.asset}) : JSON.stringify({pair: data.asset}));
            return {
                ...state,
                api: data.host,
                wsApi: `${window.location.protocol.includes("https") ? 'wss' : 'ws'}://${wsHost}/dealing/`,
                asset: state.alias || data.asset,
                prevAsset: state.alias || data.asset,
            };
        }
        default:
            return state;
    }
}
