// @flow
import type {ListingFormAdapterInterface as ListingAdapter} from '../../adapter/ListingForm';

class SubmitListingForm {
    adapter: ListingAdapter;

    constructor(adapter: ListingAdapter) {
        this.adapter = adapter;
    }

    async invoke(
        locale: string,
        formData: {} = {},
        token: string,
    ): Promise<any> {
        try {
            const {data} = await this.adapter.submitListingForm(
                locale,
                formData,
                token,
            );
            return data;
        } catch (error) {
            if (error.response) {
                const {status, data} = error.response;

                if (status === 422) {
                    return data;
                }
            }

            throw error;
        }
    }
}

export default SubmitListingForm;
