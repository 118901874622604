//@ Flow
import React, {PureComponent} from "react";
import classnames from 'classnames';
import moment from "moment/moment";

import { TICKET_TYPES, BOT_NAME } from './../constants'
import MessageContent from './MessageContent';
import AvatarComponent from './AvatarComponent';
import avatarSupport from "../assets/avatar.svg";
import avatarBot from "../assets/icon-avatar-bot.svg";
import parseTranslation from "../../../../adapter/helper/parseTranslation";

interface PropTypes {
    reply: {
        username: string;
        created_at: number;
        content: string;
    },
    currentUser: string;
    updatedAt: Object;
    isFirstMessage: boolean;
    type: string;
    additionalInfo: Object;
}

class ChatListItem extends PureComponent<PropTypes> {
    constructor(props) {
        super(props);

        this.createMessageContent = this.createMessageContent.bind(this);
        this.getShownAvatar = this.getShownAvatar.bind(this);
    }

    createMessageContent() {
        
        const {
            type,
            additionalInfo,
            reply: {
                content,
            },
            isFirstMessage,
        } = this.props;

        if (!isFirstMessage) {
            return content
        }

        const translations = {
            questionTittle: parseTranslation('ticket-chat-question-title'),
            questionDescription: parseTranslation('ticket-chat-question-description'),
            withdrawAmount: parseTranslation('ticket-chat-withdraw-amount'),
            withdrawDescription: parseTranslation('ticket-chat-withdraw-method'),
            withdrawAddress: parseTranslation('ticket-chat-withdraw-address'),
            projectOverview: parseTranslation('ticket-chat-project-overview'),
            token: parseTranslation('ticket-chat-token'),
            documents: parseTranslation('ticket-chat-documents'),
        };

        switch (type) {
            case TICKET_TYPES.QUESTION: return (
                <MessageContent
                    firstTitle={`${translations.questionTittle}: `}
                    firstInfo={additionalInfo.title}
                    secondTitle={`${translations.questionDescription}: `}
                    secondInfo={additionalInfo.description}
                />
            );
            case TICKET_TYPES.WITHDRAW: return (
                <MessageContent
                    firstTitle={`${translations.withdrawAmount}: `}
                    firstInfo={`${Math.abs(additionalInfo.amount)}  ${additionalInfo.currency}`}
                    secondTitle={`${translations.withdrawDescription}: `}
                    secondInfo={`${additionalInfo.method} (${translations.withdrawAddress}: 
                    ${typeof additionalInfo.where === 'object' ? additionalInfo.where.card_number : additionalInfo.where})`}
                />
            );
            case TICKET_TYPES.LISTING: return (
                <MessageContent
                    firstTitle={`${translations.projectOverview}: `}
                    firstInfo={additionalInfo.project_name}
                    secondTitle={`${translations.token}: `}
                    secondInfo={additionalInfo.token_name}
                />
            );
            case TICKET_TYPES.VERIFICATION: return (
                <MessageContent
                    isDocument
                    firstTitle={`${translations.documents}: `}
                    firstInfo={''}
                    secondTitle={''}
                    secondInfo={''}
                />
            );

            default: return '';
        }
    }

    getShownAvatar() {
        const {
            currentUser,
            avatar,
            isFirstMessage,
            reply,
        } = this.props;

        let {
            username,
        } = reply;

        username = isFirstMessage ? currentUser : username;
        const clientAvatar = (
            avatar ? (
                <AvatarComponent
                    avatar={avatar}
                    className="ticket-messages__client-avatar"
                />
            ) : (
                <span className="ticket-messages__client-avatar no-avatar">
                    {currentUser.slice(0, 2).toUpperCase()}
                </span>
            )
        );

        switch(username) {
            case BOT_NAME: return (
                <AvatarComponent
                    avatar={avatarBot}
                    className="ticket-messages__support-avatar"
                />);
            case currentUser: return clientAvatar;

            default: return (
                <AvatarComponent
                    avatar={avatarSupport}
                    className="ticket-messages__support-avatar"
                />
            );
        }
    }

    render() {
        const {
            reply,
            currentUser,
            updatedAt,
            isFirstMessage,
        } = this.props;

        const {
            username,
            created_at,
        } = reply;

        const createdAt = isFirstMessage ? updatedAt : moment.unix(created_at);
        const name = isFirstMessage ? currentUser : username;
        const shownUserName = name === BOT_NAME ? parseTranslation(name) : name;

        const ticketMessageClass = classnames('ticket-messages__wrapper', {
            support: !isFirstMessage && currentUser !== username
        });

        const content = this.createMessageContent();

        return (
            <li className="ticket-messages">
                {
                    this.getShownAvatar()
                }
                <div className={ticketMessageClass}>
                    <span className="ticket-messages__name">
                        {shownUserName}
                    </span>
                    <span className="ticket-messages__date">
                        {createdAt.format('DD/MM/YY')}
                    </span>
                    <span className="ticket-messages__time">
                        {createdAt.format('HH:mm:ss')}
                    </span>
                    <div>{content}</div>
                </div>
            </li>
        )
    }
}

export default ChatListItem;
