export const ActionTypes = {
    setAssets: 'assets.set'
};

export function setAssets(payload) {
    return {
        type: ActionTypes.setAssets,
        payload
    }
}
