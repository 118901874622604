import React from 'react';
import {
    ContentLabel,
    ContentColumn,
    ContentValue,
    ContentWrap,
    ContentPrice,
    Icon,
    ContentRow,
} from '../styled';
import t from '../../../infrastructure/adapter/helper/parseTranslation';

export default function TradesTooltip({date, amount, time, price, isBuy}) {
    return (
        <ContentWrap>
            <ContentColumn>
                <ContentRow>
                    <ContentLabel>{t('label-open-time')}</ContentLabel>
                    <ContentValue>{time}</ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-open-date')}</ContentLabel>
                    <ContentValue>{date}</ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-price')}</ContentLabel>
                    <ContentPrice isBuy={isBuy}>
                        <Icon
                            className={
                                isBuy
                                    ? 'icon-single-up-pointing-angle'
                                    : 'icon-single-down-pointing-angle'
                            }
                        />
                        {price}
                    </ContentPrice>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-amount')}</ContentLabel>
                    <ContentValue>{amount}</ContentValue>
                </ContentRow>
            </ContentColumn>
        </ContentWrap>
    );
}
