import React from 'react';
import {ContentLabel, ContentColumn, ContentValue, ContentWrap, ContentRow} from '../styled';
import t from '../../../infrastructure/adapter/helper/parseTranslation';

export default function DepthTooltip({count, amount, total, price}) {
    return (
        <ContentWrap>
            <ContentColumn>
                <ContentRow>
                    <ContentLabel>{t('label-count')}</ContentLabel>
                    <ContentValue>
                        {count}
                    </ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-amount')}</ContentLabel>
                    <ContentValue>{amount}</ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-total')}</ContentLabel>
                    <ContentValue>{total}</ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-price')}</ContentLabel>
                    <ContentValue>{price}</ContentValue>
                </ContentRow>
            </ContentColumn>
        </ContentWrap>
    );
}
