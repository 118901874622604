import React from 'react';
import styled from 'styled-components';

import { customStyles } from './customStyles';
import datafeed from './api/datafeed';
import overrides from './overrides';
import {widget} from 'charting_library/charting_library.min';
import Preloader from '../../../../component/Preloader';
import {createVerticalLinearGradient} from 'react-stockcharts/es/lib/utils';

function getLanguageFromURL() {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null
        ? null
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

class TVChartContainer extends React.PureComponent {
    static defaultProps = {
        symbol: 'BTC/USD',
        interval: '1',
        containerId: 'tv_chart_container',
        datafeedUrl: 'https://demo_feed.tradingview.com',
        libraryPath: '/charting_library/',
        chartsStorageUrl: 'https://saveload.tradingview.com',
        chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        tradingViewData: null,
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
        timeFrames: [
            {text: '3m', resolution: '60', description: '3 Month'},
            {text: '1m', resolution: '60', description: '1 Month'},
            {text: '5d', resolution: '30', description: '5 Days'},
            {text: '1d', resolution: '1', description: '1 Day'},
        ],
    };

    tvWidget = null;
    componentWillMount(): void {
        customStyles();
        setTimeout(() => {
        this.tvWidget.subscribe('drawing', () => {
            this.tvWidget.save((e) => {

               this.props.onSave(e);
            });
        });

        this.tvWidget.subscribe('study', () => {
            this.tvWidget.save((e) => {
                this.props.onSave(e);
            });
        });

        }, 2000)


    }

    componentDidMount() {
        const {
            nextSymbol,
            interval,
            chartInterval,
            containerId,
            libraryPath,
            chartsStorageUrl,
            chartsStorageApiVersion,
            clientId,
            userId,
            fullscreen,
            autosize,
            studiesOverrides,
            timeFrames,
        } = this.props;

        const widgetOptions = {
            // debug: true,
            symbol: nextSymbol,
            datafeed,
            interval: chartInterval === '1H' ? 60 : parseInt(chartInterval),
            container_id: containerId,
            library_path: libraryPath,
            locale: getLanguageFromURL() || 'en',
            disabled_features: [
                'header_symbol_search',
                'symbol_search_hot_key',
                'header_compare',
                'compare_symbol',
                'header_saveload',
            ],
            charts_storage_url: chartsStorageUrl,
            charts_storage_api_version: chartsStorageApiVersion,
            client_id: clientId,
            user_id: userId,
            fullscreen: fullscreen,
            autosize: autosize,
            studies_overrides: studiesOverrides,
            time_frames: timeFrames,
            theme: 'light',
            overrides,
        };

        this.tvWidget = new widget(widgetOptions);



    }

    componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
    ): void {
        const {nextSymbol, interval, tradingViewData} = this.props;

        if (nextSymbol !== prevProps.nextSymbol) {
            if (this.tvWidget) {
                this.tvWidget.onChartReady(() => {
                    this.tvWidget.setSymbol(nextSymbol, interval);
                });
            }
        }

        // if (tradingViewData !== prevProps.tradingViewData) {
        //     console.log('tradingViewData =>', this.props.tradingViewData);

        // }
    }

    componentWillUnmount() {
        const {chartDXHistoryRequest} = this.props;

        chartDXHistoryRequest();

        if (this.tvWidget !== null) {
            this.tvWidget.remove();
            this.tvWidget = null;
        }
    }

    render() {
        const {isPending} = this.props;

        return (
            <>
                {isPending && <Preloader />}

                <ChartContainer
                    id={this.props.containerId}
                    className="TVChartContainer"
                    style={{
                        display: isPending ? 'none' : 'block',
                    }}
                />
            </>
        );
    }
}

const ChartContainer = styled.div`
    height: 100%;
`;

export default TVChartContainer;
