import styled from 'styled-components';

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;   
    grid-column-gap: 4rem;
    grid-row-gap: 1.8rem;
    padding: 1.8rem 4rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;

export const OrderButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 4rem;
`;    
