export const ActionTypes = {
    getProfileForm: "profile-scene.form.get",
    submitProfileForm: "profile-scene.form.submit",
    getPasswordForm: "password-scene.form.get",
    submitPasswordForm: "password-scene.form.submit",
    errorPasswordForm: "error-password-scene.form.submit"
};

export function getProfileForm() {
    return {
        type: ActionTypes.getProfileForm
    };
}

export function submitProfileForm() {
    return {
        type: ActionTypes.submitProfileForm
    };
}

export function getPasswordForm(payload) {
    return {
        type: ActionTypes.getPasswordForm,
        payload,
    };
}

export function submitPasswordForm() {
    return {
        type: ActionTypes.submitPasswordForm
    };
}

