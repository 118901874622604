import styled, {keyframes} from 'styled-components';

const show = keyframes`
from {
    opacity: 0;
}
to {
    opacity: 1;
}
`;

export const TooltipWrapper = styled.div`
    position: relative;
    z-index: 100;
`;
export const Tooltip = styled.div`
    white-space: nowrap;
    position: absolute;
    box-shadow: 0 0 3rem ${props => props.theme.color46};
    opacity: 0;
    padding: ${({direction}) =>
            ((direction === 'left' || direction === 'right') && '2.5rem') ||
            '1.3rem'}
        4.1666rem;
    border: 1px solid ${props => props.theme.color1};
    background: ${props => props.theme.color23};    
    color: ${props => props.theme.color14};
    top: ${({direction, offset}) =>
        (direction === 'bottom' && `calc(100% + ${offset}rem)`) ||
        ((direction === 'left' || direction === 'right') && '50%') ||
        'auto'};
    bottom: ${({direction, offset}) =>
        (direction === 'top' && `calc(100% + ${offset}rem)`) || 'auto'};
    right: ${({direction, offset}) =>
        (direction === 'left' && `calc(100% + ${offset}rem)`) || 'auto'};
    left: ${({direction, offset}) =>
        (direction === 'right' && `calc(100% + ${offset}rem)`) ||
        ((direction === 'top' || direction === 'bottom') && '50%') ||
        'auto'};
    //transform: translateX(-50%);
    transform: ${({direction}) =>
        ((direction === 'bottom' || direction === 'top') &&
            'translateX(-50%)') ||
        ((direction === 'left' || direction === 'right') &&
            'translateY(-50%)')};
    animation: ${show} 0s linear;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-size: 2.3333rem;

    &::after {
        content: '';
        display: block;
        position: absolute;

        width: 2.5rem;
        height: 2.5rem;

        background: ${props => props.theme.color23};
        border: 0.16rem solid ${props => props.theme.color1};
        border-bottom-color: transparent;
        border-right-color: transparent;
        bottom: auto;
        left: ${({direction}) =>
            (direction === 'bottom' && '50%') ||
            (direction === 'right' && '-1.5rem')};
        top: ${({direction}) =>
            (direction === 'bottom' && '-1.5rem') ||
            (direction === 'right' && '50%')};
        right: auto;
        //transform: translateX(-50%) rotate(45deg);
        transform: ${({direction}) =>
            (direction === 'bottom' && 'translateX(-50%) rotate(45deg)') ||
            (direction === 'right' && 'translateY(-50%) rotate(-45deg)')};
    }
`;
