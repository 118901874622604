import {combineReducers, applyMiddleware, createStore, compose} from 'redux';
import rootSaga from '../../../guest/saga';

import formsReducer from './reducer/forms';
import userReducer from './reducer/user';
import dashboardReducer from '../scene/Dashboard/service/reducer';
import assetsReducer from './reducer/assets';
import tradingHistoryReducer from '../scene/Dashboard/components/TradingHistory/service/reducer';
import DOMReducer from '../scene/Dashboard/components/DOM/service/reducer';
import marketTradesReducer from '../scene/Dashboard/components/MarketTrades/service/reducer';
import symbolsReducer from '../scene/Dashboard/components/Symbols/service/reducer';
import twoFAReducer from '../services/reducer/twoFA';
import recoveryReducer from '../scene/RecoveryForm/service/reducer';
import translationReducer from './reducer/translation';
import avatarReducer from './reducer/avatar';
import menuReducer from './reducer/menu';
import DXChartHistoryProviderReducer from './reducer/DXChartHistoryProvider';
import chartReducer from '../scene/Dashboard/components/Chart/service/reducer';
import chartContainerReducer from '../scene/Dashboard/components/ChartContainer/service/reducer';
import chartSymbolsModalReducer from '../scene/Dashboard/components/ChartContainer/components/SymbolsModal/service/reducer';

import symbolsModalReducer from '../scene/Dashboard/components/Header/component/SymbolsModal/service/reducer';
import layoutModalReducer from '../scene/Dashboard/components/Header/component/LayoutModal/service/reducer';
import faqModalReducer from '../scene/Dashboard/components/Header/component/FaqModal/service/reducer';
import newsReducer from '../scene/Dashboard/components/News/service/reducer';
import ticketsReducer from '../scene/Tickets/service/reducer';

import dashboardSceneMiddleware from '../scene/Dashboard/service/middleware';
import notificationModalReducer from '../scene/Dashboard/components/DOM/component/service/reducer';
import notificationReducer from './reducer/notification';
import settingsReducer from './reducer/settings';

import registrationReducer from '../scene/Registration/service/reducer';

import fullscreenReducer from './reducer/fullscreen';

import transactionHistoryReducer from '../scene/Billing/service/reducer';

import listingReducer from '../scene/Listing/reducer';
import sessionReducer from '../services/reducer/session';

import DXChartReducer from './reducer/DXChart';
import socketReducer from './reducer/socket';
import modalReducer from './reducer/modal';

import userMiddleware from './middleware/user';
import tradingMiddleware from './middleware/trading';
import billingMiddleware from './middleware/billing';
import ticketsMiddleware from './middleware/tickets';
import notificationMiddleware from './middleware/notification';
import registrationMiddleware from './middleware/registration';
import fullscreenMiddleware from './middleware/fullscreen';
import twoFAMiddleware from './middleware/twoFA';
import translationMiddleware from './middleware/translation';
import settingsMiddleware from './middleware/settings';
import listingMiddleware from './middleware/listing';

/****************** Template refactor structure *************************/
import {chatReducer} from 'infrastructure/modules/Chat';
import instanceXHR from 'infrastructure/adapter/helper/axios';
import {
    createChatMiddleware,
    ChatServiceXHR,
    ChatServiceWS,
} from 'infrastructure/modules/Chat';
import createSagaMiddleware from 'redux-saga';
import guestDashboard from '../../../guest/GuestDashboard/reducer';
import dealingWs from '../../../guest/webSockets/reducer';
import currencyPair from '../../../guest/CurrencyPairContainer/reducer';
import Modal from '../../../guest/components/Modal/reducer';
import Chart from '../../../guest/GuestChart/reducer';
import ChartMenu from '../../../guest/ChartMenu/reducer';
import marketTrades from '../../../guest/GuestMarketTrades/reducer';
import exchangeModal from '../scene/Dashboard/components/Header/reducer';

const dealingServiceXHR = new ChatServiceXHR(instanceXHR);
const dealingServiceWS = new ChatServiceWS(WebSocket);
const chatMiddleware = createChatMiddleware(
    dealingServiceXHR,
    dealingServiceWS
);
/************************************************************************/

// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
    process.env.NODE_ENV === 'development' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
/* eslint-enable */
// const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
    user: userReducer,
    forms: formsReducer,
    recovery: recoveryReducer,
    dashboard: dashboardReducer,
    tradingHistory: tradingHistoryReducer,
    DOM: DOMReducer,
    marketTrades: marketTradesReducer,
    assets: assetsReducer,
    symbols: symbolsReducer,
    transactionHistory: transactionHistoryReducer,
    twoFA: twoFAReducer,
    translation: translationReducer,
    settings: settingsReducer,
    listing: listingReducer,
    chart: chartReducer,
    chartContainer: combineReducers({
        container: chartContainerReducer,
        modal: chartSymbolsModalReducer,
    }),
    symbolsModal: symbolsModalReducer,
    layoutModal: layoutModalReducer,
    faqModal: faqModalReducer,
    news: newsReducer,
    tickets: ticketsReducer,
    NotificationModal: notificationModalReducer,
    notifications: notificationReducer,
    registration: registrationReducer,
    fullscreen: fullscreenReducer,
    avatarModal: avatarReducer,
    menu: menuReducer,
    DXChart: DXChartReducer,
    socket: socketReducer,
    ...chatReducer,
    session: sessionReducer,
    DXChartHistory: DXChartHistoryProviderReducer,
    modal: modalReducer,
    GUEST_API_URL: guestDashboard,
    GUEST_DEALING_WS: dealingWs,
    CURRENCY_PAIR: currencyPair,
    MODAL: Modal,
    CHART: Chart,
    CHART_MENU: ChartMenu,
    MARKET_TRADES: marketTrades,
    exchangeModal: exchangeModal,
});
const sagaMiddleware = createSagaMiddleware();
const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        state = undefined
    }
    return reducers(state, action)
};

const middlewares = [
    userMiddleware,
    settingsMiddleware,
    tradingMiddleware,
    billingMiddleware,
    ticketsMiddleware,
    dashboardSceneMiddleware,
    notificationMiddleware,
    registrationMiddleware,
    fullscreenMiddleware,
    twoFAMiddleware,
    translationMiddleware,
    listingMiddleware,
    chatMiddleware,
    sagaMiddleware,
];

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
);
sagaMiddleware.run(rootSaga);
export default store;
