import {ActionTypes} from '../action/session';

const initialState = {
    isPending: false,
    data: null,
    error: null,
    activityTimer: false,
    confirmationTimer: false,
    sessionServerTimer: false,
    pingServerSession: false,
    absoluteSessionTimer: false,
};

export default function sessionReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.START_ABSOLUTE_SESSION_TIMER:
            return {
                ...state,
                absoluteSessionTimer: false,
            };

        case ActionTypes.CLEAR_ABSOLUTE_SESSION_TIMER:
            return {
                ...state,
                absoluteSessionTimer: true,
            };

        case ActionTypes.SET_ABSOLUTE_SESSION_TIMER:
            return {
                ...state,
                absoluteSessionTimer: action.payload,
            };

        case ActionTypes.POST_UPDATE_SERVER_SESSION_REQUEST:
            return {
                ...state,
                isPending: true,
                data: null,
                error: null,
            };

        case ActionTypes.POST_UPDATE_SERVER_SESSION_SUCCESS:
            return {
                ...state,
                isPending: false,
                data: action.payload,
                error: null,
            };

        case ActionTypes.POST_UPDATE_SERVER_SESSION_FAILURE:
            return {
                ...state,
                isPending: false,
                data: null,
                error: action.payload,
            };

        case ActionTypes.setActivityTimer:
            return {
                ...state,
                activityTimer: action.payload,
            };

        case ActionTypes.setConfirmationTimer:
            return {
                ...state,
                confirmationTimer: action.payload,
            };

        case ActionTypes.setSessionServerTimer:
            return {
                ...state,
                sessionServerTimer: action.payload,
            };
        case ActionTypes.setPingServerSession:
            return {
                ...state,
                pingServerSession: action.payload,
            };
        default:
            return state;
    }
}
