import styled, {keyframes} from 'styled-components';

export const opacityAnimation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`;

export const DateWrapper = styled.div`
    font-size: 3rem;
    text-align: center;
    color: ${props => props.theme.color20};
`;

export const TimeWrapper = styled.div`
    font-size: 3.5rem;
    text-align: center;
    color: ${props => props.theme.color67};
`;

