import { ActionsType } from "../action/twoFA";

const initialState = {
    isActive: false,
    isLoaded: false,
    checkboxStatus: false,
    enableStep: 1,
    enableModalState: false,
    disableModalState: false,
    twoFAType: "",
    qrCode: "",
    code: "",
    validateEnabledCheckbox: false,
    infoModalState: true
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ActionsType.checkboxChange:
            return { ...state, checkboxStatus: action.checked };

        case ActionsType.showEnableModal:
            return { ...state, enableModalState: true };

        case ActionsType.hideEnableModal:
            return { ...state, enableModalState: false };

        case ActionsType.nextEnableStep:
            return { ...state, enableStep: state.enableStep + 1 };

        case ActionsType.prevEnableStep:
            return { ...state, enableStep: state.enableStep - 1 };

        case ActionsType.restoreEnableStep:
            return { ...state, enableStep: 1 };

        case ActionsType.showDisableModal:
            return { ...state, disableModalState: true };

        case ActionsType.hideDisableModal:
            return { ...state, disableModalState: false };

        case ActionsType.changeTwoFAStatus:
            return { ...state, isActive: action.newStatus };

        case ActionsType.setTwoFAStatus:
            return { ...state, isActive: action.isActive, checkboxStatus: action.isActive, twoFAType: action.twoFAType, isLoaded: true };

        case ActionsType.setTwoFAService:
            return { ...state, qrCode: action.qrCode, code: action.code };

        case ActionsType.changeInfoModalState:
            return { ...state, infoModalState: action.state };

        default:
            return state;
    }
}
