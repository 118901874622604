import React from 'react';
import {Button, Icon, Text, Link} from './styled';

/*

color: ['primary' | 'secondary']
icon: icon class
innerLink: NavLink to props

 */
export default ({
    icon,
    children,
    disabled = false,
    color = 'primary',
    onClick,
    innerLink,
    size = 'large',
}) => {
    return (
        <>
            {innerLink ? (
                <Link
                    to="/billing"
                    disabled={disabled}
                    color={color}
                    onClick={onClick}
                    className="main-button"
                    size={size}
                >
                    {icon && <Icon className={icon} />}
                    <Text>{children}</Text>
                </Link>
            ) : (
                <Button
                    disabled={disabled}
                    color={color}
                    onClick={onClick}
                    className="main-button"
                    size={size}
                >
                    {icon && <Icon className={icon} />}
                    <Text>{children}</Text>
                </Button>
            )}
        </>
    );
};
