import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import Panel from '../../component/Panel/Panel';
import NavBar from '../../component/NavBar';
import Header from 'infrastructure/view/component/Header';

import BankWire from './component/BankWire';
import DepositFunds from './component/DepositFunds';
import WithdrawFunds from './component/WithdrawFunds';
import TransactionFunds from './component/TransactionFunds';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';

import parseTranslation from '../../../adapter/helper/parseTranslation';
import {getDealingToken} from "../../services/action/user";

function mapStateToProps(state) {
    const {balances} = state.user;
    const {isOpenMenu} = state.menu;
    const isPositiveBalance = Object.keys(balances).map(item => +balances[item].total).reduce((a, b) => a + b, 0);
    return {
        isPositiveBalance,
        balances,
        noMoney: parseTranslation('label-withdraw-insufficient-funds'),
        titleBilling: parseTranslation('label-billing'),
        labelDepositFunds: parseTranslation('billing-deposit-funds'),
        labelWithdrawFunds: parseTranslation('billing-withdraw-funds'),
        labelTransactionHistory: parseTranslation(
            'billing-transaction-history'
        ),
        titleInput: parseTranslation('label-find'),
        titleBalances: parseTranslation('title-balances'),

        labelCoin: parseTranslation('billing-table-coin'),
        labelName: parseTranslation('billing-table-name'),
        labelTotalBalance: parseTranslation('billing-table-total-balance'),
        labelAvailableBalance: parseTranslation(
            'billing-table-total-available-balance'
        ),
        labelInBalance: parseTranslation('billing-table-in-order'),
        labelValue: parseTranslation('billing-table-value'),
        isOpenMenu,
    };
}

class Billing extends Component {
    state = {
        activeTab: 2,
        activeTabBank: 0,
        filterValue: '',
    };

    constructor() {
        super();
        this.renderActiveTabBank = this.renderActiveTabBank.bind(this);
        this.onTabClickBank = this.onTabClickBank.bind(this);
    }

    componentDidMount() {
        const {getDealingToken} = this.props;

        getDealingToken();
    }

    render() {
        return (
            <Fragment>
                <div className="container billing">
                    <NavBar isOpen={this.props.isOpenMenu} />

                    <div className="wrapper">
                        <div className="content">
                            <div className="column purse">
                                <Panel
                                    className="panel_bank-wire"
                                    title={this.props.titleBalances}
                                    icon={<button className="panel-button" />}
                                >
                                    <div className="panel-body-content purse-content">
                                        <div className="wrapper-filter">
                                            {this.state.filterValue.length ? <i className="icon-close" onClick={() => this.setState({filterValue:''})} /> : <i className="icon-find" />}
                                            <input
                                                className="filter"
                                                type="text"
                                                placeholder={
                                                    this.props.titleInput
                                                }
                                                value={this.state.filterValue}
                                                onChange={event => {
                                                    const reg = /[а-яА-ЯёЁ]/g;

                                                    if (
                                                        event.target.value
                                                            .length <= 15 &&
                                                        event.target.value.search(
                                                            reg
                                                        ) === -1
                                                    ) {
                                                        this.setState({
                                                            filterValue:
                                                                event.target
                                                                    .value,
                                                        });
                                                    }
                                                }}
                                            />

                                            <div>
                                                {this.renderTableHeader()}
                                                {this.renderActiveTabBank()}
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </div>

                            <div className="column">
                                <Panel
                                    title={this.props.titleBilling}
                                    isCenter={true}
                                    className={
                                        this.state.activeTab === 2
                                            ? 'panel_billing_transaction'
                                            : ''
                                    }
                                >
                                    <div className="tabs-component">
                                        <div className="tabs-component__tabs">
                                            <div
                                                style={{width: '100%'}}
                                                className={classnames(
                                                    'tabs-component__tab',
                                                    {
                                                        active:
                                                            this.state
                                                                .activeTab === 2                                                            ,
                                                    }
                                                )}
                                                onClick={this.onTabClick(2)}
                                            >
                                                {
                                                    this.props
                                                        .labelTransactionHistory
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="active-content"
                                        style={{height: 'auto'}}
                                    >
                                        {this.renderActiveTab()}
                                    </div>

                                    {/*<div className="billing-image">*/}
                                    {/*<img src={require('./icon_Funds.svg')} alt="Logo_Funds" />*/}
                                    {/*<img src={require('./icon_master_card.svg')} alt="Logo_Master_Card" />*/}
                                    {/*<img src={require('./icon_PCI.svg')} alt="Logo_PCI." />*/}
                                    {/*<img src={require('./icon_SSL_Sertified.svg')} alt="Logo_Sertified" />*/}
                                    {/*<img src={require('./icon_visa.svg')} alt="Logo_Visa" />*/}
                                    {/*</div>*/}
                                </Panel>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    onTabClick = activeTab => () => this.setState({activeTab});

    renderActiveTab = () => {
        switch (this.state.activeTab) {
            case 0:
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: '14rem',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                        }}
                    >
                        <Scrollbars>
                            <DepositFunds />
                        </Scrollbars>
                    </div>
                );
            case 1:
                return (
                    <div
                        style={{
                            position: 'absolute',
                            top: this.props.isPositiveBalance === 0 ? '6rem' : '14rem',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                        }}
                    >
                        {this.props.isPositiveBalance === 0 ?
                            <div className="empty-withdraw">{this.props.noMoney}</div> :
                            <Scrollbars>
                                <WithdrawFunds/>
                            </Scrollbars>
                        }
                    </div>
                );
            case 2:
                return <TransactionFunds />;
            default:
                return null;
        }
    };

    onTabClickBank(activeTabBank) {
        return () => this.setState({activeTabBank});
    }

    renderActiveTabBank() {
        switch (this.state.activeTabBank) {
            case 0:
                return (
                    <BankWire
                        filter={this.state.filterValue.toLowerCase()}
                        balances={this.props.balances}
                    />
                );
            default:
                return null;
        }
    }

    renderDepositFunds() {
        return <DepositFunds />;
    }

    renderWithdrawFunds() {
        return <WithdrawFunds />;
    }

    renderTransactionHistory() {
        return <TransactionFunds />;
    }

    rendererBankBankWire() {
        return <BankWire />;
    }

    renderTableHeader() {
        return (
            <table className="billing-header">
                <thead>
                    <tr style={{backgroundColor: 'transparent'}}>
                        <th>{this.props.labelCoin}</th>
                        <th>{this.props.labelName}</th>
                        <th>{this.props.labelTotalBalance}</th>
                        <th>{this.props.labelAvailableBalance}</th>
                        <th>{this.props.labelInBalance}</th>
                        <th>{this.props.labelValue}</th>
                    </tr>
                </thead>
            </table>
        );
    }
}

const mapDispatchToProps = {
    getDealingToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
