import React, {Component} from 'react';
import {connect} from 'react-redux';
import {locale as settingLocale} from 'settings';
import {LangWrapper, Lang, LangDropdown, LangLabel, LangList} from './styled';

import Dropdown from '../Dropdown/Dropdown';
import FlagsImg from 'infrastructure/view/component/Flags/FlagsImg';
import {
    setLocal,
    getTranslation,
    getLanguage,
} from '../../services/action/translation';
import {sendSettingsData, setLocale} from '../../services/action/settings';

const mapStateToProps = (state) => {
    const locale =
        state.settings.locale ||
        localStorage.getItem('locale') ||
        settingLocale;
    const {language} = state.translation;
    const {accessToken} = state.user;
    return {locale, language, accessToken};
};
const mapDispatchToProps = {
    setLocal,
    setLocale,
    sendSettingsData,
    getTranslation,
    getLanguage,
};

class Language extends Component {
    selectLangHandler = (loc) => {
        localStorage.setItem('locale', loc);
        this.props.setLocal(loc);
        this.props.setLocale(loc);
        if (this.props.accessToken.length) {
            this.props.sendSettingsData();
        }
    };

    componentDidMount(): void {
        this.props.getLanguage();
        this.props.getTranslation(this.props.locale);
    }

    componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
    ): void {
        if (prevProps.locale !== this.props.locale) {
            this.props.setLocal(this.props.locale);
            this.props.getTranslation(this.props.locale);
            // this.props.setLocale(this.props.locale);
        }
    }

    render() {
        const {hide, locale, leftBottom, centerBottom, language, isNavBar} =
            this.props;
        if (hide) return null;

        return (
            <Dropdown
                isNavBar={isNavBar}
                btn={
                    <LangWrapper>
                        <FlagsImg className="dont-close-menu" name={locale} />
                    </LangWrapper>
                }
                {...this.props}
            >
                <LangDropdown
                    leftBottom={+leftBottom}
                    centerBottom={centerBottom}
                >
                    <LangList
                        leftBottom={+leftBottom}
                        centerBottom={centerBottom}
                    >
                        {language.map((lang, i) => (
                            <Lang
                                key={i}
                                onClick={() =>
                                    this.selectLangHandler(lang.code)
                                }
                            >
                                <FlagsImg
                                    className="dont-close-menu"
                                    name={lang.code}
                                />
                                <LangLabel active={locale === lang.code}>
                                    {lang.name}
                                </LangLabel>
                            </Lang>
                        ))}
                    </LangList>
                </LangDropdown>
            </Dropdown>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Language);
