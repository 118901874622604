import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';

import {switchChartView, chartView} from "../../Chart/service/actions";
import {chartDXHistoryRequest} from "../../../../../services/action/DXChartHistoryProvider";


function SwitchChartView({view, switchChartView, chartDXRequest, translates}) {
    const handleClickOnTab = data => () => {
        switchChartView(data);
        if (data !== chartView.tradingView) {
            chartDXRequest();
        }
    };

    return (
        <SwitchView className="chart-view without-width">
            <div className="tabs-component">
                <div className="tabs-component__tabs">
                    <div
                        className={classnames(
                            'tabs-component__tab',
                            {
                                'active': (view === chartView.custom)
                            }
                        )}
                        onClick={handleClickOnTab(chartView.custom)}
                    >
                        {translates.custom}
                    </div>
                    <div
                        className={classnames(
                            'tabs-component__tab',
                            {
                                'active': (view === chartView.tradingView)
                            }
                        )}
                        onClick={handleClickOnTab(chartView.tradingView)}
                    >
                        {translates.tradingView}
                    </div>
                </div>
            </div>

        </SwitchView>
    );
}

const SwitchView = styled.div`
    display: flex;
    align-items: center;

    .chart-view {
        &__tabs {
            position: relative;
            display: flex;
        }
    
        &__tab {
            display: flex;
            align-items: center;
            width: fit-content;
            height: 4.16rem;
            text-transform: uppercase;
            font-weight: normal;
            margin-right: 1.5rem;            
            border: 1px solid ${p => p.theme.color29};
            padding: 0 2rem;
            color: ${p => p.theme.color14};
            font-size: 2.33rem;

            &:hover {
                cursor: pointer;
                border-color: ${p => p.theme.color14};
            }

            &--active {
                color: ${p => p.theme.color15};
                background: ${p => p.theme.color28};
                border: none;
            }

        }
    }
`;

const mapStateToProps = (state) => ({
    view: state.chart.chartView.chartView,
});

const mapDispatchToProps = dispatch => ({
    switchChartView: (payload) => dispatch(switchChartView(payload)),
    chartDXRequest: () => dispatch(chartDXHistoryRequest()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SwitchChartView);
