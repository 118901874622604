import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import {open as openSymbolsModal} from '../../../Header/component/SymbolsModal/service/actions';

function mapStateToProps(state) {
    const {selectedSymbol} = state.settings;

    return {...selectedSymbol};
}

const mapDispatchToProps = {
    openSymbolsModal,
};
// TODO: Accept callback which will be invoked on Asset selection
class CurrencyPair extends Component {
    constructor() {
        super();

        this.openSymbolsModal = this.openSymbolsModal.bind(this);
    }

    openSymbolsModal() {
        const { disabled } = this.props;

        if (!disabled) {
            this.props.openSymbolsModal(this.props.chartId);
        }
    }

    render() {
        const { disabled } = this.props;

        const classes = classnames('currency-pair--tools');

        return (
            <Fragment>
                <button
                    className={classes}
                    onClick={this.openSymbolsModal}
                >
                    <i
                        className={`icon icon-${this.props.numerator &&
                            this.props.numerator.toLowerCase()}`}
                    />
                    <span className="name-currency">
                        {this.props.name && this.props.name}
                    </span>
                    <i
                        className={`icon icon-${this.props.denominator &&
                            this.props.denominator.toLowerCase()}`}
                    />
                    {disabled && <i className="icon-single-down-pointing-angle" />}
                </button>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrencyPair);
