import {ActionTypes} from './actions';

const INITIAL_STATE = {
    isOpen: false,
};

function exchangeModal(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.openExchange:
            return {
                ...state,
                isOpen: action.value,
            };

        default:
            return state;
    }
}

export default exchangeModal;
