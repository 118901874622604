export const ActionTypes = {
    open: 'dashboard-scene.header-component.symbols-modal.open',
    close: 'dashboard-scene.header-component.symbols-modal.close',
    setCryptoCurrencyFilter:
        'dashboard-scene.header-component.symbols-modal.set-crypto-currency-filter',
    setFindFilter:
        'dashboard-scene.header-component.symbols-modal.set-find-filter',
    setSymbols: 'dashboard-scene.header-component.symbols-modal.set-symbols',
};

export function open() {
    return {
        type: ActionTypes.open,
    };
}

export function close() {
    return {
        type: ActionTypes.close,
    };
}

export function setCryptoCurrencyFilter(filter) {
    return {
        type: ActionTypes.setCryptoCurrencyFilter,
        filter,
    };
}

export function setFindFilter(filter) {
    return {
        type: ActionTypes.setFindFilter,
        filter,
    };
}

export function setSymbols(symbols) {
    return {
        type: ActionTypes.setSymbols,
        symbols,
    };
}
