import {ActionTypes} from './actions';

const initState = {
    isPending: true,
    data: [],
    error: null,
};

function newsReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.setNews:
            return {
                isPending: false,
                data: action.news,
                error: null,
            };

        case ActionTypes.errorNews:
            return {
                isPending: false,
                data: [],
                error: action.payload,
            };

        default:
            return state;
    }
}

export default newsReducer;
