import {takeEvery, put, select} from 'redux-saga/effects';
import {SET_CURRENCY_PAIR, setCurrencyPair, setPrevCurrencyPair} from './actions';
import {sendDealingWebsocket} from '../webSockets/actions';
import {getPrevSelectedPair} from './selectors';
import {SET_ERROR} from '../GuestChart/actions';

export default function*() {
    yield takeEvery(SET_CURRENCY_PAIR, setWs);
    yield takeEvery(SET_ERROR, clearAsset)
}

function* clearAsset() {
    yield put(setPrevCurrencyPair(''));
    yield put(setCurrencyPair(''));
}

function* setWs({value}) {
    window.history.pushState(null, null, `/market/${value.replace('/', '-')}`);
    const prevSelectedPair = yield select(getPrevSelectedPair);
    const subscribe = ['subscribe', {asset: value}];
    const unsubscribe = ['unsubscribe', {asset: prevSelectedPair}];
    yield put(sendDealingWebsocket(unsubscribe));
    yield put(sendDealingWebsocket(subscribe));
    yield put(setPrevCurrencyPair(value));
    const questSettings = JSON.parse(localStorage.getItem('guestSettings'));
    localStorage.setItem('guestSettings', questSettings ? JSON.stringify({...questSettings, pair: value}) : JSON.stringify({pair: value}));
}
