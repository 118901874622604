import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

function SwitchButton({toggle, hover, ...rest}) {
    const IconClass = classnames({
        'icon-time': hover ? toggle : !toggle,
        'icon-server': hover ? !toggle : toggle,
    });

    return (
        <StyledButton {...rest}>
            <StyledI className={IconClass} />
        </StyledButton>
    );
}

const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    color: ${({theme}) => theme.color65};

    &:hover {
        color: ${({theme}) => theme.color7};
    }

    &:active {
        color: ${({theme}) => theme.color20};
        background: ${({theme}) => theme.color1};        
    }
`;

const StyledI = styled.i`
    font-size: 4rem;
`;

export default SwitchButton;
