import React from 'react';
import {css} from 'styled-components';
import {BeatLoader} from 'react-spinners';

import colors from 'infrastructure/view/styles/common-colors/common-colors';

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    text-align: center;
`;

const Preloader = props => <BeatLoader css={override} {...props} />;

Preloader.defaultProps = {
    color: colors.color1,
};

export default Preloader;
