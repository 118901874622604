import React from 'react';
import t from '../../../infrastructure/adapter/helper/parseTranslation';
import {Button, AccentText} from './styled';
import config from '../../../config';

export default () => {
    return (
        <Button>
            <AccentText href={`${config.workspaceUrl}/registration`}>{t('label-sign-up')}</AccentText>&nbsp;{t('label-or')}&nbsp;
            <AccentText  href={`${config.workspaceUrl}/login`}>{t('login-btn')}</AccentText>
        </Button>
    );
};
