import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import colors  from '../../styles/common-colors/common-colors';
import Modal from 'react-modal';
import parseTranslation from '../../../adapter/helper/parseTranslation';
import {logout} from 'infrastructure/view/services/action/user';

import { clearActivityTimer } from 'infrastructure/view/services/action/session';
import {clearConfirmTimer} from "../../services/action/session";


class ActivitySessionModal extends Component {
    onContinue = () => {
        const { clearActivityTimerDispatch, clearConfirmTimerDispatch } = this.props;

        clearActivityTimerDispatch();
        clearConfirmTimerDispatch();
    };

    onHandleLogout = () => {
        const { clearActivityTimerDispatch, logoutDispatch } = this.props;

        clearActivityTimerDispatch();
        logoutDispatch();
    };

    render() {
        const { activityTimer, confirmationTimer } = this.props;

        return (
            <Modal
                isOpen={localStorage.getItem('userRegion') && activityTimer}
                className="modal session-modal"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(20, 22, 27, 0.65)',
                    },
                }}
            >
                <ContentWrap>
                    <Header>
                        <span>
                            {this.props.titleSessionModal}
                        </span>

                        <button onClick={this.onContinue}>
                            <i className="icon-close" />
                        </button>
                    </Header>
                    <Column>
                        <p>{this.props.contentSessionModal}</p>
                    </Column>

                    <ButtonWrapper>
                        <ButtonLogOut
                            className="secondary-button"
                            onClick={this.onHandleLogout}
                        >
                            {this.props.logoutSessionModal}
                        </ButtonLogOut>
                        <ButtonContinue
                            onClick={this.onContinue}
                            className="main-button"
                        >
                            {this.props.continueSessionModal}
                        </ButtonContinue>
                    </ButtonWrapper>
                </ContentWrap>
            </Modal>
        )

    }
}

function mapStateToProps(state) {
    return {
        activityTimer: state.session.activityTimer,
        confirmationTimer: state.session.confirmationTimer,

        titleSessionModal: parseTranslation('title-session-modal'),
        contentSessionModal: parseTranslation('content-session-modal'),
        logoutSessionModal: parseTranslation('logout-session-modal'),
        continueSessionModal: parseTranslation('continue-session-modal'),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clearActivityTimerDispatch: () => dispatch(clearActivityTimer()),
        clearConfirmTimerDispatch: () => dispatch(clearConfirmTimer()),
        logoutDispatch: () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySessionModal);

const ContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    background-color: ${colors.color52};
`;

const Header = styled.div`
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-between;
    top: 0;
    width: 100%;
    height: 6.65rem;
    background: ${colors.color50};
    color: ${colors.color51};
    
    span {
      margin-left: 2rem;
      font-size: 3rem;
    }
    
    button {
      margin-right: 3rem;
      font-size: 3rem;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    padding: 6rem 10rem 0 10rem;
    
    p {
        color: ${colors.color14};
        font-size: 3rem;
    }
`;

const ButtonLogOut = styled.button`    
    margin-right: 4rem;   
    
`;

const ButtonContinue = styled.button`    
    
    
`;
