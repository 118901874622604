import React from 'react';
import {ModalTopWrapper, Arrow} from './styled';
import SymbolsInput from '../../components/SymbolsInput';
import t from '../../../infrastructure/adapter/helper/parseTranslation';

export default function ModalTop({selectedPair, newPair}) {
    return(
        <ModalTopWrapper>
            <SymbolsInput title={t('active-symbol')} pair={selectedPair} />
            <Arrow className="icon-arrow-symbols" />
            <SymbolsInput title={t('selected-symbol')} pair={newPair} />
        </ModalTopWrapper>
    )
}
