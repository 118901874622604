// @flow
import type { AccessToken } from '../../../domain/entitiy/AccessToken';
import type { BillingAdapterInterface as BillingAdapter }  from '../../../application/adapter/Billing';

class GetTransactionHistory {
    billingAdapter: BillingAdapter;

    constructor(billingAdapter: BillingAdapter) {
        this.billingAdapter = billingAdapter;
    }

    async invoke(token: AccessToken) {
        try {
            const { data } = await this.billingAdapter.getTransactionHistory(token);

            if (!Array.isArray(data)) {
                throw new Error('Billing transaction history data is not an array');
            }

            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default GetTransactionHistory;
