import axios from 'axios';
import 'regenerator-runtime/runtime';
import {call, put, select} from 'redux-saga/effects';
import config from '../../localConfig';

import {
  failAction,
  startAction,
  successAction,
  SEND_REQUEST,
  RESPONSE_ERROR,
  UNAUTHORIZED,
} from '../actions/actionTypes';


const instance = axios.create({
  baseURL: config.authUrl,
  withCredentials: false,
});
instance.interceptors.response.use((data) => {
    if(data && data.data && data.data.host) instance.defaults.baseURL = data.data.host;
    return data;
});
export const callApi = (
  headers,
  method = 'get',
  endpoint,
  body,
  params,
  responseType = 'json',
  actionType,
) =>
  instance({
    actionType,
    headers,
    url: endpoint,
    method,
    data: body,
    params,
    responseType,
  }).then(
    response => {
      return {response, error: null};
    },
    error => ({response: null, error}),
  );

export default function* watchApi(action) {
  let {endpoint} = action;
  const callApiAction = action;
  delete callApiAction.apiCall;
  const {
    type,
    method = 'get',
    body,
    headers,
    params,
    formatter = response => response,
    responseType,
  } = callApiAction;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(yield select());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (typeof type !== 'string') {
    throw new Error('Expected action type to be string.');
  }

  const actionWith = data => ({...callApiAction, ...data});

  yield put(
    actionWith({
      type: startAction(type),
    }),
  );

  yield put({type: SEND_REQUEST});

  // const headers = {};
  const {response, error} = yield call(
    callApi,
    headers,
    method,
    endpoint,
    body,
    params,
    responseType,
    action.type,
  );

  if (response) {
    yield put(
      actionWith({
        response: formatter(response),
        type: successAction(type),
      }),
    );
  }
  if (error) {
    if (!error.response) {
      yield put(
        actionWith({
          type: failAction(type),
          error: error.message || 'Error happened during API call',
        }),
      );
    } else {
      if (error.response.status) {
        yield put({
          type: RESPONSE_ERROR,
          response: error.response,
        });
      }
      if (error.response.status === 401) {
        yield put(
          actionWith({
            type: UNAUTHORIZED,
            error: error.response.data,
          }),
        );
      } else {
        yield put(
          actionWith({
            type: failAction(type),
            error: error.response.data || 'Error happened during API call',
          }),
        );
      }
    }
  }
}
