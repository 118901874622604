import styled from 'styled-components';

export const inputBg = '#F5F5F5';
export const buttonColor = '#000000';
export const labelColor = '#2B9241';
export const iconColor = '#999999';

export const Wrapper = styled.div`
    height: 7.5rem;
    padding: 1.4rem 0;
    background-color: ${inputBg};    
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const Icon = styled.i`
  color: ${iconColor};
  margin: 0 2rem;  
`;
export const Pair = styled.div`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  align-items: center;
  color: ${buttonColor}
`;

export const Label = styled.div`
    margin: 0 0 2.2rem;
    line-height: 1;
    font-size: 2.333rem;
    color: ${labelColor};
    text-align: center; 
`;

