import React from 'react';
import {IconArrow, Icon, CurrencyRow, CurrencyTitle} from './styled';

export default function CurrencyRowComponent({title, isActive, onClick}) {
    const handleClick = () => onClick();
    return (
        <CurrencyRow isActive={isActive} onClick={handleClick}>
            <CurrencyTitle>
                <Icon className={`icon-${title.toLowerCase()}`} />
                {title}
            </CurrencyTitle>
            {isActive && <IconArrow className="icon-single-right-pointing-angl" />}
        </CurrencyRow>
    );
}
