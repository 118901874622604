import React from 'react';
import Modal from 'react-modal';

const PreviewImageModal = ({isOpenImagePreview, title, onCloseModal, imgUrl}) => {
    return (
        <Modal
            isOpen={isOpenImagePreview}
            contentLabel="PreviewImageModal"
            className="modal image-preview-modal"
        >
            <div className="modal-header">
                <div className='image-preview-modal__title'>{title}</div>
                <button
                    className='image-preview-modal__close-button'
                    type={'button'}
                    onClick={onCloseModal}
                >
                    <i className="icon-close" />
                </button>
            </div>
            <div className="image-preview-modal__body">
                <img
                    className="image-preview-modal__image"
                    src={imgUrl} alt="preview-image" />
            </div>
        </Modal>
    )
};

export default PreviewImageModal;
