import { ActionTypes } from '../action/assets';

export default function(state = [], action) {
    switch (action.type) {
        case ActionTypes.setAssets:
            return action.payload.map(
                ({ name }) => name
            );

        default:
            return state;
    }
}
