import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';

export const Nav = styled.nav`
    width: 10.8333rem;
    background: ${props => props.theme.color15};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const NavWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const NavBottomWrapper = styled(NavWrapper)`
    margin-bottom: 4rem;
`;

const NavIconStyles = css`
    font-size: 4rem;
    padding: 2rem;
    margin: 2rem;
    display: block;

    &:hover {
        color: ${props => props.theme.color23};
    }

    &.active {        
        background: linear-gradient(
            to bottom,
            ${props => props.theme.color1},
            ${props => props.theme.color1}
        );
        color: ${props => props.theme.color23};
    }
`;

export const NavLinkIcon = styled(NavLink)`
    ${NavIconStyles};
`;
export const NavLinkIconStatic = styled.a`
    ${NavIconStyles};
`;

export const NavIcon = styled.a`
    ${NavIconStyles};
`;

export const Icon = styled(NavIcon)``;
