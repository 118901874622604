// @flow
import {EventTypesChat, type EventChat} from 'infrastructure/view/widget/Chat';
import type {Action} from './action';
import {type State} from './reducer';
import type ChatServiceXHR from './serviceXHR';
import type ChatServiceWS from './serviceWS';
import {
    setAccessChat,
    setRoom,
    setMessage,
    setMessageText,
    setTypingStatus,
    removeChat,
    setChatStatus,
    setConnection,
    errorAccessChat,
    closeRoom,
} from './action';

type commonEvent = EventChat;

type Store = {
    getState: () => {'module.chat': State},
    dispatch: (a: Action) => {},
};

type Next = (e: commonEvent) => {};

function subscribeToServiceEvents(service, chatServiceXHR, store) {
    service.on('chat-socket-onclose', async () => {
        const {accessToken} = store.getState().user;

        if (!accessToken) {
            return false;
        }

        const chatAccess = await chatServiceXHR.getChatAccessToken(
            accessToken
        );
        window.localStorage.setItem(
            'chatAccessData',
            JSON.stringify(chatAccess)
        );

        // await service.connect(chatAccess);

        store.dispatch(setChatStatus(false));
        // store.dispatch(setAccessChat(chatAccess));
    });


    service.on('room-closed', () => store.dispatch(closeRoom()));
    service.on('room', data => createEventHandlerRoom(store, data));
    service.on('newMessage', data => createEventHandlerNewMessage(store, data));
    service.on('incomingTyping', data =>
        createEventHandlerIncomingTyping(store, data)
    );
    service.on('incomingTypingStop', data =>
        createEventHandlerIncomingTypingStop(store, data)
    );
    service.on('socketClosed', data =>
        createEventHandlerSocketClosed(store, data)
    );
}

const createEventHandlerRoom = (store, data) => store.dispatch(setRoom(data));
const createEventHandlerNewMessage = (store, data) => {
    store.dispatch(setMessage(data));

}
const createEventHandlerIncomingTyping = (store, data) =>
    store.dispatch(setTypingStatus(data));
const createEventHandlerIncomingTypingStop = (store, data) =>
    store.dispatch(setTypingStatus(data));
const createEventHandlerSocketClosed = (store, data) =>
    store.dispatch(setChatStatus(data));

function createChatMiddleware(
    chatServiceXHR: ChatServiceXHR,
    chatServiceWS: ChatServiceWS
) {
    return (store: Store) => {
        const doneTypingInterval: number = 500;
        let typingTimer: TimeoutID;

        subscribeToServiceEvents(chatServiceWS, chatServiceXHR, store);

        return (next: Next) => async (event: commonEvent) => {
            next(event);

            switch (event.type) {
                case EventTypesChat.getChatData: {
                    try {
                        const {accessToken} = store.getState()['user'];

                        if (!accessToken) {
                            return false;
                        }

                        const chatAccess = await chatServiceXHR.getChatAccessToken(
                            accessToken
                        );
                        window.localStorage.setItem(
                            'chatAccessData',
                            JSON.stringify(chatAccess)
                        );

                        await chatServiceWS.connect(chatAccess);

                        store.dispatch(setChatStatus(true));
                        store.dispatch(setAccessChat(chatAccess));
                    } catch (error) {
                        store.dispatch(errorAccessChat(error));
                    }
                    return;
                }

                case EventTypesChat.setMessageText: {
                    // if (event.messageText === '') {
                    //     return store.dispatch(setMessageText(''));
                    // }

                    const chat = store.getState()['module.chat'];
                    const {roomId} = chat.room;
                    const messageDataShow = {
                        type: 'typing_show',
                        roomId,
                        data: event.messageText,
                    };
                    const messageDataHide = {
                        type: 'typing_hide',
                        roomId,
                        data: '',
                    };
                    const messageTypingStop = {
                        type: 'typing_stop',
                        roomId,
                        data: '',
                    };

                    chatServiceWS.sendMessage(messageDataShow);
                    chatServiceWS.sendMessage(messageDataHide);

                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(() => {
                        chatServiceWS.sendMessage(messageTypingStop);
                    }, doneTypingInterval);

                    return store.dispatch(setMessageText(event.messageText));
                }

                case EventTypesChat.sendMessage: {
                    const chat = store.getState()['module.chat'];
                    const {roomId} = chat.room;
                    const messageText = chat.messageText;
                    const messageData = {
                        type: 'new_message',
                        roomId,
                        data: messageText,
                    };
                    const messageTypingStop = {
                        type: 'typing_stop',
                        roomId,
                        data: '',
                    };

                    chatServiceWS.sendMessage(messageData);
                    chatServiceWS.sendMessage(messageTypingStop);
                    return false;
                }

                case EventTypesChat.setConnection: {
                    return store.dispatch(setConnection());
                }

                case EventTypesChat.closeChat: {
                    chatServiceWS.disconnect();
                    return store.dispatch(removeChat());
                }

                default:
                    return false;
            }
        };
    };
}

export default createChatMiddleware;
