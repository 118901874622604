import {ActionType} from '../action/menu';

const initialState = {
    isOpenMenu: false,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ActionType.toggleMenu:
            return {
                ...state,
                isOpenMenu: !state.isOpenMenu,
            };

        case ActionType.closeMenu:
            return {...state, isOpenMenu: false};

        default:
            return {...state};
    }
}
