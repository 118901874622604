export const ActionTypes = {
    addOrdersToMarketTradesTable:
        'dashboard-scene.market-trades-component.orders-add',
    addHistoryToMarketTradesTable:
        'dashboard-scene.market-trades-component.history-add',
    addHistoryToMarketTradesTableError:
        'dashboard-scene.market-trades-component.history-add.error',
    fetchMarketTradesHistory:
        'dashboard-scene.market-trades-component.history-fetch',
    clearMarketTradesTable:
        'dashboard-scene.market-trades-component.clear-market-trades-table',
    changePending: 'dashboard-scene.market-trades-component.changeIsPending',
};

export function changePending(pendingState) {
    return {
        type: ActionTypes.changePending,
        pendingState,
    };
}

export function addOrdersToMarketTradesTable(payload) {
    return {
        type: ActionTypes.addOrdersToMarketTradesTable,
        payload,
    };
}

export function addHistoryToMarketTradesTable(deals) {
    return {
        type: ActionTypes.addHistoryToMarketTradesTable,
        deals,
    };
}

export function addHistoryToMarketTradesTableError(payload) {
    return {
        type: ActionTypes.addHistoryToMarketTradesTableError,
        payload,
    };
}

export function fetchMarketTradesHistory(page, symbol) {
    return {
        type: ActionTypes.fetchMarketTradesHistory,
        page,
        symbol,
    };
}

export function clearMarketTradesTable() {
    return {
        type: ActionTypes.clearMarketTradesTable,
    };
}
