import {eventChannel} from 'redux-saga';
import {
    call,
    put,
    take,
    takeEvery,
    select,
    race,
    all,
} from 'redux-saga/effects';

import {
    setDealingWs,
    startGuestDealingWebsocket,
    SEND_DEALING_WS,
    STOP_DEALING_WS,
    START_GUEST_DEALING_WS,
} from './actions';

import {wsUrlSelector} from '../GuestDashboard/selectors';

function watchMessages(socket) {
    return eventChannel(emit => {
        socket.onopen = () => {
            // console.log('WS OPEN');
            const urlArr = window.location.href.split('/');
            let number;
            urlArr.map((item, idx) => {
                if (item === 'market') number = idx;
                return item;
            });
            const settings = JSON.parse(localStorage.getItem('guestSettings'));
            const message = ['subscribe', {asset: urlArr[number + 1] ? urlArr[number + 1].replace('-', '/') : (settings && settings.pair)}];
            const messageSerialized = JSON.stringify(message);

            socket.send(messageSerialized); // Send data to server
        };
        socket.onclose = event => {
            // console.log('close', event.code);
            event.code === 4000 && emit(startGuestDealingWebsocket());
        };
        socket.onerror = err => console.log('error', err);
        socket.onmessage = ({data}) => {
            // console.log('WS MSG', data);
            let info = JSON.parse(data);
            const ws = {
                [info.filter(item => typeof item === 'string')[0]]: info.filter(
                    item => typeof item === 'object'
                )[0],
            };

            return emit(setDealingWs(ws));
        };
        return () => {
            socket.close();
        };
    });
}

function* backgroundTask(socketChannel) {
    while (true) {
        const action = yield take(socketChannel);
        yield put(action);
    }
}

function* executeTask(socketChannel) {
    while (true) {
        const {data} = yield take(SEND_DEALING_WS);

        socketChannel.send(JSON.stringify(data));
    }
}

function* startWs() {
    while (true) {
        const wsUrl = yield select(wsUrlSelector);
        const socket = new WebSocket(wsUrl); // eslint-disable-line no-undef

        const socketChannel = yield call(watchMessages, socket);

        const {cancel} = yield race({
            task: all([
                call(backgroundTask, socketChannel),
                call(executeTask, socket),
            ]),
            cancel: take(STOP_DEALING_WS),
        });
        // console.log(cancel, 'stop symbols');
        if (cancel) {
            socketChannel.close();
        }
    }
}

export default function* wsHandling() {
    yield takeEvery(START_GUEST_DEALING_WS, startWs);
}
