// @flow
import React, {Fragment, PureComponent} from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import parseTranslation from '../../../../adapter/helper/parseTranslation';

import Panel from '../../../component/Panel/Panel';
import QuestionForm from '../component/QuestionForm';
import VerificationForm from '../component/VerificationForm';
import List from '../component/List';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';

function mapStateToProps(state) {
    return {
        createTicketVerificationTab: parseTranslation(
            'create-ticket-verification-tab'
        ),
        createTicketQuestionTab: parseTranslation('create-ticket-question-tab'),
        createTicketTitle: parseTranslation('create-ticket-title'),
    };
}

class TicketsLayoutCreate extends PureComponent<PropTypes> {
    title: string = 'Create new ticket';

    render() {
        return (
            <Fragment>
                <Scrollbars>
                    <div className="ticket-create__wrap">
                        <div className="column">
                            <List withoutButtonCreate={true} />
                        </div>

                        <div className="column column_tabs">
                            <Panel
                                title={this.props.createTicketTitle}
                                isCenter={true}
                                className="panel_tabs"
                                width={true}
                            >
                                <div className="tabs-component">
                                    <div className="tabs-component__tabs ticket-tabs">
                                        <NavLink
                                            className="tabs-component__tab"
                                            to={'/tickets/create/verification'}
                                            activeClassName={'active'}
                                        >
                                            {
                                                this.props
                                                    .createTicketVerificationTab
                                            }
                                        </NavLink>
                                        <NavLink
                                            className="tabs-component__tab"
                                            to={'/tickets/create/questions'}
                                            activeClassName={'active'}
                                        >
                                            {this.props.createTicketQuestionTab}
                                        </NavLink>
                                        <div className="tabs-component__slider" />
                                    </div>
                                </div>
                                <div className="active-content">
                                    <Switch>
                                        <Route
                                            path="/tickets/create/verification"
                                            component={VerificationForm}
                                        />
                                        <Route
                                            path="/tickets/create/questions"
                                            component={QuestionForm}
                                        />
                                    </Switch>
                                </div>
                            </Panel>
                        </div>
                    </div>
                </Scrollbars>
            </Fragment>
        );
    }
}

export default connect(mapStateToProps)(TicketsLayoutCreate);
