import {ActionTypes} from './actions';

const initState = {
    isPending: true,
    symbols: {},
    filter: '',
};

function symbolsReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.symbolsSuccess:
            const symbols = action.payload
                .sort((a, b) => b.price - a.price)
                .reduce((result, asset) => {
                    result[asset.id] = {
                        ...asset,
                        favorite:
                            (state.symbols[asset.id] &&
                                state.symbols[asset.id].favorite) ||
                            false,
                    };

                    return result;
                }, {});

            return {
                ...state,
                symbols,
                isPending: false,
            };

        case ActionTypes.toggleSymbolFavorite:
            return {
                ...state,
                symbols: {
                    ...state.symbols,
                    [action.id]: {
                        ...state.symbols[action.id],
                        favorite: !state.symbols[action.id].favorite,
                    },
                },
            };

        case ActionTypes.setSymbolFilter:
            return {
                ...state,
                filter: action.filter,
            };

        default:
            return state;
    }
}

export default symbolsReducer;
