// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {NotificationAdapterInterface as NotificationAdapter} from '../../adapter/Notification';

class CloseNotification {
    adapter: NotificationAdapter;

    constructor(adapter: NotificationAdapter) {
        this.adapter = adapter;
    }

    invoke(token: AccessToken, id: string = '') {
        try {
            if (id) {
                return this.adapter.closeNotification(token, id);
            } else {
                return this.adapter.closeAllNotifications(token);
            }
        } catch (error) {
            throw error;
        }
    }
}

export default CloseNotification;
