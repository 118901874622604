import React, {useMemo, useState} from 'react';
import t from '../../infrastructure/adapter/helper/parseTranslation';
import {Titles, Symbol, Price, Volume, Growth, SymbolsWrapper} from './styled';
import ScrollBar from '../components/Scroll';
import {useDispatch, useSelector} from 'react-redux';
import {dealingAssets} from '../webSockets/constants';
import SymbolsRow from './components/SymbolsRow';
import {LinesFill} from '../components/LinesFill/styled';
import {setCurrencyPair} from '../CurrencyPairContainer/actions';
import Tooltip from '../components/Tooltip/TooltipAbsolute';
import SymbolsTooltip from './components/TooltipContent';

export default function GuestSymbolsContainer({
    filter,
    selectedPair,
    onSelect,
    isSelectable,
    isModal
}) {
    const dispatch = useDispatch();
    const symbols = useSelector(state => state.GUEST_DEALING_WS[dealingAssets]);
    const [prevSymbols, setPrevSymbols] = useState(null);
    const [changedSymbol, setChangedSymbol] = useState('');
    const keys = {};

    useMemo(() => {
        const symbolsLength = Object.keys(symbols).length;
        prevSymbols &&
            Object.keys(prevSymbols).map((key, idx) => {
                Object.keys(prevSymbols[key]).map(() => {
                    if (prevSymbols[key].volume !== symbols[key].volume) {
                        keys[key] =
                            prevSymbols[key].volume < symbols[key].volume;
                    }
                    if (idx + 1 === symbolsLength) {
                        setChangedSymbol(keys);
                        setPrevSymbols(symbols);
                    }
                });
            });
        setPrevSymbols(symbols);
    }, [symbols]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelect = value => () =>
        isSelectable ? dispatch(setCurrencyPair(value)) : onSelect(value);

    return (
        <SymbolsWrapper>
            <Titles>
                <Symbol>{t('label-curr-pair')}</Symbol>
                <Price>{t('label-price')}</Price>
                <Volume>{t('label-volume')}</Volume>
                <Growth>{t('label-growth')}</Growth>
            </Titles>
            <ScrollBar>
                <LinesFill />
                {Object.keys(symbols)
                    .filter(item => (filter ? item.toLowerCase().includes(filter.toLowerCase()) : item))
                    .map((item, idx) => (
                        isModal
                            ? <SymbolsRow
                                isModal={isModal}
                                onClick={handleSelect(item)}
                                isActive={item === selectedPair}
                                isChanged={changedSymbol.hasOwnProperty(item)}
                                increase={changedSymbol[item]}
                                key={item}
                                isOdd={idx % 2 === 0}
                                title={item}
                                price={symbols[item].price}
                                volume={symbols[item].volume}
                                growth={`${(+symbols[item].change).toFixed(2)}`}
                            />
                            :
                            <Tooltip
                            key={item}
                            leftCenter={16}
                            content={
                                <SymbolsTooltip
                                    value={item}
                                    price={symbols[item].price}
                                    volume={symbols[item].volume}
                                    isPositive={(+symbols[item].change).toFixed(2) > 0}
                                    isNegative={(+symbols[item].change).toFixed(2) < 0}
                                    growth={symbols[item].change}
                                />
                            }
                        >
                            <SymbolsRow
                                isModal={isModal}
                                onClick={handleSelect(item)}
                                isActive={item === selectedPair}
                                isChanged={changedSymbol.hasOwnProperty(item)}
                                increase={changedSymbol[item]}
                                key={item}
                                isOdd={idx % 2 === 0}
                                title={item}
                                price={symbols[item].price}
                                volume={symbols[item].volume}
                                growth={`${(+symbols[item].change).toFixed(2)}`}
                            />
                        </Tooltip>
                    ))}
            </ScrollBar>
        </SymbolsWrapper>
    );
}
