import React, {Component, Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import Panel from '../../../../component/Panel/Panel';
import CurrencyPair from 'infrastructure/view/component/SymbolButton';
import FormattedPrice from '../../../../component/FormattedPrice/FormattedPrice';
import Preloader from 'infrastructure/view/component/Preloader';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';

import NotificationModal from './component/NotificationModal';
import {open as openNotificationModalAction} from './component/service/actions';
import {open as openSymbolsModal} from 'infrastructure/view/scene/Dashboard/components/Header/component/SymbolsModal/service/actions';

import {setActiveView as setActiveViewAction} from '../../service/actions';
import {
    setIsBuyTablePinnedToBottom as setIsBuyTablePinnedToBottomAction,
    updateTablesVisibility as updateTablesVisibilityAction,
} from './service/actions';
import ToolTip from 'react-portal-tooltip';
import TableTooltipDepthBuy from '../../../../component/TableTooltipDepthBuy/TableTooltipDepthBuy';
import ErrorContainer from '../../../../component/Error/ErrorContainer';

function mapStateToProps(state) {
    const {
        sell,
        buy,
        isTableBuyVisible,
        isTableSellVisible,
        isBuyTablePinnedToBottom,
        isPanging,
    } = state.DOM;

    const {selectedSymbol} = state.settings;
    return {
        orders: {
            buy,
            sell,
        },
        isTableBuyVisible,
        isTableSellVisible,
        isBuyTablePinnedToBottom,
        isPanging,
        selectedSymbol,
        socketError: state.socket.error,
        title: parseTranslation('label-market-depth'),
        titleTableCount: parseTranslation('label-count'),
        titleTableAmount: parseTranslation('label-amount'),
        titleTableTotal: parseTranslation('label-total'),
        titleTablePrice: parseTranslation('label-price'),
        titleBuySellOrders: parseTranslation('label-buy-sell-orders'),
        titleBuyOrders: parseTranslation('label-buy-orders'),
        titleSellOrders: parseTranslation('label-sell-orders'),
    };
}

function mapDispatchToProps(dispatch) {
    function setActiveView() {
        dispatch(setActiveViewAction('dom-expanded'));
    }

    function openNotificationModal() {
        dispatch(openNotificationModalAction());
    }

    function setIsBuyTablePinnedToBottom(isBuyTablePinnedToBottom) {
        dispatch(setIsBuyTablePinnedToBottomAction(isBuyTablePinnedToBottom));
    }

    function updateTablesVisibility(isTableBuyVisible, isTableSellVisible) {
        dispatch(
            updateTablesVisibilityAction(isTableBuyVisible, isTableSellVisible)
        );
    }

    function openSymbolsModalAction() {
        dispatch(openSymbolsModal());
    }

    return {
        setActiveView,
        openNotificationModal,
        setIsBuyTablePinnedToBottom,
        updateTablesVisibility,
        openSymbolsModalAction,
    };
}

class BuyGrad extends Component {
    render() {
        const {volumePercentage} = this.props;

        return (
            <tr>
                <td
                    style={{
                        zIndex: 20,
                        backgroundImage: `linear-gradient(to right, rgba(0, 227, 106, 0.15) 0%, rgba(0, 227, 106, 0.15) ${volumePercentage}%, rgba(0, 0, 0, 0) 0%)`,
                    }}
                />
            </tr>
        );
    }
}

class Buy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipActive: false,
        };

        this.timerId = null;
    }

    showTooltip() {
        this.setState(
            {isTooltipActive: false},

            () => {
                this.timerId = setTimeout(() => {
                    this.setState({isTooltipActive: true});
                }, 2000);
            }
        );
    }

    hideTooltip() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.setState({isTooltipActive: false});
    }

    render() {
        const {count, amount, total, price} = this.props;
        const {isTooltipActive} = this.state;
        const {isTooltipLeaveTable} = this.props;

        const tooltipId = `buy-${total}`.replace('.', '');

        let style = {
            style: {
                background: 'rgba(0,0,0,0)',
                boxShadow: '0 0 0 rgba(0,0,0,0)',
                left: '0',
                transition: 'none',
                visibility: 'none',
            },
            arrowStyle: {
                display: 'none',
                borderColor: 'none',
            },
        };

        return (
            <>
                <ToolTip
                    active={isTooltipActive && isTooltipLeaveTable}
                    position="left"
                    style={style}
                    parent={'#' + tooltipId}
                    group="buy"
                    tooltipTimeout={0}
                >
                    <TableTooltipDepthBuy
                        count={count}
                        amount={<FormattedPrice price={amount} />}
                        total={<FormattedPrice price={total} />}
                        price={<FormattedPrice price={price} />}
                    />
                </ToolTip>

                <tr
                    id={tooltipId}
                    onMouseEnter={this.showTooltip.bind(this)}
                    onMouseLeave={this.hideTooltip.bind(this)}
                    onWheel={this.hideTooltip.bind(this)}
                    style={{
                        position: 'relative',
                    }}
                >
                    <td className="column-count">
                        <i className="icon-single-up-pointing-angle" />
                        {count}
                    </td>
                    <td>
                        <FormattedPrice price={amount} />
                    </td>
                    <td>
                        <FormattedPrice price={total} />
                    </td>
                    <td >
                        {String(price).split('.').length > 1
                            ? <>{String(price).split('.')[0]}.<span className="buy-last-char">{String(price).split('.')[1].length > 1 ? String(price).split('.')[1] : `.${String(price).split('.')[1]}0`}</span></>
                            : <>{String(price)}<span className="buy-last-char">.00</span></>
                        }
                    </td>
                </tr>
            </>
        );
    }
}

class SellGrad extends Component {
    render() {
        const {volumePercentage} = this.props;

        return (
            <tr>
                <td
                    style={{
                        zIndex: 20,
                        backgroundImage: `linear-gradient(90deg, rgba(225, 77, 77, 0.15) 0%, rgba(225, 77, 77, 0.15) ${volumePercentage}%, rgba(0, 0, 0, 0) 0%)`,
                    }}
                />
            </tr>
        );
    }
}

class Sell extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipActive: false,
        };

        this.timerId = null;
    }

    showTooltip() {
        this.setState(
            {isTooltipActive: false},

            () => {
                this.timerId = setTimeout(() => {
                    this.setState({isTooltipActive: true});
                }, 2000);
            }
        );
    }

    hideTooltip() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.setState({isTooltipActive: false});
    }

    render() {
        const {count, amount, total, price} = this.props;
        const {isTooltipActive} = this.state;
        const {isTooltipLeaveTable} = this.props;

        const tooltipId = `buy-${total}`.replace('.', '');

        let style = {
            style: {
                background: 'rgba(0,0,0,0)',
                boxShadow: '0 0 0 rgba(0,0,0,0)',
                left: '0',
                transition: 'none',
                visibility: 'none',
            },
            arrowStyle: {
                display: 'none',
                borderColor: 'none',
            },
        };

        return (
            <>
                <ToolTip
                    active={isTooltipActive && isTooltipLeaveTable}
                    position="left"
                    style={style}
                    parent={'#' + tooltipId}
                    group="buy"
                    tooltipTimeout={0}
                >
                    <TableTooltipDepthBuy
                        count={count}
                        amount={<FormattedPrice price={amount} />}
                        total={<FormattedPrice price={total} />}
                        price={<FormattedPrice price={price} />}
                    />
                </ToolTip>
                <tr
                    id={tooltipId}
                    onMouseEnter={this.showTooltip.bind(this)}
                    onMouseLeave={this.hideTooltip.bind(this)}
                    onWheel={this.hideTooltip.bind(this)}
                >
                    <td className="column-count">
                        <i className="icon-single-down-pointing-angle" />
                        {count}
                    </td>
                    <td>
                        <FormattedPrice price={amount} />
                    </td>
                    <td>
                        <FormattedPrice price={total} />
                    </td>
                    <td>
                        {String(price).split('.').length > 1
                            ? <>{String(price).split('.')[0]}.<span className="sell-last-char">{String(price).split('.')[1].length > 1 ? String(price).split('.')[1] : `.${String(price).split('.')[1]}0`}</span></>
                            : <>{String(price)}<span className="sell-last-char">.00</span></>
                        }
                    </td>
                </tr>
            </>
        );
    }
}

class DOM extends Component {
    constructor() {
        super();
        this.openNotificationModal = this.openNotificationModal.bind(this);

        this.scrollRef = React.createRef();

        this.state = {
            shouldUpdateScrollPosition: true,
            isTooltipLeaveTable: false,
        };

        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleTooltip() {
        this.setState(state => ({
            isTooltipLeaveTable: !state.isTooltipLeaveTable,
        }));
    }

    componentDidMount() {
        if (this.scrollRef.current) {
            setTimeout(() => {
                if (
                    this.scrollRef.current &&
                    this.scrollRef.current.hasOwnProperty('scrollToBottom')
                ) {
                    this.scrollRef.current.scrollToBottom();
                }
            }, 0);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.scrollRef.current) {
            if (
                prevProps.isTableSellVisible &&
                !this.props.isTableSellVisible
            ) {
                setTimeout(() => {
                    this.scrollRef.current.scrollToBottom();
                }, 0);
            }

            if (
                this.state.shouldUpdateScrollPosition ||
                (!prevProps.isTableSellVisible && this.props.isTableSellVisible)
            ) {
                setTimeout(() => {
                    if (this.scrollRef.current) {
                        this.scrollRef.current.scrollToBottom();
                    }
                }, 0);
            }
        }
    }

    render() {
        const {
            isPanging,
            socketError,
            selectedSymbol,
            openSymbolsModalAction,
        } = this.props;

        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-market-depth" />
            </button>
        );

        return (
            <Panel
                icon={icon}
                title={this.props.title}
                expandable
                expandHandler={this.props.setActiveView}
                className="panel_DOM"
            >
                <>
                    {isPanging && !socketError ? (
                        <Preloader />
                    ) : (
                        <>
                            <div className="market-depth">
                                <div className="currency-pair-modal">
                                    <CurrencyPair
                                        firstCurrency={selectedSymbol.numerator}
                                        secondCurrency={
                                            selectedSymbol.denominator
                                        }
                                        onClick={openSymbolsModalAction}
                                    />
                                </div>

                                <div className="tabs-dom">
                                    <button
                                        onClick={() =>
                                            this.props.updateTablesVisibility(
                                                true,
                                                true
                                            )
                                        }
                                    >
                                        <i className="icon-buy-sell-up" />
                                        <i className="icon-buy-sell-down" />
                                        <div className="tooltip-buy-one">
                                            {this.props.titleBuySellOrders}
                                        </div>
                                    </button>

                                    <button
                                        onClick={() =>
                                            this.props.updateTablesVisibility(
                                                true,
                                                false
                                            )
                                        }
                                    >
                                        <i className="icon-buy-up" />
                                        <div className="tooltip-buy-one">
                                            {this.props.titleBuyOrders}
                                        </div>
                                    </button>

                                    <button
                                        onClick={() =>
                                            this.props.updateTablesVisibility(
                                                false,
                                                true
                                            )
                                        }
                                    >
                                        <i className="icon-sell-down" />
                                        <div className="tooltip-buy-one">
                                            {this.props.titleSellOrders}
                                        </div>
                                    </button>
                                </div>
                            </div>

                            {/*
                        TODO: Max - Table alignment example
                        TODO: Move height calc to styles (Max)
                    */}

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: 'calc(100% - 7.5rem)',
                                }}
                            >
                                {socketError && <ErrorContainer />}
                                {this.renderTableHead()}
                                {this.props.isTableBuyVisible &&
                                    !this.props.isTableSellVisible &&
                                    this.renderTableBuy()}

                                {this.props.isTableSellVisible &&
                                    !this.props.isTableBuyVisible &&
                                    this.renderTableSell()}

                                {this.props.isTableSellVisible &&
                                    this.props.isTableBuyVisible && (
                                        <Fragment>
                                            {this.renderTableBuy()}
                                            {this.renderTableSell()}
                                        </Fragment>
                                    )}
                            </div>
                        </>
                    )}

                    <NotificationModal />
                </>
            </Panel>
        );
    }

    openNotificationModal() {
        this.props.openNotificationModal();
    }

    renderTableHead() {
        return (
            <table>
                <thead>
                    <tr style={{backgroundColor: 'transparent'}}>
                        {/*<th style={{width: '2rem'}} />*/}
                        <th>{this.props.titleTableCount}</th>
                        <th>{this.props.titleTableAmount}</th>
                        <th>{this.props.titleTableTotal}</th>
                        <th>{this.props.titleTablePrice}</th>
                    </tr>
                </thead>
            </table>
        );
    }

    renderTableBuy() {
        const {orders, socketError} = this.props;
        const {buy: rawBuyData} = orders;

        const buyTotal = rawBuyData.reduce(
            (result, order) => result + order.amount,
            0
        );

        const {isTooltipLeaveTable} = this.state;

        const buy = rawBuyData
            .sort(this.sortDESC)
            .reduce((result, order, index) => {
                let volume = order.amount;
                if (index) {
                    volume += result[index - 1].volume;
                }

                const volumePercentage = (volume / buyTotal) * 100;

                result.push({
                    ...order,
                    volume,
                    volumePercentage,
                });

                return result;
            }, [])
            .reverse();

        // TODO: need optimization here - we are iterating through an array 2 times
        const entriesBuy = buy.map((buy, index) => (
            <Buy
                key={index}
                {...buy}
                isTooltipLeaveTable={isTooltipLeaveTable}
            />
        ));

        const entriesBuyGrad = buy.map((buy, index) => (
            <BuyGrad
                key={index}
                {...buy}
                isTooltipLeaveTable={isTooltipLeaveTable}
            />
        ));

        return (
            <div
                className="container-flex table-buy-container"
                style={{position: 'relative'}}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                    }}
                >
                    <Scrollbars
                        refScroll={this.scrollRef}
                        onScroll={e => {
                            const {
                                scrollHeight,
                                scrollTop,
                                clientHeight,
                            } = e.target;
                            const offset =
                                scrollTop + clientHeight - scrollHeight;

                            if (isNaN(offset)) {
                                return;
                            }

                            this.setState({
                                shouldUpdateScrollPosition: !offset,
                            });
                        }}
                    >
                        <div
                            className="container-table-buy"
                            style={{
                                position: 'relative',
                            }}
                        >
                            <table
                                className="table-buy-grad table-grad-zebra"
                                onMouseLeave={this.toggleTooltip}
                                onMouseEnter={this.toggleTooltip}
                            >
                                <tbody>{entriesBuyGrad}</tbody>
                            </table>
                            <table
                                className="table-buy"
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                }}
                                onMouseLeave={this.toggleTooltip}
                                onMouseEnter={this.toggleTooltip}
                            >
                                <tbody>{entriesBuy}</tbody>
                            </table>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }

    renderTableSell() {
        const {orders} = this.props;
        const {sell: rawSellData} = orders;

        const sellTotal = rawSellData.reduce(
            (result, order) => result + order.amount,
            0
        );
        const sell = rawSellData
            .sort(this.sortASC)
            .reduce((result, order, index) => {
                let volume = order.amount;
                if (index) {
                    volume += result[index - 1].volume;
                }

                const volumePercentage = (volume / sellTotal) * 100;

                result.push({
                    ...order,
                    volume,
                    volumePercentage,
                });

                return result;
            }, []);

        const {isTooltipLeaveTable} = this.state;

        const entriesSell = sell.map((sell, index) => (
            <Sell
                key={index}
                {...sell}
                isTooltipLeaveTable={isTooltipLeaveTable}
            />
        ));

        const entriesSellGrad = sell.map((sell, index) => (
            <SellGrad
                key={index}
                {...sell}
                isTooltipLeaveTable={isTooltipLeaveTable}
            />
        ));

        return (
            <div
                className="container-flex table-sell-container"
                style={{
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                    }}
                >
                    <Scrollbars>
                        <div
                            className="container-table-sell"
                            style={{
                                position: 'relative',
                                marginTop: 'auto',
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                }}
                            >
                                <table
                                    className="table-sell-grad table-grad-zebra"
                                    onMouseLeave={this.toggleTooltip}
                                    onMouseEnter={this.toggleTooltip}
                                >
                                    <tbody>{entriesSellGrad}</tbody>
                                </table>
                                <table
                                    className="table-sell"
                                    onMouseLeave={this.toggleTooltip}
                                    onMouseEnter={this.toggleTooltip}
                                >
                                    <tbody>{entriesSell}</tbody>
                                </table>
                            </div>
                        </div>
                    </Scrollbars>
                </div>
            </div>
        );
    }

    sortASC(a, b) {
        return a.price - b.price;
    }
    sortDESC(a, b) {
        return b.price - a.price;
    }
}

const ConnectedDOM = connect(
    mapStateToProps,
    mapDispatchToProps
)(DOM);
export default ConnectedDOM;
