import React, {Component} from 'react';
import {connect} from 'react-redux';
import Preloader from 'infrastructure/view/component/Preloader';

import ToolTip from 'react-portal-tooltip';
import AnimateOnChange from 'lib/react-animate-on-change/index';

import {TableTooltip} from '../../../../../component/TableTooltip/index';
import FormattedPrice from '../../../../../component/FormattedPrice/FormattedPrice';

import {
    setFavoriteCurrency,
    sendSettingsData,
} from '../../../../../services/action/settings';

import {toggleSymbolFavorite} from '../service/actions';
import ErrorContainer from '../../../../../component/Error/ErrorContainer';
import {changeCurrency} from '../../../../../services/action/currency';

function mapStateToProps(state) {
    const {favoriteCurrency} = state.settings;
    const {isPending} = state.symbols;

    return {
        symbols: Object.values(state.symbols.symbols).sort(
            (a, b) => b.price - a.price
        ),
        favoriteCurrency,
        isPending,
        socketError: state.socket.error,
    };
}

const mapDispatchToProps = {
    toggleSymbolFavorite,
    setFavoriteCurrency,
    sendSettingsData,
    changeCurrency,
};

class Symbol extends Component {
    static defaultProps = {
        positionTooltip: 'right',
    };

    state = {
        price: this.props.price,
        isTooltipActive: false,
        isFavorite: this.props.favoriteCurrency,
    };

    constructor(props) {
        super(props);
        this.timerId = null;
    }

    favoriteHandler() {
        this.setState({isFavorite: !this.state.isFavorite});
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextProps.price !== this.props.price ||
            nextState.isTooltipActive !== this.state.isTooltipActive
        ) {
            return true;
        } else if (nextState.isFavorite !== this.state.isFavorite) return true;
        return false;
    }

    static getDerivedStateFromProps(props, state) {
        if (state.price !== props.price) {
            return {
                shouldPlayAnimation: state.price !== props.price,
                animationClassName:
                    props.price > state.price ? 'quadrat-green' : 'quadrat-red',
                price: props.price,
                favorite: props.favorite,
            };
        }

        return null;
    }

    showTooltip() {
        this.setState(
            {
                isTooltipActive: false,
            },

            () => {
                this.timerId = setTimeout(() => {
                    this.setState({isTooltipActive: true});
                }, 2000);
            }
        );
    }

    hideTooltip() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        this.setState({isTooltipActive: false});
    }

    handleTrClick = nextSymbol => () => {
        const {changeCurrency} = this.props;

        changeCurrency(nextSymbol);
    };

    render() {
        const {isTooltipActive} = this.state;

        const {
            id,
            change,
            price,
            volume,
            toggleSymbolFavorite,
            setFavoriteCurrency,
            sendSettingsData,
            isTooltipLeaveTable,
            disableTooltip,
            positionTooltip,
        } = this.props;

        const isTooltipShow =
            isTooltipActive && isTooltipLeaveTable && !disableTooltip;

        const [numerator, denominator] = id.split('/');

        let COLOR_RED = '#FF4D4D';
        let COLOR_GREEN = '#00B456';

        const tooltipId = id.replace('/', '');

        let style = {
            style: {
                display: `${isTooltipShow ? 'block' : 'none'}`,
                background: 'rgba(0,0,0,0)',
                boxShadow: '0 0 0 rgba(0,0,0,0)',
                left: '0',
                transition: 'none',
                visibility: 'none',
            },
            arrowStyle: {
                display: 'none',
                borderColor: 'none',
            },
        };

        return (
            <>
                <ToolTip
                    active={isTooltipShow}
                    position={positionTooltip}
                    parent={'#' + tooltipId}
                    style={style}
                    group="popular"
                    tooltipTimeout={0}
                >
                    <TableTooltip
                        positionTooltip={positionTooltip}
                        price={<FormattedPrice price={price} />}
                        volume={<FormattedPrice price={volume} />}
                        symbol={

                                <span className="currency-pair-symbols">
                                    <i
                                        className={` icon-currency icon-currency-left icon-${numerator.toLowerCase()}`}
                                    />
                                    <span className="currency-pair-title">
                                        {id}
                                    </span>
                                    <i
                                        className={`icon-currency icon-currency-right icon-${denominator.toLowerCase()}`}
                                    />
                                </span>

                        }
                        change={
                            <span
                                style={{
                                    color:
                                        change >= 0 ? COLOR_GREEN : COLOR_RED,
                                }}
                            >
                                {change && parseFloat(change.toFixed(2))}%
                            </span>
                        }
                    />
                </ToolTip>

                <AnimateOnChange
                    baseClassName="quadrat"
                    animationClassName={
                        this.state && this.state.animationClassName
                    }
                    animate={this.state && this.state.shouldPlayAnimation}
                    customTag="tr"
                    otherProps={{
                        id: tooltipId,
                        onMouseEnter: this.showTooltip.bind(this),
                        onMouseLeave: this.hideTooltip.bind(this),
                        onClick: this.handleTrClick(id),
                    }}
                >
                    <td
                        style={{
                            width: '30%',
                            paddingLeft: '2rem',
                            textAlign: 'left',
                        }}
                    >
                        <span className="currency-pair-symbols">
                            <i
                                className={` icon-currency icon-currency-left icon-${numerator.toLowerCase()}`}
                            />
                            <span
                                style={{
                                    fontSize: '2.3rem',
                                }}
                            >
                                {id}
                            </span>
                            <i
                                className={`icon-currency icon-currency-right icon-${denominator.toLowerCase()}`}
                            />
                        </span>
                    </td>
                    <td
                        style={{
                            width: '21%',
                            textAlign: 'right',
                            paddingLeft: '2rem',
                        }}
                    >
                        <FormattedPrice price={price} />
                    </td>
                    <td
                        style={{
                            width: '25%',
                            textAlign: 'right',
                            paddingLeft: '2rem',
                        }}
                    >
                        <FormattedPrice price={volume} />
                    </td>
                    <td
                        style={{
                            width: '15%',
                            textAlign: 'right',
                            color: change >= 0 ? COLOR_GREEN : COLOR_RED,
                        }}
                    >
                        {change && parseFloat(change.toFixed(2))}%
                    </td>
                    <td
                        style={{
                            width: '9%',
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                            toggleSymbolFavorite(id);
                            setFavoriteCurrency(id);
                            sendSettingsData();
                            this.favoriteHandler();
                        }}
                    >
                        <i
                            className={
                                this.state.isFavorite
                                    ? 'icon-star-fill'
                                    : 'icon-star-empty'
                            }
                        />
                    </td>
                </AnimateOnChange>
            </>
        );
    }
}

class Popular extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltipLeaveTable: false,
        };

        this.tableRefHide = React.createRef();
        this.toggleTooltip = this.toggleTooltip.bind(this);
    }

    toggleTooltip() {
        this.setState(state => ({
            isTooltipLeaveTable: !state.isTooltipLeaveTable,
        }));
    }

    render() {
        const {isTooltipLeaveTable} = this.state;

        const {
            symbols,
            toggleSymbolFavorite,
            favoriteCurrency,
            isPending,
            disableTooltip,
            socketError,
            positionTooltip,
            changeCurrency,
        } = this.props;

        const entries = symbols.map(symbol => (
            <Symbol
                disableTooltip={disableTooltip}
                positionTooltip={positionTooltip}
                key={symbol.id}
                {...symbol}
                isTooltipLeaveTable={isTooltipLeaveTable}
                favoriteCurrency={favoriteCurrency.includes(symbol.id)}
                setFavoriteCurrency={id => {
                    this.props.setFavoriteCurrency(id);
                }}
                toggleSymbolFavorite={toggleSymbolFavorite}
                sendSettingsData={() => this.props.sendSettingsData()}
                changeCurrency={changeCurrency}
            />
        ));

        return (
            <>
                {isPending && !socketError ? (
                    <Preloader />
                ) : (
                    <>
                        {socketError && <ErrorContainer />}
                        <table
                            ref={this.tableRefHide}
                            onMouseLeave={this.toggleTooltip}
                            onMouseEnter={this.toggleTooltip}
                        >
                            <tbody style={{fontSize: '2.3rem'}}>
                                {entries}
                            </tbody>
                        </table>
                    </>
                )}
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Popular);
