import styled from 'styled-components';
import {opacityAnimation} from '../../DateTime/styled';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    opacity: 0;
    animation: ${opacityAnimation} 1s linear;
    animation-delay: 1s;
    animation-fill-mode: forwards;
`;

export default Wrapper;
