import {ActionType} from '../action/translation';

const initialState = {
    isPendingLanguage: true,
    isPendingTranslation: true,
    local: localStorage.getItem('local') || 'en-US',
    language: [],
    translation: [],
    errorLanguage: null,
    errorTranslation: null,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ActionType.setLocal:
            return {...state, local: action.local};

        case ActionType.getLanguage:
            return {
                ...state,
                isPendingLanguage: true,
            };

        case ActionType.setLanguage:
            return {
                ...state,
                isPendingLanguage: false,
                language: action.language,
            };

        case ActionType.errorLanguage:
            return {
                ...state,
                isPendingLanguage: false,
                errorLanguage: action.payload,
            };

        case ActionType.getTranslation:
            return {
                ...state,
                isPendingTranslation: true,
            };

        case ActionType.setTranslation:
            return {
                ...state,
                isPendingTranslation: false,
                translation: action.translation,
            };

        case ActionType.errorTranslation:
            return {
                ...state,
                isPendingTranslation: false,
                errorTranslation: action.payload,
            };

        default:
            return {...state};
    }
}
