import {ActionTypes} from './actions';

const INITIAL_STATE = {
    isPendingOpened: true,
    isPendingClosed: true,
    shouldLoadHistory: true,
    openedOrders: null,
    closedOrders: null,
    page: 1,
    errorOpenedOrders: null,
    errorClosedOrders: null,
    errorWSCancelOrder: [],
};

function TradingHistoryReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.setOpenedOrders: {
            const openedOrders = action.orders.reduce((result, order) => {
                result[order.id] = {
                    ...order,
                    openingTime: order.openingTime,
                };

                return result;
            }, {});

            return {
                ...state,
                openedOrders,
                isPendingOpened: false,
            };
        }

        case ActionTypes.openOrderSuccess: {
            const order = {
                ...action.payload,
                highlightNew: true,
                openingTime: action.payload.openingTime,
            };

            return {
                ...state,
                openedOrders: {
                    ...state.openedOrders,
                    [order.id]: order,
                },
            };
        }

        case ActionTypes.updateOrder: {
            let order;
            const precisionDivisions = 10000000000000000;
            const {limit} = action.symbols.symbols[action.order.symbol];


            if (state.openedOrders) {
                order = state.openedOrders[action.order.id];
            }

            if (!order) {
                return state;
            }

            const amountSummary = (order.amount * precisionDivisions - action.order.amount * precisionDivisions) / precisionDivisions;

            if (
                action.order.amount >= order.amount || limit > amountSummary
            ) {
                const openedOrders = {...state.openedOrders};
                delete openedOrders[action.order.id];

                return {
                    ...state,
                    openedOrders,
                };
            } else {
                return {
                    ...state,
                    openedOrders: {
                        ...state.openedOrders,
                        [action.order.id]: {
                            ...order,
                            amount: amountSummary,
                        },
                    },
                };
            }
        }

        case ActionTypes.dropOrderHighlight: {
            const order = state[action.id];

            if (!order) {
                return state;
            }

            return {
                ...state,
                [action.id]: {
                    ...order,
                    highlightNew: false,
                    highlightUpdate: order.highlightNew
                        ? order.highlightUpdate
                        : false,
                },
            };
        }

        case ActionTypes.removeOrderFromList: {
            const openedOrders = {...state.openedOrders};
            delete openedOrders[action.id];

            return {
                ...state,
                openedOrders,
                errorWSCancelOrder: state.errorWSCancelOrder.filter(item => item !== action.id),
            };
        }

        case ActionTypes.setClosedOrders: {
            if (!action.orders.length) {
                return {
                    ...state,
                    shouldLoadHistory: false,
                    isPendingClosed: false,
                };
            }

            const sortedOrders = action.orders.sort((a, b) =>
                a.closTime.isBefore(b.closTime) ? 1 : -1
            );

            if (state.closedOrders === null) {
                return {
                    ...state,
                    page: state.page + 1,
                    isPendingClosed: false,
                    closedOrders: [...sortedOrders],
                };
            }

            return {
                ...state,
                page: state.page + 1,
                isPendingClosed: false,
                closedOrders: [...state.closedOrders, ...sortedOrders],
            };
        }

        case ActionTypes.errorClosedOrders: {
            return {
                ...state,
                isPendingClosed: false,
                closedOrders: null,
                page: 1,
                errorClosedOrders: action.payload,
            }
        }

        case ActionTypes.errorOpenOrders: {
            return {
                ...state,
                isPendingOpened: false,
                errorOpenedOrders: action.payload,
            }
        }

        case ActionTypes.clearClosedOrders: {
            const { openedOrders } = state;

            return {
                ...INITIAL_STATE,
                openedOrders
            }
        }

        case ActionTypes.CANCEL_ORDER_ERROR: {
            return {
                ...state,
                errorWSCancelOrder: [
                    ...state.errorWSCancelOrder,
                    action.payload,
                ],
            }
        }

        case ActionTypes.CLEAR_CANCEL_ORDER_ERROR: {
            return {
                ...state,
                errorWSCancelOrder: state.errorWSCancelOrder.filter(item => item !== action.payload),
            }
        }

        // TODO: add reaction to SYMBOL-CHANGE action -> clear history ???

        default:
            return state;
    }
}

export default TradingHistoryReducer;
