// @flow
import React, {Component} from 'react';
import classnames from 'classnames';

import parseTranslation from 'infrastructure/adapter/helper/parseTranslation';
import { notificationMessageMap } from 'settings';

export type Notification = {
    id: number,
    code: string,
    currency: string,
    amount: string,
    isVisible: boolean,
};

type PropsType = {
    notification: Notification,
    close: () => mixed,
};

type StateType = {
    className: string,
};

const POPUP_NOTIFICATION_LIFETIME = '2000';

class NotificationItem extends Component<PropsType, StateType> {
    constructor(props) {
        super(props);

        this.state = {
            className: false,
        };

        this.closeNotification = this.closeNotification.bind(this);
    }

    componentDidMount(): void {
        const {notification, close} = this.props;

        setTimeout(() => {
            this.setState({
                className: 'hideAnimation',
            });
        }, POPUP_NOTIFICATION_LIFETIME - 300);

        setTimeout(() => {
            close(notification.id);
        }, POPUP_NOTIFICATION_LIFETIME);
    }

    closeNotification() {
        const {notification, close} = this.props;
        close(notification.id);
    }

    render() {
        const {className} = this.state;
        const {notification} = this.props;
        const {code} = notification;

        const translationTitle = `${notificationMessageMap[code]}_title`;
        const translationBody = `${notificationMessageMap[code]}_body`;

        const messageBody = parseTranslation(
            JSON.stringify({[translationBody]: notification})
        );

        return (
            <div
                className={classnames('notification-item', {
                    hideAnimation: className,
                })}
            >
                <div className="notification_title">
                    {parseTranslation(translationTitle)}
                </div>
                <div className="notification_body">{messageBody}</div>
                <div className="notification_close-btn">
                    <i
                        className="icon-close cursor-pointer"
                        onClick={this.closeNotification}
                    />
                </div>
            </div>
        );
    }
}

export default NotificationItem;
