// @flow
import type {
    RegistrationFormAdapterInterface as RegistrationFormAdapter
} from '../../../application/adapter/RegistrationForm';

class SubmitRegistrationForm {
    adapter: RegistrationFormAdapter;

    constructor(adapter: RegistrationFormAdapter) {
        this.adapter = adapter;
    }

    async invoke(formData: {} = {}, locale: string) {
        try {
            const { data } = await this.adapter.sendForm(formData, locale);

            return data;
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response;

                if (status && status === 422) {
                    return data;
                }
            }

            throw error;
        }
    }
}

export default SubmitRegistrationForm;
