import {combineReducers} from 'redux';

import orderForm from './reducer-order-form';
import theme from './reducer-theme';
import chartView from './reducer-chart-view';

const chartReducer = combineReducers({
    chartView,
    orderForm,
    theme,
});

export default chartReducer;
