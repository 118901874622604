import React, {useState, useRef} from 'react';
import styled, {css} from 'styled-components';

export default ({onChange}) => {
    const inputRef = useRef(null);
    const [value, setValue] = useState(1);
    const [isFocus, setFocus] = useState(false);

    const setValueValidation = inputValue => {
        if (+inputValue >= 7) {
            setValue(7);
        } else if (+inputValue <= -1) {
            setValue(0);
        } else {
            setValue(+inputValue);
        }
    };

    const incrementHandler = () => {
        inputRef && inputRef.current.focus();
        setValueValidation(+value + 1);
        onChange(+value + 1);
    };
    const decrementValue = () => {
        inputRef && inputRef.current.focus();
        setValueValidation(+value - 1);
        onChange(+value - 1);
    };

    const inputHandler = ({target: {value}}) => {
        if (value.match(/^[0-9]+$/) !== null || value === '') {
            setValueValidation(+value);
            onChange(+value);
        }
    };

    const focusHandler = () => setFocus(true);
    const blurHandler = () => {
        value === 0 && setValue(1);
        setFocus(false);
    };

    return (
        <Width isFocus={isFocus}>
            <WidthInput
                ref={inputRef}
                type="text"
                onChange={()=>{}}
                value={value}
                onInput={inputHandler}
                onFocus={focusHandler}
                onBlur={blurHandler}
            />
            <Increment
                onClick={incrementHandler}
                className="icon-single-up-pointing-angle"
            />
            <Decrement
                onClick={decrementValue}
                className="icon-single-down-pointing-angle"
            />
        </Width>
    );
};

const Width = styled.div`
    width: 8.33rem;
    height: 5.833rem;
    margin-right: 1rem;
    border: 1px solid ${p => p.theme.color7};
    background: ${p => p.theme.color7};
    box-sizing: border-box;
    text-align: center;
    font-size: 2.3rem;
    color: ${p => p.theme.color65};
    position: relative;
    display: flex;
    align-items: center;    

    ${({isFocus}) =>
        isFocus &&
        css`
            border: 1px solid ${p => p.theme.color1};
            outline: none;
        `}
`;

const WidthInput = styled.input`
    border: none;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    padding: 0 1rem;
    width: 92%;
    font-size: 2.3rem;
    color: ${p => p.theme.color14};
    background: ${p => p.theme.color7};
     box-sizing: border-box;
    &:focus {
        outline: none;
    }
`;

const Increment = styled.div`
    position: absolute;
    top: 0.4rem;
    right: 1.2rem;
    width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.8rem;
`;
const Decrement = styled.div`
    position: absolute;
    bottom: 0.4rem;
    right: 1.2rem;
    width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.8rem;
`;
