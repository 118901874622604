// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {TicketsAdapterInterface as TicketsAdapter} from '../../../application/adapter/Tickets';

class GetTicketImageVerification {
    adapter: TicketsAdapter;

    constructor(adapter: TicketsAdapter) {
        this.adapter = adapter;
    }

    async invoke(token: AccessToken, code: string) {
        try {
            const response = await this.adapter.getTicketImageVerification(token, code);

            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default GetTicketImageVerification;
