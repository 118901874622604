class GetTwoFAService {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(token: string): Promise<any> {
        try {
            const { data } = await this.adapter.getTwoFAService(token);
            return data;
        } catch (e) {
            throw e
        }
    }
}

export default GetTwoFAService;
