import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import {getAccessToken} from 'utils/auth';
import {getLanguage, getTranslation} from './services/action/translation';
import {getSettingsData} from './services/action/settings';

import Login from './scene/Login/Login';
import Registration from './scene/Registration/Registration';
import RegistrationConfirmation from './scene/Registration/Confirmation';
import Recovery from './scene/RecoveryForm/Recovery';
import RecoveryPasswordForm from './scene/RecoveryForm/RecoveryPasswordForm';
import Dashboard from './scene/Dashboard/Dashboard';
import Billing from './scene/Billing/Billing';
import Tickets from './scene/Tickets/Tickets';
import Chat from './scene/Chat/Chat';
import Settings from './scene/Settings/Settings';
import Listing from './scene/Listing/Listing';
import GuestDashboardContainer from '../../guest/GuestDashboard';
import Header from './component/Header';
import {logout} from './services/action/user';
import config from '../../config';

export default () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const local = useSelector(state => state.translation.local) || 'en-Us';
    const token = useSelector(state => state.user.token);
    // const getCookie = (name) => {
    //     var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    //     return v ? v[2] : null;
    // };
    // if (getCookie('isLogout') === 'true') {
    //     dispatch(logout());
    //     window.location.href = `${config.workspaceUrl}/login`
    // }

    const confirmRender = ({match}) => {
        const {username, token} = match.params;
        return <RegistrationConfirmation username={username} token={token} />;
    };
    //
    // useEffect(() => {
    //     localStorage.getItem('userRegion')
    //     && JSON.parse(localStorage.getItem('userRegion')).accessToken
    //     && dispatch(getSettingsData());
    //     // !getAccessToken() && dispatch(getTranslation());
    // }, [confirmRender, local]);

    const sitePair = history.location.pathname.split('/').length > 1 ? history.location.pathname.split('/')[2] : '';
    const data =
        history.location.search &&
        new URLSearchParams(history.location.search).get('data');
    if (data) {
        localStorage.removeItem('userRegion');
    }

    return !!getAccessToken() ? (
        <div className="logged-in">
            <Header isDashboard={history.location.pathname.includes('dashboard')} sitePair={sitePair} />
            <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/dashboard/:pair" component={({match}) => <Dashboard sitePair={match.params.pair} />}/>
                <Route exact path="/billing" component={Billing} />
                <Route path="/tickets" component={Tickets} />
                {/*<Route exact path="/chat" component={Chat} />*/}
                <Route path="/settings" component={Settings} />
                <Route path="/listing" component={Listing} />
                <Redirect to="/dashboard" />
            </Switch>
        </div>
    ) : (
        <Switch>
            <Route exact path="/market/:pair" render={({match}) => <GuestDashboardContainer sitePair={match.params.pair} />} />
            <Route exact path="/market" component={GuestDashboardContainer} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/registration" component={Registration} />
            <Route path="/recovery" component={Recovery} />
            <Route
                path="/recovery-confirmation"
                component={RecoveryPasswordForm}
            />
            <Route
                exact
                path="/register/confirmation/:username/:token"
                render={confirmRender}
            />
            <Redirect to="/market" />
        </Switch>
    );
};
