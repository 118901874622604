import styled, {css} from 'styled-components';

export const LangWrapper = styled.div`
    width: 3.6666rem;
    height: 2.5rem;
    padding: 1rem;
    cursor: pointer;

    .icon {
        display: block;
        width: 100%;
        height: 100%;
        display: block;
    }
`;

export const LangDropdown = styled.div`
    position: absolute;
    background: ${(props) => props.theme.color23};
    z-index: 1003;
    border: 1px solid ${(props) => props.theme.color1};
    width: 38.3333rem;
    box-shadow: 0 0 2.4rem ${(props) => props.theme.color66};
    ${(p) =>
        !p.leftBottom &&
        css`
            left: 120%;
            bottom: -2.5rem;
            z-index: 1002;
        `}

    ${(p) =>
        p.leftBottom &&
        css`
            top: -3rem;
            right: 7.7rem;
        `}
    ${({centerBottom}) =>
        centerBottom &&
        css`
            top: 100%;
            left: 50%;
            bottom: auto;
            top: 100%;
            transform: translateX(-50%);
        `}
`;

export const LangList = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        background: ${(props) => props.theme.color23};
        border: 1px solid ${(props) => props.theme.color1};
        border-bottom-color: transparent;
        border-right-color: transparent;

        ${(p) =>
            !p.leftBottom &&
            css`
                left: -1.5rem;
                bottom: 3.5rem;
                transform: rotate(-45deg);
            `}

        ${(p) =>
            p.leftBottom &&
            css`
                right: -1.5rem;
                top: 4rem;
                transform: rotate(135deg);
            `}
    }
    ${({centerBottom}) =>
        centerBottom &&
        css`
            &:after,
            &:before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                bottom: 100%;
                border: 2.5rem solid transparent;
                top: auto;
                left: 50%;
                background: 0;
                transform: translateX(-50%);
            }
            &:after {
                border-bottom-color: ${(props) => props.theme.color23};
                margin-bottom: -1px;
            }
            &:before {
                border-bottom-color: ${(props) => props.theme.color1};
            }
        `}
`;
export const LangLabel = styled.div`
    color: ${(props) => props.active && props.theme.color1};
`;
export const Lang = styled.div`
    display: flex;
    padding: 1rem 4rem;
    align-items: center;
    cursor: pointer;
    color: ${(props) => props.theme.color14};
    position: relative;
    font-size: 3rem;
    z-index: 30;

    &:hover {
        background: #e5e5e5;
        color: ${(props) => props.theme.color1};
    }

    .icon {
        width: 4rem;
        height: 2.3334rem;
        margin-right: 2rem;
        display: block;
        &-error {
            opacity: 0 !important;
            visibility: hidden !important;
        }
    }
`;
