import type Axios from 'axios';

import localConfig from 'localConfig';
import {getDomain} from 'utils';

import type {SettingsInterface} from '../../application/adapter/Settings';

class PasswordFormAdapter implements SettingsInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getSettings(token: string = ''): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/user/get-client-settings`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
            },
        });
    }

    async sendSettings(token: string = '', data): Promise<AccessToken> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/user/set-client-settings`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
            },
            data: data,
        });
    }
}

export default PasswordFormAdapter;
