import { ActionTypes } from './actions';

const INITIAL_STATE = {
    // activeLayoutIndex: 0
    activeLayoutIndex: 1
};

function chartReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.setActiveLayoutIndex:
            return {
                ...state,
                activeLayoutIndex: action.activeLayoutIndex
            };

        default:
            return state;
    }
}

export default chartReducer;
