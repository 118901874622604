import styled from 'styled-components';

export const SymbolIcon = styled.i`
    color: ${props => props.theme.color65};
`;
export const SymbolLabel = styled.span`
    padding: 0 1rem;
    color: ${props => props.theme.color15};
`;

export const SymbolButton = styled.div`
    font-size: ${({size}) =>
        (size === 'large' && '3rem') ||
        ((size === 'medium' || size === 'small') && '2.333rem')};
    background: ${({size, theme}) =>
    (size === 'large' && theme.color5) ||
    ((size === 'medium' || size === 'small') && theme.color7)};
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    height: ${({size}) =>
        (size === 'large' && '8.333rem') ||
        (size === 'medium' && '4.5rem') ||
        (size === 'small' && '5.83333rem')};
    padding: ${({size}) =>
        (size === 'large' || size === 'medium' || size === 'small') &&
        '0 4rem'};

    &:hover {
        background: ${props => props.theme.color69};
        color: ${props => props.theme.color20};

        ${SymbolIcon} {
            color: ${props => props.theme.color1};
        }
    }    
`;
