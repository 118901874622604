import historyProvider from './historyProvider';
import store from "infrastructure/view/services/store";
import {
    chartDXHistoryFailure,
    chartDXHistoryRequest,
} from "../../../../../services/action/DXChartHistoryProvider";
import {setChartInterval, sendSettingsData} from '../../../../../services/action/settings';


const supportedResolutions = ['1', '5', '15', '30', '60'];

const config = {
    supported_resolutions: supportedResolutions
};

function updateCandle() {
    let cacheLastCandle = null;
    let currentResolution;
    let currentUID;

    return (lastCandle, tick, resolution, UID) => {
        let previousResolution = currentResolution;
        let previousUID = currentUID;
        currentResolution = resolution;
        currentUID = UID;

        if (previousResolution !== currentResolution
            || previousUID !== currentUID) {
            cacheLastCandle = null;
            tick = {
                ...tick,
                price: lastCandle.open,
            }
        }

        const currentCandle = cacheLastCandle || lastCandle;

        const {high, low, volume, time, open} = currentCandle;
        const {price, amount} = tick;

        const candle = {
            time,
            open,
            high: Math.max(high, price),
            low: Math.min(low, price),
            close: price,
            volume: volume + amount,
        };

        const nextTickTime = time + resolution;

        if (nextTickTime <= new Date().getTime()) {
            cacheLastCandle = {
                time: nextTickTime,
                open: price,
                high: price,
                low: price,
                close: price,
                volume: amount,
            };
        } else {
            cacheLastCandle = {...candle};
        }

        return {...candle};
    }
}

const newCandle = updateCandle();

let unsubscribeStoreBars = null;
let currentCandle = null;

export default {
	onReady: cb => {
		setTimeout(() => cb(config), 0)
	},
	resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
        const splitSymbolName = symbolName.split(':');

        const symbolStub = {
			name: splitSymbolName.slice(-1)[0],
			description: '',
			type: 'crypto',
			session: '24x7',
			timezone: 'Etc/UTC',
			ticker: symbolName,
			exchange: 'ACEX',
			minmov: 1,
			pricescale: 100000000,
			has_intraday: true,
			intraday_multipliers: ['1', '5', '15', '30', '60'],
			supported_resolution:  supportedResolutions,
			volume_precision: 8,
			data_status: 'streaming',
            supports_search: false,
		};

		if (symbolName.match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
            symbolStub.pricescale = 100
		}
		setTimeout(function() {
			onSymbolResolvedCallback({...symbolStub});
		}, 0)
	},
	getBars: function(symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest) {
        if (firstDataRequest) {
            store.dispatch(chartDXHistoryRequest());
        }

        store.dispatch(setChartInterval(0, resolution > 30 ? resolution / 60 + 'H' : resolution + 'M'));
        store.dispatch(sendSettingsData());


		historyProvider(symbolInfo.name, resolution, from, to, firstDataRequest)
            .then(bars => {
                if (bars.length) {
                    if (firstDataRequest) {
                        currentCandle = bars.slice(-1)[0];
                    }
                    onHistoryCallback(bars, {noData: false})
                } else {
                    onHistoryCallback(bars, {noData: true})
                }
            })
            .catch(err => {
                store.dispatch(chartDXHistoryFailure());
                onErrorCallback(err)
            });
	},
	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
        let currentTick;

        function handleChange() {
            const currentValue = store.getState().chart.chartView.lastTick;
            const {name} = store.getState().settings.selectedSymbol;
            const guestName= store.getState().CURRENCY_PAIR.asset;

            if (currentCandle) {
                let previousTick = currentTick;
                if (symbolInfo.name === name || symbolInfo.name === guestName) {
                    currentTick = currentValue;
                }

                if (currentTick
                    && currentTick.hasOwnProperty('price')
                    && currentTick.hasOwnProperty('amount')
                    && currentTick.hasOwnProperty('openingTime')) {
                    currentTick = {
                        price: currentTick.price,
                        amount: currentTick.amount,
                        time: currentTick.openingTime * 1000,
                    };

                    const resolutionInMilliseconds = resolution * 60 * 1000;

                    if ((previousTick && previousTick.time)
                        !== (currentTick && currentTick.time)) {
                        onRealtimeCallback(newCandle(currentCandle, currentTick, resolutionInMilliseconds, subscribeUID))
                    }
                }
            }
        }

        unsubscribeStoreBars = store.subscribe(handleChange);
	},
	unsubscribeBars: subscriberUID => {
        unsubscribeStoreBars();
        currentCandle = null;
	},
}
