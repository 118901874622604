export const color1 = '#020d1d';
export const color2 = '#2c313d';
export const color3 = '#363945';
export const color4 = '#3f4151';
export const color5 = '#999999';
export const color6 = '#ea6433';
export const color7 = '#e0d9cb';
export const color8 = '#00124A';
export const color9 = '#00B456';
export const color10 = '#FF4D4D';
export const color11 = '#ffe52c';
export const color13 = 'rgba(44, 49, 61, 0.3)';
export const color14 = '#232733';
export const color15 = 'rgba(35, 39, 51, 0.3)';
export const color16 = '#161d2d';
export const color19 = 'transparent';
export const color20 = '#ebebeb';
export const color21 = '#f5f6f8';
export const color23 = '#00cc66';
export const color24 = '#FF4D4D';
export const color25 = '#0F1827';
export const color26 = 'rgba(234, 100, 51, 0.5)';
export const color27 = 'rgba(63, 65, 81, 0.3)';
export const color28 = '#767989';
export const color29 = '#f5f5f5';
export const color30 = '#0C3DFB';
export const color31 = '#333333';
export const color32 = '#0D1D50';
export const color33 = '#bcc5d8';
export const color34 = 'rgba(123, 132, 159, 0.5)';
export const color35 = '#2261ff';
