export default {
    color1: '#2B9241',
    color2: '#0061A2',
    color3: '#D15028',
    color4: '#7f6b00',
    color5: '#F5F5F5',
    color6: '#D1DBFF',
    color7: '#F5F5F5',
    color8: '#00B456',
    color9: '#00E56C',
    color10: '#009963',
    color11: '#FF4D4D',
    color12: '#FF4D4D',
    color13: '#B13030',
    color14: '#000000',
    color15: '#000000',
    color16: '#FFFCF8',
    color17: '#0C3DFB33',
    color18: '#0F1827',
    color19: '#020d1d4d',
    color20: '#ffffff',
    color21: '#132342',
    color22: '#9999994d',
    color23: '#fff',
    color24: '#99999933',
    color25: 'transparent',
    color26: '#008BE84d',
    color27: '#fff',
    color28: '#123E64',
    color29: '#99999932',
    color30: '#F8F8F8',
    color31: '#0F1827',
    color32: '#3F415110',
    color33: '#2B9241',
    color34: '#5C687C',
    color35: '#00B4560A',
    color36: '#FF4D4D0A',
    color37: '#2327334d',
    color38: '#3f415133',
    color39: '#767989',
    color40: '#2327334D',
    color41: '#9999994d',
    color42: '#0E1522',
    color43: '#909CBA',
    color44: '#7D829C',
    color45: '#8E9EBE',
    color46: '#8A92B280',
    color47: '#ffffff0d',
    color48: '#ffffff',
    color49: '#ffffff1a',
    color50: '#E5E5E5',
    color51: '#00124A',
    color52: '#fff',
    color53: '#171F2D',
    color54: '#FF4D4D',
    color55: '#FFE52C',
    color56: '#00B456',
    color57: '#F5F6F8',
    color58: '#FF4D4D',
    color59: '#3F415126',
    color60: '#707070',
    color61: 'rgba(44, 49, 61, 0.8)',
    color62: '#008BE8',
    color63: 'rgba(138, 150, 188, .3)',
    color64: '#0061a280',
    color65: '#999999',
    color66: '#8a92b280',
    color67: '#D6A63C',
    color68: 'rgba(25, 54, 127, .5)',
    color69: '#E5E5E5',
    $color70: '#0C3DFB20',
    color71: '#e3e5ee',
}
