import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';

const buttonStyle = css`
    padding: 0 4rem;    
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({color, theme}) =>
        (color === 'primary' && theme.color1) ||
        (color === 'secondary' && theme.color5)};
    color: ${({color, theme}) =>
        (color === 'primary' && theme.color20) ||
        (color === 'secondary' && theme.color15)};

    &:focus {
        box-shadow: 0 0 0 0.1rem ${props => props.theme.color3};
    }
    &:hover {
        background: ${({color, theme}) =>
            (color === 'primary' && theme.color2) ||
            (color === 'secondary' && theme.color6)};
        color: ${({color, theme}) =>
            (color === 'primary' && theme.color20) ||
            (color === 'secondary' && theme.color15)};
    }
    &:active {
        background: ${({color, theme}) =>
            (color === 'primary' && theme.color3) ||
            (color === 'secondary' && theme.color21)};
        color: ${({color, theme}) =>
            (color === 'primary' && theme.color20) ||
            (color === 'secondary' && theme.color15)};
    }

    ${({disabled}) =>
        disabled &&
        css`
            background: ${props => props.theme.color5};
            color: ${props => props.theme.color6};
            pointer-events: none;
        `}
`;

export const Button = styled.div`    
    height: ${({size}) =>
        (size === 'large' && '8.333rem') ||
        (size === 'medium' && '5rem') ||
        (size === 'small' && '4.16666rem')};
    font-size: ${({size}) =>
        (size === 'large' && '3rem') ||
        (size === 'medium' && '2.5rem') ||
        (size === 'small' && '2rem')};
`;
export const Icon = styled.i`
    margin-right: 2rem;
`;
export const Text = styled.span``;
export const Link = styled(NavLink)`    
    height: ${({size}) =>
        (size === 'large' && '8.333rem') ||
        (size === 'medium' && '5rem') ||
        (size === 'small' && '4.16666rem')};
    font-size: ${({size}) =>
        (size === 'large' && '3rem') ||
        (size === 'medium' && '2.5rem') ||
        (size === 'small' && '2rem')};
`;
