import styled from 'styled-components';
import Scroll from 'react-custom-scrollbars';

export const StyledScrollbars = styled(Scroll)`
    
    & div:first-child {
        &::-webkit-scrollbar-track {
            border: none;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar {
            width: 17px;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
        
        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
        
        @media screen and (max-width: 800px),
        @media screen and (max-device-width: 800px) {
          &::-webkit-scrollbar {
            width: 0;
            height: 0;
            -ms-overflow-style: none;
            overflow: -moz-scrollbars-none;
          }
        }
    }
    
    
    
    & .track-vertical {
        position: absolute;
        width: 8px;
        right: 0;
        bottom: 2px;
        top: 2px;
    }
    
    & .thumb-vertical {
        position: relative;
        z-index: 10;
        display: block;
        width: 100%;
        cursor: pointer;
        background-color: ${p => p.theme.color29};
        height: 127px;
        transform: translateY(0px);
        
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1px;
            height: 80%;
        }
`;
