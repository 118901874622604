import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import Menu, {MenuItem} from 'rc-menu';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';

import CurrencyPair from '../components/CurrencyPair/CurrencyPair';

import Chart from '../../Chart/Chart';

function mapStateToProps(state) {
    const {data, metadata} = state.chart;

    const chartData0 =
        (data[metadata[0].symbol.name] &&
            data[metadata[0].symbol.name].OHLC &&
            data[metadata[0].symbol.name].OHLC[metadata[0].interval]) ||
        [];
    const chartData1 =
        (data[metadata[1].symbol.name] &&
            data[metadata[1].symbol.name].OHLC &&
            data[metadata[1].symbol.name].OHLC[metadata[1].interval]) ||
        [];

    return {
        metadata,
        chartData0,
        chartData1,
    };
}

class Layout010 extends Component {
    state = {
        longList: false,
    };

    render() {
        const menuItems = [
            <MenuItem key="1" onClick={this.activeThemeLight}>
                1min
            </MenuItem>,

            <MenuItem key="2" onClick={this.removeThemeLight}>
                1hour
            </MenuItem>,
        ];

        if (this.state.longList) {
            menuItems.push(
                <MenuItem key="3">3rd LONG SUPER LONG item</MenuItem>
            );
        }
        const menu = <Menu>{menuItems}</Menu>;

        return (
            <Fragment>
                <div
                    className="chart-container"
                    style={{
                        width: 'calc(50% - 2rem)',
                        height: 'calc(100% - 2rem)',
                        float: 'left',
                    }}
                >
                    <CurrencyPair chartId={0} />

                    <Dropdown overlay={menu}>
                        <button className="interval">1m</button>
                    </Dropdown>

                    {this.props.chartData0.length >=
                    this.props.metadata[0].minDataLength ? (
                        <Chart id={0} type="area" />
                    ) : (
                        <div className="preloader">
                            <div className="pre">
                                <div className="react-preloader">
                                    <div className="react-preloader__inner" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div
                    className="chart-container"
                    style={{
                        width: 'calc(50% - 2rem)',
                        height: 'calc(100% - 2rem)',
                        float: 'left',
                    }}
                >
                    <CurrencyPair chartId={1} />

                    <Dropdown overlay={menu}>
                        <button className="interval">1m</button>
                    </Dropdown>

                    {this.props.chartData1.length >=
                    this.props.metadata[1].minDataLength ? (
                        <Chart id={1} type="area" />
                    ) : (
                        <div className="preloader">
                            <div className="pre">
                                <div className="react-preloader">
                                    <div className="react-preloader__inner" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Fragment>
        );
    }
}

const ConnectedLayout010 = connect(mapStateToProps)(Layout010);
export default ConnectedLayout010;
