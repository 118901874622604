// @flow
class GetPasswordForm {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(locale: string, token: string): Promise<any> {
        try {
            const { data } = await this.adapter.getForm(locale, token);

            return data;
        } catch (e) {
            throw e;
        }
    }
}

export default GetPasswordForm;
