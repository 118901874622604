import styled from 'styled-components';
import {wrapperBg} from '../../GuestDashboard/styled';

export const headerBg = '#000000';

export const HeaderWrapper = styled.div`
    background: ${headerBg};
    height: 14.7rem;
    position:relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 4rem;
`;
export const Logo = styled.img`
    margin-left: 12.8333rem;
    height: 7.5rem;
`;
export const RightSide = styled.div``;
export const HeaderRightRadius = styled.div`
  height: 5rem;
  width: 5rem;
  position: absolute;
  overflow: hidden;
  background: ${headerBg};
  top: 100%;
  right: 0;
  &:before {
    content: '';
    position:absolute;
    top: 0;
    right: 0;
    height: 10rem;
    width: 10rem;
    background: ${wrapperBg};    
  }
`;
export const HeaderLeftRadius = styled.div`
  height: 5rem;
  width: 5rem;
  position: absolute;
  overflow: hidden;
  background: ${headerBg};
  top: 100%;
  left: 0;
  &:before {
    content: '';
    position:absolute;
    top: 0;
    left: 0;
    height: 10rem;
    width: 10rem;
    background: ${wrapperBg};    
  }
`;
