// @flow
import type Axios from 'axios';

import type { PasswordRecoveryFormAdapterInterface } from '../../application/adapter/PasswordRecoveryForm';
import type {AccessToken} from "../../domain/entitiy/AccessToken";
import localConfig from 'localConfig';

class PasswordRecoveryFormAdapter implements PasswordRecoveryFormAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getForm(locale: string): Promise<any> {
        return await this.apiClient({
            method: "PUT",
            url: `${localConfig.authUrl}/v1/form/reset-password`,
            withCredentials: false,
            headers: {
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`,
            }
        });
    }

    async sendForm( formData: {}, locale: string): Promise<AccessToken> {

        return await this.apiClient({
            method: "POST",
            url: `${localConfig.authUrl}/v1/form/reset-password`,
            withCredentials: false,
            headers: {
                "content-type": "application/json",
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`
            },

            data: formData,

        });

    }
}

export default PasswordRecoveryFormAdapter;
