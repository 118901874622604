import screenfull from 'screenfull';

import { ActionTypes, setFullscreenValue } from '../action/fullscreen';

export default function(store) {
    if (screenfull.enabled) {
        screenfull.onchange(
            () =>
                store.dispatch(
                    setFullscreenValue(screenfull.isFullscreen)
                )
        );
    }

    return function(next) {
        return function(action) {
            if (action.type === ActionTypes.toggleFullscreen) {
                if (screenfull.enabled) {
                    if (screenfull.isFullscreen) {
                        screenfull.exit();
                    } else {
                        screenfull.request();
                    }
                }
            }

            next(action);
        }
    }
}
