import React, {PureComponent} from 'react';

import ProfileSettings from '../component/ProfileSettings';

export class ProfileSettingsLayout extends PureComponent {
    render() {
        return (
            <div className="active-content" style={{height: 'auto'}}>
                <ProfileSettings />
            </div>
        );
    }
}

export default ProfileSettingsLayout;
