import config from './config';
import {getServerAPI} from './utils';

let Config = config;
if (process.env.NODE_ENV === 'development') {
    Config = {
        apiUrl: getServerAPI(),
        wsUrl: getServerAPI(true),
        authUrl: 'https://api.ex.pinigus.com',
        landingUrl: 'https://acex.market',
        staticUrl: 'https://static.ce.acex.market/',
        workspaceUrl: 'https://ws.acex.market',
        recaptchaUrl:
            'https://www.recaptcha.net/recaptcha/api.js?render=explicit',
    };
}

export default Config;
