import {ActionTypes, chartView} from './actions';

const initState = {
    chartView: chartView.custom,
    lastTick: null,
    isErrorChart: false,
};

function chartViewReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.switchChartView:
            if (action.payload === chartView.tradingView) {
                return {
                    ...state,
                    chartView: chartView.tradingView,
                };
            } else if (action.payload === chartView.custom) {
                return {
                    ...state,
                    chartView: chartView.custom,
                };
            }
            break;
        case ActionTypes.addNewTickChartTV:
            return {
                ...state,
                lastTick: action.payload,
            };
        case ActionTypes.setErrorChart:
            return {
                ...state,
                isErrorChart: action.payload,
            };
        default:
            return state;
    }
}

export default chartViewReducer;
