import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import t from '../../infrastructure/adapter/helper/parseTranslation';
import Panel from '../components/Panel';
import {
    Header,
    Titles,
    Count,
    Amount,
    Total,
    Price,
    Markets,
    MarketTop,
    MarketBottom,
    Sort,
    SortTabs,
    MarketTopBottom,
} from './styled';
import {dealingOrderBook} from '../webSockets/constants';
import DepthRow from './components/DepthRow';
import ScrollBar from '../components/Scroll';
import CurrencyPairContainer from '../CurrencyPairContainer';
import {BOTH, SELL, BUY} from './constatnts';
import Tooltip from '../components/Tooltip';
import {LinesFill} from '../components/LinesFill/styled';
import TooltipAbsolute from '../components/Tooltip/TooltipAbsolute';
import DepthTooltip from './components/TooltipContent';

export default function GuestMarketDepthContainer() {
    const ref = React.createRef();
    const [activeTab, setActiveTab] = useState(BOTH);
    const [isScrolled, setScrolled] = useState(false);
    const markets = useSelector(
        state => state.GUEST_DEALING_WS[dealingOrderBook]
    );
    const positiveLength = markets['2'] && markets['2'].length;
    const negativeLength = markets['1'] && markets['1'].length;

    useEffect(() => {
        if (
            ref.current &&
            !isScrolled &&
            markets['1'] &&
            markets['1'].length > 0
        ) {
            ref.current.scrollToBottom();
            setScrolled(true);
        }
        if (ref.current) {
            !collapse && ref.current.scrollToBottom();
            !collapse && setScrolled(true);
        }
    }, [markets['1']]);
    useEffect(() => {
        activeTab === BUY && ref.current.scrollToBottom();
        activeTab === BOTH && ref.current.scrollToBottom();
    }, [activeTab]);

    const handleSort = value => () => setActiveTab(value);
    const [collapse, setCollapse] = useState(false);
    const handleCollapse = value => {
        setCollapse(value);
        setActiveTab(BOTH);
    };
    useEffect(() => {
        collapse && ref.current.scrollToTop();
    }, [collapse]);
    return (
        <Panel
            title={t('label-market-depth')}
            icon="icon-market-depth"
            onCollapse={handleCollapse}
        >
            <Header>
                <div>
                    <CurrencyPairContainer />
                </div>
                {!collapse && (
                    <SortTabs>
                        <Sort onClick={handleSort(BOTH)}>
                            <i className="icon-buy-sell-down" />
                            <i className="icon-buy-sell-up" />
                            <Tooltip bottomRight title={t('label-buy-sell-orders')} />
                        </Sort>
                        <Sort onClick={handleSort(BUY)}>
                            <i className="icon-buy-up" />
                            <Tooltip bottomRight title={t('label-buy-orders')} />
                        </Sort>
                        <Sort onClick={handleSort(SELL)}>
                            <i className="icon-sell-down" />
                            <Tooltip
                                title={t('label-sell-orders')}
                                bottomRight
                            />
                        </Sort>
                    </SortTabs>
                )}
            </Header>
            {!collapse && (
                <Titles>
                    <Count collapse={collapse}>{t('label-count')}</Count>
                    <Amount>{t('label-amount')}</Amount>
                    <Total>{t('label-total')}</Total>
                    <Price>{t('label-price')}</Price>
                </Titles>
            )}
            <Markets collapsed={collapse}>
                {(activeTab === BOTH || activeTab === BUY) && (
                    <MarketTop collapse={collapse}>
                        {collapse && (
                            <Titles>
                                <Count collapse={collapse}>
                                    {t('label-count')}
                                </Count>
                                <Amount>{t('label-amount')}</Amount>
                                <Total>{t('label-total')}</Total>
                                <Price>{t('label-price')}</Price>
                            </Titles>
                        )}
                        <ScrollBar ref={ref}>
                            <MarketTopBottom />
                            <LinesFill fromBottom={!collapse} />
                            {markets['2'] &&
                                (collapse
                                    ? markets['2']
                                    : markets['2'].reverse()
                                ).map(({count, amount, price}, idx) => {
                                    return (
                                        <TooltipAbsolute
                                            key={`${amount}-${idx}`}
                                            rightCenter={16}
                                            content={
                                                <DepthTooltip
                                                    count={count}
                                                    amount={amount}
                                                    price={price}
                                                    total={(
                                                        price * amount
                                                    ).toFixed(4)}
                                                />
                                            }
                                        >
                                            <DepthRow
                                                collapsed={collapse}
                                                isTop
                                                width={
                                                    idx === 0
                                                        ? collapse
                                                            ? `${100 /
                                                                  positiveLength}%`
                                                            : '100%'
                                                        : collapse
                                                        ? `${(100 /
                                                              positiveLength) *
                                                              (idx + 1)}%`
                                                        : `${100 -
                                                              (100 /
                                                                  positiveLength) *
                                                                  idx}%`
                                                }
                                                isOdd={idx % 2 === 0}
                                                count={count}
                                                amount={amount}
                                                total={(price * amount).toFixed(
                                                    4
                                                )}
                                                price={price}
                                            />
                                        </TooltipAbsolute>
                                    );
                                })}
                        </ScrollBar>
                    </MarketTop>
                )}
                {(activeTab === BOTH || activeTab === SELL) && (
                    <MarketBottom>
                        {collapse && (
                            <Titles>
                                <Price>{t('label-price')}</Price>
                                <Total>{t('label-total')}</Total>
                                <Amount>{t('label-amount')}</Amount>
                                <Count collapse={collapse}>
                                    {t('label-count')}
                                </Count>
                            </Titles>
                        )}
                        <ScrollBar>
                            <LinesFill />
                            {markets['1'] &&
                                markets['1'].map(
                                    ({count, amount, price}, idx) => {
                                        return (
                                            <TooltipAbsolute
                                                key={`${amount}-${idx}`}
                                                rightCenter={16}
                                                content={
                                                    <DepthTooltip
                                                        count={count}
                                                        amount={amount}
                                                        price={price}
                                                        total={(
                                                            price * amount
                                                        ).toFixed(4)}
                                                    />
                                                }
                                            >
                                                <DepthRow
                                                    collapsed={collapse}
                                                    width={`${
                                                        idx === 0
                                                            ? collapse
                                                                ? (100 /
                                                                      negativeLength) *
                                                                      idx +
                                                                  1
                                                                : 100 /
                                                                  negativeLength
                                                            : collapse
                                                            ? (100 /
                                                                  negativeLength) *
                                                              (idx + 1)
                                                            : (100 /
                                                                  negativeLength) *
                                                              (idx + 1)
                                                    }%`}
                                                    isOdd={idx % 2 === 0}
                                                    count={count}
                                                    amount={amount}
                                                    total={price * amount}
                                                    price={price}
                                                />
                                            </TooltipAbsolute>
                                        );
                                    }
                                )}
                        </ScrollBar>
                    </MarketBottom>
                )}
            </Markets>
        </Panel>
    );
}
