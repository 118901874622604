import styled, {css} from 'styled-components';
import colors  from '../../styles/common-colors/common-colors';
import {Scrollbars} from 'react-custom-scrollbars';
import TextareaAutosize from 'react-textarea-autosize';

import {
    color5,
    color8,
    color19,
    color29,
    color30,
    color31,
    color32,
    color33,
    color34,
    color35,
} from 'infrastructure/view/ui/colors';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 136rem;
    margin: 0 auto;
    display: flex;
    height: 100%;
    flex-direction: column;    
`;

export const ScrollContainer = styled.div`
    padding: 20rem 0 42.5rem;

    & > img {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
    }
`;

export const ChatScrollbar = styled(Scrollbars)`
    & div:first-child {
        &::-webkit-scrollbar-track {
            border: none;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar {
            width: 17px;
            background-color: transparent;
        }
    
        &::-webkit-scrollbar-thumb {
            background-color: transparent;
        }
        
        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }
    }
    .scrollbar-container {
        top: 0;
        bottom: 0;
        right: 0;
        width: 0 !important;
    }

    .scrollbar-thumb {
        right: 0;
        top: 0;
        bottom: 0;
        background-color: ${colors.color29};

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 80%;
            background-color: ${color34};
        }
    }
`;

export const Title = styled.h4`
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    max-width: inherit;
    height: 15.5rem;
    margin: 0;
    color: ${colors.color15};
    font-size: 3rem;
    line-height: 16rem;
    text-align: center;
    text-transform: uppercase;
    background-color: #9E9EAC;
    z-index: 98;
`;

export const MessageItem = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 2rem;
    z-index: 10;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${p =>
        p.system &&
        css`
            ${AvatarWrapper} {
                margin-right: 2rem;
                margin-left: 0.2rem;
            }

            ${MessageItemWrapper} {                
                background-color: ${colors.color48};
                color: ${colors.color25};
            }
        `}

    ${p =>
        p.user &&
        css`
            flex-direction: row-reverse;

            ${AvatarWrapper} {
              background-color: ${colors.color65};
              color: ${colors.color20}
            }

            ${MessageItemWrapper} {
                margin-right: 2.2rem;
                background-color: ${colors.color7};                

                &::before {
                    right: -12px;
                    border-width: 12px 12px 0 0;
                    border-color: ${colors.color25};
                }
            }
        `}
`;

export const AvatarWrapper = styled.div`
    width: 8.33rem;
    height: 8.33rem;    
    overflow: hidden;
    background-color: ${colors.color65};
    color: ${props => props.theme.color20}
    display: flex;    
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    & > span {
        text-transform: uppercase;
        font-size: 3.66rem;
    }

    ${p =>
    p.withUsername &&
    css`
            display: flex;
            justify-content: center;
            align-items: center;
        `}
`;

export const Avatar = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`;

export const MessageItemWrapper = styled.div`
    position: relative;
    width: 78.1%;
    padding: 3.5rem 4.666rem 4rem 3.9rem;
    word-wrap: break-word;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
    }
`;

export const User = styled.div`
    margin-bottom: 1.4rem;
`;

export const UserName = styled.span`
    margin-right: 2.1rem;
    font-size: 2.6rem;
    color: ${colors.color1};
`;

export const UserDate = styled.span`
    color: ${color5};
    font-size: 2.333rem;

    span:first-of-type {
        margin-right: 2.1rem;
    }
`;

export const MessageItemText = styled.p`
    margin: 0;
    color: ${colors.color15};
    font-size: 2.333rem;
    line-height: 1.1;
    white-space: pre-line
`;

export const FormInputText = styled.form`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #9E9EAC;
    width: 100%;
    height: 42.5rem;
    max-width: inherit;
    padding: 1.5rem 10.533rem 0;
    overflow: hidden;
    box-sizing: border-box;    
    z-index: 10;
    .textarea-scroll {
        margin-bottom: 3.6rem;    
    }
`;

export const ChatTop = styled.div`
    flex: 1;
`;

export const SystemMessage = styled.p`
    margin: 0 0 1.2rem;
    color: ${colors.color14};
    line-height: 1;
    font-size: 2.333rem;
`;

export const InputField = styled(TextareaAutosize)`
    display: block;
    width: 100%;
    min-height: 21.666rem;
    padding: 1.7rem 1.7rem;    
    background-color: ${colors.color7};
    color: ${colors.color15};
    //font-family: 'Lato', sans-serif;
    font-size: 2.333rem;
    box-sizing: border-box;
    border: none;
    resize: none;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: ${colors.color14};
    }
`;

export const ChatTextareaScrollbar = styled(ChatScrollbar)`
    height: 21.716rem !important;
    margin-bottom: 3.6rem;       
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
`;

export const Button = styled.button`
    min-width: 20rem;
    height: 8.333rem;
    padding: 0 3.333rem;
    line-height: 8.333rem;    
    color: ${colors.color20};
    font-size: 3rem;
    font-family: Lato;
    background: linear-gradient(to bottom, #0C3DFB, #0C3DFB);
    transition: background 0.3s;

    &:hover {
        background: linear-gradient(to top, #1D33E0, #1D33E0);
    }
    
    &:active {
        background: linear-gradient(to top, #172AB9, #172AB9); 
     }
`;

export const Tooltip = styled.p`
    margin-right: 3.4rem;
    color: ${colors.color14};
    font-size: 2.333rem;
`;

export const WrapperItemChat = styled.div`
    margin-top: 20rem;
    font-size: 2.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 20rem);
    min-height: 21rem;
    position:relative;
    span {
      color: ${colors.color1};
    }
    
    p {
      color: ${colors.color15};
    }
`;

export const ButtonChat = styled.button`
      width: 20rem;
      height: 8.3rem;
      font-size: 3rem;      
      color: ${colors.color20};
      padding: 2rem 0;
      margin-top: 5rem;
      background: linear-gradient(to bottom, #0C3DFB, #0C3DFB);
      transition: background 0.3s;
      z-index: 2;  
       font-family: "Lato", Roboto, Arial, sans-serif; 
    &:hover {
        background: linear-gradient(to top, #1D33E0, #1D33E0);
    }
    &:active {
        background: linear-gradient(to top, #172AB9, #172AB9);
     }
`;

export const WrapperLogo = styled.div`
    position: absolute;
    top: 60%;
    bottom: 0;
    width: calc(78.1% + 4.666rem + 3.9rem);
    left: 10.33rem;
    transform: translateY(-50%);      
    max-width: inherit;
    padding: 1.5rem 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: transparent;
    z-index: 0;
    
    img {
       width: 100%;
       display: block;
       margin: 0 auto;
    }
`;
