// @flow
import React, {Fragment, Component} from 'react';
import {connect} from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import InputMask from 'inputmask';

import {getDepositForm, submitDepositForm} from '../service/actions';
import {updateForm} from '../../../services/action/forms';
import FormField from '../../Registration/component/FormField';

import parseTranslation from '../../../../adapter/helper/parseTranslation';
import config from '../../../../../localConfig';

import Error from "../../../component/Error";

function mapStateToProps(state) {
    const formError = state.forms.error;
    const form = state.forms['DepositForm'] || {};
    const {locale} = state.settings;

    return {
        formError,
        form,
        locale,
        labelErrorCheckbox: parseTranslation('error-checkbox'),
        amountDeposit_1: parseTranslation('amount-deposit-1'),
        amountDeposit_2: parseTranslation('amount-deposit-2'),
        amountDeposit_3: parseTranslation('amount-deposit-3'),
        go_back: parseTranslation('label-go-back'),
        copied: parseTranslation('copy'),
        labelCurrency: parseTranslation('currency-deposit-funds'),
        labelDepositMethod: parseTranslation('deposit-method-deposit-funds'),
        labelCheckaccept: parseTranslation('deposit-terms-accept'),
        labelCheckconditions: parseTranslation('deposit-terms-conditions'),
        labelConfirm: parseTranslation('confirm-deposit-funds'),
        receive: parseTranslation('receive'),
        to_you_account: parseTranslation('to-you-account'),
        deposit_amount: parseTranslation('deposit-amount'),
        free: parseTranslation('free'),
    };
}

const mapDispatchToProps = {
    getDepositForm,
    submitDepositForm,
    updateForm,
};

class DepositFunds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSubmitEnabled: false,
            errorClick: false,
            percent: 0,
            commission: 0,
            amount: 0,
        };

        this.paymentProviderFormRef = React.createRef();

        this.handleCheckClick = this.handleCheckClick.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.calcPercent = this.calcPercent.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
    }

    componentDidMount() {
        this.props.getDepositForm();
    }

    async componentDidUpdate(prevProps) {
        const {form, locale} = this.props;

        if (locale !== prevProps.locale) {
            this.props.getDepositForm();
        }

        if (
            form.result &&
            !Array.isArray(form.result) &&
            (form.result.method === 'post' || form.result.method === 'get')
        ) {
            this.paymentProviderFormRef.current.submit();
        }
        if (prevProps.form !== form) {
            this.setState({
                percent: form.scheme ? form.scheme.draw_tax.value : 0,
            });
            this.calcPercent(
                form.scheme ? form.scheme.recipient_amount.value : 0
            );
        }
    }

    render() {
        const {form} = this.props;

        if (form.result && form.result.method) {
            if (form.result.method === 'show-btc-address') {
                return this.renderWalletAddressForm(
                    form.result.data.amount,
                    form.result.data.address
                );
            }

            if (form.result.method === 'post' || form.result.method === 'get') {
                return this.renderPaymentProviderForm(
                    form.result.method,
                    form.result.url,
                    form.result.data
                );
            }
        }

        return this.renderDepositForm();
    }

    renderDepositForm() {
        const {scheme, errors} = this.props.form;
        const {formError} = this.props;

        if (this.state.error) {
            return <h1>Caught an error.</h1>;
        }

        return (
            <Fragment>
            {formError && <Error />}
            <form className="form-billing" onSubmit={this.onSubmit}>
                {scheme && (
                    <Fragment>
                        {
                            Object.values(scheme)
                                .sort((a, b) => a.idx - b.idx)
                                .map(field => (
                                    <FormField
                                        key={field.name}
                                        name={field.name}
                                        type={field.type}
                                        label={field.label}
                                        value={field.value ? field.value : ''}
                                        options={field.options}
                                        onChange={value => {
                                            if (field.name === 'email') {
                                                const im = new InputMask({
                                                    mask:
                                                        '*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]',
                                                });
                                                im.mask(
                                                    document.querySelectorAll(
                                                        'input[name=email]'
                                                    )
                                                );
                                                this.props.updateForm(
                                                    'DepositForm',
                                                    field.name,
                                                    value
                                                );
                                            }
                                            if (field.name === 'state_code') {
                                                const im = new InputMask('AA');
                                                im.mask(
                                                    document.querySelectorAll(
                                                        'input[name=state_code]'
                                                    )
                                                );
                                                this.props.updateForm(
                                                    'DepositForm',
                                                    field.name,
                                                    value
                                                );
                                            }
                                            if (field.name === 'country_code') {
                                                const im = new InputMask('AA');
                                                im.mask(
                                                    document.querySelectorAll(
                                                        'input[name=country_code]'
                                                    )
                                                );
                                                this.props.updateForm(
                                                    'DepositForm',
                                                    field.name,
                                                    value
                                                );
                                            }
                                            if (field.name === 'recipient_amount') {
                                                if (
                                                    scheme['recipient_currency']
                                                        .value === 'USD' ||
                                                    scheme['recipient_currency']
                                                        .value === 'EUR'
                                                ) {
                                                    const regex = /^\d*(\.|,)?\d{0,2}$/g;
                                                    let match = regex.exec(value);
                                                    if (
                                                        match &&
                                                        match.shift().length <= 16
                                                    ) {
                                                        this.props.updateForm(
                                                            'DepositForm',
                                                            field.name,
                                                            value.replace(',', '.')
                                                        );
                                                    }
                                                } else {
                                                    const regex = /^\d*\.?\d{0,8}$/g;
                                                    let match = regex.exec(value);
                                                    if (
                                                        match &&
                                                        match.shift().length <= 16
                                                    ) {
                                                        this.props.updateForm(
                                                            'DepositForm',
                                                            field.name,
                                                            value.replace(',', '.')
                                                        );
                                                    }
                                                }
                                            }

                                            if (field.name === 'sender_service') {
                                                this.props.getDepositForm({
                                                    DepositForm: {
                                                        [field.name]: value,
                                                        recipient_currency:
                                                        scheme.recipient_currency
                                                            .value,
                                                        recipient_amount:
                                                        scheme.recipient_amount
                                                            .value,
                                                    },
                                                });
                                                // this.setState({ percent: scheme.draw_tax.value });
                                            }

                                            if (field.name === 'recipient_currency') {
                                                this.props.getDepositForm({
                                                    DepositForm: {
                                                        [field.name]: value,
                                                        sender_service:
                                                        scheme.sender_service.value,
                                                        recipient_amount:
                                                        scheme.recipient_amount
                                                            .value,
                                                    },
                                                });
                                            }

                                            // this.props.updateForm('DepositForm', field.name, value);
                                        }}
                                        error={
                                            !Array.isArray(errors) &&
                                            errors[field.name] &&
                                            errors[field.name][0]
                                        }
                                    />
                                ))
                        }

                        <div className="incorporated percent-calc">
                            {this.props.receive}{' '}
                            <span>
                                {this.state.commission}{' '}
                                        {document.querySelector(
                                            'select[name="recipient_currency"]'
                                        ) &&
                                        document.querySelector(
                                            'select[name="recipient_currency"]'
                                        ).value}
                            </span>{' '}
                            {this.props.to_you_account}
                            <br/>
                            (
                            {this.state.amount ? this.state.amount : 0}{' '}
                            {document.querySelector(
                                'select[name="recipient_currency"]'
                            ) &&
                            document.querySelector(
                                'select[name="recipient_currency"]'
                            ).value}{' '}
                            {this.props.deposit_amount} - {this.state.percent}%{' '}
                            {this.props.free} ={' '}
                            <span>
                        {this.state.commission}{' '}
                                {document.querySelector(
                                    'select[name="recipient_currency"]'
                                ) &&
                                document.querySelector(
                                    'select[name="recipient_currency"]'
                                ).value}
                    </span>
                            )
                        </div>
                        <div className="incorporated">
                            {/*ToDo: block for incorporated text*/}
                        </div>

                        <div className="check-billing">
                            <label>
                                <input
                                    id="deposit-funds-checkbox"
                                    // className="icon-check-mark"
                                    type="checkbox"
                                    checked={this.state.isSubmitEnabled}
                                    onChange={this.handleCheckClick}
                                    style={{
                                        border:
                                            this.state.errorClick &&
                                            !this.state.isSubmitEnabled
                                                ? '0.16rem solid #EA6433'
                                                : '0.16rem solid #999999',
                                    }}
                                />
                                {this.props.labelCheckaccept}
                                <a
                                    href={`${config.landingUrl}/terms-of-use`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                    {this.props.labelCheckconditions}{' '}
                                </a>
                            </label>

                            {this.state.errorClick && !this.state.isSubmitEnabled && (
                                <p
                                    style={{
                                        color: '#FF4D4D',
                                        position: 'absolute',
                                        fontSize: '1.8rem',
                                        // top: '3rem',
                                    }}
                                >
                                    {this.props.labelErrorCheckbox}
                                </p>
                            )}
                        </div>

                        <button
                            type="submit"
                            className={`confirm bubble ${!this.state.isSubmitEnabled &&
                            'disable'}`}
                            disabled={!this.state.isSubmitEnabled}
                        >
                            {this.props.labelConfirm}
                        </button>
                    </Fragment>
                )}
            </form>
            </Fragment>
        );
    }

    calcPercent(amount) {
        const commission = amount - (amount * this.state.percent) / 100;
        const select =
            document.querySelector('select[name="recipient_currency"]') &&
            document.querySelector('select[name="recipient_currency"]').value;
        if (select === 'USD' || select === 'EUR') {
            this.setState({
                amount: amount,
                commission: parseFloat(commission).toFixed(2),
            });
        } else {
            this.setState({
                amount: amount,
                commission: parseFloat(commission).toFixed(8),
            });
        }
    }

    handleCheckClick() {
        this.setState({
            isSubmitEnabled: !this.state.isSubmitEnabled,
        });
    }

    handleCopy () {
        this.setState({copyVisibility: true});
        setTimeout(() => this.setState({copyVisibility: false}), 2000);
    }

    renderWalletAddressForm(amount, address) {
        return (
            <Fragment>
                <div className="account-deposit">
                    <div>
                        {this.props.amountDeposit_1} <span>{amount}</span> {this.props.amountDeposit_2}
                    </div>

                    <div>{this.props.amountDeposit_3}</div>
                    <div>
                        {address}
                        <CopyToClipboard text={address}>
                            <button className="copy-button" onClick={this.handleCopy}>
                                {this.state.copyVisibility && <div className="copied">{this.props.copied}</div>}
                                <svg
                                    className="copy-svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                    style={{background: 'new 0 0 32 32'}}
                                    space="preserve"
                                >
                                    <path
                                        d="M31.2,3H5.1C4.6,3,4.2,3.4,4.2,3.9v3.6c0,0.1,0,0.3,0.1,0.4H0.8C0.4,7.9,0,8.3,0,8.7v19.4C0,28.6,0.4,29,0.8,29h26.1
                                    c0.5,0,0.8-0.4,0.8-0.8V24c0.1,0.1,0.2,0.1,0.4,0.1h3c0.5,0,0.8-0.4,0.8-0.8V3.9C32,3.4,31.6,3,31.2,3z M26.1,9.6v17.7H1.7V9.6H26.1
                                    z M26.9,7.9H5.8c0.1-0.1,0.1-0.2,0.1-0.4V4.7h24.4v17.7h-2.2c-0.1,0-0.2,0-0.4,0.1V8.7C27.8,8.3,27.4,7.9,26.9,7.9z"
                                    />
                                </svg>
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>

                <div>
                    <button
                        className="confirm bubble"
                        onClick={() => this.props.getDepositForm()}
                    >
                        {this.props.go_back}
                    </button>
                </div>
            </Fragment>
        );
    }

    renderPaymentProviderForm(method, action, data) {
        return (
            <form
                ref={this.paymentProviderFormRef}
                action={action}
                method={method}
                style={{display: 'none'}}
            >
                {Object.keys(data).map(key => (
                    <input type="text" name={key} value={data[key]} />
                ))}
                <input type="submit" />
            </form>
        );
    }

    onSubmit(e) {
        if (!this.state.isSubmitEnabled) {
            this.setState({errorClick: true});
        } else {
            if (this.state.isSubmitEnabled) {
                this.setState({errorClick: false});
            }
            this.props.submitDepositForm();
        }
        e.preventDefault();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DepositFunds);
