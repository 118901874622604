import styled from 'styled-components';

export const ServerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ToggleWrapper = styled.div`
    position: relative;
    height: 7.5rem;
`;

export const SwitchButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: ${p => p.isDashboard ? '35%' : '47%'};
    margin-right: 2.666rem;
`;

export const ServerTitle = styled.div`
    color: ${({theme}) => theme.color7};
    font-size: 3rem;
`;

export const ServerName = styled.div`
    color: ${({theme}) => theme.color67};
    font-size: 3.5rem;
`;
