export const START_GUEST_DEALING_WS = 'START_GUEST_DEALING_WS';
export const SET_DEALING_WS = 'SET_DEALING_WS';
export const STOP_DEALING_WS = 'STOP_DEALING_WS';
export const SEND_DEALING_WS = 'SEND_DEALING_WS';

export const setDealingWs = (value, isArray) => ({
  type: SET_DEALING_WS,
  isArray,
  value,
});
export const stopDealingWebsocket = () => ({type: STOP_DEALING_WS});
export const startGuestDealingWebsocket = () => ({type: START_GUEST_DEALING_WS});
export const sendDealingWebsocket = data => ({type: SEND_DEALING_WS, data});
