import React, {useEffect} from 'react';
import t from '../../infrastructure/adapter/helper/parseTranslation';
import {useDispatch, useSelector} from 'react-redux';
import Language from 'infrastructure/view/component/Language';
import {getGuestApiUrl, setSitePairAlias} from './actions';
import GuestSymbolsContainer from '../GuestSymbols';
import {
    Wrapper,
    Symbols,
    Chart,
    Market,
    Orders,
    Trades,
    HeaderContent,
    OrderForm,
} from './styled';
import GuestChartContainer from '../GuestChart';
import GuestMarketDepthContainer from '../GuestMarketDepth';
import Header from '../components/GuestHeader';
import GuestOrdersContainer from '../GuestOrders';
import GuestMarketTradesContainer from '../GuestMarketTrades';
import GuestOrderForm from '../GuestOrderForm';
import Panel from '../components/Panel';
import Preloader from '../../infrastructure/view/component/Preloader';
import config from '../../localConfig';
import {
    setCurrencyPair,
    setCurrencyPairAlias,
} from '../CurrencyPairContainer/actions';
import {NotFound} from '../components/NotFound';

export default function GuestDashboardContainer({sitePair}) {
    const selectedPair = useSelector(state => state.CURRENCY_PAIR.asset);
    const translation = useSelector(state => state.translation.translation);
    const error = useSelector(state => state.CHART.error);
    const dispatch = useDispatch();

    useEffect(() => {
        if (sitePair) {
            dispatch(setSitePairAlias(sitePair.replace('-', '/')));
            dispatch(setCurrencyPairAlias(sitePair.replace('-', '/')));
            dispatch(getGuestApiUrl());
        } else {
            dispatch(getGuestApiUrl());
        }
    }, []);

    if (error) {
        return <NotFound />;
    }

    return (
        <>
            {translation && selectedPair ? (
                <>
                    <Header>
                        <HeaderContent>
                            <Language centerBottom />
                            <a href={`${config.workspaceUrl}/login`} className="secondary-button">{t('login-btn')}</a>
                            <a href={`${config.workspaceUrl}/registration`} className="main-button">{t('label-sign-up')}</a>
                        </HeaderContent>
                    </Header>
                    <Wrapper>
                        <Symbols>
                            <Panel title={t('label-symbols')} icon="icon-symbols">
                                <GuestSymbolsContainer isSelectable />
                            </Panel>
                        </Symbols>
                        <Chart>
                            <GuestChartContainer />
                        </Chart>
                        <Market>
                            <GuestMarketDepthContainer />
                        </Market>
                        <OrderForm>
                            <GuestOrderForm />
                        </OrderForm>
                        <Orders>
                            <GuestOrdersContainer />
                        </Orders>
                        <Trades>
                            <GuestMarketTradesContainer />
                        </Trades>
                    </Wrapper>
                </>
            ) : <Preloader />}
        </>
    );
}
