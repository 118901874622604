import {ActionTypes} from './actions';
import {
    createCandle,
    isTickFromPast,
    isSameCandle,
    mapInterval,
    updateCandle,
} from './ohlc';

// TODO: initialize this state from LocalStorage
const INITIAL_STATE = {
    type: 'candle',
    symbol: {numerator: 'BTC', denominator: 'USD', name: 'BTC/USD'},
    interval: '1M',
    data: [],
    minDataLength: 50,
    loading: false,
    active: false,
    isPanging: true,
    newTick: {},
};

function chartReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.addOrdersToChart: {
            // TODO: refactor later to use tick instead of orders-array
            const [tick] = action.orders;

            const interval = mapInterval(action.chartInterval);
            const candle = state.data[state.data.length - 1];

            if (candle && isTickFromPast(candle, tick)) {
                return state;
            }

            const shouldCreateNewCandle = !isSameCandle(interval, candle, tick);
            let data;
            let newTick;
            if (shouldCreateNewCandle) {
                const newCandle = createCandle(tick, interval);
                newTick = newCandle;
                data = [...state.data, newCandle];
            } else {
                const updatedCandle = updateCandle(candle, tick);
                newTick = updatedCandle;
                data = [
                    ...state.data.slice(0, state.data.length - 1),
                    updatedCandle,
                ];
            }

            return {
                ...state,
                data,
                newTick,
            };
        }

        case ActionTypes.cleanupNewTick: {
            return {
                data: [],
                newTick: {},
            };
        }

        // TODO: Looks like this reaction doesn't needed here
        case ActionTypes.fetchChartDataHistory: {
            const {id} = action;

            if (id !== 10) {
                return state;
            }

            const updatedState = {
                ...state,
                loading: true,
            };

            return updatedState;
        }

        case ActionTypes.addChartDataHistory: {
            const {id, history} = action;

            if (id !== 10) {
                return {
                    ...state,
                    isPanging: false,
                };
            }

            return {
                ...state,
                data: [...history, ...state.data],
                loading: false,
                isPanging: false,
            };
        }

        // case ActionTypes.setSelectedSymbolForChart: {
        //     const {
        //         id,
        //         selectedSymbol
        //     } = action;
        //
        //     const updatedState = {
        //         ...state,
        //         [id]: {
        //             ...state[id],
        //             data: [],
        //             symbol: {
        //                 ...selectedSymbol,
        //                 name: `${selectedSymbol.numerator}/${selectedSymbol.denominator}`
        //             }
        //         }
        //     };
        //
        //     return updatedState;
        // }
        //
        // case ActionTypes.setChartInterval: {
        //     const {
        //         id,
        //         interval
        //     } = action;
        //
        //     const updatedState = {
        //         ...state,
        //         [id]: {
        //             ...state[id],
        //             data: [],
        //             interval
        //         }
        //     };
        //
        //     return updatedState;
        // }
        //
        // case ActionTypes.setChartType: {
        //     const {
        //         id,
        //         chartType
        //     } = action;
        //
        //     const updatedState = {
        //         ...state,
        //         [id]: {
        //             ...state[id],
        //             type: chartType
        //         }
        //     };
        //
        //     return updatedState;
        // }

        default:
            return state;
    }
}

export default chartReducer;
