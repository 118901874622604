// @flow
export const EventType = {
    getListingForm: 'module.listingForm.event.get',
    submitListingForm: 'module.listingForm.event.submit',
    updateForm: 'module.listingForm.event.update',
    listingReducer: 'module.listingForm.event.reducer',
};

export type GetListingForm = {|
    type: 'module.listingForm.event.get',
|};

export type SubmitListingForm = {|
    type: 'module.listingForm.event.submit',
|};

export type UpdateForm = {|
    type: 'module.listingForm.event.update',
|};

export type ListingReducer = {|
    type: 'module.listingForm.event.reducer',
|};

export type Event = GetListingForm | SubmitListingForm | UpdateForm;

export function getListingForm(formData): GetListingForm {
    return {
        type: EventType.getListingForm,
        formData,
    };
}

export function submitListingForm(): SubmitListingForm {
    return {
        type: EventType.submitListingForm,
    };
}

export function updateForm(): UpdateForm {
    return {
        type: EventType.updateForm,
    };
}

export function listingReducer(): ListingReducer {
    return {
        type: EventType.listingReducer,
    };
}
