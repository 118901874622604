import React, {Component} from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';

import parseTranslation from '../../../../adapter/helper/parseTranslation';

import FormInput from './FormInput';

const mapStateToProps = state => {
    const {translation} = state.translation;
    return {
        translation,
    };
};
class FormField extends Component {
    render() {
        const {
            type,
            name,
            options,
            label,
            placeholder,
            description,
            onChange,
            captchaRefCallback,
            error,
            disabled,
            value,
            typeFileParams,
            selectTransparent,
            selectHeight,
            animatePlaceholder,
            isSort,
            ...rest
        } = this.props;

        return (
            <div
                className={classnames({
                    error: !!error, failed: type !== 'hidden'
                })}
            >
                <FormInput
                    type={type}
                    name={name}
                    options={options}
                    onChange={onChange}
                    captchaRefCallback={captchaRefCallback}
                    label={parseTranslation(label)}
                    placeholder={placeholder}
                    typeFileParams={typeFileParams}
                    value={value}
                    disabled={disabled}
                    description={description}
                    selectTransparent={selectTransparent}
                    selectHeight={selectHeight}
                    animatePlaceholder={animatePlaceholder}
                    isSort={isSort}
                    {...rest}
                >
                    {(error && type !== 'hidden') && (
                        <span className="authorisation-error">
                            {parseTranslation(error)}
                        </span>
                    )}
                </FormInput>
            </div>
        );
    }
}

export default connect(mapStateToProps)(FormField);
