// @flow
import DealingAdapter from '../../infrastructure/adapter/Dealing';

class SelectAsset {
    dealingAdapter: DealingAdapter;

    constructor(dealingAdapter: DealingAdapter) {
        this.dealingAdapter = dealingAdapter;
    }

    invoke(assetToSubscribe: string, assetToUnsubscribe: string = ''): void {
        try {
            if (assetToUnsubscribe.length) {
                this.dealingAdapter.unsubscribeFromAsset(assetToUnsubscribe);
            }

            this.dealingAdapter.subscribeToAsset(assetToSubscribe);
        } catch (error) {
            throw error;
        }
    }
}

export default SelectAsset;
