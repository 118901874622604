import React, {Component} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';

import {
    setChartLayout,
    sendSettingsData,
} from '../../../../../../services/action/settings';
import parseTranslation from '../../../../../../../adapter/helper/parseTranslation';
import Dropdown from 'infrastructure/view/component/Dropdown/Dropdown';
import SwitchChartView from '../SwitchChartView';

const mapStateToProps = state => {
    const {chartLayout} = state.settings;

    return {
        chartLayout,
        chartText: parseTranslation('label-chart'),
        customTranslate: parseTranslation('label-chart1-name'),
        tradingViewTranslate: parseTranslation('label-chart2-name'),
    };
};

const mapDispatchToProps = {
    setChartLayout,
    sendSettingsData,
};

class Panel extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            button: true,
            activeTab: 1,
        };
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            menu: !prevState.menu,
            button: !prevState.button,
        }));
    };

    render() {
        const {
            title,
            children,
            expandable,
            closable,
            className,
            expandHandler,
            closableHandler,
            isCenter,
            chartLayout,
            chartView,
            isLayoutChart,
        } = this.props;

        return (
            <div className={classnames('panel', {[className]: className})}>
                <div className="panel-header">
                    <div className="panel__node">
                        <button className="panel-button panel-left-icon" >
                            <i className="icon-chart" />
                        </button>

                        <span
                            className={`panel-title ${isCenter &&
                            'panel-title--center-align'}`}
                        >
                            {title}
                        </span>

                        {chartView && <SwitchChartView translates={{custom: this.props.customTranslate, tradingView: this.props.tradingViewTranslate}} />}
                    </div>

                    {/* TODO: Add disabled state styles for not-implemented buttons */}

                    <div className="panel__node">
                        <div className="settings-panel">
                            {isLayoutChart && (
                                <Dropdown
                                    className="button-menu-chart"
                                    btn={
                                        <button className="button-menu-chart">
                                            <i className="icon-layout-chart chart-icon" />
                                        </button>
                                    }
                                >
                                    <div className="menu-chart-layout">
                                        <div
                                            className={`icon-chart-container ${
                                                chartLayout === 1 ? 'active' : ''
                                                }`}
                                        >
                                            {this.props.chartText} 1
                                            <i
                                                className={
                                                    chartLayout === 1
                                                        ? 'icon-chart-one active'
                                                        : 'icon-chart-one'
                                                }
                                                onClick={() => {
                                                    this.props.setChartLayout(1);
                                                    this.props.sendSettingsData();
                                                }}
                                            />
                                        </div>

                                        <div
                                            className={`icon-chart-container ${
                                                chartLayout === 2 || chartLayout === 0
                                                    ? 'active'
                                                    : ''
                                                }`}
                                        >
                                            {this.props.chartText} 2
                                            <div className="icon-wrapper">
                                                <i
                                                    className={
                                                        chartLayout === 2
                                                            ? 'icon-chart-double-h active'
                                                            : 'icon-chart-double-h'
                                                    }
                                                    onClick={() => {
                                                        this.props.setChartLayout(2);
                                                        this.props.sendSettingsData();
                                                    }}
                                                />
                                                <i
                                                    className={
                                                        chartLayout === 0
                                                            ? 'icon-chart-double-v active'
                                                            : 'icon-chart-double-v'
                                                    }
                                                    onClick={() => {
                                                        this.props.setChartLayout(0);
                                                        this.props.sendSettingsData();
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={`icon-chart-container ${
                                                chartLayout === 5 || chartLayout === 4
                                                    ? 'active'
                                                    : ''
                                                }`}
                                        >
                                            {this.props.chartText} 3
                                            <div className="icon-wrapper">
                                                <i
                                                    className={
                                                        chartLayout === 5
                                                            ? 'icon-chart-three-h active'
                                                            : 'icon-chart-three-h'
                                                    }
                                                    onClick={() => {
                                                        this.props.setChartLayout(5);
                                                        this.props.sendSettingsData();
                                                    }}
                                                />
                                                <i
                                                    className={
                                                        chartLayout === 4
                                                            ? 'icon-chart-three-v active'
                                                            : 'icon-chart-three-v'
                                                    }
                                                    onClick={() => {
                                                        this.props.setChartLayout(4);
                                                        this.props.sendSettingsData();
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={`icon-chart-container ${
                                                chartLayout === 6 ? 'active' : ''
                                                }`}
                                        >
                                            {this.props.chartText} 4
                                            <i
                                                className={
                                                    chartLayout === 6
                                                        ? 'icon-chart-four active'
                                                        : 'icon-chart-four'
                                                }
                                                onClick={() => {
                                                    this.props.setChartLayout(6);
                                                    this.props.sendSettingsData();
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Dropdown>
                            )}

                            {this.state.menu ? this.menu() : ''}

                            {/*<button>*/}
                            {/*<i className="icon-settings" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-chart-bars" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-chart-cursor" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-zoom" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-chart-indicators" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-chart-trading_line" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-chart_fibonacci" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-chart-text" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-look" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-delete" />*/}
                            {/*</button>*/}
                            {/*<button>*/}
                            {/*<i className="icon-chart-save" />*/}
                            {/*</button>*/}
                        </div>
                        {expandable && (
                            <button
                                className="panel-button"
                                onClick={expandHandler}
                            >
                                <i className="icon-expand" />
                            </button>
                        )}
                        {closable && (
                            <button
                                className="panel-button"
                                onClick={closableHandler}
                            >
                                <i className="icon-minimize-fullscreen-exit" />
                            </button>
                        )}
                    </div>
                </div>

                <div className="panel-body">
                    <div className="panel-body-content">{children}</div>
                </div>
            </div>
        );
    }
}

const ConnectedPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(Panel);

export default ConnectedPanel;
