// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {TicketsAdapterInterface as TicketsAdapter} from '../../../application/adapter/Tickets';

class GetTicketsHistory {
    adapter: TicketsAdapter;
    storage: Storage;

    constructor(adapter: TicketsAdapter, storage: Storage) {
        this.adapter = adapter;
        this.storage = storage;
    }

    async invoke(token: AccessToken, locale: string) {
        try {
            const history = await this.adapter.getHistory(token, locale);
            const persistedTickets =
                JSON.parse(this.storage.getItem('tickets')) || {};

            return history.map(ticket => {
                if (persistedTickets[ticket.id] === ticket.updatedAt.unix()) {
                    ticket.shouldShowNotification = false;
                } else {
                    ticket.shouldShowNotification = true;
                }

                return ticket;
            });
        } catch (error) {
            throw error;
        }
    }
}

export default GetTicketsHistory;
