import React from 'react';
import {ContentLabel, ContentColumn, ContentValue, ContentWrap, ContentRow} from '../styled';
import t from '../../../infrastructure/adapter/helper/parseTranslation';
import FormattedPrice from '../../../infrastructure/view/component/FormattedPrice/FormattedPrice';

export default function SymbolsTooltip({value, price, volume, growth, isPositive, isNegative}) {
    return (
        <ContentWrap>
            <ContentColumn>
                <ContentRow>
                    <ContentLabel>{t('label-symbol')}</ContentLabel>
                    <ContentValue>
                        <i
                            className={`icon-${value.split('/')[0].toLowerCase()}`}
                        />
                        <span className="pair">{value}</span>
                        <i className={`icon-${value.split('/')[1].toLowerCase()}`} />
                    </ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-price')}</ContentLabel>
                    <ContentValue>{<FormattedPrice price={+price} />}</ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-volume')}</ContentLabel>
                    <ContentValue>{<FormattedPrice price={+volume} />}</ContentValue>
                </ContentRow>
                <ContentRow>
                    <ContentLabel>{t('label-growth')}</ContentLabel>
                    <ContentValue isPositive={isPositive} isNegative={isNegative}>{(+growth).toFixed(2)}%</ContentValue>
                </ContentRow>
            </ContentColumn>
        </ContentWrap>
    );
}
