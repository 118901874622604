import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import parseTranslation from '../../../adapter/helper/parseTranslation';

function Error({fontSize, errorMessage, ...otherProps}) {
    return (
        <Styles fontSize={fontSize} {...otherProps}>
            {errorMessage || parseTranslation('error-message')}
        </Styles>
    );
}

Error.defaultProps = {
    fontSize: '2.333rem',
};

Error.propTypes = {
    fontSize: PropTypes.string.isRequired,
};

const Styles = styled.div`
    padding: 2rem;
    font-size: ${p => p.fontSize} !important;
    line-height: 2.2 !important;
    color: #FF4D4D !important;
    text-align: center;
`;

export default Error;
