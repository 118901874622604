import React, { Component } from 'react';
import Collapsible from 'react-collapsible';



class CollapsibleFaq extends Component {

    render() {
        return (
            <div className="collapsible">
                <Collapsible trigger="GETTING STARTED (12)">
                    <p>This is the collapsible content. It can be any element or React component you like.</p>
                    <p>It can even be another Collapsible component. Check out the next section!</p>
                </Collapsible>

                <Collapsible trigger="SECURITY (10)">
                    <p>This is the collapsible content. It can be any element or React component you like.</p>
                    <p>It can even be another Collapsible component. Check out the next section!</p>
                </Collapsible>

                <Collapsible trigger="TRADING (25)">
                    <p>This is the collapsible content. It can be any element or React component you like.</p>
                    <p>It can even be another Collapsible component. Check out the next section!</p>
                </Collapsible>

                <Collapsible trigger="ORDER TYPES (10)">
                    <p>This is the collapsible content. It can be any element or React component you like.</p>
                    <p>It can even be another Collapsible component. Check out the next section!</p>
                </Collapsible>

                <Collapsible trigger="MARGIN FUNDING (13)">
                    <p>This is the collapsible content. It can be any element or React component you like.</p>
                    <p>It can even be another Collapsible component. Check out the next section!</p>
                </Collapsible>

                <Collapsible trigger="MOVING FUNDS (22)">
                    <p>This is the collapsible content. It can be any element or React component you like.</p>
                    <p>It can even be another Collapsible component. Check out the next section!</p>
                </Collapsible>
            </div>
        );
    }
}

export default CollapsibleFaq;
