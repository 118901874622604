// @flow
import React, {PureComponent} from 'react';
import classnames from 'classnames';

type PropTypes = {
    title: string,
    width?: boolean,
    className?: string,
    isCenter?: boolean,
};

class Panel extends PureComponent<PropTypes> {
    render() {
        const {
            title,
            width,
            children,
            expandable,
            closable,
            expandHandler,
            closableHandler,
            isCenter,
            icon,
            className,
        } = this.props;

        return (
            <div
                className={classnames('panel', {
                    panel_tabs: width,
                    [className]: className,
                })}
            >
                <div className="panel-header">
                    {icon}
                    <span
                        className={`panel-title ${isCenter &&
                            'panel-title--center-align'}`}
                    >
                        {title}
                    </span>
                    {expandable && (
                        <button
                            className="panel-button"
                            onClick={expandHandler}
                        >
                            <i className="icon-expand" />
                        </button>
                    )}
                    {closable && (
                        <button
                            className="panel-button"
                            onClick={closableHandler}
                        >
                            <i className="icon-minimize-fullscreen-exit" />
                        </button>
                    )}
                </div>
                <div className="panel-body">
                    <div className="panel-body-content">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Panel;
