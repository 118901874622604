// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import Form from 'muicss/lib/react/form';
import Language from 'infrastructure/view/component/Language';
import FormField from '../Registration/component/FormField';
import {setLocal} from '../../services/action/translation';
import {getForm, submitForm, recoveryReducer} from './service/actions';
import {updateForm} from '../../services/action/forms';

import logo from '../Dashboard/components/News/logo.svg';


import parseTranslation from '../../../adapter/helper/parseTranslation';
import config from '../../../../localConfig';
import Error from '../../component/Error';
import {object} from 'prop-types';

function mapStateToProps(state) {
    const form = state.forms['RecoveryForm'] || {};
    const formError = state.forms.error;
    const {language, errorLanguage} = state.translation;
    const {locale} = state.settings;

    return {
        form,
        formError,
        locale,
        language,
        errorLanguage,
        langName: parseTranslation('lang-name'),
        titleRecovery: parseTranslation('recovery-title'),
        recoverInfo: parseTranslation('recover-info'),
        recoverCancel: parseTranslation('recover-cancel'),
        recoverSubmitEmail: parseTranslation('recover-submit-email'),
        recoveryMessage: parseTranslation('recover-message'),
    };
}

const mapDispatchToProps = {
    getForm,
    submitForm,
    updateForm,
    recoveryReducer,
    setLocal,
};

class Recovery extends Component {
    captchaRef = null;

    state = {
        successSubmitForm: false,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.captchaRefCallback = this.captchaRefCallback.bind(this);
    }

    componentDidMount() {
        this.props.getForm();
        this.script = document.createElement("script");
        this.script.src = config.recaptchaUrl;
        this.script.async = true;
        this.script.defer = true;
        document.body.appendChild(this.script);
    }

    componentWillUnmount() {
        const scripts = document.getElementsByTagName('script');
        for (let script of scripts) {
            if (script.src.includes('recaptcha')) {
                script.remove();
            }
        }
    }

    renderSuccessText() {
        return (
            <>
                <div className="message-wrap">
                    <p
                        className="recovery-message"
                        dangerouslySetInnerHTML={{
                            __html: this.props.recoveryMessage,
                        }}
                    />
                </div>
            </>
        );
    }

    renderForm() {
        const {formError} = this.props;
        const {scheme, errors, name} = this.props.form;

        return (
            <>
                <Language className="lng-recovery" leftBottom={true} />
                {!this.state.successSubmitForm && (
                    <Form onSubmit={this.onSubmit}>
                        <h3>{this.props.titleRecovery}</h3>
                        {!formError && (
                            <p className="text-indent-down">
                                <i className="icon-info" />
                                {this.props.recoverInfo}
                            </p>
                        )}
                        {formError && <Error />}
                        {scheme && (
                            <>
                                {Object.values(scheme)
                                    .sort((a, b) => a.idx - b.idx)
                                    .map(field => (
                                        <FormField
                                            key={field.name}
                                            name={field.name}
                                            type={field.type}
                                            label={field.label}
                                            options={field.options}
                                            placeholder={field.placeholder}
                                            onChange={value => {
                                                this.props.updateForm(
                                                    'RecoveryForm',
                                                    field.name,
                                                    value
                                                );
                                                this.setState({[field.name]: value});
                                            }
                                            }
                                            captchaRefCallback={
                                                this.captchaRefCallback
                                            }
                                            error={
                                                !Array.isArray(errors) &&
                                                errors[field.name] &&
                                                errors[field.name][0]
                                            }
                                            formName={name}
                                            animatePlaceholder={true}
                                        />
                                    ))}

                                <div className="btn-wrap">
                                    <Link
                                        to="/login"
                                        className="link-item"
                                    >
                                        {this.props.recoverCancel}
                                    </Link>
                                    <button type="submit">
                                        {this.props.recoverSubmitEmail}
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </>
        );
    }

    render() {
        return (
            <div className="container recovery-container">
                <div className="wrapper">
                    <div className="content recovery login-img">
                        <div
                            className={`column-wrap ${
                                this.state.successSubmitForm ? 'send-email' : ''
                            }`}
                        >
                            <a
                                href={`${config.landingUrl}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="logo-link"
                            >
                                <img src={logo} alt="logo" />
                            </a>

                            {(this.state.successSubmitForm &&
                                this.renderSuccessText()) ||
                                this.renderForm()}
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps): void {
        const { form } = this.props;
        if (form.result !== prevProps.form.result && form.result === true) {
            this.setState({successSubmitForm: true});
        }
        const {locale, getForm} = this.props;

        if (prevProps.locale !== locale) {
            getForm();
        }
    }

    changeLocal = e => {
        const local = e.target.dataset.code;
        this.props.setLocal(local);
        localStorage.setItem('local', local);
    };

    onSubmit(e) {
        e.preventDefault();
        Object.keys(this.state).map(item => {
                this.props.updateForm(
                    'RecoveryForm',
                    item,
                    this.state[item]
                )
            }
        );
        this.props.submitForm();

        if (this.captchaRef) {
            this.captchaRef.reset();
        }
    }

    captchaRefCallback(ref) {
        this.captchaRef = ref;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Recovery);
