export const ActionTypes = {
    toggleFullscreen: 'fullscreen.toggle',
    setFullscreenValue: 'fullscreen.value.set',
};

export function toggleFullscreen() {
    return {
        type: ActionTypes.toggleFullscreen
    }
}

export function setFullscreenValue(isEnabled) {
    return {
        type: ActionTypes.setFullscreenValue,
        isEnabled
    }
}
