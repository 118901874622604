// @flow
import DealingAdapter from '../../infrastructure/adapter/Dealing';

class UnselectAsset {
    dealingAdapter: DealingAdapter;

    constructor(dealingAdapter: DealingAdapter) {
        this.dealingAdapter = dealingAdapter;
    }

    invoke(asset: string): void {
        try {
            this.dealingAdapter.unsubscribeFromAsset(asset);
        } catch (error) {
            throw error;
        }
    }
}

export default UnselectAsset;
