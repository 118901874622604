import {ActionTypes} from './actions';

const INITIAL_STATE = {
    isOpen: false,
    cryptoCurrencyFilter: '',
    findFilter: '',
    modalSymbols: '',
};

function layoutModalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.open:
            return {
                ...state,
                isOpen: true,
            };

        case ActionTypes.close:
            return {
                ...state,
                isOpen: false,
            };

        case ActionTypes.setCryptoCurrencyFilter:
            return {
                ...state,
                cryptoCurrencyFilter: action.filter,
            };

        case ActionTypes.setFindFilter:
            return {
                ...state,
                findFilter: action.filter,
            };

        case ActionTypes.setSymbols:
            return {
                ...state,
                modalSymbols: action.symbols,
            };

        default:
            return state;
    }
}

export default layoutModalReducer;
