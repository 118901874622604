// @flow
import {createSelector} from 'reselect';

import {type State as ChatState, type IncomingTypingStatus} from './reducer';

type State = {
    ['module.chat']: ChatState,
};

const moduleChat = (state: State) => state['module.chat'];
const user = state => state['user'];

export const getAccessChatDataError = state => state['module.chat'].accessChatDataError;
export const getSocketError = state => state['module.chat'].socketError;

export const isActiveChat: boolean = createSelector(
    moduleChat,
    moduleChat => moduleChat.isActive
);

export const isConnected: boolean = createSelector(
    moduleChat,
    moduleChat => moduleChat.isConnected
);

export const messages: [] = createSelector(
    moduleChat,
    moduleChat => moduleChat.messages
);

export const messageText: string = createSelector(
    moduleChat,
    moduleChat => moduleChat.messageText
);

export const incomingTypingStatus: IncomingTypingStatus = createSelector(
    moduleChat,
    moduleChat => moduleChat.incomingTypingStatus
);

export const userInfo: string = createSelector(
    user,
    user => ({
        username: user.info.username,
        firstName: user.info.first_name,
        lastName: user.info.last_name,
    })
);
