// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {TicketsAdapterInterface as TicketsAdapter} from '../../../application/adapter/Tickets';

class GetTicket {
    adapter: TicketsAdapter;
    storage: Storage;

    constructor(adapter: TicketsAdapter, storage: Storage) {
        this.adapter = adapter;
        this.storage = storage;
    }

    async invoke(token: AccessToken, id: number, locale: string) {
        try {
            const ticket = await this.adapter.getTicket(token, id, locale);

            const persistedTickets =
                JSON.parse(this.storage.getItem('tickets')) || {};
            persistedTickets[id] = ticket.updatedAt.unix();
            this.storage.setItem('tickets', JSON.stringify(persistedTickets));

            return ticket;
        } catch (error) {
            throw error;
        }
    }
}

export default GetTicket;
