// @flow
import type {DealingAdapterInterface as DealingAdapter} from '../adapter/Dealing';
import type {AccessToken} from '../../domain/entitiy/AccessToken';

class FetchChartDataHistory {
    dealingAdapter: DealingAdapter;

    constructor(dealingAdapter: DealingAdapter) {
        this.dealingAdapter = dealingAdapter;
    }

    async invoke(
        token: AccessToken,
        asset: string,
        interval: string,
        limit: number,
        offset: number | null = null,
    ): Promise<any> {
        return await this.dealingAdapter.getChartDataHistory(
            token,
            asset,
            interval,
            limit,
            offset,
        );
    }
}

export default FetchChartDataHistory;
