// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import type Moment from 'moment';

import parseTranslation from 'infrastructure/adapter/helper/parseTranslation';

type PropTypes = {
    id: number,
    status: string,
    category: string,
    updateAt: Moment,
    additionalInfo: {
        title: string,
        description: string,
    },
    shouldShowNotification: boolean,
    fetchTicket(id: string): void,
};

function mapStateToProps(state, ownProps) {
    const {category, title} = ownProps;

    return {
        ticketTitle:
            category.toLowerCase() === 'question'
                ? title
                : parseTranslation(title),
        labelCategory: parseTranslation('ticket-category'),
        labelUpdateTime: parseTranslation('ticket-update-time'),
        subcategoryTitle: parseTranslation('ticket-subcategory-title'),
        noOption: parseTranslation('ticket-subcategory-no-option'),
        _category: {
            question: parseTranslation('create-ticket-question-tab'),
            verification: parseTranslation('create-ticket-verification-tab'),
            withdraw: parseTranslation('label-withdraw-real'),
            listing: parseTranslation('label-listing'),
        },
    };
}

class ListItem extends PureComponent<PropTypes> {
    constructor(props: PropTypes) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(): void {
        this.props.fetchTicket(this.props.id);
    }

    render() {
        const {
            id,
            title,
            status,
            category,
            updatedAt,
            additionalInfo,
            selectedTicketId,
            shouldShowNotification,
        } = this.props;

        const translations = {
            ticketTitle:
                category.toLowerCase() === 'question'
                    ? title
                    : parseTranslation(title),
            labelCategory: parseTranslation('ticket-category'),
            labelUpdateTime: parseTranslation('ticket-update-time'),
            subcategoryTitle: parseTranslation('ticket-subcategory-title'),
            noOption: parseTranslation('ticket-subcategory-no-option'),
            _category: {
                question: parseTranslation('create-ticket-question-tab'),
                verification: parseTranslation(
                    'create-ticket-verification-tab'
                ),
                withdraw: parseTranslation('label-withdraw-real'),
                listing: parseTranslation('label-listing'),
            },
        };

        const subcategoryText = additionalInfo.method
            ? additionalInfo.method[0].toUpperCase() +
              additionalInfo.method.slice(1)
            : '-';

        let statusIcon = status === 'processed' ? 'confirmed' : status;

        return (
            <div
                className={`ticket ${id === selectedTicketId ? 'active' : ''}`}
                onClick={this.handleClick}
            >
                <div className="ticket-title">
                    <span
                        className={`ticket-title__text ticket-title_${status}`}
                    >
                        {translations.ticketTitle}
                    </span>
                    <i className={`icon-${statusIcon} color-${statusIcon}`} />
                </div>
                <div className="ticket-meta">
                    <div className="info-block category">
                        <div className="info-block-title">
                            {translations.labelCategory}
                        </div>

                        <div className="info-block-content">
                            {translations._category[category.toLowerCase()]}
                        </div>
                    </div>
                    <div className="info-block subcategory">
                        <div className="info-block-title">
                            {translations.subcategoryTitle}
                        </div>
                        <div className="info-block-content">
                            {subcategoryText}
                        </div>
                    </div>
                    <div className="info-block time">
                        <div className="info-block-title">
                            {translations.labelUpdateTime}
                        </div>
                        <div className="info-block-content">
                            {updatedAt.format('DD/MM/YY HH:mm:ss')}
                        </div>
                    </div>
                </div>
                {shouldShowNotification && (
                    <div className="ticket-notification" />
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps)(ListItem);
