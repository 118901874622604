// @flow
class SubmitLoginForm {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(formData: {} = {}, locale): Promise<any> {
        try {
            return await this.adapter.sendAuthForm(formData, locale);
        } catch (error) {
            if(error.response) {
                const {status, data} = error.response;

                if (status === 422) {
                    return data;
                }
            }
            throw error;
        }
    }
}

export default SubmitLoginForm;
