// @flow
import _ from 'lodash';
import axios from '../../../adapter/helper/axios';

import {
    ActionTypes as UserActionTypes,
    errorSubscribeToSymbol,
    setDealingToken,
} from '../action/user';
import {ActionTypes as OrderFormActionTypes} from '../../scene/Dashboard/components/OrderForm/service/actions';
import {
    ActionTypes as TradingHistoryActionTypes,
    clearClosedOrders,
    errorClosedOrders,
    errorOpenOrders,
    fetchClosedOrders,
} from '../../scene/Dashboard/components/TradingHistory/service/actions';
import {
    ActionTypes as MarketTradesActionTypes,
    addHistoryToMarketTradesTableError,
    fetchMarketTradesHistory,
} from '../../scene/Dashboard/components/MarketTrades/service/actions';
import {
    addNewTickChartTV,
    cleanupNewTick,
    clearCharts,
} from '../../scene/Dashboard/components/Chart/service/actions';
import {
    ActionTypes as DXChartAction,
    chartHistoryIsPending,
    chartHistoryRequest,
    setNoDataErrorChart,
} from '../action/DXChart';
import {ActionType as currencyAction} from 'infrastructure/view/services/action/currency';
import {ActionTypes as socketActionTypes} from 'infrastructure/view/services/action/socket';

import {setUserData, setBalances} from '../../services/action/user';

import {setSymbols} from '../../scene/Dashboard/components/Symbols/service/actions';
import {
    setOpenedOrders,
    openOrderSuccess,
    updateOrder,
    setClosedOrders,
} from '../../scene/Dashboard/components/TradingHistory/service/actions';
import {
    setLoading,
    updateDOM,
} from '../../scene/Dashboard/components/DOM/service/actions';
import {
    addOrdersToMarketTradesTable,
    addHistoryToMarketTradesTable,
    clearMarketTradesTable,
    changePending,
} from '../../scene/Dashboard/components/MarketTrades/service/actions';
import {
    addOrdersToChart,
    //clearCharts,
    addChartDataHistory,
} from '../../scene/Dashboard/components/Chart/service/actions';
import {chartHistorySuccess, chartHistoryFailure} from '../action/DXChart';
import {removeOrderFromList, cancelOrderError} from '../../scene/Dashboard/components/TradingHistory/service/actions';
import {openDepositModal, setOrderForm} from '../../scene/Dashboard/service/actions';
import {errorOrder} from '../../scene/Dashboard/service/actions';
import {notificationReceived} from '../action/notification';
import {Order} from '../../../../domain/entitiy/Order';
import {Asset} from '../../../../domain/entitiy/Asset';

import UserAdapter from '../../../adapter/User';
import DealingAdapter from '../../../adapter/Dealing';
import SelectAssetUseCase from '../../../../application/use-case/SelectAsset';
import UnselectAssetUseCase from '../../../../application/use-case/UnselectAsset';
import OpenOrderUseCase from '../../../../application/use-case/OpenOrder';
import CloseOrderUseCase from '../../../../application/use-case/CloseOrder';

import FetchChartDataHistory from '../../../../application/use-case/FetchChartDataHistory';
import {
    startAbsoluteSessionTimer,
    startActivityTimer,
    startIntervalUpdateSession,
} from '../action/session';
import {
    errorUserData,
    setSelectedSymbol as setSelectedSymbolUser,
} from '../../services/action/user';
import {sendSettingsData, setSelectedSymbol} from '../action/settings';
import {notificationMessageMap} from 'settings';
import GetDealingToken from '../../../../application/use-case/user/GetDealingToken';
import LoginFormAdapter from '../../../adapter/LoginForm';
import store from '../store';
import {openExchange} from '../../scene/Dashboard/components/Header/actions';


let useCases;
let dealingAdapter;
let prevCurrencyPair;

export default function(store: {dispatch: Function, getState: Function}) {
    return function(next: Function) {
        return async function(action: {type: string, ...any}) {
            if (action.type === UserActionTypes.getUserData) {
                const userAdapter = new UserAdapter(axios);
                getUserData(userAdapter)(store);
            }

            if (action.type === UserActionTypes.dealingToken) {
                dealingAdapter = new DealingAdapter(axios, WebSocket);
                await dealingAdapter.connect(action.dealingToken, store);

                subscribeToTradingEvents(dealingAdapter)(store);

                useCases = initUseCaces(dealingAdapter);

                try {
                    const symbolToSubscribe = store.getState().settings
                        .selectedSymbol.name;
                    prevCurrencyPair = action.sitePair || symbolToSubscribe;

                    useCases.selectAsset.invoke(action.sitePair || symbolToSubscribe);

                    dealingAdapter.getOpenedOrders();
                } catch (error) {
                    store.dispatch(errorOpenOrders(error));
                }

                try {
                    // const state = store.getState();
                    // const { accessToken } = state.user;
                    // const symbol = state.settings.selectedSymbol.name;
                    // const { interval, minDataLength: limit } = state.chart.orderForm;
                    // const history = await useCases.fetchChartDataHistory.invoke(
                    //     accessToken,
                    //     symbol,
                    //     interval,
                    //     limit,
                    //     0
                    // );
                    // store.dispatch(addChartDataHistory(10, history.data));
                } catch (e) {}
            }

            if (action.type === socketActionTypes.DEALING_SOCKET_DISCONNECT) {
                dealingAdapter && dealingAdapter.socket.close();
            }

            if (action.type === currencyAction.CHANGE_CURRENCY) {
                const {payload} = action;
                window.history.pushState(null, null, `/dashboard/${payload.replace('/', '-')}`);
                const selectedPair = store.getState().settings.selectedSymbol.name;
                if (selectedPair !== payload) {
                    store.dispatch(setLoading(true));
                }

                store.dispatch(setOrderForm({
                    price: '',
                    amount: '',
                }));


                if (payload) {
                    const [numerator, denominator] = payload.split('/');
                    const {
                        dxChart: {chartInterval},
                        selectedSymbol: {name},
                    } = store.getState().settings;

                    if (name !== payload) {
                        store.dispatch(
                            fetchMarketTradesHistory(1, payload)
                        );
                        store.dispatch(setSelectedSymbol({numerator, denominator}));
                        store.dispatch(sendSettingsData());
                        store.dispatch(
                            setSelectedSymbolUser({numerator, denominator})
                        );
                        // store.dispatch(clearClosedOrders());
                        // store.dispatch(fetchClosedOrders(1));
                        store.dispatch(cleanupNewTick());
                        store.dispatch(chartHistoryIsPending());
                        store.dispatch(chartHistoryRequest({
                            symbol: payload,
                            resolution: chartInterval,
                            limit: 1000,
                        }))
                    }
                }
            }

            if (
                action.type === MarketTradesActionTypes.fetchMarketTradesHistory
            ) {
                try {
                    const {selectedSymbol} = store.getState().settings;
                    const {page, symbol} = action;
                    if (!dealingAdapter) {
                        dealingAdapter = new DealingAdapter(axios, WebSocket);
                    }

                    let currentSymbol;
                    if (symbol) {
                        currentSymbol = symbol;
                    } else {
                        currentSymbol = selectedSymbol.name;
                    }

                    store.dispatch(changePending(true));

                    const data = await dealingAdapter.getClosedDealsHistory(
                        currentSymbol,
                        page,
                        store
                    );

                    store.dispatch(addHistoryToMarketTradesTable(data));
                } catch (error) {
                    store.dispatch(addHistoryToMarketTradesTableError(error));
                }
            }

            if (action.type === DXChartAction.chartHistoryRequest) {
                try {
                    const state = store.getState();
                    const {accessToken} = state.user;
                    const {symbol, resolution, limit} = action.payload;
                    if (!dealingAdapter) {
                        dealingAdapter = new DealingAdapter(axios, WebSocket);
                    }
                    const history = await dealingAdapter.getChartDataHistory(
                        accessToken,
                        symbol,
                        resolution,
                        limit
                    );

                    // if (!history.data.length){
                    //     store.dispatch(setNoDataErrorChart(false));
                    // } else {
                        store.dispatch(chartHistorySuccess(history.data));
                    // }
                } catch (e) {
                    setTimeout(() => {
                        store.dispatch(chartHistoryFailure(e));
                    }, 0);
                }
            }

            if (action.type === OrderFormActionTypes.openOrder) {
                const {asset, price, amount, exType, type} = action.payload;

                useCases.openOrder.invoke(asset, price, amount, exType, type);
            }

            if (action.type === UserActionTypes.setSelectedSymbol) {
                // store.dispatch(clearMarketTradesTable());
                try {
                    const symbolToSubscribe = `${action.selectedSymbol.numerator}/${action.selectedSymbol.denominator}`;
                    const symbolToUnsubscribe = store.getState().settings
                        .selectedSymbol.name;

                    useCases.selectAsset.invoke(
                        symbolToSubscribe,
                        prevCurrencyPair || symbolToUnsubscribe
                    );
                    prevCurrencyPair = symbolToSubscribe;
                } catch (e) {
                    store.dispatch(errorSubscribeToSymbol());
                }
            }

            if (action.type === TradingHistoryActionTypes.closeOrder) {
                const {symbol, type, id} = action.order;

                useCases.closeOrder.invoke(symbol, type, id);
            }
            if (action.type === TradingHistoryActionTypes.fetchClosedOrders) {
                try {
                    const {accessToken} = store.getState().user;
                    const {name} = store.getState().settings.selectedSymbol;
                    const data = await dealingAdapter.getUserClosedDealsHistory(
                        name,
                        action.page,
                        accessToken
                    );

                    store.dispatch(setClosedOrders(data));
                } catch (error) {
                    store.dispatch(errorClosedOrders(error));
                }
            }

            next(action);
        };
    };
}

function initUseCaces(adapter) {
    return {
        selectAsset: new SelectAssetUseCase(adapter),
        unselectAsset: new UnselectAssetUseCase(adapter),
        openOrder: new OpenOrderUseCase(adapter),
        closeOrder: new CloseOrderUseCase(adapter),
        fetchChartDataHistory: new FetchChartDataHistory(adapter),
    };
}

function getUserData(adapter) {
    return async function(store) {
        try {
            const {accessToken} = store.getState().user;
            const {info: {email}} = store.getState().user;

            const {data} = await adapter.getUserData(accessToken);

            store.dispatch(setUserData(data));
            store.dispatch(startActivityTimer());
            store.dispatch(startAbsoluteSessionTimer());
            store.dispatch(startIntervalUpdateSession());

            await controllerTwoFAModal(data.info.username, email);

        } catch (e) {
            store.dispatch(errorUserData(e));
        }
    };
}

async function controllerTwoFAModal(userName, email) {
    if (!userName) {
        return false;
    }
    const _un = localStorage.getItem('username');
    if (_un === userName) {
        return false;
    }
    localStorage.removeItem(email);
    localStorage.setItem('username', userName);
    return true;
}

const loginFormAdapter = new LoginFormAdapter(axios);
const getDealingToken = new GetDealingToken(loginFormAdapter);

function subscribeToTradingEvents(adapter: DealingAdapter) {
    return function(store: {dispatch: Function, getState: Function}) {
        // Reconnect web socket
        async function reconnectDealingSocket() {
            const {accessToken} = store.getState().user;

            const dealingToken = await getDealingToken.invoke(accessToken);
            store.dispatch(setDealingToken(dealingToken));
        }

        adapter.on('dealing-socket-onclose', async (assets: Asset[]) => {
            await reconnectDealingSocket();
        });

        adapter.on('assets-data', (assets: Asset[]) => {
            store.dispatch(setSymbols(assets));
        });

        adapter.on('confirm-deals', (orders: Order[]) => {
            const {
                selectedSymbol,
                dxChart: {chartInterval},
            } = store.getState().settings;

            const {isPending} = store.getState().DXChart;

            const filteredOrders = orders.filter(
                order => order.symbol === selectedSymbol.name
            );
            const sortedOrders = orders.sort((a, b) =>
                a.openingTime.isBefore(b.openingTime) ? -1 : 1
            );

            store.dispatch(addOrdersToMarketTradesTable(filteredOrders));

            sortedOrders.forEach(order => {
                if (selectedSymbol.name === order.symbol) {
                    if (!isPending) {
                        store.dispatch(
                            addOrdersToChart([order], chartInterval)
                        );
                    }
                    store.dispatch(addNewTickChartTV(order));
                }
            });
        });

        // adapter.on('closed-deals', (deals: Order[]) => {
        //     store.dispatch(addHistoryToMarketTradesTable(deals));
        // });

        // TODO: check if this is needed to save chart history
        // adapter.on('chart-data-history', (history: { deals: Order[], symbol: string }) => {
        //     // TODO: Check if history have no entries and fetch from `asset-closed-deals` route
        //     store.dispatch(
        //         addChartDataHistory(history)
        //     );
        // });

        adapter.on('user-orders', (orders: Order[]) => {
            store.dispatch(setOpenedOrders(orders));
        });

        adapter.on('open-order', (order: Order) => {
            const {symbols} = store.getState().symbols;
            store.dispatch(
                openOrderSuccess(
                    order.isMarket
                        ? {
                              ...order,
                              price: symbols[order.symbol].price,
                          }
                        : order
                )
            );
            store.dispatch(openExchange(false));
            const MAX_ORDERS = 100;
            const openedOrdersLength = Object.keys(
                store.getState().tradingHistory.openedOrders
            ).length;


                store.dispatch(
                    openOrderSuccess(
                        order.isMarket
                            ? {
                                  ...order,
                                  price: symbols[order.symbol].price,
                              }
                            : order
                    )
                );

        });

        adapter.on('open-order-error', orderError => {
            store.dispatch(errorOrder(orderError));
            store.dispatch(openDepositModal());
            const {error} = orderError;
            if (
                error === 'INCORRECT_COMMAND' ||
                error === 'NOT_ENOUGH_BALANCE' ||
                error === 'COMMAND_LIMIT_TIMEOUT' ||
                error === 'LIMIT_EXCEEDED'
            ) {
                store.dispatch(openDepositModal());
            }


            if (error === 'NOT_ENOUGH_BALANCE') {
                store.dispatch(
                    notificationReceived(
                        {
                            id: _.uniqueId('notification_'),
                            code: 'not_enough_balance',
                        },
                        false
                    )
                );
            } else if (error === 'INCORRECT_COMMAND') {
                store.dispatch(
                    notificationReceived(
                        {
                            id: _.uniqueId('notification_'),
                            code: 'fill_order_form',
                        },
                        false
                    )
                );
            } else if (error === 'LIMIT_EXCEEDED') {
                store.dispatch(
                    notificationReceived(
                        {
                            code: 'order-limit-error',
                            id: _.uniqueId('notification_'),
                        },
                        false
                    )
                );
            }
        });

        adapter.on('cancel-order', (id: string) => {
            store.dispatch(removeOrderFromList(id));
        });

        adapter.on('cancel-order-error', cancelOrder => {
            const {orderId, error} = cancelOrder;
            if (error === 'INCORRECT_COMMAND') {
                store.dispatch(
                    notificationReceived(
                        {
                            orderId,
                            id: _.uniqueId('notification_'),
                            code: 'cancel_order_incorrect',
                        },
                        false
                    )
                );
            } else if (error === 'COMMAND_LIMIT_TIMEOUT') {
                store.dispatch(openDepositModal());
                store.dispatch(errorOrder({error}));
            } else if (error === 'ORDER_CANCEL_ERROR') {
                store.dispatch(
                    notificationReceived({
                        orderId,
                        id: _.uniqueId('notification_'),
                        code: 'cancel_order_incorrect',
                    })
                );
            } else {
                store.dispatch(cancelOrderError(orderId));
            }
        });

        adapter.on('confirm-order', (order: Order) => {
            const {symbols} = store.getState();
            store.dispatch(updateOrder(order, symbols));
        });

        adapter.on(
            'order-book',
            (orders: {sell: [], buy: [], symbol: string}) => {
                const {selectedSymbol} = store.getState().settings;
                if (selectedSymbol.name === orders.symbol) {
                    const {buy, sell} = orders;

                    store.dispatch(
                        updateDOM({
                            buy,
                            sell,
                        })
                    );
                }
            }
        );

        adapter.on('balances', (balances: {}) => {
            store.dispatch(setBalances(balances));
        });

        // adapter.on('user-closed-deals', (orders: Order[]) => {
        //     store.dispatch(setClosedOrders(orders));
        // });
    };
}
