export const ActionType = {
    getLanguage: 'translation.language.get',
    setLanguage: 'translation.language.set',
    errorLanguage: 'translation.language.error',
    getTranslation: 'translation.translation.get',
    setTranslation: 'translation.translation.set',
    errorTranslation: 'translation.translation.error',
    setLocal: 'translation.local.set',
};

export function setLanguage(language) {
    return {
        type: ActionType.setLanguage,
        language,
    };
}

export function errorLanguage(payload) {
    return {
        type: ActionType.errorLanguage,
        payload,
    };
}

export function setTranslation(translation) {
    return {
        type: ActionType.setTranslation,
        translation,
    };
}

export function errorTranslation(payload) {
    return {
        type: ActionType.errorTranslation,
        payload,
    };
}

export function setLocal(local) {
    return {
        type: ActionType.setLocal,
        local,
    };
}
export function getLanguage() {
    return {
        type: ActionType.getLanguage,
    };
}
export function getTranslation(payload) {
    return {
        type: ActionType.getTranslation,
        payload,
    };
}
