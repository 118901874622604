import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import InputMask from 'inputmask';

import {getWithdrawForm, submitWithdrawForm} from '../service/actions';
import {updateForm, dropFormResult} from '../../../services/action/forms';
import FormField from '../../Registration/component/FormField';

import parseTranslation from '../../../../adapter/helper/parseTranslation';
import config from '../../../../../localConfig';

import Error from "../../../component/Error";


function mapStateToProps(state) {
    const formError = state.forms.error;
    const form = state.forms['WithdrawForm'] || {};
    const {locale} = state.settings;

    return {
        formError,
        form,
        locale,
        labelErrorCheckbox: parseTranslation('error-checkbox'),
        labelCurrency: parseTranslation('currency-deposit-funds'),
        labelWithdrawMethod: parseTranslation('withdraw-method'),
        labelConfirm: parseTranslation('confirm-deposit-funds'),
        labelAmountWithdraw: parseTranslation('label-amount'),
        receive: parseTranslation('receive'),
        to_you_account: parseTranslation('to-you-account'),
        withdrawal_amount: parseTranslation('withdrawal-amount'),
        free: parseTranslation('free'),
        labelCheckaccept: parseTranslation('deposit-terms-accept'),
        labelCheckconditions: parseTranslation('deposit-terms-conditions'),
        noMoney: parseTranslation('label-withdraw-insufficient-funds'),
    };
}

const mapDispatchToProps = {
    getWithdrawForm,
    submitWithdrawForm,
    updateForm,
    dropFormResult,
};

class WithdrawFunds extends PureComponent {
    state = {
        isSubmitEnabled: false,
        errorClick: false,
        percent: 0,
        commission: 0,
        amount: 0,
    };

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.calcPercent = this.calcPercent.bind(this);
        this.handleCheckClick = this.handleCheckClick.bind(this);
        props.getWithdrawForm();
    }

    componentDidUpdate(prevProps) {
        const {form, locale} = this.props;
        if (prevProps.locale !== this.props.locale) {
            this.props.getWithdrawForm();
        }
        // if (prevProps.form !== form) {
        //     await this.setState({ percent: form.scheme.withdraw_tax.value });
        //     this.calcPercent(form.scheme.amount.value);
        // }
        if (prevProps.form !== form) {
            this.setState({
                percent: form.scheme ? form.scheme.withdraw_tax.value : 0,
            });
            this.calcPercent(form.scheme ? form.scheme.amount.value : 0);
        }
    }

    componentWillUnmount(): void {
        const { scheme } = this.props.form;
        Object.keys(scheme).map(item => {
            this.props.updateForm(
                'WithdrawForm',
                item,
                ''
            );
        });
    }

    handleCheckClick() {
        this.setState({
            isSubmitEnabled: !this.state.isSubmitEnabled,
        });
    }

    render() {
        const {scheme, result, errors} = this.props.form;

        const {formError} = this.props;

        if (this.state.error) {
            return <h1>Caught an error.</h1>;
        }

        if (+result) {
            this.props.dropFormResult('WithdrawForm');

            return <Redirect to="/tickets" />;
        }

        return (
            <Fragment>
            {formError && <Error />}
                <form className="form-billing" onSubmit={this.onSubmit}>
                    {scheme && (
                        <Fragment>
                            {Object.values(scheme)
                                .sort((a, b) => a.idx - b.idx)
                                .map(field => (
                                    <FormField
                                        key={field.name}
                                        name={field.name}
                                        type={field.type}
                                        value={field.value ? field.value : ''}
                                        label={field.label}
                                        options={field.options}
                                        onChange={value => {
                                            if (field.name === 'amount') {
                                                // this.calcPercent(value);
                                                if (
                                                    scheme['currency'].value ===
                                                    'USD' ||
                                                    scheme['currency'].value === 'EUR'
                                                ) {
                                                    const regex = /^\d*(\.|,)?\d{0,2}$/g;
                                                    let match = regex.exec(value);

                                                    if (
                                                        match &&
                                                        match.shift().length <= 16
                                                    ) {
                                                        this.props.updateForm(
                                                            'WithdrawForm',
                                                            field.name,
                                                            value.replace(',', '.')
                                                        );
                                                    }
                                                } else {
                                                    const regex = /^\d*\.?\d{0,8}$/g;
                                                    let match = regex.exec(value);

                                                    if (
                                                        match &&
                                                        match.shift().length <= 16
                                                    ) {
                                                        this.props.updateForm(
                                                            'WithdrawForm',
                                                            field.name,
                                                            value.replace(',', '.')
                                                        );
                                                    }
                                                }
                                            } else if (field.name === 'card_number') {
                                                const regex = /^\d*$/g;
                                                let match = regex.exec(value);

                                                if (
                                                    match &&
                                                    match.shift().length <= 25
                                                ) {
                                                    this.props.updateForm(
                                                        'WithdrawForm',
                                                        field.name,
                                                        value
                                                    );
                                                }
                                            } else if (
                                                field.name === 'card_expiration'
                                            ) {
                                                const im = new InputMask('99/99');
                                                im.mask(
                                                    document.querySelectorAll(
                                                        'input[name=card_expiration]'
                                                    )
                                                );
                                                this.props.updateForm(
                                                    'WithdrawForm',
                                                    field.name,
                                                    value
                                                );
                                            } else {
                                                this.props.updateForm(
                                                    'WithdrawForm',
                                                    field.name,
                                                    value
                                                );
                                            }

                                            if (
                                                field.name === 'currency' ||
                                                field.name === 'method'
                                            ) {
                                                this.props.getWithdrawForm({
                                                    WithdrawForm: {
                                                        [field.name]: value,
                                                        amount: scheme.amount.value,
                                                    },
                                                });
                                            }
                                        }}
                                        disabled={field.options.items && field.options.items.length < 1}
                                        error={
                                            !Array.isArray(errors) &&
                                            errors[field.name] &&
                                            errors[field.name][0]
                                        }
                                    />
                                ))}

                            <div className="incorporated percent-calc">
                                {this.props.receive}{' '}
                                <span>
                    {this.state.commission}{' '}
                                    {document.querySelector('select[name="currency"]') &&
                                    document.querySelector('select[name="currency"]')
                                        .value}
                </span>{' '}
                                {this.props.to_you_account}
                                <br/>
                                (
                                {this.state.amount ? this.state.amount : 0}{' '}
                                {document.querySelector('select[name="currency"]') &&
                                document.querySelector('select[name="currency"]')
                                    .value}{' '}
                                {this.props.withdrawal_amount} - {this.state.percent}%{' '}
                                {this.props.free} ={' '}
                                <span>
                    {this.state.commission}{' '}
                                    {document.querySelector('select[name="currency"]') &&
                                    document.querySelector('select[name="currency"]')
                                        .value}
                </span>)
                            </div>
                            <div className="incorporated">
                                {/*ToDo: block for incorporated text*/}
                            </div>

                            <div className="check-billing">
                                <label>
                                    <input
                                        id="withdraw-funds-checkbox"
                                        // className="icon-check-mark"
                                        type="checkbox"
                                        checked={this.state.isSubmitEnabled}
                                        onChange={this.handleCheckClick}
                                        style={{
                                            border:
                                                this.state.errorClick &&
                                                !this.state.isSubmitEnabled
                                                    ? '0.16rem solid #EA6433'
                                                    : '0.16rem solid #999999',
                                        }}
                                    />
                                    {this.props.labelCheckaccept}
                                    <a
                                        href={`${config.landingUrl}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {' '}
                                        {this.props.labelCheckconditions}{' '}
                                    </a>
                                </label>

                                {this.state.errorClick && !this.state.isSubmitEnabled && (
                                    <p
                                        style={{
                                            color: '#FF4D4D',
                                            position: 'absolute',
                                            fontSize: '1.8rem',
                                        }}
                                    >
                                        {this.props.labelErrorCheckbox}
                                    </p>
                                )}
                            </div>

                            <button
                                type="submit"
                                className={`confirm ${!this.state.isSubmitEnabled &&
                                'disable'}`}
                                disabled={!this.state.isSubmitEnabled}
                            >
                                {this.props.labelConfirm}
                            </button>
                        </Fragment>
                    )}
                </form>
            </Fragment>
        );
    }

    calcPercent(amount) {
        const commission = amount - (amount * this.state.percent) / 100;
        const select =
            document.querySelector('select[name="currency"]') &&
            document.querySelector('select[name="currency"]').value;
        if (select === 'USD' || select === 'EUR') {
            this.setState({
                amount: amount,
                commission: parseFloat(commission).toFixed(2),
            });
        } else {
            this.setState({
                amount: amount,
                commission: parseFloat(commission).toFixed(8),
            });
        }
    }

    onSubmit(e) {
        if (!this.state.isSubmitEnabled) {
            this.setState({errorClick: true});
        } else {
            if (this.state.isSubmitEnabled) {
                this.setState({errorClick: false});
            }
            this.props.submitWithdrawForm();
        }
        e.preventDefault();
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithdrawFunds);
