import React, {useState} from 'react';
import {
    Wrapper,
    Header,
    Body,
    Icon,
    Title,
    Expand,
    HeaderComponent,
} from './styled';
export default function Panel({
    title,
    children,
    icon,
    headerComponent,
    onCollapse,
    hideExpander,
}) {
    const [collapse, setCollapse] = useState(false);
    const handleCollapse = () => {
        onCollapse && onCollapse(!collapse)
        setCollapse(!collapse);
    };
    return (
        <Wrapper collapse={collapse}>
            <Header>
                <Title>
                    <Icon className={icon} />
                    {title}
                </Title>
                <HeaderComponent>{headerComponent}</HeaderComponent>
                {!hideExpander && (
                    <Expand
                        className={
                            collapse
                                ? 'icon-minimize-fullscreen-exit'
                                : 'icon-expand'
                        }
                        onClick={handleCollapse}
                    />
                )}
            </Header>
            <Body>{children}</Body>
        </Wrapper>
    );
}
