import styled from 'styled-components';
import colors from '../../theme/colors';

export const InputWrapper = styled.div``;
export const SortButtons = styled.div`
  line-height: 2.9rem;
`;

export const InputLabel = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Label = styled.div`
    font-size: 2.3rem;
    color: ${colors.color5};    
`;

export const MinMaxButton = styled.i`
    font-size: 3rem;
    cursor: pointer;
    position:relative;
    &:hover {
        color: ${colors.color8};
        div {
          display: flex;
        }
    }

    &:active {
        color: ${colors.color7};
    }
`;

export const MinButton = styled(MinMaxButton)`
    color: ${colors.color13};
    vertical-align: top;
`;

export const MaxButton = styled(MinMaxButton)`
    color: ${colors.color10};
    margin-right: 2.3rem;
    vertical-align: top;
`;
