export const ActionType = {
    CHANGE_CURRENCY: 'CHANGE_CURRENCY',
};

export function changeCurrency(payload) {
    return {
        type: ActionType.CHANGE_CURRENCY,
        payload,
    };
}
