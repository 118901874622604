// @flow
import type Axios from "axios";
import localConfig from 'localConfig';

import type { LoginFormAdapterInterface } from "../../application/adapter/LoginForm";
import type { AccessToken } from "../../domain/entitiy/AccessToken";
import type { DealingAccessToken } from "../../domain/entitiy/DealingAccessToken";
import {getDomain} from 'utils';

class LoginFormAdapter implements LoginFormAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getForm(locale: string, token: string = ""): Promise<any> {
        return await this.apiClient({
            method: "PUT",
            url: `${localConfig.authUrl}/v1/form/login`,
            withCredentials: false,
            headers: {
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`,
            }
        });
    }

    async getMathCaptcha(url): Promise<any> {
        return await this.apiClient({
            method: "GET",
            url,
            withCredentials: false,
            responseType: 'arraybuffer',
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "cache-control": "no-cache",
            }
        });
    }

    async sendForm(formData: {}, locale: string, server): Promise<AccessToken> {
        return await this.apiClient({
            method: "POST",
            url: server ? `${server}/v1/form/${formData.id}` : `${localConfig.authUrl}/v1/form/login`,
            withCredentials: false,
            headers: {
                "content-type": "application/json",
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`
            },
            data: server
                ? {GoogleAuthenticatorForm: formData}
                : {LoginForm: formData}
        });
    }

    async sendAuthForm(formData: {}, locale: string): Promise<AccessToken> {
        return await this.apiClient({
            method: "POST",
            url: `${localConfig.authUrl}/v1/form/${formData.id}`,
            headers: {
                "content-type": "application/json",
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`
            },
            data: {
                GoogleAuthenticatorForm: formData
            }
        });
    }
    //TODO: Move this method to dealing adapter
    async getDealingToken(token: AccessToken): Promise<DealingAccessToken> {
        return await this.apiClient({
            method: "POST",
            url: `${getDomain(localConfig.apiUrl)}/v1/user/dealing-auth`,
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "cache-control": "no-cache",
                authorization: `Bearer ${token}`
            }
        });
    }
}

export default LoginFormAdapter;
