import styled from 'styled-components';

export const CreatedWrapper = styled.div`
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 4rem;
  color: #E0D9CB;

  &:nth-child(3) {
    margin-bottom: 2.81rem;
  }
`;

export const Description = styled.div`
  font-size: 3rem;
  color:#999999;
  text-align: center;
  margin-bottom: 6rem;
  width: 70%;
`;
export const Image = styled.img`
  margin-bottom: 6.25rem;
`;
