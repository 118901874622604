import store from './infrastructure/view/services/store';
import {
    postUpdateServerSessionRequest,
    setActivityTimer,
    setConfirmationTimer,
    startConfirmTimer,
    clearAbsoluteSessionTimer,
    setAbsoluteSessionTimer,
} from './infrastructure/view/services/action/session';
import {logout} from './infrastructure/view/services/action/user';
import {
    activityTime,
    confirmTime,
    intervalUpdateSessionTime,
    absoluteSessionLogoutTime,
} from './settings';
import config from './localConfig';

export function absoluteSessionTimer() {
    let absoluteTimeExpiration = localStorage.getItem('absoluteTimeExpiration');
    let timerTime;
    if (absoluteTimeExpiration) {
        timerTime = absoluteTimeExpiration - new Date().getTime();
    } else {
        timerTime = 10000000000;
    }

    return setTimeout(() => {
        store.dispatch(clearAbsoluteSessionTimer());
        store.dispatch(setAbsoluteSessionTimer(true));
        absoluteSessionTimerLoguot();
    }, timerTime);
}

export function absoluteSessionTimerLoguot() {
    return setTimeout(() => {
        store.dispatch(logout());
        window.location.href = `${config.workspaceUrl}/logout`;
    }, absoluteSessionLogoutTime); //
}

export function timerActivity() {
    return setTimeout(() => {
        store.dispatch(setActivityTimer(true));
        store.dispatch(startConfirmTimer());
    }, activityTime);
}

export function timerConfirm() {
    const timerId = setTimeout(() => {
        store.dispatch(setConfirmationTimer(true));
        store.dispatch(logout());
        window.location.href = `${config.workspaceUrl}/logout`;
    }, confirmTime);

    return () => {
        clearTimeout(timerId);
        store.dispatch(setConfirmationTimer(false));
    };
}

export function intervalUpdateSession() {
    setInterval(() => {
        store.dispatch(postUpdateServerSessionRequest());
    }, intervalUpdateSessionTime);
}
