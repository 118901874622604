// @flow
import type Axios from 'axios';
import type {AccessToken} from '../../domain/entitiy/AccessToken';
import type {NotificationAccessToken} from '../../domain/entitiy/NotificationAccessToken';
import EventEmitter from 'events';

import type {NotificationAdapterInterface} from '../../application/adapter/Notification';

import store from 'infrastructure/view/services/store';
import config from '../../localConfig';
import {
    notificationSocketClose,
    notificationSocketError,
    notificationSocketSuccess
} from "../view/services/action/notification";

import localConfig from 'localConfig';
import {getDomain} from 'utils';


class NotificationAdapter implements NotificationAdapterInterface {
    apiClient: Axios;
    socket: WebSocket;
    eventEmitter: EventEmitter;

    constructor(apiClient: Axios, webSocketClient) {
        this.apiClient = apiClient;
        this.webSocketClient = webSocketClient;
        this.eventEmitter = new EventEmitter();
    }

    async getNotificationToken(accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/user/notify-auth`,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                authorization: `Bearer ${accessToken}`,
                'cache-control': 'no-cache',
            },
        });
    }

    async connect(token: NotificationAccessToken): Promise<any> {
        return new Promise(resolve => {
            this.socket = new this.webSocketClient(
                `${getDomain(localConfig.wsUrl)}/notify/${token}`
            );

            this.socket.onopen = () => {
                resolve();
                store.dispatch(notificationSocketSuccess());
            };

            this.socket.onclose = (event) => {
                if (event.code === 4000) {
                    this.eventEmitter.emit('notify-socket-onclose');
                }
                store.dispatch(notificationSocketClose());
            };

            this.socket.onmessage = event => {
                try {
                    if (event.data !== 'ping') {
                        const res = JSON.parse(event.data);

                        this.eventEmitter.emit('notification', res);
                    }
                } catch (error) {
                    store.dispatch(notificationSocketError(error));
                }
            };

            this.socket.onerror = (error) => {
                store.dispatch(notificationSocketError(error));
            };
        });
    }

    disconnect(): void {
        if (this.socket) {
            this.socket.close();
        }
    }

    async closeNotification(
        accessToken: AccessToken,
        id: string
    ): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/notification/remove`,
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'cache-control': 'no-cache',
            },
            data: {
                id,
            },
        });
    }

    async closeAllNotifications(accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/notification/remove-all`,
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'cache-control': 'no-cache',
            },
        });
    }

    async getNotificationsHistory(accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/notification/history`,
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
                'cache-control': 'no-cache',
            },
        });
    }

    on(eventName: string, handler: Function) {
        this.eventEmitter.on(eventName, handler);
    }
}

export default NotificationAdapter;
