import styled from 'styled-components';

export const NotificationWrapper = styled.div`
    font-size: 2.3333rem;
    border-bottom: 0.16rem solid ${props => props.theme.color7};
    cursor: pointer;
    padding: 2rem 0;
    margin: 0 3.33rem;
    position: relative;
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 4.16666rem;
        background: ${({theme}) => theme.color33};
        top: 0;
        bottom: 0;
        right: 100%;
        display: none;
    }
    &:after {
        left: 100%;
        right: auto;
    }
    &:hover {
        background: ${({theme}) => theme.color33};
        border-bottom: 0;
        margin-bottom: 1px;
        &:after,
        &:before {
            display: block;
        }
        div {
            &:nth-child(2) {
                color: ${({theme}) => theme.color1};
            }
        }
    }
`;

export const NotificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`;

export const NotificationTitle = styled.div`
    color: ${props => props.theme.color67};
    position: relative;
    margin-bottom: 0.8rem;
    width: 96%;
    word-break: break-word;
`;

export const NotificationText = styled.div`
    word-break: break-word;
    white-space: pre-line;
    color: ${props => props.theme.color14};
`;

export const Close = styled.i`
    color: ${props => props.theme.color65};
    width: 4%;
    display: flex;
    cursor: pointer;
    padding: 0 0 2rem 2rem;
    &:hover {
        color: ${props => props.theme.color1} !important;
    }
`;
