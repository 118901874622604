export default {
    color1: '#020D1D',
    color2: '#fff',
    color3: '#F5F5F5',
    color4: '#3F4151',
    color5: '#000000',
    color6: '#123E64',
    color7: '#2B9241',
    color8: '#2B9241',
    color9: '#FFFFFF',
    color10: '#00B456',
    color11: '#17BB53',
    color12: '#123B21',
    color13: '#FF4D4D',
    color14: '#D83131',
    color15: '#360D0D',
    color16: '#5A5C71',
    color17: '#323443',
};
