// @flow
import axios from '../../../adapter/helper/axios';

import {setForm, errorForm} from "../action/forms";

import ListingFormAdapter from 'infrastructure/adapter/ListingForm';

import {EventType} from 'infrastructure/view/scene/Listing/event';

import GetListingForm from 'application/use-case/listing/GetListingForm';
import SubmitListingForm from 'application/use-case/listing/SubmitListingForm';

const listingAdapter = new ListingFormAdapter(axios);

const getListingForm = new GetListingForm(listingAdapter);
const submitListingForm = new SubmitListingForm(listingAdapter);

function middleware(store) {
    return function(next) {
        return async function(event) {
            next(event);
            switch (event.type) {
                case EventType.getListingForm: {
                    const state = store.getState();
                    const {local} = state.translation;
                    const {accessToken} = state.user;

                    try {
                        const form = await getListingForm.invoke(
                            local,
                            accessToken
                        );

                        store.dispatch(setForm(form));

                        return;
                    } catch (error) {
                        store.dispatch(errorForm(error));
                        return;
                    }
                }

                case EventType.submitListingForm: {
                    const state = store.getState();
                    const {local} = state.translation;
                    const {accessToken} = state.user;
                    const {scheme} = store.getState().forms['ListingForm'];

                    const formData = {
                        ListingForm: Object.values(scheme).reduce(
                            (result, schemeData) => {
                                result[schemeData.name] = schemeData.value;
                                return result;
                            },
                            {}
                        ),
                    };

                    try {
                        const form = await submitListingForm.invoke(
                            local,
                            formData,
                            accessToken
                        );

                        store.dispatch(setForm(form));
                        return;
                    } catch (error) {
                        store.dispatch(errorForm(error));
                        return;
                    }
                }

                default:
                    return false;
            }
        };
    };
}

export default middleware;
