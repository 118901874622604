// @flow
import DealingAdapter from '../../infrastructure/adapter/Dealing';

class OpenOrder {
    dealingAdapter: DealingAdapter;

    constructor(dealingAdapter: DealingAdapter) {
        this.dealingAdapter = dealingAdapter;
    }

    invoke(
        asset: string,
        price: number,
        amount: number,
        exType: number,
        type: number
    ): void {
        try {
            this.dealingAdapter.openOrder(asset, price, amount, exType, type);
        } catch (error) {
            throw error;
        }
    }
}

export default OpenOrder;
