import moment from 'moment';

let oldTick;

export function isSameCandle(interval, prevCandle, tick) {
    const {timestamp, price, amount} = tick;
    if (!prevCandle) {
        return false;
    }

    const start = moment.duration(prevCandle.timestamp).minutes();
    const end = start + moment.duration(interval).minutes() || 60;
    const tickTimestamp = end === 60 ? (moment.duration(timestamp).minutes() || 60) : moment.duration(timestamp).minutes();
    if (tickTimestamp > end) oldTick = {timestamp, open: +price, hi: +price, lo: +price, close: +price, volume: +amount};
    return tickTimestamp < end;
}

export function createCandle(tick, interval) {
    const {price, amount, timestamp} = tick;

    const candle = {
        timestamp,
        open: +price,
        hi: +price,
        lo: +price,
        close: +price,
        volume: +amount,
    };

    oldTick = candle;
    return candle;
}

export function updateCandle(currentCandle, tick) {
    const {hi, lo, volume} = currentCandle;
    const {price, amount} = tick;

    const candle = {
        ...currentCandle,
        hi: Math.max(+hi, +price),
        lo: Math.min(+lo, +price),
        close: +price,
        volume: Number(volume) + Number(amount),
    };
    oldTick = candle;
    return candle;
}
let oldPrevTick;
export function formCandle(tick, interval, prevTick) {
    if (oldPrevTick && oldPrevTick.timestamp !== prevTick.timestamp) { //при смене интервала очищаем предыдущий старый тик
        oldTick = null;
    }
    oldPrevTick = prevTick;

    const shouldCreateNewCandle = !isSameCandle(interval, oldTick || prevTick , {price: +tick.price, amount: +tick.amount, timestamp: tick.openTime * 1000});
    let newTick;

    if (shouldCreateNewCandle) {
        const newCandle = createCandle({price: +tick.price, amount: +tick.amount, timestamp: tick.openTime * 1000}, interval);
        newTick = newCandle;

    } else {
        const updatedCandle = updateCandle(oldTick || prevTick, {price: +tick.price, amount: +tick.amount, timestamp: tick.openTime * 1000});
        newTick = updatedCandle;
    }
    return newTick;
}

export function clearOldPrevTick() {
    oldTick = null;
}
