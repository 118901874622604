// @flow
import {UserAdapterInterface} from '../../application/adapter/User';

import localConfig from 'localConfig';
import {getDomain} from 'utils';

import type {Axios} from 'axios/index';
import type {AccessToken} from '../../domain/entitiy/AccessToken';

class UserAdapter implements UserAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getUserData(accessToken: AccessToken): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/user/info`,

            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                authorization: `Bearer ${accessToken}`,
                'cache-control': 'no-cache',
            },
        });
    }
}

export default UserAdapter;
