import {takeEvery, put} from 'redux-saga/effects';
import {successAction} from '../actions/actionTypes';

import {GET_GUEST_API_URL} from './actions';
import {startGuestDealingWebsocket} from '../webSockets/actions';

export default function* () {
    yield takeEvery(successAction(GET_GUEST_API_URL), getApiUrl);
}

function* getApiUrl({
    response: {
        data: {host},
    },
}) {
    const userRegion = JSON.parse(localStorage.getItem('userRegion'));
    if (!(userRegion && userRegion.access_token)) {
        localStorage.setItem(
            'userRegion',
            JSON.stringify({server: host, ws: host})
        );
        yield put(startGuestDealingWebsocket());
    } else {
        window.location.href = '/dashboard';
    }
}
