export const ActionType = {
    SHOW_MODAL: 'SHOW_MODAL',
    HIDE_MODAL: 'HIDE_MODAL',
    HIDE_DELETE_MODAL: 'HIDE_DELETE_MODAL',
    SHOW_DELETE_MODAL: 'SHOW_DELETE_MODAL',
};

export function showModal() {
    return {
        type: ActionType.SHOW_MODAL,
    };
}
export function hideModal() {
    return {
        type: ActionType.HIDE_MODAL,
    };
}
export function hideDeleteModal() {
    return {
        type: ActionType.HIDE_DELETE_MODAL,
    };
}
export function showDeleteModal() {
    return {
        type: ActionType.SHOW_DELETE_MODAL,
    };
}
