import React, {useState} from 'react';
import {Tooltip, TooltipWrapper} from './styled';

export default ({children, label, offset = 2, direction = 'bottom', ...rest}) => {
    const [isShow, setShow] = useState(false);
    const showHandler = () => setShow(true);
    const hideHandler = () => setShow(false);
    return (
        <TooltipWrapper onMouseEnter={showHandler} onMouseLeave={hideHandler} onClick={hideHandler} {...rest}>
            {children}
            {isShow && (
                <Tooltip offset={offset} direction={direction}>
                    {label}
                </Tooltip>
            )}
        </TooltipWrapper>
    );
};
