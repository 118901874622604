export const ActionTypes = {
    getTicketsHistory: 'tickets-scene.history.get',
    setTicketsHistory: 'tickets-scene.history.set',
    errorTicketsHistory: 'tickets-scene.history.error',
    fetchTicket: 'tickets-scene.ticket.fetch',
    fetchTicketError: 'tickets-scene.ticket.fetch.error',
    setSelectedTicket: 'tickets-scene.ticket.set',
    errorSelectedTicket: 'tickets-scene.ticket.error',
    replyToTicket: 'tickets-scene.ticket.reply',
    getQuestionForm: 'tickets-scene.form.question.get',
    submitQuestionForm: 'tickets-scene.form.question.submit',
    submitQuestionFormResponse: 'tickets-scene.form.question.submit.response',
    getVerificationForm: 'tickets-scene.form.verification.get',
    submitVerificationForm: 'tickets-scene.form.verification.submit',
    submitVerificationFormResponse: 'tickets-scene.form.verification.submit.response',
    submitVerificationFormError: 'tickets-scene.form.verification.submit.error',
    setFilterTicketType: 'tickets-scene.ticket.filter.type.set',
    setFilterTicketPattern: 'tickets-scene.ticket.filter.pattern.set',
    setFilterTicketCategory: 'tickets-scene.ticket.filter.category.set',
    setFilterTicketSubCategory: 'tickets-scene.ticket.filter.subcategory.set',
    setFilterRangeDates: 'tickets-scene.ticket.filter.date.range.set',
    GET_TICKET_VERIFICATION_IMAGE_REQUEST: 'GET_TICKET_VERIFICATION_IMAGE_REQUEST',
    GET_TICKET_VERIFICATION_IMAGE_SUCCESS: 'GET_TICKET_VERIFICATION_IMAGE_SUCCESS',
    GET_TICKET_VERIFICATION_IMAGE_FAILURE: 'GET_TICKET_VERIFICATION_IMAGE_FAILURE',
    CLEAR_ARRAY_TICKETS_VERIFICATION_IMAGE: 'CLEAR_ARRAY_TICKETS_VERIFICATION_IMAGE',
};

export function getTicketsHistory() {
    return {
        type: ActionTypes.getTicketsHistory,
    };
}

export function setTicketsHistory(tickets) {
    return {
        type: ActionTypes.setTicketsHistory,
        tickets,
    };
}

export function fetchTicket(id) {
    return {
        type: ActionTypes.fetchTicket,
        id,
    };
}

export function fetchTicketError(payload) {
    return {
        type: ActionTypes.fetchTicketError,
        payload,
    };
}

export function setSelectedTicket(ticket) {
    return {
        type: ActionTypes.setSelectedTicket,
        ticket,
    };
}

export function replyToTicket(id, message) {
    return {
        type: ActionTypes.replyToTicket,
        id,
        message,
    };
}

export function getQuestionForm() {
    return {
        type: ActionTypes.getQuestionForm,
    };
}

export function submitQuestionForm() {
    return {
        type: ActionTypes.submitQuestionForm,
    };
}

export function getVerificationForm() {
    return {
        type: ActionTypes.getVerificationForm,
    };
}

export function submitVerificationForm() {
    return {
        type: ActionTypes.submitVerificationForm,
    };
}

export function setFilterTicketType(type) {
    return {
        type: ActionTypes.setFilterTicketType,
        payload: type,
    };
}

export function setFilterTicketPattern(pattern) {
    return {
        type: ActionTypes.setFilterTicketPattern,
        payload: pattern,
    };
}

export function setFilterTicketCategory(category) {
    return {
        type: ActionTypes.setFilterTicketCategory,
        payload: category,
    };
}

export function setFilterTicketSubCategory(subcategory) {
    return {
        type: ActionTypes.setFilterTicketSubCategory,
        payload: subcategory,
    };
}

export function setFilterTicketRangeDates(range) {
    return {
        type: ActionTypes.setFilterRangeDates,
        payload: range,
    };
}

export function submitVerificationFormResponse() {
    return {
        type: ActionTypes.submitVerificationFormResponse,
    };
}

export function submitVerificationFormError() {
    return {
        type: ActionTypes.submitVerificationFormError,
    };
}

export function submitQuestionFormResponse() {
    return {
        type: ActionTypes.submitQuestionFormResponse,
    };
}

export function errorTicketsHistory(payload) {
    return {
        type: ActionTypes.errorTicketsHistory,
        payload,
    };
}

export function errorSelectedTicket(payload) {
    return {
        type: ActionTypes.errorSelectedTicket,
        payload,
    };
}

export function getTicketVerificationImageRequest(payload) {
    return {
        type: ActionTypes.GET_TICKET_VERIFICATION_IMAGE_REQUEST,
        payload,
    };
}

export function getTicketVerificationImageSuccess(payload) {
    return {
        type: ActionTypes.GET_TICKET_VERIFICATION_IMAGE_SUCCESS,
        payload,
    };
}

export function getTicketVerificationImageFailure(payload) {
    return {
        type: ActionTypes.GET_TICKET_VERIFICATION_IMAGE_FAILURE,
        payload,
    };
}

export function clearArrayTicketsVerificationImage() {
    return {
        type: ActionTypes.CLEAR_ARRAY_TICKETS_VERIFICATION_IMAGE,
    };
}

