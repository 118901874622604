import React from 'react';
import {Wrapper, Icon, Label, Pair} from './styled';

export default function SymbolsInput({title, pair}) {
    const firstCurrency = pair && pair.split('/')[0].toLowerCase();
    const secondCurrency = pair && pair.split('/')[1].toLowerCase();
    return (
        <Wrapper>
            <Label>{title}</Label>
            {pair && (
                <Pair>
                    <Icon className={`icon-${firstCurrency}`} />
                    {pair}
                    <Icon className={`icon-${secondCurrency}`} />
                </Pair>
            )}
        </Wrapper>
    );
}
