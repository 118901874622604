export const CALL_API = '[CALL_API]';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';
export const SEND_REQUEST = 'SEND_REQUEST';

export const startAction = type => `START_${type}`;
export const successAction = type => `SUCCESS_${type}`;
export const failAction = type => `FAIL_${type}`;
export const callApi = type => `${type}-${CALL_API}`;

export const callApiActionFactory = (
  type,
  endpoint,
  method,
  body,
  params,
  other,
) => {
  const template = {
    type,
    params,
    body,
    endpoint,
    method,
    apiCall: true,
    ...other,
  };

  return template;
};
