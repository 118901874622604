import React, {useState} from 'react';
import classnames from 'classnames';

const FlagsImg = ({name}) => {
    const [imgError, setImgError] = useState(false);
    const onErrImg = () => {
        setImgError(true);
    };

    return (
        <>
            <img
                src={`${window.location.protocol}//${window.location.host}/img/svg/svg-flag/${name}.svg`}
                alt={`${name}`}
                className={classnames(`icon icon-${name}`, {
                    'icon-error': imgError,
                })}
                onError={onErrImg}
            />
        </>
    );
};

export default FlagsImg;
