// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';

import Panel from '../../../../component/Panel/Panel';
import Exchange from './component/Exchange';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';

function mapStateToProps(state) {
    return {
        title: parseTranslation('label-order-form'),
        titleExchange: parseTranslation('label-exchange'),
    };
}

type PropTypes = {
    title: string,
    titleExchange: string,
};

class OrderForm extends PureComponent<PropTypes> {
    state = {
        activeTab: 0,
    };

    constructor(props) {
        super(props);
        this.renderActiveTab = this.renderActiveTab.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
    }

    render() {
        const {title} = this.props;

        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-open-order" />
            </button>
        );

        return (
            <Panel icon={icon} title={title}>
                <Fragment>{this.renderActiveTab()}</Fragment>
            </Panel>
        );
    }

    onTabClick(activeTab) {
        return () => this.setState({activeTab});
    }

    renderActiveTab() {
        switch (this.state.activeTab) {
            case 0:
                return <Exchange />;

            // case 1:
            //     return <Margin />;

            default:
                return null;
        }
    }
}

export default connect(mapStateToProps)(OrderForm);
