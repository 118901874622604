import colors from 'infrastructure/view/styles/common-colors/common-colors';

export default {
    'paneProperties.crossHairProperties.color': colors.color39,

    'scalesProperties.lineColor': colors.color14,
    'scalesProperties.textColor': colors.color14,

    // Candles styles
    'mainSeriesProperties.candleStyle.upColor': colors.color8,
    'mainSeriesProperties.candleStyle.downColor': colors.color11,
    'mainSeriesProperties.candleStyle.borderUpColor': colors.color8,
    'mainSeriesProperties.candleStyle.borderDownColor': colors.color11,
    'mainSeriesProperties.candleStyle.wickUpColor': colors.color8,
    'mainSeriesProperties.candleStyle.wickDownColor': colors.color11,

    // Hollow Candles styles
    'mainSeriesProperties.hollowCandleStyle.upColor': colors.color8,
    'mainSeriesProperties.hollowCandleStyle.downColor': colors.color11,
    'mainSeriesProperties.hollowCandleStyle.borderUpColor': colors.color8,
    'mainSeriesProperties.hollowCandleStyle.borderDownColor': colors.color11,

    // Heikin Ashi styles
    'mainSeriesProperties.haStyle.upColor': colors.color8,
    'mainSeriesProperties.haStyle.downColor': colors.color11,
    'mainSeriesProperties.haStyle.borderUpColor': colors.color8,
    'mainSeriesProperties.haStyle.borderDownColor': colors.color11,

    // Bar styles
    'mainSeriesProperties.barStyle.upColor': colors.color8,
    'mainSeriesProperties.barStyle.downColor': colors.color11,

    // Line styles
    'mainSeriesProperties.lineStyle.color': colors.color1,

    // Area styles
    'mainSeriesProperties.areaStyle.color1': colors.color1,
    'mainSeriesProperties.areaStyle.color2': colors.color25,
    'mainSeriesProperties.areaStyle.linecolor': colors.color1,

    // Baseline styles
    'mainSeriesProperties.baselineStyle.baselineColor': '#999999',
    'mainSeriesProperties.baselineStyle.topFillColor1': 'rgba( 80, 213, 118, 0.05)',
    'mainSeriesProperties.baselineStyle.topFillColor2': 'rgba( 80, 213, 118, 0.05)',
    'mainSeriesProperties.baselineStyle.bottomFillColor1': 'rgba( 255, 77, 77, 0.05)',
    'mainSeriesProperties.baselineStyle.bottomFillColor2': 'rgba( 255, 77, 77, 0.05)',
    'mainSeriesProperties.baselineStyle.topLineColor': colors.color8,
    'mainSeriesProperties.baselineStyle.bottomLineColor': colors.color11,
}
