// @flow
export const EventTypes = {
    getChatData: 'widget.chat.event.getChatData',
    sendMessage: 'widget.chat.event.sendMessage',
    setMessageText: 'widget.chat.event.setMessageText',
    closeChat: 'widget.chat.event.closeChat',
    setConnection: 'widget.chat.event.setConnection'
};

export type GetChatData = {|
    type: 'widget.chat.event.getChatData',
|};

export type SendMessage = {|
    type: 'widget.chat.event.sendMessage',
|};

export type SetMessageText = {|
    type: 'widget.chat.event.setMessageText',
    messageText: string,
|};

export type CloseChat = {|
    type: 'widget.chat.event.closeChat',
|};

export type SetConnection = {|
    type: 'widget.chat.event.setConnection',
|};

export type Event = GetChatData | SendMessage | SetMessageText | CloseChat | SetConnection;

export function getChatData(): GetChatData {
    return {
        type: EventTypes.getChatData,
    };
}

export function sendMessage(): SendMessage {
    return {
        type: EventTypes.sendMessage,
    };
}

export function setMessageText(messageText: string): SetMessageText {
    return {
        type: EventTypes.setMessageText,
        messageText,
    };
}

export function closeChat(): CloseChat {
    return {
        type: EventTypes.closeChat,
    };
}

export function setConnection(): SetConnection {
    return {
        type: EventTypes.setConnection,
    };
}
