import React from 'react';
import styled from 'styled-components';
import Error from "./index";

function ErrorContainer(props) {
    return (
        <Styles>
            <Error {...props} />
        </Styles>
    );
}

const Styles = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

export default ErrorContainer;
