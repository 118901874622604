import React, {Component, Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import moment from 'moment/moment';

import FormattedPrice from '../../../component/FormattedPrice/FormattedPrice';
import {getTransactionHistory} from '../service/actions';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';

import parseTranslation from '../../../../adapter/helper/parseTranslation';
import Error from '../../../component/Error';
import ErrorContainer from '../../../component/Error/ErrorContainer';

function mapStateToProps(state) {
    const {translation} = state.translation;
    const transactionError = state.transactionHistory.error;

    return {
        translations: translation,
        history: state.transactionHistory.data,
        titleTableDateTransaction: parseTranslation(
            'label-transaction-history-date'
        ),
        titleTableTypeTransaction: parseTranslation(
            'label-transaction-history-type'
        ),
        titleTableAmountTransaction: parseTranslation(
            'label-transaction-history-amount'
        ),
        titleTableCurrencyDepositFunds: parseTranslation(
            'currency-deposit-funds'
        ),
        titleTableService: parseTranslation('service-history-title'),
        titleNa: parseTranslation('na'),
        DEPOSIT_REAL: parseTranslation('label-deposit-real'),
        WITHDRAW_REAL: parseTranslation('label-withdraw-real'),
        transactionError,
    };
}

const mapDispatchToProps = {
    getTransactionHistory,
};

class TransactionHistory extends PureComponent {
    render() {
        const {
            translations,
            tr: {
                deposit, withdraw
            },
            sum,
            type,
            currency,
            service,
        } = this.props;

        return (
            <Fragment>
                <tr className="transaction-history">
                    <td>
                        <div className="transaction-date">
                            <div>{moment.unix(this.props.create_time).format('HH:mm:ss')}</div>
                            <div>{moment
                                .unix(this.props.confirm_time)
                            .format('DD.MM.YYYY')}{' '}</div>
                        </div>
                    </td>
                    <td
                        style={{
                            color:
                                type === 'WITHDRAW_REAL'
                                    ? '#FF4D4D'
                                    : '#00B456',
                        }}
                    >
                        { type === 'WITHDRAW_REAL' ? withdraw : deposit}
                    </td>
                    <td>{currency}</td>
                    <td>{service || '-'}</td>
                    <td
                        style={{
                            color:
                                type === 'WITHDRAW_REAL'
                                    ? '#FF4D4D'
                                    : '#00B456',
                        }}
                    >
                        <div className="transaction-amount">
                            {sum}
                        </div>

                    </td>
                </tr>
            </Fragment>
        );
    }
}

class TransactionFunds extends Component {
    constructor(props) {
        super(props);

        props.getTransactionHistory();
    }

    renderTableHead() {
        return (
            <table className="table-head">
                <thead className="table-header table-header-transaction">
                    <tr
                        className="head-transaction"
                        style={{
                            backgroundColor: 'transparent',
                            fontWeight: 'normal',
                        }}
                    >
                        <th>{this.props.titleTableDateTransaction}</th>
                        <th style={{whiteSpace: 'nowrap'}}>
                            {this.props.titleTableTypeTransaction}
                        </th>
                        <th>{this.props.titleTableCurrencyDepositFunds}</th>
                        <th>{this.props.titleTableService}</th>
                        <th>{this.props.titleTableAmountTransaction}</th>
                    </tr>
                </thead>
            </table>
        );
    }

    render() {
        const {history, translations, transactionError} = this.props;

        const entries = history.map((transaction, i) => (
            <TransactionHistory
                key={i}
                {...transaction}
                translations={translations}
                tr={{deposit: this.props.DEPOSIT_REAL, withdraw: this.props.WITHDRAW_REAL}}
            />
        ));

        return (
            <Fragment>
                {this.renderTableHead()}
                <div
                    style={{
                        position: 'absolute',
                        top: '13rem',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                    }}
                >
                    <Scrollbars>
                        {transactionError && <ErrorContainer />}
                        <table className="container-table-history transaction-table-history">
                            <tbody>{entries}</tbody>
                        </table>
                    </Scrollbars>
                </div>
            </Fragment>
        );
    }
}

const ConnectedTransactionFunds = connect(
    mapStateToProps,
    mapDispatchToProps
)(TransactionFunds);
export default ConnectedTransactionFunds;
