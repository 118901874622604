import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components';

import {resetActivityTimeEvents} from 'settings';
import {getAccessToken, getUserRegion} from 'utils/auth';
import ActivitySessionModal from './component/Session/Session';
import SessionAbsModal from './component/Session/SessionAbsoluteTimerModal';
import {clearActivityTimer} from './services/action/session';

import store from './services/store';

import Routes from './Routes';
import commonColors from 'infrastructure/view/styles/common-colors/common-colors';

import {createGlobalStyle} from 'styled-components';
import {initAuth, logout, setUserRegion} from './services/action/user';

const GlobalStyle = createGlobalStyle`
body {
font-family: "Lato", Roboto, Arial, sans-serif;
}
`;

const accessToken = getAccessToken();
const userRegion = getUserRegion();


if (accessToken) {
    store.dispatch(initAuth(accessToken));
    store.dispatch(setUserRegion(userRegion));
}

class App extends Component {
    componentDidMount(): void {
        resetActivityTimeEvents.forEach(e => {
            document.addEventListener(e, this.resetActivityTimer);
        });
    }

    resetActivityTimer() {
        const {activityTimer} = store.getState().session;
        const {accessToken} = store.getState().user;

        if (!activityTimer && accessToken) {
            store.dispatch(clearActivityTimer());
        }
    }

    render() {
        return (
            <ThemeProvider theme={commonColors}>
                <Provider store={store}>
                    <ActivitySessionModal />
                    <SessionAbsModal />
                    <GlobalStyle />
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>
        );
    }
}

export default App;
