import React, {PureComponent} from 'react';
import styled from 'styled-components';
import colors from 'infrastructure/view/styles/common-colors/common-colors';
import parseTranslation from '../../../adapter/helper/parseTranslation';

class TableTooltipMyTrading extends PureComponent {
    render() {
        const {price, symbol, type, amount} = this.props;

        return (
            <ContentTooltipMyTrading>
                <div>
                    <span>{parseTranslation('label-symbol')}</span> <span>{symbol}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-type')}</span> <span>{type}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-set-price')}</span> <span>{price}</span>
                </div>
                <div>
                    <span>{parseTranslation('label-amount')}</span> <span>{amount}</span>
                </div>
            </ContentTooltipMyTrading>
        );
    }
}

export default TableTooltipMyTrading;

const ContentTooltipMyTrading = styled.div`
    display: table;
    justify-content: center;
    flex-direction: column;

    position: absolute;
    right: 8rem;
    top: -20rem;

    background: ${colors.color20};    
    border: 1px solid ${colors.color1};
    box-shadow: 0 10 20 ${colors.color66};
    font-size: 2.3rem;
    color: ${colors.color14};
    padding: 10px 20px;
    &:hover {
        a {
            color: $color20;
        }        
    }
    div {
        display: table-row;
    }

    span:not(.buy):not(.currency-pair-symbols):not(.currency-name) {        
        display: table-cell;
        white-space: nowrap;
        &:nth-child(2) {
            padding-left: 2rem;
        }
    }

    span:nth-child(2) {
        display: flex;
        width: 15rem;

        .order-type {
            .buy {
                color: #00B456;
             
                .Buy {
                    content: '\e92d';
                    font-family: 'juicy_orange';
                    font-size: 1.3rem;
                    font-weight: 800;
                    position: relative;
                    top: 0;
                    left: -0.5rem;
                    font-style: inherit;
                }
            }

            .sell {
                 color: #FF4D4D;
                .Sell {
                    content: '\e91a';
                    font-family: 'juicy_orange';
                    font-size: 1.3rem;
                    font-weight: 800;
                    position: relative;
                    top: 0;
                    left: -0.5rem;
                    font-style: inherit;
                }
            }
        }

        .currency-pair {
            left: 2.6rem;
        }
    }

    &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        right: 7%;

        transform: translateY(51%) rotate(-135deg);
        display: block;
        width: 2rem;
        height: 2rem;
        border: 0.1rem solid ${colors.color1};
        border-bottom: none;
        border-right: none;
        background-color: ${colors.color20};
    }
`;
