export const ActionTypes = {
    open: 'dashboard-scene.chart-container.symbols-modal.open',
    close: 'dashboard-scene.chart-container.symbols-modal.close',
    setCryptoCurrencyFilter: 'dashboard-scene.chart-container.symbols-modal.set-crypto-currency-filter',
    setFindFilter: 'dashboard-scene.chart-container.symbols-modal.set-find-filter'
};

export function open(chartId) {
    return {
        type: ActionTypes.open,
        chartId
    };
}

export function close() {
    return {
        type: ActionTypes.close
    };
}

export function setCryptoCurrencyFilter(filter) {
    return {
        type: ActionTypes.setCryptoCurrencyFilter,
        filter
    };
}

export function setFindFilter(filter) {
    return {
        type: ActionTypes.setFindFilter,
        filter
    };
}