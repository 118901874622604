// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {SettingsInterface as SettingsAdapter} from '../../../application/adapter/Settings';

class SendSettings {
    adapter: SettingsAdapter;

    constructor(adapter: SettingsAdapter) {
        this.adapter = adapter;
    }

    async invoke(token: AccessToken, data: object) {
        try {
            if (!token) {
                throw Error('No access token')
            }
            return await this.adapter.sendSettings(token, data);
        } catch (e) {
            console.error("Can't get the form", e);
        }
    }
}

export default SendSettings;
