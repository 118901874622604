// @flow
import React, {PureComponent, Fragment} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import Modal from 'react-modal';

import FormField from '../../Registration/component/FormField';
import {getQuestionForm, submitQuestionForm} from '../service/actions';
import {updateForm} from '../../../services/action/forms';

import parseTranslation from '../../../../adapter/helper/parseTranslation';

import Error from '../../../component/Error';
import Button from 'infrastructure/view/component/Button';

import icon from '../icon_limit_ticket.svg';

function mapStateToProps(state) {
    const {local} = state.translation;
    const questionForm = state.forms['QuestionForm'] || {};
    const {isPendingQuestionForm} = state.tickets;
    const formError = state.forms.error;

    return {
        questionForm,
        local,
        isPendingQuestionForm,
        formError,
        titleCreate: parseTranslation('create-ticket'),
        titleTicketAfter: parseTranslation('create-ticket-after'),

        buttonCancel: parseTranslation('create-ticket-button-cancel'),
        buttonCreate: parseTranslation('create-ticket-button-create'),
        ticketRequirements: parseTranslation(
            'create-ticket-question-requirements'
        ),
        newQuestionLimit: parseTranslation('QUESTION_LIMIT_EXCEEDED'),
    };
}

const mapDispatchToProps = {
    getQuestionForm,
    updateForm,
    submitQuestionForm,
};

type PropTypes = {
    questionForm: {},
    getQuestionForm: () => mixed,
    submitQuestionForm: () => mixed,
    updateForm: () => mixed,
};

class QuestionForm extends PureComponent<PropTypes> {
    state = {
        modal: {
            title: '',
            isOpenModal: false,
            img: '',
        },
    };

    constructor(props) {
        super(props);
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.previewFile = this.previewFile.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    componentDidMount(): void {
        this.props.getQuestionForm();
    }

    componentDidUpdate(prevProps) {
        const {questionForm, history} = this.props;

        if (
            questionForm.result !== prevProps.questionForm.result &&
            questionForm.result === 'Success'
        ) {
            history.push('/tickets');
        }

        const {locale} = this.props;

        if (prevProps.locale !== locale) {
            this.props.getQuestionForm();
        }
    }

    handleTicketLimitCancel = () => {
        const {history} = this.props;

        history.push('/tickets');
    };

    submitFormHandler(e) {
        e.preventDefault();
        this.props.submitQuestionForm();
    }

    previewFile(file): void {
        this.setState({
            modal: {
                title: file.name,
                img: file.preview.url,
                isOpen: true,
            },
        });
    }

    onCloseModal(): void {
        this.setState({
            modal: {
                isOpen: false,
            },
        });
    }

    render() {
        const {isPendingQuestionForm, formError} = this.props;
        const {scheme, errors, name} = this.props.questionForm;
        const isQuestionLimitExceeded =
            errors && errors.user_id &&
            errors.user_id[0] === 'QUESTION_LIMIT_EXCEEDED';

        if (isQuestionLimitExceeded) {
            return (
                <div className="ticket-limit">
                    <div className="ticket-limit__img">
                        <img src={icon} alt="" />
                    </div>
                    <div className="ticket-limit__msg">
                        {this.props.newQuestionLimit}
                    </div>
                    <div className="ticket-limit__btn-container">
                        <Button
                            color="secondary"
                            className="ticket-limit__btn"
                            onClick={this.handleTicketLimitCancel}
                        >
                            {this.props.buttonCancel}
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <Fragment>
                {formError && <Error />}
                {!!Object.keys(this.props.questionForm).length && (
                    <div className="wrapper-form-create-ticket">
                        <form
                            className="form-question form-billing"
                            onSubmit={this.submitFormHandler}
                        >
                            <p className={'wrapper-form-create-ticket__title'}>
                                {this.props.titleCreate}
                            </p>
                            {scheme &&
                                Object.values(scheme).map(field => (
                                    <FormField
                                        key={field.name}
                                        name={field.name}
                                        type={field.type}
                                        label={field.label}
                                        options={field.options}
                                        onChange={value => {
                                            this.props.updateForm(
                                                'QuestionForm',
                                                field.name,
                                                value
                                            );
                                        }}
                                        error={
                                            !Array.isArray(errors) &&
                                            errors[field.name] &&
                                            errors[field.name][0]
                                        }
                                        allErrors={errors}
                                        typeFileParams={{
                                            className: 'files',
                                            previewFile: this.previewFile,
                                        }}
                                        formName={name}
                                    />
                                ))}

                            <p className={'wrapper-form-create-ticket__title'}>
                                {this.props.ticketRequirements}
                                <br />
                                <br />
                                {this.props.titleTicketAfter}
                                {/*After you submit this ticket, you can track its status and comments by*/}
                                {/*support agents in general list of tickets. You can also add your additional*/}
                                {/*comments and files to this ticket later on.*/}
                            </p>
                            <div className="wrapper-form-create-ticket__wrap-btn">
                                <Link to={'/tickets'}>
                                    {this.props.buttonCancel}
                                </Link>
                                <button
                                    type="submit"
                                    disabled={isPendingQuestionForm}
                                >
                                    {this.props.buttonCreate}
                                </button>
                            </div>
                        </form>

                        <Modal
                            isOpen={this.state.modal.isOpen}
                            contentLabel="Ticket Modal"
                            className="modal faq preview"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(20, 22, 27, 0.65)',
                                    display: 'flex',
                                    zIndex: 100,
                                },
                            }}
                        >
                            <div className="modal-header">
                                <span>{this.state.modal.title}</span>
                                <button
                                    type={'button'}
                                    onClick={this.onCloseModal}
                                >
                                    <i className="icon-close" />
                                </button>
                            </div>
                            <div className="modal-wrap">
                                <img src={this.state.modal.img} alt="" />
                            </div>
                        </Modal>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withRouter
)(QuestionForm);
