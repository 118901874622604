export const ActionTypes = {
    setForm: 'form.set',
    fetchForm: 'form.fetch',
    updateForm: 'form.update',
    dropFormResult: 'form.result.drop',
    dropFormErrors: 'form.errors.drop',
    errorForm: 'form.error',
    errorPasswordForm: 'form-password.error',
    cleanQuestionForm: 'CLEAN_QUESTION_FORM',
    ORDER_FORM_AMOUNT_IS_LIMIT: 'ORDER_FORM_AMOUNT_IS_LIMIT',
    REMOVE_G_A_F: ' REMOVE_G_A_F',
    setMathCaptcha: 'SET_MATH_CAPTCHA',
};

export const removeGoogleAuthForm = () => ({
    type: ActionTypes.REMOVE_G_A_F,
});

export const amountIsLimit = (isLimit) => ({
   type: ActionTypes.ORDER_FORM_AMOUNT_IS_LIMIT,
   payload: isLimit,
});

export function fetchForm(params) {
    return {
        type: ActionTypes.fetchForm,
        params,
    };
}

export function setForm(form) {
    return {
        type: ActionTypes.setForm,
        form,
    };
}
export function setMathCaptcha(data) {
    return {
        type: ActionTypes.setMathCaptcha,
        data,
    };
}

export function updateForm(form, field, value) {
    return {
        type: ActionTypes.updateForm,
        form,
        field,
        value,
    };
}

export function dropFormResult(form) {
    return {
        type: ActionTypes.dropFormResult,
        form,
    };
}

export function dropFormErrors(form, keys) {
    return {
        type: ActionTypes.dropFormErrors,
        form,
        keys,
    };
}

export function errorForm(payload) {
    return {
        type: ActionTypes.errorForm,
        payload,
    };
}

export function errorPasswordForm(payload) {
    return {
        type: ActionTypes.errorPasswordForm,
        payload,
    };
}

export function cleanQuestionForm(formName) {
    return {
        type: ActionTypes.cleanQuestionForm,
        formName,
    };
}
