import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import store from '../../../../services/store';
import {fetchChartDataHistory, setErrorChart} from './service/actions';
import {sendSettingsData} from '../../../../services/action/settings';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';
import ChartDX from './ChartDX';
import Preloader from '../../../../component/Preloader';
import ErrorContainer from '../../../../component/Error/ErrorContainer';
import {chartHistoryRequest} from '../../../../services/action/DXChart';

function mapStateToProps(state) {
    const {isPending, error, data} = state.DXChart;
    const {name} = state.settings.selectedSymbol;

    const {chartType, chartInterval, offsets} = state.settings.dxChart;
    const {dealingToken} = state.user;
    let {newTick} = state.chart.orderForm;

    newTick = Object.keys(newTick).reduce((result, key) => {
        if (key === 'date') {
            result['timestamp'] = moment(newTick[key]).unix() * 1000;
            return result;
        } else if (key === 'hi') {
            result['hi'] = newTick[key];
            return result;
        } else if (key === 'lo') {
            result['lo'] = newTick[key];
            return result;
        } else {
            result[key] = newTick[key];
            return result;
        }
    }, {});

    return {
        newTick,
        data,
        isPending,
        error,
        offsets,
        dealingToken,
        symbol: name,
        interval: chartInterval,
        type: chartType,
        labelNoData: parseTranslation('chart-no-data-text'),
        isErrorChart: state.chart.chartView.isErrorChart,
        isNoDataErrorChart: state.DXChart.isNoDataErrorChart,
    };
}

const mapDispatchToProps = {
    fetchChartDataHistory,
    sendSettingsData,
    setErrorChart,
};

class Chart extends Component {
    constructor(props) {
        super(props);

        this.chartRef = React.createRef();
    }

    renderError(noData) {
        return noData ? (
            <ErrorContainer errorMessage={this.props.labelNoData} />
        ) : (
            <ErrorContainer />
        );
    }

    componentDidMount(): void {
        const {interval, symbol, dealingToken} = this.props;
        symbol && store.dispatch(chartHistoryRequest({
            symbol,
            resolution: interval,
            limit: 1000,
        }))
    }

    componentDidUpdate(prevProps) {
        const {interval, symbol, dealingToken} = this.props;
        if ((symbol !== prevProps.symbol && symbol) || (interval !== prevProps.interval) || (prevProps.dealingToken && dealingToken !== prevProps.dealingToken)) {
            const promise = new Promise(resolve => {
                const idInterval = setInterval(() => {
                    if (this.chartRef.current) {
                        resolve(this.chartRef.current, idInterval);
                    }
                }, 200);
            });

            promise.then((node, idInterval) => {
                clearInterval(idInterval);
                node.setAutoScale();
            });

            store.dispatch(chartHistoryRequest({
                symbol,
                resolution: interval,
                limit: 1000,
            }))
        }
    }

    renderSuccess() {
        const {
            id,
            studies,
            drawings,
            cancelDrawing,
            deleteDw,
            deleteI,
            deleteAll,
            setSelectedDrawing,
            data,
            newTick,
            type,
            interval,
            offsets,
            symbol,
            setErrorChart,
        } = this.props;
        return (
            <ChartDX
                ref={this.chartRef}
                setErrorChart={setErrorChart}
                selectedSymbol={symbol}
                offsets={offsets}
                chartInterval={interval}
                type={type}
                newTick={newTick}
                data={data}
                id={id}
                studiesList={studies}
                drawings={drawings}
                cancelDrawing={cancelDrawing}
                deleteDw={deleteDw}
                deleteI={deleteI}
                deleteAll={deleteAll}
                setSelectedDrawing={setSelectedDrawing}
            />
        );
    }

    conditionRender() {
        const {isErrorChart, isNoDataErrorChart} = this.props;

        if (isErrorChart) {
            return this.renderError();
        } else if (isNoDataErrorChart) {
            return this.renderError(true);
        } else {
            return this.renderSuccess();
        }
    }

    render() {
        const {isPending, error, data, labelNoData} = this.props;

        if (isPending) {
            return <Preloader />;
        }

        if (!data.length) {
            return <h1 className="chart-no-data">{labelNoData}</h1>
        }

        if (error) {
            return (
                <h1 className="chart-no-data">Oops, something went wrong :(</h1>
            );
        }
        return (
            <div className="chart-container__wrap">
                {this.conditionRender()}
            </div>
        );
    }
}

Chart.defaultProps = {
    xAxisZoomEnabled: true,
    YAxisZoomEnabled: true,
    rendererType: process.env.NODE_ENV === 'test' ? 'svg' : 'hybrid',
    panEvent: true,
    zoomEvent: true,
    volumes: true,
    volumesHeight: 80,
    ohlcTooltip: true,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Chart);
