import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

const ChartMenuBtn = ({icon, children, ...otherProps}) => {
    const iconClasses = classnames('chart-menu-btn__icon', {[icon]: icon});

    return (
        <Button className="chart-menu-btn" {...otherProps}>
            {!!children ? children : <Icon className={iconClasses} />}
        </Button>
    );
};

const Icon = styled.i`
    color: #999999;
    font-size: 3rem;
    transition: color 0.3s;
`;

const Button = styled.button`
    height: 5.833rem;
    width: 5.833rem;
    background: ${p => p.theme.color7};
    transition: 0.3s;
    font-size: 2rem;

    &.active {
        color: ${p => p.theme.color7};
        background: ${p => p.theme.color1};

        &:before {
            display: none;
        }

        &:hover {
            color: ${p => p.theme.color1};
            background: ${p => p.theme.color7};
            & ${Icon} {
                color: ${p => p.theme.color1};
            }
        }

        & ${Icon} {
            color: ${p => p.theme.color7};
        }
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 0.7rem;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.5rem 0.5rem 0 0.5rem;
        border-color: #999999 transparent transparent transparent;
        transition: border 0.3s;
    }

    &:hover {
        color: ${p => p.theme.color1};

        &:before {
            border-color: ${p => p.theme.color1} transparent transparent
                transparent;
        }
    }

    &:hover ${Icon} {
        color: ${p => p.theme.color1};
    }
`;

export default ChartMenuBtn;
