export const ActionTypes = {
    setNews: 'dashboard-scene.news-component.news.set',
    errorNews: 'dashboard-scene.news-component.news.error',
};

export function setNews(news) {
    return {
        type: ActionTypes.setNews,
        news,
    };
}

export function errorNews(payload) {
    return {
        type: ActionTypes.errorNews,
        payload,
    };
}
