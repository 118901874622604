// @flow
import _ from 'lodash';
import React, {Component} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import chartConfig from './chartConfig';
import {setErrorChart} from './service/actions';
import {getLanguage, getTranslation} from '../../../../services/action/translation';
import {getSettingsData} from '../../../../services/action/settings';
import {customChartDrawingsKey} from 'settings';
import parseTranslation from '../../../../../adapter/helper/parseTranslation';

type PropTypes = {
    data: {},
    newTick: {},
    typeChart: string,
    chartInterval: string,
    lineColor: string,
    lineWidth: number,
};

class ChartDX extends Component<PropTypes> {
    chartInstance: ?{} = null;
    htmlContainer: ?HTMLDivElement = React.createRef();
    chartInstrument: {} = {
        type: 'FOREX',
        precision: 2,
        priceIncrements: [0.000001],
        pricePrecisions: [8],
    };
    chartConfig: {} = chartConfig(
        parseTranslation('label-chart1-pointer-open'),
        parseTranslation('label-chart1-pointer-high'),
        parseTranslation('label-close'),
        parseTranslation('label-chart1-pointer-low'),
        parseTranslation('label-volume'),
    );
    selectedDrawing = null;
    state = {
        selectedDrawing: null,
    };

    componentDidMount(): void {
        this.initChart();

        this.chartInstance.addDrawingSelectHandler(this.onDrawingSelect);
        this.chartInstance.addDrawingDoneHandler(this.onDrawingSelect);

        document.addEventListener('keyup', this.cancelDrawingHandle);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.cancelDrawingHandle);
    }

    componentDidUpdate(prevProps: PropTypes, prevState): void {
        const {selectedDrawing} = this.state;
        const {studiesList, offsets, setSelectedDrawing, translation: {translation}} = this.props;
        if (prevProps.translation !== this.props.translation) {
            this.chartConfig = chartConfig(
                parseTranslation('label-chart1-pointer-open'),
                parseTranslation('label-chart1-pointer-high'),
                parseTranslation('label-close'),
                parseTranslation('label-chart1-pointer-low'),
                parseTranslation('label-volume'),
            );
            this.htmlContainer.current.innerHTML = '';
            this.initChart();
        }

        const {type} = this.props;
        if (prevState.selectedDrawing !== selectedDrawing) {
            setSelectedDrawing({
                selectedDrawing,
                chartInstance: this.chartInstance,
            });
        }
        if (prevProps.type !== type) {
            this.chartInstance && this.chartInstance.setChartType(type);
        }
        if (studiesList.length !== prevProps.studiesList.length) {
            this.setCandles();
        }
        if (!_.isEqual(offsets, prevProps.offsets)) {
            this.chartInstance.setOffsets({
                ...offsets,
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {selectedDrawing} = this.state;

        const {
            chartInterval,
            type,
            studiesList,
            drawings,
            offsets
        } = this.props;
        if (nextProps.translation !== this.props.translation) return true;
        this.setTick();

        if (
            nextProps.drawings.type !== drawings.type ||
            nextProps.drawings.repeat ||
            nextProps.drawings.lineColor !== drawings.lineColor ||
            nextProps.drawings.lineWidth !== drawings.lineWidth ||
            nextProps.drawings.text !== drawings.text ||
            nextProps.drawings.fillBackground !== drawings.fillBackground
        ) {
            this.startDrawing(nextProps.drawings);
        }

        if (nextProps.drawings.isVisible !== drawings.isVisible) {
            this.chartInstance &&
                this.chartInstance.setShowDrawings(
                    nextProps.drawings.isVisible
                );
        }

        if (nextProps.cancelDrawing) {
            this.chartInstance && this.chartInstance.cancelCurrentDrawing();
        }

        if (nextProps.deleteDw) {
            if (this.chartInstance) {
                this.chartInstance.removeAllDrawings();
                this.chartInstance.cancelCurrentDrawing();
            }

            this.saveChartData();
        }

        if (nextProps.deleteAll) {
            if (this.chartInstance) {
                this.chartInstance.removeAllDrawings();
                this.chartInstance.cancelCurrentDrawing();
            }

            this.saveChartData();
        }

        return !(
            nextState.selectedDrawing === selectedDrawing &&
            nextProps.chartInterval === chartInterval &&
            nextProps.type === type &&
            studiesList.length === nextProps.studiesList.length &&
            _.isEqual(offsets, nextProps.offsets)
        );
    }

    setAutoScale = () => {
        this.chartInstance.setAutoScale();
    };

    saveChartData = () => {
        const {selectedSymbol} = this.props;

        const chartData = this.chartInstance.getData();

        delete chartData.instrument;

        const chartDataJson = sessionStorage.getItem(customChartDrawingsKey);

        let allCurrencyChartData = null;

        if (chartDataJson) {
            allCurrencyChartData = JSON.parse(chartDataJson);
        }

        if (chartData && chartData.drawings && chartData.drawings.undefined) {
            chartData.drawings.undefined.map(
                drawing => (drawing.id = _.uniqueId('drawing_'))
            );

            if (allCurrencyChartData) {
                sessionStorage.setItem(
                    customChartDrawingsKey,
                    JSON.stringify({
                        ...allCurrencyChartData,
                        [selectedSymbol]: chartData,
                    })
                );
            } else {
                sessionStorage.setItem(
                    customChartDrawingsKey,
                    JSON.stringify({
                        [selectedSymbol]: chartData,
                    })
                );
            }
        }
    };

    getChartData = () => {
        const {selectedSymbol} = this.props;

        const chartDataJson = sessionStorage.getItem(customChartDrawingsKey);

        let chartData = null;

        if (chartDataJson) {
            chartData = JSON.parse(chartDataJson);
        }

        if (chartData) {
            return chartData[selectedSymbol];
        }

        return null;
    };

    onDrawingSelect = model => {
        this.setState({selectedDrawing: model});

        this.saveChartData();
    };

    cancelDrawingHandle = ({key}) => {
        const {selectedDrawing} = this.state;

        if (key === 'Escape') {
            this.setState({selectedDrawing: null});
        }

        if (key === 'Delete') {
            this.deleteDrawing(selectedDrawing);
        }
    };

    deleteDrawing = model => {
        this.chartInstance.removeDrawing(model);
        this.setState({selectedDrawing: null});

        this.saveChartData();
    };

    startDrawing(param) {
        const {type, lineColor, lineWidth, text, fillBackground} = param;

        if (type) {
            this.chartInstance.addDrawing({
                type,
                data: {text},
                properties: {
                    line: {
                        lineColor,
                        lineWidth,
                        lineDash: [],
                    },
                    style: {
                        fillBackground,
                    },
                },
            });
        }
    }

    initChart(): void {
        this.setChart();
        this.setCandles();
    }

    setChart(): void {
        const {offsets} = this.props;
        this.chartInstance = new window.chart.ChartBootstrap(
            this.htmlContainer.current,
            {
                ...this.chartConfig,
                components: {
                    ...this.chartConfig.components,
                    chart: {
                        ...this.chartConfig.components.chart,
                        type: this.props.type,
                    },
                },
                // chartOnCanvasMode: true, // Render chart in canvas mode
            },
            this.getChartData(),
        );
        this.chartInstance.setOffsets({
            ...offsets,
        });
        this.chartInstance.bus.fire('instrumentSelected', this.chartInstrument);
    }

    setTick(): void {
        const {newTick} = this.props;

        Object.keys(newTick).length &&
            this.chartInstance.bus.fire('candle', [newTick]);
    }

    toStudies(candles) {
        const res = [];
        candles.forEach(candle => {
            if (candle.studyValues) {
                const values = candle.studyValues.values;
                let value;
                for (let j = 0; j < values.length; j++) {
                    value = values[j];
                    res[j] = res[j] || [];
                    res[j].push({
                        timestamp: candle.timestamp,
                        val: value,
                    });
                }
            }
        });
        return res;
    }

    setCandles(): void {
        const {data, studiesList, setErrorChart} = this.props;
        const candles = this.toCandles(
            data.map((item) => {
                return {
                    ...item,
                    timestamp: moment(item.date).unix(),
                    studyValues: {
                        values: [],
                    },
                };
            })
        );

        const candlesLength = candles.length;

        if (studiesList.length) {
            let timeCndls = candles.map(candle => {
                return {
                    ...candle,
                    isVisible: true,
                    time: candle.timestamp,
                };
            });

            const dxStudies = window.dxstudies;
            const studies = new dxStudies.DxStudies(candlesLength, timeCndls);

            studies.addCandleItems(timeCndls);

            const addedStudies = [];

            studiesList.forEach(el => {
                const item = el;
                let params = [];
                item.uiid = item.id;

                for (let j = 0; j < item.parameters.length; j++) {
                    const parameter = item.parameters[j];
                    params.push({key: parameter.id, value: parameter.value});
                }

                const study = studies.createStudy(item.id, params);

                const values = study.calculateAll();

                candles.forEach((candle, index) => {
                    if (candle.studyValues) {
                        candle.studyValues.values.push(...values[index]);
                    } else {
                        candle.studyValues = {
                            values: [...values[index]],
                        };
                    }
                });

                try {
                    this.chartInstance.setCandles(candles);
                } catch (error) {
                    setErrorChart(true);
                }
                this.chartInstance.setXScale(candlesLength - 100, candlesLength);

                addedStudies.push(item);
            });

            this.chartInstance.setStudies(addedStudies);
            this.chartInstance.bus.fire('upd/studies');
            this.chartInstance.bus.fire(
                'studyUpdated',
                this.toStudies(candles)
            );
        } else {
            try {
                this.chartInstance.setCandles(candles);
            } catch (error) {
                setErrorChart(true);
            }
            this.chartInstance.setXScale(candlesLength - 100, candlesLength);
        }
    }

    toCandles(chartData) {
        return chartData.map(function(candleData) {
            return new window.chart.Candle(
                candleData.hi,
                candleData.lo,
                candleData.open,
                candleData.close,
                candleData.timestamp,
                candleData.volume
            );
        });
    }

    render() {
        return (
            <div
                id="custom-chart"
                className="chart chartArea--graph"
                data-chart-container="true"
                ref={this.htmlContainer}
            />
        );
    }
}

function mapStateToProps(state, ownProps) {
    const {data} = state.DXChart;
    const {chartType, chartInterval, offsets} = state.settings.dxChart;
    const {name} = state.settings.selectedSymbol;
    const {translation} = state.translation;
    let {newTick} = state.chart.orderForm;

    newTick = Object.keys(newTick).reduce((result, key) => {
        if (key === 'date') {
            result['timestamp'] = moment(newTick[key]).unix() * 1000;
            return result;
        } else if (key === 'hi') {
            result['hi'] = newTick[key];
            return result;
        } else if (key === 'lo') {
            result['lo'] = newTick[key];
            return result;
        } else {
            result[key] = newTick[key];
            return result;
        }
    }, {});

    return {
        data,
        newTick,
        type: chartType,
        chartInterval,
        offsets,
        selectedSymbol: name,
        translation,
    };
}

const mapDispatchToProps = {
    setErrorChart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartDX);
