import React, {Fragment, PureComponent} from 'react';

import List from '../component/List';
import Ticket from '../component/Ticket';


class TicketsLayoutIndex extends PureComponent {
    render() {
        return (
            <Fragment>
                <div className="column">
                    <List />
                </div>
                <Ticket />
            </Fragment>
        );
    }
}

export default TicketsLayoutIndex;
