import { ActionTypes } from './actions';

function faqModalReducer(state = { isOpen: false }, action) {
    switch (action.type) {
        case ActionTypes.open:
            return {
                isOpen: true
            };

        case ActionTypes.close:
            return {
                isOpen: false
            };

        default:
            return state;
    }
}

export default faqModalReducer;
