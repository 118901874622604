import {ActionType} from '../action/settings';
import {locale, dxChart, selectedSymbol, dashboardLayout} from 'settings';

const initialState = {
    isPending: true,
    locale: null,
    dashboardLayout,
    dxChart,
    selectedSymbol: {},
    favoriteCurrency: [],
    error: null,
    tradingViewData: null,
    isSoundOn: true,
};

export default function(state = initialState, action) {
    switch (action.type) {
        case ActionType.getSettingsData:
            return {
                ...state,
                isPending: true,
            };

        case ActionType.ON_SAVE_TRADIN_VIEW: {
            return {...state, tradingViewData: action.payload};
        }

        case ActionType.setSettingsData:
            if (typeof action.data !== 'object') {
                return {
                    ...state,
                    isPending: false,
                };
            }


            return action.data.selectedSymbol ?
             {
                ...initialState,
                ...action.data,
                dxChart: {
                    ...initialState.dxChart,
                    ...action.data.dxChart,
                },
                isPending: false,
            }
            : {
                    ...initialState,
                    ...action.data,
                    selectedSymbol,
                    dxChart: {
                        ...initialState.dxChart,
                        ...action.data.dxChart,
                    },
                    isPending: false,
                }

        case ActionType.getSettingsDataFailure:
            return {
                ...state,
                isPending: false,
                error: action.payload,
            };

        case ActionType.setLocale:
            return {...state, locale: action.locale};

        case ActionType.setDashboardLayout:
            return {...state, dashboardLayout: action.layout};

        case ActionType.setChartInterval:
            return {
                ...state,
                dxChart: {
                    ...state.dxChart,
                    chartInterval: action.interval,
                },
            };

        case ActionType.setChartType:
            return {
                ...state,
                dxChart: {
                    ...state.dxChart,
                    chartType: action.chartType,
                },
            };

        case ActionType.setChartOffset:
            return {
                ...state,
                dxChart: {
                    ...state.dxChart,
                    offsets: action.payload,
                },
            };

        case ActionType.setSelectedSymbol:
            return {
                ...state,
                selectedSymbol: {
                    ...action.selectedSymbol,
                    name: `${action.selectedSymbol.numerator}/${
                        action.selectedSymbol.denominator
                    }`,
                },
            };

        case ActionType.setFavoriteCurrency:
            return {
                ...state,
                favoriteCurrency: !state.favoriteCurrency.includes(
                    action.favoriteCurrency
                )
                    ? [...state.favoriteCurrency, action.favoriteCurrency]
                    : state.favoriteCurrency.filter(
                          element => element !== action.favoriteCurrency
                      ),
            };

        case ActionType.ON_SOUND:
            return {
                ...state,
                isSoundOn: !state.isSoundOn,
            };

        default:
            return {...state};
    }
}
