// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Logo from './logo.svg';

import {
    isActiveChat,
    isConnected,
    messages,
    messageText,
    userInfo,
    incomingTypingStatus,
    getAccessChatDataError,
    getSocketError,
} from 'infrastructure/modules/Chat';
import {
    getChatData,
    sendMessage,
    setMessageText,
    closeChat,
    setConnection,
} from './event';

import {setChatStatus} from 'infrastructure/modules/Chat/action';

import parseTranslation from 'infrastructure/adapter/helper/parseTranslation';
import {
    Wrapper,
    ScrollContainer,
    ChatTextareaScrollbar,
    Title,
    FormInputText,
    InputField,
    SystemMessage,
    ButtonWrapper,
    Button,
    Tooltip,
    WrapperItemChat,
    MessageItem,
    AvatarWrapper,
    MessageItemWrapper,
    Avatar,
    ChatTop,
    ButtonChat,
    WrapperLogo,
    ChatScrollbar,
} from 'infrastructure/view/ui/widgets/Chat';

import ChatItem from 'infrastructure/view/widget/Chat/chatItem';
import Error from '../../component/Error';
import TextareaAutosize from 'react-textarea-autosize';
import {Scrollbars} from 'react-custom-scrollbars';

function mapStateToProps(state) {
    return {
        title: parseTranslation('chat-title'),
        supportName: parseTranslation('chat-support-name'),
        supportMsg: parseTranslation('chat-support-msg'),
        statusTyping: parseTranslation('chat-status-typing'),
        placeholder: parseTranslation('chat-placeholder'),
        shortcutKey: parseTranslation('chat-shortcut-key'),
        titleSend: parseTranslation('label-send'),
        buttonChat: parseTranslation('label-button-chat'),
        you: parseTranslation('chat-client-name'),
        titleSupportModalChat: parseTranslation('chat-title-support-modal'),
        titleNewConversationChat: parseTranslation('chat-new-conversation'),
        titleChatOnlineSupport: parseTranslation('chat-online-support'),
        isActiveChat: isActiveChat(state),
        isConnected: isConnected(state),
        messages: messages(state),
        userInfo: userInfo(state),
        messageText: messageText(state),
        shortenUsername: userInfo(state).username.substr(0, 2),
        incomingTypingStatus: incomingTypingStatus(state),
        accessChatDataError: getAccessChatDataError(state),
        socketError: getSocketError(state),
    };
}

const mapDispatchToProps = {
    getChatData,
    sendMessage,
    setMessageText,
    closeChat,
    setConnection,
    setChatStatus,
};

type PropTypes = {
    title: string,
    supportName: string,
    supportMsg: string,
    statusTyping: string,
    placeholder: string,
    titleSend: string,
    isActiveChat: boolean,
    isConnected: boolean,
    messages: [],
    messageText: string,
    userName: string,
    shortenUsername: string,

    userInfo: {
        username: string,
        firstName: string,
        lastName: string,
    },
    incomingTypingStatus: {
        username: string,
        status: boolean,
    },
    getChatData(): mixed,
    sendMessage(): mixed,
    setMessageText(messageText: string): mixed,
    scrollToBottom(): mixed,
    closeChat(): mixed,
    setConnection(): mixed,
    setConnection(): mixed,
};

class ChatScene extends Component<PropTypes> {
    constructor(props) {
        super(props);
        this.state = {
            openedChat: false,
        };
        this.chatScrollbarContainer = React.createRef();

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChangeTextFiled = this.handleChangeTextFiled.bind(this);
        this.replyByEnter = this.replyByEnter.bind(this);
        this.connectChat = this.connectChat.bind(this);
    }

    render() {
        const {
            title,
            supportName,
            supportMsg,
            statusTyping,
            placeholder,
            shortcutKey,
            titleSend,
            messages,
            userInfo,
            shortenUsername,
            messageText,
            incomingTypingStatus,
            isConnected,
            isActiveChat,
            buttonChat,
            titleSupportModalChat,
            titleNewConversationChat,
            titleChatOnlineSupport,
            accessChatDataError,
            socketError,
        } = this.props;

        return (
            <Wrapper>
                <ChatTop>
                <Title>{title}</Title>
                <ChatScrollbar
                    ref={this.chatScrollbarContainer}
                    className="scrollbar"
                    renderTrackVertical={p => (
                        <div {...p} className="scrollbar-container" />
                    )}
                    renderThumbVertical={p => (
                        <div {...p} className="scrollbar-thumb" />
                    )}
                >
                    {(!isConnected || !isActiveChat) && (
                        <>
                            <WrapperItemChat>
                                <MessageItem style={{height: '25rem'}} system>
                                    <AvatarWrapper>
                                        <i className="icon-avatar-bot" />
                                    </AvatarWrapper>
                                    <MessageItemWrapper>
                                        <span>{titleSupportModalChat}</span>
                                        {accessChatDataError || socketError ? (
                                            <Error />
                                        ) : (
                                            <>
                                                <p>{titleChatOnlineSupport}</p>

                                                <p>
                                                    {titleNewConversationChat}
                                                </p>
                                            </>
                                        )}
                                    </MessageItemWrapper>
                                </MessageItem>
                                <ButtonChat
                                    type="button"
                                    onClick={this.connectChat}
                                >
                                    {buttonChat}
                                </ButtonChat>

                                <WrapperLogo>
                                    <img src={Logo} alt="logo" />
                                </WrapperLogo>
                            </WrapperItemChat>
                        </>
                    )}
                    {isConnected && isActiveChat && (
                        <>
                            <ScrollContainer>
                                {!!messages.length &&
                                messages.map((el, index) => {
                                    const {
                                        username,
                                        avatar,
                                        createTime,
                                        data,
                                        id,
                                    } = el;
                                    let userName = username;
                                    let message = data;

                                    if (index === 0) {
                                        userName = supportName;
                                        message = supportMsg;
                                    }

                                    if (messages.length === 1) {
                                        return (
                                            <div key={id}>
                                                <ChatItem

                                                    username={userName}
                                                    date={createTime.date}
                                                    time={createTime.time}
                                                    avatar={avatar}
                                                >
                                                    {message}
                                                </ChatItem>

                                                <WrapperLogo>
                                                    <img
                                                        src={Logo}
                                                        alt="logo"
                                                    />
                                                </WrapperLogo>
                                            </div>
                                        );
                                    }

                                    if (
                                        userName ===
                                        `${userInfo.firstName} ${userInfo.lastName}` ||
                                        (userName === (userInfo.firstName || '')) ||
                                        (userName === (userInfo.lastName || '')) ||
                                        userName === userInfo.username
                                    ) {
                                        return (
                                            <ChatItem
                                                user
                                                key={id}
                                                username={userName}
                                                avatar={avatar}
                                                translation ={{you: this.props.you}}
                                                shortenUsername={
                                                    shortenUsername
                                                }
                                                date={createTime.date}
                                                time={createTime.time}
                                            >
                                                {message}
                                            </ChatItem>
                                        );
                                    } else {
                                        return (
                                            <ChatItem
                                                key={id}
                                                username={userName}
                                                date={createTime.date}
                                                time={createTime.time}
                                                avatar={avatar}
                                            >
                                                {message}
                                            </ChatItem>
                                        );
                                    }
                                })}
                            </ScrollContainer>
                        </>
                    )}
                </ChatScrollbar>
                </ChatTop>
                {isConnected && isActiveChat && (
                    <FormInputText onSubmit={this.handleFormSubmit}>
                        <SystemMessage>
                            {incomingTypingStatus.status &&
                            incomingTypingStatus.username +
                            ' ' +
                            statusTyping}
                            &nbsp;
                        </SystemMessage>
                        <Scrollbars
                            className="scroll textarea-scroll"
                            autoHeight
                            autoHeightMax="16rem"
                            renderTrackVertical={p => (
                                <div {...p} className="srollbar-container" />
                            )}
                            renderThumbVertical={p => (
                                <div {...p} className="srollbar-thumb" />
                            )}
                        >
                            <TextareaAutosize
                                onKeyPress={this.replyByEnter}
                                value={messageText}
                                onChange={this.handleChangeTextFiled}
                                maxLength={2000}
                                placeholder={placeholder}
                                className="listing-textarea"
                                onChangeCapture={this.handleInput}
                                name={'name'}
                            />
                        </Scrollbars>
                        <ButtonWrapper>
                            <Button type="submit">{titleSend}</Button>
                            <Tooltip>{shortcutKey}&nbsp;Ctrl + Enter</Tooltip>
                        </ButtonWrapper>
                    </FormInputText>
                )}
            </Wrapper>
        );
    }

    shouldComponentUpdate(nextProps) {
        const {
            closeChat,
            messages,
            messageText,
            incomingTypingStatus,
        } = this.props;

        if (!nextProps.isActiveChat) {
            closeChat();
            this.forceUpdate();
        } else if (
            messages.length === nextProps.messages.length &&
            messageText === nextProps.messageText &&
            incomingTypingStatus === nextProps.incomingTypingStatus &&
            userInfo === nextProps.userInfo
        ) {
            return false;
        }
        return true;
    }

    getSnapshotBeforeUpdate(prevProps): ?boolean {
        if (prevProps.messages.length !== this.props.messages.length) {
            return true;
        }
        return null;
    }

    componentDidUpdate(snapshot) {
        if (!this.props.isConnected && this.state.openedChat) {
            this.props.setConnection();
        }
        if (snapshot !== null) {
            if (this.chatScrollbarContainer.current) {
                setTimeout(() => {
                    this.chatScrollbarContainer.current.scrollToBottom();
                }, 100);
            }
        }
    }

    componentWillUnmount() {
        const {closeChat} = this.props;
        closeChat();
    }

    connectChat() {
        const {getChatData, isActiveChat} = this.props;
        if (!isActiveChat && !this.state.openedChat) {
            getChatData();
        }
        this.setState({openedChat: true});
    }

    handleChangeTextFiled({target}: {target: HTMLInputElement}): void {
        this.props.setMessageText(target.value);
    }

    replyByEnter(e): void {
        if (e.which === 13 && e.ctrlKey) {
            this.handleFormSubmit(e);
        }
    }

    handleFormSubmit(e: Event): void {
        const {sendMessage, setMessageText} = this.props;
        e.preventDefault();
        sendMessage();
        setMessageText('');

        if (this.chatScrollbarContainer.current) {
            setTimeout(() => {
                this.chatScrollbarContainer.current.scrollToBottom();
            }, 100);
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatScene);
