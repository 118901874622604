// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {uniqBy, isEqual} from 'lodash';
import classnames from 'classnames';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import moment from 'moment';

import Panel from 'infrastructure/view/component/Panel/Panel';
import ListItem from './ListItem';
import RangeDatePicker from 'infrastructure/view/component/RangeDatePickerWrapper';
import ReactSelectWrapper from 'infrastructure/view/component/ReactSelectWrapper/ReactSelectWrapper';
import Tooltip from 'infrastructure/view/component/Tooltip';

import logo from '../logo.svg';

import {
    fetchTicket,
    setFilterTicketType,
    setFilterTicketPattern,
    setFilterTicketCategory,
    setFilterTicketSubCategory,
    setFilterTicketRangeDates,
} from '../service/actions';

import parseTranslation from 'infrastructure/adapter/helper/parseTranslation';
import Error from '../../../component/Error';

function mapStateToProps(state) {
    const {
        list,
        selectedTicket,
        filterParams,
        error,
        errorTicket,
    } = state.tickets;
    const categoriesAll = parseTranslation('ticket-categories-dropdown-all');
    const subcategoriesAll = parseTranslation(
        'ticket-subcategories-dropdown-all'
    );
    const uniqCategoryTypes = list.map(item => ({
        value: item.type,
        label: parseTranslation(
            `category-option-${item.category.toLowerCase()}`
        ),
    }));
    const uniqSubcategoryTypes = list
        .filter(
            item => !item.additionalInfo.length && item.additionalInfo.method
        )
        .map(item => ({
            value: item.additionalInfo.method,
            label: parseTranslation(
                `subcategory-option-${item.additionalInfo.method.toLowerCase()}`
            ),
        }));

    const categoryList = [
        {value: '', label: categoriesAll},
        ...(list ? uniqBy(uniqCategoryTypes, 'value') : []),
    ];

    const subcategoryList = [
        {value: '', label: subcategoriesAll},
        ...(list ? uniqBy(uniqSubcategoryTypes, 'value') : []),
    ];

    return {
        list,
        error,
        selectedTicket,
        filterParams,
        categoryList,
        subcategoryList,
        categoriesAll,
        subcategoriesAll,
        labelNewTicket: parseTranslation('ticket-button-create'),
        titlePanelTickets: parseTranslation('title-ticket'),
        labelAnyStatus: parseTranslation('any-status'),
        filterLabel: parseTranslation('tickets-filter-label'),
        inputPlaceholder: parseTranslation('label-find-ticket'),
        categoryVerification: parseTranslation(
            'create-ticket-verification-tab'
        ),
        categoryQuestion: parseTranslation('create-ticket-question-tab'),
        categoryWithdraw: parseTranslation('label-withdraw-real'),
        categoryListing: parseTranslation('create-ticket-button-listing'),
        placeholderDateRange: parseTranslation('ticket-placeholder-date'),
        tooltipTextNew: parseTranslation('tickets-filter-new'),
        tooltipTextPending: parseTranslation('tickets-filter-pending'),
        tooltipTextRejected: parseTranslation('tickets-filter-rejected'),
        tooltipTextProcessed: parseTranslation('tickets-filter-processed'),
    };
}

const mapDispatchToProps = {
    fetchTicket,
    setFilterTicketType,
    setFilterTicketPattern,
    setFilterTicketCategory,
    setFilterTicketSubCategory,
    setFilterTicketRangeDates,
};

type PropTypes = {
    list: {},
    labelNewTicket: string,
    titlePanelTickets: string,
    withoutButtonCreate?: boolean,
    titlePanelTickets?: string,
    fetchTicket: () => mixed,
};

const STATUSES = {
    NEW: ['new'],
    PENDING: ['pending', 'wait system confirmation'],
    REJECTED: ['rejected'],
    PROCESSED: ['processed', 'confirmed'],
};

class List extends PureComponent<PropTypes> {
    constructor(props) {
        super(props);

        this.setTicketSearchCategory = this.setTicketSearchCategory.bind(this);
        this.setTicketSearchSubCategory = this.setTicketSearchSubCategory.bind(this);
        this.setTicketSearchPattern = this.setTicketSearchPattern.bind(this);
        this.onSelectRangeDates = this.onSelectRangeDates.bind(this);
        this.getDisabledDates = this.getDisabledDates.bind(this);
    }

    title: string = 'TICKETS';

    componentWillUnmount(): * {
        this.props.setFilterTicketPattern('');
    }

    getDisabledDates(date) {
        return date.diff(moment().startOf('day'), 'days') > 0;
    }
    setTicketType(type) {
        this.props.setFilterTicketType(type);
    }

    setTicketSearchPattern(e) {
        this.props.setFilterTicketPattern(e.target.value);
    }

    setTicketSearchCategory(option) {
        const {subcategory} = this.props.filterParams;

        subcategory && this.setTicketSearchSubCategory({value: ''});
        this.props.setFilterTicketCategory(option.value);
    }

    setTicketSearchSubCategory(option) {
        this.props.setFilterTicketSubCategory(option.value);
    }

    onSelectRangeDates(param) {
        this.props.setFilterTicketRangeDates(
            param.map(item => item.startOf('day').unix())
        );
    }

    getFilteredList() {
        const {list, filterParams} = this.props;

        const {status, pattern, category, subcategory, range} = filterParams;

        let filteredList = list.slice(0);

        if (!!status.length) {
            filteredList = filteredList.filter(item =>
                status.includes(item.status)
            );
        }

        if (category) {
            filteredList = filteredList.filter(item => item.type === category);
        }

        if (subcategory) {
            filteredList = filteredList.filter(
                item =>
                    !item.additionalInfo.length &&
                    item.additionalInfo.method &&
                    item.additionalInfo.method === subcategory
            );
        }

        if (range.length === 2) {
            filteredList = filteredList.filter(item => {
                const dataDay = moment
                    .unix(item.updatedAt.unix())
                    .startOf('day');
                return (
                    dataDay.startOf('day').unix() >= range[0] &&
                    dataDay.startOf('day').unix() <= range[1]
                );
            });
        }

        if (pattern) {
            filteredList = filteredList.filter(item => {
                const {title = '', description = ''} = item.additionalInfo;

                return (
                    title.toLowerCase().includes(pattern.toLowerCase()) ||
                    description.toLowerCase().includes(pattern.toLowerCase())
                );
            });
        }

        return filteredList;
    }

    render() {
        const {
            titlePanelTickets,
            fetchTicket,
            labelNewTicket,
            withoutButtonCreate,
            selectedTicket,
            inputPlaceholder,
            placeholderDateRange,
            categoryList,
            subcategoryList,
            filterParams,
            categoriesAll,
            subcategoriesAll,
            tooltipTextNew,
            tooltipTextPending,
            tooltipTextRejected,
            tooltipTextProcessed,
            error,
            errorTicket,
        } = this.props;
        

        const {status, range, category, subcategory, pattern} = filterParams;

        const filterNewClassName = classnames('tittle__icon-wrapper', {
            selected: isEqual(STATUSES.NEW, status),
        });
        const filterPendingClassName = classnames('tittle__icon-wrapper', {
            selected: isEqual(STATUSES.PENDING, status),
        });
        const filterRejectedClassName = classnames('tittle__icon-wrapper', {
            selected: isEqual(STATUSES.REJECTED, status),
        });
        const filterProcessedClassName = classnames('tittle__icon-wrapper', {
            selected: isEqual(STATUSES.PROCESSED, status),
        });

        const subcategoryFilterValue = subcategoryList.find(
            item => item.value === subcategory
        );

        const categoryFilterValue = categoryList.find(
            item => item.value === category
        );

        return (
            <div className='panel-wrapper'>
                <Panel title={titlePanelTickets}>
                    <Fragment>
                        <div className="create-tickets">
                            <div className="tittle">
                                <span className="tittle__label">
                                    {this.props.filterLabel}
                                </span>
                                <div className="tittle__filters-wrapper">
                                    <div className="tittle__filters">
                                        <div className={filterNewClassName}>
                                            <Tooltip text={tooltipTextNew}>
                                                <i
                                                    className="icon-new color-new"
                                                    onClick={this.setTicketType.bind(
                                                        this,
                                                        STATUSES.NEW
                                                    )}
                                                />
                                                {/*<img*/}
                                                {/*src={newTypeIcon}*/}
                                                {/*onClick={this.setTicketType.bind(*/}
                                                {/*this,*/}
                                                {/*STATUSES.NEW,*/}
                                                {/*)}*/}
                                                {/*alt='icon'*/}
                                                {/*/>*/}
                                            </Tooltip>
                                        </div>
                                        <div className={filterPendingClassName}>
                                            <Tooltip text={tooltipTextPending}>
                                                <i
                                                    className="icon-pending color-pending"
                                                    onClick={this.setTicketType.bind(
                                                        this,
                                                        STATUSES.PENDING
                                                    )}
                                                />
                                            </Tooltip>
                                        </div>
                                        <div className={filterRejectedClassName}>
                                            <Tooltip text={tooltipTextRejected}>
                                                <i
                                                    className="icon-rejected color-rejected"
                                                    onClick={this.setTicketType.bind(
                                                        this,
                                                        STATUSES.REJECTED
                                                    )}
                                                />
                                            </Tooltip>
                                        </div>
                                        <div className={filterProcessedClassName}>
                                            <Tooltip text={tooltipTextProcessed}>
                                                <i
                                                    className="icon-confirmed color-processed"
                                                    onClick={this.setTicketType.bind(
                                                        this,
                                                        STATUSES.PROCESSED
                                                    )}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {!!status.length && (
                                        <div className="tittle__clear-type-filter">
                                            <i
                                                className="icon-close"
                                                onClick={this.setTicketType.bind(
                                                    this,
                                                    ''
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tittle__category-filter">
                                <div className="tittle__select-category">
                                    <ReactSelectWrapper
                                        value={categoryFilterValue}
                                        options={categoryList}
                                        placeholder={categoriesAll}
                                        onChange={this.setTicketSearchCategory}
                                    />
                                </div>
                                <div className="tittle__select-sub-category">
                                    <ReactSelectWrapper
                                        value={subcategoryFilterValue}
                                        options={subcategoryList}
                                        placeholder={subcategoriesAll}
                                        onChange={this.setTicketSearchSubCategory}
                                        disabled={
                                            category !== 'withdraw' &&
                                            category !== 'listing'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="tittle__date-filter">
                                <div className="tittle__select-range-dates">
                                    <RangeDatePicker
                                        onChange={this.onSelectRangeDates}
                                        placeholder={[placeholderDateRange, '']}
                                        separator={range.length === 2 ? '-' : ''}
                                        isRangeEmpty={!range.length}
                                        suffixIcon={
                                            range.length === 2 ? (
                                                <i />
                                            ) : (
                                                <i className="icon-single-down-pointing-angle" />
                                            )
                                        }
                                        disabledDate={this.getDisabledDates}
                                    />
                                </div>
                            </div>
                            <div className="tittle__input-wrapper">
                                <input
                                    className="tittle__find-input"
                                    onChange={this.setTicketSearchPattern}
                                    placeholder={inputPlaceholder}
                                    value={pattern}
                                />
                                {pattern.length ? <i className="icon-close" onClick={() => this.setTicketSearchPattern({target: {value: ''}})} /> : <i className="icon-find" />}
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '26rem',
                                    bottom: '17rem',
                                    left: 0,
                                    right: 0,
                                    display: 'flex',
                                    zIndex: 1,
                                }}
                            >
                                <Scrollbars>
                                    <div className="ticket-wrap">
                                        {error && <Error />}
                                        {this.getFilteredList().map(ticket => (
                                            <ListItem
                                                key={ticket.id}
                                                id={ticket.id}
                                                title={ticket.additionalInfo.title}
                                                status={ticket.status}
                                                category={ticket.category}
                                                updatedAt={ticket.updatedAt}
                                                additionalInfo={
                                                    ticket.additionalInfo
                                                }
                                                fetchTicket={fetchTicket}
                                                shouldShowNotification={
                                                    ticket.shouldShowNotification
                                                }
                                                selectedTicketId={
                                                    selectedTicket &&
                                                    selectedTicket.id
                                                }
                                            />
                                        ))}
                                    </div>
                                </Scrollbars>
                            </div>
                            {!this.getFilteredList().length && (
                                <div className="ticket-bg">
                                    <img src={logo} alt="logo-ticket" />
                                </div>
                            )}
                        </div>

                        <Link
                            className={`create ${
                                withoutButtonCreate ? 'disable' : ''
                            }`}
                            to={'/tickets/create/verification'}
                        >
                            {labelNewTicket}
                        </Link>
                    </Fragment>
                </Panel>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
