export const ActionTypes = {
    open: 'dashboard-scene.header-component.faq-modal.open',
    close: 'dashboard-scene.header-component.faq-modal.close'
};

export function open() {
    return {
        type: ActionTypes.open
    };
}

export function close() {
    return {
        type: ActionTypes.close
    };
}