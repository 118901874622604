import {callApiActionFactory, callApi} from '../actions/actionTypes';

export const GET_CHART_HISTORY = callApi('GET_CHART_HISTORY');
export const CHANGE_INTERVAL = 'CHANGE_INTERVAL';
export const SET_ERROR = 'SET_ERROR';

const CHART_URL = (pair, interval) => `/v1/dealing/history?asset=${pair}&interval=${interval.toLowerCase()}&limit=1000`;

export const getChartHistory = (pair, interval) => callApiActionFactory(GET_CHART_HISTORY, CHART_URL(pair, interval), 'POST');
export const changeInterval = value => ({type: CHANGE_INTERVAL, value});
export const setError = value => ({type: SET_ERROR, value});
