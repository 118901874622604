// @flow
class SubmitLoginForm {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(formData: {} = {}, locale, server): Promise<any> {
        try {
            return await this.adapter.sendForm(formData, locale, server);
        } catch (error) {
            if (error.response) {
                const { status } = error.response;

                if (status && status === 422) {
                    return error.response;
                }
            }

            throw error;
        }
    }
}

export default SubmitLoginForm;
