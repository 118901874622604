// @flow
import type Axios from 'axios';
import localConfig from 'localConfig';

import type { RecoveryFormAdapterInterface } from '../../application/adapter/RecoveryForm';
import type {AccessToken} from "../../domain/entitiy/AccessToken";

class RecoveryFormAdapter implements RecoveryFormAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getForm(locale: string, token: string = ""): Promise<any> {
        return await this.apiClient({
            method: "PUT",
            url: `${localConfig.authUrl}/v1/form/recovery`,
            withCredentials: false,
            headers: {
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`,
            }
        });
    }

    async sendForm( formData: {}, locale: string): Promise<AccessToken> {

        return await this.apiClient({
            method: "POST",
            url: `${localConfig.authUrl}/v1/form/recovery`,
            withCredentials: false,
            headers: {
                "content-type": "application/json",
                "cache-control": "no-cache",
                "Accept-Language": `${locale}; q=1`
            },

            data: {
                RecoveryForm: {
                    ...formData.RecoveryForm,
                    callBackUrl: `${window.location.origin}/recovery-confirmation/{token}{username}`
                }
            }

        });
    }
}

export default RecoveryFormAdapter;
