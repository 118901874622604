import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';

import CurrencyPair from '../../../../../component/CurrencyPair/CurrencyPair';

import {close} from './service/actions';

function mapStateToProps(state) {
    const {isOpen} = state.NotificationModal;
    const {assets: notification} = state;

    return {
        isOpen,
        notification,
    };
}

const mapDispatchToProps = {
    close,
};

class NotificationModal extends Component {
    constructor() {
        super();

        this.close = this.close.bind(this);
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                contentLabel="Notification Modal"
                className="modal notification"
                style={{
                    overlay: {
                        backgroundColor: 'rgba(20, 22, 27, 0.65)',
                        display: 'flex',
                    },
                }}
            >
                <div className="modal-header">
                    <span>MARKET DEPTH NOTIFICATION</span>
                    <button onClick={this.close}>
                        <i className="icon-close" />
                    </button>
                </div>

                <div className="content-notification">
                    <div className="col">
                        <span>Price</span>
                        <input type="text" />
                        <i className="icon-sound-on" />
                        <CurrencyPair />
                    </div>

                    <div className="col">
                        <button  onClick={this.close}>
                            Close
                        </button>
                        <button>Submit</button>
                    </div>
                </div>
            </Modal>
        );
    }

    close() {
        this.props.close();
    }
}

const ConnectedNotificationModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationModal);

export default ConnectedNotificationModal;
