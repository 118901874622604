// @flow
class GetLoginForm {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(locale: string, token: string): Promise<any> {
        return await this.adapter.getForm(locale, token);
    }
}

export default GetLoginForm;
