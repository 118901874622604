import styled from 'styled-components';

export const wrapperBg = '#9E9EAC';

export const Wrapper = styled.div`
    height: calc(100% - 14.7rem);
    padding: 2rem;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 25% calc(50% - 4rem) 25%;
    grid-template-rows: 43% 27% auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    background: ${wrapperBg};
    position:relative;
    z-index: 1;
    border-radius: 0;
`;
export const HeaderContent = styled.div`
  display: flex;
  .wrapper-dropdown {
    justify-content: center;
  } 
  a {
    margin-left: 4rem;
    text-transform: uppercase;
  }
`;
export const Symbols = styled.div`    
    display: flex;
    grid-area: 1 / 2 / 4 / 1;
`;
export const Chart = styled.div`
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 2;
    display: flex;
`;
export const Market = styled.div`
    grid-column-start: 3;
    grid-column-end: span 1;
    grid-row-start: 1;
    grid-row-end: span 1;
    display: flex;
`;
export const News = styled.div`
    grid-column-start: 1;
    grid-column-end: span 1;
    grid-row-start: 2;
    grid-row-end: span 2;
    display: flex;
`;
export const Orders = styled.div`
    grid-column-start: 2;
    grid-column-end: span 1;
    grid-row-start: 3;
    grid-row-end: span 1;
    display: flex;
`;
export const Trades = styled.div`
    grid-column-start: 3;
    grid-column-end: span 1;
    grid-row-start: 3;
    grid-row-end: span 1;
    display: flex;
`;

export const OrderForm = styled.div`
    grid-column-start: 3;
    grid-column-end: span 1;
    grid-row-start: 2;
    grid-row-end: span 1;
    display: flex;
`;
