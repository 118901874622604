import React, {useEffect} from 'react';
import t from '../../infrastructure/adapter/helper/parseTranslation';
import Panel from '../components/Panel';
import {Empty} from './styled';
import config from '../../config';

export default function GuestOrdersContainer() {
    return(
        <Panel title={t('label-my-trading')} icon="icon-my-trading" hideExpander>
            <Empty><div><a href={`${config.workspaceUrl}/registration`}>{t('label-sign-up')}</a> {t('label-or')} <a href={`${config.workspaceUrl}/login`}>{t('login-btn')}</a></div></Empty>
        </Panel>
    )
}
