// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import Chat from '../../../component/TicketsChat/TicketChat';

import parseTranslation from 'infrastructure/adapter/helper/parseTranslation';
import Error from "../../../component/Error";

function mapStateToProps(state) {
    const {selectedTicket, errorTicket} = state.tickets;

    return {
        selectedTicket,
        errorTicket,
        ticketUpdateTime: parseTranslation('ticket-update-time'),
        categories: {
            question: parseTranslation('category-option-question'),
            verification: parseTranslation('category-option-verification'),
            withdraw: parseTranslation('category-option-withdraw'),
            listing: parseTranslation('category-option-listing'),
        },
    };
}

const mapDispatchToProps = {};

type PropTypes = {
    additionalInfo: {},
    category: string,
    id: number,
    status: string,
    type: string,
};

class Ticket extends PureComponent<PropTypes> {
    renderChatTitle(ticket, categories) {
        const {updatedAt, status, category} = ticket;

        return (
            <div className="ticket-chat">
                <span className="ticket-chat__title">
                    {categories[category.toLowerCase()]}
                </span>
                <div className="ticket-chat__content">
                    <span className="ticket-chat__updated-at">

                        {this.props.ticketUpdateTime}&nbsp;
                        {updatedAt.format('DD/MM/YYYY  HH:mm:ss')}
                    </span>
                    <span className={`ticket-chat__status color-${status}`}>
                        <i className={`icon-${status === 'processed' ? 'confirmed' : status} color-${status} icon-tickets-size`} />
                        &nbsp;
                        {status.charAt(0).toUpperCase() + status.slice(1)}
                    </span>
                </div>
            </div>
        );
    }

    renderTicket(ticket, categories) {
        const {
            additionalInfo: {description},
            type,
            updatedAt,
        } = ticket;

        const title = this.renderChatTitle(ticket, categories);

        return (
            <div className='content no-logo'>
                <Chat
                    title={title}
                    updatedAt={updatedAt}
                    ticketId={ticket.id}
                    message={description}
                    type={type}
                />
            </div>
        );
    }

    render() {
        const {selectedTicket, categories, errorTicket} = this.props;

        return (
            <>
                {selectedTicket && this.renderTicket(selectedTicket, categories)}
                {errorTicket && <Error />}
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ticket);
