import axios from 'axios';
import store from 'infrastructure/view/services/store';
import Config from 'localConfig';
import {chartDXHistorySuccess} from "../../../../../services/action/DXChartHistoryProvider";
import {getDomain} from '../../../../../../../utils';


function historyProvider(symbol, resolution, from, to) {
    const {accessToken} = store.getState().user;
    const limit = 1000;

    let interval = `${resolution}m`;
    if (resolution === '60') {
        interval = `1h`;
    }

    const url = `${getDomain(Config.apiUrl)}/v1/dealing/candles?asset=${symbol}&interval=${interval}&from=${from}&to=${to}&limit=${limit}`;

    return axios({
        method: 'POST',
        url,
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'cache-control': 'no-cache',
            authorization: `Bearer ${accessToken}`,
        },
    }).then(data => {
        store.dispatch(chartDXHistorySuccess());
        if (data.data.length) {
            return data.data.reverse().map(el => {
                return {
                    ...el,
                    time: el.time,
                };
            });
        } else {
            return [];
        }
    });
}

export default historyProvider;
