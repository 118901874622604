import { LayoutActionTypes } from './actions';

function symbolsModalReducer(state = { isOpen: false }, action) {
    switch (action.type) {
        case LayoutActionTypes.open:
            return {
                isOpen: true
            };

        case LayoutActionTypes.close:
            return {
                isOpen: false
            };

        default:
            return state;
    }
}

export default symbolsModalReducer;
