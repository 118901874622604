import React from 'react';
import {CurrencyLabel, CurrencyWrapper} from './styled';
import ScrollBar from 'react-custom-scrollbars';
import CurrencyRowComponent from './CurrencyRow';
import {LinesFill} from '../../components/LinesFill/styled';
import t from '../../../infrastructure/adapter/helper/parseTranslation';

export default function Currency({symbols, selectedSymbols, onSelect, filter}) {
    const currencies = [];
    Object.keys(symbols).map(item => {
        currencies.push(item.split('/')[0]);
        currencies.push(item.split('/')[1]);
    });
    const filteredCurrencies = currencies.filter(
        (el, i, a) => i === a.indexOf(el)
    );
    const handleClick = item => () => onSelect(item);
    return (
        <CurrencyWrapper>
            <CurrencyLabel>{t('active-symbol-base')}</CurrencyLabel>
            <ScrollBar>
                <LinesFill />
                {filteredCurrencies.map(item => filter ? (item.toLowerCase().includes(filter.toLowerCase()) &&
                    <CurrencyRowComponent
                        key={item}
                        onClick={handleClick(item)}
                        title={item}
                        isActive={selectedSymbols.split('/')[0].includes(item)}
                    />)
                    : <CurrencyRowComponent
                        key={item}
                        onClick={handleClick(item)}
                        title={item}
                        isActive={selectedSymbols.split('/')[0].includes(item)}
                    />
                )}
            </ScrollBar>
        </CurrencyWrapper>
    );
}
