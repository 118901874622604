import React, {Component} from 'react';
import moment from 'moment';

import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
    HeaderWrapper,
    Logo,
    IconButton,
    DropdownItem,
    Avatar,
    NoImage,
    UserBlock,
    UserBlockArrow,
    UserBlockContent,
    UserControls,
    UserInfos,
    RegistrationInfo,
    Link,
    UserButton,
    LinkIcon,
} from './styled';
import logo from './logo.svg';
import {Redirect} from 'react-router-dom';
import Button from './Button';
import SymbolButton from '../SymbolButton';
import parseTranslation from '../../../adapter/helper/parseTranslation';
import {open as openSymbolsModal} from '../../scene/Dashboard/components/Header/component/SymbolsModal/service/actions';
import {open as openLayoutModal} from '../../scene/Dashboard/components/Header/component/LayoutModal/service/actions';
import {open as openFaqModal} from '../../scene/Dashboard/components/Header/component/FaqModal/service/actions';
import {setChartTheme} from '../../scene/Dashboard/components/Chart/service/actions';
import {toggleFullscreen} from '../../services/action/fullscreen';
import {closeMenu, toggleMenu} from '../../services/action/menu';
import DateDisplay from '../DateTime/Date';
import TimeDisplay from '../DateTime/Time';
import LayoutModal from '../../scene/Dashboard/components/Header/component/LayoutModal/LayoutModal';
import FaqModal from '../../scene/Dashboard/components/Header/component/FaqModal/FaqModal';
import SymbolsModal from '../../scene/Dashboard/components/Header/component/SymbolsModal/SymbolsModal';
import OrderModal from '../../scene/Dashboard/components/Header/component/OrderModal';
import Dropdown from 'infrastructure/view/component/Dropdown/Dropdown';
import DropdownWrapper from './DropdownWrapper';
import Notification from '../Notification';
import {
    closeNotification,
    closeAllNotifications,
    getNotificationsHistory,
} from 'infrastructure/view/services/action/notification';
import Tooltip from './Tooltip';

import Balance from './Balance';
import config from '../../../../localConfig';
import NotificationsContainer from '../../scene/Dashboard/components/Header/component/GlobalNotifications/NotificationsContainer';
import {logout} from 'infrastructure/view/services/action/user';
import {getSettingsData} from 'infrastructure/view/services/action/settings';
import {getUserData} from '../../services/action/user';
import {notificationSocketRequest} from '../../services/action/notification';
import ServerInfo from './ServerInfo';
import {openExchange} from '../../scene/Dashboard/components/Header/actions';

const mapStateToProps = state => {
    const {notifications} = state;
    const {balances, info, errorUserData, userRegion} = state.user;
    const {selectedSymbol} = state.settings;
    const {data} = state.chart.orderForm;
    const {isOpenMenu} = state.menu;
    const {enable} = state.fullscreen;
    const {isOpen} = state.exchangeModal;

    return {
        isOpenModal: isOpen,
        balances,
        selectedSymbol,
        notifications,
        errorUserData,
        userRegion,
        nameUser: info,
        chartData: data || [],
        labelButtonOpenOrder: parseTranslation('label-open-order-button'),
        labelButtonDeposit: parseTranslation('label-deposit'),
        titleLayout: parseTranslation('label-layout'),
        titleFAQ: parseTranslation('label-faq'),
        titleNotification: parseTranslation('label-notifications'),
        titleDay: parseTranslation('label-day'),
        titleNight: parseTranslation('label-night'),
        titleExchange: parseTranslation('label-exchange'),
        tooltipFullScreen: parseTranslation('tooltip-fullscreen'),
        tooltipAllSymbols: parseTranslation('tooltip-all-symbols'),
        tooltipBalance: parseTranslation('tooltip-balance'),
        myProfileBtnText: parseTranslation('button-my-profile'),
        registrationDate: parseTranslation('label-creation-date'),
        titleProfileSettings: parseTranslation('profile-settings-user'),
        themeTooltip: parseTranslation('theme-tooltip'),
        logoutText: parseTranslation('logout'),
        symbolText: parseTranslation('tooltip-all-symbols'),
        noNotifLabel: parseTranslation('label-incoming-notifications'),
        closeAll: parseTranslation('label-button-close-all-notifications'),
        tuitionText: parseTranslation('label-faq-modal-tuition'),
        headerServerInfoLabel: parseTranslation('header-server-info-label'),
        headerServerInfoTimeTooltip: parseTranslation(
            'header-server-info-time-tooltip'
        ),
        headerServerInfoRegionTooltip: parseTranslation(
            'header-server-info-region-tooltip'
        ),
        shouldShowNotificationsAvailable: !!state.notifications.data.length,
        isOpenMenu,
        isFullscreenEnabled: enable,
    };
};

const mapDispatchToProps = {
    openExchange,
    openSymbolsModal,
    openLayoutModal,
    openFaqModal,
    setChartTheme,
    toggleFullscreen,
    toggleMenu,
    closeMenu,
    closeAllNotifications,
    closeNotification,
    getNotificationsHistory,
    getSettingsData,
    getUserData,
    logout,
    notificationSocketRequest,
};

class Header extends Component {
    userData = {
        first_name: null,
        last_name: null,
        avatar_url: null,
        created_at: null,
    };
    state = {
        showOpenOrderModal: false,
        showNotificationDropDown: false,
        selectLeft: 'one',
        selectRight: 'one',
        selectedOption: 'one',
        longList: false,
        integrationInfo: null,
    };

    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        const {
            getSettingsData,
            getNotificationsHistory,
            getUserData,
            notificationSocketRequest,
            notifications: {isSocketConnected},
        } = this.props;

        getNotificationsHistory();
        getSettingsData(this.props.sitePair);
        getUserData();

        if (!isSocketConnected) {
            notificationSocketRequest();
        }

        const infoJSON = localStorage.getItem('userRegion');
        try {
            const info = JSON.parse(infoJSON);
            const {userInfo} = info;
            this.setState({integrationInfo: userInfo});
        } catch (e) {}
    }

    logOut = () => {
        const {history, logout} = this.props;
        localStorage.removeItem('userRegion');
        logout(history);
        window.location.href = `${config.workspaceUrl}/logout`;
    };

    render() {
        const {
            nameUser,
            labelButtonOpenOrder,
            labelButtonDeposit,
            openLayoutModal,
            openSymbolsModal,
            selectedSymbol,
            balances,
            notifications,
            closeNotification,
            closeAllNotifications,
            isDashboard,
            data,
            userRegion,
            headerServerInfoLabel,
            headerServerInfoTimeTooltip,
            headerServerInfoRegionTooltip,
            isOpenModal,
        } = this.props;

        const userData = {
            username: this.userData.last_name || nameUser.username,
            avatar: this.userData.avatar_url || nameUser.avatar,
            email: this.userData.email || nameUser.email,
            createdAt: this.userData.created_at || nameUser.created_at,
        };

        return (
            <HeaderWrapper classNames="app-header">
                <NotificationsContainer />
                <a
                    href={config.landingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Logo src={logo} />
                </a>
                {isDashboard && (
                    <div className="icons-menu app-header__symbols">
                        <Tooltip label={this.props.symbolText} offset={3.1}>
                            <SymbolButton
                                size="large"
                                onClick={openSymbolsModal}
                                firstCurrency={selectedSymbol.numerator}
                                secondCurrency={selectedSymbol.denominator}
                            />
                        </Tooltip>
                        <Button icon="icon-open-order" onClick={this.openModal}>
                            {labelButtonOpenOrder}
                        </Button>
                    </div>
                )}

                <ServerInfo
                    isDashboard={isDashboard}
                    region={userRegion}
                    serverLabel={headerServerInfoLabel}
                    serverTimeTooltip={headerServerInfoTimeTooltip}
                    serverRegionTooltip={headerServerInfoRegionTooltip}
                />

                <div className="icons-menu">
                    <Button
                        innerLink={`${config.workspaceUrl}/billing/deposit-funds`}
                        icon="icon-purse"
                    >
                        {labelButtonDeposit}
                    </Button>
                    {isDashboard && (
                        <>
                            <Balance
                                balances={balances}
                                selectedSymbol={selectedSymbol}
                            />
                            <Tooltip
                                label={this.props.titleLayout}
                                offset={1.4}
                            >
                                <IconButton
                                    className="icon-layout"
                                    onClick={openLayoutModal}
                                />
                            </Tooltip>
                        </>
                    )}

                    <div className="faq-notif">
                        <Tooltip label={this.props.titleFAQ} offset={1.4}>
                            <Dropdown btn={<IconButton className="icon-faq" />}>
                                <DropdownWrapper>
                                    <DropdownItem>
                                        <a
                                            target="_blank"
                                            href={`${config.landingUrl}/academy`}
                                            className="with-border-bottom"
                                        >
                                            {this.props.tuitionText}
                                        </a>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <a
                                            target="_blank"
                                            href={`${config.landingUrl}/faq`}
                                        >
                                            {this.props.titleFAQ}
                                        </a>
                                    </DropdownItem>
                                </DropdownWrapper>
                            </Dropdown>
                        </Tooltip>
                        <Tooltip
                            label={this.props.titleNotification}
                            offset={1.4}
                        >
                            <Dropdown
                                btn={
                                    <IconButton
                                        isNew={!!notifications.data.length}
                                        className="icon-notifications"
                                        onClick={openFaqModal}
                                    />
                                }
                                isInsideClick
                            >
                                <div>
                                    <DropdownWrapper align="right">
                                        <Notification
                                            notifications={notifications}
                                            closeNotification={
                                                closeNotification
                                            }
                                            closeAllNotifications={
                                                closeAllNotifications
                                            }
                                            translation={{
                                                closeAll: this.props.closeAll,
                                                titleNotification: this.props
                                                    .titleNotification,
                                                noNotifLabel: this.props
                                                    .noNotifLabel,
                                            }}
                                        />
                                    </DropdownWrapper>
                                </div>
                            </Dropdown>
                        </Tooltip>
                    </div>
                    <Dropdown
                        btn={
                            <UserBlock>
                                {(this.state.integrationInfo &&
                                    this.state.integrationInfo.avatar) ||
                                userData.avatar ? (
                                    <Avatar
                                        src={
                                            (this.state.integrationInfo &&
                                                this.state.integrationInfo
                                                    .avatar) ||
                                            userData.avatar
                                        }
                                        alt="Avatar"
                                    />
                                ) : (
                                    <NoImage headerAvatar>
                                        {this.state.integrationInfo &&
                                        this.state.integrationInfo.name
                                            ? this.state.integrationInfo.name
                                                .slice(0, 2)
                                                .toUpperCase()
                                            : userData.username
                                                .slice(0, 2)
                                                .toUpperCase()}
                                    </NoImage>
                                )}
                                <UserBlockArrow className="icon-single-down-pointing-angle" />
                            </UserBlock>
                        }
                        isInsideClick
                    >
                        <div>
                            <DropdownWrapper
                                align="right"
                                offsetArrowRight={2.5}
                                offsetRight={2}
                            >
                                <UserBlockContent>
                                    {(this.state.integrationInfo &&
                                        this.state.integrationInfo.avatar) ||
                                    userData.avatar ? (
                                        <Avatar
                                            size="medium"
                                            src={
                                                (this.state.integrationInfo &&
                                                    this.state.integrationInfo
                                                        .avatar) ||
                                                userData.avatar
                                            }
                                            alt="Avatar"
                                        />
                                    ) : (
                                        <NoImage size="medium">
                                            {this.state.integrationInfo &&
                                            this.state.integrationInfo.name
                                                ? this.state.integrationInfo.name
                                                      .slice(0, 2)
                                                      .toUpperCase()
                                                : userData.username
                                                      .slice(0, 2)
                                                      .toUpperCase()}
                                        </NoImage>
                                    )}
                                    <UserInfos>
                                        <div>
                                            <div>
                                                {(this.state.integrationInfo &&
                                                    this.state.integrationInfo
                                                        .accountId) ||
                                                    userData.username}
                                            </div>
                                            <div>
                                                {(this.state.integrationInfo &&
                                                    this.state.integrationInfo
                                                        .name) ||
                                                    userData.email}
                                            </div>
                                        </div>
                                        <RegistrationInfo>
                                            <div>
                                                {this.props.registrationDate}
                                            </div>
                                            <div>
                                                {moment
                                                    .unix(userData.createdAt)
                                                    .format('DD/MM/YYYY')}
                                            </div>
                                        </RegistrationInfo>
                                    </UserInfos>
                                    <UserControls>
                                        <a
                                            href={`${config.workspaceUrl}/settings/general-info`}
                                        >
                                            <LinkIcon className="icon-profile" />
                                            {this.props.titleProfileSettings}
                                        </a>
                                        <UserButton onClick={this.logOut}>
                                            <LinkIcon className="icon-exit" />
                                            {this.props.logoutText}
                                        </UserButton>
                                    </UserControls>
                                </UserBlockContent>
                            </DropdownWrapper>
                        </div>
                    </Dropdown>
                </div>
                <LayoutModal />
                <FaqModal />
                <OrderModal
                    closeModal={this.closeModal}
                    labelButtonOpenOrder={labelButtonOpenOrder}
                    showOpenOrderModal={isOpenModal}
                />
                <SymbolsModal />
            </HeaderWrapper>
        );
    }

    openModal() {
        this.props.openExchange(true);
    }

    closeModal() {
        this.props.openExchange(false);
    }

    openSymbolsModal() {
        this.props.openSymbolsModal();
    }

    openLayoutModal() {
        this.props.openLayoutModal();
    }
    openFaqModal() {
        this.props.openFaqModal();
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Header)
);
