import styled from 'styled-components';

export const NotificationWrapper = styled.div`
    padding: 3.33rem;
    width: 57.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ScrollWrapper = styled.div`
    height: ${({empty, quantity}) =>
        (!empty && quantity > 5 && '88rem') ||
        (!empty && quantity && `${quantity * 15}rem`) ||
        '6rem'};
    width: calc(100% + 6.66rem);
    padding: 0 3.33rem;
    margin: 0 -3.33rem 4rem;
`;

export const Title = styled.div`
    font-size: 3rem;
    margin-bottom: 3rem;
    color: ${props => props.theme.color1};
`;

export const NotNotificationText = styled.div`
    text-align: center;
    margin: 3.3333rem 0;
    font-size: 2.3333rem;
    color: ${props => props.theme.color14};
`;


