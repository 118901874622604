export const ActionTypes = {
    notificationReceived: 'notification.received',
    closeNotification: 'notification.close',
    closeAllNotifications: 'notification.close-all',
    closePopupNotification: 'notification.popup.close',
    getNotificationsHistory: 'notification.history.get',
    errorNotificationsHistory: 'notification.history.error',
    notificationsHistorySuccess: 'notification.history.success',
    notificationTokenError: 'notification.token.error',
    notificationSocketError: 'notification.socket.error',
    notificationCloseError: 'notification.close.error',
    notificationCloseAllError: 'notification.close.all.error',
    NOTIFICATION_SOCKET_REQUEST: 'NOTIFICATION_SOCKET_REQUEST',
    NOTIFICATION_SOCKET_SUCCESS: 'NOTIFICATION_SOCKET_SUCCESS',
    NOTIFICATION_SOCKET_CLOSE: 'NOTIFICATION_SOCKET_CLOSE',
    NOTIFICATION_SOCKET_DISCONNECT: 'NOTIFICATION_SOCKET_DISCONNECT',
};

export function notificationReceived(notification, isVisible = true) {
    return {
        type: ActionTypes.notificationReceived,
        notification,
        isVisible,
    };
}

export function closeNotification(id) {
    return {
        type: ActionTypes.closeNotification,
        id,
    };
}

export function closeAllNotifications() {
    return {
        type: ActionTypes.closeAllNotifications,
    };
}

export function closePopupNotification(id) {
    return {
        type: ActionTypes.closePopupNotification,
        id,
    };
}

export function getNotificationsHistory() {
    return {
        type: ActionTypes.getNotificationsHistory,
    };
}

export function errorNotificationsHistory(payload) {
    return {
        type: ActionTypes.errorNotificationsHistory,
        payload,
    };
}

export function notificationsHistorySuccess(history) {
    return {
        type: ActionTypes.notificationsHistorySuccess,
        history,
    };
}

export function notificationTokenError(payload) {
    return {
        type: ActionTypes.notificationTokenError,
        payload,
    };
}

export function notificationSocketError(payload) {
    return {
        type: ActionTypes.notificationSocketError,
        payload,
    };
}

export function notificationCloseError(payload) {
    return {
        type: ActionTypes.notificationCloseError,
        payload,
    };
}

export function notificationCloseAllError(payload) {
    return {
        type: ActionTypes.notificationCloseAllError,
        payload,
    };
}

export function notificationSocketRequest() {
    return {
        type: ActionTypes.NOTIFICATION_SOCKET_REQUEST,
    };
}

export function notificationSocketSuccess() {
    return {
        type: ActionTypes.NOTIFICATION_SOCKET_SUCCESS,
    };
}

export function notificationSocketClose() {
    return {
        type: ActionTypes.NOTIFICATION_SOCKET_CLOSE,
    };
}

export function notificationSocketDisconnect() {
    return {
        type: ActionTypes.NOTIFICATION_SOCKET_DISCONNECT,
    };
}
