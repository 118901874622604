import React, {Component} from 'react';
import {connect} from 'react-redux';

import Panel from './components/Panel/Panel';
import SymbolsModal from './components/SymbolsModal/SymbolsModal';

import {setActiveView as setActiveViewAction} from '../../service/actions';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';

import Layout00 from './layout/layout-chart-00';
import Layout01 from './layout/layout-chart-01';
import Layout02 from './layout/layout-chart-02';
import Layout010 from './layout/layout-chart-010';
import Layout03 from './layout/layout-chart-03';
import Layout04 from './layout/layout-chart-04';
import Layout05 from './layout/layout-chart-05';
import {chartView} from '../Chart/service/actions';
import TVChartLayout from './components/TVChartLayout';
import {chartDXHistoryRequest} from '../../../../services/action/DXChartHistoryProvider';

function mapStateToProps(state) {
    const {isPending} = state.DXChartHistory;
    const {chartLayout} = state.settings;
    const {name} = state.settings.selectedSymbol;
    const {locale} = state.settings;

    return {
        isPending,
        locale,
        symbol: name,
        view: state.chart.chartView.chartView,
        activeLayoutIndex: chartLayout,
        title: parseTranslation('label-chart'),
    };
}

function mapDispatchToProps(dispatch) {
    function setActiveView() {
        dispatch(setActiveViewAction(null));
    }

    function chartDXHistoryRequestAction() {
        dispatch(chartDXHistoryRequest());
    }

    return {
        setActiveView,
        chartDXHistoryRequestAction,
    };
}

class ChartContainer extends Component {
    title = 'CHART';

    render() {
        const {view, symbol, locale, isPending, chartDXHistoryRequestAction} = this.props;

        return (
            <Panel
                className="chart-panel"
                title={this.props.title}
                closable
                closableHandler={this.props.setActiveView}
                chartView
                isLayoutChart={false}
                expandHandler={this.props.setActiveView}
            >
                {view === chartView.tradingView ? (
                    <TVChartLayout
                        symbol={symbol}
                        locale={locale}
                        isPending={isPending}
                        chartDXHistoryRequest={chartDXHistoryRequestAction}
                    />
                ) : (
                    <>
                        {this.props.activeView ? (
                            this.renderActiveView(this.props.activeView)
                        ) : (
                            <Layout02 />
                        )}
                    </>
                )}

                <SymbolsModal />
            </Panel>
        );
    }

    renderChartContainer(activeLayoutIndex) {
        switch (activeLayoutIndex) {
            case 0:
                return <Layout00 />;
            case 1:
                return <Layout02 />;
            case 2:
                return <Layout01 />;
            case 3:
                return <Layout010 />;
            case 4:
                return <Layout03 />;
            case 5:
                return <Layout04 />;
            case 6:
                return <Layout05 />;
            default:
                return null;
        }
    }
}

const ConnectedChartContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChartContainer);
export default ConnectedChartContainer;
