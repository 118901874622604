import { ActionTypes } from './actions';

const INITIAL_STATE = {
    isOpen: false,
    cryptoCurrencyFilter: '',
    findFilter: ''
};

function layoutModalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.open:
            return {
                ...state,
                isOpen: true,
                chartId: action.chartId
            };

        case ActionTypes.close:
            return {
                ...state,
                isOpen: false
            };

        case ActionTypes.setCryptoCurrencyFilter:
            return {
                ...state,
                cryptoCurrencyFilter: action.filter
            };

        case ActionTypes.setFindFilter:
            return {
                ...state,
                findFilter: action.filter
            };

        default:
            return state;
    }
}

export default layoutModalReducer;
