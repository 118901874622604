// @flow
import React, {PureComponent, type ReactNode} from 'react';

import {
    MessageItem,
    AvatarWrapper,
    Avatar,
    MessageItemWrapper,
    User,
    UserName,
    UserDate,
    MessageItemText,
} from 'infrastructure/view/ui/widgets/Chat';
import AvatarPic from 'img/svg/avatar.svg';
import parseTranslation from '../../../adapter/helper/parseTranslation';

type PropTypes = {
    user?: boolean,
    avatar?: string,
    shortenUsername?: string,
    date: string,
    time: string,
    username: string,
    children: ReactNode,
};

class ChatItem extends PureComponent<PropTypes> {
    render() {
        const {
            user,
            avatar,
            shortenUsername,
            date,
            time,
            username,
            children,
        } = this.props;
        const avatarImg = JSON.parse(localStorage.getItem('userRegion')) &&
            JSON.parse(localStorage.getItem('userRegion')).userInfo.avatar;
        console.log(avatarImg);
        if (user) {
            return (
                <MessageItem user>
                    {!avatarImg ? (
                        <AvatarWrapper withUsername>
                            <span>{shortenUsername}</span>
                        </AvatarWrapper>
                    ) : (
                        <AvatarWrapper>
                            <Avatar src={avatarImg || avatar} alt="avatar" />
                        </AvatarWrapper>
                    )}
                    <MessageItemWrapper>
                        <User>
                            <UserName>{this.props.translation && (this.props.translation.you || 'You')}:</UserName>
                            <UserDate>
                                <span>{date}</span>
                                <span>{time}</span>
                            </UserDate>
                        </User>
                        <MessageItemText>{children}</MessageItemText>
                    </MessageItemWrapper>
                </MessageItem>
            );
        } else {
            return (
                <MessageItem system>
                    <AvatarWrapper>
                        <Avatar src={avatar || AvatarPic} />
                    </AvatarWrapper>
                    <MessageItemWrapper>
                        <User>
                            <UserName>{username || parseTranslation('support-manager')}:</UserName>
                            <UserDate>
                                <span>{date}</span>
                                <span>{time}</span>
                            </UserDate>
                        </User>
                        <MessageItemText>{children}</MessageItemText>
                    </MessageItemWrapper>
                </MessageItem>
            );
        }
    }
}

export default ChatItem;
