import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';

import Panel from '../../../../component/Panel/Panel';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';

import Find from './component/Find';
import Popular from './component/Popular';
import MyChoice from './component/MyChoice';

import parseTranslation from '../../../../../adapter/helper/parseTranslation';

import {setActiveView as setActiveViewAction, setActiveTabSymbols} from '../../service/actions';

function mapStateToProps(state) {
    return {
        title: parseTranslation('label-symbols'),
        labelTabsPopular: parseTranslation('label-popular'),
        labelTabsMyChoice: parseTranslation('label-my-choice'),
        labelTabsFind: parseTranslation('label-find'),
        titleCurrencyPair: parseTranslation('label-curr-pair'),
        titlePopularPrice: parseTranslation('label-price'),
        titlePopularVolume: parseTranslation('label-volume'),
        titlePopularGrowth: parseTranslation('label-growth'),
        symbolsActiveTab: state.dashboard.symbolsActiveTab
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setActiveView: () => dispatch(setActiveViewAction(null)),
        setActiveTabSymbols: (payload) => dispatch(setActiveTabSymbols(payload))
    };
}

class Symbols extends Component {
    constructor() {
        super();

        this.renderActiveTab = this.renderActiveTab.bind(this);
        this.renderTabBtn = this.renderTabBtn.bind(this);
    }

    render() {
        const icon = (
            <button className="panel-button panel-left-icon">
                <i className="icon-symbols" />
            </button>
        );
        const {
            symbolsActiveTab,
            labelTabsPopular,
            labelTabsMyChoice,
            labelTabsFind,
            title,
            setActiveView
        } = this.props;

        return (
            <Panel
                icon={icon}
                title={title}
                closable
                closableHandler={setActiveView}
                className={
                    symbolsActiveTab !== 2
                        ? 'symbols-extended panel_symbols-expanded'
                        : 'symbols-extended panel_symbols-expanded-find'
                }
            >
                <Fragment>
                    <div className="tabs-component">
                        <div className="tabs-component__tabs">
                            {this.renderTabBtn(0, labelTabsPopular)}
                            {this.renderTabBtn(1, labelTabsMyChoice)}
                            {this.renderTabBtn(2, labelTabsFind)}
                            <div className="tabs-component__slider" />
                        </div>
                    </div>
                    {symbolsActiveTab !== 2 && this.renderTableHeader()}
                    <div
                        style={{
                            position: 'absolute',
                            top:
                                symbolsActiveTab !== 2
                                    ? '12rem'
                                    : '6.83rem',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: 'flex',
                        }}
                    >
                        <Scrollbars>{this.renderActiveTab()}</Scrollbars>
                    </div>
                </Fragment>
            </Panel>
        );
    }

    renderTableHeader() {
        const {
            titleCurrencyPair,
            titlePopularPrice,
            titlePopularVolume,
            titlePopularGrowth
        } = this.props;
        return (
            <table>
                <thead>
                    <tr
                        style={{
                            backgroundColor: 'transparent',
                            height: '4.17rem',
                            fontSize: '2.3rem',
                            width: '100%',
                        }}
                    >
                        <th
                            style={{
                                width: '30%',
                                textAlign: 'left',
                                paddingLeft: '2rem',
                            }}
                        >
                            {titleCurrencyPair}
                        </th>
                        <th style={{
                            width: '21%',
                            textAlign: 'right',
                            paddingLeft: '2rem'
                        }}>
                            {titlePopularPrice}
                        </th>
                        <th style={{
                            width: '25%',
                            textAlign: 'right',
                            paddingLeft: '2rem'
                        }}>
                            {titlePopularVolume}
                        </th>
                        <th style={{textAlign: 'right'}}>
                            {titlePopularGrowth}
                        </th>
                        <th style={{width: '9%'}}>
                            <i className="icon-star-empty" />
                        </th>
                    </tr>
                </thead>
            </table>
        );
    }

    renderTabBtn(activeTab, title) {
        const {symbolsActiveTab, setActiveTabSymbols} = this.props;
        return (
            <div
                className={classnames('tabs-component__tab', {
                    active: symbolsActiveTab === activeTab,
                })}
                onClick={() => setActiveTabSymbols(activeTab)}
            >
                {title}
            </div>
        );
    }

    renderActiveTab() {
        switch (this.props.symbolsActiveTab) {
            case 0:
                return <Popular disableTooltip />;
            case 1:
                return <MyChoice disableTooltip />;
            case 2:
                return <Find disableTooltip />;
            default:
                return null;
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Symbols);
