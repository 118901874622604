import styled from 'styled-components';
import {NavLink} from 'react-router-dom';

import colors from '../../theme/colors';

export const Button = styled.div`
    height: 5.83rem;
    background-color: ${colors.color3};    
    border: 1px solid ${colors.color8};
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.color5};
    align-self: center;
    cursor: default;
    font-size: 2.3rem;
`;

export const AccentText = styled.a`
    color: ${colors.color8};
    cursor: pointer;
    &:hover {
        color: #0DC962;
    }
`;
