import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'react-modal';
import classnames from 'classnames';
import Button from 'infrastructure/view/component/Button';

import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
// import FormattedPrice from '../../../../../../component/FormattedPrice/FormattedPrice';

import {fetchMarketTradesHistory} from 'infrastructure/view/scene/Dashboard/components/MarketTrades/service/actions';

import {
    close,
    setCryptoCurrencyFilter,
    setFindFilter,
    setSymbols,
} from './service/actions';
import {setSelectedSymbol} from '../../../../../../services/action/user';
import {setOrderForm} from 'infrastructure/view/scene/Dashboard/service/actions';
import parseTranslation from '../../../../../../../adapter/helper/parseTranslation';
import {
    setSelectedSymbol as setSymbol,
    sendSettingsData,
} from '../../../../../../services/action/settings';
import Preloader from 'infrastructure/view/component/Preloader';
import {
    clearClosedOrders,
    fetchClosedOrders,
} from '../../../TradingHistory/service/actions';
import {
    chartHistoryRequest,
    chartHistoryIsPending,
} from '../../../../../../services/action/DXChart';
import Error from '../../../../../../component/Error';
import {cleanupNewTick} from '../../../Chart/service/actions';

function mapStateToProps(state) {
    const {
        isOpen,
        cryptoCurrencyFilter,
        findFilter,
        modalSymbols,
    } = state.symbolsModal;

    const {
        selectedSymbol,
        dxChart: {chartInterval},
    } = state.settings;
    const {isPending} = state.symbols;

    const symbols = Object.values(state.symbols.symbols)
        .filter(symbol => {
            if (findFilter) return true;
            let filter = selectedSymbol.numerator;

            if (cryptoCurrencyFilter) {
                filter = cryptoCurrencyFilter;
            }
            return symbol.id.includes(filter);
        })
        // TODO: Merge this filter with previous one
        .filter(symbol => symbol.id.includes(findFilter.toUpperCase()));

    const currencies = [
        ...Object.keys(state.symbols.symbols).reduce((result, symbol) => {
            const [currNumerator, currDenominator] = symbol.split('/');

            result.add(currNumerator).add(currDenominator);

            return result;
        }, new Set()),
    ].filter(symbol => {
        let filter = findFilter.toUpperCase();

        if (findFilter.includes('/')) {
            filter = findFilter.split('/')[0].toUpperCase();
        }
        return symbol.includes(filter);
    });

    const selectedCurrency = cryptoCurrencyFilter
        ? cryptoCurrencyFilter
        : selectedSymbol.numerator;

    return {
        isPending,
        isOpen,
        symbols,
        socketError: state.socket.error,
        modalSymbols,
        currencies,
        findFilter,
        selectedSymbol,
        chartInterval,
        selectedCurrency,
        titleSymbolModal: parseTranslation('title-symbol-modal'),
        titleCurrencyPair: parseTranslation('label-curr-pair'),
        titlePopularPrice: parseTranslation('label-price'),
        titlePopularVolume: parseTranslation('label-volume'),
        titlePopularGrowth: parseTranslation('label-growth'),
        titleInput: parseTranslation('label-find'),
        cancelButton: parseTranslation('cancel-button'),
        activeSymbolName: parseTranslation('active-symbol'),
        activeSymbolBase: parseTranslation('active-symbol-base'),
        selectedSymbolName: parseTranslation('selected-symbol'),
        applyBtn: parseTranslation('apply-button'),
    };
}

const mapDispatchToProps = {
    close,
    setCryptoCurrencyFilter,
    setFindFilter,
    setSelectedSymbol,
    setSymbol,
    setOrderForm,
    sendSettingsData,
    setSymbols,
    clearClosedOrders,
    fetchClosedOrders,
    fetchMarketTradesHistory,
    chartHistoryRequest,
    chartHistoryIsPending,
    cleanupNewTick,
};

class Entries extends Component {
    render() {
        const {
            id,
            price,
            volume,
            change,
            selectedSymbol,
            modalSymbols,
            setSymbols,
        } = this.props;
        const [numerator, denominator] = id.split('/');
        let statusSymbol =
            modalSymbols === '' ? selectedSymbol.name : modalSymbols;
        return (
            <tr
                onClick={() => {
                    setSymbols(id);
                }}
                className={classnames(id === statusSymbol ? 'active' : '')}
            >
                <td className="symbols-pair-right">
                    <span className="symbols-pair">
                        <i className={`icon icon-${numerator.toLowerCase()}`} />
                        <span className="currency">{id}</span>
                        <i
                            className={`icon icon-${denominator.toLowerCase()}`}
                        />
                    </span>
                </td>
                <td>{price}</td>
                <td>{volume}</td>
                <td
                    className={
                        change >= 0 ? 'color-green' : 'color-red'
                    }
                >
                    {change && parseFloat(change.toFixed(2))}%
                </td>
            </tr>
        );
    }
}

class SymbolsModal extends Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.select = this.select.bind(this);
        this.state = {
            filterValue: '',
        }
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.isOpen || this.props.isOpen;
    }

    componentDidMount() {
        this.props.setSelectedSymbol(this.props.selectedSymbol);
    }

    render() {
        const {
            isPending,
            symbols,
            selectedSymbol,
            setSymbol,
            setSymbols,
            currencies,
            isOpen,
            titleSymbolModal,
            titleInput,
            findFilter,
            selectedCurrency,
            setCryptoCurrencyFilter,
            modalSymbols,
            cancelButton,
            activeSymbolName,
            selectedSymbolName,
            applyBtn,
            activeSymbolBase,
            socketError,
        } = this.props;

        return (
            <>
                {selectedSymbol.numerator &&
                    <Modal
                        isOpen={isOpen}
                        contentLabel="Symbols Modal"
                        className="modal symbols"
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(20, 22, 27, 0.65)',
                                display: 'flex',
                                zIndex: 100,
                            },
                        }}
                    >
                        <div className="modal-header">
                            <span>{titleSymbolModal}</span>
                            <button type="button" onClick={this.close}>
                                <i className="icon-close" />
                            </button>
                        </div>

                        <div className="symbols__search">
                            <div className="symbols__info">
                                <div className="symbols__info-item">
                                    <p className="symbols__info-title">
                                        {activeSymbolName}
                                    </p>
                                    <div className="symbols__info-currency">
                                        <i
                                            className={`icon-currency icon-${selectedSymbol.numerator.toLowerCase()}`}
                                        />
                                        <span>{selectedSymbol.name}</span>
                                        <i
                                            className={`icon-currency icon-${selectedSymbol.denominator.toLowerCase()}`}
                                        />
                                    </div>
                                </div>
                                <i className="icon-arrow-symbols" />
                                <div className="symbols__info-item">
                                    <p className="symbols__info-title">
                                        {selectedSymbolName}
                                    </p>
                                    <div className="symbols__info-currency">
                                        {modalSymbols !== '' && (
                                            <>
                                                <i
                                                    className={`icon-currency icon-${modalSymbols
                                                        .split('/')[0]
                                                        .toLowerCase()}`}
                                                />
                                                <span>{modalSymbols}</span>
                                                <i
                                                    className={`icon-currency icon-${modalSymbols
                                                        .split('/')[1]
                                                        .toLowerCase()}`}
                                                />
                                            </>
                                        )}
                                        &nbsp;
                                    </div>
                                </div>
                            </div>
                            <div className="filter">
                                {this.state.filterValue ? <i className="icon-close" onClick={this.clearFilter} /> : <i className="icon-find" />}
                                <input
                                    type="text"
                                    className="filter__item"
                                    placeholder={titleInput}
                                    value={findFilter}
                                    onChange={this.setFilter}
                                />
                            </div>
                        </div>

                        <div className="symbols-modal-content">
                            {isPending && !socketError ? (
                                <Preloader />
                            ) : (
                                <>
                                    {socketError && <Error />}
                                    {!socketError && (
                                        <>
                                            <div className="currency-crypto">
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                    }}
                                                >
                                                    <Scrollbars>
                                                        <div
                                                            style={{
                                                                position: 'relative',
                                                                height: '100%',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <div className="currency-crypto__title">
                                                                {activeSymbolBase}
                                                            </div>
                                                            <ul>
                                                                {!!currencies.length &&
                                                                currencies.map(
                                                                    currency => (
                                                                        <li
                                                                            className={classnames(
                                                                                {
                                                                                    selected:
                                                                                        currency ===
                                                                                        selectedCurrency,
                                                                                }
                                                                            )}
                                                                            key={
                                                                                currency
                                                                            }
                                                                        >
                                                                            <i
                                                                                className={`icon-currency icon-${currency.toLowerCase()}`}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                onClick={() =>
                                                                                    setCryptoCurrencyFilter(
                                                                                        currency
                                                                                    )
                                                                                }
                                                                            >
                                                                                <span>
                                                                                    {
                                                                                        currency
                                                                                    }
                                                                                </span>
                                                                            </button>
                                                                            <i className="icon-single-right-pointing-angl" />
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </Scrollbars>
                                                </div>
                                            </div>
                                            <div className="search-currency">
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0,
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Scrollbars>
                                                        <table>
                                                            {this.renderTableHeader()}
                                                            <tbody>
                                                            {!!symbols.length &&
                                                            symbols.map(
                                                                (
                                                                    symbol,
                                                                    index
                                                                ) => (
                                                                    <Entries
                                                                        key={
                                                                            index
                                                                        }
                                                                        {...symbol}
                                                                        modalSymbols={
                                                                            modalSymbols
                                                                        }
                                                                        selectedSymbol={
                                                                            selectedSymbol
                                                                        }
                                                                        setSymbol={
                                                                            setSymbol
                                                                        }
                                                                        setSymbols={
                                                                            setSymbols
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                            </tbody>
                                                        </table>
                                                    </Scrollbars>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="symbols__footer">
                            <button className="secondary-button" onClick={this.close}>{cancelButton}</button>
                            <button className="main-button" onClick={this.select}>{applyBtn}</button>
                        </div>
                    </Modal>
                }

                </>
        );
    }

    componentWillUnmount() {
        this.props.setSymbols('');
    }

    renderTableHeader() {
        return (
            <thead>
                <tr>
                    <th className="sticky-header">
                        {this.props.titleCurrencyPair}
                    </th>
                    <th className="sticky-header">
                        {this.props.titlePopularPrice}
                    </th>
                    <th className="sticky-header">
                        {this.props.titlePopularVolume}
                    </th>
                    <th className="sticky-header">
                        {this.props.titlePopularGrowth}
                    </th>
                </tr>
            </thead>
        );
    }

    close() {
        const {close, setOrderForm, setFindFilter} = this.props;
        close();
        setOrderForm({
            price: '',
            amount: '',
        });
        setFindFilter('');
    }

    setFilter(e) {
        this.props.setFindFilter(e.target.value);
        this.setState({filterValue: e.target.value})
    }

    clearFilter() {
        this.props.setFindFilter('');
        this.setState({filterValue: ''})
    }

    select() {
        const {
            selectedSymbol,
            chartInterval,
            setSymbol,
            modalSymbols,
            sendSettingsData,
            setSelectedSymbol,
            setFindFilter,
            fetchMarketTradesHistory,
            page,
            clearClosedOrders,
            fetchClosedOrders,
            chartHistoryRequest,
            chartHistoryIsPending,
            cleanupNewTick,
        } = this.props;
        const [numerator, denominator] = modalSymbols.split('/');

        if (selectedSymbol.name !== modalSymbols && modalSymbols !== '') {
            // fetchMarketTradesHistory(page, modalSymbols);
            setSymbol({numerator, denominator});
            sendSettingsData();
            setSelectedSymbol({numerator, denominator});
            // clearClosedOrders();
            // fetchClosedOrders(1);
            cleanupNewTick();
            // chartHistoryIsPending();
            chartHistoryRequest({
                symbol: modalSymbols,
                resolution: chartInterval,
                limit: 1000,
            });
        }

        this.close();
        setFindFilter('');
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SymbolsModal);
