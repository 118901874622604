import styled from 'styled-components';

export const headerBg = '#E5E5E5';
export const headerColor = '#000000';
export const bodyBg = '#fff';
export const buttonColor = '#fff';
export const modalOverlay = 'rgba(0, 0, 0, 0.25)';
export const closeColorHover = '#000000';
export const closeColor = '#000000';

export const ModalWrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: ${modalOverlay};
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const ModalHolder = styled.div`
  width: ${({width}) => width}rem;
  box-shadow: 0 0 3rem rgba(0,0,0,.5);
  border: 1px solid rgba(0,0,0,0.9);
  border-radius: 0;
  overflow: hidden;
`;
export const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    height: 6.6rem;
    padding: 0 3rem;
    font-weight: 500;
    background-color: ${headerBg};    
    color: ${headerColor};
    font-size: 3rem;    
`;
export const ModalBody = styled.div`
  background: ${bodyBg};  
`;
export const ModalClose = styled.i`
  font-size: 2.6rem;
  color: ${closeColor};
  cursor:pointer;
  &:hover {
    color: ${closeColorHover} !important; //fucking old styles
  }
`;


