// @flow
import type Axios from 'axios';
import localConfig from 'localConfig';
import {getDomain} from 'utils';

import {Ticket} from '../../domain/entitiy/Ticket';

import type {AccessToken} from '../../domain/entitiy/AccessToken';
import type {TicketsAdapterInterface} from '../../application/adapter/Tickets';

class TicketsAdapter implements TicketsAdapterInterface {
    apiClient: Axios;

    constructor(apiClient: Axios) {
        this.apiClient = apiClient;
    }

    async getHistory(token: AccessToken, locale: string): Promise<any> {
        const {data} = await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/ticket/history`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`,
            },
        });

        const tickets = data.map(rawTicket => {
            const ticket = new Ticket(rawTicket);
            return ticket;
        });

        return tickets;
    }

    async getTicket(token: AccessToken, id: number, locale: string): Promise<any> {
        const {data} = await this.apiClient({
            method: 'GET',
            url: `${getDomain(localConfig.apiUrl)}/v1/ticket/get`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`,
            },
            params: {id},
        });

        const ticket = new Ticket(data);

        return ticket;
    }

    async getTicketImageVerification(token: AccessToken, code: string): Promise<any> {
        return await this.apiClient({
            method: 'GET',
            url: `${getDomain(localConfig.apiUrl)}/v1/ticket/get-image/passport/${code}`,
            responseType: 'blob',
            headers: {
                'content-type': 'application/json',
                authorization: `Bearer ${token}`,
            },
        });
    }

    async replyToTicket(
        token: AccessToken,
        id: number,
        message: string
    ): Promise<any> {
        const {data} = await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/ticket/reply`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
            },
            params: {id},
            data: {message},
        });

        const ticket = new Ticket(data);

        return ticket;
    }

    async getForm(
        token: AccessToken,
        type: string,
        locale: string
    ): Promise<any> {
        const {data: form} = await this.apiClient({
            method: 'PUT',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/${type}`,
            headers: {
                'content-type': 'application/json',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`,
            },
        });

        return form;

    }

    async sendForm(
        token: AccessToken,
        type: string,
        formData: {},
        locale: string
    ): Promise<any> {
        return await this.apiClient({
            method: 'POST',
            url: `${getDomain(localConfig.apiUrl)}/v1/form/${type}`,
            headers: {
                'content-type': 'multipart/form-data',
                'cache-control': 'no-cache',
                authorization: `Bearer ${token}`,
                'Accept-Language': `${locale}; q=1`,
            },
            data: formData,
        });
    }
}

export default TicketsAdapter;
