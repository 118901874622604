// @flow
import {ActionTypes, type Action} from './action';
import type {Moment} from 'moment';

export type Room = {
    roomId: string,
    username: string,
    avatar: string,
    createTime: Moment,
};

export type MessageTypes =
    | 'new_message'
    | 'typing_show'
    | 'typing_hide'
    | 'typing_stop'
    | 'join_editor'
    | 'join_viewer'
    | 'leave_editor'
    | 'leave_viewer'
    | 'close_chat'
    | 'room'
    | 'join_creator'
    | 'client_leave_chat_info'
    | 'editor_removed_from_chat';

export type Message = {
    type?: MessageTypes,
    data: string,
    username: string,
    avatar: string,
    createTime: {
        date: string,
        time: string,
    },
};

export type IncomingTypingStatus = {
    username: string,
    status: boolean,
};

export type State = {
    isConnected: boolean,
    isActive: boolean,
    accessChatData: string,
    room: Room,
    messages: Message[],
    incomingTypingStatus: IncomingTypingStatus,
    messageText: string,
};

const INITIAL_STATE: State = {
    isConnected: false,
    isActive: false,
    accessChatData: '',
    accessChatDataError: null,
    room: {},
    messages: [
        {
            id: 'msg-0',
            avatar: '',
            username: '',
            createTime: {
                date: '',
                time: '',
            },
            data: '',
        },
    ],
    incomingTypingStatus: {
        username: '',
        status: false,
    },
    messageText: '',
    socketError: null,
};

function reducer(state: State = INITIAL_STATE, action: Action): State {
    switch (action.type) {
        case ActionTypes.setAccessChat:
            return {
                ...state,
                accessChat: action.accessChat,
            };

        case ActionTypes.errorAccessChat:
            return {
                ...state,
                accessChat: '',
                accessChatDataError: action.payload,
            };

        case ActionTypes.setRoom:
            return {
                ...state,
                room: action.room,
            };

        case ActionTypes.setMessage:
            return {
                ...state,
                messages: [...state.messages, action.message],
            };

        case ActionTypes.setMessageText:
            return {
                ...state,
                messageText: action.messageText,
            };

        case ActionTypes.setTypingStatus:
            return {
                ...state,
                incomingTypingStatus: action.incomingTypingStatus,
            };

        case ActionTypes.removeChat:
            return {
                ...state,
                messages: [state.messages[0]],
                room: {},
            };
        case ActionTypes.closeRoom:
            return {
                ...state,
                isActive: false,
            };

        case ActionTypes.setChatStatus:
            return {
                ...state,
                isActive: action.status,
            };

        case ActionTypes.setConnection:
            return {
                ...state,
                isConnected: true,
            };

        case ActionTypes.socketError:
            return {
                ...state,
                socketError: action.payload,
            };

        default:
            return state;
    }
}

export default {'module.chat': reducer};
