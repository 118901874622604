import React, {PureComponent} from 'react';
import styled from 'styled-components';
import colors from 'infrastructure/view/styles/common-colors/common-colors';
import parseTranslation from '../../../adapter/helper/parseTranslation';

class TableTooltip extends PureComponent {
    render() {
        const {price, volume, symbol, change, positionTooltip} = this.props;

        return (
            <ContentTooltip positionTooltip={positionTooltip}>
                <div className="currency-line">
                    <span>{parseTranslation('label-symbol')}</span> <div>{symbol}</div>
                </div>
                <div className="currency-line">
                    <span>{parseTranslation('label-price')}</span> <div>{price}</div>
                </div>
                <div className="currency-line">
                    <span>{parseTranslation('label-volume')}</span> <div>{volume}</div>
                </div>
                <div className="currency-line">
                    <span>{parseTranslation('label-growth')}</span> <div>{change}</div>
                </div>
            </ContentTooltip>
        );
    }
}


export default TableTooltip;

const ContentTooltip = styled.div`
    display: table;
    position: relative;
    left: ${props => props.positionTooltip === 'left' ? ' 2.6rem' : ' -3.3rem'};
    right: 0;    
    bottom: 0;
    background: ${colors.color20};    
    border: 1px solid ${colors.color1};
    box-shadow: 0 0 20px ${colors.color66};
    font-size: 2.5rem;
    color: ${colors.color14};
    width: 27rem;
    padding: 10px 20px;
    &:hover {
        a {
            color: $color20;
        }        
    }
    .currency-line {
        display: table-row;        
        .icon-currency-left {
            padding-right: 1rem;
        }
        .icon-currency-right {
            padding-left: 1rem;
        }
        & > span {
            padding-right: 2rem;
        }
        & > div,
        & > span {            
            display: table-cell;
        }
    }

    span:not(.currency-pair-title) {        
        width: auto;
        padding-right: 2rem;
        line-height: 2.5rem;
    }

    span:nth-child(2) {
        display: flex;        
    }

    &::after {
        content: ' ';
        position: absolute;
        top: 43%;
        left: ${props => props.positionTooltip === 'left' ? '94%' : 0};
        transform: translateX(${props => props.positionTooltip === 'left' ? '50%' : '-50%'}) rotate(${props => props.positionTooltip === 'left' ? '-224deg' : '-45deg'});
       
        display: block;
        width: 2rem;
        height: 2rem;
        border: 1px solid ${colors.color1};
        border-bottom: none;
        border-right: none;
        background-color: ${colors.color20};
    }
`;
