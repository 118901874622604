export const intervals = (t) => [
    {title: t['1M'], value: '1M'},
    {title: t['5M'], value: '5M'},
    {title: t['15M'], value: '15M'},
    {title: t['30M'], value: '30M'},
    {title: t['1H'], value: '1H'},
];
export const chartTypes = (t) => [
    {title: t.candle, value: 'candle', icon: 'icon-candlestick'},
    {title: t.line, value: 'line', icon: 'icon-line'},
    {title: t.bar, value: 'bar', icon: 'icon-bars'},
    {title: t.area, value: 'area', icon: 'icon-area'},
];
