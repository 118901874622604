// @flow
import moment from 'moment';

type RawTicketStatus = 0 | 1 | 2 | 3 | 4 | 5;

type RawTicketType = {
    name: string,
    slug: string,
};

type RawTicket = {
    id: number,
    status: RawTicketStatus,
    title: string,
    type: RawTicketType,
    updated_at: number,
    comments: [] | null,
    additional: string,
};

type TicketStatus =
    | 'rejected'
    | 'new'
    | 'pending'
    | 'processed'
    | 'wait'
    | 'confirmed'
    | 'unknown';

function mapTicketStatus(status: RawTicketStatus): TicketStatus {
    switch (status) {
        case 0:
            return 'rejected';
        case 1:
            return 'new';
        case 2:
            return 'pending';
        case 3:
            return 'processed';
        case 4:
            return 'wait';
        case 5:
            return 'confirmed';
        default:
            return 'unknown';
    }
}

// TODO: Add AdditionalInfo entity the same way as RawTicket
function mapAdditionalInfo(info: string): {} {
    const mappedInfo = JSON.parse(info);
    return mappedInfo;
}

export class Ticket {
    id: number;
    status: TicketStatus;
    title: string;
    type: string;
    category: string;
    updatedAt: moment;
    replies: [];
    additionalInfo: {};

    constructor(rawTicket: RawTicket) {
        this.id = rawTicket.id;
        this.status = mapTicketStatus(rawTicket.status);
        this.title = rawTicket.title;
        this.type = rawTicket.type ? rawTicket.type.slug : '';
        this.category = rawTicket.type.name;
        this.updatedAt = moment.unix(rawTicket.updated_at);
        this.replies = rawTicket.comments || null;
        this.additionalInfo = rawTicket.additional;

    }
}
