// @flow
import DealingAdapter from '../../infrastructure/adapter/Dealing';

class CloseOrder {
    dealingAdapter: DealingAdapter;

    constructor(dealingAdapter: DealingAdapter) {
        this.dealingAdapter = dealingAdapter;
    }

    invoke(asset: string, type: number, orderId: string): void {
        try {
            let orderType = 1;
            if (type === 'Buy') {
                orderType = 2;
            }

            this.dealingAdapter.closeOrder(asset, orderType, orderId);
        } catch (error) {
            throw error;
        }
    }
}

export default CloseOrder;
