export const ActionTypes = {
           setActivityTimer: 'set.session.activity.timer',
           setConfirmationTimer: 'set.session.confirmation.timer',
           setSessionServerTimer: 'set.session.server.timer',
           startActivityTimer: 'start.session.activity.timer',
           clearActivityTimer: 'clear.session.activity.timer',
           startConfirmTimer: 'start.session.confirm.timer',
           clearConfirmTimer: 'clear.session.confirm.timer',
           setPingServerSession: 'set.session.ping.server',
           startIntervalUpdateSession: 'start.interval.update.session',
           POST_UPDATE_SERVER_SESSION_REQUEST: 'POST_UPDATE_SERVER_SESSION_REQUEST',
           POST_UPDATE_SERVER_SESSION_SUCCESS: 'POST_UPDATE_SERVER_SESSION_SUCCESS',
           POST_UPDATE_SERVER_SESSION_FAILURE: 'POST_UPDATE_SERVER_SESSION_FAILURE',
           START_ABSOLUTE_SESSION_TIMER: 'START_ABSOLUTE_SESSION_TIMER',
           CLEAR_ABSOLUTE_SESSION_TIMER: 'CLEAR_ABSOLUTE_SESSION_TIMER',
           SET_ABSOLUTE_SESSION_TIMER: 'SET_ABSOLUTE_SESSION_TIMER',
           START_INTERVAL_UPDATE_SESSION: 'START_INTERVAL_UPDATE_SESSION',
       };

export function startActivityTimer() {
    return{
        type: ActionTypes.startActivityTimer
    }
}

export function clearActivityTimer() {
    return{
        type: ActionTypes.clearActivityTimer
    }
}

export function startConfirmTimer() {
    return{
        type: ActionTypes.startConfirmTimer
    }
}

export function clearConfirmTimer() {
    return{
        type: ActionTypes.clearConfirmTimer
    }
}

export function setActivityTimer(payload) {
    return {
        type: ActionTypes.setActivityTimer,
        payload
    }
}

export function setConfirmationTimer(payload) {
    return {
        type: ActionTypes.setConfirmationTimer,
        payload
    }
}

export function setPingServerSession(payload) {
    return{
        type: ActionTypes.setPingServerSession,
        payload
    }
}

export function postUpdateServerSessionRequest() {
    return {
        type: ActionTypes.POST_UPDATE_SERVER_SESSION_REQUEST,
    }
}

export function postUpdateServerSessionSuccess(payload) {
    return {
        type: ActionTypes.POST_UPDATE_SERVER_SESSION_SUCCESS,
        payload
    }
}

export function postUpdateServerSessionFailure(payload) {
    return {
        type: ActionTypes.POST_UPDATE_SERVER_SESSION_FAILURE,
        payload
    }
}

export function startAbsoluteSessionTimer() {
    return {
        type: ActionTypes.START_ABSOLUTE_SESSION_TIMER,
    }
}

export function clearAbsoluteSessionTimer() {
    return{
        type: ActionTypes.CLEAR_ABSOLUTE_SESSION_TIMER
    }
}

export function setAbsoluteSessionTimer(payload) {
    return{
        type: ActionTypes.SET_ABSOLUTE_SESSION_TIMER,
        payload,
    }
}

export function startIntervalUpdateSession() {
    return{
        type: ActionTypes.START_INTERVAL_UPDATE_SESSION,
    }
}
