import React from 'react';

const QuestionImages = ({questionImages, onOpenPreviewImage}) => {
    const onOpenPreviewButtonClick = imagePreviewUrl => () => {
        onOpenPreviewImage(imagePreviewUrl);
    };

    return (
        <div className="tickets-image-preview">
            <div className="tickets-image-preview__counter">
                <i className="icon icon-attach-file" />
                {`${questionImages.length} files`}
            </div>
            <div className="tickets-image-preview__wrap-list">
                {questionImages &&
                questionImages.map(imageUrl => (
                    <div className="tickets-image-preview__wrap">
                        <button
                            className={
                                'tickets__control-btn tickets__control-btn_preview'
                            }
                            type={'button'}
                            onClick={onOpenPreviewButtonClick(imageUrl)}
                        >
                            <i className="icon show-password icon-look" />
                        </button>
                        <img
                            className="tickets-image-preview__item"
                            src={imageUrl}
                            alt="img"
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuestionImages;
