export const ActionTypes = {
    openOrder: 'dashboard-scene.header-component.order-form.open-order'
};

export function openOrder(asset, price, amount, exType, type) {
    return {
        type: ActionTypes.openOrder,
        payload: {
            asset,
            price,
            amount,
            exType,
            type
        }
    };
}
