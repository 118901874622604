import {ActionType} from '../action/avatar';

const initialState = {
    modalState: false,
    deleteModalState: false,
};

function avatarReducer(state = initialState, action) {
    switch (action.type) {
        case ActionType.SHOW_MODAL:
            return {...state, modalState: true};

        case ActionType.HIDE_MODAL:
            return {...state, modalState: false};

        case ActionType.HIDE_DELETE_MODAL:
            return {...state, deleteModalState: false};

        case ActionType.SHOW_DELETE_MODAL:
            return {...state, deleteModalState: true};

        default:
            return state;
    }
}

export default avatarReducer;
