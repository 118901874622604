// @flow
class GetLanguage {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(accessToken: string): Promise<any> {
        return await this.adapter.getLanguage(accessToken);
    }
}

export default GetLanguage;
