import React, {useEffect, useState, useRef} from 'react';
import {Wrapper, List, Button, ListItem,ListItemIcon} from './styled';

export default function DropDown({onChange, list, button, selectedItem, activeHandler}) {
    const wrapperRef = useRef(null);
    const [visibility, setVisibility] = useState(false);
    const handleClick = () => setVisibility(!visibility);
    const [newSelectedItem, setNewSelectedItem] = useState(selectedItem.value);
    const handleChange = value => () => {
        onChange(value);
        setVisibility(false);
        setNewSelectedItem(value);
    };
    useEffect(() => {
        activeHandler && activeHandler(visibility)
    }, [visibility]);
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setVisibility(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    return (
        <Wrapper ref={wrapperRef}>
            <Button active={visibility} onClick={handleClick}>{button}</Button>
            {visibility && (
                <List>
                    {list &&
                        list.map(item => (
                            <ListItem
                                active={item.value === newSelectedItem}
                                onClick={handleChange(item.value)}
                            >
                                {item.icon && <ListItemIcon className={item.icon} />}
                                {item.title}
                            </ListItem>
                        ))}
                </List>
            )}
        </Wrapper>
    );
}
