// @flow
import type { AccessToken } from '../../../domain/entitiy/AccessToken';
import type { BillingAdapterInterface as BillingAdapter }  from '../../../application/adapter/Billing';

class GetDepositForm {
    billingAdapter: BillingAdapter;

    constructor(billingAdapter: BillingAdapter) {
        this.billingAdapter = billingAdapter;
    }

    async invoke(token: AccessToken, formData: {} = {}, locale: string) {
        try {
            const { data } = await this.billingAdapter.getDepositForm(token, formData, locale);
            return data;
        } catch (e) {
            throw e
        }
    }
}

export default GetDepositForm;
