// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import App from './infrastructure/view/App';
import TagManager from 'react-gtm-module';
import registerServiceWorker from './registerServiceWorker';

import 'react-virtualized/styles.css';
import './infrastructure/view/styles/index.scss';

import './infrastructure/polyfill/string.prototype.supplant.polyfil';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
