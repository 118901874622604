import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { onSound, sendSettingsData } from 'infrastructure/view/services/action/settings';

import {Nav, NavWrapper, NavIcon, Icon, NavBottomWrapper, NavLinkIcon, NavLinkIconStatic} from './styled';
import Language from '../Language';
import Tooltip from '../Header/Tooltip';
import parseTranslation from '../../../adapter/helper/parseTranslation';
import config from '../../../../localConfig';

const mapStateToProps = state => {
    const {locale} = state.settings;
    const {language} = state.translation;
    const {enable} = state.fullscreen;
    const { isSoundOn } = state.settings;
    return {
        trading: parseTranslation('label-dashboard'),
        billing: parseTranslation('label-billing'),
        tickets: parseTranslation('label-tickets'),
        chat: parseTranslation('label-chat'),
        settings: parseTranslation('label-settings'),
        listing: parseTranslation('label-listing'),
        sound: parseTranslation('label-sound'),
        fullscreen: parseTranslation('label-fullscreen'),
        transactions: parseTranslation('billing-transaction-history'),
        profile: parseTranslation('button-my-profile'),
        locale,
        language,
        isFullscreenEnabled: enable,
        isSoundEnabled: isSoundOn
    };
};

const mapDispatchToProps = {
    onSound,
    sendSettingsData,
};

const NavBar = ({isSoundEnabled, onSound, sendSettingsData,
    trading,
    billing,
    tickets,
    chat,
    settings,
    listing,
    sound,
    fullscreen,
    transactions,
    profile
}) => {
    const [isFullscreen, changeFullscreen] = useState(false);
    useEffect(() => {
        window.addEventListener('resize', detectScreenChange);
        return () => window.removeEventListener('resize', detectScreenChange);
    }, []);


    const detectScreenChange = () => {
        const isFullScreen =
            Math.ceil(window.screen.height / 10) * 10 ===
            Math.ceil(window.innerHeight / 10) * 10;
        changeFullscreen(!!isFullScreen);
    };

    const elem = document.documentElement;

    function openFullscreen() {
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }

    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    const toggleFullscreen = () => {
        if (isFullscreen) {
            closeFullscreen();
            changeFullscreen(false);
        } else {
            openFullscreen();
            changeFullscreen(true);
        }
    };

    const checkActive = (match, location) => {
        if (!location) return false;
        const {pathname} = location;

        return (
            pathname === '/settings/profile' ||
            pathname === '/settings/security'
        );
    };

    const handleSound = () => {
        onSound();
        sendSettingsData();

    };

    return (
        <Nav>
            <NavWrapper>
                <Tooltip label={profile} offset={0} direction="right">
                    <NavLinkIconStatic className="icon-profile" href={config.workspaceUrl} />
                </Tooltip>
                <Tooltip label={trading} offset={0} direction="right">
                    <NavLinkIcon className="icon-trading" to="/dashboard"/>
                </Tooltip>
                <Tooltip label={billing} offset={0} direction="right">
                    <NavLinkIconStatic className="icon-billing" href={`${config.workspaceUrl}/billing/payment-history`} />
                </Tooltip>
                <Tooltip label={transactions} offset={0} direction="right">
                    <NavLinkIcon className="icon-transaction" to="/billing"/>
                </Tooltip>
                <Tooltip label={tickets} offset={0} direction="right">
                    <NavLinkIconStatic className="icon-tickets" href={`${config.workspaceUrl}/tickets`}/>
                </Tooltip>
                {/*<Tooltip label={chat} offset={0} direction="right">*/}
                {/*    <NavLinkIcon className="icon-chat-menu" to="/chat"/>*/}
                {/*</Tooltip>*/}
                <Tooltip label={settings} offset={0} direction="right">
                    <NavLinkIconStatic
                        className="icon-chart-settings"
                        href={`${config.workspaceUrl}/settings/general-info`}
                        isActive={checkActive}
                    />
                </Tooltip>
                <Tooltip label={listing} offset={0} direction="right">
                    <NavLinkIconStatic className="icon-listing" href={`${config.workspaceUrl}/tickets/create/listing`} />
                </Tooltip>
            </NavWrapper>
            <NavBottomWrapper>
                <Tooltip label={sound} offset={0} direction="right">
                    <NavIcon
                        className={
                            isSoundEnabled ? "icon-sound-on" : "icon-sound-off"
                        }
                        onClick={handleSound}
                    />
                </Tooltip>
                <Tooltip label={fullscreen} offset={0} direction="right">
                    <Icon
                        className={
                            isFullscreen ? 'icon-resize' : 'icon-full-size'
                        }
                        onClick={toggleFullscreen}
                    />
                </Tooltip>
                <Language isNavBar />
            </NavBottomWrapper>
        </Nav>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
