import React from 'react';
import {isEmpty} from 'lodash';

import {Balance, BalanceLabel, BalanceValue, FirstBalance, SecondBalance} from './styled';

export default ({balances, selectedSymbol}) => {
  return (
     <Balance>
         <FirstBalance>
             <BalanceValue>
                 {!isEmpty(balances) && balances[selectedSymbol.numerator]
                     ? balances[selectedSymbol.numerator].available
                     : '0.00'}
             </BalanceValue>
             <BalanceLabel>{selectedSymbol.numerator}</BalanceLabel>
         </FirstBalance>
         <SecondBalance>
             <BalanceValue>
                 {!isEmpty(balances) && balances[selectedSymbol.denominator]
                     ? balances[selectedSymbol.denominator].available
                     : '0.00'}
             </BalanceValue>
             <BalanceLabel>{selectedSymbol.denominator}</BalanceLabel>
         </SecondBalance>
     </Balance>
 );
};
