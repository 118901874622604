import {ActionTypes} from './actions';

const INITIAL_STATE = {
    buy: [],
    sell: [],

    isTableBuyVisible: true,
    isTableSellVisible: true,

    isBuyTablePinnedToBottom: false,
    isPanging: true,
};

function DOMReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.updateDOM:
            return {
                ...state,
                ...action.orders,
                isPanging: false,
            };
        case ActionTypes.setDOMLoading:
            const {value} = action;
            return {
                ...state,
                ...action.orders,
                isPanging: value,
            };

        case ActionTypes.clearDOM:
            return {
                ...state,
                buy: [],
                sell: [],
            };

        case ActionTypes.setIsBuyTablePinnedToBottom:
            return {
                ...state,
                isBuyTablePinnedToBottom: action.isBuyTablePinnedToBottom,
            };

        case ActionTypes.updateTablesVisibility:
            return {
                ...state,
                isTableBuyVisible: action.isTableBuyVisible,
                isTableSellVisible: action.isTableSellVisible,
            };

        default:
            return state;
    }
}

export default DOMReducer;
