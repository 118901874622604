import styled from 'styled-components';

export const Balance = styled.div`
    display: flex;
    align-items: center;
    background: ${props => props.theme.color5};    
    color: ${props => props.theme.color14};    
    height: 8.333rem;
    padding: 0 4rem;
    margin: 0 3rem 0 2rem;
`;

export const FirstBalance = styled.div`
    text-align: right;
    padding-right: 2rem;
    border-right: 0.16rem solid ${props => props.theme.color14};
    min-width: 15rem;
`;
export const SecondBalance = styled.div`
    padding-left: 2rem;
    min-width: 15rem;
`;

export const BalanceValue = styled.div`
    font-size: 3rem;
`;
export const BalanceLabel = styled.div`
    font-size: 2rem;
    color: ${props => props.theme.color14};
`;
