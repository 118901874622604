import styled, {css} from 'styled-components';


export const listBg = '#fff';
export const listBgHover = '#E5E5E5';
export const listColor = '#000000';
export const listColorHover = '#2B9241';
export const listBorder = '#2B9241';
export const activeColor = '#2B9241';
export const boxShadow = '0 0 3rem #2B92411a';
export const listItemBorder = '#F5F5F5';

export const Wrapper = styled.div`    
    position:relative;
    display: block;
`;
export const Button = styled.div`
    display: flex;
    align-items: flex-start;
    ${({active}) => active && css`
    & > div {        
        color: ${listColorHover};
        &:before {
          border-top-color: ${listColorHover};
        }
    }
    
    &:hover {
      .tooltip-alt {
        display: none;
      }
    }
    
    &:before {
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        top: 100%;
        border: 2rem solid transparent;
        left: 50%;    
        transform: translateX(-50%);
        border-bottom-color: ${listBorder};
        z-index: 4;
        margin-top: -2rem;
    }
    &:after {
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        top: 100%;        
        border: 2rem solid transparent;
        left: 50%;    
        transform: translateX(-50%);
        border-bottom-color: ${listBg};
        z-index: 5;
        margin-top: calc(-2rem + 1px);
    }
`}
`;
export const ListItemIcon = styled.i`
  font-size: 3rem;
  margin-right: 2rem;
`;

export const ListItem = styled.div`
    position: relative;
    padding: 2rem;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    border-top: 1px solid ${listItemBorder};
    &:first-child {
      border-top: 0;
    }
    ${({active}) => active && css`
      color: ${activeColor};
    `}
    &:hover {
        position: relative;
        color: ${listColorHover};
        background: ${listBgHover};
        z-index: 1;
    }
`;
export const List = styled.div`
    top: 100%;
    left: 50%;    
    transform: translateX(-50%);    
    background: ${listBg};
    color: ${listColor};
    text-align: center;
    font-size: 2.3rem;
    position: absolute;
    z-index: 3;
    box-shadow: ${boxShadow};
    border: 1px solid ${listBorder};    
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 2rem;
    &:before {
        content: "";
        position:absolute;
        border: 2rem solid transparent;
    }
`;


