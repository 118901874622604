// @flow
import React, {Component} from 'react';
import Select, {components} from 'react-select';
import {Scrollbars} from 'infrastructure/view/component/ScrollbarsWrapper';
import styled from 'styled-components';
import icon from './checkbox-icon.svg';
import noChecked from './no-checked-icon.svg';
import noCheckedSelected from './no-checked-icon-selected.svg';

import colors from '../../styles/common-colors/common-colors';

class ReactSelectWrapper extends Component {
    static defaultProps = {
        menuListHeight: 100,
        closeMenuOnSelect: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
        this.menuList = this.menuList.bind(this);
    }

    menuList(props) {
        const {menuListHeight} = this.props;

        return (
            <components.MenuList {...props}>
                <Scrollbars autoHeight autoHeightMax={menuListHeight}>
                    {props.children}
                </Scrollbars>
            </components.MenuList>
        );
    }

    handleChange = value => {
        this.setState({defaultValue: value});
        this.props.handleChange && this.props.handleChange(value);
        this.props.onChange && this.props.onChange(value);
    };

    render() {
        const {
            components,
            sizeBig,
            selectTransparent,
            placeholder,
            disabled,
            isEmpty,
            ...otherProps
        } = this.props;
        const emptyPlaceholder = this.props.options[0] ? this.props.options[0].label : '';

        return (
            <Styles
                className="select-container"
                sizeBig={sizeBig}
                transparent={selectTransparent}
            >
                <div
                    className="select-wrapper"
                >
                    <Select
                        className="react-select"
                        classNamePrefix="select-input"
                        isSearchable={false}
                        components={{...components, MenuList: this.menuList}}
                        placeholder={placeholder || (!isEmpty ? emptyPlaceholder : '')}
                        // value={this.props.defaultValue || this.props.options[0]}
                        isDisabled={disabled}
                        onChange={this.handleChange}
                        {...otherProps}
                        value={this.props.value || this.state.defaultValue}
                    />
                </div>
            </Styles>
        );
    }
}

const Styles = styled.div`
    width: 100%;
    .select-wrapper {
        margin-bottom: 1rem;
        .select-input {
            &__menu-list {
                overflow: hidden;
            }
            
            &--is-disabled {
                .select-input {
                    &__placeholder {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: ${colors.color15};
                    }
                }
            }

            &__multi-value,
            &__single-value {
                margin-left: 0.33rem;
                margin-right: 0.33rem;
                max-width: calc(100% - 1.33rem);
                color: ${props =>
                    props.transparent
                        ? `${colors.color15}`
                        : `${colors.color15}`};
                font-size: ${props =>
                    props.transparent ? '2.66rem' : '2.33rem'};
                //font-family: 'Lato', sans-serif;
                position: inherit;
                transform: inherit;
                top: 0;
                border: none;
                
                &:hover {
                    color: ${colors.color20};
                }

                &--is-disabled {
                    color: ${colors.color14};
                    opacity: .5;
                }
            }

            &__control {
                cursor: pointer;
                width: 100%;
                box-sizing: border-box;
                font-size: ${props =>
                    props.transparent ? '2.8rem' : '2.33rem'};
                //font-family: 'Lato', sans-serif;
                box-shadow: none;
                background-color: ${props =>
                    props.transparent
                        ? `${colors.color25}`
                        : props.sizeBig
                        ? `${colors.color7}`
                        : `${colors.color7}`};
                border-radius: 0;
                border: 1px solid transparent;
                border-bottom: 1px solid ${props =>
                    props.transparent
                        ? props.theme.color5
                        : 'transparent'};
                outline: none;
                padding: ${props =>
                    props.transparent ? '0 0.5rem 0 0' : '0 0.5rem 0 0.5rem'};
                min-height: ${props => (props.sizeBig ? '5.83rem' : '4.16rem')};
                font-weight: normal;
                transition: .3s;
                
                &:hover {
                     border-color: ${props =>
                         props.transparent
                             ? `${colors.color25}`
                             : `${colors.color1}`};
                     border-bottom-color: ${props =>
                         props.transparent
                             ? 'rgba(0,139,232,0.5)'
                             : props.theme.color1};
                     // border-top: ${props =>
                         props.transparent ? 'none' : 'inherit'};
                     // border-left: ${props =>
                         props.transparent ? 'none' : 'inherit'};
                     // border-right: ${props =>
                         props.transparent ? 'none' : 'inherit'};
                     
                     .select-input {
                         &__placeholder,
                         &__single-value {
                            color: ${props =>
                                props.transparent
                                    ? `${colors.color15}`
                                    : `${colors.color15}`};
                         }
                     }
                }
                
                &--is-disabled {
                    .select-input {
                        &__indicators {
                            display: none;
                        }
                    }
                }

                &--menu-is-open {                    
                    box-shadow: none;
                    z-index: 20;
                    background-color: ${props =>
                        props.transparent
                            ? 'transparent'
                            : props.sizeBig
                            ? `${colors.color20}`
                            : `${colors.color20}`};
                    border-color: ${props =>
                        props.transparent ? 'transparent' : `${colors.color1}`};
                    
                    .select-input {
                        &__placeholder,
                        &__single-value {
                            color: ${props =>
                                props.transparent
                                    ? `${colors.color15}`
                                    : `${colors.color15}`};
                        }
                    
                        &__multi-value {
                            &__label {
                                color: ${colors.color15};
                            }
                        }
                    }
                }
            }

            &__placeholder {
                position: inherit;
                transform: inherit;
                top: 0;
                color: ${props =>
                    props.transparent ? 'inherit' : `${colors.color14}`};;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${props => props.theme.color14};               
            }

            &__menu {
                position: absolute;
                top: ${props => (props.transparent ? '100%' : '-1.33rem')};
                z-index: 15;
                margin-top: ${props => (props.transparent ? 0 : '1.33rem')};
                background: ${props =>
                    props.transparent ? props.theme.color20 : props.theme.color20};
                color: #999999;
                overflow: hidden;
                border: 1px solid ${(p) => p.theme.color1};                
                box-shadow: ${props =>
                    props.transparent
                        ? `0 0 3rem ${(p) => p.theme.color66}`
                        : `0 0 3rem ${(p) => p.theme.color66}`};
            }

            &__menu-list {
                padding: ${props =>
                    props.sizeBig
                        ? '5.7rem 0 0 0'
                        : props.transparent
                        ? 0
                        : '4.4rem 0 0 0'};

                .dropdown {
                    max-height: 30rem;
                }

                .scrollbar-container.vertical {
                    margin-right: 0.5rem;
                }
              
                &--is-multi {
                    .select-input {
                        &__option {
                             position: relative;
                            display: flex;
                            align-items: center;
                            color: #999999;
                            height: ${props =>
                                props.sizeBig ? '7.5rem' : 'auto'};
                            padding-left: 7.5rem;
                            font-size: ${props =>
                                props.sizeBig ? '3.5rem' : 'inherit'};
                            
                             &:before {
                                content: '';
                                background: url(${noChecked}) no-repeat 0 50%;
                                background-size: 100%;
                                width: 2.7rem;
                                height: 2.7rem;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                right: auto;
                                left: 2rem;                                
                            }
                            
                            &:hover:before {
                                background: url(${noCheckedSelected}) no-repeat 50% 50%;
                                background-size: 100%;                               
                            }
                     
                            &--is-selected {
                                position: relative;
                                color: ${props => props.theme.color1};
                                
                                &:before {
                                    // display: none;
                                }
                                &:before {
                                    content: '';
                                    background: url(${icon}) no-repeat 50% 50%;
                                    background-size: 100%;
                                    width: 2.7rem;
                                    height: 2.7rem;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    left: 2rem;                                    
                                    color: ${props => props.theme.color1};
                                }
                                &:hover {
                                    &:before {
                                        background: url(${icon}) no-repeat 50% 50%;
                                        background-size: 100%;
                                    }                                    
                                }
                            }
                        }
                    }
                }
            }

            &__option {
                position: relative;
                padding: 1.3334rem 2rem;    
                cursor: pointer;
                width: 100%;
                margin: 0;
                font-size: 2.33rem;
                font-family: 'Lato', sans-serif;
                font-weight: 400;
                color: ${props =>
                    props.transparent
                        ? `${colors.color14}`
                        : `${colors.color14}`};

                &:before {
                    content: '';
                    position: absolute;
                    left: 2rem;
                    right: 2rem;
                    bottom: 100%;
                    height: 1px;
                    background: ${colors.color5};
                }
                
                &:hover {
                    position: relative;
                    color: ${props =>
                        props.transparent
                            ? `${colors.color1}`
                            : `${colors.color1}`};
                    background: ${props =>
                        props.transparent
                            ? `${colors.color69}`
                            : `${colors.color69}`};
                    z-index: 1;
                }

                &--is-selected {
                    color: ${props =>
                        props.transparent
                            ? `${colors.color1}`
                            : `${colors.color1}`};
                    background: ${props =>
                        props.transparent ? 'none' : `${colors.color25}`};
                }

                &--is-focused {
                    background: ${props =>
                        props.transparent ? 'none' : `${colors.color25}`};
                }
            }

            &__indicator-separator {
                display: none;
            }

            &__dropdown-indicator {
                font-family: 'iconfont';
                font-style: normal;
                color: #999999;
                padding: 1.33rem;
                padding-top: ${props => (props.sizeBig ? '2.5rem' : '1.5rem')};

                svg {
                    display: none;
                }

                &::before {
                    content: "\\e959";
                    font-family: 'iconfont';
                    font-size: 2rem;                    
                }
            }

            &__value-container {
                cursor: pointer;
                height: 4.16rem;
                padding: 0.33rem 1.33rem;
                padding-left: ${props => (props.transparent ? 0 : '1.33rem')};
                
                input {
                    position: absolute;
                }

                &--is-multi {
                    display: flex;
                    .listing-exchanges & {
                        display: block;
                        white-space: nowrap;
                        .select-input__multi-value {
                            display: inline;
                            .select-input__multi-value__label {
                                display: inline;
                            }
                        }
                    }                    
                }
            }

            &__multi-value {
                background-color: transparent;
                display: flex;
                margin: 0 1rem 0 0;
                min-width: 0;
                box-sizing: border-box;

                &__label {
                    padding: 0;
                    font-size: ${props => (props.sizeBig ? '3.5rem' : '100%')};
                    line-height: ${props =>
                        props.sizeBig ? '3.5rem' : '100%'};
                    color: ${colors.color15};
                }

                &__remove {
                    display: none;
                }
            }

            &__indicators {
                height: 4.16rem;
                cursor: pointer;
            }

            &__clear-indicator {
                display: none;
            }
        }
        
        .track-vertical {
            z-index: 99;
        }
    }
`;

export default ReactSelectWrapper;
