import { ActionTypes } from './actions';

const initialState = {
    activeView: null,
    activeDashboardIndex: 0,
    showDepositModal: false,
    showSessionModal: false,
    orderFormValues: {
        price: '',
        amount: ''
    },
    errorOrder: {},
    positionTooltip: false,
    symbolsActiveTab: 0,
    myTradingActiveTab: 0,
    openErrorLimitOrderModal: false,
};

function dashboardReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.openLimitOrderModal:
            return{
                ...state,
                openErrorLimitOrderModal: true
            };

        case ActionTypes.closeLimitOrderModal:
            return{
                ...state,
                openErrorLimitOrderModal: false,
            };

        case ActionTypes.setActiveView:
            return {
                ...state,
                activeView: action.view
            };

        // TODO: rename `activeDashboardIndex` to `activeLayoutIndex`
        case ActionTypes.setActiveDashboardIndex:
            return {
                ...state,
                activeDashboardIndex: action.activeDashboardIndex
            };

        case ActionTypes.openDepositModal:
            return {
                ...state,
                showDepositModal: true
            };

        case ActionTypes.openSessionModal:
            return {
                ...state,
                showSessionModal: true,
            };

        case ActionTypes.closeSessionModal:
            return {
                ...state,
                showSessionModal: false
            };

        case ActionTypes.errorOrder:
            return {
                ...state,
                errorOrder: action.error,
                showDepositModal: true,
            };

        case ActionTypes.closeDepositModal:
            return {
                ...state,
                showDepositModal: false
            };

        case ActionTypes.setOrderForm:
            return {
                ...state,
                orderFormValues: action.payload
            };

        case ActionTypes.positionTooltip:
            return {
                ...state,
                positionTooltip: true
            };
        case ActionTypes.setActiveTabSymbols:
            return {
                ...state,
                symbolsActiveTab: action.payload
            };
        case ActionTypes.setActiveTabMyTrading:
            return {
                ...state,
                myTradingActiveTab: action.payload
            };

        default:
            return state;
    }
}

export default dashboardReducer;
