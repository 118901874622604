// @flow
import type {AccessToken} from '../../../domain/entitiy/AccessToken';
import type {NotificationAdapterInterface as NotificationAdapter} from '../../adapter/Notification';

class GetNotificationsHistory {
    adapter: NotificationAdapter;

    constructor(adapter: NotificationAdapter) {
        this.adapter = adapter;
    }

    async invoke(token: AccessToken) {
        try {
            return await this.adapter.getNotificationsHistory(token);
        } catch (error) {
            throw error;
        }
    }
}

export default GetNotificationsHistory;
