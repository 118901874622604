import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import parseTranslation from '../../../../adapter/helper/parseTranslation';

import ReactSelectWrapper from 'infrastructure/view/component/ReactSelectWrapper/ReactSelectWrapper';
import parseTranslation from '../../infrastructure/adapter/helper/parseTranslation';
import connect from 'react-redux/es/connect/connect';

function mapStateToProps() {
    return {
        labelDay: parseTranslation('label-day'),
        labelMonth: parseTranslation('label-month'),
        labelYear: parseTranslation('label-year'),
    };
}

class Index extends Component {
    constructor(props) {
        super(props);
        let {dayLabel, monthLabel, yearLabel} = props;

        this.state = {
            day: null,
            month: null,
            year: null,
            selectDay: props.mode === 'TH' ? 'วันที่' : dayLabel,
            selectMonth: props.mode === 'TH' ? 'เดือน' : monthLabel,
            selectYear: props.mode === 'TH' ? 'ปี' : yearLabel,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.selectDay !== nextState.selectDay ||
            this.state.selectMonth !== nextState.selectMonth ||
            this.state.selectYear !== nextState.selectYear ||
            this.props.labelDay !== nextProps.labelDay ||
            this.props.labelMonth !== nextProps.labelMonth ||
            this.props.labelYear !== nextProps.labelYear
        );
    }

    componentWillMount() {
        let day = [],
            month = [],
            year = [];

        const pad = n => {
            return n < 10 ? '0' + n : n;
        };

        for (let i = 1; i <= 31; i++) {
            day.push(this.props.padDay ? pad(i) : i);
        }

        let monthIndex = 1;
        for (const monthName of moment.localeData().months()) {
            month.push({
                text: this.props.useMonthNames
                    ? monthName
                    : this.props.padMonth
                    ? pad(monthIndex)
                    : monthIndex,
                value: monthIndex,
            });
            monthIndex++;
        }

        for (let i = this.props.maxYear; i >= this.props.minYear; i--) {
            year.push(i);
        }

        this.setState({
            day: day,
            month: month,
            year: year,
        });
    }

    changeDate(e, type) {
        this.setState({
            [type]: e.value,
        });
        this.checkDate(e.value, type);
    }

    checkDate(value, type) {
        let {selectDay, selectMonth, selectYear} = this.state;

        if (type === 'selectDay') {
            selectDay = value;
        } else if (type === 'selectMonth') {
            selectMonth = value;
        } else if (type === 'selectYear') {
            selectYear = value;
        }

        if (this.isSelectedAllDropdowns(selectDay, selectMonth, selectYear)) {
            this.props.dateChange(
                moment({
                    year: selectYear,
                    month: selectMonth - 1,
                    day: selectDay,
                }).format()
            );
        }
    }

    isSelectedAllDropdowns(selectDay, selectMonth, selectYear) {
        return this.props.mode === 'TH'
            ? selectDay !== 'วันที่' &&
                  selectMonth !== 'เดือน' &&
                  selectYear !== 'ปี'
            : selectDay !== this.props.dayLabel &&
                  selectMonth !== this.props.monthLabel &&
                  selectYear !== this.props.yearLabel;
    }

    render() {
        const dayOptions = this.state.day.map(day => {
            return {label: day, value: day};
        });

        const monthOptions = this.state.month.map(month => {
            return {label: month.value, value: month.value};
        });

        const yearOptions = this.state.year.map(year => {
            return {label: year, value: year};
        });

        return (
            <div className="date-picker--value">
                <ReactSelectWrapper
                    selectTransparent
                    placeholder={this.props.labelDay}
                    disabled={this.props.disabled}
                    options={dayOptions}
                    onChange={e => this.changeDate(e, 'selectDay')}
                    menuListHeight="40rem"
                />
                <ReactSelectWrapper
                    selectTransparent
                    placeholder={this.props.labelMonth}
                    disabled={this.props.disabled}
                    options={monthOptions}
                    onChange={e => this.changeDate(e, 'selectMonth')}
                    menuListHeight="40rem"
                />
                <ReactSelectWrapper
                    selectTransparent
                    placeholder={this.props.labelYear}
                    disabled={this.props.disabled}
                    options={yearOptions}
                    onChange={e => this.changeDate(e, 'selectYear')}
                    menuListHeight="40rem"
                />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    null
)(Index);

Index.propTypes = {
    className: PropTypes.string,
    dateChange: PropTypes.func,
    // dayLabel: PropTypes.string,
    maxYear: PropTypes.number,
    minYear: PropTypes.number,
    mode: PropTypes.string,
    // monthLabel: PropTypes.string,
    padDay: PropTypes.bool,
    padMonth: PropTypes.bool,
    selectDay: PropTypes.string,
    selectMonth: PropTypes.string,
    selectYear: PropTypes.string,
    useMonthNames: PropTypes.bool,
    // yearLabel: PropTypes.string,
};

Index.defaultProps = {
    // dayLabel: 'day',
    minYear: 1916,
    maxYear: 2016,
    // monthLabel: 'month',
    padDay: false,
    padMonth: false,
    selectDay: '',
    selectMonth: '',
    selectYear: '',
    // yearLabel: 'year',
    useMonthNames: false,
};
