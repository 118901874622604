import axios from '../../../adapter/helper/axios';
import {accessTokenKey, userRegionKey} from 'settings';

import {ActionTypes as LoginServiceActionTypes, getMathCaptcha} from '../../scene/Login/service/actions';
import {ActionTypes as ProfileServiceActionTypes} from '../../scene/Settings/service/actions';
import {
    ActionTypes as UserServiceActionTypes,
    initAuth,
    setDealingToken,
    setUserRegion,
} from '../action/user';

import {
    ActionTypes as SessionActionTypes,
    setActivityTimer,
    startActivityTimer,
    setPingServerSession,
    postUpdateServerSessionFailure,
    postUpdateServerSessionSuccess,
    postUpdateServerSessionRequest,
} from '../action/session';

import {ActionTypes as RecoveryFormActionTypes} from '../../scene/RecoveryForm/service/actions';
import {
    setForm,
    updateForm,
    errorForm,
    errorPasswordForm, setMathCaptcha,
} from '../action/forms';
import {getUserData} from '../action/user';
import {clearClosedOrders} from '../../scene/Dashboard/components/TradingHistory/service/actions';

import GetLoginForm from '../../../../application/use-case/user/GetLoginForm';
import GetProfileForm from '../../../../application/use-case/user/GetProfileForm';
import GetPasswordForm from '../../../../application/use-case/user/GetPasswordForm';
import GetRecoveryForm from '../../../../application/use-case/user/GetRecoveryForm';
import GetRecoveryPasswordForm from '../../../../application/use-case/user/GetRecoveryPasswordForm';
import SubmitLoginForm from '../../../../application/use-case/user/SubmitLoginForm';
import SubmitAuthForm from '../../../../application/use-case/user/SubmitAuthForm';
import SubmitProfileForm from '../../../../application/use-case/user/SubmitProfileForm';
import SubmitRecoveryForm from '../../../../application/use-case/user/SubmitRecoveryForm';
import SubmitRecoveryPasswordForm from '../../../../application/use-case/user/SubmitRecoveryPasswordForm';
import SubmitPasswordForm from '../../../../application/use-case/user/SubmitPasswordForm';
import GetDealingToken from '../../../../application/use-case/user/GetDealingToken';
import LoginFormAdapter from '../../../adapter/LoginForm';
import ProfileFormAdapter from '../../../adapter/ProfileForm';
import PasswordFormAdapter from '../../../adapter/PasswordForm';
import RecoveryFormAdapter from '../../../adapter/RecoveryForm';
import PasswordRecoveryFormAdapter from '../../../adapter/PasswordRecoveryForm';
import UserAdapter from '../../../adapter/User';
import GetUserData from '../../../../application/use-case/user/GetUserData';
import GetSession from '../../../adapter/Session';

import {
    timerActivity,
    timerConfirm,
    absoluteSessionTimer,
    intervalUpdateSession,
} from '../../../../session';
import {notificationSocketDisconnect} from '../action/notification';
import {dealingSocketDisconnect} from '../action/socket';
import {getServerAPI} from '../../../../utils';
import GetMathCaptcha from '../../../../application/use-case/user/GetMathCaptcha';

const loginFormAdapter = new LoginFormAdapter(axios);
const profileFormAdapter = new ProfileFormAdapter(axios);
const passwordFormAdapter = new PasswordFormAdapter(axios);
const recoveryFormAdapter = new RecoveryFormAdapter(axios);
const recoveryPasswordFormAdapter = new PasswordRecoveryFormAdapter(axios);
const userAdapter = new UserAdapter(axios);
const getSessionData = new GetSession(axios);

const submitLoginForm = new SubmitLoginForm(loginFormAdapter);
const submitAuthForm = new SubmitAuthForm(loginFormAdapter);
const submitProfileForm = new SubmitProfileForm(profileFormAdapter);
const submitPasswordForm = new SubmitPasswordForm(passwordFormAdapter);
const submitRecoveryForm = new SubmitRecoveryForm(recoveryFormAdapter);
const submitPasswordRecoveryForm = new SubmitRecoveryPasswordForm(
    recoveryPasswordFormAdapter
);
const getData = new GetUserData(userAdapter);

const getLoginForm = new GetLoginForm(loginFormAdapter);
const getMathCaptchaAdapter = new GetMathCaptcha(loginFormAdapter);
const getDealingToken = new GetDealingToken(loginFormAdapter);
const getProfileForm = new GetProfileForm(profileFormAdapter);
const getPasswordForm = new GetPasswordForm(passwordFormAdapter);
const getRecoveryForm = new GetRecoveryForm(recoveryFormAdapter);
const getPasswordRecoveryForm = new GetRecoveryPasswordForm(
    recoveryPasswordFormAdapter
);

let clearTimerActivity;
let clearConfirmTimer;
let timerAbsolute;
let savedServer;

export default function(store) {
    return function(next) {
        return async function(action) {
            const local = localStorage.getItem('locale') || store.getState().translation.local;

            if (action.type === LoginServiceActionTypes.submitForm) {
                let data = null;
                if (store.getState().forms['GoogleAuthenticatorForm']) {

                    const {scheme, result} = store.getState().forms['GoogleAuthenticatorForm'];
                    const formData = Object.values(scheme).reduce(
                        (result, schemeData) => {
                            result[schemeData.name] = schemeData.value;
                            return result;
                        },
                        {}
                    );
                    savedServer = savedServer || result.server;
                    try {
                        const res = await submitLoginForm.invoke(
                            formData,
                            local,
                            savedServer,
                        );

                        const userRegion = JSON.stringify({...res.data.result, ...JSON.parse(localStorage.getItem(userRegionKey))});
                        store.dispatch(setUserRegion(res.data.result));
                        localStorage.setItem(userRegionKey, userRegion);

                        localStorage.setItem(
                            'absoluteTimeExpiration',
                            Number(absoluteTimeMs) + Number(new Date().getTime())
                        );

                        let absoluteAuthTimeout = 30000;
                        let absoluteTimeMs = absoluteAuthTimeout * 1000 * 0.9;

                        localStorage.setItem(
                            'absoluteTimeExpiration',
                            Number(absoluteTimeMs) + Number(new Date().getTime())
                        );

                        data = res.data;
                    } catch (e) {
                        store.dispatch(errorForm(e));
                    }

                    if (data && data.result.length !== 0) {
                        store.dispatch(initAuth(data.result.access_token, action.history));
                        localStorage.setItem(
                            accessTokenKey,
                            data.result
                        );

                        const promise = new Promise((resolve) => {
                            const intervalId = setInterval(() => {
                                if (data.result.access_token) {
                                    resolve(intervalId);
                                }
                            }, 100);
                        });

                        promise.then((intervalId) => {
                            clearInterval(intervalId);
                            action.history.push('/dashboard');
                        });

                    } else {
                        store.dispatch(setForm(data));
                    }
                    store.dispatch(updateForm('GoogleAuthenticatorForm', 'captcha', null));
                } else {
                    const {scheme} = store.getState().forms['LoginForm'];
                    const formData = Object.values(scheme).reduce(
                        (result, schemeData) => {
                            result[schemeData.name] = schemeData.value;
                            return result;
                        },
                        {}
                    );

                    try {
                        const res = await submitLoginForm.invoke(
                            formData,
                            local
                        );

                        const userRegion = JSON.stringify(res.data.result);
                        // debugger;
                        store.dispatch(setUserRegion(res.data.result));
                        localStorage.setItem(userRegionKey, userRegion);

                        localStorage.setItem(
                            'absoluteTimeExpiration',
                            Number(absoluteTimeMs) + Number(new Date().getTime())
                        );

                        let absoluteAuthTimeout = 30000;
                        let absoluteTimeMs = absoluteAuthTimeout * 1000 * 0.9;

                        localStorage.setItem(
                            'absoluteTimeExpiration',
                            Number(absoluteTimeMs) + Number(new Date().getTime())
                        );

                        data = res.data;
                    } catch (e) {
                        store.dispatch(errorForm(e));
                    }

                    if (data && data.result.length !== 0) {

                        store.dispatch(initAuth(data.result.access_token));
                        localStorage.setItem(
                            accessTokenKey,
                            data.result.access_token
                        );

                        const promise = new Promise((resolve) => {
                            const intervalId = setInterval(() => {

                                if (getServerAPI()().length) {
                                    resolve(intervalId);
                                }
                            }, 100);
                        });

                        data.result.access_token &&
                        promise.then((intervalId) => {
                            clearInterval(intervalId);
                            action.history.push('/dashboard');
                        });

                    } else {
                        store.dispatch(setForm(data));
                    }
                    store.dispatch(updateForm('LoginForm', 'captcha', null));
                }
                store.dispatch(setForm(data));
            }

            if (action.type === LoginServiceActionTypes.getForm) {
                try {
                    const res = await getLoginForm.invoke(local);

                    store.dispatch(setForm(res.data));
                } catch (e) {
                    store.dispatch(errorForm(e.message));
                }
            }

            if (action.type === LoginServiceActionTypes.getMathCaptcha) {

                try {
                    const res = await getMathCaptchaAdapter.invoke(action.url);

                    function _arrayBufferToBase64( buffer ) {
                        var binary = '';
                        var bytes = new Uint8Array( buffer );
                        var len = bytes.byteLength;
                        for (var i = 0; i < len; i++) {
                            binary += String.fromCharCode( bytes[ i ] );
                        }
                        return window.btoa( binary );
                    }

                    store.dispatch(setMathCaptcha(_arrayBufferToBase64(res.data)));
                } catch (e) {
                    /**/store.dispatch(errorForm(e.message));
                }
            }

            if (
                action.type === SessionActionTypes.START_INTERVAL_UPDATE_SESSION
            ) {
                intervalUpdateSession();
            }

            if (
                action.type === SessionActionTypes.START_ABSOLUTE_SESSION_TIMER
            ) {
                timerAbsolute = absoluteSessionTimer();
            }

            if (
                action.type === SessionActionTypes.CLEAR_ABSOLUTE_SESSION_TIMER
            ) {
                if (timerAbsolute) {
                    clearTimeout(timerAbsolute);
                }
            }

            if (action.type === SessionActionTypes.startActivityTimer) {
                clearTimerActivity = timerActivity();
            }

            if (action.type === SessionActionTypes.clearActivityTimer) {
                clearTimeout(clearTimerActivity);
                store.dispatch(setActivityTimer(false));
                store.dispatch(startActivityTimer());
                store.dispatch(setPingServerSession(false));
            }

            if (action.type === SessionActionTypes.startConfirmTimer) {
                clearConfirmTimer = timerConfirm();
            }

            if (action.type === SessionActionTypes.clearConfirmTimer) {
                if (clearConfirmTimer) {
                    store.dispatch(postUpdateServerSessionRequest());
                    clearConfirmTimer();
                }
            }

            if (
                action.type ===
                SessionActionTypes.POST_UPDATE_SERVER_SESSION_REQUEST
            ) {
                try {
                    const {accessToken} = store.getState().user;
                    const data = await getSessionData.getSession(accessToken);
                    store.dispatch(postUpdateServerSessionSuccess());
                } catch (e) {
                    store.dispatch(postUpdateServerSessionFailure());
                }
            }

            if (action.type === UserServiceActionTypes.GET_DEALING_TOKEN) {
                const {accessToken} = store.getState().user;
                const {isConnected} = store.getState().socket;
                const sitePair = action.value;
                if (!isConnected) {
                    try {
                        const dealingToken = await getDealingToken.invoke(
                            accessToken
                        );

                        sitePair
                            ? store.dispatch(setDealingToken(dealingToken, sitePair))
                            : store.dispatch(setDealingToken(dealingToken));
                    } catch (error) {
                        // TODO: Add error handling
                    }
                }
            }

            if (action.type === UserServiceActionTypes.setSelectedSymbol) {
                store.dispatch(clearClosedOrders());
            }

            if (action.type === UserServiceActionTypes.LOGOUT) {
                const {history} = action;

                // disconnect notification and dealing sockets before logout
                store.dispatch(notificationSocketDisconnect());
                store.dispatch(dealingSocketDisconnect());

                // cleanup localStorage from user data
                window.localStorage.removeItem('userRegion');
                window.localStorage.removeItem('currentUserTime');
                window.localStorage.removeItem('absoluteTimeExpiration');
                // window.localStorage.removeItem('nextShow2FAModalTime');

                // cleanup cookie from user data
                document.cookie =
                    'token=; expires=' +
                    new Date().toUTCString() +
                    '; path=/; domain=' +
                    window.location.hostname.substring(
                        window.location.hostname.lastIndexOf(
                            '.',
                            window.location.hostname.lastIndexOf('.') - 1
                        ) + 1
                    ) +
                    ';';

                // redirect to login page
                if (history) {
                    // history.push('/login');
                } else {
                    // window.location.href = '/login';
                }
            }

            if (action.type === ProfileServiceActionTypes.getProfileForm) {
                try {
                    const {accessToken} = store.getState().user;

                    const formData = await getProfileForm.invoke(
                        local,
                        accessToken
                    );
                    store.dispatch(setForm(formData));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ProfileServiceActionTypes.submitProfileForm) {
                const {accessToken} = store.getState().user;
                const {scheme} = store.getState().forms['ProfileForm'];
                let formData = new FormData();

                formData.append('ProfileForm[avatar]', scheme.avatar.value);
                formData.append(
                    'ProfileForm[remove_avatar]',
                    scheme.remove_avatar.value
                );

                try {
                    const form = await submitProfileForm.invoke(
                        accessToken,
                        formData,
                        local
                    );

                    store.dispatch(setForm(form));
                    store.dispatch(getUserData());
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === ProfileServiceActionTypes.getPasswordForm) {
                try {
                    const {accessToken} = store.getState().user;
                    const {payload} = action;
                    const formData = await getPasswordForm.invoke(
                        payload || local,
                        accessToken
                    );

                    store.dispatch(setForm(formData));
                } catch (e) {
                    store.dispatch(errorPasswordForm(e));
                }
            }

            if (action.type === ProfileServiceActionTypes.submitPasswordForm) {
                try {
                    const {accessToken} = store.getState().user;
                    const {scheme} = store.getState().forms['PasswordForm'];

                    const formData = {
                        PasswordForm: Object.values(scheme).reduce(
                            (result, schemeData) => {
                                result[schemeData.name] = schemeData.value;
                                return result;
                            },
                            {}
                        ),
                    };

                    const form = await submitPasswordForm.invoke(
                        accessToken,
                        formData,
                        local
                    );

                    store.dispatch(setForm(form));
                } catch (e) {
                    store.dispatch(errorPasswordForm(e));
                }
            }

            if (action.type === RecoveryFormActionTypes.getForm) {
                try {
                    const res = await getRecoveryForm.invoke(local);
                    store.dispatch(setForm(res.data));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (action.type === RecoveryFormActionTypes.submitForm) {

                const {scheme} = store.getState().forms['RecoveryForm'];

                const formData = {
                    RecoveryForm: Object.values(scheme).reduce(
                        (result, schemeData) => {
                            result[schemeData.name] = schemeData.value;
                            return result;
                        },
                        {}
                    ),
                };

                try {
                    const form = await submitRecoveryForm.invoke(
                        formData,
                        local
                    );

                    store.dispatch(setForm(form.data));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (
                action.type === RecoveryFormActionTypes.getRecoveryPasswordForm
            ) {
                try {
                    const res = await getPasswordRecoveryForm.invoke(local);
                    store.dispatch(setForm(res.data));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            if (
                action.type ===
                RecoveryFormActionTypes.submitRecoveryPasswordForm
            ) {
                const {scheme} = store.getState().forms['ResetPasswordForm'];
                const tokenConfirmationArr: [] = window.location.pathname.split(
                    '/'
                );
                const tokenConfirmation: string =
                    tokenConfirmationArr[tokenConfirmationArr.length - 1];

                let data = Object.values(scheme).reduce(
                    (result, schemeData) => {
                        result[schemeData.name] = schemeData.value;
                        return result;
                    },
                    {}
                );

                let formData = {
                    ResetPasswordForm: {
                        ...data,
                        token: tokenConfirmation,
                    },
                };

                try {
                    const form = await submitPasswordRecoveryForm.invoke(
                        formData,
                        local
                    );

                    store.dispatch(setForm(form.data));
                } catch (e) {
                    store.dispatch(errorForm(e));
                }
            }

            next(action);
        };
    };
}
