export const ActionTypes = {
    setOpenedOrders: 'dashboard-scene.trading-history-component.orders.set',
    openOrderSuccess: 'dashboard-scene.trading-history-component.open-order.success',
    openOrderError: 'dashboard-scene.trading-history-component.open-order.error',
    updateOrder: 'dashboard-scene.trading-history-component.order.update',
    dropOrderHighlight: 'dashboard-scene.trading-history-component.order.drop-highlight',
    closeOrder: 'dashboard-scene.trading-history-component.order.close',
    removeOrderFromList: 'dashboard-scene.trading-history-component.order.remove-from-list',

    fetchClosedOrders: 'dashboard-scene.trading-history-component.order.closed-orders.fetch',
    setClosedOrders: 'dashboard-scene.trading-history-component.order.closed-orders.set',
    errorClosedOrders: 'dashboard-scene.trading-history-component.order.closed-orders.error',
    errorOpenOrders: 'dashboard-scene.trading-history-component.order.open-orders.error',
    clearClosedOrders: 'dashboard-scene.trading-history-component.order.closed-orders.clear',
    CANCEL_ORDER_ERROR: 'CANCEL_ORDER_ERROR',
    CLEAR_CANCEL_ORDER_ERROR: 'CLEAR_CANCEL_ORDER_ERROR',
};

export function setOpenedOrders(orders) {
    return {
        type: ActionTypes.setOpenedOrders,
        orders
    };
}

export function openOrderSuccess(order) {
    return {
        type: ActionTypes.openOrderSuccess,
        // TODO: Replace `payload` with just `order`
        payload: {
            ...order
        }
    };
}

// TODO: Currenly not used -> add implementation
export function openOrderError(order) {
    return {
        type: ActionTypes.openOrderError,
        order
    };
}

export function updateOrder(order, symbols) {
    return {
        type: ActionTypes.updateOrder,
        order,
        symbols
    };
}

export function dropOrderHighlight(id) {
    return {
        type: ActionTypes.dropOrderHighlight,
        id
    };
}

export function closeOrder(order) {
    return {
        type: ActionTypes.closeOrder,
        order
    };
}

export function removeOrderFromList(id) {
    return {
        type: ActionTypes.removeOrderFromList,
        id
    };
}

export function fetchClosedOrders(page) {
    return {
        type: ActionTypes.fetchClosedOrders,
        page
    };
}

export function setClosedOrders(orders) {
    return {
        type: ActionTypes.setClosedOrders,
        orders
    };
}

export function clearClosedOrders() {
    return {
        type: ActionTypes.clearClosedOrders
    };
}

export function errorClosedOrders(payload) {
    return {
        type: ActionTypes.errorClosedOrders,
        payload,
    };
}

export function errorOpenOrders(payload) {
    return {
        type: ActionTypes.errorOpenOrders,
        payload,
    };
}

export function cancelOrderError(payload) {
    return {
        type: ActionTypes.CANCEL_ORDER_ERROR,
        payload,
    };
}

export function clearCancelOrderError(payload) {
    return {
        type: ActionTypes.CLEAR_CANCEL_ORDER_ERROR,
        payload,
    };
}
