// @flow
class SubmitPasswordForm {
    constructor(adapter) {
        this.adapter = adapter;
    }

    async invoke(token, formData: {} = {}, locale): Promise<any> {
        try {
            const { data } = await this.adapter.sendForm(token, formData, locale);

            return data;
        } catch (error) {
            const { status, data } = error.response;

            if (status === 422) {
                return data;
            }

            throw error;
        }
    }
}

export default SubmitPasswordForm;
