import React from 'react';
import {SymbolButton, SymbolIcon, SymbolLabel} from './styled';

export default ({firstCurrency, secondCurrency, size = 'medium', onClick}) => {
    return (
        <SymbolButton size={size} onClick={onClick} className="new__symbol-button">
            <SymbolIcon
                className={
                    firstCurrency && `icon-${firstCurrency.toLowerCase()}`
                }
            />
            <SymbolLabel>{`${
                firstCurrency ? firstCurrency.toUpperCase() : 'N/A'
            }/${
                secondCurrency ? secondCurrency.toUpperCase() : 'N/A'
            }`}</SymbolLabel>
            <SymbolIcon
                className={
                    secondCurrency && `icon-${secondCurrency.toLowerCase()}`
                }
            />
        </SymbolButton>
    );
};
