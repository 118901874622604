import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';

import {setActiveCharts} from '../../Chart/service/actions';

import Chart from '../../Chart/ChartWithDimentionsFit';
import withControls from '../../Chart/withControls';

const ChartWithControls = withControls(Chart);

const mapDispatchToProps = {
    setActiveCharts,
};

class Layout03 extends Component {
    componentDidMount() {
        this.props.setActiveCharts([0, 1, 2]);
    }

    render() {
        return (
            <Fragment>
                <div
                    className="chart-container"
                    style={{
                        width: 'calc(31.5% - 0.5rem)',
                        height: 'calc(100% - 2rem)',
                        float: 'left',
                        marginLeft: '2rem',
                    }}
                >
                    <ChartWithControls id={0} />
                </div>

                <div
                    className="chart-container"
                    style={{
                        width: 'calc(31.5% - 0.5rem)',
                        height: 'calc(100% - 2rem)',
                        float: 'left',
                    }}
                >
                    <ChartWithControls id={1} />
                </div>

                <div
                    className="chart-container"
                    style={{
                        width: 'calc(31.5% - 0.5rem)',
                        height: 'calc(100% - 2rem)',
                        float: 'left',
                    }}
                >
                    <ChartWithControls id={2} />
                </div>
            </Fragment>
        );
    }
}

export default connect(
    null,
    mapDispatchToProps
)(Layout03);
