import React, {Fragment} from 'react';

import Symbols from '../components/Symbols/Symbols';
import ChartContainer from '../components/ChartContainer/ChartContainer';
import TradingHistory from '../components/TradingHistory/TradingHistory';
import DOM from '../components/DOM/DOM';

export default function() {
    return (
        <Fragment>
            <div className="col">
                <div className="col-item">
                    <Symbols />
                </div>
            </div>
            <div className="col wide">
                <div className="col-item">
                    <ChartContainer />
                </div>
                <div className="col-item">
                    <TradingHistory />
                </div>
            </div>
            <div className="col">
                <div className="col-item">
                    <DOM />
                </div>
            </div>
        </Fragment>
    );
}
